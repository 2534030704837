import React from 'react';
import isEmpty from 'lodash/isEmpty';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Featured } from '../Components';
import {
  Banner,
  BannerMobile,
  Carousel,
  CarouselItem
} from '../Commons';
import {
  activateKind,
  discordInviteLink,
  experiences,
  featuredBannerButtons,
  gamePassGamesCTA,
  maxWidths,
  minWidths
} from '../../constants';
import discordDailyBoostBannerDesktop from '../../assets/discord-daily-boost/banners/desktop.jpg';
import discordDailyBoostBannerMobile from '../../assets/discord-daily-boost/banners/mobile.jpg';

export const HomeFeaturedCarousel = ({ featured, wenLamboBanner, handleBannerCheckout }) => {
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  const handleClick = (experience, url) => {
    if (experience === activateKind.gamePass) handleBannerCheckout(url);
    else if (experience === experiences.discordDailyBoost) window.open(discordInviteLink, "_self");
  }

  return (
    <Featured>
      <Carousel>
        {featured.map((f, key) => {
          const isGamePass = f.experience === activateKind.gamePass;
          const isDiscordDailyBoost = f.experience === 'discord-daily-boost';
         
          let buttonText = featuredBannerButtons.startPlaying;
          if (isGamePass) buttonText = featuredBannerButtons.startPlaying;

          let buttonLink = gamePassGamesCTA;
          if (isDiscordDailyBoost) buttonLink = discordInviteLink;

          let imageUrl = null;
          if (f.image_url) imageUrl = f.image_url;
          if (isGamePass) {
            if (isMobile && !isEmpty(wenLamboBanner.mobile)) {
              imageUrl = wenLamboBanner.mobile;
            }
            if (!isMobile && !isEmpty(wenLamboBanner.desktop)) {
              imageUrl = wenLamboBanner.desktop;
            }
          }

          if (isDiscordDailyBoost) {
            if (isMobile) imageUrl = discordDailyBoostBannerMobile;
            else if (!isMobile) imageUrl = discordDailyBoostBannerDesktop;
          }

          let mobileTitle = f.name;
          if (isGamePass || isDiscordDailyBoost) mobileTitle = null;

          let description = '';

          return (
            <CarouselItem key={key} onClick={() => handleClick(f.experience, buttonLink)}>
              <MediaQuery minWidth={minWidths.tablet}>
                <Banner
                  title={f.name}
                  text={description}
                  imageUrl={imageUrl}
                  buttonText={buttonText}
                  buttonLink={buttonLink}
                  featured={featured}
                  imageClickable
                  carousel
                />
              </MediaQuery>
              <MediaQuery maxWidth={maxWidths.mobileSM}>
                <BannerMobile
                  title={mobileTitle}
                  imageUrl={imageUrl}
                  link={buttonLink}
                  featured={featured}
                  isBundle={f.experience === activateKind.gamePass}
                  carousel
                />
              </MediaQuery>
            </CarouselItem>
          );
        })}
      </Carousel>
    </Featured>
  );
}