import {
  SET_REFERRAL_LINK,
  SET_REFERRAL_CODE,
  SET_REFERRAL_SHARE_MESSAGE,
  SET_REFERRAL_HISTORY,
  SET_REFERRER_DETAILS,
  SET_DISPLAY_TOAST,
  RESET_STATE,
  SHOW_REFERRAL_AD_BANNER
} from '../actions/constants';

const referralState = {
  link: '',
  code: '',
  shareMessage: '',
  history: {},
  toast: undefined,
  referrer: {},
  openBanner: false
};

const referralReducer = (state = referralState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return referralState;
    case SET_REFERRAL_LINK:
      return {
        ...state,
        link: action.referralLink
      };
    case SET_REFERRAL_CODE:
      return {
        ...state,
        code: action.referralCode
      };
    case SET_REFERRAL_SHARE_MESSAGE:
      return {
        ...state,
        shareMessage: action.referralShareMessage
      };
    case SET_REFERRAL_HISTORY:
      return {
        ...state,
        history: action.referralHistory
      };
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case SET_REFERRER_DETAILS:
      return {
        ...state,
        referrer: action.referrerDetails
      };
    case SHOW_REFERRAL_AD_BANNER:
      return {
        ...state,
        openBanner: action.openBanner
      };
    default:
      return state;
  }
}

export default referralReducer;