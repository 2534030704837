import React, { Component } from 'react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { Toast } from '../Commons';
import {
  BuyBoosterModal,
  FloatingIconManager,
  GamePassPlayPopup,
  LayoutContent,
  PageTitle,
  RedirectToMobileAppToast
} from '../Components';
import { HomeFeatured } from './HomeFeatured';
import { HomeSections } from './HomeSections';
import { HomeMarketplace } from './HomeMarketplace';
import { HomeMenuItems } from './HomeMenuItems';
import { HomeCredits } from './HomeCredits';
import { HomeBanners } from './HomeBanners';
import {
  defaultTimeoutMS,
  gamePassPacks,
  gamePassScreenSections,
  mobileAppPromptText,
  onboardingParams,
  storageKeys
} from '../../constants';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import desktopBg from '../../assets/gamepass/bg/home/desktop.jpg';
import mobileBg from '../../assets/gamepass/bg/home/mobile.jpg';
import './Home.scss';

export class Home extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      gameDetails: {},
      toastOpen: false,
      showRedirectoMobileAppToast: false,
      showBuyBoosterModal: false,
      bannerHeight: null
    };
  }

  componentDidMount() {
    const isSwitchToAppHomeShown = Cookies.get(storageKeys.switchToApp.home);
    const isBuyBoosterShown = Cookies.get(storageKeys.isBuyBoosterShown)

    this.props.getHomeCollection();
    this.props.getHomePageBanners();

    if (!this.props.isGuest) {
      this.props.getUserProfile();
    }

    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    const referrer = queryParams.get('ref');
    if (referrer) {
      this.props.getReferrerDetails(referrer);
    }

    if (!isSwitchToAppHomeShown && !this.props.isGuest) {
      const deviceInfo = getDeviceInfo();
      const android = /android/i.test(deviceInfo.os);

      if (android) {
        this.setState({ showRedirectoMobileAppToast: android });
      }
    }

    const isMobileOnboarding = queryParams.get(onboardingParams.isMobileOnboarding);
    if (!isBuyBoosterShown || Boolean(isMobileOnboarding)) {
      this.setState({ showBuyBoosterModal: true });
    }

    if (!this.props.isGuest) {
      localStorage.removeItem(storageKeys.isNew);
      localStorage.removeItem(storageKeys.isTutorial);
      Cookies.remove(storageKeys.isLoggedInTutorial);
    }
  }

  componentDidUpdate(prevProps) {
    const { 
      referrerDetails,
      toast,
      isGuest
    } = this.props;

    if (prevProps.toast !== toast && toast && !toast?.disabled) {
      this.openToast(true);
    }

    if (prevProps.isGuest !== isGuest && !isGuest) {
      this.props.getUserProfile();
    }

    if (prevProps.referrerDetails !== referrerDetails && 
      !isEmpty(referrerDetails) && 
      isGuest) {
      Cookies.remove(storageKeys.isSpinModalShown);
      Cookies.set(storageKeys.referralCode, referrerDetails.referralCode);
    }
  }

  openToast = (toastOpen) => {
    this.props.setGamePassPlayGame(false);
    this.setState({ toastOpen });
  }

  closeToast = () => {
    this.openToast(false);
    setTimeout(() => {
      this.props.setDisplayToast();
    }, defaultTimeoutMS);
  }

  handleGameDetails = (gameDetails) => {
    this.setState({ gameDetails });
  }

  hideRedirectToMobileAppToast = () => {
    this.setState({ showRedirectoMobileAppToast: false });
    Cookies.set(storageKeys.switchToApp.home, true);
  }

  hideBuyBoosterModal = () => {
    this.setState({ showBuyBoosterModal: false });
    Cookies.set(storageKeys.isNewUser, true);
  }

  handleBannerHeight = (height) => {
    this.setState({ bannerHeight: height });
  }

  render() {
    const { 
      sections, 
      menuItems, 
      crypto, 
      isGuest, 
      toast,
      featuredBanner,
      isDesktop
    } = this.props;
    const { 
      gameDetails, 
      toastOpen,
      showRedirectoMobileAppToast,
      showBuyBoosterModal,
      bannerHeight
    } = this.state;
    
    if (sections && !isEmpty(sections)) {
      const homeSections = sections.filter(s => !s.dataset.includes('Featured'));
      const gamePassSections = homeSections.filter(s => {
        return s.dataset === gamePassScreenSections.buyGamePass
          || s.dataset === gamePassScreenSections.playAndEarnGold;
      });
      const gamePassesSection = gamePassSections.find(s => s.dataset === gamePassScreenSections.buyGamePass);
      const goldenVault8XPack = gamePassesSection?.d[0]?.skus?.find(s => s.kind === gamePassPacks.pack8X);
      const otherHomeSections = difference(homeSections, gamePassSections);

      const redirectUri = sessionStorage.getItem(storageKeys.redirectUri);
      const clientId = sessionStorage.getItem(storageKeys.clientId);
      const hasGamePartnerRedirectUri = redirectUri && clientId;

      const sectionBg = isDesktop ? desktopBg : mobileBg;
      const offset = isDesktop ? 20 : 0;

      return (
        <>
          <LayoutContent outerClassName="home-layout-content" innerClassName="home-layout-content-inner">
            <PageTitle title="Home" />
            <div className="home-menu">
              <HomeMenuItems menuItems={menuItems} />
              <HomeCredits crypto={crypto} transparent />
            </div>
            <div className="home-sections-container">
              <img 
                src={sectionBg} 
                alt="gamepass-bg" 
                loading="lazy" 
                className="home-sections-container-bg"
                style={{ height: `calc(100% - ${bannerHeight + offset}px)` }}
              />
              <HomeFeatured
                featuredBanner={featuredBanner}
                selectedItem={goldenVault8XPack}
                handleBannerHeight={this.handleBannerHeight}
                carousel
              />
              <HomeBanners />
              <HomeSections
                sections={gamePassSections}
                isGuest={isGuest}
                handleGameDetails={this.handleGameDetails}
              />
            </div>
            <HomeMarketplace />
            <HomeSections
              sections={otherHomeSections}
              isGuest={isGuest}
              handleGameDetails={this.handleGameDetails}
            />
          </LayoutContent>
          <GamePassPlayPopup {...gameDetails} />
          {!hasGamePartnerRedirectUri &&
            <FloatingIconManager />}
          {toast &&
            <Toast
              toast={toast}
              open={toastOpen}
              hide={this.closeToast}
            />}
          {!isGuest && 
            !isDesktop &&
            <RedirectToMobileAppToast 
              open={showRedirectoMobileAppToast}
              hide={this.hideRedirectToMobileAppToast}
              title={mobileAppPromptText.home}
            />}
          {isGuest &&
            !hasGamePartnerRedirectUri &&
            <BuyBoosterModal open={showBuyBoosterModal} hide={this.hideBuyBoosterModal} />}
        </>
      );
    }

    return null;
  }
}