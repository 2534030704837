export const pinFormInputs = [
  {
    name: 'pin',
    label: 'Enter 6-digit PIN',
    hint: 'Avoid weak combinations like 111111, 123456, or your birthday'
  },
  {
    name: 'confirm',
    label: 'Confirm 6-digit PIN',
    hasErrorMessage: true
  }
];
export const pinTexts = {
  recoveryPopup: {
    title: 'PIN Recovery',
    description: 'We need to do 2-step authentication before resetting your PIN. A 6-digit code will be sent to your mobile number.'
  },
  newPIN: {
    title: 'Set New PIN',
    description: 'Your PIN is the password to your MetaverseGo account. For your account security, never share your PIN with anyone.'
  }
};
export const pinResetFormInputs = [
  { name: 'current', label: 'Current 6-digit PIN' },
  { name: 'new', label: 'New 6-digit PIN', hint: 'Avoid weak combinations like 111111, 123456, or your birthday' },
  { name: 'retype', label: 'Retype 6-digit PIN' }
];
export const pinSimilar = 'Please enter a different PIN from your current PIN';
export const pinSuccess = 'PIN updated successfully';
export const pinError = 'Please enter the correct PIN on both fields';