import React from 'react';
import classNames from 'classnames';
import './Featured.scss';

const Featured = ({ fluid, children }) => (
  <div className={classNames("featured-section", {"featured-section-fluid": fluid})}>
    {children}
  </div>
);

export default Featured;