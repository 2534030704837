import { connect } from 'react-redux';
import { getNFTCollectionDetails } from '../actions';
import { nftCollectionDetailsSelector } from '../selectors';
import { NFTCollection } from '../components';

const mapStateToProps = (state) => ({
  nftCollection: nftCollectionDetailsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getNFTCollectionDetails: (nftId) => dispatch(getNFTCollectionDetails(nftId))
});

const NFTCollectionContainer = connect(mapStateToProps, mapDispatchToProps)(NFTCollection);

export default NFTCollectionContainer;