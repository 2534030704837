import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  ActivateGamePassToast,
  // ActiveGamePasses,
  ActiveGamePassPopup,
  LayoutContent,
  PageTitle,
  Section,
  TransactionDetailsModal,
  WalletConnect,
  WalletCredits
} from '../Components';
import WalletCollectibles from './WalletCollectibles';
import WalletViewAll from './WalletViewAll';
import { WalletTransactions } from './WalletTransactions';
import { WalletTopUpModal } from './WalletTopUpModal';
import { WalletInstructionsModal } from './WalletInstructionsModal';
import { WalletBanners } from './WalletBanners';
import {
  defaultCTATexts,
  defaultTimeoutMS,
  firebaseEvents,
  gamePassText,
  goldBoosterText,
  siteRoutes,
  walletEmpty
} from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { getGoldCoinsValue } from '../../utils/getWalletCredits';
import './Wallet.scss';

export class Wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: {},
      isCollectibles: false,
      showNftDetails: false,
      openTopUp: false,
      showActivateGamePass: false,
      activateGamePassToast: {},
      activeGamePassOpen: false
    };
  }

  componentDidMount() {
    this.props.getUserCollections();
    this.props.getGamePassActiveEntitlements();
    this.props.getUserProfile();
  }

  componentDidUpdate(prevProps) {
    const query = window.location.search;
    if (query.includes('topup') && !prevProps.isTopUpVisible) {
      setTimeout(() => {
        this.props.setTopUpVisible(true);
        window.history.replaceState(null, '', '/wallet');
      }, defaultTimeoutMS);
    }
  }

  componentWillUnmount() {
    this.props.connectFromTopUp('');
    this.props.setPaymentMethods([]);
  }

  filter = (e, category) => {
    const listItems = document.getElementsByClassName('filters-list__item');
    for (let i = 0; i < listItems.length; i++) {
      const li = listItems[i];
      li.classList.remove('active');
    }
    e.target.classList.add('active');
    this.props.getUserCollections(category);
  }

  showNft = () => {
    this.setState({ showNftDetails: true });
  }

  handleSelectedItem = (selectedItem) => {
    this.setState({ selectedItem });
  }

  handleShowCollectibles = (isCollectibles) => {
    this.setState({ isCollectibles });
  }

  handleActivateGamePassToast = (data) => {
    this.setState({ activateGamePassToast: data });
    this.setState({ showActivateGamePass: true });
  }

  hideActivateGamePass = () => {
    this.setState({ showActivateGamePass: false });
  }

  handleActivateGamePass = (sku, quantity) => {
    const itemData = { sku, quantity };
    logFirebaseEventWithTimestamp(
      firebaseEvents.inventoryGamePassActivate, {
        sku_name: this.state.activateGamePassToast.name,
        page_url: window.location.pathname
      });
    this.hideActivateGamePass();
    this.props.activateGamePass(itemData);
  }

  openActiveGamePass = (activeGamePassOpen) => {
    this.setState({ activeGamePassOpen });
  }
  
  handleGamePassClick = () => {
    const { entitlements } = this.props;
    const activeGamePasses = !isEmpty(entitlements) && entitlements.find(e => e.name === gamePassText);
    const activeGoldBoosters = !isEmpty(entitlements) && entitlements.find(e => e.name === goldBoosterText);
    let activeGamePassItems = [];
    let activeGoldBoosterItems = [];
    if (activeGamePasses) activeGamePassItems = activeGamePasses.items;
    if (activeGoldBoosters) activeGoldBoosterItems = activeGoldBoosters.items;

    if ((activeGamePasses &&
      activeGamePassItems.length > 1) ||
      (activeGoldBoosters &&
      activeGoldBoosterItems.length > 1)) {
      this.openActiveGamePass(true);
    }
  }

  render() {
    const {
      collections,
      crypto,
      transactions,
      isTopUpVisible,
      setTopUpVisible,
      entitlements
    } = this.props;
    const { 
      selectedItem,
      isCollectibles,
      showActivateGamePass,
      activateGamePassToast,
      activeGamePassOpen
    } = this.state;

    const goldCoinsValue = getGoldCoinsValue(crypto);
    const activeGamePasses = !isEmpty(entitlements) && entitlements.find(e => e.name === gamePassText);
    const activeGoldBoosters = !isEmpty(entitlements) && entitlements.find(e => e.name === goldBoosterText);
    let activeGamePassItems = [];
    let activeGoldBoosterItems = [];
    if (activeGamePasses) activeGamePassItems = activeGamePasses.items;
    if (activeGoldBoosters) activeGoldBoosterItems = activeGoldBoosters.items;
  
    if (collections) {
      return (
        <>
          <LayoutContent id="scroll" innerClassName="collection-list-inner">
            {isEmpty(selectedItem) && !isCollectibles && <>
              <PageTitle title="Wallet" />
              <WalletCredits crypto={crypto} handleShowTopUp={setTopUpVisible} />
              <WalletConnect />
              <div className="d-flex align-items-start justify-content-start mx-0 px-0">
                <Link to={siteRoutes.rewardsCenter} className="collection-list-redeem mb-0">
                  <div className="collection-list-redeem-rewards"/>
                  <div className="collection-list-redeem-title mt-1">Redeem Rewards</div>
                </Link>
                <Link to="/spend" className="collection-list-buy mb-0">
                  <div className="collection-list-buy-load"/>
                  <div className="collection-list-buy-title mt-1">Load</div>
                </Link>
              </div>
              <div>
                {/* Hide Inventory UI, UI access to inventory and all redirections to it
                  <ActiveGamePasses
                    activeGamePass={activeGamePassItems}
                    activeGoldBooster={activeGoldBoosterItems}
                    handleGamePassClick={this.handleGamePassClick}
                    containerClassName="game-pass-screen-timer"
                    hasHeader
                    inventoryRedirect
                  /> */}
                <WalletBanners goldCoinsValue={goldCoinsValue} />
              </div>
              <Section
                title="Your Collectibles"
                sectionClassName="collection-list-wrapper"
                sectionHeaderClassName="collection-list-header"
              >
                <WalletCollectibles
                  handleSelectedItem={this.handleSelectedItem}
                  collections={collections}
                  walletEmpty={walletEmpty}
                  handleShowCollectibles={this.handleShowCollectibles}
                />
              </Section>
            </>}
            {isCollectibles && <WalletViewAll 
              isCollectibles={isCollectibles}
              handleSelectedItem={this.handleSelectedItem}
              walletEmpty={walletEmpty}
              collections={collections} 
              handleShowCollectibles={this.handleShowCollectibles}
            />}
            {!isCollectibles && 
              isEmpty(selectedItem) && 
              <Section title="Transaction History" sectionClassName="wallet">
                {transactions.length >= 3 && 
                  <Link
                    to="/wallet/transactions"
                    className="collection-list-button collection-list-button-history"
                  >
                    {defaultCTATexts.viewAll}
                  </Link>}
                <WalletTransactions transactions={transactions} />
              </Section>}
          </LayoutContent>
          <WalletTopUpModal openTopUp={isTopUpVisible} hide={() => setTopUpVisible(false)} />
          <WalletInstructionsModal />
          <TransactionDetailsModal />
          <ActivateGamePassToast 
            open={showActivateGamePass}
            hide={this.hideActivateGamePass}
            className="gamepass-activate-toast"
            header
            headerTitle="Activate Game Pass"
            toast={activateGamePassToast}  
            onClick={this.handleActivateGamePass}
          />
          <ActiveGamePassPopup
            open={activeGamePassOpen}
            hide={() => this.openActiveGamePass(false)}
            entitlements={entitlements}
          />
        </>
      );
    }

    return null;
  }
}