import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { LayoutContent, PageTitle, Section } from '../Components';
import { Toast } from '../Commons';
import { 
  getGamePassCartItems, 
  getGamePassCheckout, 
  setDisplayToast, 
  updateGamePassCheckout
} from '../../actions';
import { 
  defaultTimeoutMS, 
  gamePassCheckoutCTA, 
  paymentMethods, 
  storageKeys 
} from '../../constants';
import { 
  cartItemsSelector, 
  displayLoginToastSelector,
  updatedCartItemsSelector 
} from '../../selectors';
import { CartCard } from './CartCard';
import './GamePassCart.scss';

export const GamePassCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [subTotal, setSubTotal] = useState({});
  const [toastOpen, setToastOpen] = useState(false);

  const cartItems = useSelector(state => cartItemsSelector(state));
  const updatedCartItems = useSelector(state => updatedCartItemsSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));

  const pmSourceSessionStorage = sessionStorage.getItem(storageKeys.pmSource);
  const pmSourceCookie = Cookies.get(storageKeys.pmSource);

  useEffect(() => {
    dispatch(getGamePassCartItems());
  }, [dispatch])

  useEffect(() => {
    if (isEmpty(cartItems)) return;
    setItems(cartItems.items);
    setSubTotal(cartItems.subTotal);
  }, [cartItems])

  useEffect(() => {
    if (isEmpty(updatedCartItems)) return;
    setItems(updatedCartItems.items);
    setSubTotal(updatedCartItems.subTotal);
  }, [updatedCartItems])

  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const handleCheckout = () => {
    navigate(gamePassCheckoutCTA);
    if (pmSourceSessionStorage && pmSourceCookie) {
      dispatch(updateGamePassCheckout({ 
        selectedPaymentMethod: paymentMethods.gcash, 
        navigate,
        shouldNotBack: true
      }));
      return;
    }
    dispatch(getGamePassCheckout());
  }

  return (
    <LayoutContent 
      outerClassName="gamepass-cart" 
      innerClassName="gamepass-cart-inner" 
    >
      <PageTitle title="Cart"/>
      <Section
        sectionClassName="gamepass-cart-section"
        sectionHeaderClassName="w-100"
        title="My Cart"
        text="Back"
        titleCentered
        backButtonRoute
        backRoute={() => navigate(-1)}
      >
        {isEmpty(items) ? (
          <div className="gamepass-cart-empty">
            <div>You have no items in cart yet</div>
          </div> 
        ) : (
          <div className="gamepass-cart-container">
            <div className="w-100">
              {items.map((c, cIdx) => (
                <CartCard key={`CART_ITEM_KEY_${cIdx}`} item={c} />
              ))}
            </div>
            <div className="gamepass-cart-footer">
              <div className="gamepass-cart-footer-amount mb-3">
                <div>Subtotal</div>
                {subTotal && <div>{subTotal.text}</div>}
              </div>
              <button 
                onClick={handleCheckout}
                className="gamepass-cart-footer-button">
                Checkout
              </button>
            </div>
          </div>
        )}
      </Section>
      {toast && <Toast toast={toast} open={toastOpen} hide={closeToast} />}   
    </LayoutContent>
  )
}
