import React from 'react';
import classNames from 'classnames';
import { emptyReferralHistory, referralStatus } from '../../constants';
import './ReferralScreen.scss';

export const ReferralHistory = ({ list }) => (
  <>
    {list && (
      list.length > 0 ?
        <div className="referral-screen-history-list">
          {list.map((l, lIdx) => {
            let text = '';
            if (l.status === referralStatus.invited) {
              text = l?.mobnum;
            } else {
              text = l?.userName;
            }

            return (
              <div
                key={`ReferralHistoryItem_${lIdx}_${l.id}`}
                className="referral-screen-history-list-item"
              >
                <div className="referral-screen-history-list-item-details">
                  <p className="referral-screen-history-list-item-details-name">{text}</p>
                  {l.status === referralStatus.success &&
                    <p className="referral-screen-history-list-item-details-date">{l?.details}</p>}
                </div>
                <div className="referral-screen-history-list-item-status">
                  <div className={classNames(
                    "referral-screen-history-list-item-status-text",
                    `referral-screen-history-list-item-status-text-${l.status.toLowerCase()}`
                  )}>
                    {l.statusText}
                  </div>
                </div>
              </div>
            );
          })}
        </div> :
        <div className="referral-screen-history-empty">
          {emptyReferralHistory}
        </div>
    )}
  </>
);