import { connect } from 'react-redux';
import { setLoading } from '../actions';
import { securityMenuItems } from '../constants';
import { Security } from '../components';

const mapStateToProps = () => ({
  securityMenuItems
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

const SecurityContainer = connect(mapStateToProps, mapDispatchToProps)(Security);

export default SecurityContainer;