import React from 'react';
import { LegalStatement } from '../Components';
import { fairPlayPolicy } from '../../constants';

export const FairPlayPolicy = () => (
  <LegalStatement
    pageTitle="Fair Play Policy"
    type="FairPlayPolicy"
    content={fairPlayPolicy}
  />
);