import React from 'react';
import LayoutContent from '../LayoutContent/LayoutContent';
import PageTitle from '../PageTitle/PageTitle';
import Section from '../Section/Section';
import { Grid } from '../../Commons';
import './TournamentGrid.scss';

const TournamentGrid = ({ dataset, experience, d, className }) => {
  let experienceName = `${experience.toLowerCase()}s`;
  if (experience === 'f2p') experienceName = experience.toLowerCase();
  else if (dataset.includes('Premium')) experienceName = dataset.split(' ')[0].toLowerCase();

  return (
    <LayoutContent outerClassName={className}>
      <PageTitle title={dataset} />
      <Section title={dataset} sectionClassName={`${className}-section`} backButtonRoute="/" titleCentered>
        <Grid items={d} keyPrefix="Tournaments" className={`${className}-grid`} experience={experienceName} />
      </Section>
    </LayoutContent>
  );
}

export default TournamentGrid;