import React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import './OrderSummary.scss';

const OrderSummary = ({ open, summary, accept, hide }) => (
  <Modal
    backdrop
    show={open}
    className="order-summary-container"
    contentClassName="order-summary"
    dialogClassName="order-summary-dialog"
    backdropClassName="order-summary-bg"
    onHide={hide}
    centered
  >
    <Modal.Header closeButton closeVariant="white">Order Summary</Modal.Header>
    <Modal.Body>
      <div className="order-summary-table">
        {summary.map(s => (
          <div key={`OrderSummary_${s.label}`} className="order-summary-table-row">
            <div className={classNames("order-summary-table-cell", "order-summary-table-label")}>{s.label}</div>
            <div className={classNames("order-summary-table-cell", "order-summary-table-value", {
              "order-summary-table-value-large": s.label === 'MGC'
            })}>
              {s.value}
              {s.label === 'MGC' && <div className="order-summary-mgo-coin"></div>}
            </div>
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button onClick={hide} className="order-summary-cancel-button">Cancel</button>
      <button onClick={accept} className="order-summary-accept-button">Accept</button>
    </Modal.Footer>
  </Modal>
);

export default OrderSummary;