import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import './Chat.scss';

const ChatMessage = ({ id, name, imgUrl, message, timestamp, read, goToMessage, markAsRead }) => {
  const dateTimeNow = DateTime.now();
  const dateTimeLatest = DateTime.fromISO(timestamp);
  const dateTimeDiff = dateTimeNow.diff(dateTimeLatest, ['days', 'hours', 'minutes', 'seconds', 'milliseconds']);
  const dateTimeDiffResult = dateTimeDiff.toObject();
  const { days, hours, minutes, seconds } = dateTimeDiffResult;

  let date = dateTimeLatest.toFormat('LL/dd/yy');
  if (days < 1) {
    if (hours > 0) {
      date = `${hours} hr ago`;
    } else {
      if (minutes > 0) date = `${minutes} min ago`;
      else date = `${seconds} sec ago`;
    }
  }

  const stripHtmlTags = (str) => {
    if (!str)
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }

  return (
    <div className="chat-message">
      <Link to={`/messages/${id}`} className={classNames("chat-message-link", {"chat-message-link-read": !read})} onClick={(e) => goToMessage(e)}>
        <div className="chat-message-profile-pic" style={{ backgroundImage: `url(${imgUrl})` }}></div>
        <div className="chat-message-details">
          <div className="chat-message-sender">
            <div className="chat-message-sender-name">{name}</div>
            <div className="chat-message-date">
              <span className="chat-message-bullet">&bull;</span>{date}
            </div>
          </div>
          <div className={classNames("chat-message-latest-message", {"chat-message-latest-message-read": !read})}>{message && stripHtmlTags(message)}</div>
        </div>
      </Link>
      {
        !read &&
        <div className="chat-message-read">
          <span className="chat-message-read-button" onClick={() => markAsRead(id)}></span>
        </div>
      }
    </div>
  );
}

export default ChatMessage;