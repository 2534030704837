import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { mgcCurrency } from '../../../constants';
import { handleGamePassAmount } from '../../../utils/handleAmount';
import './GamePassProductAmount.scss';

const GamePassProductAmount = ({ defaultPrice, alternativePrice, priceLC, isMarketplace, fontLarge }) => {
  let amount = '';
  if (!isEmpty(defaultPrice)) {
    amount = handleGamePassAmount(defaultPrice);
  } else if (!isEmpty(priceLC) && 
    isMarketplace) {
    const newPrice = priceLC.split(' ');
    const fallbackPrice = {
      value: newPrice[0],
      currency: newPrice[1]
    }
    amount = handleGamePassAmount(fallbackPrice);
  }

  let convertedAmount = '';
  if (!isEmpty(alternativePrice)) {
    convertedAmount = handleGamePassAmount(alternativePrice);
  }

  return (
    <div className={classNames(
      "game-pass-product-amount", {
        "game-pass-product-amount-medium": isMarketplace,
        "game-pass-product-amount-large": fontLarge
      }
    )}>
      {((!isEmpty(defaultPrice) &&
        defaultPrice?.currency === mgcCurrency) ||
        (isEmpty(defaultPrice) && 
        !isEmpty(priceLC) && 
        priceLC.includes(mgcCurrency))) &&
        <div className={classNames(
          "game-pass-product-amount-icon",
          {"game-pass-product-amount-icon-large": fontLarge}
        )} />}
      <span className="game-pass-product-amount-price">{amount}</span>
      {!isEmpty(convertedAmount) &&
        <span className="game-pass-product-amount-converted">
          (
            {!isEmpty(alternativePrice) &&
              alternativePrice?.currency === mgcCurrency &&
              <div className={classNames(
                "game-pass-product-amount-icon", 
                {"game-pass-product-amount-icon-large": fontLarge}
              )} />}
            <span>{convertedAmount}</span>
          )
        </span>}
    </div>
  );
}

export default GamePassProductAmount;