import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { load } from 'recaptcha-v3';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { GamePassCheckoutCheckbox } from './GamePassCheckoutCheckbox';
import { GamePassCheckoutForm } from './GamePassCheckoutForm';
import { ModalContent } from './ModalContent';
import { 
  checkoutButtonTexts,
  defaultQuantity,
  firebaseEvents,
  onboardingTexts,
  paymentMethods,
  recaptchaSiteKey,
  siteRoutes
} from '../../../constants';
import {
  setDisplayToast,
  setGamePassCheckoutForm,
  setLoading,
  submitLogin
} from '../../../actions';
import { getDeviceInfo } from '../../../utils/getDeviceInfo';
import { logFirebaseEvent } from '../../../utils/logFirebaseEvent';
import { 
  displayLoginToastSelector,
  gamePassCheckoutFormSelector,
  loadingSelector,
  showPaymentMethodsSelector
} from '../../../selectors';
import './GamePassCheckoutModal.scss';

export const GamePassCheckoutContents = ({ 
  countryCode,
  setCountryCode,
  handleSelectPaymentMethod,
  checked,
  setChecked,
  checkoutContents,
  hideEmail,
  errors,
  setErrors,
  remoteConfigKey
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [recaptcha, setRecaptcha] = useState({});
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  const showPaymentMethods = useSelector(state => showPaymentMethodsSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const checkoutForm = useSelector(state => gamePassCheckoutFormSelector(state));
  const loading = useSelector(state => loadingSelector(state));

  useEffect(() => {
    if (isEmpty(checkoutContents)) return;
    for (const paymentMethod of checkoutContents?.acceptedPaymentMethods) {
      if (checkoutContents?.selectedPaymentMethod === paymentMethod.code) {
        setSelectedPaymentMethod(paymentMethod);
      }
    }
  }, [checkoutContents])

  const getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        setRecaptcha(recaptcha);
        setIsRecaptchaLoaded(true);
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  useEffect(() => {
    getRecaptcha();
    return () => setChecked(false);
  }, [setChecked])

  useEffect(() => {
    setErrors(prevErrors => ({
      ...prevErrors,
      termsAndConditions: checked ? false : prevErrors.termsAndConditions,
      paymentMethod: !isEmpty(selectedPaymentMethod) ? false : prevErrors.paymentMethod
    }));
  }, [checked, selectedPaymentMethod, setErrors])

  const handleCheckoutPay = () => {
    if (!loading) {
      const { selectedPaymentMethod, summary, items } = checkoutContents;
      const errors = {};
      if (isEmpty(selectedPaymentMethod) && !checked) {
        errors.paymentMethod = true;
        errors.termsAndConditions = true;
      } else if (!checked) {
        errors.paymentMethod = false;
        errors.termsAndConditions = true;
      } else if (isEmpty(selectedPaymentMethod)){
        errors.paymentMethod = true;
        errors.termsAndConditions = false;
      }
      setErrors(errors);
      if (!isEmpty(errors)) {
        return;
      }
      const paymentUrl = `/payment/card?channel=${selectedPaymentMethod}&amount=${summary?.subTotal?.value}&currency=${summary?.subTotal?.currency}`;
      const skuId = items[0]?.skuId;
      const productId = items[0]?.productId;
      const formData = {
        mobileNumber: `${countryCode}${checkoutForm?.mobileNumber}`,
        deviceInfo: getDeviceInfo(),
        from: onboardingTexts.onboarding,
        checkoutData: {
          skuId,
          productId,
          quantity: defaultQuantity,
          mobnum: `${countryCode}${checkoutForm?.mobileNumber}`,
          selectedPaymentMethod: selectedPaymentMethod,
          amount: summary?.subTotal?.value,
          currency: summary?.subTotal?.currency
        },
        navigate
      };
      if (checkoutForm?.email) {
        formData.email = checkoutForm?.email;
      }
      const paymentState = { 
        paymentData: formData.checkoutData,
        from: formData.from,
        deviceInfo: formData.deviceInfo,
        mobileNumber: formData.mobileNumber
      };
      dispatch(setLoading(true));
      if (isRecaptchaLoaded) {
        recaptcha.execute('onboardingLogin')
          .then(t => {
            const updatedFormData = {
              ...formData,
              recaptchaToken: t
            };
            const updatedPaymentState = {
              ...paymentState,
              recaptchaToken: updatedFormData.recaptchaToken
            };
            navigateToNextPage(selectedPaymentMethod, paymentUrl, updatedFormData, updatedPaymentState);
          })
          .catch(error => {
            logFirebaseEvent(
              firebaseEvents.failedEvent, {
                error_message: error
              });
            navigateToNextPage(selectedPaymentMethod, paymentUrl, formData, paymentState);
          });
      } else {
        navigateToNextPage(selectedPaymentMethod, paymentUrl, formData, paymentState);
      }
    }
  }

  const navigateToNextPage = (paymentMethod, paymentUrl, formData, state) => {
    if (paymentMethod === paymentMethods.ccdc) {
      navigate(paymentUrl, { state });
    } else {
      dispatch(submitLogin(formData));
    }
  }

  const handleInputChange = (inputName, inputValue) => {
    dispatch(
      setGamePassCheckoutForm({
        ...checkoutForm,
        [inputName]: inputValue
      })
    );
    dispatch(setDisplayToast());
  };

  let buttonText = checkoutButtonTexts.registerAndPay;
  if (window.location.pathname.includes(siteRoutes.referral)) {
    buttonText = checkoutButtonTexts.pay;
  }

  return (
    <ModalContent
      checkoutContents={checkoutContents}
      errors={errors}
      showPaymentMethods={showPaymentMethods}
      selectedPaymentMethod={selectedPaymentMethod}
      handleSelectPaymentMethod={handleSelectPaymentMethod}
      buttonClassName={classNames(
        "game-pass-checkout-button",
        {"game-pass-checkout-button-disabled": !checkoutForm?.mobileNumber || loading}
      )}
      buttonText={`${buttonText} ${checkoutContents?.summary?.subTotal?.text}`}
      handleCheckoutPay={handleCheckoutPay}
      checkoutForm={
        <GamePassCheckoutForm
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          hideEmail={hideEmail}
          toast={toast}
          handleInputChange={handleInputChange}
          handleCheckoutPay={handleCheckoutPay}
          checkoutForm={checkoutForm}
          remoteConfigKey={remoteConfigKey}
        />
      }
      checkoutCheckbox={
        <GamePassCheckoutCheckbox 
          checked={checked}
          setChecked={setChecked} 
          hasError={errors?.termsAndConditions}
        />
      }
    />
  );
}
