import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DiscordDailyBoostModal from '../DiscordDailyBoostModal/DiscordDailyBoostModal';
import { activeModalSelector } from '../../../selectors';
import { defaultModals } from '../../../constants';
import { showActiveModal } from '../../../actions';

const ModalManager = () => {
  const dispatch = useDispatch();
  const activeModal = useSelector(state => activeModalSelector(state));

  const hideShownModal = () => {
    dispatch(showActiveModal(''));
  }

  return (
    <Fragment>
      <DiscordDailyBoostModal 
        open={activeModal === defaultModals.goldBonanza} 
        hide={hideShownModal} 
      />
    </Fragment>
  )
}

export default ModalManager;