import React, { useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { minWidths } from '../../../constants';
import './Tags.scss';

export const Tags = ({ name, tags, maxTags }) => {
  const [isMoreTagsOpen, openMoreTags] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  
  const onClick = () => isDesktop ? null : openMoreTags(!isMoreTagsOpen);

  return (
    <div className="tags-component-tags">
      {tags.slice(0, maxTags).map(t => <div key={`${name}_${t}`} className="tags-component-tag">{t}</div>)}
      {tags.length > maxTags && <Tooltip
        open={isMoreTagsOpen}
        onOpen={() => openMoreTags(true)}
        onClose={() => openMoreTags(false)}
        title={<div className="tags-component-tooltip">
          {tags.slice(maxTags).map(t => (
            <div
              key={`${name}_${t}`}
              className={classNames("tags-component-tag", "tags-component-tag-fluid")}
            >{t}</div>
          ))}</div>}
        TransitionComponent={Fade}
        classes={{ tooltip: "tags-component-tooltip-inner" }}
      >
        <div
          className={classNames("tags-component-tag", "tags-component-tag-more")}
          onClick={onClick}
        >+{tags.length - maxTags}</div>
      </Tooltip>}
    </div>
  );
}