import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ref, update } from 'firebase/database';
import { database } from '../utils/firebase';
import { 
  requestUserCommunities,
  requestUserCommunity,
  requestGuildApplicationForm,
  requestSubmitGuildApplicationForm
} from '../api/communities';
import {
  GET_USER_COMMUNITIES,
  GET_USER_COMMUNITY,
  GET_GUILD_APPLICATION_FORM,
  SUBMIT_GUILD_APPLICATION_FORM
} from '../actions/constants';
import {
  setLoading,
  setUserCommunities,
  setUserCommunity,
  setGuildApplicationForm,
  setDisplayToast,
  failedRequests
} from '../actions';
import { firebaseEvents, storageKeys } from '../constants';
import { logFirebaseEvent, logFirebaseEventWithTimestamp } from '../utils/logFirebaseEvent';

const getUserCommunitiesSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserCommunities, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setUserCommunities(response.data.sections));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserCommunitySaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserCommunity, payload.userCommunityId, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setUserCommunity(response.data.d));
    }
    if (payload.setLoading) {
      yield put(setLoading(false));
    }
  } catch (e) {
    if (payload.setLoading) {
      yield put(setLoading(false));
    }
    if (e.response) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGuildApplicationFormSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGuildApplicationForm, payload.userCommunityId, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const form = response.data.d;
      form.fields.map(f => {
        f.updated = false;
        return f;
      });
      yield put(setGuildApplicationForm(form));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitGuildApplicationFormSaga = function* (payload) {
  try {
    const { guildId, form, userId, newProfile } = payload;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const body = { answers: form, threadId: newProfile.id };
    const response = yield call(requestSubmitGuildApplicationForm, guildId, body, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      // update user's profile in chat reference
      const newProfileRef = ref(database, `chatReference/${userId}/${newProfile.id}`);
      update(newProfileRef, newProfile);
      logFirebaseEvent(
        firebaseEvents.applyForScholarship, {
          username: form[0], 
          guild_id: guildId, 
          guild_name: newProfile.name
        });
      logFirebaseEventWithTimestamp(
        firebaseEvents.guildSubmitScholarshipSuccess, {
          guild_name: newProfile.name
        });
      // set display toast
      yield put(setDisplayToast(response.data));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* communitySaga() {
  yield all([
    takeLatest(GET_USER_COMMUNITIES, getUserCommunitiesSaga),
    takeLatest(GET_USER_COMMUNITY, getUserCommunitySaga),
    takeLatest(GET_GUILD_APPLICATION_FORM, getGuildApplicationFormSaga),
    takeLatest(SUBMIT_GUILD_APPLICATION_FORM, submitGuildApplicationFormSaga),
  ]);
}