import { END, eventChannel } from 'redux-saga';
import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { 
  GET_USER_TRANSACTION_DATA,
  GET_USER_TRANSACTIONS, 
  GET_USER_TRANSACTIONS_DETAILS 
} from '../actions/constants';
import { 
  requestUserTransactionData, 
  requestUserTransactions, 
  requestUserTransactionsDetails 
} from '../api/transactions';
import {
  responseStatus,
  status,
  storageKeys
} from '../constants';
import { 
  setGamePassTransactionIsInTimeLimit, 
  setLoading, 
  setUserTransactionData,
  setUserTransactions, 
  setUserTransactionDetails, 
  setUserTransactionsSize, 
  failedRequests
} from '../actions';

const getUserTransactionDataSaga = function* (payload) {
  yield put(setGamePassTransactionIsInTimeLimit(false));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    let response;
    let requestCounter = 0;

    while (requestCounter < 3) {
      response = yield call(requestUserTransactionData, payload.payload, sessionToken, 'v2');

      if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
        yield put(setUserTransactionData(response.data.d));
        yield put(setGamePassTransactionIsInTimeLimit(false));

        if (response.data.d.status !== status.pending) {
          break;
        }
      }

      yield delay(3000);
      requestCounter++;
    }
    
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setUserTransactionData(response.data.d));
      yield put(setGamePassTransactionIsInTimeLimit(true));
    }

    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserTransactionsSaga = function* (payload) {
  yield put(setUserTransactionsSize({ loading: true }));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserTransactions, payload.payload, sessionToken, 'v2');
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setUserTransactions(response.data.d));
      yield put(setUserTransactionsSize({
        loading: true,
        size: response.data.size
      }));
    }
    yield put(setUserTransactionsSize({ loading: false }));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setUserTransactionsSize({ loading: false }));
    yield put(setLoading(false));
    if (e.response) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserTransactionDetailsSaga = function* (payload) {
  yield put(setUserTransactionDetails({}));
  yield put(setLoading(true));
  const { id, referenceId } = payload.payload;
  const newReferenceId = !isEmpty(referenceId) ? `&referenceId=${referenceId}` : '';
  
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserTransactionsDetails, id, newReferenceId, sessionToken, 'v2');
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setUserTransactionDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* transactionsSaga() {
  yield all([
    takeLatest(GET_USER_TRANSACTION_DATA, getUserTransactionDataSaga),
    takeLatest(GET_USER_TRANSACTIONS, getUserTransactionsSaga),
    takeLatest(GET_USER_TRANSACTIONS_DETAILS, getUserTransactionDetailsSaga)
  ]);
}