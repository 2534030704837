import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { GamePassInventoryCoins } from '../Components';
import {
  bannerDimensions,
  minWidths,
  referralBannerText,
  siteRoutes
} from '../../constants';
import referralTextDesktop from '../../assets/referral/wallet/text/desktop.png';
import referralTextMobile from '../../assets/referral/wallet/text/mobile.png';

export const WalletBanners = ({ goldCoinsValue }) => {
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  let referralText = referralTextMobile;
  let referralTextDimensions = bannerDimensions.walletReferralBannerText.mobile;
  if (isDesktop) {
    referralText = referralTextDesktop;
    referralTextDimensions = bannerDimensions.walletReferralBannerText.desktop;
  }

  return (
    <div className="collection-list-banner">
      <GamePassInventoryCoins coins={goldCoinsValue} className="game-pass-inventory-coins-wallet" />
      <Link to={siteRoutes.referral} className="collection-list-banner-referral">
        <div className="collection-list-banner-referral-inner">
          <img
            src={referralText}
            alt="Refer a Friend"
            className="collection-list-banner-referral-text"
            width={referralTextDimensions.width}
            height={referralTextDimensions.height}
          />
          <button className="collection-list-banner-referral-button">{referralBannerText.inviteNow}</button>
        </div>
      </Link>
    </div>
  )
}
