import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import classNames from 'classnames';
import countryCodes from 'country-codes-list';
import { 
  absoluteValue,
  billingType,
  countryCode, 
  defaultCountry, 
  defaultMobileNumberPrefix, 
  formInputKinds, 
  primaryType, 
  regexConstants, 
  usdCurrency
} from '../../constants';
import { 
  createPayment, 
  payGamePassOnCheckout, 
  setLoading, 
  submitLogin
} from '../../actions';

export const CardPaymentForm = ({ 
  description, 
  fields,
  amount,
  channel,
  currency,
  from
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [country, setCountry] = useState([]);
  const [validation, setValidation] = useState({});

  const countryCodesList = countryCodes.customArray({
    name: '{countryNameEn}',
    code: '{countryCode}',
    value: '+{countryCallingCode}'
  });

  useEffect(() => {
    let countryArr = [];
    for (const item of countryCodesList) {
      countryArr.push({
        value: item.code,
        label: item.name,
        number: item.value
      });
    }
    setCountry(countryArr);
  }, [])

  const defineValidation = (field) => {
    const shouldBeObject = {
      billing_country: Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
        number: Yup.string().required(),
      })
    };

    if (shouldBeObject[field]) {
      return shouldBeObject[field];
    }

    if (field === billingType.billing_phone || field === billingType.billing_zip) {
      return Yup.string().required().matches(regexConstants.numbersOnly);
    }

    if (field === billingType.billing_email) {
      return Yup.string().required().matches(regexConstants.email);
    }

    return Yup.string().required();
  }

  const form = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: (val, { setSubmitting }) => {
      dispatch(setLoading(true));
      const customerData = {
        email: val.billing_email,
        address: val.billing_address,
        firstName: val.billing_first_name,
        lastName: val.billing_last_name,
        country: val.billing_country.value,
        city: val.billing_city,
        state: val.billing_state,
        zipCode: val.billing_zip,
        phoneNumber: `${Number(absoluteValue(val.billing_country.number))}${val.billing_phone}`,
      };
      const paymentData = {
        fromCurrency: usdCurrency,
        toAmount: amount,
        channelCode: channel,
        customer: { ...customerData }
      };

      const initialFormData = {
        mobileNumber: `${val.billing_country.number}${val.billing_phone}`,
        deviceInfo: location?.state?.deviceInfo,
        from: location?.state?.from,
        checkoutData: location?.state?.paymentData,
        customer: customerData,
        navigate
      };
      let formData = initialFormData;
      if (location?.state?.recaptchaToken) {
        formData = {
          ...initialFormData,
          recaptchaToken: location?.state?.recaptchaToken
        };
      }

      if (from !== primaryType.topup) {
        if (!isEmpty(location?.state?.paymentData)) {
          dispatch(submitLogin(formData))
        } else {
          dispatch(payGamePassOnCheckout({ customer: customerData }));
        }
      } else {
        dispatch(createPayment({ ...paymentData }));
      }
      setSubmitting(false);
    }
  })

  useEffect(() => {
    const validation = {};
    const data = {};

    fields?.forEach((field) => {
      if (field.key === billingType.billing_country) {
        data.billing_country = {
          value: countryCode,
          label: defaultCountry,
          number: defaultMobileNumberPrefix
        };
      } else {
        data[field.key] = '';
      }

      if (field.isRequired) {
        validation[field.key] = defineValidation(field.key);
      }
    })
    
    form.setValues(data);
    setValidation(validation);
  }, [fields])

  const {
    values,
    errors,
    handleBlur, 
    handleSubmit, 
    handleChange, 
    touched,
    isSubmitting
  } = form;

  return (
    <div className="card-payment-container">
      <div className="card-payment-wrapper">
        <div className="card-payment-description">{description}</div>
        <form onSubmit={handleSubmit}>
          {fields?.map((f, fIdx) => (
            <div key={fIdx} className="card-payment-form">
              <div className="card-payment-form-title">
                {f.title} {f.isRequired && <span>*</span>}
              </div>
              {f.inputKind === formInputKinds.country ? (
                <Select 
                  classNamePrefix='card-payment-filter' 
                  options={country} 
                  name={f.key}
                  value={values.billing_country} 
                  onChange={(val) => form.setFieldValue(f.key, val)}
                  onBlur={handleBlur}
                  style={{ outline: 'none' }}
                />
              ) : (
                <div className={classNames(
                  "card-payment-form-input-wrapper",
                  {"card-payment-form-input-required": touched[f.key] && errors[f.key]}
                )}>
                  {formInputKinds.phone === f.inputKind && 
                    <div className="card-payment-form-input-country">{values?.billing_country?.number}</div>}
                  <input
                    type="text"
                    name={f.key}
                    className={classNames(
                      "card-payment-form-input",
                      {"card-payment-form-input-phone": formInputKinds.phone === f.inputKind},
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              )}
            </div>
          ))}
          <button
            className="card-payment-button"
            type="submit"
            disabled={isSubmitting}
          >
            Pay {amount} {currency}
          </button>
        </form>
      </div>
    </div>
  )
}
