import { 
  RESET_STATE,
  SET_BARCODE_VISIBLE,
  SET_CLEAR_USER_TRANSACTIONS,
  SET_INSTRUCTIONS_VISIBLE, 
  SET_SELECTED_METHOD, 
  SET_TOPUP_VISIBLE, 
  SET_TRANSACTION_ACTIVE_TAB, 
  SET_TRANSACTION_VISIBLE, 
  SET_USER_TRANSACTIONS, 
  SET_USER_TRANSACTIONS_DETAILS, 
  SET_USER_TRANSACTIONS_SIZE, 
  SET_USER_TRANSACTION_DATA
} from "../actions/constants";

const transactionsState = {
  isTopUpVisible: false,
  isInstructionsVisible: false,
  isBarcodeVisible: false,
  isTransactionVisible: false,
  selectedMethod: {},
  data: {},
  transactions: [],
  transactionDetails: {},
  size: {},
  activeTab: 1
};
  
const transactionsReducer = (state = transactionsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return transactionsState;
    case SET_TOPUP_VISIBLE:
      return {
        ...state,
        isTopUpVisible: action.isTopUpVisible
      };
    case SET_INSTRUCTIONS_VISIBLE:
      return {
        ...state,
        isInstructionsVisible: action.isInstructionsVisible
      };
    case SET_BARCODE_VISIBLE:
      return {
        ...state,
        isBarcodeVisible: action.isBarcodeVisible
      };
    case SET_TRANSACTION_VISIBLE:
      return {
        ...state,
        isTransactionVisible: action.isTransactionVisible
      };
    case SET_SELECTED_METHOD:
      return {
        ...state,
        selectedMethod: action.selectedMethod
      };
    case SET_USER_TRANSACTION_DATA:
      return {
        ...state,
        data: action.data
      };
    case SET_USER_TRANSACTIONS:
      return {
        ...state,
        transactions: [...state.transactions, ...action.transactions]
      };
    case SET_USER_TRANSACTIONS_SIZE:
      return {
        ...state,
        size: action.size
      };
    case SET_CLEAR_USER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions
      };
    case SET_USER_TRANSACTIONS_DETAILS:
      return {
        ...state,
        transactionDetails: action.transactionDetails
      };
    case SET_TRANSACTION_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    default:
      return state;
  }
}

export default transactionsReducer;