import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { BlurBackgroundModal } from '../Commons';
import { 
  duration,
  loginTexts, 
  otpDefaultTimes, 
  otpMaxLength, 
  regexConstants 
} from '../../constants';
import { 
  displayLoginToastSelector, 
  emailLoginOTPTimerActiveSelector, 
  loadingSelector, 
  loginDataSelector, 
  loginOTPTimerActiveSelector,
  rewardIsClaimedSelector
} from '../../selectors';
import { 
  getRewardOTP,
  redeemReward,
  setDisplayToast,
  setLoading,
  startLoginOTPTimer
} from '../../actions';
import './OTPModal.scss';

export const RewardsOTPModal = ({ 
  open, 
  hide, 
  rewardDetails, 
  formErrors, 
  formFields 
}) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const timerActive = useSelector(state => loginOTPTimerActiveSelector(state));
  const emailTimerActive = useSelector(state => emailLoginOTPTimerActiveSelector(state));
  const loginData = useSelector(state => loginDataSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const loading = useSelector(state => loadingSelector(state));
  const isClaimed = useSelector(state => rewardIsClaimedSelector(state));

  useEffect(() => {
    if (timerActive) {
      setMinutes(otpDefaultTimes.minutes);
      setSeconds(otpDefaultTimes.seconds);
    }
  }, [timerActive])

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            dispatch(startLoginOTPTimer(false));
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, duration.oneSecond);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, timerActive, minutes, seconds]);

  const formatTime = (minutes, seconds, resendViaEmail) => {
    const prefix = resendViaEmail ? loginTexts.otp.resendCodeViaEmail : loginTexts.otp.resendCodeIn;
    const minutesString = minutes?.toString();
    const secondsString = seconds?.toString();
    if (typeof minutesString === 'string' && 
      typeof secondsString === 'string') {
      return `${prefix} ${minutesString.padStart(2, '0')}:${secondsString.padStart(2, '0')}`;
    }
  }

  const submitOTP = () => {
    if (loading) {
      return;
    }
    const payload = {
      id: rewardDetails?.id,
      otp
    };
    if (rewardDetails?.claimForm &&
      !isEmpty(rewardDetails?.claimForm) &&
      isEmpty(formErrors)) {
      payload.claimForm = formFields?.map(f => (
        pick(f, Object.keys(f).filter(k => k !== 'required')))
      );
    }
    dispatch(redeemReward(payload));
  }

  useEffect(() => {
    if (otp?.length === otpMaxLength) {
      submitOTP();
    }
  }, [otp])

  useEffect(() => {
    return () => {
      setOtp('');
    }
  }, [])

  const handleResendOTP = () => {
    if (loading) {
      return;
    }
    const otpObj = { mobnum: loginData?.mobnum, id: rewardDetails?.id };
    dispatch(setLoading(true));
    dispatch(setDisplayToast());
    dispatch(getRewardOTP(otpObj));
  }

  const formatPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber?.replace(regexConstants.formattedPhoneNumber, '$1$2 $3 $4');
    return formattedPhoneNumber;
  }

  const handleChange = (otp) => {
    dispatch(setDisplayToast());
    setOtp(otp);
  }

  const title = `${loginTexts.otp.newTitle} ${formatPhoneNumber(loginData.mobnum)}`;

  return (
    <BlurBackgroundModal
      open={open}
      title={title}
      hide={hide}
    >
      <div className="otp-auth">
        <div className="otp-auth-input-text">{loginTexts.otp.newDescription}</div>
        <div className={classNames(
          "otp-auth-input",
          {"otp-auth-input-error": toast && !isClaimed}
        )}>
          <OtpInput
            shouldAutoFocus={true}
            numInputs={otpMaxLength}
            onChange={otp => handleChange(otp)}
            value={otp}
            containerStyle="otp-auth-input-grid"
            inputStyle={classNames(
              "otp-auth-digit",
              {"otp-auth-digit-error": toast}
            )}
            focusStyle="otp-auth-digit-focused"
            isInputNum={true}
            placeholder="——————"
          />
        </div>
        {toast &&
          !isClaimed &&
          <div className="otp-auth-error">{toast?.message}</div>}
        {minutes === 0 && seconds === 0 ? 
          <div className="otp-auth-text-wrapper">
            <div className="otp-auth-text" onClick={handleResendOTP}>
              {loginTexts.otp.resendCode}
            </div>
          </div> : 
          <div className="otp-auth-text-wrapper">{formatTime(minutes, seconds)}</div>}
      </div>
    </BlurBackgroundModal>
  );
}