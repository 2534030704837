import axios from 'axios';

export function requestChatMessages(url) {
  return axios.request({
    method: 'GET',
    url
  });
}

export function requestChatMessageThread(url, threadId) {
  return axios.request({
    method: 'GET',
    url
  });
}

export function requestClaimScholarship(url, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url
  });
}