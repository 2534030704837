import { operatingSystems, browsers } from '../constants';

export const getDeviceInfo = () => {
  const module = {
    options: [],
    header: [
      navigator.userAgent,
      navigator.vendor,
      window.opera
    ],
    dataos: operatingSystems,
    databrowser: browsers,
    init: function () {
      const agent = this.header.join(' '),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);
      return { os, browser, tz: (new Date()).getTimezoneOffset()/60 };
    },
    matchItem: function (string, data) {
      let i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version;
      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i')
        match = regex.test(string)
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) {
            if (matches[1]) matches = matches[1];
          }
          if (matches) {
            matches = matches.split(/[._]+/)
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return { name: 'unknown', version: 0 };
    }
  };
  const e = module.init();
  const deviceInfo = {
    os: e.os.name,
    os_version: e.os.version,
    browser: e.browser.name,
    vendor: navigator.vendor,
    tz: e.tz
  };
  return deviceInfo;
}