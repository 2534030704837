import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { BlurBackgroundModal } from '../../Commons';
import { flagKeys, freeGamePassContents, storageKeys } from '../../../constants';
import { setClaimFreeGamePass, updateFlag } from '../../../actions';
import freePassImage from '../../../assets/gamepass/free-pass.png';
import './ClaimFreeGamePass.scss';

const ClaimFreeGamePass = ({ open }) => {
  const dispatch = useDispatch();

  const handleClaim = () => {
    const flagsData = {
      key: flagKeys.isFreeGamePassModalShown,
      body: {
        value: true
      }
    };
    dispatch(updateFlag(flagsData));
    dispatch(setClaimFreeGamePass(false));
  }

  useEffect(() => {
    if (open) {
      Cookies.set(storageKeys.isFreeGamePassModalShown);
    }
  }, [open])

  return (
    <BlurBackgroundModal
      open={open}
      containerClassName="claim-free-container"
      dialogClassName="claim-free-dialog"
    >
      <img 
        src={freePassImage} 
        alt="game-pass"
        className="claim-free-image" 
      />
      <div className="claim-free-title">{freeGamePassContents.title}</div>
      <div className="claim-free-description">{freeGamePassContents.description}</div>
      <button 
        onClick={handleClaim}
        className="claim-free-button"
      >
        {freeGamePassContents.button}
      </button>
    </BlurBackgroundModal>
  )
}

export default ClaimFreeGamePass