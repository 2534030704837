import React from 'react';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export const ProfileMainMenu = ({ items }) => (
  <div className="profile-main-menu">
    <h1 className="profile-main-menu-header">My Account</h1>
    {items.map(i => (
      <Link key={`MyAccount_${i.name}`} to={i.route} className="profile-main-menu-item">
        <div className={classNames("profile-main-menu-item-icon", `profile-main-menu-item-icon-${kebabCase(i.name)}`)}></div>
        <span>{i.name}</span>
        {i.comingSoon && <span className="profile-main-menu-item-coming-soon">Coming Soon</span>}
        <ArrowForwardIosRoundedIcon className="profile-main-menu-item-forward-icon" />
      </Link>
    ))}
  </div>
);