import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNetwork } from 'wagmi';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Radio } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { walletConnectTexts } from '../../../constants';
import { checkoutSelectCurrencySelector, showPaymentMethodsSelector } from '../../../selectors';
import { getGamePassCheckoutSelectedCurrency, setShowPaymentMethods } from '../../../actions';
import './SelectCurrencyDropdown.scss';

const SelectCurrencyDropdown = () => {
  const dispatch = useDispatch();
  const { chain } = useNetwork();

  const [selectedCurrency, setSelectedCurrency] = useState({});

  const currency = useSelector(state => checkoutSelectCurrencySelector(state));
  const showPaymentMethods = useSelector(state => showPaymentMethodsSelector(state));

  useEffect(() => {
    if (!isEmpty(currency)) {
      for (const item of currency) {
        if (item?.isSelected) {
          setSelectedCurrency(item);
          return;
        }
      }
    }
    setSelectedCurrency({});
  }, [currency])

  const handleSelectedCurrency = (data) => {
    setSelectedCurrency(data.kind);
    dispatch(getGamePassCheckoutSelectedCurrency({
      chainId: chain?.id,
      currency: data?.kind
    }));
  }

  const openSelectCurrency = () => {
    dispatch(setShowPaymentMethods({ dropdown: false, currency: !showPaymentMethods?.currency }));
  }

  return (
    <div 
      onClick={openSelectCurrency}
      className={classNames(
        "select-currency-dropdown", 
        {"select-curreny-dropdown-solo": isEmpty(selectedCurrency)}
      )}
    >
      {isEmpty(selectedCurrency) ?
        walletConnectTexts.selectCurrency :
        <div className="d-flex align-items-center gap-3">
          {selectedCurrency?.iconUrl &&
            <img 
              src={selectedCurrency?.iconUrl} 
              alt={selectedCurrency?.kind} 
              className="select-currency-dropdown-image" 
            />}
          <div>{selectedCurrency?.name}</div>
        </div>}
      <ArrowDropDownIcon className="select-currency-dropdown-icon" />
      {showPaymentMethods?.currency && 
        <div className="select-currency-dropdown-container">
          {!isEmpty(currency) && currency?.map((c, cIdx) => (
            <div 
              className="select-currency-dropdown-wrapper" 
              onClick={() => handleSelectedCurrency(c)} 
              key={`Currencies_${cIdx}`}
            >
              <div className="select-currency-dropdown-wrapper">
                <img className="select-currency-dropdown-wrapper-image" src={c?.iconUrl} alt={c?.kind} />
                <div>{c?.name}</div>
              </div>
              <Radio
                checked={c.kind === selectedCurrency?.kind}
                name="radio-buttons"
                value={c.kind}
                sx={{
                  color: "#f304a0",
                  '&.Mui-checked': {
                    color: "#f304a0",
                  },
                }}
              />
            </div>
          ))}
        </div>}
      </div>
  );
}

export default SelectCurrencyDropdown;