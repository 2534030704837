import React from 'react';
import './ChatThread.scss'

const ChatThreadOptions = ({ options, onOptionClick }) => (
  <div className="pe-3 ps-3">
    {options.map(o => (
      <button
        key={o.id}
        className="chat-thread-options-button"
        onClick={() => onOptionClick(o.title)}
      >
        {o.title}
      </button>
    ))}
  </div>
);

export default ChatThreadOptions;