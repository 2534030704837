import { useEffect, useState } from 'react';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { 
  ErrorToast,
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { WithdrawalForm } from './WithdrawalForm';
import OTPModal from '../OTPModal';
import GeneralDropdown from '../Commons/GeneralDropdown/GeneralDropdown';
import { 
  cryptoWithdrawalValidateSuccessData,
  getCryptoChains,
  getCryptoCurrencies, 
  getCurrencyConversion, 
  getWithdrawalNotice
} from '../../actions';
import { 
  authSteps,
  cryptoCurrency,
  cryptoIntent,
  defaultChain,
  defaultTimeoutMS,
  mgcCurrency, 
  regexConstants, 
  topUpChannels, 
  topUpDirection
} from '../../constants';
import { 
  authStepSelector,
  cryptoChainsSelector,
  cryptoCurrenciesSelector,
  topUpCurrencySelector, 
  topUpIsLoadingSelector, 
  walletCryptoSelector 
} from '../../selectors';
import { getWalletCredits } from '../../utils/getWalletCredits';
import { useDebounce } from '../../hooks/useDebounce';
import { useLogin } from '../../hooks/useLogin';
import './Withdrawal.scss';

export const Withdrawal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { requestOtp } = useLogin();

  const crypto = useSelector(state => walletCryptoSelector(state));

  const creditsObject = getWalletCredits(crypto);
  let creditsValue = null;
  if (creditsObject) creditsValue = creditsObject.val;
  
  const [currencyValue, setValue] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [selectedChain, setSelectedChain] = useState({});
  
  const currency = useSelector(state => topUpCurrencySelector(state));
  const cryptoCurrencies = useSelector(state => cryptoCurrenciesSelector(state));
  const isConverting = useSelector(state => topUpIsLoadingSelector(state));
  const cryptoChains = useSelector(state => cryptoChainsSelector(state));
  const authStep = useSelector(state => authStepSelector(state));

  const debouncedValue = useDebounce(currencyValue, defaultTimeoutMS);

  const inputChange = (e) => {  
    const { value } = e.target || '';
    let inputValue = 0;
    
    if (regexConstants.numbersWithDot.test(value) && value) {
      const inputValueString = e.target.value.toString();
      const inputValueTrimmed = inputValueString?.replace(regexConstants.startingZeroes, '');
      inputValue = inputValueTrimmed?.replace(regexConstants.comma, '');
    }
    setValue(inputValue);
  };

  const convertAll = () => {
    setValue(Number(creditsValue));
  }

  useEffect(() => {
    return () => {
      dispatch(cryptoWithdrawalValidateSuccessData({}));
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getCryptoChains({ intent: cryptoIntent.withdrawal }));
  }, [dispatch])

  useEffect(() => {
    if (isEmpty(cryptoChains)) {
      return;
    }
    const selectedChain = cryptoChains?.find(c => c?.chain === defaultChain);
    setSelectedChain(selectedChain);
  }, [cryptoChains])

  useEffect(() => {
    if (isEmpty(selectedChain)) {
      return;
    }
    dispatch(
      getCryptoCurrencies({
        intent: cryptoIntent.withdrawal,
        chainId: selectedChain?.chainId
      })
    );
  }, [dispatch, selectedChain])

  useEffect(() => {
    if (isEmpty(selectedChain) ||
      isEmpty(selectedCurrency)) {
      return;
    }
    const params = {
      chainId: selectedChain?.chainId,
      toCurrency: selectedCurrency?.kind
    };
    dispatch(
      getWithdrawalNotice({
        intent: cryptoIntent.withdrawal,
        params,
      })
    );
  }, [dispatch, selectedCurrency, selectedChain])

  useEffect(() => {
    if (isEmpty(cryptoCurrencies?.currencies)) {
      return;
    }
    const defaultCurrency = cryptoCurrencies?.currencies?.find(c => c?.kind === cryptoCurrency.usdc);
    setSelectedCurrency(defaultCurrency);
  }, [cryptoCurrencies?.currencies])

  useEffect(() => {
    if (isEmpty(selectedCurrency) || 
      isEmpty(selectedChain)) {
      return;
    }
    const direction = topUpDirection.from;
    const fromCurrency = mgcCurrency;
    const toCurrency = selectedCurrency?.kind;
    const channel = topUpChannels.externalWallet;
    const chainId = selectedChain?.chainId;
    const intent = cryptoIntent.withdrawal;
    let value = debouncedValue;
    if (!value && value !== 0) {
      return;
    };
    dispatch(getCurrencyConversion({
      fromCurrency, 
      toCurrency, 
      direction, 
      value, 
      channel,
      chainId,
      intent
    }));
  }, [dispatch, debouncedValue, selectedCurrency, selectedChain]);

  const handleBack = () => {
    if (showForm) {
      setShowForm(!showForm);
      return;
    }
    navigate(-1);
  }
  
  const withdrawIcon = <img src={selectedCurrency?.iconUrl} alt={selectedCurrency?.kind} className="general-dropdown-title-icon" />;
  const chainIcon = <img src={selectedChain?.iconUrl} alt={selectedChain?.chain} className="general-dropdown-title-icon" />;
  
  return (
    <LayoutContent 
      outerClassName="withdrawal-content" 
      innerClassName="withdrawal-content-intent"
    >
      <PageTitle title="Withdraw" />
      <Section 
        sectionClassName="withdrawal-section"
        backButtonRoute
        backRoute={handleBack}
        title={`Withdraw MGC to Crypto`}
        titleCentered
        text="Back"
      >
        {!showForm ? 
          <div className="withdrawal">
            <div className="w-100">
              <div className="withdrawal-row-container mb-3">
                <div className="withdrawal-title">Select Network: </div>
                  {!isEmpty(cryptoChains) &&
                    <GeneralDropdown 
                      options={cryptoChains} 
                      title={selectedChain?.chain}
                      icon={chainIcon}
                      setSelected={selected => setSelectedChain(selected)}
                      className="general-dropdown"
                    />}
              </div>
              <div className="withdrawal-container">
                <div className="withdrawal-row-container">
                  <div className="withdrawal-title">You are converting from:</div>
                  <div className="withdrawal-row-container">
                    <div className="withdrawal-currency-icon" />
                    <div className="withdrawal-currency">{mgcCurrency}</div>
                  </div>
                </div>
                <input 
                  className="withdrawal-input" 
                  value={currencyValue}
                  onChange={inputChange}
                />
                <div className="withdrawal-row-container">
                  <div className="withdrawal-text"> 
                    Balance: 
                    <b className="withdrawal-text-balance"> 
                      {creditsValue} {mgcCurrency} 
                    </b>
                  </div>
                  <div 
                    className="withdrawal-convert-all" 
                    onClick={() => convertAll()}
                  > 
                    Convert All 
                  </div>
                </div>
              </div>
              <div className="pb-4">
                <div className="withdrawal-row-container">
                  <div className="withdrawal-title">You will withdraw:</div>
                    {!isEmpty(cryptoCurrencies?.currencies) &&
                      <GeneralDropdown 
                        options={cryptoCurrencies?.currencies} 
                        title={selectedCurrency?.name}
                        icon={withdrawIcon}
                        setSelected={selected => setSelectedCurrency(selected)}
                        className="general-dropdown"
                      />}
                </div>
                <div className="withdrawal-title-amount"> 
                  {isConverting ? '--' : currency?.amountToReceive?.value}
                  <span className="withdrawal-title-currency"> 
                    {currency?.amountToReceive?.currency}
                  </span>
                </div>
                <div className="withdrawal-rate-box">
                  <div className="withdrawal-rate-title">Current Rate</div>
                  {!isEmpty(selectedCurrency) && 
                    !isEmpty(currency) &&
                    <div>{`1 ${selectedCurrency?.kind} = ${currency?.rate?.value} ${currency?.rate?.currency}`}</div>}
                </div>
              </div>
            </div>
            <div className="withdrawal-button-wrapper">
              <button 
                className={classNames(
                  "withdrawal-button",
                  {"withdrawal-button-disabled": isConverting || debouncedValue <= 0}
                )}
                onClick={() => setShowForm(true)}
              >
                Continue
              </button>
            </div>
          </div> : 
          <WithdrawalForm
            value={currencyValue}
            chainId={selectedChain?.chainId}
            toCurrency={selectedCurrency?.kind}
            transferAmount={`Transfer ${currency?.amountToReceive?.text}`}
            fee={`${currency?.totalFees?.text} Transaction Fee`}
            requestOtp={requestOtp}
          />}
      </Section>
      {authStep !== authSteps.otp && 
        <ErrorToast />}
      <OTPModal
        open={authStep === authSteps.otp}
        hideWrongNumber
      />
    </LayoutContent>
  )
}