import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { erc20ABI, useContractWrite } from 'wagmi';
import { useMediaQuery } from 'react-responsive';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import PageTitle from '../PageTitle/PageTitle';
import LayoutContent from '../LayoutContent/LayoutContent';
import WalletConnect from '../WalletConnect/WalletConnect';
import GeneralDropdown from '../../Commons/GeneralDropdown/GeneralDropdown';
import { 
  currencies,
  defaultTimeoutMS, 
  errorMessage, 
  mgcCurrency, 
  minWidths, 
  primaryType, 
  regexConstants, 
  siteRoutes, 
  topUpDirection, 
  usdCurrency, 
  usdtABI, 
  wallets 
} from '../../../constants';
import { 
  createPayment,
  getCryptoCurrencies,
  getCurrencyConversion, 
  setCurrencyConversion, 
  setLoading,
} from '../../../actions';
import { 
  cryptoCurrenciesSelector, 
  topUpCurrencySelector, 
  topUpIsLoadingSelector
} from '../../../selectors';
import { useDebounce } from '../../../hooks/useDebounce';
import { useWalletConnect } from '../../../hooks/useWalletConnect';
import { useQuery } from '../../../hooks';
import './CryptoPayment.scss';

const CryptoPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isConnected, chain } = useWalletConnect();
  const query = useQuery();
  const channel = query.get('channel');

  const cryptoData = useSelector(state => cryptoCurrenciesSelector(state));
  const conversion = useSelector(state => topUpCurrencySelector(state));
  const isConverting = useSelector(state => topUpIsLoadingSelector(state));

  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [value, setValue] = useState(10);
  const [errorMsg, setErrorMsg] = useState('');
  const debouncedValue = useDebounce(value, defaultTimeoutMS);

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  useEffect(() => {
    if (isConnected) {
      dispatch(
        getCryptoCurrencies({
          intent: primaryType.topup,
          chainId: chain?.id
        })
      );
    }
  }, [dispatch, chain, isConnected])

  useEffect(() => {
    let route = siteRoutes.wallet;
    if (!isConnected) {
      if (!isDesktop) {
        route = siteRoutes.topUp;
      }
      navigate(route, { replace: true });
    }
  }, [navigate, isConnected])

  useEffect(() => {
    let currency = [];
    if (isEmpty(cryptoData?.currencies)) {
      return;
    }
    currency = cryptoData?.currencies?.find(c => c?.name === currencies.USDT);
    setSelectedCurrency(currency);
  }, [cryptoData?.currencies])

  useEffect(() => {
    if (isEmpty(selectedCurrency) || 
      value.length === 0 || 
      isEmpty(cryptoData?.currencies)) {
      dispatch(setCurrencyConversion({}));
      return;
    };
    dispatch(
      getCurrencyConversion({
        fromCurrency: selectedCurrency?.name, 
        toCurrency: mgcCurrency, 
        direction: topUpDirection.from, 
        value: debouncedValue, 
        channel: wallets.walletConnect.name,
        chainId: chain?.id
      })
    );
  }, [dispatch, debouncedValue, selectedCurrency, cryptoData?.currencies])

  const handleBack = () => {
    if (!isDesktop) return navigate(siteRoutes.topUp);
    navigate(siteRoutes.wallet);
  }

  useEffect(() => {
    let defaultValue = 10;
    if (isEmpty(cryptoData?.currencies)) {
      defaultValue = 0;
    }
    setValue(defaultValue);
  }, [cryptoData?.currencies])

  const handleChange = (value) => {
    if (regexConstants.numbersWithDot.test(value) || value === '') {
      setValue(value);
      setErrorMsg('');
    }
  }

  const { data, write, isLoading } = useContractWrite({ 
    address: selectedCurrency?.address,
    abi: selectedCurrency?.kind === currencies.USDT ? usdtABI : erc20ABI,
    functionName: 'transfer',
    args: [cryptoData?.selectedPaymentMethodRecipient,
      conversion?.amountToPay?.wei
    ],
    onError(error) {
      const splittedErrorMessage = error?.message?.split('\n');
      const firstErrorMessage = splittedErrorMessage[0]?.trim();
      if (firstErrorMessage?.includes(errorMessage.userRejectedRequest)) {
        return;
      }
      let message = firstErrorMessage;
      const usdtInsufficientBalance = selectedCurrency.kind === currencies.USDT && 
        error?.message?.includes(errorMessage.rpcRequestFailed);
      if (error?.message?.includes(errorMessage.transferAmountExceedsBalance) ||
        usdtInsufficientBalance) {
        message = errorMessage.insufficientBalance;
      }
      setErrorMsg(message);
    }
  });

  useEffect(() => {
    dispatch(setLoading(isLoading));
    return () => {
      dispatch(setLoading(false));
    }
  }, [dispatch, isLoading])

  useEffect(() => {
    if (data?.hash) {
      dispatch(setLoading(true));
      dispatch(
        createPayment({ 
          fromAmount: value,
          fromCurrency: selectedCurrency?.kind,
          transactionHash: data.hash,
          chainId: chain?.id,
          channelCode: channel
        })
      );
    }
  }, [dispatch, data])

  let iconUrl = selectedCurrency?.iconUrl;
  let kind = selectedCurrency?.kind;
  
  const icon = <img src={iconUrl} alt={kind} className="general-dropdown-title-icon" />;
  const currentRateValue = `1 ${mgcCurrency} = ${conversion?.rate?.value ?? '--'} ${conversion?.rate?.currency ?? ''}`;

  return (
    <LayoutContent innerClassName="crypto-payment-inner">
      <PageTitle title="Payment" />
      <div className="crypto-payment-header">
        <ArrowBackIosNewRoundedIcon className="payment-header-back" onClick={handleBack} />
        <div className="px-5">Topup MGC</div>
      </div>
      <div className="crypto-payment">
        <div className="crypto-payment-container">
          <div className="crypto-payment-row-container">
            <div className="crypto-payment-title">You are converting from:</div>
            {!isEmpty(cryptoData?.currencies) &&
              <GeneralDropdown
                options={cryptoData?.currencies}
                title={cryptoData?.currencies[1]?.name}
                icon={icon}
                setSelected={selected => setSelectedCurrency(selected)}
                selected={selectedCurrency}
                className="general-dropdown"
              />}
          </div>
          <input 
            className={classNames(
              "crypto-payment-input",
              {"crypto-payment-input-error": errorMsg}
            )}
            disabled={isEmpty(cryptoData?.currencies)}
            onChange={e => handleChange(e.target.value)} 
            value={value ?? 0}
          />
          <div className="crypto-payment-error">{errorMsg}</div>
        </div>
        <div className="crypto-payment-wrapper">
          <div className="crypto-payment-row-container">
            <div className="crypto-payment-title">You will receive:</div>
          </div>
          <div className="crypto-payment-title-amount"> 
            {isConverting ? `--` : conversion?.amountToReceive?.value ?? '--'}
            <span className="crypto-payment-title-currency"> 
              {conversion?.amountToReceive?.currency ?? mgcCurrency} 
            </span>
          </div>
          <div className="crypto-payment-conversion-container"> 
            <div className="crypto-payment-conversion-title">Current Rate:</div>
            {currentRateValue}
          </div>
        </div>
        <WalletConnect 
          hideHeader 
          hideFaqs 
          hideDisconnect 
          showWalletName
          hideErrorToast
          className="crypto-payment-wallet"
        />
        <div className="crypto-payment-button-wrapper">
          <div 
            onClick={() => write?.()}
            className={classNames(
              "crypto-payment-button",
              {"crypto-payment-button-disabled": (isConverting || 
                errorMsg || 
                value.length === 0 ||
                isEmpty(cryptoData?.currencies))}
            )}
          >
            {`Pay ${selectedCurrency?.name ?? usdCurrency} ${conversion?.amountToPay?.value ?? 0}`}
          </div>
        </div>
      </div>
    </LayoutContent>
  );
};

export default CryptoPayment;