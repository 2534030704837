import React, { Component } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import ChatThreadMessage from './ChatThreadMessage';
import './ChatThread.scss';

class ChatThreadMessages extends Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    const mostRecentMessage = document.getElementById('mostRecentMessage');
    mostRecentMessage.scrollIntoView();
  }

  render() {
    const { id, messages, imageUrl, name, confirmDeleteMessage, submitMessage, chatboxHidden } = this.props;

    return (
      <div className={classNames("chat-thread-messages", {"chat-thread-messages-chatbox-hidden": chatboxHidden})}>
        <div className="chat-thread-messages-inner">
          <div id="messageThread">
            <div className="chat-thread-messages-title">
              <div className="chat-thread-messages-title-pic" style={{ backgroundImage: `url(${imageUrl})` }}></div>
              <div className="chat-thread-messages-title-name">{name}</div>
            </div>
            {messages.map((g, gIdx) => (
              <div key={`ChatThread_${id}_MessageGroup_${g?.label}`} className="chat-thread-group">
                <div className="chat-thread-label">{g?.label}</div>
                {g?.messages.map((m, mIdx) => {
                  const { messageDate, name } = m;
                  const self = name === 'You';
                  const currentMessageDate = DateTime.fromISO(messageDate);
                  const prevMessageDate = g.messages[mIdx - 1] && DateTime.fromISO(g.messages[mIdx - 1].messageDate);
                  const nextMessageDate = g.messages[mIdx + 1] && DateTime.fromISO(g.messages[mIdx + 1].messageDate);
                  const dateDiffPrev = prevMessageDate && currentMessageDate.diff(prevMessageDate, ['hours', 'minutes']);
                  const dateDiffNext = nextMessageDate && nextMessageDate.diff(currentMessageDate, ['hours', 'minutes']);
                  const dateDiffPrevResult = dateDiffPrev && dateDiffPrev.toObject();
                  const dateDiffNextResult = dateDiffNext && dateDiffNext.toObject();

                  const consecutivePrevSelf = dateDiffPrevResult && dateDiffPrevResult.hours < 1 && dateDiffPrevResult.minutes < 1 &&
                    self && g.messages[mIdx - 1] && g.messages[mIdx - 1].name === 'You';
                  const consecutiveNextSelf = dateDiffNextResult && dateDiffNextResult.hours < 1 && dateDiffNextResult.minutes < 1 &&
                    self && g.messages[mIdx + 1] && g.messages[mIdx + 1].name === 'You';
                  const consecutivePrevSender = dateDiffPrevResult && dateDiffPrevResult.hours < 1 && dateDiffPrevResult.minutes < 1 &&
                    !self && g.messages[mIdx - 1] && !g.messages[mIdx - 1].name !== 'You';
                  const consecutiveNextSender = dateDiffNextResult && dateDiffNextResult.hours < 1 && dateDiffNextResult.minutes < 1 &&
                    !self && g.messages[mIdx + 1] && !g.messages[mIdx + 1].name !== 'You';

                  return (
                    <div key={`ChatThread_${mIdx}_Message`}>
                      <ChatThreadMessage
                        {...m}
                        messageId={m.id}
                        self={self}
                        componentId={gIdx === messages.length - 1 && mIdx === g.messages.length - 1 ? 'mostRecentMessage' : undefined}
                        consecutivePrevSelf={consecutivePrevSelf}
                        consecutivePrevSender={consecutivePrevSender}
                        consecutiveNextSelf={consecutiveNextSelf}
                        consecutiveNextSender={consecutiveNextSender}
                        confirmDeleteMessage={confirmDeleteMessage}
                        submitMessage={submitMessage}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ChatThreadMessages;