import React from 'react';
import { useSelector } from 'react-redux';
import { walletCryptoSelector } from '../../../selectors';
import { defaultLocaleString } from '../../../constants';
import { getGoldCoinsValue } from '../../../utils/getWalletCredits';
import goldCoins from '../../../assets/gamepass/coin.png';
import './GoldBalance.scss';

export const GoldBalance = () => {
  const crypto = useSelector(state => walletCryptoSelector(state));
  const goldBalance = getGoldCoinsValue(crypto);
  const goldBalanceLocalStr = Number(goldBalance)?.toLocaleString(defaultLocaleString);
  return (
    <div className="gold-balance">
      <div className="gold-balance-container">
        <img src={goldCoins} alt="gold-coins" className="gold-balance-icon" />
        <div className="gold-balance-wrapper">
          <div className="gold-balance-wrapper-gold">{goldBalanceLocalStr}</div>
          <div className="gold-balance-wrapper-text">Gold Balance</div>
        </div>
      </div>
    </div>
  )
}
