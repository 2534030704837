export const buyNftText = [
  'All transactions are final and cannot be reversed.',
  'Upon confirmation, item will be reflected in your MetaverseGo Wallet.'
];
export const cancelListNftText = 'Are you sure you want to cancel NFT listing?';
export const sellDefaultValue = '50';
export const range = (size) => {
  return [...Array(size).keys()];
}
export const marketplaceText = 'Marketplace';
export const collectiblesTexts = {
  title: {
    cancel: 'Cancel NFT Listing',
    sell: 'Sell NFT',
    confirm: 'Confirm NFT Purchase'
  },
  ctaButton: {
    viewNft: 'View NFT',
    viewInMarketplace: 'View in Marketplace',
    sell: 'Sell Item',
    proceed: 'Proceed',
    cancel: 'Cancel Listing',
    insufficientFunds: 'Insufficient funds. Kindly top up',
    pay: 'Pay'
  },
  closeButton: {
    close: 'Close',
    noNotYet: 'No, not yet',
    cancel: 'Cancel'
  },
  listed: 'Listed',
  buy: 'Buy',
  sell: 'Sell',
  cancelListing: 'Cancel Listing',
  loginToBuy: 'Login to Buy',
  ownedBy: 'Owned by',
  share: 'Share',
  withdraw: 'Withdraw'
};