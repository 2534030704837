import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  Collectibles,
  LayoutContent,
  PageTitle
} from '../Components';
import { getUserCollections, getCollectionDetails } from '../../actions';
import { firebaseEvents } from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './WalletCollection.scss';

export const WalletCollection = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState({});

  const collection = useSelector((state) => state.marketplace.collection);
  const collectionList = useSelector(state => state.collections.list);
  const isList = useSelector((state) => state.marketplace.isList);
  const isBuy = useSelector((state) => state.marketplace.isBuy);
  const isCancelList = useSelector((state) => state.marketplace.isCancelList);

  const id = location.pathname.split('/');

  useEffect(() => {
    logFirebaseEventWithTimestamp(firebaseEvents.walletViewNFT);
  }, [])
  
  useEffect(() => {
    dispatch(getUserCollections());
  }, [dispatch])

  useEffect(() => {
    const filteredCollection = collectionList.filter(item => item.id === id[2]);
    setSelectedItem(filteredCollection[0]);
  }, [collectionList, id])

  useEffect(() => {
    if (isEmpty(selectedItem)) return;
    const data = {
      slug: selectedItem.slug,
      id: selectedItem.id
    }
    dispatch(getCollectionDetails(data));
  }, [dispatch, selectedItem, isList, isBuy, isCancelList])

  return (
    <LayoutContent id="scroll" innerClassName="collection-list-inner">
      <PageTitle title="Collectible" />
      {!isEmpty(collection) &&
        <Collectibles item={collection} />}
    </LayoutContent>
  );
}