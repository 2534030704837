import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import {
  LayoutContent,
  PageTitle,
  Section
} from '../Components';
import { PartnersCarousel } from './PartnersCarousel';
import { PartnersIcons } from './PartnersIcons';
import { PartnersThumbnails } from './PartnersThumbnails';
import { rewardPartnersSelector } from '../../selectors';
import { getRewardPartners } from '../../actions';
import {
  maxWidths,
  rewardPartnersSections,
  wenLamboSpinTheWheelCTA
} from '../../constants';
import './Partners.scss';

export const Partners = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const partnersObj = useSelector(state => rewardPartnersSelector(state));

  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  useEffect(() => {
    dispatch(getRewardPartners());
  }, [dispatch]);

  const setSectionContent = (sectionName, items) => {
    switch (sectionName) {
      case rewardPartnersSections.brand:
        return <PartnersThumbnails title={sectionName} items={items} isMobile={isMobile} />;
      case rewardPartnersSections.rush:
        return <PartnersCarousel items={items} />;
      case rewardPartnersSections.game:
        return <PartnersIcons title={sectionName} items={items} />;
      default:
        return null;
    }
  }

  return (
    <>
      {!isEmpty(partnersObj) &&
        <LayoutContent outerClassName="partners">
          <PageTitle title="Partners" />
          <Section 
            sectionClassName="partners-section"
            backButtonRoute
            backRoute={() => navigate(-1)}
            title={partnersObj.title}
            titleCentered
            text="Back"
          >
            <Link to={wenLamboSpinTheWheelCTA}>
              <img className="partners-banner" src={partnersObj.imageUrls?.banner} alt="banner" />
            </Link>
            <div>
              {partnersObj.sections.map((s, sIdx) => (
                <div key={`${sIdx}_${s.name}`} className="partners-sections">
                  {setSectionContent(s.name, s.items)}
                </div>
              ))}
            </div>
          </Section>
        </LayoutContent>}
    </>
  );
}