import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { MenuItem } from './MenuItem';
import { TestMenu } from './TestMenu';
import {
  guestSelector,
  remoteConfigKeysSelector
} from '../../selectors';
import { unauthorizedRedirect } from '../../actions';
import {
  authSteps,
  defaultTestMenuTexts,
  siteRoutes,
  storageKeys
} from '../../constants';
import sideMenuAd from '../../assets/referral/banners/side-menu.jpg';
import './Menu.scss';

export const MenuList = ({
  openMenu,
  userProfile,
  items,
  others,
  logout,
  sessionExpired,
  ...props
}) => {
  const dispatch = useDispatch();

  const isGuest = useSelector(state => guestSelector(state));
  const testKeys = useSelector(state => remoteConfigKeysSelector(state));

  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const username = localStorage.getItem(storageKeys.username);

  const hasSessionToken = sessionToken && sessionToken !== '';

  const unexpired = userProfile && !isEmpty(userProfile) && hasSessionToken;

  const loginMenuItems = unexpired ? items : items.filter(i => i.name);
  const usernameText = unexpired ? userProfile.profileHeader.username : username;
  
  let profilePhotoStyle = null;
  if (unexpired &&
    userProfile.profileHeader.imageUrl &&
    userProfile.profileHeader.imageUrl !== '') {
    profilePhotoStyle = {
      backgroundImage: `url(${userProfile.profileHeader.imageUrl})`
    };
  }

  const handleRoute = (route) => {
    openMenu();
    dispatch(
      unauthorizedRedirect({ 
        isGuest, 
        authSteps: authSteps.login,
        route
      })
    );
  }

  const handleReferralAdClick = () => {
    if (!isGuest) {
      handleRoute(siteRoutes.referral);
    }
  }

  const showTestMenu = testKeys?.strippedStwEnabled &&
    testKeys?.strippedStwUsers?.includes(userProfile?.uid);

  const testMenus = [];
  if (showTestMenu) {
    testMenus.push({
      items: testKeys?.strippedGameUrls,
      title: defaultTestMenuTexts.title
    });
  }

  return (
    <div className="menu-list">
      {unexpired ?
          <div 
            onClick={() => handleRoute(siteRoutes.profile)} 
            className="menu-list-profile"
          >
            <div className="menu-list-profile-pic" style={profilePhotoStyle}></div>
            <div className="menu-list-profile-details">
              <div className="menu-list-profile-details-name">{usernameText}</div>
              <KeyboardArrowRightRoundedIcon />
            </div>
          </div> :
          <div className="menu-list-profile">
            <div className="menu-list-profile-pic" />
            <div
              onClick={() => handleRoute()}
              className={classNames(
                "menu-list-profile-details",
                "menu-list-profile-details-login"
              )}
            >
              <div className="menu-list-profile-details-login-btn">Login / Signup</div>
              <KeyboardArrowRightRoundedIcon />
            </div>
          </div>}
      <div className={classNames(
        "menu-list-items",
        {"menu-list-items-logged-in": unexpired}
      )}>
        <div>
          {!isEmpty(loginMenuItems) &&
            <div className="menu-list-items-inner">
              {loginMenuItems.map(i => (
                <MenuItem
                  key={`MenuItem_${i.name}`}
                  {...props}
                  name={i.name}
                  route={i.route}
                  openMenu={openMenu}
                />
              ))}
            </div>}
          <div className="menu-referral">
            <div
              onClick={() => handleRoute(siteRoutes.referral)}
              className={classNames(
                "menu-referral-button",
                {"menu-referral-button-disabled": isGuest}
              )}
            >
              <div className="menu-referral-icon" />
              <span>Refer a Friend</span>
            </div>
            <div
              onClick={handleReferralAdClick}
              className={classNames(
                "menu-referral-ad",
                {"menu-referral-ad-disabled": isGuest}
              )}
            >
              <img src={sideMenuAd} className="menu-referral-ad-image" />
            </div>
          </div>
          {testMenus.length > 0 && testMenus.map((m, mIdx) => (
            <TestMenu
              key={`TestMenu_${mIdx}_${m.title}`}
              items={m.items} 
              hide={openMenu} 
              title={m.title}
            />
          ))}
        </div>
        <div className="menu-list-items-others">
          {others.map(i => (
            <div key={`MenuItem_${i.name}`} className="menu-item-others">
              <a href={i.route} className={"menu-item-others-name"}>{i.name}</a>
              <a href={i.route} className={`menu-item-others-${i.icon}`} />
            </div>
          ))}
        </div>
      </div>
      {unexpired && 
        <div className="menu-logout" onClick={logout}>
          <div className="menu-logout-icon" />
          <div className="menu-logout-text">Logout</div>
        </div>}
    </div>
  );
}