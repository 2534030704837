import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
// import { Carousel, CarouselItem } from '../Commons';
import { LayoutContent, PageTitle } from '../Components';
import { ProfileInfo } from './ProfileInfo';
import { ProfileMainMenu } from './ProfileMainMenu';
import { storageKeys } from '../../constants';
import './Profile.scss';

export class Profile extends Component {
  componentDidMount() {
    if (!window.location.href.includes('metaversego.gg')) {
      this.props.getTutorial();
    }
    this.props.setLoading(false);
  }

  render() {
    const { profile, menuItems } = this.props;

    if (profile && !isEmpty(profile)) {
      const editProfilePage = '/profile/edit';
      const editProfileLink = profile.showTutorial ? '/tutorial' : editProfilePage;
      const editProfileLinkClick = () => profile.showTutorial ? localStorage.setItem(storageKeys.tutorialLink, editProfilePage) : null;

      return (
        <LayoutContent>
          <PageTitle title="Profile" />
          <ProfileInfo {...profile.profileHeader} editProfileLink={editProfileLink} editProfileLinkClick={editProfileLinkClick} />
          {/* <Carousel className="profile-ad-carousel">
            {profile.ads.map(ad => (
              <CarouselItem key={`Ad_${ad.id}`}>
                {
                  ad.isClickable ? <a href={ad.linkUrl} className="profile-ad" style={{ backgroundImage: `url(${ad.imageUrl})` }}></a>
                    : <div className="profile-ad" style={{ backgroundImage: `url(${ad.imageUrl})` }}></div>
                }
              </CarouselItem>
            ))}
          </Carousel> */}
          <ProfileMainMenu items={menuItems} />
        </LayoutContent>
      );
    }

    return null;
  }
}