import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import { BarcodeDetails } from './BarcodeDetails';
import {
  setCreatePayment,
  setInstructionsVisible,
  setTopUpVisible
} from '../../../actions';
import { minWidths } from '../../../constants';
import './Barcode.scss';

const Barcode = ({ selectedMethod, isBarcodeVisible, hide }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const handleNavigate = () => {
    if (!isDesktop) return navigate(`/payment/instructions?channel=${selectedMethod.details?.paymentChannel?.code}`);
    dispatch(setTopUpVisible(false));
    dispatch(setInstructionsVisible(true));
    hide();
  }
  
  useEffect(() => {
    if (isBarcodeVisible && !isEmpty(selectedMethod.details)){
      if (searchParams.has('method')) {
        searchParams.delete('mode');
        searchParams.delete('method');
        setSearchParams(searchParams);
        dispatch(setCreatePayment({ loading: false, data: {} }));
      }
      if (!searchParams.has('method' && !isEmpty(selectedMethod))){
        searchParams.set('channel', selectedMethod.details?.paymentChannel?.code);
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [dispatch, selectedMethod, isBarcodeVisible, setSearchParams, searchParams])

  return (
    <Modal
        backdrop
        show={isBarcodeVisible}
        className="barcode-container"
        contentClassName="barcode"
        dialogClassName="barcode-dialog"
        backdropClassName="barcode-bg"
        centered
        onHide={hide}
      >
        <Modal.Body>
          {!isEmpty(selectedMethod.details) && 
            <BarcodeDetails 
              selectedMethod={selectedMethod} 
              hide={hide} 
              handleNavigate={handleNavigate}
            />}
        </Modal.Body>
        <Modal.Footer>
        {!isDesktop && <>
          <button onClick={handleNavigate} className="barcode-view-button mb-2">View Instruction</button>
          <button onClick={hide} className="barcode-close-button-mobile">Close</button>
        </>}
        </Modal.Footer>
      </Modal>
  )
}

export default Barcode;
