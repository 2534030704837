import { 
  RESET_STATE, 
  SET_WITHDRAWAL_NFT, 
  SET_WITHDRAWAL_NFT_VALIDATE, 
  SET_WITHDRAW_NFT 
} from "../actions/constants";

const withdrawalState = {
  selectedNft: {},
  validatedNft: {},
  withdrawStatus: {}
};

const withdrawalReducer = (state = withdrawalState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return withdrawalState;
    case SET_WITHDRAWAL_NFT:
      return {
        ...state,
        selectedNft: action.selectedNft
      };
    case SET_WITHDRAWAL_NFT_VALIDATE:
      return {
        ...state,
        validatedNft: action.validatedNft
      };
    case SET_WITHDRAW_NFT:
      return {
        ...state,
        withdrawStatus: action.withdrawStatus
      };
    default:
      return state;
  }
}

export default withdrawalReducer;