import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { GoldCoinsHeader } from '../Components';
import { walletCryptoSelector } from '../../selectors';
import { getGoldCoinsValue } from '../../utils/getWalletCredits';
import { defaultCTATexts, minWidths } from '../../constants';
import './GamePassGames.scss';

export const GamePassGamesSection = ({
  backRoute, 
  backButtonRoute, 
  className, 
  title, 
  play, 
  showCoins,
  showCart,
  showDot,
  handleCartRedirect
}) => {
  const crypto = useSelector(state => walletCryptoSelector(state));
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const goldCoinsValue = getGoldCoinsValue(crypto);
  const backButtonText = isDesktop ? defaultCTATexts.back : '';

  return (  
    <div className={classNames(`${className}-container`, {"gamepass-play-section" : play && !isDesktop})}>
      <Link 
        to={backButtonRoute} 
        onClick={backRoute} 
        className={`${className}-back-text`}
      >
        <ArrowBackIosNewRoundedIcon className={`${className}-back-icon`}/>
        {backButtonText}
      </Link>
      {
        (showCoins && isDesktop) && 
        <>
          {title && <h1 className={`${className}-title`}>{title}</h1>}
          <GoldCoinsHeader value={goldCoinsValue} />
        </>
      }
      {
        (showCart && isDesktop) && 
        <>
          {title && <h1 className={`${className}-title`}>{title}</h1>}
          <div className="position-relative" onClick={handleCartRedirect}>
            <div className={`${className}-cart`} /> 
            {showDot && <div className={`${className}-cart-dot`} />} 
          </div>
        </>
      }
    </div>
  );
}
