import { RESET_STATE, SET_CHAT_MESSAGES, SET_CHAT_MESSAGE_THREAD } from '../actions/constants';

const chatState = {
  messages: {},
  thread: {}
};

const chatReducer = (state = chatState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return chatState;
    case SET_CHAT_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };
    case SET_CHAT_MESSAGE_THREAD:
      return {
        ...state,
        thread: action.messageThread
      };
    default:
      return state;
  }
}

export default chatReducer;