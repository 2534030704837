import { connect } from 'react-redux';
import {
  setLoading,
  setDisplayToast,
  getUserProfileFields,
  setUserProfileCountryCode,
  editUserProfile,
  updateUserProfilePhoto,
  removeUserProfilePhoto
} from '../actions';
import { userProfileFieldsSelector, userProfileToastSelector } from '../selectors';
import { EditProfile } from '../components';

const mapStateToProps = (state) => ({
  fields: userProfileFieldsSelector(state),
  toast: userProfileToastSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  getUserProfileFields: () => dispatch(getUserProfileFields()),
  setCountryCode: (countryCode) => dispatch(setUserProfileCountryCode(countryCode)),
  editUserProfile: (values) => dispatch(editUserProfile(values)),
  updateUserProfilePhoto: (imageFile) => dispatch(updateUserProfilePhoto(imageFile)),
  removeUserProfilePhoto: () => dispatch(removeUserProfilePhoto()),
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast))
});

const EditProfileContainer = connect(mapStateToProps, mapDispatchToProps)(EditProfile);

export default EditProfileContainer;