import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Banner, Grid, Tabs } from '../Commons';
import { Featured, LayoutContent, PageTitle, Section, Stats } from '../Components';
import './NFTCollection.scss';

export class NFTCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nftId: window.location.pathname.split('/').slice(1)[1],
      activeKey: ''
    };
  }

  componentDidMount() {
    this.props.getNFTCollectionDetails(this.state.nftId);
  }

  setActiveKey = (activeKey) => {
    this.setState({ activeKey });
  }

  render() {
    const { nftCollection } = this.props;
    const { nftId } = this.state;

    if (nftCollection && !isEmpty(nftCollection)) {
      return (
        <LayoutContent>
          <PageTitle title={nftCollection.name} />
          <Featured fluid>
            <Banner
              title={nftCollection.name}
              text={nftCollection.short_desc}
              imageUrl={nftCollection.image_url}
            >
              <Stats stats={nftCollection.stats} lg={4} fluid />
            </Banner>
          </Featured>
          <Section>
            <Tabs contents={{
              Items: <Grid id={nftId} items={nftCollection.items} keyPrefix="NFTItem" className="nft-collection" experience="nfts" />,
              Activity: null
            }} />
          </Section>
        </LayoutContent>
      );
    }

    return null;
  }
}