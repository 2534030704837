import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { referralFreeSpinTexts, wenLamboSpinTheWheelCTA } from '../../constants';

export const ReferralFreeSpin = ({ count }) => (
  <div className="referral-screen-free-spin">
    <div className="referral-screen-free-spin-wrapper">
      <div>
        <div className="referral-screen-free-spin-title">
          {referralFreeSpinTexts.freeSpinReceived}
        </div>
        <div className="d-flex justify-content-start align-items-center gap-1">
          <div className="referral-screen-free-spin-icon" />
          <div className="referral-screen-free-spin-count">{count}</div>
        </div>
      </div>
      <Link to={wenLamboSpinTheWheelCTA} 
        className={classNames(
          "referral-screen-free-spin-button",
          {"referral-screen-free-spin-button-disabled": count === 0}
        )}
      >
        {referralFreeSpinTexts.spinNow}
      </Link>
    </div>
  </div>
);

