import { connect } from 'react-redux';
import { getUserCollection } from '../actions';
import { userCollectionSelector } from '../selectors';
import { Collection } from '../components';

const mapStateToProps = (state) => ({
  collection: userCollectionSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getUserCollection: (userCollectionId) => dispatch(getUserCollection(userCollectionId))
});

const CollectionContainer = connect(mapStateToProps, mapDispatchToProps)(Collection);

export default CollectionContainer;