import React from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { dateTimeFormats } from '../../constants';
import './RewardDetails.scss';

export const RewardDetailsInfo = ({ title, imageUrl, redeemed, expiresAt, children }) => {
  const status = redeemed ? 'Redeemed' : 'Active';
  const currentDateTime = DateTime.now();
  const expiryDateTime = DateTime.fromISO(expiresAt);
  const fmtExpiryDateTime = expiryDateTime.toFormat(dateTimeFormats.rewardExpiryDate);
  const isBeforeExpiry = currentDateTime < expiryDateTime;

  let expiryStr = 'Expired';
  if (isBeforeExpiry) {
    expiryStr = `Expires at ${fmtExpiryDateTime}`;
  }

  return (
    <div className={classNames("reward-details-info", {
      "reward-details-info-no-banner": !imageUrl
    })}>
      <h1>{title}</h1>
      <div className={classNames("reward-details-info-status", {
        "reward-details-info-status-active": !redeemed,
        "reward-details-info-status-redeemed": redeemed
      })}>
        {status}
      </div>
      <div className="reward-details-info-expiry">
        {expiryStr}
      </div>
      {children}
    </div>
  );
}