import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomNavigate } from '../Components';
import { setAuthStep } from '../../actions';
import { guestSelector } from '../../selectors';
import { authSteps, siteRoutes, storageKeys } from '../../constants';

export const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isGuest = useSelector(state => guestSelector(state));

  const hasSessionToken = localStorage.getItem(storageKeys.sessionToken);
  
  useEffect(() => {
    if (isGuest && !hasSessionToken) {
      dispatch(setAuthStep(authSteps.login));
    }
  }, [dispatch, isGuest])
  
  let redirectRoute;
  if (location.pathname.includes(siteRoutes.referral)) {
    redirectRoute = siteRoutes.referral;
  }

  if (isGuest && !hasSessionToken) {
    return <CustomNavigate to={siteRoutes.home} state={{ route: redirectRoute }} replace />;
  } 
  return <Outlet />;
};