import axios from 'axios';
import { baseUrl } from './url';

export function requestSpendSession() {
  return axios.request({
    method: 'GET',
    url: baseUrl('spend/session')
  });
}

export function requestSpendAuthentication(body) {
  return axios.request({
    method: 'POST',
    data: body,
    url: baseUrl('spend/auth')
  });
}

export function requestSpendLoadProducts(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('spend/load/products')
  });
}

export function requestSpendCountry(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('spend/country')
  });
}

export function requestSpendLoadProductConfirm(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('spend/load/confirm')
  });
}

export function requestSpendLoadProductPay(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('spend/load/pay')
  });
}

export function requestSpendLoadOtp(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/spend/load/otp')
  });
}

export function requestSpendLoadResendOtp(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/spend/load/resend-otp')
  });
}