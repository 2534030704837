import React from 'react';
import { Modal } from 'react-bootstrap';
import './PopupMenu.scss';

export const PopupMenu = ({ open, hide, children }) => (
  <Modal
    backdrop
    show={open}
    className="popup-menu-container"
    contentClassName="popup-menu"
    dialogClassName="popup-menu-dialog"
    backdropClassName="popup-menu-bg"
    onHide={hide}
  >
    <Modal.Header className="popup-menu-header" onClick={hide}>Cancel</Modal.Header>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);