import React from 'react';
import Lottie from 'lottie-react';
import process from './processing.json';
import './Processing.scss'

export const Processing = () => (
  <div className="process">
    <Lottie animationData={process} loop={true} autoplay={true}/>
  </div>
);
