import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { getGamePassCheckoutTokenizedPayments, setAddedGamePassCheckoutTokenizedPayments } from '../../actions';
import { checkoutTokenizedPaymentsSelector } from '../../selectors';
import { gamePassPaymentMethodsTitles, paymentMethods } from '../../constants';

export const UserActiveNumbers = ({ setSelectedNumber, setInputValue, setActive }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeNumber, setActiveNumber] = useState({});
  const [inactiveNumber, setInactiveNumber] = useState([]);

  const tokenizedPayments = useSelector(state => checkoutTokenizedPaymentsSelector(state));

  useEffect(() => {
    dispatch(getGamePassCheckoutTokenizedPayments({ selectedPaymentMethod: paymentMethods.carrierX }));
    dispatch(setAddedGamePassCheckoutTokenizedPayments(false));
    setSelectedNumber({});
  }, [dispatch])

  useEffect(() => {
    if (isEmpty(tokenizedPayments)) return;
    let inactiveNumberArr = [];
    for (const value of tokenizedPayments?.values) {
      if (value.isActive) {
        setActiveNumber(value)
      } else {
        inactiveNumberArr.push(value);
      }
      setInactiveNumber(inactiveNumberArr);
    }
  }, [tokenizedPayments])

  const onAddNumber = () => {
    setInputValue('');
    setActive(false);
    navigate('?show=input');
  }

  const handleSelectedNumber = (data) => {
    setSelectedNumber(data);
    navigate('?show=input');
  }

  return (
    <div className="payment-methods-numbers">
      <div className="payment-methods-numbers-box">
        <div className="payment-methods-numbers-title">{gamePassPaymentMethodsTitles.myActiveNumber}</div>
        <div onClick={() => handleSelectedNumber(activeNumber)} className="position-relative">
          <div className="payment-methods-numbers-text">{activeNumber.mobileNumber}</div>
          <KeyboardArrowRightRoundedIcon className="payment-methods-numbers-arrow" />
        </div>
      </div>
      {!isEmpty(inactiveNumber) && 
        <div className="payment-methods-numbers-wrapper">
          <div className="payment-methods-numbers-title">{gamePassPaymentMethodsTitles.savedMobileNumber}</div>
          {inactiveNumber.map((i, iIdx) => (
            <div key={`MobileNumbers_${i._id}_${iIdx}`} className="payment-methods-numbers-container">
              <div className="position-relative" onClick={() => handleSelectedNumber(i)}>
                <div className="payment-methods-numbers-text">{i.mobileNumber}</div>
                <KeyboardArrowRightRoundedIcon className="payment-methods-numbers-arrow" />
              </div>
            </div>
          ))}
        </div>}
      <div className="payment-methods-numbers-button-container">
        <div 
          onClick={onAddNumber} 
          className="payment-methods-numbers-button"
        >
          {gamePassPaymentMethodsTitles.addNumber}
        </div>
      </div>
    </div>
  )
}
