import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_HOME_PAGE_BANNERS,
  GET_WEN_LAMBO_PAGE_BANNERS
} from '../actions/constants';
import {
  setLoading,
  setHomePageDesktopBanner,
  setHomePageMobileBanner,
  setWenLamboPageDesktopBannerLogo,
  setWenLamboPageMobileBannerLogo,
  setWenLamboPageDesktopBannerBG,
  setWenLamboPageMobileBannerBG,
  setWenLamboSTWDesktopBanner,
  setWenLamboSTWMobileBanner
} from '../actions';
import {
  requestBannerImage,
  requestAllBannerImages
} from '../api/banners';
import {
  firebaseEvents,
  bannerSlugs,
  devicePlatforms,
  storageKeys
} from '../constants';
import { logFirebaseEvent } from '../utils/logFirebaseEvent';

const getBannerSaga = function* (slug, platform, action, size) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestBannerImage, sessionToken, slug, platform);
    if (response.status >= 200 && response.status < 400) {
      yield put(action(response.data.d.imageUrls[size]));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      logFirebaseEvent(
        firebaseEvents.failedEvent, {
          status_code: e.response.data.code, 
          error_message: e.response.data.message
        });
    }
  }
}

const getBannersSaga = function* (slugs, platform) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const slugNames = slugs.map(s => s.slug);
    const slugNamesStr = encodeURI(JSON.stringify(slugNames));
    const response = yield call(requestAllBannerImages, sessionToken, slugNamesStr, platform);
    if (response.status >= 200 && response.status < 400) {
      const data = response.data.d;
      yield all(slugs.map(s => {
        const action = s.action[platform];
        const size = s.size[platform];
        let imageData = data.find(d => d.key === s.slug);
        let image = '';
        if (imageData) image = imageData.imageUrls[size];
        return put(action(image));
      }));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      logFirebaseEvent(
        firebaseEvents.failedEvent, {
          status_code: e.response.data.code, 
          error_message: e.response.data.message
        });
    }
  }
}

const getHomePageBannersSaga = function* () {
  const slugs = [
    {
      platform: devicePlatforms.desktop,
      size: 'x2',
      action: setHomePageDesktopBanner
    },
    {
      platform: devicePlatforms.mobile,
      size: 'x3',
      action: setHomePageMobileBanner
    }
  ];
  yield all(slugs.map(s => (
    call(getBannerSaga, bannerSlugs.homePageBanner, s.platform, s.action, s.size)
  )));
}

const getWenLamboPageBannersSaga = function* () {
  const slugs = [
    {
      slug: bannerSlugs.wenLamboPageBanner,
      size: {
        [devicePlatforms.desktop]: 'x1',
        [devicePlatforms.mobile]: 'x2'
      },
      action: {
        [devicePlatforms.desktop]: setWenLamboPageDesktopBannerLogo,
        [devicePlatforms.mobile]: setWenLamboPageMobileBannerLogo
      }
    },
    {
      slug: bannerSlugs.wenLamboPageBannerBG,
      size: {
        [devicePlatforms.desktop]: 'x1',
        [devicePlatforms.mobile]: 'x2'
      },
      action: {
        [devicePlatforms.desktop]: setWenLamboPageDesktopBannerBG,
        [devicePlatforms.mobile]: setWenLamboPageMobileBannerBG
      }
    },
    {
      slug: bannerSlugs.wenLamboSTWBanner,
      size: {
        [devicePlatforms.desktop]: 'x2',
        [devicePlatforms.mobile]: 'x3'
      },
      action: {
        [devicePlatforms.desktop]: setWenLamboSTWDesktopBanner,
        [devicePlatforms.mobile]: setWenLamboSTWMobileBanner
      }
    }
  ];
  yield all(Object.values(devicePlatforms).map(p => (
    call(getBannersSaga, slugs, p)
  )))
}

export default function* bannersSaga() {
  yield all([
    takeLatest(GET_HOME_PAGE_BANNERS, getHomePageBannersSaga),
    takeLatest(GET_WEN_LAMBO_PAGE_BANNERS, getWenLamboPageBannersSaga)
  ]);
}