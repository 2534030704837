import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './TopUpPayment.scss';

export const FeeBreakdown = ({ rate, converted, totalFees }) => (
  <div className="payment-breakdown">
    <Row>
      <Col xs={8} sm={8} className="payment-breakdown-title">{rate.title}</Col>
      <Col xs={4} sm={4} className="payment-breakdown-value">{rate.text}</Col>
    </Row>
    <Row>
      <Col xs={8} sm={8} className="payment-breakdown-title">{converted.title}</Col>
      <Col xs={4} sm={4} className="payment-breakdown-value">{converted.text}</Col>
    </Row>
    <Row>
      <Col xs={8} sm={8} className="payment-breakdown-title">{totalFees.title}</Col>
      <Col xs={4} sm={4} className="payment-breakdown-value">{totalFees.text}</Col>
    </Row>
  </div>
)