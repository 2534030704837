export const singleQuantityAddToCart = 1;
export const resendVerificationCodeText = 'Resend verification code';
export const paymentMethods = {
  carrierX: 'CARRIER_X',
  ccdc: 'CCDC',
  gcash: 'PH_GCASH'
};
export const gamePassPaymentMethodsTitles = {
  globeLoad: 'Globe Load',
  paymentMethods: 'Payment Methods',
  saveDetails: 'Save Details',
  inputNumber: 'Input Number',
  setAsActiveNumber: 'Set as Active Number',
  addGlobeNumber: 'Add Globe Number',
  myActiveNumber: 'My Active Number',
  savedMobileNumber: 'Saved Mobile Numbers',
  addNumber: 'Add Number',
  delete: 'Delete'
};
export const switchColor = '#B47DFF';
export const checkoutButtonTexts = {
  pay: 'Pay',
  registerAndPay: 'Register & Pay',
  registerAndPayWithAmount: 'Register and Pay PHP 50.00'
};