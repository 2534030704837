import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Spend.scss';

const CheckoutTable = ({ data, id }) => (
  <div className="checkout-details-row">
    <span>{data.title}</span>
    <div className="checkout-details-details">
      <div className="checkout-table">
        {data.details.map(d => (
          <Row key={`${id}_${d.label}`}>
            <Col xs="6" className="checkout-table-label">{d.label}</Col>
            <Col xs="6" className="checkout-table-value">{d.value}</Col>
          </Row>
        ))}
      </div>
    </div>
  </div>
);

export default CheckoutTable;