import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import './Stats.scss';

const Stats = ({ stats, xs = 12, sm = 6, md = 4, lg = 6, xl = 4, fluid = false, noTopSpace = false }) => (
  <Row className={classNames("stats", {"stats-fluid": fluid, "stats-no-top-space": noTopSpace})}>
    {stats.map(s => (
      <Col key={`Stat_${s.label}`} xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="stat">
        <div className="stat-inner">
          <div className="stat-value">{s.value}</div>
          <div className="stat-label">{s.label}</div>
        </div>
      </Col>
    ))}
  </Row>
);

export default Stats;