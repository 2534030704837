import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { walletConnectTexts, wallets } from '../../constants';

export const GamePassCheckoutAmounts = ({ 
  handleBuyGamePass, 
  selectedPaymentMethod, 
  summary,
  openCurrency,
  currency,
  selectedCurrency,
  isDisabled,
  chain,
  isConnected
}) => {
  let chainName = isEmpty(selectedCurrency)
    ? isEmpty(currency)
      ? chain?.name
      : currency[0]?.chain
    : selectedCurrency?.chain;
  const shouldDisableButton = selectedPaymentMethod?.code === wallets.walletConnect.name &&
    (isEmpty(currency) ||
    isDisabled ||
    !isConnected)
  return (
    <div className="checkout-payment-bottom">
      <div className="checkout-payment-bottom-wrapper">
      {selectedPaymentMethod?.code === wallets.walletConnect.name && 
        chainName &&
        isConnected &&
        <div className={classNames(
          "checkout-payment-bottom-currency", 
          {"checkout-payment-bottom-currency-disabled": isEmpty(currency)}
        )}>
          <div className="checkout-payment-bottom-currency-title">{chainName}</div>
          <div className="checkout-payment-bottom-voucher-arrow" onClick={openCurrency}>
            <div>{walletConnectTexts.selectCurrency}</div>
            <KeyboardArrowRightRoundedIcon />
          </div>
        </div>}
        {selectedPaymentMethod?.code !== wallets.walletConnect.name &&
          <div className="checkout-payment-bottom-sub">
            <div>{summary.subTotal.title}</div>
            <div className="d-flex align-items-center gap-1">
              {!isEmpty(summary.subTotal.currencyIconUrl) &&
                <img
                  src={summary.subTotal.currencyIconUrl}
                  alt={summary.subTotal.currency}
                  className="checkout-payment-bottom-icon"
                />}
              <div className="checkout-card-price">{summary.subTotal.text}</div>
            </div>
          </div>}
        <div className="checkout-payment-bottom-voucher">
          <div className="checkout-payment-bottom-voucher-title">Game Pass Voucher</div>
          <div className="checkout-payment-bottom-voucher-arrow">
            <div>Apply Voucher</div>
            <KeyboardArrowRightRoundedIcon />
          </div>
        </div>
        {selectedPaymentMethod?.code !== wallets.walletConnect.name &&
          <div className="checkout-payment-bottom-sub">
            <div>{summary.serviceFee.title}</div>
            <div className="d-flex align-items-center gap-1">
              {!isEmpty(summary.subTotal.currencyIconUrl) &&
                <img
                  src={summary.subTotal.currencyIconUrl}
                  alt={summary.subTotal.currency}
                  className="checkout-payment-bottom-icon"
                />}
              <div className="checkout-card-price">{summary.serviceFee.text}</div>
            </div>
          </div>}
        <div className="checkout-payment-bottom-total">
          <div>{summary.total.title}</div>
          <div>{summary.total.text}</div>
        </div>
        <button 
          onClick={handleBuyGamePass}
          className={classNames(
            "checkout-payment-bottom-button",
            {"checkout-payment-bottom-button-disabled": shouldDisableButton}
          )}
        >
          {`Pay ${summary.total.text}`}
        </button>
      </div>
    </div>
  )
};
