import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Cookies from 'js-cookie';
import { 
  useNetwork, 
  erc20ABI, 
  useContractWrite
} from 'wagmi';
import {
  LayoutContent,
  PageTitle,
  Section,
  SelectCurrencyToast
} from '../Components';
import { Toast } from '../Commons';
import { GamePassPurchased } from './GamePassPurchased';
import { GamePassCheckoutAmounts } from './GamePassCheckoutAmounts';
import { GamePassCheckoutPaymentMethod } from './GamePassCheckoutPaymentMethod';
import { GamePassCheckoutSku } from './GamePassCheckoutSku';
import {
  setDisplayToast,
  getGamePassProductDetails,
  getGamePassCheckout,
  updateGamePassCheckout,
  getGamePassCheckoutSelectedCurrency,
  setLoading,
  disableCheckoutButton,
  payGamePassOnCheckout,
  showCurrencyToast,
  setGamePassCheckoutSelectedPaymentMethod,
  redirectToCheckoutPage
} from '../../actions';
import {
  currencies,
  defaultTimeoutMS,
  errorMessage,
  firebaseEvents,
  paymentMethods,
  siteRoutes,
  storageKeys,
  usdtABI,
  wallets,
} from '../../constants';
import { 
  checkoutContentsSelector, 
  checkoutPaySelector, 
  checkoutSelectCurrencySelector, 
  checkoutSelectedPaymentMethodSelector, 
  disableCheckoutButtonSelector, 
  displayLoginToastSelector,
  showCurrencyToastSelector,
  userWalletConnectedSelector, 
} from '../../selectors';
import { useQuery } from '../../hooks/useQuery';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { useWalletConnect } from '../../hooks/useWalletConnect';
import './GamePassCheckout.scss';

export const GamePassCheckout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const { chain } = useNetwork();
  const { address, connector, isConnected } = useWalletConnect();

  const [toastOpen, setToastOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState([]);
  const [summary, setSummary] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});

  const payDetails = useSelector(state => checkoutPaySelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const contents = useSelector(state => checkoutContentsSelector(state));
  const currency = useSelector(state => checkoutSelectCurrencySelector(state));
  const isDisabled = useSelector(state => disableCheckoutButtonSelector(state));
  const showCurrency = useSelector(state => showCurrencyToastSelector(state));
  const selectedPaymentMethod = useSelector(state => checkoutSelectedPaymentMethodSelector(state));
  const userWalletConnected = useSelector(state => userWalletConnectedSelector(state));

  const prevWalletConnectedRef = useRef(userWalletConnected);

  const productId = query.get('productId');
  const skuId = query.get('skuId');
  const pmSourceSessionStorage = sessionStorage.getItem(storageKeys.pmSource);
  const pmSourceCookie = Cookies.get(storageKeys.pmSource);
  
  useEffect(() => {
    if (prevWalletConnectedRef.current && !userWalletConnected) {
      dispatch(getGamePassCheckout());
    }
    prevWalletConnectedRef.current = userWalletConnected;
  }, [dispatch, userWalletConnected]);

  useEffect(() => {
    if (isEmpty(contents)) return;
    dispatch(redirectToCheckoutPage(false));
    dispatch(disableCheckoutButton(false));
    setItems(contents.items);
    setAcceptedPaymentMethods(contents.acceptedPaymentMethods);
    setSummary(contents.summary);
  }, [dispatch, contents])

  useEffect(() => {
    for (const method of acceptedPaymentMethods) {
      if (!isNil(contents.selectedPaymentMethod) &&
        contents.selectedPaymentMethod !== '' &&
        method.code === contents.selectedPaymentMethod) {
        dispatch(setGamePassCheckoutSelectedPaymentMethod(method));
      }
    }
  }, [dispatch, selectedPaymentMethod, items, acceptedPaymentMethods, contents])

  useEffect(() => {
    if (selectedPaymentMethod.code === wallets.walletConnect.name) {
      if (isConnected) {
        dispatch(getGamePassCheckoutSelectedCurrency({ chainId: chain?.id }));
      }
    }
  }, [dispatch, isConnected, selectedPaymentMethod, chain])

  useEffect(() => {
    if (!isEmpty(contents)) return;
    if (pmSourceSessionStorage && pmSourceCookie) {
      dispatch(updateGamePassCheckout({ 
        selectedPaymentMethod: paymentMethods.gcash, 
        navigate,
        shouldNotBack: true
      }));
      return;
    }
    dispatch(getGamePassCheckout());
  }, [dispatch, contents, navigate, pmSourceSessionStorage, pmSourceCookie]);

  useEffect(() => {
    if (!productId && !skuId) return;
    const productData = {
      productId: productId,
      skuId: skuId
    }
    dispatch(getGamePassProductDetails(productData));
  }, [dispatch, productId, skuId])

  useEffect(() => {
    if (!isEmpty(contents)
      && isEmpty(payDetails)) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.homeGamePassBuyForConfirmation, {
          sku_name: contents.items.map(i => i.name).join(', '),
          payment_method: contents.selectedPaymentMethod,
          page_url: window.location.pathname
        });
    }
  }, [contents, payDetails])

  useEffect(() => {
    if (!isEmpty(currency)) {
      for (const item of currency) {
        if (item?.isSelected) {
          setSelectedCurrency(item);
        }
      }
    } else {
      setSelectedCurrency('')
    }
  }, [currency])

  const { data, write, isLoading } = useContractWrite({ 
    address: selectedCurrency.address,
    abi: selectedCurrency.kind === currencies.USDT ? usdtABI : erc20ABI,
    functionName: 'transfer',
    args: [contents?.selectedPaymentMethodRecipient,
      contents?.summary?.total?.wei
    ],
    onError(error) {
      const splittedErrorMessage = error?.message?.split('\n');
      const firstErrorMessage = splittedErrorMessage[0]?.trim();
      if (firstErrorMessage?.includes(errorMessage.userRejectedRequest)) {
        return;
      }
      let message = firstErrorMessage;
      const usdtInsufficientBalance = selectedCurrency.kind === currencies.USDT && 
        error?.message?.includes(errorMessage.rpcRequestFailed);
      if (error?.message?.includes(errorMessage.transferAmountExceedsBalance) ||
        usdtInsufficientBalance) {
        message = errorMessage.insufficientBalance;
      }
      dispatch(setDisplayToast({ result: false, message }));
      dispatch(disableCheckoutButton(true));
    }
  });

  useEffect(() => {
    dispatch(setLoading(isLoading));
    return () => {
      dispatch(setLoading(false));
    }
  }, [dispatch, isLoading])

  useEffect(() => {
    if (data?.hash) {
      dispatch(payGamePassOnCheckout({ transactionHash: data.hash }));
    }
  }, [dispatch, data])

  const handleBuyGamePass = () => {
    if (isEmpty(contents)) return;

    if (contents.selectedPaymentMethod === paymentMethods.ccdc) {
      navigate(`${siteRoutes.payment}/card?channel=${contents.selectedPaymentMethod}&amount=${contents.summary?.total?.value}&currency=${contents.summary?.total?.currency}`);
      return;
    }

    if (contents.selectedPaymentMethod === wallets.walletConnect.name) {
      write?.();
    } else {
      dispatch(payGamePassOnCheckout());
    }

    if (isEmpty(payDetails)) {
      const qty = {};
      contents.items.map(i => {
        return qty[i.skuKind] = i.quantity;
      });
      logFirebaseEventWithTimestamp(
        firebaseEvents.homeGamePassBuyPay, {
          sku_name: contents.items.map(i => i.name).join(', '),
          payment_method: contents.selectedPaymentMethod,
          page_url: window.location.pathname,
          amount: contents.summary.total.value,
          qty: JSON.stringify(qty),
          currency: contents.summary.total.currency
        });
    }
  }
  
  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }
  
  const handleBack = () => {
    navigate(-1);
  }

  const openCurrency = () => {
    dispatch(showCurrencyToast(true));
  }

  const hideCurrency = () => {
    dispatch(showCurrencyToast(false));
  }

  return (
    <LayoutContent outerClassName="checkout" innerClassName="checkout-inner">
      <PageTitle title="Game Pass Checkout" />
      <Section 
        sectionClassName="checkout-section"
        backButtonRoute={isEmpty(payDetails)}
        backRoute={handleBack}
        title={isEmpty(payDetails) && "Game Pass Checkout"}
        titleCentered
        text="Back"
      >
        {isEmpty(contents) &&
          <div className="checkout-empty">
            <div>You have no items to checkout yet</div>
          </div>}
        {!isEmpty(contents) && 
          isEmpty(payDetails) && 
          <div className="checkout-section-content">
            <div className="w-100">
              <GamePassCheckoutSku items={items} /> 
              <GamePassCheckoutPaymentMethod 
                selectedPaymentMethod={selectedPaymentMethod} 
                navigate={navigate} 
                connector={connector}
                address={address}
                isConnected={isConnected}
              />
            </div>
            {!isEmpty(summary) &&
              selectedPaymentMethod !== '' &&
              <GamePassCheckoutAmounts 
                selectedPaymentMethod={selectedPaymentMethod} 
                summary={summary} 
                handleBuyGamePass={handleBuyGamePass}
                openCurrency={openCurrency}
                currency={currency}
                selectedCurrency={selectedCurrency}
                isDisabled={isDisabled}
                chain={chain}
                isConnected={isConnected}
              />}
          </div>}
        {!isEmpty(payDetails) && <GamePassPurchased />}
      </Section>
      <SelectCurrencyToast 
        chain={chain}
        open={showCurrency} 
        hide={hideCurrency} 
      />
      {toast && 
        <Toast toast={toast} open={toastOpen} hide={closeToast}/>}
    </LayoutContent>
  );
}
