import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import MediaQuery from 'react-responsive';
import {
  BackArrow,
  Banner,
  ImagePreview
} from '../Commons';
import {
  Featured,
  LayoutContent,
  PageTitle,
  Section,
  Stats,
  TournamentBanner } from '../Components';
import Gallery from './Gallery';
import GameInfo from './GameInfo';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import {
  firebaseEvents,
  maxWidths,
  minWidths
} from '../../constants';

export class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameName: window.location.pathname.split('/').slice(1)[1],
      imagePreviewUrl: ''
    };
  }

  componentDidMount() {
    if (this.state.gameName !== 'games' && this.state.gameName !== 'game') {
      this.props.getGameDetails(this.state.gameName);
    }
  }

  componentDidUpdate(prevProps) {
    const { game } = this.props;
    if (prevProps.game !== game) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.gamesPage, {
          game_name: game.name
        });
    }
  }

  openImage = (imagePreviewUrl) => {
    this.setState({ imagePreviewUrl });
  }

  render() {
    const { game } = this.props;
    const { imagePreviewUrl } = this.state;

    if (game && !isEmpty(game)) {
      const isNonPartner = game.game_type && game.game_type === 'nonPartner';

      let stats = null;
      if (isNonPartner && game.participants) {
        stats = [
          { label: 'Participants', value: game.participants }
        ];
      }

      return (
        <LayoutContent outerClassName="game" innerClassName="game-inner">
          <PageTitle title={game.name} />
          <MediaQuery minWidth={minWidths.tablet}>
            <BackArrow text="Back" isInline />
          </MediaQuery>
          <MediaQuery maxWidth={maxWidths.mobileSM}>
            <BackArrow isInline />
          </MediaQuery>
          <Featured fluid>
            {isNonPartner ? 
              <TournamentBanner
                title={game.name}
                text={game.short_desc}
                imageUrl={game.image_url}
                stats={stats}
                buttons={
                  <div className="f2p-game-buttons">
                    <a
                      href={game.launch_url}
                      target="_blank"
                      rel="noreferrer"
                      id={game.id}
                      name={game.id}
                      className="f2p-game-button"
                    >
                      Play Now
                    </a>
                  </div>
                }
              >
                {(game.mechanics || game.screenshots) && <div className="game-sections">
                  {game.mechanics && <Section title="Mechanics" sectionClassName="game-mechanics-section">
                    <div className="game-mechanics">{game.mechanics}</div>
                  </Section>}
                  {game.screenshots.length > 0 && <Gallery
                    title="Gallery"
                    screenshots={game.screenshots}
                    openImage={this.openImage}
                    compressed={!isNil(game.participants)}
                    sectionClassName="gallery-section"
                  />}
                </div>}
              </TournamentBanner> :
              <Banner
                id={game.id}
                title={game.name}
                text={game.short_desc}
                imageUrl={game.image_url}
                buttonText="Launch Game"
                buttonId="launchGame"
                buttonLink={game.launch_url}
                buttonAnchor
                openInNewTab
                imageClickable
              >
                <Stats stats={game.stats} lg={4} fluid />
              </Banner>}
          </Featured>
          {!isNonPartner && <>
            {game.screenshots && game.screenshots.length > 0 && <Gallery
              title="Gallery"
              screenshots={game.screenshots}
              openImage={this.openImage}
            />}
            <GameInfo info={game.game_info} />
          </>}
          <ImagePreview url={imagePreviewUrl} openImage={this.openImage} />
        </LayoutContent>
      );
    }

    return null;
  }
}