import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export const SpendSuccess = ({
  currentStep,
  translate,
  selectedProduct,
  mobileNumber,
  isError,
}) => {
  const navigate = useNavigate();
  const referenceId = useSelector((state) => state.spend.referenceId);
  const { product_currency, product } = selectedProduct;
  
  return (
    <div className="success spend-third-step" style={translate}>
      <button onClick={currentStep} className="success-back">
        <ArrowBackIosNewRoundedIcon className="success-back-icon" />
      </button>
      <div id="spend-third-step" className="success-container">
        <div className="success-wrapper">
          <div
            className={classNames(
              { "success-wrapper-success": !isError.result },
              { "success-wrapper-failed": isError.result }
            )}
          ></div>
          {isError.result ? <h3>Payment Failed</h3> : <h3>Transaction is processing.</h3>}
          {!isError.result && <p>Transaction ID: {referenceId}</p>}
          {isError.result ? (
            <p>{isError.message}</p>
          ) : (
            <>
              <p>{product && product.toFixed(2)} PHP Load</p>
              <p>We are processing your load transaction request. You will receive an in-app notification on the status of your transaction. You will receive your mobile load if transaction is successful.</p>
            </>
          )}
        </div>
        <button onClick={() => navigate("/")}>Back to Home Page</button>
      </div>
    </div>
  );
};

export default SpendSuccess