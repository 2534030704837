import axios from 'axios';
import { baseUrl } from './url';

export function requestQuests(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/quests')
  });
}

export function requestQuestLeaderboards(url) {
  return axios.request({
    method: 'POST',
    url
  });
}