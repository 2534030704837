import basicPrizes from '../assets/spin/mechanics/overview/basic-prizes.png';
import localMegaPrizes from '../assets/spin/mechanics/overview/local-mega-prizes.png';
import globalMegaPrizes from '../assets/spin/mechanics/overview/global-mega-prizes.png';
import mgoCredits from '../assets/spin/mechanics/basic-prizes/mgo-credits.png';
import boosters from '../assets/spin/mechanics/basic-prizes/boosters.png';
import collectibles from '../assets/spin/mechanics/basic-prizes/digital-collectibles.png';
import giftCertificates from '../assets/spin/mechanics/basic-prizes/gift-certificates.png';
import mechanic1 from '../assets/spin/mechanics/carousel/1.png';
import mechanic2 from '../assets/spin/mechanics/carousel/2.png';
import mechanic3 from '../assets/spin/mechanics/carousel/3.png';
import mechanic3Global from '../assets/spin/mechanics/carousel/3-global.png';
import mechanic4 from '../assets/spin/mechanics/carousel/4.png';
import mechanic5 from '../assets/spin/mechanics/carousel/5.png';
import mechanic6 from '../assets/spin/mechanics/carousel/6.png';
import mechanic7 from '../assets/spin/mechanics/carousel/7.png';
import megaPrize1 from '../assets/spin/mechanics/mega-prizes/1.png';
import megaPrize2 from '../assets/spin/mechanics/mega-prizes/2.png';
import megaPrize3 from '../assets/spin/mechanics/mega-prizes/3.png';
import megaPrize4 from '../assets/spin/mechanics/mega-prizes/4.png';
import megaPrize5 from '../assets/spin/mechanics/mega-prizes/5.png';
import megaPrize6 from '../assets/spin/mechanics/mega-prizes/6.png';
import megaPrize7 from '../assets/spin/mechanics/mega-prizes/7.png';
import megaPrize8 from '../assets/spin/mechanics/mega-prizes/8.png';
import megaPrize9 from '../assets/spin/mechanics/mega-prizes/9.png';
import megaPrize10 from '../assets/spin/mechanics/mega-prizes/10.png';
import megaPrize11 from '../assets/spin/mechanics/mega-prizes/11.png';
import megaPrize12 from '../assets/spin/mechanics/mega-prizes/12.png';

export const extraGDayPrizes = { 
  1: 'YOU_LOSE',
  2: 'MGO_CREDIT_1',
  3: 'GLOBE_LOAD_20',
  4: 'METAPUP_TEAL_NFT',
  5: 'GLOBE_LOAD_20',
  6: 'YOU_LOSE',
  7: 'GLOBE_LOAD_50',
  8: 'FREE_SPIN',
  9: "GCASH_CREDIT_1000",
  10: 'YOU_LOSE',
  11: "MGO_CREDIT_1",
  12: 'GLOBE_LOAD_20',
  13: "METAPUP_TEAL_NFT",
  14: 'GLOBE_LOAD_20',
  15: 'YOU_LOSE',
  16: 'GLOBE_LOAD_50',
  17: 'FREE_SPIN',
  18: 'IPHONE_13_PRO'
};
export const imagePrizes = {
  loadTwenty: 'GLOBE_LOAD_20',
  loadFifty: 'GLOBE_LOAD_50',
  youLose: 'YOU_LOSE',
  noPrize: 'NO_PRIZE',
  freeSpin: 'FREE_SPIN',
  mgc: 'MGO_CREDIT_1',
  metapup: 'METAPUP_TEAL_NFT',
  gcash: 'GCASH_CREDIT_1000',
  uniqlo: 'sm-uniqlo-gc-1000',
  plains: 'sm-plains-and-prints-gc-1000',
  moment: 'sm-the-moment-gc-500',
  crocs: 'sm-crocs-gc-500',
  slimmers: 'sm-slimmers-world-gc-1500',
  slimmersMain: 'sm-slimmers-world-gc-2500',
  kamsRoast: 'sm-kams-roast-gc-500',
  theMensRoom: 'sm-the-mens-room-gc-100',
  collezione: 'sm-collezione-gc-1000',
  arrowLand: 'sm-arrowland-gc-250',
  theNewEra: 'sm-new-era-gc-500',
  botejyu: 'sm-botejyu-gc-500',
  iphone: 'IPHONE_13_PRO',
};
export const componentClasses = {
  spinLayout: 'spin-layout',
  spinLayoutSm: 'spin-layout-sm',
  spinBody: 'spin-body-head',
  spinBodySm: 'spin-body-head-sm',
  wheel: 'wheel',
  wheelSm: 'wheel-sm'
};
export const spinTheWheelMechanics = {
  title: "Mechanics & Prize Pool",
  dtiText: "Per DTI Fair Trade Permit No. FTEB-161789 Series of 2023",
  sections: [
    {
      name: "Overview",
      isLocal: true,
      title: [
        "Special Prize to Win!",
        "Cool Prizes for Grabs!"
      ],
      imageUrl: [
        localMegaPrizes,
        basicPrizes,
      ],
      description: [
        "Get a chance to win this SPECIAL PRIZE with every spin and drive home a brand-new <b> NMAX! </b>",
        "But wait, more prizes await you! Just keep playing to earn more Gold to spin and get more chances of winning.",
        "Instantly win any of our other prizes, such as MetaverseGo Credits (MGC) for in-app purchases, Boosters to up your Gold earnings, fancy Digital Collectibles, and redeemable gift certificates from brands.",
      ],
      prizeTitle: [
        "Mega Prizes",
        "Basic Prizes"
      ],
      prizes: [
        {
          imageUrl: mgoCredits,
          title: "MetaverseGo Credits",
          description: "MetaverseGo Credits or MGCs are digital credits that can be used to purchase load, Game Pass, NFTs, etc.",
        },
        {
          imageUrl: boosters,
          title: "Gold Boosters",
          description: "x2 or x3 Gold Boosters will be stored in your inventory to multiply Gold earnings!",
        },
        {
          imageUrl: collectibles,
          title: "Digital Collectibles",
          description: "Digital art that will be stored in your MetaverseGo Wallet",
        },
        {
          imageUrl: giftCertificates,
          title: "Gift Certificates",
          description: "Claim and enjoy discount vouchers from our sponsor brands!",
        },
      ],
      specialPrizes: [
        {
          imageUrl: megaPrize1,
          title: "Motorcycle - NMAX 155",
          details: "Yamaha NMAX 155",
          pesoValue: "PHP 150,800.00",
        }
      ]
    },
    {
      name: "Eligibility",
      description: "To be eligible for a spin on the wheel, you must:<ul><li><div>Be a registered MetaverseGo user.</div></li><li><div>You need a minimum of 1,000 Gold to be eligible for a single spin on the wheel.</div></li></ul><p><i>Note: Employees of Xurpas and MetaverseGo, including their relatives up to 2nd degree of consanguinity or affinity, are disqualified from joining.</i></p>"
    },
    {
      name: "Mechanics",
      carousel: [
        {
          imageUrl: mechanic1,
          title: "Play and Earn Gold",
          description: "Earn and accumulate Gold by playing any of the MetaverseGo games."
        },
        {
          imageUrl: mechanic2,
          title: "Gold Coins",
          description: "Once you have accumulated 1,000 Gold, you can exchange it for a spin on the wheel."
        },
        {
          imageUrl: mechanic3,
          title: "Click Spin the Wheel",
          description: "Click the SPIN NOW button to redirect you to the Spin the Wheel page."
        },
        {
          imageUrl: mechanic4,
          title: "Click Spin",
          description: "Click the Spin button found at the bottom of the wheel."
        },
        {
          imageUrl: mechanic6,
          title: "Prize Reveal",
          description: "Click Stop at the bottom of the wheel to stop the wheel from turning."
        },
        {
          imageUrl: mechanic7,
          title: "Prize Reveal",
          description: "You will be informed of the result of your spin right away!"
        }
      ],
      stw: true,
    },
    {
      name: "FAQs",
      title: "Spin the Wheel FAQs",
      faqs: [
        {
          question: "What do I need to spin the wheel?",
          answer: "You need 1,000 Gold to be able to spin the wheel and get a chance to win prizes!"
        },
        {
          question: "How do I spin the wheel?",
          answer: "Click the Spin the Wheel button on the Wen Lambo page. At the Spin the Wheel page, click the Spin button to start spinning the wheel. Click the Stop button to determine if you have won a prize."
        },
        {
          question: "What are the prizes that I can win?",
          answer: "You can win MetaverseGo Credits (MGC), Gold Boosters, Digital Collectibles, Gift Certificates, a brand-new NMAX, and more. "
        },
        {
          question: "It’s my lucky day. I won a prize. How can I claim it?",
          answer: "Here are the ways to claim your prize depending on what you have won:<ul><li><div>If you won MetaverseGo Credits, kindly check your MetaverseGo Wallet to use your MGC.</div></li><li><div>If you won an NFT, kindly check your MetaverseGo Wallet under Collectibles.</div></li><li><div>If you won a Gold Booster, you may activate this in your Game Pass Inventory.</div></li><li><div>If you won a Gift Certificate, please go to the Rewards Center to claim your prize.</div></li></ul>"
        },
        {
          question: "Why can’t I spin the wheel?",
          answer: "This means you don’t have enough Gold for a spin. Make sure to play more to collect more Gold, and come back for a spin for a chance to win our cool prizes!"
        },
        {
          question: "I have won once. Can I spin and win again?",
          answer: "Definitely! You can win more than once, so feel free to spin the wheel as long as you have enough Gold for spins."
        },
      ]
    },
    {
      name: "Terms & Conditions",
      title: "Spin the Wheel Prizes General Terms & Conditions",
      items: [
        "All Digital items are transferable but NOT convertible to cash",
        "Special Prize is convertible to cash.",
        "The Special Prize's exact model and color are subject to availability.",
        "The PHP amounts are final.", 
        "Any and all taxes for prizes exceeding P10,000 will be shouldered by the winner.",
        "Depending on the prize, winners must present one valid ID and other necessary details to successfully process and claim the prizes.",
        "The use of a Virtual Private Network (VPN) shall strictly be prohibited. Any user caught using the VPN to bypass the local restrictions shall be duly sanctioned in addition to the forfeiture of his/her prize reward.",
        "Users currently residing in the Philippines are not eligible to avail the global promo.",
        "MetaverseGo is not liable for any mistakes made by the user when typing the pertinent details. The submitted details are considered final and irrevocable.",
        "MetaverseGo reserves the right to publish the name, region address, and photographs of the winners. This rule is one of the conditions of participation on the Website, which allows MetaverseGo to provide the public with proof of the prizes won by users. This constitutes a fundamental transparency issue.<p>As a winner of a special item, the winner may be asked, depending on advertising needs, to take part in a photo session and a brief interview about the circumstances surrounding his/her win. The following rules apply to the photo session:</p><ol><li><div>The face must be uncovered.</div></li><li><div>MetaverseGo may require a photo ID to confirm the winner's usual appearance. If a winner refuses to comply with these rules, MetaverseGo reserves the right to suspend the payment of the prize.</div></li></ol><p>The information gathered may be used to write a press release sent to the media. The winner's information, photos, and videos may also appear in one of MetaverseGo's commercials, including but not limited to television and radio, as well as on the website.<p>",
      ]
    }
  ]
};
export const spinTheWheelMechanicsGlobal = {
  title: "Mechanics & Prize Pool",
  sections: [
    {
      name: "Overview",
      title: [
        "Special Prizes to be Unlocked!",
        "Cool Prizes for Grabs!",
      ],
      imageUrl: [
        globalMegaPrizes,
        basicPrizes,
      ],
      description: [
        "Play with your friends to reach the COMMUNITY MILESTONES and unlock MetaverseGo’s SPECIAL PRIZES. The more Game Passes everyone gets, the grander prizes to be won!<br><br><p>Win these SPECIAL PRIZES with every spin and drive home a brand-new <b>Lamborghini</b> after the final milestone is unlocked.</p>",
        "To unlock the special prizes, you must:<ul><li><div>Work with the community of players to reach the target number of Game Passes used to unlock specific COMMUNITY MILESTONES.</div></li><li><div>Purchase more Game Passes!</div></li></ul><p>But wait! More prizes await you! You don’t need to unlock any of our other fantastic prizes to bag them. Just keep playing to earn more Gold to spin and get more chances of winning.</p>",
        "Instantly win any of our other prizes without unlocking. Bag our such as MetaverseGo Credits (MGC) for in-app purchases, Boosters to up your Gold earnings, fancy Digital Collectibles, and redeemable gift certificates from brands."
      ],
      prizeTitle: [
        "Mega Prizes",
        "Basic Prizes"
      ],
      prizes: [
        {
          imageUrl: mgoCredits,
          title: "MetaverseGo Credits",
          description: "MetaverseGo Credits or MGCs are digital credits that can be used to purchase load, Game Pass, NFTs, etc.",
        },
        {
          imageUrl: boosters,
          title: "Gold Boosters",
          description: "x2 or x3 Gold Boosters will be stored in your inventory to multiply Gold earnings!",
        },
        {
          imageUrl: collectibles,
          title: "Digital Collectibles",
          description: "Digital art that will be stored in your MetaverseGo Wallet",
        },
        {
          imageUrl: giftCertificates,
          title: "Gift Certificates",
          description: "Claim and enjoy discount vouchers from our sponsor brands!",
        },
      ],
      specialPrizes: [
        {
          imageUrl: megaPrize1,
          title: "Motorcycle - NMAX 155",
          details: "Yamaha NMAX 155",
          usdValue: "3000 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize2,
          title: "Gift Certificate",
          details: "SM, Sodexo, etc",
          usdValue: "4050 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize3,
          title: "Gaming Setup",
          details: "Monitor, Keyboard, PC, Mouse",
          usdValue: "5468 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize4,
          title: "Gas / Fleet Card",
          details: "Either Shell or Petron",
          usdValue: "7382 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize5,
          title: "Groceries Gift Certificate",
          details: "SM, Puregold, etc",
          usdValue: "9966 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize6,
          title: "Toyota Lite Ace",
          details: "Panel Van",
          usdValue: "13455 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize7,
          title: "Toyota Vios",
          details: "1.5G CVT",
          usdValue: "18165 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize8,
          title: "BMW Motorcycle",
          details: "2022 BMW S 1000 R",
          usdValue: "24523 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize9,
          title: "Toyota Corolla Cross",
          details: "Corolla Cross GR-S HEV",
          usdValue: "33107 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize10,
          title: "Toyota Fortuner",
          details: "2.8 4x2 LTD AT",
          usdValue: "44695 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize11,
          title: "Lexus IS 300h Executive",
          details: "Lexus IS 300h Executive",
          usdValue: "60339 USD",
          quantity: "1",
        },
        {
          imageUrl: megaPrize12,
          title: "Lamborghini",
          details: "Lamborghini Huracan",
          usdValue: "200000 USD",
          quantity: "1",
        },
      ]
    },
    {
      name: "Eligibility",
      description: "To be eligible for a spin on the wheel, you must:<ul><li><div>Be a registered MetaverseGo user.</div></li><li><div>Must have at least 1,000 Gold to pay for one (1) chance to spin the wheel.</div></li></ul><p><i>Note: Employees of Xurpas and MetaverseGo, including their relatives up to 2nd degree of consanguinity or affinity, are disqualified from joining.</i></p>"
    },
    {
      name: "Mechanics",
      carousel: [
        {
          imageUrl: mechanic1,
          title: "Play and Earn Gold",
          description: "Earn and accumulate Gold by playing any of the MetaverseGo games."
        },
        {
          imageUrl: mechanic2,
          title: "Gold Coins",
          description: "Once you have accumulated 1,000 Gold, you can exchange it for a spin on the wheel."
        },
        {
          imageUrl: mechanic3Global,
          title: "Click Spin the Wheel",
          description: "Click the SPIN NOW button to redirect you to the Spin the Wheel page."
        },
        {
          imageUrl: mechanic5,
          title: "Unlock Special Prizes",
          description: "Make sure to buy more game passes to reach community targets and unlock special prizes."
        },
        {
          imageUrl: mechanic4,
          title: "Click Spin",
          description: "Click the Spin button found at the bottom of the wheel."
        },
        {
          imageUrl: mechanic6,
          title: "Unlock Special Prizes",
          description: "Click Stop at the bottom of the wheel to stop the wheel from turning."
        },
        {
          imageUrl: mechanic7,
          title: "Prize Reveal",
          description: "You will be informed of the result of your spin right away!"
        },
      ],
      stw: true,
    },
    {
      name: "FAQs",
      title: "Spin the Wheel FAQs",
      faqs: [
        {
          question: "What do I need to spin the wheel?",
          answer: "You need 1,000 Gold to be able to spin the wheel and get a chance to win prizes!"
        },
        {
          question: "How do I spin the wheel?",
          answer: "Click the Spin the Wheel button on the Wen Lambo page. At the Spin the Wheel page, click the Spin button to start spinning the wheel. Click the stop button to determine if you have won a prize."
        },
        {
          question: "What are the prizes that I can win?",
          answer: "You can win MetaverseGo Credits (MGC), Boosters, Digital Collectibles, or unlock special prizes like a Toyota Fortuner!"
        },
        {
          question: "It’s my lucky day. I won a prize. How can I claim it?",
          answer: "Here are the ways to claim your prize depending on what you won:<ul><li><div>If you won MetaverseGo Credits, kindly check your MetaverseGo Wallet to use your MGC.</div></li><li><div>If you won an NFT, kindly check your MetaverseGo Wallet under Collectibles.</div></li><li><div>If you won a Gold Booster, you may activate this on your Game Pass Inventory.</div></li><li><div>If you won a Gift Certificate, please go to Rewards Center to claim your prize.</div></li></ul>"
        },
        {
          question: "Why can’t I spin the wheel?",
          answer: "This means you don’t have enough Gold for a spin. Make sure to play more to collect more Gold, and come back for a spin to get a chance to win our cool prizes!"
        },
        {
          question: "I have won once. Can I spin and win again?",
          answer: "Definitely! You can win more than once, so feel free to spin the wheel as much as you have Gold for spins."
        },
        {
          question: "What happens when someone wins a Special Prize?",
          answer: "The special prize will be removed from the list of prizes and can no longer be won."
        },
        {
          question: "Why did I not get the locked Special Prize?",
          answer: "Locked Special Prizes can be unlocked reaching the community targets. You can help the community by buying more Game Passes to unlock Special Prizes!"
        }
      ]
    },
    {
      name: "Terms & Conditions",
      title: "Spin the Wheel Prizes General Terms & Conditions",
      items: [
        "All Digital items are transferable but NOT convertible to cash.",
        "Special Prize is convertible to cash.",
        "The USD amounts are final.",
        "Any and all applicable taxes for prizes will be shouldered by and be the responsibility of the winner.",
        "Depending on the prize, winners must present one valid ID and other necessary details to successfully process and claim the prizes.",
        "The use of a Virtual Private Network (VPN) shall strictly be prohibited. Any user caught using the VPN to bypass the local restrictions shall be duly sanctioned in addition to the forfeiture of his/her prize reward.",
        "This global promo is unavailable for users currently residing in the Philippines.", 
        "MetaverseGo is not liable for any mistakes made by the user when typing the pertinent details. The submitted details are considered final and irrevocable.",
        "MetaverseGo reserves the right to revoke rewards for any behavior not compliant with the Game Pass and MetaverseGo Terms and Conditions.",
        "MetaverseGo reserves the right to publish the name, region address, and photographs of the winners. This rule is one of the conditions of participation on the Website, which allows MetaverseGo to provide the public with proof of the prizes won by users. This constitutes a fundamental transparency issue.<p>As a winner of a special item, the winner may be asked, depending on advertising needs, to take part in a photo session and a brief interview about the circumstances surrounding his/her win. The following rules apply to the photo session:</p><ol><li><div>The face must be uncovered.</div></li><li><div>MetaverseGo may require a photo ID to confirm the winner's usual appearance. If a winner refuses to comply with these rules, MetaverseGo reserves the right to suspend the payment of the prize.</div></li></ol><p>The information gathered may be used to write a press release sent to the media. The winner's information, photos, and videos may also appear in one of MetaverseGo's commercials, including but not limited to television and radio, as well as on the website.<p>",
      ]
    }
  ]
};
export const spinTheWheelSections = {
  overview: 'Overview',
  eligibility: 'Eligibility',
  mechanics: 'Mechanics',
  faqs: 'FAQs',
  termsAndConditions: 'Terms & Conditions',
};
export const spinModalTitles = {
  header: 'Congratulations!',
  description: 'Enjoy <b><strong>1,000 Gold</strong></b> and use it to spin the wheel for a chance to win over P50M worth of prizes!',
  buttonText: 'Spin with 1000 Gold'
};
export const spinTheWheelShareParams = {
  share: 'share',
  prize: 'prize',
  prizeImageUrl: 'prizeImageUrl',
  testPrize: 'testPrize',
  testDayOfWeek: 'testDayOfWeek',
  testHourOfDayInUTC: 'testHourOfDayInUTC'
};
export const spinTheWheelSharePlatforms = [
  'whatsapp',
  'twitter'
];