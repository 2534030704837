import React, { Component } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { LayoutContent } from '../Components';
import { TutorialSwipeable } from './TutorialSwipeable';
import { storageKeys } from '../../constants';

export class Tutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0
    };
  }

  componentDidMount() {
    const tutorialLink = localStorage.getItem(storageKeys.tutorialLink);
    const fromProfilePage = tutorialLink && tutorialLink !== '';
    this.props.getTutorialScreens(fromProfilePage);
  }

  selectIndex = (selectedIndex) => {
    this.setState({ selectedIndex });
  }

  onLinkClick = () => {
    if (localStorage.getItem(storageKeys.tutorialLink)) {
      localStorage.removeItem(storageKeys.tutorialLink);
    }
  }

  render() {
    const { tutorialScreens } = this.props;
    const { selectedIndex } = this.state;
    const tutorialLink = localStorage.getItem(storageKeys.tutorialLink) || '/';

    if (tutorialScreens && !isEmpty(tutorialScreens)) {
      return (
        <LayoutContent outerClassName="tutorials-page">
          <TutorialSwipeable screens={tutorialScreens} selectedIndex={selectedIndex} selectIndex={this.selectIndex} />
          <div className="tutorials-indicators">
            {tutorialScreens.map((s, idx) => (
              <button
                key={`TutorialIndicator_${idx}_${s.title}`}
                className={classNames("tutorials-indicators-button", {"active": idx === selectedIndex})}
                onClick={() => this.selectIndex(idx)}
              ></button>
            ))}
          </div>
          <div className="tutorials-buttons">
            {
              selectedIndex === 0 ? <a href={tutorialLink} className="tutorial-skip" onClick={this.onLinkClick}>Skip</a> :
              <button className="tutorial-prev" onClick={() => this.selectIndex(selectedIndex - 1)}>
                <KeyboardArrowLeftRoundedIcon className="tutorial-arrow" />Prev
              </button>
            }
            {
              selectedIndex === tutorialScreens.length - 1 ? <a href={tutorialLink} className="tutorial-go" onClick={this.onLinkClick}>Done</a> :
              <button className="tutorial-next" onClick={() => this.selectIndex(selectedIndex + 1)}>
                Next<KeyboardArrowRightRoundedIcon className="tutorial-arrow" />
              </button>
            }
          </div>
        </LayoutContent>
      );
    }

    return null;
  }
}