import React, { Component } from 'react';
import { load } from 'recaptcha-v3';
import Cookies from 'js-cookie';
import { HiddenHeaderAndFooter, Toast } from '../Commons';
import { PINRecoveryPopup } from './PINRecoveryPopup';
import { PINKeyboardPage } from './PINKeyboardPage';
import { PINAuthentication } from './PINAuthentication';
import { PINAccountRecovery } from './PINAccountRecovery';
import { 
  authSteps,
  defaultTimeoutMS, 
  duration, 
  firebaseEvents, 
  otpDefaultTimes, 
  recaptchaSiteKey, 
  regexConstants, 
  storageKeys 
} from '../../constants';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import {
  logFirebaseEvent,
  logFirebaseEventWithTimestamp
} from '../../utils/logFirebaseEvent';
import './PIN.scss';

var CryptoJS = require("crypto-js");

export class PIN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptcha: {},
      isRecaptchaLoaded: false,
      deviceInfo: getDeviceInfo(),
      toastOpen: false,
      pinRecoveryPopupOpen: false,
      pinFormData: {
        pin: '',
        confirm: '',
      },
      invalidPINFormInputs: [],
      emailIsActive: false,
      otpMinutes: otpDefaultTimes.minutes,
      otpSeconds: otpDefaultTimes.seconds
    };
  }

  componentDidMount() {
    const isDailyRewardsShown = Cookies.get(storageKeys.isDailyRewardsShown);

    this.getRecaptcha();
    this.props.setLoading(false);
    setTimeout(() => {
      this.props.setShowLoading(false);
    }, duration.pointOneSecond)
    logFirebaseEventWithTimestamp(firebaseEvents.loginPIN);

    if (isDailyRewardsShown) {
      Cookies.remove(storageKeys.isDailyRewardsShown);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toast !== this.props.toast && this.props.toast) {
      this.openToast(true);
    }
  }

  getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        this.setState({
          recaptcha,
          isRecaptchaLoaded: true
        });
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  openPINRecoveryPopup = (pinRecoveryPopupOpen) => {
    this.setState({ pinRecoveryPopupOpen });
  };

  sendCode = () => {
    const { isRecaptchaLoaded, recaptcha, deviceInfo } = this.state;
    const payload = { deviceInfo };

    this.openPINRecoveryPopup(false);
    this.props.setLoading(true);

    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          this.props.recoverPIN(updatedPayload);
        })
        .catch(error => {
          this.props.recoverPIN(payload);
        });
    } else {
      this.props.recoverPIN(payload);
    }
  };

  submitOTP = (otpDigits) => {
    const otp = otpDigits.join('');
    const encryptedMobileNumber = localStorage.getItem(storageKeys.mobileNumber);
    const bytes = CryptoJS.AES.decrypt(encryptedMobileNumber, storageKeys.mobileNumber.toLowerCase());
    const mobileNumberLS = bytes.toString(CryptoJS.enc.Utf8).replace(regexConstants.quotes, '');

    let mobnum = '';
    if (mobileNumberLS && mobileNumberLS !== '') mobnum = mobileNumberLS;

    this.props.setLoading(true);
    this.props.submitPINOTP({ otp, mobnum });
  };

  handleEmailLogin = (e) => {
    const encryptedMobileNumber = localStorage.getItem(storageKeys.mobileNumber);
    const bytes = CryptoJS.AES.decrypt(encryptedMobileNumber, storageKeys.mobileNumber.toLowerCase());
    const mobileNumber = bytes.toString(CryptoJS.enc.Utf8).replace(regexConstants.quotes, '');
    const payload = {
      mobileNumber,
      deviceInfo: this.state.deviceInfo
    };

    e.preventDefault(e);

    if (mobileNumber && mobileNumber !== '') {
      if (this.state.isRecaptchaLoaded) {
        this.state.recaptcha.execute('login')
          .then(t => {
            const updatedPayload = {
              ...payload,
              recaptchaToken: t
            }
            this.props.resendEmailOTP(updatedPayload);
          })
          .catch(error => {
            this.props.resendEmailOTP(payload);
          });
      } else {
        this.props.resendEmailOTP(payload);
      }
    }
  };

  resendOTP = () => {
    this.setState({ emailIsActive: true });
  }

  stopTimerEmail = () => {
    this.setState({ emailIsActive: false });
  }

  backToPIN = () => {
    const { setAuthStep, startOTPTimer } = this.props;
    setAuthStep(authSteps.login);
    startOTPTimer(false);
    this.stopTimerEmail();
  };

  handlePINInput = (e, fieldName) => {
    this.setState({
      invalidPINFormInputs: this.state.invalidPINFormInputs.filter(
        (i) => i !== fieldName
      ),
      pinFormData: {
        ...this.state.pinFormData,
        [fieldName]: e.target.value,
      },
    });
  }
  
  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  }

  closeToast = () => {
    this.openToast(false);
    setTimeout(() => this.props.setDisplayToast(), defaultTimeoutMS);
  }

  enterPIN = (pin) => {
    this.props.setLoading(true);
    this.props.submitPIN(pin);
  }

  submitPIN = (e) => {
    const { pinFormInputs } = this.props;
    const { pinFormData } = this.state;
    const { pin, confirm } = pinFormData;
    const pinFormDataValues = pinFormInputs.map((i) => pinFormData[i.name]);

    e.preventDefault();

    if (!pinFormDataValues.includes('')) {
      if (pin === confirm) {
        this.setState({ invalidPINFormInputs: [] });
        this.props.setLoading(true);
        this.props.submitNewPIN({ pin });
      } else {
        this.setState({ invalidPINFormInputs: ['confirm'] });
      }
    } else {
      this.setState({
        invalidPINFormInputs: pinFormInputs
          .filter((i) => pinFormData[i.name] === '')
          .map((i) => i.name)
      });
    }
  };

  render() {
    const {
      authStep,
      pinTexts,
      pinFormInputs,
      timerActive,
      recoverPIN,
      startOTPTimer,
      toast
    } = this.props;
    const {
      pinRecoveryPopupOpen,
      invalidPINFormInputs,
      pinFormData,
      toastOpen,
      otpMinutes,
      otpSeconds,
      emailIsActive
    } = this.state;
    const pinOTP = authStep === 2;
    const pinAccountRecovery = authStep === 3;

    let translateX = 0;
    if (pinOTP) translateX = -100;
    if (pinAccountRecovery) translateX = -200;
    const transform = { transform: `translateX(${translateX}%)` };

    return (
      <div className="pin-container">
        <HiddenHeaderAndFooter hideHeader hideFooter all />
        <div className="pin-pages">
          <PINKeyboardPage
            {...this.props}
            {...this.state}
            transform={transform}
            handleKeyClick={this.handleKeyClick}
            submitPIN={this.enterPIN}
            openPINRecoveryPopup={this.openPINRecoveryPopup}
          />
          <PINAuthentication
            authStep={authStep}
            transform={transform}
            timerActive={timerActive}
            emailIsActive={emailIsActive}
            resend={recoverPIN}
            submit={this.submitOTP}
            stopTimer={() => startOTPTimer(false)}
            backToPIN={this.backToPIN}
            otpMinutes={otpMinutes}
            otpSeconds={otpSeconds}
            resendEmail={this.handleEmailLogin}
            resendOTP={this.resendOTP}
            stopTimerEmail={this.stopTimerEmail}
          />
          <PINAccountRecovery
            transform={transform}
            handleInput={this.handlePINInput}
            backToPIN={this.backToPIN}
            texts={pinTexts.newPIN}
            formInputs={pinFormInputs}
            invalidInputs={invalidPINFormInputs}
            formData={pinFormData}
            submitPIN={this.submitPIN}
          />
        </div>
        <PINRecoveryPopup
          open={pinRecoveryPopupOpen}
          hide={() => this.openPINRecoveryPopup(false)}
          sendCode={this.sendCode}
          texts={pinTexts.recoveryPopup}
        />
        {toast && <Toast toast={toast} open={toastOpen} hide={this.closeToast} />}
      </div>
    );
  }
}
