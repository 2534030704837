import React from 'react';
import {
  answerFormats,
  defaultTextFieldPlaceholder,
  fieldTypes
} from '../../../constants';

export const CheckboxTextField = ({
  fieldType,
  field,
  choice,
  index,
  handleInput,
  handleTextField,
  className,
  captionClassName,
  textFieldClassName
}) => {
  const hasTextField = choice.answerFormat &&
    choice.answerFormat === answerFormats.textField;
  return (
    <>
      <label className={className}>
        <input
          name={field.id}
          id={`${field.id}_${choice.id}`}
          type={fieldType}
          value={choice.id}
          onClick={(e) => handleInput(e, field, hasTextField, index)}
        />
        <span className={fieldType} />
        <span className={captionClassName}>{choice.caption}</span>
      </label>
      {hasTextField &&
        <input
          type={fieldTypes.textField}
          placeholder={defaultTextFieldPlaceholder}
          className={textFieldClassName}
          onChange={(e) => handleTextField(field.id, e.target.value)}
        />}
    </>
  );
}