import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './Popup.scss';

export const Popup = ({ 
  open, 
  hide,
  children, 
  className, 
  header, 
  headerTitle,
  footer,
  buttonText,
  centered,
  buttonClick,
  scrollable 
}) => (
  <Modal
    backdrop
    show={open}
    className={`${className}-container`}
    contentClassName={className}
    dialogClassName={`${className}-dialog`}
    backdropClassName={`${className}-bg`}
    onHide={hide}
    centered={centered}
    scrollable={scrollable}
  >
    {header &&
      <Modal.Header className={`${className}-header`}>
        {headerTitle}
        <CloseRoundedIcon 
          fontSize="medium" 
          onClick={hide} 
          className={`${className}-header-close`}
        />
      </Modal.Header>}
    {!header && 
      <div onClick={hide} className={`${className}-close`} />}
    <Modal.Body className={`${className}-body`}>
      {children}
    </Modal.Body>
    {footer && 
      <Modal.Footer className={`${className}-footer`}>
        <button className={`${className}-button`} onClick={buttonClick}>{buttonText}</button>
      </Modal.Footer>}
  </Modal>
);