import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import classNames from 'classnames';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  absoluteValue,
  goldCurrency,
  minWidths,
  paymentStatus
} from '../../../constants';
import './TransactionCard.scss';

const TransactionCard = ({ 
  transaction,
  symbol,
  updatedDateFormat, 
  updatedTimeFormat, 
  handleTransaction, 
  isOverTheCounter,
  setIsBarcodeVisible 
}) => {
  const [product, setProduct] = useState([]);

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  useEffect(() => {
    const product = transaction.details?.pendingItems;
    if (isEmpty(product)) return;
    let latestProductArr = [];
    if (!isDesktop) {
      latestProductArr = product.slice(0, 1);
    } else {
      latestProductArr = product;
    }
    setProduct(latestProductArr);
  }, [transaction, isDesktop])

  return (
    <Fragment>
      <div onClick={() => handleTransaction(transaction)} className="transaction-history-products">
        <div className="d-flex align-items-center w-100 justify-content-between">
          <div className="transaction-history-products-time">{updatedDateFormat} {updatedTimeFormat}</div>
          <div className="d-flex align-items-center gap-2">
            <div className={classNames(
              "transaction-history-status", 
              "transaction-history-products-status", {
              "transaction-history-status-completed": transaction?.status === paymentStatus.completed,
              "transaction-history-status-failed": transaction?.status === paymentStatus.failed,
              "transaction-history-status-pending": transaction?.status === paymentStatus.pending
            })}>
              {transaction?.status}
            </div>
            <ArrowForwardIosRoundedIcon className="transaction-history-products-arrow"/>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          {isEmpty(product) ? 
            <div className="transaction-history-products-body">
              <div className="d-flex flex-column align-items-start gap-1 justify-content-center">
                <div className="transaction-history-products-name">{startCase(transaction?.primaryType)}</div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="transaction-history-products-quantity">{transaction?.origin}</div>
                </div>
              </div>
              {transaction?.status === "pending" && 
                isOverTheCounter && 
                <div 
                  onClick={(e) => setIsBarcodeVisible(e, transaction)} 
                  className="transaction-history-products-button">
                  View Code
                </div>}
            </div> : 
            <div className="transaction-history-products-card">
              <div className={classNames(
                "transaction-history-products-wrapper", {
                "transaction-history-products-wrapper-gap": transaction.details?.pendingItems?.length > 1
              })}>
                {!isEmpty(product) && 
                  product?.map((i, iIdx) => (
                    <div key={`CardItems_${i.id}_${iIdx}`} className="transaction-history-products-box">
                      <div className="d-flex align-items-center gap-3 w-100">
                        <img className="transaction-history-products-image" src={i.imageUrl} alt={i.skuKind} />
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                              <div className="transaction-history-products-name">{i.name}</div>
                              <div className="transaction-history-products-quantity">{`Qty: ${i.quantity}`}</div>
                            </div>
                            <div className="transaction-history-products-value">{i.subTotal?.text}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>}
        </div>
        {transaction.details?.pendingItems?.length > 1 && 
          !isDesktop &&
          <div className="transaction-history-products-count">
            {`View Products (${transaction.details?.pendingItems?.length - 1})`}
          </div>}
        <div className="d-flex align-items-center justify-content-between">
          <div className="transaction-history-products-total">Total</div>
          <div className="transaction-history-products-amount">
            <div>{symbol}</div>
            {transaction?.amount?.currency === goldCurrency && 
              <div className="transaction-history-products-coin" />}
            <div>{absoluteValue(transaction?.amount?.value)}</div> 
            {transaction?.amount?.currency !== goldCurrency && 
              <div>{transaction?.amount?.currency}</div>}
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default TransactionCard;
