import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Modal } from 'react-bootstrap';
import Tabs, { Tab } from 'react-best-tabs';
import GamePassInventoryTimer from './ActiveGamePassPopupTimer';
import { activeGamePassPopupTitle } from '../../../constants';
import './ActiveGamePassPopup.scss';

const ActiveGamePassPopup = ({ open, hide, entitlements }) => (
  <Modal
    backdrop
    centered
    show={open}
    className="active-game-pass-popup-container"
    contentClassName="active-game-pass-popup"
    dialogClassName="active-game-pass-popup-dialog"
    backdropClassName="active-game-pass-popup-bg"
    onHide={hide}
  >
    <Modal.Header
      className="active-game-pass-popup-header"
      closeButton
      closeVariant="white"
    >
      <div className="active-game-pass-popup-header-left" />
      <div className="active-game-pass-popup-header-title">{activeGamePassPopupTitle}</div>
    </Modal.Header>
    <Modal.Body className="active-game-pass-popup-body">
      <Tabs
        activeTab="1"
        className="active-game-pass-popup-tabs"
        ulClassName="active-game-pass-popup-tabs-lists"
        activityClassName={classNames(
          "active-game-pass-popup-tabs-indicator",
          {"active-game-pass-popup-tabs-indicator-solo": entitlements?.length === 1}
        )}
      >
        {!isEmpty(entitlements) && 
          entitlements.map(e => (
          <Tab
            key={`ActiveGamePassesAndBoosters_${e.name}`}
            title={e.name}
            className="active-game-pass-popup-tab"
          >
            <div className="active-game-pass-popup-tab-content">
              {e.items.map((i, idx) => (
                <div
                  key={`ActiveGamePassesAndBoosters_${e.name}_${idx}_${i.id}`}
                  className="active-game-pass-popup-tab-item"
                >
                  <img
                    src={i.data.imageUrl}
                    className="active-game-pass-popup-tab-item-image"
                    alt={i.data.subKind}
                  />
                  <div className="active-game-pass-popup-tab-item-name">
                    {i.data.name}
                  </div>
                  <GamePassInventoryTimer targetDate={i.validityEndDate} />
                </div>
              ))}
            </div>
          </Tab>
        ))}
      </Tabs>
    </Modal.Body>
  </Modal>
);

export default ActiveGamePassPopup;