import React from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { BlurBackgroundModal } from '../Commons';
import { flagKeys, loginTexts, storageKeys } from '../../constants';
import { updateFlag } from '../../actions';
import './TermsAndConditionsModal.scss';

export const TermsAndConditionsModal = ({ open }) => {
  const dispatch = useDispatch();

  const handleTermsAndConditions = () => {
    const flagsData = {
      key: flagKeys.isGamePassTermsAndConditionsShown,
      body: {
        value: true
      },
      isNewUser: true
    };
    dispatch(updateFlag(flagsData));
    Cookies.set(storageKeys.isNewUser, true);
  }

  return (
    <BlurBackgroundModal
      open={open}
      title={loginTexts.termsAndPrivacy.title}
      hideClose
    >
      <div 
        className="terms-and-conditions-consent" 
        dangerouslySetInnerHTML={{ __html: loginTexts.consent }} 
      />
      <button 
        onClick={handleTermsAndConditions} 
        className="terms-and-conditions-button"
      >
        {loginTexts.button.acceptAndContinue}
      </button>
    </BlurBackgroundModal>
  )
}
