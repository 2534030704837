import React, { useRef } from 'react';

export const FileUploader = ({ buttonText, buttonClassName, uploadFile }) => {
  const hiddenFileInput = useRef(null);
  const handleClick = () => hiddenFileInput.current.click();
  const handleChange = (e) => uploadFile(e.target.files[0]);

  return (
    <div className='w-100'>
      <button className={buttonClassName} onClick={handleClick}>{buttonText}</button>
      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
    </div>
  );
}