import React from 'react';
import pending from '../../assets/spend/payment-process.png';
import { HiddenHeaderAndFooter } from '../Commons';
import { useLocation, useNavigate } from 'react-router-dom';
import './WithdrawalResult.scss';

export const WithdrawalResult = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const referenceId = new URLSearchParams(search).get('referenceId');
  const result = window.location.pathname.split('/').slice(1)[3];
  let icon, title, text, buttonText = '';
  let isSuccess = false;
  isSuccess = true;
  icon = pending;
  title = 'Withdrawal is processing.';
  text = `Transaction ID: ${referenceId}`;
  buttonText = 'Back to Collectibles';

  return (
    <>
      <HiddenHeaderAndFooter
        hideFooter
        hideHeader
        hideMenuIcon
      />
      <div className="withdrawal-result-container">
        <img src={icon} alt={result} className="withdrawal-result-image" />
        <h1 className="withdrawal-result-title">{title}</h1>
        <p className="withdrawal-result-text">{text}</p>
        <div className="withdrawal-result-button-container">
          <button className="withdrawal-result-button" onClick={() => navigate("/wallet")}>{buttonText}</button>
          {!isSuccess && 
           <button 
             className="withdrawal-result-button-back" 
             onClick={() => navigate('/wallet?show=collectibles')}
           >
           Back to Wallet
           </button>}
        </div>
      </div>
    </>
  )
}