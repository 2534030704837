import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { GridContainer } from '../Components';
import './NFTs.scss';

export class NFTs extends Component {
  constructor(props) {
    super(props);

    const route = window.location.pathname.split('/').slice(1);

    this.state = {
      category: route[route.length - 1]
    };
  }

  componentDidMount() {
    if (!this.props.trendingDrops || !this.props.topCollections) {
      this.props.getHomeCollection();
    }
  }

  render() {
    const { trendingDrops, topCollections } = this.props;
    const { category } = this.state;

    if ((category === 'trending' || category === 'nfts') && trendingDrops && !isEmpty(trendingDrops)) {
      return <GridContainer data={trendingDrops} keyPrefix="TrendingDrops" className="nfts" />;
    }

    if (category === 'top' && topCollections && !isEmpty(topCollections)) {
      return <GridContainer data={topCollections} keyPrefix="TopCollections" className="nfts" />;
    }

    return null;
  }
}