import { connect } from 'react-redux';
import { getNFTItemDetails, buyNFTItem, setLoading, showOrderSummary } from '../actions';
import { nftItemDetailsSelector, orderSummaryVisibleSelector, sessionExpiredSelector } from '../selectors';
import { NFTItem } from '../components';

const mapStateToProps = (state) => ({
  nftItem: nftItemDetailsSelector(state),
  orderSummaryVisible: orderSummaryVisibleSelector(state),
  sessionExpired: sessionExpiredSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getNFTItemDetails: (nftId, nftItemId) => dispatch(getNFTItemDetails(nftId, nftItemId)),
  buyNFTItem: (nftItemId) => dispatch(buyNFTItem(nftItemId)),
  showOrderSummary: (visible) => dispatch(showOrderSummary(visible)),
  setLoading: (loading) => dispatch(setLoading(loading))
});

const NFTItemContainer = connect(mapStateToProps, mapDispatchToProps)(NFTItem);

export default NFTItemContainer;