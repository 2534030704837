import {
  SET_ITEM_REDEMPTION_ERROR,
  SET_DISPLAY_TOAST,
  RESET_STATE
} from '../actions/constants';

const vouchersState = {
  itemRedemptionError: '',
  toast: undefined
};

const vouchersReducer = (state = vouchersState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return vouchersState;
    case SET_ITEM_REDEMPTION_ERROR:
      return {
        ...state,
        itemRedemptionError: action.error
      };
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    default:
      return state;
  }
}

export default vouchersReducer;