import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import { GridContainer, ListContainer } from '../Components';
import { getHomeCollection } from '../../actions';
import { firebaseEvents, minWidths } from '../../constants';
import { homeCollectionSelector } from '../../selectors';
import { useQuery } from '../../hooks/useQuery';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import filterByDataset from '../../utils/filterByDataset';
import './Games.scss';

export const Games = () => {
  const query = useQuery();
  const categoryQuery = query.get('category');
  const categoryName = categoryQuery || 'Games';

  const collection = useSelector(state => homeCollectionSelector(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!collection ||
      (collection && isEmpty(collection))) {
      return dispatch(getHomeCollection());
    }
  }, [dispatch, collection]);

  useEffect(() => {
    const gamesCategory = query.get('category');
    if (gamesCategory) {
      let event = '';
      if (gamesCategory.toLowerCase() === 'new') {
        event = firebaseEvents.gamesNewAllPage;
      } else if (gamesCategory.toLowerCase() === 'casual') {
        event = firebaseEvents.gamesCasualAllPage;
      }
      if (event !== '') {
        logFirebaseEventWithTimestamp(event);
      }
    }
  }, [query])

  const games = collection && filterByDataset(collection, categoryName);
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  return (
    <>
      {games && !isEmpty(games) && (
        isDesktop ? <GridContainer data={games} keyPrefix="TrendingGame" className="trending-game" />
          : <ListContainer data={games} keyPrefix="TrendingGame" className="trending-game" />
      )}
    </>
  );
}