import {
  SET_USER_PROFILE_FIELDS,
  SET_USER_PROFILE_COUNTRY_CODE,
  SET_DISPLAY_TOAST,
  SET_TRANSACTIONS,
  SET_PROFILE_TUTORIAL,
  RESET_STATE,
  USER_PROFILE_REQUESTED
} from '../actions/constants';

const profileState = {
  fields: {},
  toast: undefined,
  transactions: {},
  hasTutorial: false,
  isRequested: false
};

const profileReducer = (state = profileState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return profileState;
    case SET_USER_PROFILE_FIELDS:
      return {
        ...state,
        fields: action.fields
      };
    case SET_USER_PROFILE_COUNTRY_CODE:
      return {
        ...state,
        fields: {
          ...state.fields,
          countryCode: action.countryCode
        }
      };
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions
      };
    case SET_PROFILE_TUTORIAL:
      return {
        ...state,
        hasTutorial: action.hasTutorial
      };
    case USER_PROFILE_REQUESTED:
      return {
        ...state,
        isRequested: action.isRequested
      }; 
    default:
      return state;
  }
}

export default profileReducer;