import ReactCountryFlag from 'react-country-flag';
import usdcIcon from '../assets/payment-methods/1644837029143-USDC.png';
import cryptoIcon from '../assets/withdrawal/icons/crypto.png';

export const withdrawalCurrency = [
  {
    name: 'PHP',
    value: 'PHP',
    icon: <ReactCountryFlag countryCode='PH' className="general-dropdown-title-icon" svg />
  },
  {
    name: 'USDC',
    value: 'USDC',
    icon: <img src={usdcIcon} alt="USDC" className="general-dropdown-title-icon" />
  },
];

export const withdrawalChannel = 'PH_SHOPEEPAY';
export const withdrawalMethods = [
  {
    name: 'Crypto',
    icon: cryptoIcon
  }
];
export const withdrawalForms = {
  title: 'Enter Details',
  crypto: {
    title: 'Crypto Wallet Address',
    placeholder: 'Enter Crypto Wallet Address'
  }
};
export const withdrawalTitles = {
  withdrawalMethods: 'Withdrawal Methods',
  selectWithdrawalMethod: 'Select Withdrawal Method'
};
export const withdrawalStatus = (amount, currency, address, txhash) => {
  const success = {
    title: 'Withdrawal Successful',
    description: `You have successfully transferred ${amount} ${currency} to ${address}`
  };
  const pending = {
    title: 'Withdrawal Processing',
    description: `Your ${amount} ${currency} is pending. Kindly check ${txhash} to monitor your transaction.`
  };
  const failed = {
    title: 'Withdrawal Failed',
    description: `Your ${amount} ${currency} withdrawal failed. Kindly retry again`
  };
  return {
    success,
    pending,
    failed
  }
};