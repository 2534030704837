import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import { 
  authSteps, 
  duration, 
  loginTexts, 
  otpMaxLength 
} from '../../../constants';
import './OTP.scss';

export class OTP extends Component {
  constructor(props) {
    super(props);

    const { minutes, seconds } = props.time;

    this.state = {
      otp: '',
      minutes: minutes || 0,
      seconds: seconds || 0,
      emailMinutes: minutes || 0,
      emailSeconds: seconds || 0,
    };

    this.tick = this.tick.bind(this);
    this.emailTick = this.emailTick.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(() => this.tick(), duration.oneSecond);
    this.timer = setInterval(() => this.emailTick(), duration.oneSecond);
  }

  componentDidUpdate(prevProps, prevState) {
    const { timerActive, time, authStep, autoSubmit, emailIsActive } = this.props;
    const { otp } = this.state;

    if (prevProps.timerActive !== timerActive && timerActive) {
      const minutes = time.minutes || 0;
      const seconds = time.seconds || 0;
      this.setTime(minutes, seconds);
    }

    if ((prevProps.authStep !== authStep && authStep) || (prevProps.emailIsActive !== emailIsActive && emailIsActive)) {
      const emailMinutes = time.minutes || 0;
      const emailSeconds = time.seconds || 0;
      this.setEmailTime(emailMinutes, emailSeconds);

      setTimeout(() => {
        if (authStep === authSteps.otp) {
          const otpRef = document.querySelector('.otp-digit');
          const otpRefFocus = document.querySelector('.otp-digit-focused');
          if (otpRefFocus) return otpRefFocus.focus();
          if (otpRef) return otpRef.focus();
        }
      },700)
    }

    if (authStep !== prevProps.authStep) {
      this.handleOTPInput('');
    }

    if (prevState.otp !== otp && otp.length === 6 && autoSubmit) {
      const otpDigits = otp.split('');
      this.props.submit(otpDigits);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleOTPInput = (otp) => {
    this.setState({ otp });
  }

  setTime = (minutes, seconds) => {
    this.setState({ minutes, seconds });
  }

  setEmailTime = (emailMinutes, emailSeconds) => {
    this.setState({ emailMinutes, emailSeconds });
  }

  tick = () => {
    const { timerActive } = this.props;
    const { minutes, seconds } = this.state;
    if (timerActive) {
      if (minutes === 0 && seconds === 0) {
        this.props.stopTimer();
      } else if (seconds === 0) {
        this.setTime(minutes - 1, 59);
      } else {
        this.setTime(minutes, seconds - 1);
      }
    }
  };

  emailTick = () => {
    const { emailMinutes, emailSeconds } = this.state;
    const { emailIsActive, stopTimerEmail } = this.props;
    if (emailIsActive) {
      if (emailMinutes === 0 && emailSeconds === 0) {
        stopTimerEmail();
      } else if (emailSeconds === 0) {
        this.setEmailTime(emailMinutes - 1, 59);
      } else {
        this.setEmailTime(emailMinutes, emailSeconds - 1);
      }
    }
  };

  formatTime = (time) => {
    return time > 9 ? time : `0${time}`;
  }

  resendEmailOtp = (e) => {
    const { resendEmail, resendOTP } = this.props;
    resendEmail(e);
    resendOTP();
  }

  render() {
    const { description, resend, autoSubmit, submit } = this.props;
    const { minutes, seconds, otp, emailMinutes, emailSeconds } = this.state;
    const formattedTime = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    const formattedEmailTime = `${this.formatTime(emailMinutes)}:${this.formatTime(emailSeconds)}`;
    const otpDigits = otp.split('');
    
    return (
      <div className="otp-form">
        <div className="otp-label">{description}</div>
        <OtpInput
          shouldAutoFocus={this.props.showOTP}
          numInputs={otpMaxLength}
          onChange={this.handleOTPInput}
          value={otp}
          containerStyle="otp-input-grid"
          inputStyle="otp-digit"
          focusStyle="otp-digit-focused"
          isInputNum={true}
        />
        {(minutes === 0 && seconds === 0) ? 
          <div className="otp-timer">
            {loginTexts.otp.didNotReceiveOtp}&nbsp;
            <button onClick={(e) => resend(e)}>{loginTexts.otp.resendVerificationCode}</button>
          </div> : 
          <div className="otp-timer">
            {loginTexts.otp.resendCodeViaSMS} {formattedTime}
          </div>}
          <div className="otp-or">{loginTexts.otp.or}</div>
        {((emailMinutes === 0 && emailSeconds === 0) || 
          (emailMinutes === 1 && emailSeconds === 30)) ? 
          <button onClick={(e) => this.resendEmailOtp(e)}>
            {loginTexts.otp.resendVerificationThroughEmail}
          </button> : 
          <div className="otp-timer otp-timer-email">
            {loginTexts.otp.resendCodeViaEmail} {formattedEmailTime}
          </div>}
        {!autoSubmit && 
          <button 
            className="otp-submit" 
            onClick={() => submit(otpDigits)}
          >
            {loginTexts.otp.submit}
          </button>}
      </div>
    );
  }
}