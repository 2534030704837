import React from 'react';
import { useDispatch } from 'react-redux';
import { ReferralLink } from './ReferralLink';
import { ReferralSMS } from './ReferralSMS';
import { ReferralProgress } from './ReferralProgress';
import { checkoutButtonTexts } from '../../constants';
import { showGamePassCheckout } from '../../actions';
import { isMobile } from '../../utils/isMobile';
import './ReferralScreen.scss';

export const ReferralInvite = ({
  link,
  handleShare,
  countryCode,
  setCountryCode,
  mobileNumber,
  handleMobileNumberInput,
  handleInvite,
  emptyMobileNumberError,
  isGuest,
  freeSpinCount
}) => {
  const dispatch = useDispatch();
  const isMobileDevice = isMobile();

  return (
    <div className="referral-screen-referral-link">
      <ReferralProgress 
        isGuest={isGuest} 
        freeSpinCount={freeSpinCount} 
      />
      {!isGuest ? <>
        <ReferralLink link={link} handleShare={handleShare} />
        {isMobileDevice && <>
          <div className="referral-screen-referral-link-or">
            <div className="referral-screen-referral-link-or-horizontal-line"><hr /></div>
            <div className="referral-screen-referral-link-or-text">or</div>
            <div className="referral-screen-referral-link-or-horizontal-line"><hr /></div>
          </div>
          <ReferralSMS
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            mobileNumber={mobileNumber}
            handleMobileNumberInput={handleMobileNumberInput}
            handleInvite={handleInvite}
            emptyMobileNumberError={emptyMobileNumberError}
          />
        </>}
      </> : 
      <button 
        className="referral-screen-progress-button"
        onClick={() => dispatch(showGamePassCheckout(true))}
      >
        {checkoutButtonTexts.registerAndPayWithAmount}
      </button>}
    </div>
  );
}