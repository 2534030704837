import React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import './ImagePreview.scss';

export const ImagePreview = ({ url, openImage, gamepassGame }) => {
  const open = url !== '';
  return (
    <Modal
      centered
      backdrop
      show={open}
      size="xl"
      contentClassName={classNames(
        "image-preview", {
        "image-preview-game": gamepassGame 
      })}
      backdropClassName="image-preview-bg"
      dialogClassName="image-preview-dialog"
      onHide={() => openImage('')}
    >
      <Modal.Header closeButton closeVariant="white" />
      <Modal.Body>
        <img className={classNames({"image-preview-gallery": gamepassGame})} src={url} alt={url} />
      </Modal.Body>
    </Modal>
  );
};