import axios from 'axios';
import { baseUrl } from './url';

export function requestReferralLink(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/referrals/link')
  });
}

export function requestReferralHistory(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/referrals')
  });
}

export function requestReferralInvite(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/referrals/invite')
  });
}

export function requestReferrerDetails(query, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`referrals/referrer?${query}`)
  });
}