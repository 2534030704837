import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { HorizontalScroll } from '../../Commons';
import Section from './Section';
import { minWidths, maxWidths } from '../../../constants';
import './Section.scss';

const SectionUnlinkedList = ({ title, list }) => {
  const sm = useMediaQuery({ minWidth: minWidths.mobileSM, maxWidth: maxWidths.mobileSM });
  const md = useMediaQuery({ minWidth: minWidths.tablet, maxWidth: maxWidths.tablet });
  const lg = useMediaQuery({ minWidth: minWidths.desktopSM, maxWidth: maxWidths.desktopLG });
  const xl = useMediaQuery({ minWidth: minWidths.desktopXL, maxWidth: maxWidths.desktopXXL });
  const xxxl = useMediaQuery({ minWidth: minWidths.desktopXXXL });

  let slidesToShow = 3;
  if (sm) slidesToShow = 4;
  else if (md) slidesToShow = 5;
  else if (lg) slidesToShow = 6;
  else if (xl) slidesToShow = 7;
  else if (xxxl) slidesToShow = 8;

  return (
    <Section title={title}>
      <div className="section-list">
        <HorizontalScroll slidesToShow={slidesToShow}>
          {list.map(l => (
            <div key={`${title}_${l.name}`} className="section-list-item">
              <div className="section-list-item-link">
                <div className="thumbnail" style={{ backgroundImage: `url(${l.image_url})` }}></div>
                <div className="thumbnail-name">{l.name}</div>
              </div>
            </div>
          ))}
        </HorizontalScroll>
      </div>
    </Section>
  );
}

export default SectionUnlinkedList;