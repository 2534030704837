import referralMechanic1 from '../assets/referral/mechanics/1.png';
import referralMechanic2 from '../assets/referral/mechanics/2.png';
import referralMechanic3 from '../assets/referral/mechanics/3.png';
import referralMechanic4 from '../assets/referral/mechanics/4.png';
import referralMechanic5 from '../assets/referral/mechanics/5.png';

export const referralHistory = (history) => ({
  dataset: "Referral History",
  d: history
});
export const setInvitationPopupContent = (referrerName) => ({
  title: `${referrerName} invited you to join MetaverseGo.`,
  description: 'Sign up now and get a free Game Pass!',
  buttonText: 'Sign Up Now'
});
export const emptyReferralHistory = "Your referral history is empty";
export const referralMechanics = {
  title: "Refer a Friend Mechanics",
  description: "Get a free spin for every successful referral! Refer more friends for a chance to win more prizes! Just make sure your friend uses your referral link and validates their mobile number for us to credit your free spin.",
  mechanics: {
    title: "Refer a Friend Mechanics",
    carousel: [
      {
        imageUrl: referralMechanic1,
        title: "Access Referral Page",
        description: "Click the Refer a Friend floater at the lower-right side of the screen or the Invite a Friend banner at the bottom of the Wen Lambo page."
      },
      {
        imageUrl: referralMechanic2,
        title: "Share to or Invite a Friend",
        description: "On the Refer a Friend page, copy and share your referral link with friends or on social media pages. You can also enter their mobile number to send an invite."
      },
      {
        imageUrl: referralMechanic3,
        title: "Check Referral Status",
        description: "Every referred friend and their respective status will appear on the Refer a Friend page."
      },
      {
        imageUrl: referralMechanic4,
        title: "Successful Referral",
        description: "A “successful” referral means that your friends have clicked on your link, registered on the MetaverseGo page, and bought any of our Game Pass."
      },
      {
        imageUrl: referralMechanic5,
        title: "Referral Reward",
        description: "Your 30-minute Game Pass and 30-minute 2x Gold Booster will be credited immediately once your friend is successfully referred."
      }
    ]
  },
  faqs: {
    title: "Refer a Friend FAQs",
    items: [
      {
        question: "How do I refer a friend?",
        answers: [
          "At the Wen Lambo page, navigate to the ‘Refer a Friend’ banner. Click ‘Invite Now’ to get a referral link to share with your friend."
        ]
      },
      {
        question: "What do I get from referring a friend?",
        answers: [
          "You will receive a free spin that can be used to win more prizes! Be sure that your friend verifies their mobile number upon registration so we can credit your free spin."
        ]
      },
      {
        question: "How do I validate my mobile number?",
        answers: [
          "Upon signing up, use your mobile OTP instead of an email OTP to validate your mobile number."
        ]
      },
      {
        question: "Who will get the referral bonus if a friend or I get multiple referral links?",
        answers: [
          "It will depend on the link the invited users use to register! Only one referral link is used to create an account."
        ]
      },
      {
        question: "If I put your friends’ mobile numbers to invite them, does it mean I automatically get the referral bonus?",
        answers: [
          "No, but you’re just a few steps away! You have to ensure they create a MetaverseGo account and verify their mobile number."
        ]
      },
      {
        question: "What do the statuses mean?",
        answers: [
          "<i>Invited</i> means that you have already sent an invitation to your friend to create their MetaverseGo account.",
          "<i>Rewarded</i> means that your friend has registered and their mobile number is validated."
        ]
      },
      {
        question: "Is there a cap on how many friends I can invite?",
        answers: [
          "There’s none! Feel free to invite all of your friends to earn more spins and prizes."
        ]
      }
    ]
  },
  cta: {
    text: 'View Full Mechanics',
    redirectUri: '/referral/mechanics'
  }
};
export const referralSharePlatforms = [
  'facebook',
  'viber',
  'whatsapp',
  'twitter',
  'telegram'
];
export const referralLinkTexts = {
  label: "Share your Referral Link",
  button: "Share",
  linkCopied: "Link copied!"
};
export const referralSMSTexts = {
  label: "Enter your Friend's Mobile Number",
  button: "Invite",
  emptyError: "Please enter a valid mobile number.",
  placeholder: 'Enter Mobile Number'
};
export const referralStatus = {
  registered: 'registered',
  success: 'success',
  invited: 'invited'
};
export const referralBannerText = {
  referFriend: 'Refer a Friend,',
  getGamePass: {
    desktop: 'Get a Game Pass & Gold Booster',
    mobile: 'Get a Game Pass<br/>& Gold Booster'
  },
  inviteNow: 'Invite Now'
};
export const referralFreeSpinTexts = {
  freeSpinReceived: 'Free Spins Received',
  spinNow: 'Spin Now'
};
export const referralProgress = {
  title: 'How It Works',
  data: [
    {
      id: 1,
      title: 'Refer a Friend'
    },
    {
      id: 2,
      title: 'Sign Up & Verify Mobile Number'
    },
    {
      id: 3,
      title: 'Get a FREE Spin!'
    },
  ]
};
export const cryptoFreeSpinName = 'SPIN_CREDIT';
