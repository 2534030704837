import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { PINForm, PINKeyboard } from '../Commons';
import { loginTexts } from '../../constants';

export const LoginPIN = ({ 
  transform,
  setPINFormDisplayed,
  backToLogin,
  texts,
  hasValidationError,
  setPINValidationError,
  validatePIN,
  isSubmit,
  ...props
}) => {
  let headerText = loginTexts.pin.login;
  if (setPINFormDisplayed) headerText = texts.title;
  return (
    <div className="login-pin" style={transform}>
      <div className="login-pin-header">
        <div className="back" onClick={backToLogin}><ArrowBackIosNewRoundedIcon /></div>
        <span>{headerText}</span>
      </div>
      {
        setPINFormDisplayed ? <PINForm {...props} title={texts.subtitle} description={texts.description} isSubmit={isSubmit} /> :
        <div className="login-pin-keyboard">
          <PINKeyboard submit={validatePIN} hasValidationError={hasValidationError} setValidationError={setPINValidationError} />
        </div>
      }
    </div>
  );
}