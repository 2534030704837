import CloseIcon from '@mui/icons-material/Close';
import { loginWaitListText as text } from '../../constants/login';

export const LoginWaitListModal = ({ hide }) => {
  return (
    <div className="login-container-wait">
      <div className="login-container-wait-wrapper">
        <div className="login-container-wait-header">
          <h1>{text.title}</h1>
        </div>
        <div className="login-container-wait-body">
          {text.description.map((data, dIdx) => {
            return <p key={`Login_Wait_List_Desc_${dIdx}`}>{data}</p>;
          })}
        </div>
        <div className="login-container-wait-footer">
          {text.icon.map((data, dIdx) => {
            return (
              <a href={data.link} target="_blank" rel="noreferrer">
                <div
                  key={`Login_Wait_List_Icon_${dIdx}`}
                  className={`login-container-wait-footer-${data.name}`}
                />
              </a>
            );
          })}
        </div>
        <div onClick={hide} className="login-container-wait-close">
          <CloseIcon fontSize="large" />
        </div>
      </div>
    </div>
  );
};
