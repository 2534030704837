import { 
  RESET_STATE,
  SET_ONBOARDING_TUTORIALS, 
  SET_TUTORIAL_SCREENS 
} from '../actions/constants';

const tutorialState = {
  screens: [],
  onboarding: {}
};

const tutorialReducer = (state = tutorialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return tutorialState;
    case SET_TUTORIAL_SCREENS:
      return {
        ...state,
        screens: action.tutorialScreens
      };
    case SET_ONBOARDING_TUTORIALS:
      return {
        ...state,
        onboarding: action.onboarding
      };
    default:
      return state;
  }
}

export default tutorialReducer;