import {
  SET_DISPLAY_TOAST, 
  LOGIN_SUCCESS, 
  LOGIN_FAILURE, 
  LOGIN_OTP_SUCCESS, 
  LOGIN_OTP_FAILURE, 
  SET_AUTH_STEP,
  START_LOGIN_OTP_TIMER,
  START_EMAIL_LOGIN_OTP_TIMER,
  DISPLAY_REGISTRATION_FORM,
  SET_ERROR_MESSAGE,
  LOGIN_DATA,
  RESET_STATE,
  ENABLE_EMAIL_OTP,
  SET_EMAIL_OTP_TO_ENABLED,
  REDIRECT_TO_GAME_PARTNER_PAGE
} from '../actions/constants';
import { authSteps } from '../constants';

const loginState = {
  token: '',
  toast: undefined,
  authStep: authSteps.default,
  loginOTPTimerActive: false,
  emailLoginOTPTimerActive: false,
  registrationFormVisible: false,
  errorMessage: {},
  loginData: {},
  isEmailAvailable: false,
  isEmailOTPEnabled: false,
  gamePartnerPageRedirect: false
};

const loginReducer = (state = loginState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return loginState;
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authStep: authSteps.otp,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case LOGIN_OTP_SUCCESS:
      return {
        ...state,
        token: action.token,
        error: null
      };
    case LOGIN_OTP_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case LOGIN_DATA:
      return {
        ...state,
        loginData: action.loginData
      };
    case SET_AUTH_STEP:
      return {
        ...state,
        authStep: action.step
      };
    case START_LOGIN_OTP_TIMER:
      return {
        ...state,
        loginOTPTimerActive: action.loginOTPTimerActive
      };
    case START_EMAIL_LOGIN_OTP_TIMER:
      return {
        ...state,
        emailLoginOTPTimerActive: action.emailLoginOTPTimerActive
      };
    case DISPLAY_REGISTRATION_FORM:
      return {
        ...state,
        registrationFormVisible: action.registrationFormVisible
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case ENABLE_EMAIL_OTP:
      return {
        ...state,
        isEmailAvailable: action.isEmailAvailable
      };
    case SET_EMAIL_OTP_TO_ENABLED:
      return {
        ...state,
        isEmailOTPEnabled: action.isEmailOTPEnabled
      };
    case REDIRECT_TO_GAME_PARTNER_PAGE:
      return {
        ...state,
        gamePartnerPageRedirect: action.gamePartnerPageRedirect
      };
    default:
      return state;
  }
}

export default loginReducer;