import { 
  CONNECT_FROM_TOPUP,
  RESET_STATE,
  SET_CREATE_PAYMENT, 
  SET_CURRENCY_CONVERSION, 
  SET_CURRENCY_CONVERSION_BATCH, 
  SET_CURRENCY_LOADING, 
  SET_PAYMENT_CHANNELS, 
  SET_PAYMENT_METHODS, 
  SET_WALLET_CURRENCY_CONVERSION
} from "../actions/constants";

const topupState = {
  buyData: [],
  channels: [],
  payment: {},
  currency: {},
  currencyBatch: [],
  isLoading: false,
  walletCurrency: {},
  isFromTopUp: ''
};
  
const topupReducer = (state = topupState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return topupState;
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        buyData: action.buyData
      };
    case SET_PAYMENT_CHANNELS:
      return {
        ...state,
        channels: action.channels
      };
    case SET_CREATE_PAYMENT:
      return {
        ...state,
        payment: action.payment
      };
    case SET_CURRENCY_CONVERSION:
      return {
        ...state,
        currency: action.currency
      };
    case SET_CURRENCY_CONVERSION_BATCH:
      return {
        ...state,
        currencyBatch: action.currencyBatch
      };
    case SET_CURRENCY_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case SET_WALLET_CURRENCY_CONVERSION:
      return {
        ...state,
        walletCurrency: action.walletCurrency
      };
    case CONNECT_FROM_TOPUP:
      return {
        ...state,
        isFromTopUp: action.isFromTopUp
      };
    default:
      return state;
  }
}

export default topupReducer;