import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, set, push, update, remove, get, child, onValue } from 'firebase/database';
import { LayoutContent, PageTitle } from '../Components';
import { Confirm, Toast } from '../Commons';
import ChatThreadHeader from './ChatThreadHeader';
import ChatThreadMessages from './ChatThreadMessages';
import { dateTimeFormats, defaultTimeoutMS } from '../../constants';
import { database } from '../../utils/firebase';
import groupMessagesByDate from '../../utils/groupMessagesByDate';
import './ChatThread.scss';

export class ChatThread extends Component {
  constructor(props) {
    super(props);

    this.state = {
      threadId: window.location.pathname.split('/').slice(1)[1],
      messageId: '',
      userId: '',
      chatboxText: '',
      isConfirmVisible: false,
      toastOpen: false
    };
  }

  componentDidMount() {
    this.getUserId();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userId !== this.state.userId && this.state.userId !== '') {
      this.getChatMessageThread(this.state.userId, this.state.threadId);
    }
    if (prevProps.toast !== this.props.toast && this.props.toast) {
      this.openToast(true);
    }
  }

  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  }

  closeToast = () => {
    this.openToast(false);
    setTimeout(() => this.props.setDisplayToast(), defaultTimeoutMS);
  }

  getUserId = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) this.setState({ userId: user.uid });
    });
  }
  
  getChatMessageThread = (userId, threadId) => {
    if (userId && userId !== '') {
      // set up the message thread
      const messagesRef = ref(database, `messages/${threadId}`);
      onValue(messagesRef, (snapshot) => {
        if (snapshot.exists()) {
          const messagesFromDB = snapshot.val();
          if (messagesFromDB) {
            const messagesArr = Object.keys(messagesFromDB).map(k => messagesFromDB[k]);
            const chatMessagesRef = ref(database, `chatReference/${userId}/${threadId}`);
            onValue(chatMessagesRef, (threadSnapshot) => {
              if (threadSnapshot.exists()) {
                const selectedMessageThread = threadSnapshot.val();
                if (selectedMessageThread) {
                  const threadData = {
                    id: threadId,
                    name: selectedMessageThread.name,
                    imageUrl: selectedMessageThread.imgUrl
                  };
                  const thread = groupMessagesByDate(messagesArr, threadData, true);
                  this.props.setChatMessageThread(thread);
                }
              }
            } 
          )};
        }
      });

      // mark thread as read
      const latestMessageRef = ref(database, `chatReference/${userId}/${threadId}`);
      update(latestMessageRef, { read: true });
    }
    this.props.setLoading(false);
  }

  typeCheckboxText = (chatboxText) => {
    this.setState({ chatboxText });
  }

  submitMessage = (message) => {
    const { threadId, userId } = this.state;
    const messageDate = DateTime.now().toFormat(dateTimeFormats.dateTime);
    const messageRef = ref(database, `messages/${threadId}`);
    const newMessageRef = push(messageRef);

    if (userId && userId !== '') {
      const latestMessageRef = ref(database, `chatReference/${userId}/${threadId}`);
      // add reply to message thread
      set(newMessageRef, {
        id: newMessageRef.key,
        incoming: false,
        message,
        messageDate,
        name: 'You',
        pfpUrl: '',
        senderId: userId
      });
      // set latest message on message list
      update(latestMessageRef, {
        message,
        timestamp: messageDate,
        options: null
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.submitMessage(this.state.chatboxText);
    this.typeCheckboxText('');
    e.target.querySelector('input').value = '';
  }

  showConfirm = (isConfirmVisible) => {
    this.setState({ isConfirmVisible });
  }

  confirmDeleteMessage = (messageId) => {
    const isConfirmVisible = messageId !== '';
    this.setState({ messageId });
    this.showConfirm(isConfirmVisible);
  }

  deleteMessage = (messageId) => {
    const { userId, threadId } = this.state;

    // delete message within the thread here
    const messageRef = ref(database, `messages/${threadId}/${messageId}`);
    remove(messageRef);

    // update most recent message within the thread
    if (userId && userId !== '') {
      const latestMessageRef = ref(database, `chatReference/${userId}/${threadId}`);
      let latestThread = {};

      get(child(ref(database), `messages/${threadId}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const messagesFromDB = snapshot.val();
          if (messagesFromDB) {
            const messagesArr = Object.keys(messagesFromDB).map(k => messagesFromDB[k]);
            get(child(ref(database), `chatReference/${userId}/${threadId}`)).then((threadSnapshot) => {
              if (threadSnapshot.exists()) {
                const selectedMessageThread = threadSnapshot.val();
                if (selectedMessageThread) {
                  const threadData = {
                    id: threadId,
                    name: selectedMessageThread.name,
                    imageUrl: selectedMessageThread.imgUrl
                  };
                  latestThread = groupMessagesByDate(messagesArr, threadData, true);
                }
              }
            }).catch((e) => { console.error(e); });
          }
        }
      }).catch((error) => { console.error(error); });

      if (!isEmpty(latestThread)) {
        const latestMessageGroup = latestThread.messages[latestThread.messages.length - 1];
        const latestMessage = latestMessageGroup.messages[latestMessageGroup.messages.length - 1];
        const latestMessageOptions = latestMessage.options ? latestMessage.options : null;
        update(latestMessageRef, {
          message: latestMessage.message,
          timestamp: latestMessage.messageDate,
          options: latestMessageOptions
        });
      }
    }

    this.showConfirm(false);
  }

  render() {
    const { thread, toast } = this.props;
    const { isConfirmVisible, messageId, toastOpen } = this.state;

    if (thread && !isEmpty(thread)) {
      return (
        <LayoutContent outerClassName="chat-thread-page" innerClassName="chat-thread-page-inner">
          <PageTitle title={`${thread.name} | Messages`} />
          <ChatThreadHeader {...thread} />
          <ChatThreadMessages
            {...thread}
            submitMessage={this.submitMessage}
            confirmDeleteMessage={this.confirmDeleteMessage}
            chatboxHidden
          />
          {/* <Chatbox typeText={this.typeCheckboxText} onSubmit={this.onSubmit} /> */}
          <Confirm
            open={isConfirmVisible}
            title="Delete Message"
            text="Are you sure you want to delete this message?"
            confirm={() => this.deleteMessage(messageId)}
            confirmText="Delete"
            hide={() => this.confirmDeleteMessage('')}
            hideText="Cancel"
          />
          {toast && <Toast toast={toast} open={toastOpen} hide={this.closeToast} />}
        </LayoutContent>
      );
    }

    return null;
  }
}