import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomNavigate = ({ to, state, replace }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate(to, { state, replace });
  }, [navigate, to, state, replace]);

  return null;
};

export default CustomNavigate;