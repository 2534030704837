import React, { Component } from 'react';
import classNames from 'classnames';
import BackspaceIcon from '@mui/icons-material/Backspace';
import './PINKeyboard.scss';

export class PINKeyboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: ['', '', '', '', '', ''],
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
      selectedIndex: 0
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', (e) => {
      if (e.code === 'Backspace') {
        this.handleDeletePIN();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { hasValidationError } = this.props;
    const { pin, selectedIndex } = this.state;

    if (prevState.selectedIndex !== selectedIndex) {
      if (selectedIndex === 6) {
        const pinCombined = pin.join('');
        this.props.submit(pinCombined);
      }
    }

    if (prevProps.hasValidationError !== hasValidationError && hasValidationError) {
      this.resetPINInput();
    }
  }

  resetPINInput = () => {
    this.props.setValidationError(false);
    this.setState({
      pin: ['', '', '', '', '', ''],
      selectedIndex: 0
    });
  };

  handleKeyClick = (key) => {
    const { pin, selectedIndex } = this.state;
    pin[selectedIndex] = key;
    if (selectedIndex <= 5) this.setState({ pin, selectedIndex: selectedIndex + 1 });
  };

  handleDeletePIN = () => {
    const { pin, selectedIndex } = this.state;
    if (selectedIndex === 0) return;
    const trimmed = pin.filter((p) => p !== '');
    trimmed.pop();
    const formatted = [];
    for (let i = 0; i <= 5; i++) {
      const num = trimmed[i];
      if (typeof num == 'number') {
        formatted.push(num);
      } else {
        formatted.push('');
      }
    }
    this.setState({ pin: formatted, selectedIndex: selectedIndex - 1 });
  };

  render() {
    const { menu } = this.props;
    const { pin, keys } = this.state;

    return (
      <div className='pin-keyboard-container'>
        <div className='pin-keyboard-header'>
          {menu}
          <div className='pin-keyboard-texts'>
            {menu && <h1>Enter your PIN</h1>}
            <p>Do not share your PIN or OTP with anyone</p>
          </div>
          <div className='pin-keyboard-indicators'>
            {pin.map((d, i) => (
              <span
                key={`PINDigit_${i}`}
                className={classNames('pin-keyboard-indicator', {"pin-keyboard-indicator-filled": d !== ''})}
              ></span>
            ))}
          </div>
        </div>
        <div className='pin-keyboard-keyboard'>
          {keys.map((k) => (
            <button
              key={`Key_${k}`}
              className={classNames("pin-keyboard-keyboard-key", {[`pin-keyboard-keyboard-key-${k}`]: k === 0})}
              onClick={() => this.handleKeyClick(k)}
              onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}
            >{k}</button>
          ))}
          {pin[0] !== '' && <BackspaceIcon
            onClick={this.handleDeletePIN}
            fontSize="large"
            className="pin-keyboard-keyboard-delete"
          />}
        </div>
      </div>
    );
  }
}
  