import axios from 'axios';
import { baseUrl } from "./url";

export function requestUserTransactionData(params, sessionToken, version) {
  return axios.request({
    method: 'GET',
    params: { ...params },
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/transaction', version)
  });
}

export function requestUserTransactions(params, sessionToken, version) {
  return axios.request({
    method: 'GET',
    params: { ...params },
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/transactions', version)
  });
}

export function requestUserTransactionsDetails(id, referenceId, sessionToken, version) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/transaction?id=${id}${referenceId}`, version)
  });
}