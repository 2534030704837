import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { 
  getSpinPrizes, 
  setAuthStep, 
  setDisplayToast, 
  setErrorMessage, 
  setLoading 
} from '../../actions';
import { LayoutContent, PageTitle } from '../Components';
import { Mechanics, Toast } from '../Commons';
import { Wheel } from '../Components';
import { 
  displayLoginToastSelector, 
  loginErrorMessageSelector, 
  spinAvailableSelector,
  spinPrizesSelector 
} from '../../selectors';
import { 
  authSteps,
  componentClasses,
  extraGDayPrizes,
  minWidths,
  sectionExtraGDay 
} from '../../constants';
import { 
  logo, 
  smLogo, 
  smLogoMobile, 
  wheel, 
  wheelValentines 
} from './images';
import './SpinTheWheel.scss';

export const SpinTheWheel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [spinCount, setSpinCount] = useState(0);
  const [toastOpen , setToastOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const toast = useSelector(state => displayLoginToastSelector(state));
  const spinAvailable = useSelector(state => spinAvailableSelector(state));
  const loginErrorMessage = useSelector(state => loginErrorMessageSelector(state));
  const prizes = useSelector(state => spinPrizesSelector(state));

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const search = useLocation().search;
  const extraGDayData = useMemo(() => (extraGDayPrizes), []);
  const smGiveAwayData = useMemo(() => (prizes), [prizes]);

  const tokenValue = new URLSearchParams(search).get('token');
  const marketingId = location.pathname.split('/')[3]; 
  const isExtraGDay = marketingId && marketingId.toLowerCase().includes(sectionExtraGDay);

  const layoutContentOuterClassName = isExtraGDay ? componentClasses.spinLayout : componentClasses.spinLayoutSm;
  const logoClassName = isExtraGDay ? componentClasses.spinBody : componentClasses.spinBodySm;
  const wheelPrizes = isExtraGDay ? extraGDayData : smGiveAwayData;
  const popUpBackground = isExtraGDay ? componentClasses.wheel : componentClasses.wheelSm;
  const wheelImage = isExtraGDay ? wheel : wheelValentines;

  useEffect(() => {
    dispatch(setLoading(false));
    setSpinCount(spinAvailable)
  },[dispatch, spinAvailable])

  useEffect(() => {
    setToastOpen(true);
    return () => {
      setToastOpen(false);
    }
  },[toast])

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    },500);
  }

  useEffect(() => {
    if (!isEmpty(loginErrorMessage) && loginErrorMessage.result) {
      navigate('/');
      dispatch(setAuthStep(authSteps.login));
      dispatch(setErrorMessage({
        result: false,
        message: loginErrorMessage.message
      }));
    }
  }, [dispatch, loginErrorMessage, navigate])

  const getLogo = () => {
    if (isExtraGDay) return logo;
    return isDesktop ? smLogo : smLogoMobile;
  }

  useEffect(() => {
    if (!isExtraGDay) dispatch(getSpinPrizes(marketingId));
  }, [dispatch, marketingId, isExtraGDay])

  return (
    <LayoutContent 
      outerClassName={layoutContentOuterClassName} 
      innerClassName="spin-layout-inner">
      <PageTitle title={'Spin The Wheel'} />
      <ArrowBackIosNewRoundedIcon 
        onClick={() => navigate(-1)}
        className="spin-back"/>
      <div className='spin'>
        <div className="spin-body">
          <div className="d-flex flex-column gap-2 align-items-center pb-3">
            <img src={getLogo()} alt="logo" className={logoClassName}/>
            <div className="spin-body-title">Spin the wheel to win exciting prizes!</div>
            <div className="spin-body-spin">{`You have ${spinCount} spin${spinCount > 1 ? "s" : ""}`}</div>
            <div className={classNames({
              "spin-body-mechanics": !isSpinning, 
              "spin-body-mechanics-hide": isSpinning
            })}>
              View full mechanics
              <span onClick={() => window.location.href = `${window.location.pathname}/mechanics`}>here</span>
            </div>
          </div>
        </div>
        <div className="spin-body-wrapper">
          <div className="position-relative">
            <Wheel
              prizes={wheelPrizes} 
              popUpBackground={popUpBackground}
              wheelImage={wheelImage}
              setIsSpinning={setIsSpinning} 
              spinCount={spinCount} 
              setSpinCount={setSpinCount}
              isExtraGDay={isExtraGDay}
            />
          </div>
        </div>
        <Mechanics/>
        {toast && <Toast toast={toast} open={toastOpen} hide={closeToast} tokenValue={tokenValue} />}
      </div>
    </LayoutContent>
  )
}
