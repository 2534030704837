import { 
  CRYPTO_WITHDRAWAL_VALIDATE_SUCCESS_DATA,
  RESET_STATE, 
  SET_CRYPTO_CHAINS, 
  SET_CRYPTO_CURRENCIES, 
  SET_USER_WALLET_ADDRESS, 
  SET_WALLET_SIGN_MESSAGE, 
  SET_WITHDRAWAL_NOTICE, 
  USER_WALLET_CONNECTED, 
  VERIFYING_WALLET_SIGN_MESSAGE
} from '../actions/constants';

const cryptoState = {
  signMessage: '',
  isVerifying: false,
  walletAddress: '',
  isConnected: false,
  currencies: {},
  validateSuccessData: {},
  chains: [],
  notice: {}
};

const cryptoReducer = (state = cryptoState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return cryptoState;
    case SET_WALLET_SIGN_MESSAGE:
      return {
        ...state,
        signMessage: action.signMessage
      };
    case VERIFYING_WALLET_SIGN_MESSAGE:
      return {
        ...state,
        isVerifying: action.isVerifying
      };
    case SET_USER_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.walletAddress
      };
    case USER_WALLET_CONNECTED:
      return {
        ...state,
        isConnected: action.isConnected
      };
    case SET_CRYPTO_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies
      };
    case CRYPTO_WITHDRAWAL_VALIDATE_SUCCESS_DATA:
      return {
        ...state,
        validateSuccessData: action.validateSuccessData
      };
    case SET_CRYPTO_CHAINS:
      return {
        ...state,
        chains: action.chains
      };
    case SET_WITHDRAWAL_NOTICE:
      return {
        ...state,
        notice: action.notice
      };
    default:
      return state;
  }
}

export default cryptoReducer;