import React from 'react';
import { loginTexts } from '../../../constants';
import classNames from 'classnames';

export const GamePassCheckoutCheckbox = ({ checked, setChecked, hasError }) => (
  <label className="game-pass-checkout-checkbox">
    <div
      className="game-pass-checkout-checkbox-label"
      dangerouslySetInnerHTML={{ __html: loginTexts.consent }}
    />
    <input type="checkbox" onClick={() => setChecked(!checked)} />
    <span 
      className={classNames(
        "checkbox",
        {"checkbox-error": hasError}
      )}
    />
  </label>
);
