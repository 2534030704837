import Cookies from 'js-cookie';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import {
  setActivateGamePass,
  setDisplayToast,
  setGamePassInventory,
  setGamePassProducts,
  setLoading,
  setGamePassScreen,
  showGamePassPopup,
  setGamePassTournaments,
  setGamePassTournamentsDetails,
  setGamePassTournamentToken,
  setGamePassProductDetails,
  setGamePassSkuDetails,
  setGamePassActiveEntitlements,
  setJoinBetaResponseSuccess,
  setJoinBetaResponseError,
  setBetaApplicationForm,
  showGamePassActivated,
  getGamePassActiveEntitlements,
  getUserProfile,
  setGamePassActivating,
  setOverrideActiveBooster,
  activateGamePassAndBooster,
  failedRequests,
  getFlags
} from '../actions';
import {
  ACTIVATE_GAMEPASS,
  GET_GAMEPASS_INVENTORY,
  GET_GAMEPASS_PRODUCTS,
  GET_GAMEPASS_SCREEN,
  GET_GAMEPASS_FLAG,
  SET_GAMEPASS_FLAG,
  GET_GAMEPASS_TOURNAMENTS,
  GET_GAMEPASS_TOURNAMENTS_DETAILS,
  GET_GAMEPASS_TOURNAMENT_TOKEN,
  GET_GAMEPASS_PRODUCT_DETAILS,
  GET_GAMEPASS_SKU_DETAILS,
  GET_GAMEPASS_ACTIVE_ENTITLEMENTS,
  JOIN_BETA,
  GET_BETA_APPLICATION_FORM,
  SUBMIT_BETA_APPLICATION_FORM,
  RETRY_GAMEPASS_TOURNAMENT_TOKEN,
  ACTIVATE_MULTIPLE_SKUS
} from '../actions/constants';
import {
  requestActivateGamePass,
  requestGamePassInventory,
  requestGamePassProducts,
  requestGamePassFlag,
  requestSetGamePassFlag,
  requestGamePassTournamentsDetails,
  requestGamePassTournamentToken,
  requestGamePassTournaments,
  requestGamePassProductDetails,
  requestGamePassSkuDetails,
  requestGamePassActiveEntitlements,
  requestJoinBeta,
  requestBetaApplicationForm,
  requestBetaApplicationFormSubmit,
  requestRetryGamePassTournamentToken,
  requestGamePassTournamentTokenWithCurrentUTC
} from '../api/gamepass';
import {
  errorCodes,
  gamePassSections,
  wenLamboIds,
  wenLamboBetaCodeTexts,
  firebaseEvents,
  gamePassScreenSections,
  gamePassGamesCTA,
  storageKeys,
  responseStatus,
  cardsKind,
  activateKind,
  activateTexts,
  gamePassCTA,
  flagKeys,
  onBoardingData,
  siteRoutes
} from '../constants';
import { guestSelector } from '../selectors';
import {
  logFirebaseEvent,
  logFirebaseEventWithTimestamp
} from '../utils/logFirebaseEvent';

const getGamePassProductsSaga = function* () {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassProducts, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassProducts(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassProductDetailsSaga = function* (payload) {
  const { productId, skuId } = payload.payload;
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassProductDetails, productId, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      let selectedProduct = {};
      const skus = response.data.d.skus;
      for (const item of skus) {
        if (item.id === skuId) {
          selectedProduct = item;
        }
      }
      yield put(setGamePassProductDetails(selectedProduct));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassSkuDetailsSaga = function* (payload) {
  const { productId, skuId } = payload.payload;
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassSkuDetails, productId, skuId, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassSkuDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassScreenSaga = function* () {
  try {
    const sections = gamePassSections.sections;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassProducts, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const buySection = {
        dataset: gamePassScreenSections.buyGamePass,
        experience: 'Game Pass',
        d: response.data.d
      };
      sections.splice(3, 0, buySection);
    }
    yield put(setGamePassScreen(sections));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassInventorySaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const isFreeGamePassModalShown = Cookies.get(storageKeys.isFreeGamePassModalShown);
    const response = yield call(requestGamePassInventory, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassInventory(response.data.d));
      if (isFreeGamePassModalShown) {
        yield put(getFlags({ key: flagKeys.isFreeGamePassModalShown }));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const activateGamePassSaga = function* (payload) {
  yield put(showGamePassActivated({ result: false }));
  const { 
    sku, 
    quantity, 
    kind, 
    navigate, 
    override, 
    lastItem,
    shouldRedirectToGamePassGamesPage,
    shouldRedirectToGamePassPage,
    gameUrl,
    isNewUser
  } = payload.payload;
  let activateData = { sku, quantity };
  if (override) {
    activateData.override = true;
  };
  yield put(setGamePassActivating(true));
  yield put(setLoading(true));

  let title = '';
  if (kind?.includes(activateKind.gamePass)) {
    title = activateTexts.gamePass;
  } else if (kind?.includes(activateKind.goldBoost)) {
    title = activateTexts.booster;
  } else if (kind?.includes(activateKind.bundle)) {
    title = activateTexts.bundle;
  }

  let redirectPath = shouldRedirectToGamePassPage ? gamePassCTA : gamePassGamesCTA;
  if (gameUrl) {
    redirectPath = gameUrl;
  }

  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestActivateGamePass, activateData, kind, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setActivateGamePass(response.data.d));
      yield put(showGamePassActivated({
        result: true,
        title,
        shouldNotShow: isNewUser
      }));
      yield call(getGamePassInventorySaga, sessionToken);
      yield put(getGamePassActiveEntitlements());
      yield put(getUserProfile());
      yield put(setOverrideActiveBooster({ result: false }));
      if (navigate && (shouldRedirectToGamePassGamesPage || shouldRedirectToGamePassPage || gameUrl)) {
        navigate(redirectPath, { state: isNewUser });
      };
      if (lastItem) {
        yield put(activateGamePassAndBooster(true));
      };
    }
    yield put(setGamePassActivating(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setGamePassActivating(false));
    yield put(showGamePassActivated({ result: false }));
    yield put(activateGamePassAndBooster(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        description: e.response.data.description && e.response.data.description,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      if (e.response.data?.data?.isOverridable) {
        yield put(setOverrideActiveBooster({
          result: true,
          description: e.response.data?.description,
          message: e.response.data?.message,
          data: payload.payload
        }));
      } else {
        yield put(failedRequests(failedData));
      }
    }
  }
}

const activateMultipleSkusSaga = function* (payload) {
  const { selectedSkus, navigate, isGamePassAlreadyActivated } = payload?.payload;
  let activatedSkus = [];
  let title = '';

  try {
    yield put(setLoading(true));
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const isLoggedInTutorial = Cookies.get(storageKeys.isLoggedInTutorial);
    for (const item of selectedSkus) {
      const kind = item?.skuKind.includes(cardsKind.gamePass) 
        ? activateKind.gamePass 
        : activateKind.goldBoost;
      let activateData = { 
        sku: item.skuKind,
        quantity: item.quantity
      };
      if (item.isOverridable) {
        activateData.override = item.isOverridable;
      };
      const response = yield call(requestActivateGamePass, activateData, kind, sessionToken);
      if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
        activatedSkus.push(activateData?.sku);
        let hasGamePass = false;
        let hasGoldBoost = false;
        
        activatedSkus.forEach(item => {
          if (item.includes(cardsKind.gamePass)) {
            hasGamePass = true;
          } else if (item.includes(cardsKind.goldBoost)) {
            hasGoldBoost = true;
          }
        });
        
        if ((hasGamePass || isGamePassAlreadyActivated) && 
          hasGoldBoost) {
          title = activateTexts.bundle;
        } else if (hasGamePass) {
          title = activateTexts.gamePass;
        } else if (hasGoldBoost) {
          title = activateTexts.booster;
        }

        yield put(setOverrideActiveBooster({ result: false }));
        yield put(showGamePassActivated({ result: true, title }));
      }
    }
    const qty = {};
    selectedSkus.map(i => {
      return qty[i.skuKind] = i.quantity;
    });
    logFirebaseEventWithTimestamp(
      firebaseEvents.activateGamePass, {
        sku: selectedSkus.map(i => i.name).join(', '),
        qty: JSON.stringify(qty)
      }
    );
    yield put(getGamePassActiveEntitlements());
    yield put(getUserProfile());
    yield put(setLoading(false));
    let url = gamePassGamesCTA;
    if (isLoggedInTutorial) {
      url = `${gamePassCTA}?onboard=${onBoardingData.flags.modal}`;
      Cookies.remove(storageKeys.isLoggedInTutorial);
    }
    navigate(url);
  } catch (e) {
    yield put(setLoading(false));
    yield put(activateGamePassAndBooster(false));
    let newSelectedSkus = selectedSkus;
    if (!isEmpty(activatedSkus)) {
      const filteredSkus = selectedSkus.reduce((acc, s) => {
        if (!activatedSkus.includes(s.skuKind)) {
          acc.push(s);
        }
        return acc;
      }, []);
      newSelectedSkus = filteredSkus;
    }
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        description: e.response.data.description && e.response.data.description,
        redirectUri: gamePassGamesCTA,
        showErrorToast: true
      }
      if (e.response.data.data?.isOverridable) {
        yield put(setOverrideActiveBooster({
          result: true,
          description: e.response.data?.description,
          message: e.response.data?.message,
          data: { 
            selectedSkus: newSelectedSkus,
            activatedSkus
          }
        }));
      } else {
        yield put(failedRequests(failedData));
      }
    }
  }
}

const getGamePassTournamentsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassTournaments, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassTournaments(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassTournamentsDetailsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassTournamentsDetails, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassTournamentsDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassTournamentTokenSaga = function* (payload) {
  const { id, testGoldBonanzaCurrentUTC } = payload?.payload;
  yield put(setLoading(true));
  yield put(setGamePassTournamentToken({ result: false }));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    let request = call(requestGamePassTournamentToken, id, sessionToken);
    if (testGoldBonanzaCurrentUTC) {
      const body = { testGoldBonanzaCurrentUTC };
      request = call(requestGamePassTournamentTokenWithCurrentUTC, id, sessionToken, body);
    }
    const response = yield request;
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(
        setGamePassTournamentToken({
          token: response.data.d?.token,
          result: response.data.result
        }
      ));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setGamePassTournamentToken({ result: false }));
    Cookies.remove(storageKeys.tournamentToken);
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const retryGamePassTournamentTokenSaga = function* (payload) {
  yield put(setGamePassTournamentToken({ result: false }));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRetryGamePassTournamentToken, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(
        setGamePassTournamentToken({
          token: response.data.d?.token,
          result: response.data.result
        }
      ));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setGamePassTournamentToken({ result: false }));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassFlagSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassFlag, payload.flagName, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const flagName = response.data.d.key || payload.flagName;
      const isShown = response.data.d.value;
      yield put(showGamePassPopup(flagName, isShown));
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const setGamePassFlagSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const flagValue = { value: payload.isShown };
    const response = yield call(requestSetGamePassFlag, payload.flagName, flagValue, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const flagName = response.data.d.key || payload.flagName;
      const isShown = response.data.d.value;
      yield put(showGamePassPopup(flagName, isShown));
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassActiveEntitlementsSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassActiveEntitlements, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassActiveEntitlements(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const joinBetaSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const joinBetaCode = { code: payload.betaCode };
    const response = yield call(requestJoinBeta, wenLamboIds.marketingId, joinBetaCode, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setJoinBetaResponseSuccess(wenLamboBetaCodeTexts.success));
      logFirebaseEventWithTimestamp(firebaseEvents.wenLamboJoin);
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      yield put(setJoinBetaResponseError(e.response.data.message));
      logFirebaseEvent(
        firebaseEvents.failedEvent, {
          status_code: e.response.data.code, 
          error_message: e.response.data.message
        });
    }
  }
}

const getBetaApplicationFormSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestBetaApplicationForm, wenLamboIds.applicationId, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setBetaApplicationForm(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      if (e.response.data.code === errorCodes.unauthorized) {
        const isGuest = yield select(guestSelector);
        let redirectRoute = siteRoutes.pin;
        if (isGuest) {
          redirectRoute = siteRoutes.login;
        }
        localStorage.setItem(storageKeys.previousRoute, window.location.href);
        window.location.href = redirectRoute;
      }
      logFirebaseEvent(
        firebaseEvents.failedEvent, {
          status_code: e.response.data.code, 
          error_message: e.response.data.message
        });
    }
  }
}

const submitBetaApplicationFormSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestBetaApplicationFormSubmit, payload.applicationFormAnswer, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setJoinBetaResponseSuccess(wenLamboBetaCodeTexts.success));
      logFirebaseEventWithTimestamp(firebaseEvents.wenLamboWaitlistSubmit);
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const toast = {
        result: false,
        title: e.response.data.code,
        message: e.response.data.message
      };
      yield put(setDisplayToast(toast));
      logFirebaseEvent(
        firebaseEvents.failedEvent, {
          status_code: e.response.data.code, 
          error_message: e.response.data.message
        });
    }
  }
}

export default function* gamepassSaga() {
  yield all([
    takeLatest(GET_GAMEPASS_PRODUCTS, getGamePassProductsSaga),
    takeLatest(GET_GAMEPASS_PRODUCT_DETAILS, getGamePassProductDetailsSaga),
    takeLatest(GET_GAMEPASS_SKU_DETAILS, getGamePassSkuDetailsSaga),
    takeLatest(GET_GAMEPASS_INVENTORY, getGamePassInventorySaga),
    takeLatest(ACTIVATE_GAMEPASS, activateGamePassSaga),
    takeLatest(ACTIVATE_MULTIPLE_SKUS, activateMultipleSkusSaga),
    takeLatest(GET_GAMEPASS_SCREEN, getGamePassScreenSaga),
    takeLatest(GET_GAMEPASS_TOURNAMENTS, getGamePassTournamentsSaga),
    takeLatest(GET_GAMEPASS_TOURNAMENTS_DETAILS, getGamePassTournamentsDetailsSaga),
    takeLatest(GET_GAMEPASS_TOURNAMENT_TOKEN, getGamePassTournamentTokenSaga),
    takeLatest(RETRY_GAMEPASS_TOURNAMENT_TOKEN, retryGamePassTournamentTokenSaga),
    takeLatest(GET_GAMEPASS_FLAG, getGamePassFlagSaga),
    takeLatest(SET_GAMEPASS_FLAG, setGamePassFlagSaga),
    takeLatest(GET_GAMEPASS_ACTIVE_ENTITLEMENTS, getGamePassActiveEntitlementsSaga),
    takeLatest(JOIN_BETA, joinBetaSaga),
    takeLatest(GET_BETA_APPLICATION_FORM, getBetaApplicationFormSaga),
    takeLatest(SUBMIT_BETA_APPLICATION_FORM, submitBetaApplicationFormSaga)
  ]);
}