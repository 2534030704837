import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';
import { Section } from '../Components';

export const CommunitiesNews = ({ title, news }) => (
  <Section title={title}>
    <Row className="news">
      {news.map((n, idx) => (
        <Col key={`News_${idx}_${n.id}`} xs={12} sm={12} md={12} lg={6} className="news-article">
          <Row>
            <Col xs={12} sm={5}>
              <a href={n.post_url} target="_blank" rel="noreferrer" className="news-thumbnail" style={{ backgroundImage: `url(${n.image_url})` }}></a>
            </Col>
            <Col xs={12} sm={7} className="news-details">
              <a href={n.post_url} target="_blank" rel="noreferrer" className="news-title">{n.name}</a>
              <LinesEllipsis text={n.short_desc} maxLine={3} ellipsis="..." trimRight basedOn="words" className="news-description" />
              <a href={n.post_url} target="_blank" rel="noreferrer" className="news-read-more">Read More</a>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  </Section>
);