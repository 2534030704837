import React from 'react';
import { LegalStatement } from '../Components';
import { privacyPolicy } from '../../constants';

export const PrivacyPolicy = () => (
  <LegalStatement
    pageTitle="Privacy Policy"
    type="PrivacyPolicy"
    content={privacyPolicy}
  />
);
