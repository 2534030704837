import {
  SET_LAUNCHPAD_COLLECTION,
  MINTING_IS_REDEEM,
  SET_LAUNCHPAD_COLLECTION_ITEM,
  GET_NFT_DATA,
  SET_PRIZE_SLUG,
  SET_PRIZE_SLUG_DATA,
  SET_DYNAMIC_NFT_SLUG,
  RESET_STATE
} from '../actions/constants';

const launchpadState = {
  collection: [],
  collectionItem: [],
  isRedeem: false,
  nftData: {},
  prizeSlug: '',
  prizeSlugData: {},
  slugEnableButton: false
};

const launchpadReducer = (state = launchpadState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return launchpadState;
    case SET_LAUNCHPAD_COLLECTION:
      return {
        ...state,
        collection: action.launchpadCollection
      };
    case SET_LAUNCHPAD_COLLECTION_ITEM:
      return {
        ...state,
        collectionItem: action.launchpadCollectionItem
      };
    case MINTING_IS_REDEEM:
      return {
        ...state,
        isRedeem: action.redeem
      };
    case GET_NFT_DATA:
      return {
        ...state,
        nftData: action.nftData
      };
    case SET_PRIZE_SLUG:
      return {  
        ...state,
        prizeSlug: action.prizeSlug
      };
    case SET_PRIZE_SLUG_DATA:
      return {  
        ...state,
        prizeSlugData: action.prizeSlugData
      };
    case SET_DYNAMIC_NFT_SLUG:
      return {  
        ...state,
        slugEnableButton: action.slugEnableButton
      };
    default:
      return state;
  }
}

export default launchpadReducer;