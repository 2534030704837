import React, { useState } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { CheckboxTextField } from '../../Commons';
import {
  answerFormats,
  defaultTextFieldPlaceholder,
  fieldTypes
} from '../../../constants';

const FormField = ({ formField, updateAnswer, formErrors, idKey, className }) => {
  const [checkboxAnswers, setCheckboxAnswers] = useState([]);
  const hasError = formErrors.includes(formField[idKey]);

  let caption = formField.caption;
  if (formField.caption?.indexOf('*') === formField.caption?.length - 1) {
    caption = formField.caption?.replace('*', '<span>*</span>');
  }

  const handleCheckboxAnswers = (answer, hasAdditionalField, key) => {
    const formattedAnswer = { id: answer, key };
    if (hasAdditionalField) {
      formattedAnswer.value = '';
    }
    if (!checkboxAnswers.includes(formattedAnswer)) {
      checkboxAnswers.push(formattedAnswer);
    } else {
      const checkboxAnswerIdx = checkboxAnswers.indexOf(formattedAnswer);
      if (checkboxAnswerIdx !== -1) checkboxAnswers.splice(checkboxAnswerIdx, 1);
    }
    setCheckboxAnswers(checkboxAnswers);
  }

  const handleCheckboxTextField = (fieldId, answer) => {
    const checkboxWithTextField = checkboxAnswers.find(a => !isNil(a.value));
    const checkboxWithTextFieldIndex = checkboxAnswers.indexOf(checkboxWithTextField);
    if (checkboxWithTextFieldIndex !== -1) {
      checkboxAnswers[checkboxWithTextFieldIndex].value = answer;
    }
    setCheckboxAnswers(checkboxAnswers);
    updateAnswer(fieldId, checkboxAnswers);
  }

  const handleInput = (e, field) => {
    switch (field.answerFormat) {
      case answerFormats.textField:
      case answerFormats.textArea:
      case answerFormats.multipleChoice:
        updateAnswer(field[idKey], e.target.value);
        break;
      default:
        break;
    }
  }

  const handleCheckboxInput = (e, field, hasAdditionalField, key) => {
    handleCheckboxAnswers(e.target.value, hasAdditionalField, key);
    updateAnswer(field[idKey], checkboxAnswers);
  }
  
  const renderInput = (field, error) => {
    switch (field.answerFormat) {
      case answerFormats.textField:
        return (
          <div className={classNames(
            `${className}-input`,
            `${className}-text`,
            {[`${className}-input-error`]: error},
            {[`${className}-input-prefilled`]: !isNil(field?.isEditable) && 
              !field?.isEditable}
          )}>
            <input 
              id={field[idKey]} 
              type={fieldTypes.textField}
              onChange={(e) => handleInput(e, field)}
              defaultValue={field?.placeholder}
            />
          </div>
        );
      case answerFormats.textArea:
        return (
          <div className={classNames(
            `${className}-textarea`,
            {[`${className}-textarea-error`]: error}
          )}>
            <textarea
              id={field[idKey]}
              onChange={(e) => handleInput(e, field)}
              rows="4"
              placeholder={defaultTextFieldPlaceholder}
            />
          </div>
        );
      case answerFormats.multipleChoice:
      case answerFormats.multipleSelect:
        const isMultipleSelect = field.answerFormat === answerFormats.multipleSelect;
        const checkboxFieldType = isMultipleSelect ? fieldTypes.multipleSelect : fieldTypes.multipleChoice;
        const handleInputMethod = isMultipleSelect ? handleCheckboxInput : handleInput;
        return (
          <div className={classNames(
            `${className}-input`,
            {[`${className}-input-error`]: error}
          )}>
            {field.choices &&
              field.choices.map((choice, key) => (
                <CheckboxTextField
                  fieldType={checkboxFieldType}
                  field={field}
                  choice={choice}
                  key={key}
                  index={key + 1}
                  handleInput={handleInputMethod}
                  handleTextField={handleCheckboxTextField}
                  className={`${className}-checkbox`}
                  captionClassName={`${className}-checkbox-caption`}
                  textFieldClassName={`${className}-checkbox-text`}
                />
              ))}
          </div>
        );
      default:
        break;
    }
  }

  return (
    <div className={`${className}-group`}>
      <div
        className={`${className}-label`}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
      <div>{renderInput(formField, hasError)}</div>
    </div>
  );
}

export default FormField;