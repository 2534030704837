import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { requestGameDetails } from '../api/games';
import { requestHomeCollection } from '../api/home';
import { GET_GAME_DETAILS } from '../actions/constants';
import { setLoading, setHomeCollection, setGameDetails, failedRequests } from '../actions';
import { homeCollectionSelector } from '../selectors';
import { storageKeys } from '../constants';

const getGameDetailsSaga = function* (payload) {
  try {
    const homeCollection = yield select(homeCollectionSelector);
    if (homeCollection && !isEmpty(homeCollection)) {
      yield call(getGamePageSaga, payload);
    } else {
      const sessionToken = localStorage.getItem(storageKeys.sessionToken);
      const response = yield call(requestHomeCollection, sessionToken, 'v2');
      if (response.status >= 200 && response.status < 400) {
        yield put(setHomeCollection(response.data.sections));
        yield call(getGamePageSaga, payload);
      }
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePageSaga = function* (payload) {
  try {
    const homeCollection = yield select(homeCollectionSelector);
    const games = homeCollection.filter(h => h.experience === 'Game').map(h => h.d.filter(d => d.experience === 'game'));
    const allGames = [].concat(...games);
    const selectedGames = allGames.filter(g => {
      if (g.slug) return g.slug.toLowerCase() === payload.gameName.toLowerCase();
      else return camelCase(g.name).toLowerCase() === payload.gameName.toLowerCase();
    });
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGameDetails, selectedGames[0].id, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setGameDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* homeSaga() {
  yield takeLatest(GET_GAME_DETAILS, getGameDetailsSaga);
}