import React from 'react';
import './SpinTheWheelMechanics.scss';

export const Eligibility = ({ name, description }) => (
  <div className="stw-mechanics-eligibility">
    <h1 className="stw-mechanics-title">{name}</h1>
    <div
      className="stw-mechanics-description"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </div>
);