import React from 'react';
import { LegalStatement } from '../Components';
import { termsAndConditions } from '../../constants';

export const TermsAndConditions = () => (
  <LegalStatement
    pageTitle="Terms &amp; Conditions"
    type="TermsAndConditions"
    content={termsAndConditions}
    hasSectionPrefix
    sectionTitleCentered
  />
);