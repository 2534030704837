import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  ViberShareButton,
  ViberIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon
} from 'next-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import { minWidths } from '../../../constants';

export const LaunchpadShareIcons = ({ url, title, platforms }) => {
  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const messengerOnClick = (event) => {
    window.open(
      "fb-messenger://share?link=" +
        encodeURIComponent(url)
    );
  };

  const newUrl = url ? url : '';
  
  return (
    <>
      {platforms.includes('facebook') &&
        <FacebookShareButton
          url={url}
          title={title}
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>}
      {platforms.includes('messenger') &&
        !isDesktop && 
        <button
          className="share-popup-button"
          onClick={messengerOnClick}
        >
          <FacebookMessengerIcon size={40} round />
        </button>}
      {platforms.includes('viber') &&
        !isDesktop && 
        <ViberShareButton
          url={url}
          title={title}
        >
          <ViberIcon size={40} round />
        </ViberShareButton>}
      {platforms.includes('whatsapp') &&
        <WhatsappShareButton
          url={newUrl}
          title={title}
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>}
      {platforms.includes('twitter') &&
        <TwitterShareButton
          title={title}
          url={url}
        >
          <TwitterIcon size={40} round />
        </TwitterShareButton>}
      {platforms.includes('telegram') &&
        <TelegramShareButton
          url={url}
          title={title}
        >
          <TelegramIcon size={40} round />
        </TelegramShareButton>}
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip 
          open={open} 
          title="Copied to clipboard!" 
          onClose={() => setOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div className="launchpad-social-wrapper">
            <CopyToClipboard
              className="launchpad-social"
              text={title}
              onCopy={() => setOpen(true)}>
              <ContentCopyIcon fontSize="medium" className="launchpadsocial-icon"/>
            </CopyToClipboard>
          </div>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
}

export default LaunchpadShareIcons;