import React from 'react';
import './SpinTheWheelMechanics.scss';

export const FAQs = ({ title, faqs }) => (
  <div className="stw-mechanics-faqs">
    <h1 className="stw-mechanics-title">{title}</h1>
    <div>
      {faqs.map((f, fIdx) => (
        <div
          key={`STW_FAQ_${fIdx + 1}`}
          className="stw-mechanics-faqs-item"
        >
          <div className="stw-mechanics-faqs-question">{f.question}</div>
          <div
            className="stw-mechanics-faqs-answer"
            dangerouslySetInnerHTML={{ __html: f.answer }}
          />
        </div>
      ))}
    </div>
  </div>
);