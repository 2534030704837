import React from 'react';
import { gamePassExpiredText } from '../../../constants';
import { useCountdown } from '../../../hooks/useCountdown';
import './ActiveGamePassPopup.scss';

const GamePassInventoryTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const formatTimeVal = (time) => {
    return time > 9 ? time : `0${time}`;
  }

  const daysVal = days > 0 ? `${days}d ` : '';
  const hoursVal = formatTimeVal(hours);
  const minutesVal = formatTimeVal(minutes);
  const secondsVal = formatTimeVal(seconds);

  const countdownVal = `${daysVal}${hoursVal}:${minutesVal}:${secondsVal}`;
  const isExpired = days + hours + minutes + seconds <= 0;
  const timerVal = isExpired ? gamePassExpiredText : countdownVal;

  return (
    <div className="active-game-pass-popup-tab-item-timer">
      {timerVal}
    </div>
  );
};

export default GamePassInventoryTimer;