import React from 'react';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Featured } from '../Components';
import {
  Banner,
  BannerMobile,
  Carousel,
  CarouselItem
} from '../Commons';
import {
  activateKind,
  discordInviteLink,
  experiences,
  featuredBannerButtons,
  gamePassCTA,
  maxWidths,
  minWidths
} from '../../constants';
import discordDailyBoostBannerDesktop from '../../assets/discord-daily-boost/banners/desktop.jpg'; // to be updated
import discordDailyBoostBannerMobile from '../../assets/discord-daily-boost/banners/mobile.jpg'; // to be updated

export const FeaturedGames = ({ featured, wenLamboBanner }) => {
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS })

  return (
    <Featured>
      <Carousel>
        {!isEmpty(featured) && 
          featured.map((f, key) => {
          const isQuest = f?.experience === experiences.quest;
          const isGamePass = f?.experience === activateKind.gamePass;
          const isDiscordDailyBoost = f?.experience === experiences.discordDailyBoost;
          let experience = isGamePass ? f?.experience : `${f?.experience.toLowerCase()}s`;
          const gameName = f?.slug || camelCase(f?.name).toLowerCase();

          let buttonText = featuredBannerButtons.joinTheGame;
          if (isQuest) buttonText = featuredBannerButtons.viewQuests;
          else if (isGamePass) buttonText = featuredBannerButtons.startPlaying;
          else if (isDiscordDailyBoost) buttonText = featuredBannerButtons.joinDiscord;

          let buttonLink = `/${experience}/${gameName}`;
          if (isQuest) buttonLink = `/${experience}`;
          else if (isGamePass) buttonLink = gamePassCTA;
          else if (isDiscordDailyBoost) buttonLink = discordInviteLink;

          let imageUrl = null;
          if (f?.image_url) imageUrl = f?.image_url;
          if (isGamePass) {
            if (isMobile && !isEmpty(wenLamboBanner.mobile)) {
              imageUrl = wenLamboBanner.mobile;
            }
            if (!isMobile && !isEmpty(wenLamboBanner.desktop)) {
              imageUrl = wenLamboBanner.desktop;
            }
          }

          if (isDiscordDailyBoost) {
            if (isMobile) imageUrl = discordDailyBoostBannerMobile;
            else if (!isMobile) imageUrl = discordDailyBoostBannerDesktop;
          }

          let mobileTitle = f?.name;
          if (isGamePass || isDiscordDailyBoost) mobileTitle = null;

          return (
            <CarouselItem key={key}>
              <MediaQuery minWidth={minWidths.tablet}>
                <Banner
                  title={f?.name}
                  text={f?.short_desc}
                  imageUrl={imageUrl}
                  buttonText={buttonText}
                  buttonLink={buttonLink}
                  imageClickable
                  carousel
                  buttonAnchor={isDiscordDailyBoost}
                  button={isDiscordDailyBoost}
                  onClickButton={() => window.open(discordInviteLink, '_self')}
                  featured={featured}
                />
              </MediaQuery>
              <MediaQuery maxWidth={maxWidths.mobileSM}>
                <BannerMobile
                  title={mobileTitle}
                  imageUrl={imageUrl}
                  link={buttonLink}
                  featured={featured}
                  carousel
                />
              </MediaQuery>
            </CarouselItem>
          );
        })}
      </Carousel>
    </Featured>
  );
}