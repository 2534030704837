import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { activateKind, activateKindTitle } from '../../../constants';
import './ActivateGamePassToast.scss';

const ActivateGamePassToast = ({ toast, open, hide, onClick }) => {  
  const [quantity, setQuantity] = useState(1);
  const [title, setTitle] = useState('');

  const handleAddQuantity = () => {
    const addQuantityVal = quantity < toast.quantity ? quantity + 1 : quantity;
    setQuantity(addQuantityVal);
  }

  const handleSubtractQuantity = () => {
    const subtractQuantityVal = quantity > 1 ? quantity - 1 : quantity;
    setQuantity(subtractQuantityVal);
  }

  useEffect(() => {
    let title = '';
    if (toast.activateKind === activateKind.gamePass) {
      title = activateKindTitle.gamePass;
    } else {
      title = activateKindTitle.goldBoost;
    }
    setTitle(title);
  }, [toast])

  return (
    <Modal
      backdrop
      show={open}
      className="activate-gamepass-toast-container"
      contentClassName="activate-gamepass-toast"
      dialogClassName="activate-gamepass-toast-dialog"
      backdropClassName="activate-gamepass-toast-bg"
      centered
      onHide={hide}
    >
      <Modal.Header closeButton closeVariant="white" onHide={hide}>
        <p className="modal-header-title">Activate {title}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="activate-gamepass-toast-text-container">
          <div className="activate-gamepass-toast-info">
            <img
              src={toast.image}
              className="activate-gamepass-toast-ticket"
              alt="gamepass-ticket"
            />
            <div className="activate-gamepass-toast-texts">
              <p className="activate-gamepass-toast-name">{toast.name}</p>
              <p className="activate-gamepass-toast-quantity">Qty: {toast.quantity}</p>
            </div>
          </div>
          <div className="activate-gamepass-toast-quantity-container">    
            <div className="activate-gamepass-toast-quantity-controller">
              <button
                className="activate-gamepass-toast-quantity-button-icon"
                onClick={handleSubtractQuantity}
              >
                <RemoveCircleOutline className="activate-gamepass-toast-quantity-icon" />
              </button>
              <div className="activate-gamepass-toast-quantity-input">{quantity}</div>
              <button
                className="activate-gamepass-toast-quantity-button-icon"
                onClick={handleAddQuantity}
              >
                <AddCircleOutline className="activate-gamepass-toast-quantity-icon" />
              </button>
            </div>
            <button
              className="activate-gamepass-toast-quantity-button"
              onClick={() => setQuantity(toast.quantity)}
            >
              Max
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="modal-footer-button"
          onClick={() => onClick(toast.subKind, quantity, toast.activateKind)}
        >
          Activate {title}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivateGamePassToast;