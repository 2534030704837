import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { DateTime } from 'luxon';
import { 
  requestChatMessages, 
  requestChatMessageThread, 
  requestClaimScholarship 
} from '../api/chat';
import { 
  CLAIM_SCHOLARSHIP, 
  GET_CHAT_MESSAGES, 
  GET_CHAT_MESSAGE_THREAD, 
  SUBMIT_CHAT_MESSAGE 
} from '../actions/constants';
import { 
  setLoading, 
  setChatMessages, 
  setChatMessageThread, 
  failedRequests
} from '../actions';
import { 
  chatMessageThreadSelector, 
  mobileNumberSelector, 
} from '../selectors';
import { dateTimeFormats, storageKeys } from '../constants';
import groupMessagesByDate from '../utils/groupMessagesByDate';

const getChatMessagesSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const url = 'https://run.mocky.io/v3/d63a6f06-3fa4-4913-a6e0-54aa468b6036';
    const response = yield call(requestChatMessages, url);
    if (response.status >= 200 && response.status < 400 && sessionToken) {
      const { dataset, d } = response.data;
      const messages = { title: dataset, threads: d }; 
      yield put(setChatMessages(messages));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getChatMessageThreadSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const url = 'https://run.mocky.io/v3/b90c96d3-3ea4-47ef-8ddb-696d55a4b818';
    const response = yield call(requestChatMessageThread, url, payload.threadId);
    if (response.status >= 200 && response.status < 400 && sessionToken) {
      const thread = groupMessagesByDate(response.data.d.messages, response.data.d);
      yield put(setChatMessageThread(thread));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitChatMessageSaga = function* (payload) {
  try {
    const thread = yield select(chatMessageThreadSelector);
    const mobileNumber = yield select(mobileNumberSelector);
    const messages = thread.messages;
    const messageGroups = messages.map(m => m.messages);
    const allMessages = [].concat(...messageGroups);
    const currentDateTime = DateTime.now().toFormat(dateTimeFormats.dateTime);
    const newMessage = {
      id: 'id003',
      name: 'You',
      message: payload.message,
      messageDate: currentDateTime,
      pfpUrl: 'https://png.pngitem.com/pimgs/s/649-6490124_katie-notopoulos-katienotopoulos-i-write-about-tech-round.png',
      incoming: false,
      senderId: mobileNumber
    };
    allMessages.push(newMessage);
    const newThread = groupMessagesByDate(allMessages, thread, true);
    yield put(setChatMessageThread(newThread));
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const claimScholarshipSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestClaimScholarship, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400 && sessionToken) {
      yield put(setLoading(false));
    }
  } catch (e) {
    if (e.response && e.response.data) {
      yield put(setLoading(false));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* chatSaga() {
  yield all([
    takeLatest(GET_CHAT_MESSAGES, getChatMessagesSaga),
    takeLatest(GET_CHAT_MESSAGE_THREAD, getChatMessageThreadSaga),
    takeLatest(SUBMIT_CHAT_MESSAGE, submitChatMessageSaga),
    takeLatest(CLAIM_SCHOLARSHIP, claimScholarshipSaga)
  ]);
}
