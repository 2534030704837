import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import './Carousel.scss';

export const CarouselItem = ({ children, width, onClick }) => (
  <div 
    className="carousel-item" 
    style={{ width: width }} 
    onClick={onClick}
  >
    {children}
  </div>
);

export const Carousel = ({ className, arrows, children, homeCarousel }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) updateIndex(activeIndex + 1);
    }, 5000);
    return () => {
      if (interval) clearInterval(interval);
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  const onPrevClick = () => {
    updateIndex(activeIndex - 1);
  }

  const onNextClick = () => {
    updateIndex(activeIndex + 1);
  }

  return (
    <div className="carousel-wrapper">
      <div
        {...handlers}
        className={classNames(
          "carousel", 
          className,
          {"home-carousel" : homeCarousel}
        )}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {React.Children.map(children, (child) => React.cloneElement(child, { width: "100%" }))}
        </div>
        <div className={classNames(
          "indicators", 
          {"home-carousel-indicators" : homeCarousel}
        )}>
          {React.Children.count(children) > 1 && 
            React.Children.map(children, (child, index) => (
            <button 
              className={classNames(
                "indicator",
                {"home-carousel-indicator": homeCarousel}
              )}
              onClick={() => updateIndex(index)}
            >
              <div className={classNames(
                "indicator-button",
                {"home-carousel-indicator-button": homeCarousel},
                {"indicator-button-active": index === activeIndex},
                {"home-carousel-indicator-button-active": homeCarousel && index === activeIndex},
              )} />
            </button>
          ))}
        </div>
      </div>
      {arrows &&
        <button className={classNames("carousel-arrow", "carousel-arrow-prev")} onClick={onPrevClick}>
          <KeyboardArrowLeftRoundedIcon className="carousel-arrow-icon" />
        </button>}
      {arrows &&
        <button className={classNames("carousel-arrow", "carousel-arrow-next")} onClick={onNextClick}>
          <KeyboardArrowRightRoundedIcon className="carousel-arrow-icon" />
        </button>}
    </div>
  );
};

