import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { RemoveScroll } from 'react-remove-scroll';
import {
  // ActiveGamePasses,
  ActiveGamePassPopup,
  FloatingIconManager,
  GamePassInventoryCoins,
  GamePassPlayPopup,
  LayoutContent,
  PageTitle,
  RedirectToMobileAppToast,
  Section,
  SectionList,
  WenLamboOnBoardingTutorial
} from '../Components';
import { BuyGamePassSection } from './BuyGamePassSection';
import { GamePassScreenBanner } from './GamePassScreenBanner';
import { Carousel, CarouselItem } from '../Commons';
import {
  getGamePassScreen,
  getHomeCollection,
  setLoading,
  setHideHeader,
  setHideFooter,
  getGamePassActiveEntitlements,
  getUserProfile,
  getWenLamboPageBanners,
  getRewardPartners,
  setRewardPartners,
  setAuthStep
} from '../../actions';
import {
  gamePassScreenSelector,
  homeCollectionSelector,
  walletCryptoSelector,
  entitlementsSelector,
  walkthroughHeightLimitFlagSelector,
  wenLamboPageBannerLogoSelector,
  wenLamboPageBannerBGSelector,
  wenLamboSTWBannerSelector,
  gamePassInventorySelector,
  guestSelector,
  firstTimeCompletionRewardsSelector,
  rewardPartnersSelector
} from '../../selectors';
import {
  firebaseEvents,
  gamePassText,
  goldBoosterText,
  gamePassProductKinds,
  gamePassScreenSections,
  gamePassGamesCTA,
  buyGamePassCTA,
  maxWidths,
  minWidths,
  onBoardingData,
  cardsKind,
  mobileAppPromptText,
  storageKeys,
  carouselText,
  firstTimeCompletionRewardsKinds,
  gamePassPacks,
  authSteps,
  siteRoutes
} from '../../constants';
import { useQuery } from '../../hooks';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { getGoldCoinsValue } from '../../utils/getWalletCredits';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import './GamePassScreen.scss';

export const GamePassScreen = () => {
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS });
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const isGuest = useSelector(state => guestSelector(state));
  const homeCollection = useSelector(state => homeCollectionSelector(state));
  const sections = useSelector(state => gamePassScreenSelector(state));
  const crypto = useSelector(state => walletCryptoSelector(state));
  const entitlements = useSelector(state => entitlementsSelector(state));
  const hasNoWalkthroughHeightLimit = useSelector(state => walkthroughHeightLimitFlagSelector(state));
  const wenLamboPageBannerLogo = useSelector(state => wenLamboPageBannerLogoSelector(state));
  const wenLamboPageBannerBG = useSelector(state => wenLamboPageBannerBGSelector(state));
  const wenLamboSTWBanner = useSelector(state => wenLamboSTWBannerSelector(state));
  const inventory = useSelector(state => gamePassInventorySelector(state));
  const firstTimeCompletionRewards = useSelector(state => firstTimeCompletionRewardsSelector(state));
  const rewardsPartner = useSelector(state => rewardPartnersSelector(state));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [gameDetails, setGameDetails] = useState({});
  const [activeGamePassOpen, openActiveGamePass] = useState(false);
  const [showRedirectoMobileAppToast, setShowRedirectoMobileAppToast] = useState(false);

  const isSwitchToAppWenLamboShown = Cookies.get(storageKeys.switchToApp.wenLambo);
  const isTutorial = localStorage.getItem(storageKeys.isTutorial);
  const isLoggedInTutorial = Cookies.get(storageKeys.isLoggedInTutorial);
  const onboardQuery = query.get(onBoardingData.onboard);
  const from = query.get(onBoardingData.from);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getGamePassScreen());
    dispatch(getHomeCollection());
    dispatch(getWenLamboPageBanners());
    dispatch(getRewardPartners(carouselText));
    if (!isGuest) {
      dispatch(getUserProfile());
      dispatch(getGamePassActiveEntitlements());
    }
  }, [dispatch, isGuest])

  useEffect(() => {
    if (!isSwitchToAppWenLamboShown && !isTutorial && !isGuest) {
      const deviceInfo = getDeviceInfo();
      const android = /android/i.test(deviceInfo.os);

      if (android) {
        setShowRedirectoMobileAppToast(android);
        Cookies.set(storageKeys.switchToApp.wenLambo, true);
      }
    }
  }, [isSwitchToAppWenLamboShown, isTutorial, isGuest])

  useEffect(() => {
    logFirebaseEventWithTimestamp(firebaseEvents.wenLamboMain);
  }, [])

  useEffect(() => {
    dispatch(setHideFooter(false));
    dispatch(setHideHeader(false));

    return () => dispatch(setRewardPartners({}));
  }, [dispatch]);

  const activeGamePasses = !isEmpty(entitlements) && entitlements.find(e => e.name === gamePassText);
  const activeGoldBoosters = !isEmpty(entitlements) && entitlements.find(e => e.name === goldBoosterText);
  let activeGamePassItems = [];
  let activeGoldBoosterItems = [];
  if (activeGamePasses) activeGamePassItems = activeGamePasses.items;
  if (activeGoldBoosters) activeGoldBoosterItems = activeGoldBoosters.items;

  const handleGamePassClick = () => {
    if ((activeGamePasses &&
      activeGamePassItems.length > 1) ||
      (activeGoldBoosters &&
      activeGoldBoosterItems.length > 1)) {
      openActiveGamePass(true);
    }
  }

  const handleAdBannerClick = (link) => {
    if (!isGuest) {
      let event = firebaseEvents.wenLamboMainSPWCarousel;
      if (link.includes('referral')) {
        event = firebaseEvents.wenLamboMainRefer;
      }
      logFirebaseEventWithTimestamp(event);
      navigate(link);
    } else {
      dispatch(setAuthStep(authSteps.login));
    }
  }

  useEffect(() => {
    const activateGamePass = searchParams.get(onBoardingData.onboard);
    if (activateGamePass === onBoardingData.flags.activate && isLoggedInTutorial) {
      searchParams.set(onBoardingData.onboard, onBoardingData.flags.modal);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, isLoggedInTutorial])

  const hasGamePass = inventory.filter(r => r.kind.includes(cardsKind.gamePass));
  const playWithGamePass = firstTimeCompletionRewards.filter(f => f.subKind === firstTimeCompletionRewardsKinds.playWithGamePass);
  const showOnboardingTutorial = onboardQuery === onBoardingData.flags.modal ||
    onboardQuery === onBoardingData.flags.games ||
    (onboardQuery === onBoardingData.flags.activate && 
      playWithGamePass[0]?.currentValue === 0 &&
      isEmpty(entitlements) && 
      !isEmpty(inventory) && 
      !isEmpty(hasGamePass));
  
  useEffect(() => {
    if (showOnboardingTutorial) {
      localStorage.removeItem('isNew');
    } else {
      localStorage.removeItem('isTutorial');
    }
  }, [showOnboardingTutorial])

  if (sections && !isEmpty(sections)) {
    let gamesSection = null;
    if (homeCollection && !isEmpty(homeCollection)) {
      gamesSection = homeCollection.find(c => c.dataset === gamePassScreenSections.playAndEarnGold);
    }
    const buySection = sections.find(s => s.dataset === gamePassScreenSections.buyGamePass);
    const goldenVault8XPack = buySection?.d[0]?.skus?.find(s => s.kind === gamePassPacks.pack8X);
    const gamePasses = compact(buySection.d);
    const recommendedItems = gamePasses.filter(i => i.name.toLowerCase() === gamePassProductKinds.recommended);
    const buyItems = [].concat(...recommendedItems.map(b => {
      const { currency, kind, skus } = b;
      skus.map(s => {
        s.currency = currency;
        s.mainKind = kind;
        return s;
      });
      return skus;
    }));
    const adsSection = sections.filter(s => {
      return s.dataset === gamePassScreenSections.referAFriend ||
        s.dataset === gamePassScreenSections.winPrizes
    });
    const goldCoinsValue = getGoldCoinsValue(crypto);
    
    return (
      <LayoutContent
        outerClassName="game-pass-screen"
        innerClassName="game-pass-screen-inner"
      >
        <PageTitle title="Game Pass" />
        <GamePassScreenBanner
          logo={wenLamboPageBannerLogo}
          bg={wenLamboPageBannerBG}
          isGuest={isGuest}
          selectedItem={goldenVault8XPack}
        />
        <div className={classNames(
          "game-pass-screen-sections",
          {"game-pass-screen-sections-guest": isGuest}
        )}>
          {!isGuest &&
            <div className={classNames(
              "game-pass-screen-section",
              "game-pass-screen-section-highlighted"
            )}>
              {/* Hide Inventory UI, UI access to inventory and all redirections to it
                <ActiveGamePasses
                  activeGamePass={activeGamePassItems}
                  activeGoldBooster={activeGoldBoosterItems}
                  handleGamePassClick={handleGamePassClick}
                  containerClassName="game-pass-screen-timer-container"
                  hasHeader={isDesktop}
                  redirectIfUnauthorized={false}
                  inventoryRedirect
                  compact
                /> */}
              <GamePassInventoryCoins
                coins={goldCoinsValue}
                className="game-pass-screen-gold-coins"
              />
            </div>}
          <div className="game-pass-screen-section">
            <div id="buy-gamepass">
              <BuyGamePassSection
                title={buySection.dataset}
                collection={recommendedItems}
                list={buyItems}
                link={buyGamePassCTA}
                linkText="View all"
                sectionClassName={classNames(
                  "game-pass-screen-section-inner",
                  "game-pass-screen-section-inner-themed",
                  "game-pass-screen-section-inner-themed-beta"
                )}
              />
            </div>
          </div>
          {gamesSection &&
            <div className="game-pass-screen-section">
              <div 
                id="play-and-earn-gold" 
                className={classNames(
                  {"game-pass-screen-section-tutorial": isTutorial && onboardQuery === onBoardingData.flags.games}
                )}
              >
                <SectionList
                  title={gamesSection.dataset}
                  list={gamesSection.d}
                  link={gamePassGamesCTA}
                  linkText="View all"
                  sectionClassName={classNames(
                    "game-pass-screen-section-inner",
                    "game-pass-screen-section-inner-themed"
                  )}
                  handleGameDetails={setGameDetails}
                />
              </div>
            </div>}
          <div className={classNames(
            "game-pass-screen-section",
            "game-pass-screen-section-ads",
            {"game-pass-screen-section-ads-gap": !isMobile && hasNoWalkthroughHeightLimit}
          )}>
            {adsSection.map(a => {
              const { bannerUrl, bannerUrlMobile } = a.d.imageUrls;
              const isSTW = a.dataset === gamePassScreenSections.winPrizes;
              let banner = bannerUrl;
              if (isMobile) {
                if (isSTW
                  && !isEmpty(wenLamboSTWBanner.mobile)) {
                  banner = wenLamboSTWBanner.mobile;
                } else {
                  banner = bannerUrlMobile;
                }
              } else {
                if (isSTW
                  && !isEmpty(wenLamboSTWBanner.desktop)) {
                  banner = wenLamboSTWBanner.desktop;
                }
              }
              return (
                <div
                  id={a.id}
                  key={`GamePassScreenSection_${a.dataset}`}
                  className="game-pass-screen-section-ad"
                >
                  <Section
                    title={a.dataset}
                    sectionClassName="game-pass-screen-section-inner"
                  > 
                    <img
                      src={banner}
                      alt={a.dataset}
                      className="game-pass-screen-section-ad-banner"
                      onClick={() => handleAdBannerClick(a.d.link)}
                    />
                  </Section>
                </div>
              );
            })}
            {!isEmpty(rewardsPartner) &&
              <div className="game-pass-screen-section-ad">
                <Section
                  title={rewardsPartner.title}
                  sectionClassName="game-pass-screen-section-inner"
                >
                  <Carousel className="game-pass-screen-section-carousel">
                    {rewardsPartner?.sections?.map((i, iIdx) => {
                      const banner = isDesktop ? i?.imageUrls?.carouselUrl : i?.imageUrls?.['carouselUrl-mobile'];
                      return (
                        <CarouselItem
                          key={`Banners_${i.name}_${iIdx}`}
                          onClick={() => handleAdBannerClick(siteRoutes.partners)}
                        >
                          <img
                            className="game-pass-screen-section-carousel-banner"
                            src={banner}
                            alt={i?.name}
                          />
                        </CarouselItem>
                      )
                    })}
                  </Carousel>
                </Section>
              </div>}
          </div>
        </div>
        <GamePassPlayPopup {...gameDetails} />
        <ActiveGamePassPopup
          open={activeGamePassOpen}
          hide={() => openActiveGamePass(false)}
          entitlements={entitlements}
        />
        {showOnboardingTutorial &&
          <RemoveScroll>
            <WenLamboOnBoardingTutorial 
              gamesSection={gamesSection} 
              inventory={inventory} 
            />
          </RemoveScroll>}
        <FloatingIconManager />
        {!isGuest &&
          !isTutorial && 
          !from &&
          <RedirectToMobileAppToast 
            open={showRedirectoMobileAppToast}
            hide={() => setShowRedirectoMobileAppToast(false)}
            title={mobileAppPromptText.wenLambo}
            description={mobileAppPromptText.wenLamboDescription}
          />}
      </LayoutContent>
    );
  }

  return null;
}