import React, { useEffect, useRef, useState } from 'react'
import { PageTitle, Section } from '../Components'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Link } from 'react-router-dom';
import { useIsInViewport } from '../../hooks/useIsInViewport';
import classNames from 'classnames';
import './EditProfile.scss'

export const EditProfileHeader = ({ profilePhotoStyle, openChangePhotoMenu, fields, renderInput }) => {
  const [isVisible, setIsVisible] = useState(true);
  const headerRef = useRef();
  const isInViewport = useIsInViewport(headerRef);

  useEffect(() => {
    setIsVisible(isInViewport);
  },[isInViewport]);

  const defineVisible = () => {
    let cname = "edit-profile-section";
    if (isVisible) 
      cname += "-show";
    else
      cname += "-hide";
      
    return cname;
  }

  return (
    <>
    <PageTitle title="Edit Profile" />
      <Section title="Edit Profile" backButtonRoute="/profile" sectionClassName={classNames("edit-profile-section", defineVisible())} sectionHeaderClassName="edit-profile-section-header" titleCentered>
        <div ref={headerRef} className="edit-profile-header">
          <div className="edit-profile-cover"></div>
          <div className="edit-profile-pic" style={profilePhotoStyle}></div>
          <button className="edit-profile-pic-btn" onClick={openChangePhotoMenu}>Change Photo</button>
        </div>
      </Section>
      <div className='position-relative'>
        {!isVisible && <div className="edit-profile-back">
            <Link to={'/profile'}><ArrowBackIosNewRoundedIcon className="edit-profile-back-icon" /></Link>
        </div>}
        <div>
          {fields.form.map((f, idx) => (
          <div key={`FormFields_${idx}`} className="edit-profile-fields">
            {f.caption && <div className="edit-profile-fields-caption">{f.caption}</div>}
            {f.fields.map(field => renderInput(field))}
          </div>
          ))}
        </div>
      </div>
    </>
  )
}
