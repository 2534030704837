import React from 'react';
import './FloatingIcon.scss';

export const FloatingIcon = ({ onClick, src, alt, containerClassName, children }) => (
  <div onClick={onClick} className={containerClassName}>
    <div className="floating-icon-wrapper">
      <img
        src={src}
        alt={alt}
        className="floating-icon"
        width={100}
        height={100}
      />
      {children}
    </div>
  </div>
);