import { RESET_STATE, SET_HOME_TUTORIAL } from '../actions/constants';

const homeTutorialState = {
  step: 1
};

const homeTutorialReducer = (state = homeTutorialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return homeTutorialState;
    case SET_HOME_TUTORIAL: {
      return {
        ...state,
        step: action.tutorial.step
      }
    }
    default:
      return state;
  }
}

export default homeTutorialReducer;