import { storageKeys } from './defaults';

export const handleGuestMode = (isGuest, path) => {
  if (isGuest) {
    localStorage.setItem(storageKeys.previousRoute, window.location.href);
    window.location.href = path;
  }
}

export const absoluteValue = (value) => {
  return Math.abs(Number(value));
}