export const gameNightStartTimes = [
  {
    hours: 4,
    minutes: 0, 
    seconds: 0
  },
  {
    hours: 12,
    minutes: 0, 
    seconds: 0
  },
  {
    hours: 20,
    minutes: 0, 
    seconds: 0
  }
];
export const gameNightEndTimes = [
  {
    hours: 5,
    minutes: 0, 
    seconds: 0
  },
  {
    hours: 13,
    minutes: 0, 
    seconds: 0
  },
  {
    hours: 21,
    minutes: 0, 
    seconds: 0
  }
];
export const discordInviteLink = 'https://discord.gg/STWfEV2f6C';