import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getMessaging, isSupported } from 'firebase/messaging';
import { getRemoteConfig } from 'firebase/remote-config';
import { firebaseConfig, isDev } from '../constants';

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);

export const remoteConfig = getRemoteConfig(app);
const minimumFetchIntervalMillis = isDev ? 60000 : 180000;
remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    return null;
  } catch (e) {
    return null;
  }
})();