import { connect } from 'react-redux';
import { getUserCommunities } from '../actions';
import {
  userCommunityListSelector,
  sessionExpiredSelector,
  pageContentsRenderedSelector,
  guestSelector
} from '../selectors';
import { Communities } from '../components';

const mapStateToProps = (state) => ({
  communities: userCommunityListSelector(state),
  sessionExpired: sessionExpiredSelector(state),
  pageContentsRendered: pageContentsRenderedSelector(state),
  isGuest: guestSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getUserCommunities: () => dispatch(getUserCommunities())
});

const CommunitiesContainer = connect(mapStateToProps, mapDispatchToProps)(Communities);

export default CommunitiesContainer;