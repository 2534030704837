import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { FooterItem } from './FooterItem';
import { CustomFooterItem } from './CustomFooterItem';
import { 
  disableFooterPointerEventSelector, 
  guestSelector 
} from '../../selectors';
import { storageKeys } from '../../constants';
import './Footer.scss';

export const Footer = ({ menuItems, selectRoute }) => {
  const location = useLocation();

  const [hovered, hoverMenuItem] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');

  const isGuest = useSelector((state) => guestSelector(state));
  const disableFooter = useSelector((state) => disableFooterPointerEventSelector(state));

  const sessionToken = localStorage.getItem(storageKeys.sessionToken);

  const selectedRouteEndpoint = selectedRoute.split('/').slice(1)[0];
  const menuItemRoutes = menuItems.map(m => m.route.split('/').slice(1)[0]);

  useEffect(() => {
    setSelectedRoute(location.pathname);
    return () => {
      hoverMenuItem('');
    }
  }, [location])

  return (
    <div 
      className={classNames(
        "footer",
        {"footer-disable": disableFooter}
      )}
    >
      <div className="footer-menu">
        {menuItems.map(m => {
          const routeMap = m.route.split('/').slice(1);
          const loggedIn = sessionToken && sessionToken !== '' && !isGuest;

          let selected = routeMap[0] === selectedRouteEndpoint ||
            (routeMap[0] === '' && !menuItemRoutes?.includes(selectedRouteEndpoint));
          if (selectedRouteEndpoint?.includes('guild')) {
            selected = routeMap[0] === 'community';
          }
          if (selectedRouteEndpoint?.includes('spend') ||
            selectedRouteEndpoint?.includes('topup') ||
            selectedRouteEndpoint?.includes('rewards-center')) {
            selected = routeMap[0] === 'wallet';
          }
          if (selectedRouteEndpoint?.includes('marketplace')) {
            selected = routeMap[0] === 'launchpad';
          }
          if (selectedRouteEndpoint?.includes('referral')) {
            selected = routeMap[0] === 'gamepass';
          }
          
          if (m.name === 'Game Pass') {
            return (
              <CustomFooterItem
                key={`FooterMenu_${m.name}`}
                route={m.route}
              />
            );
          } else {
            return (
              <FooterItem
                key={`FooterMenu_${m.name}`}
                name={m.name}
                route={m.route}
                selected={selected}
                selectRoute={selectRoute}
                hovered={hovered}
                hoverMenuItem={hoverMenuItem}
                loggedIn={loggedIn}
                step={m.step}
              />
            );
          }
        })}
      </div>
    </div>
  );
}