import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  bannerDimensions,
  marketplaceText,
  maxWidths,
  siteRoutes
} from '../../constants';
import marketplaceBannerDesktop from '../../assets/marketplace/home/desktop.jpg';
import marketplaceBannerMobile from '../../assets/marketplace/home/mobile.jpg';
import './Home.scss';

export const HomeMarketplace = () => {
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  let banner = marketplaceBannerDesktop;
  if (isMobile) banner = marketplaceBannerMobile;
  
  let marketplaceBannerDimensions = bannerDimensions.homePageMarketplaceBanner.desktop;
  if (isMobile) marketplaceBannerDimensions = bannerDimensions.homePageMarketplaceBanner.mobile;

  return (
    <Link to={siteRoutes.launchpad} className="home-marketplace">
      <img
        src={banner}
        alt={marketplaceText}
        className={classNames(
          "home-menu-banner",
          "home-menu-banner-image"
        )}
        width={marketplaceBannerDimensions.width}
        height={marketplaceBannerDimensions.height}
      />
    </Link>
  );
}