import { connect } from 'react-redux';
import {
  getFeaturedPromotion,
  getHomeCollection,
  getGamePassFlag,
  setGamePassFlag,
  getGamePassTournaments,
  getReferrerDetails,
  getFeaturedRewards,
  redeemReward,
  activateGamePass,
  setDisplayToast,
  setGamePassPlayGame,
  showGamePassActivated,
  getUserProfile,
  getModalNotifications,
  getHomePageBanners,
  getDailyRewards,
  getFirstTimeCompletionRewards
} from '../actions';
import { homeMenuItems } from '../constants';
import {
  homeCollectionSelector,
  sessionExpiredSelector,
  pageContentsRenderedSelector,
  userProfileErrorStatusSelector,
  redeemFeaturedSelector,
  spinAvailableSelector,
  guestSelector,
  walletCryptoSelector,
  isGamePassTermsAndConditionsShownSelector,
  referrerDetailsSelector,
  rewardsFeaturedSelector,
  rewardIsClaimedSelector,
  userProfileToastSelector,
  showGamePassActivatedSelector,
  modalNotificationsSelector,
  homePageFeaturedBannerSelector,
  dailyLoginRewardsClaimedSelector,
  modalNotificationsIsLoadingSelector,
  firstTimeCompletionRewardsSelector,
  remoteConfigKeysSelector,
  authStepSelector
} from '../selectors';
import { Home } from '../components';
import { withMediaQuery } from '../utils/withMediaQuery';

const mapStateToProps = (state) => ({
  featuredBanner: homePageFeaturedBannerSelector(state),
  toast: userProfileToastSelector(state),
  sections: homeCollectionSelector(state),
  sessionExpired: sessionExpiredSelector(state),
  pageContentsRendered: pageContentsRenderedSelector(state),
  userProfileErrorStatus: userProfileErrorStatusSelector(state),
  redeem: redeemFeaturedSelector(state),
  featured: rewardsFeaturedSelector(state),
  notifications: modalNotificationsSelector(state),
  isClaimed: rewardIsClaimedSelector(state),
  spinAvailable:  spinAvailableSelector(state),
  isGuest: guestSelector(state),
  crypto: walletCryptoSelector(state),
  isGamePassTermsPopupShown: isGamePassTermsAndConditionsShownSelector(state),
  referrerDetails: referrerDetailsSelector(state),
  showActivated: showGamePassActivatedSelector(state),
  isDailyLoginRewardsClaimed: dailyLoginRewardsClaimedSelector(state),
  notificationsIsLoading: modalNotificationsIsLoadingSelector(state),
  firstTimeCompletionRewards: firstTimeCompletionRewardsSelector(state),
  remoteConfigKey: remoteConfigKeysSelector(state), 
  authStep: authStepSelector(state),
  menuItems: homeMenuItems
});

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: () => dispatch(getUserProfile()),
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast)),
  getHomeCollection: () => dispatch(getHomeCollection()),
  getGamePassTournaments: () => dispatch(getGamePassTournaments()),
  getFeaturedRewards: () => dispatch(getFeaturedRewards()),
  getModalNotifications: (payload) => dispatch(getModalNotifications(payload)),
  redeemReward: (rewardId) => dispatch(redeemReward(rewardId)),
  getFeaturedPromotion: () => dispatch(getFeaturedPromotion()),
  activateGamePass: (itemData) => dispatch(activateGamePass(itemData)),
  getGamePassFlag: (flagName) => dispatch(getGamePassFlag(flagName)),
  setGamePassFlag: (flagName, isShown) => dispatch(setGamePassFlag(flagName, isShown)),
  getReferrerDetails: (referrer) => dispatch(getReferrerDetails(referrer)),
  setGamePassPlayGame: (openPopup) => dispatch(setGamePassPlayGame(openPopup)),
  showGamePassActivated: (showActivated) => dispatch(showGamePassActivated(showActivated)),
  getFirstTimeCompletionRewards: (payload) => dispatch(getFirstTimeCompletionRewards(payload)),
  getHomePageBanners: () => dispatch(getHomePageBanners()),
  getDailyRewards: () => dispatch(getDailyRewards())
});

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(withMediaQuery(Home));

export default HomeContainer;