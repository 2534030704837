import React, { Component } from 'react';
import classNames from 'classnames';
import './Tabs.scss';

export class Tabs extends Component {
  constructor(props) {
    super(props);

    const tabs = Object.keys(props.contents);

    this.state = {
      tabs,
      selectedTab: tabs[0]
    };
  }

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  }

  render() {
    const { contents, leftAligned } = this.props;
    const { tabs, selectedTab } = this.state;

    return (
      <div>
        <div className={classNames("tabs", {"tabs-left": leftAligned})}>
          {tabs.map(t => (
            <button key={`Tab_${t}`} onClick={() => this.selectTab(t)} className={classNames("tab", {
              "tab-active": selectedTab === t
            })}>{t}</button>
          ))}
        </div>
        <div className="tab-content">{contents[selectedTab]}</div>
      </div>
    );
  }
}