import numeral from 'numeral';
import { mgcCurrency } from '../constants';

export const handleGamePassAmount = (price) => {
  let amount = `${price.value} ${price.currency}`;
  if (price.currency !== mgcCurrency) {
    const convertedValue = numeral(price.value).format('0.00');
    amount = `${price.currency} ${convertedValue}`;
  }
  return amount;
}