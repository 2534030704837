import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Banner } from '../Commons';
import { Featured, LayoutContent, PageTitle, SectionUnlinkedList, Stats } from '../Components';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { firebaseEvents, storageKeys } from '../../constants';

export class Guild extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guildId: window.location.pathname.split('/').slice(1)[1]
    };
  }

  componentDidMount() {
    this.props.getGuild(this.state.guildId);
  }

  componentDidUpdate(prevProps) {
    const { guild } = this.props;
    if (prevProps.guild !== guild) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.guildView, {
          guild_name: guild.name
        });
    }
  }

  render() {
    const { guild, sessionExpired, isGuest } = this.props;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const loggedIn = sessionToken && sessionToken !== '';
    const loggedOut = !loggedIn || (loggedIn && sessionExpired);

    if (guild && !isEmpty(guild)) {
      const experience = guild.experience.toLowerCase();
      const guildApplicationLink = `/${experience}/${guild.id}/apply`;
      const buttonText = loggedOut ? "Login to Apply" : "Apply as Scholar";
      const buttonLink = guildApplicationLink;

      return (
        <LayoutContent>
          <PageTitle title={guild.name} />
          <Featured fluid>
            <Banner
              title={guild.name}
              text={guild.short_desc}
              imageUrl={guild.image_url}
              buttonText={buttonText}
              buttonLink={buttonLink}
              buttonAnchor
              imageClickable
              isGuest={isGuest}
            >
              <Stats stats={guild.stats} lg={4} fluid />
            </Banner>
          </Featured>
          {guild.games && <SectionUnlinkedList title="GAMES" list={guild.games} />}
        </LayoutContent>
      );
    }

    return null;
  }
}