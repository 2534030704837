import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { LayoutContent, Section } from '../Components';
import { Snackbars, Toast } from '../Commons';
import { 
  addGamePassToCart,
  gamePassAddToCart, 
  getGamePassCartCount, 
  getGamePassCartItems, 
  getGamePassSkuDetails, 
  setDisplayToast, 
  setGamePassIsAddedToCart 
} from '../../actions';
import {
  defaultTimeoutMS,
  gamePassAddedToCartText,
  gamePassCartCTA,
  gamePassCheckoutCTA,
  mgcCurrency
} from '../../constants';
import { 
  addedToCartSelector, 
  cartCountSelector, 
  displayLoginToastSelector, 
  gamePassSkuDetailsSelector 
} from '../../selectors';
import { useQuery } from '../../hooks/useQuery';
import { handleGamePassAmount } from '../../utils/handleAmount';
import './GamePassDetails.scss';
 
export const GamePassDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  const selectedProduct = useSelector(state => gamePassSkuDetailsSelector(state));
  const isAdded = useSelector(state => addedToCartSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const count = useSelector(state => cartCountSelector(state));

  const [quantity, setQuantity] = useState(1);
  const [toastOpen, setToastOpen] = useState(false);

  const productId = query.get('productId');
  const skuId = query.get('skuId');

  useEffect(() => {
    if (productId && skuId) {
      dispatch(getGamePassSkuDetails({ productId, skuId }));
      dispatch(getGamePassCartItems());
      dispatch(getGamePassCartCount());
    }
  }, [dispatch, productId, skuId])

  const handleAddToCart = () => {
    const cartData = {
      productId,
      skuId,
      quantity: quantity
    };
    dispatch(gamePassAddToCart(cartData));
  }

  useEffect(() => {
    return () => {
      dispatch(setGamePassIsAddedToCart(false));
    }
  }, [dispatch])

  const handleBuyNow = () => {
    const addToCartData = {
      productId: selectedProduct.productId,
      skuId: selectedProduct.id,
      quantity: quantity,
      isCheckout: true,
      redirectLink: gamePassCheckoutCTA,
      requestCheckout: true,
      navigate
    };
    dispatch(addGamePassToCart(addToCartData));
  }

  const handleAddQuantity = () => {
    quantity < 100 ? setQuantity(quantity + 1) : setQuantity(quantity);
  }

  const handleSubtractQuantity = () => {
    quantity > 1 ? setQuantity(quantity - 1) : setQuantity(quantity);
  }

  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const handleCartRedirect = () => {
    navigate(gamePassCartCTA);
  }

  const handleDefaultAmount = (defaultPrice) => {
    let amount = '';
    if (defaultPrice && !isEmpty(defaultPrice)) {
      amount = handleGamePassAmount(defaultPrice);
    }
    return amount;
  }

  const handleConvertedAmount = (alternativePrice) => {
    let convertedAmount = '';
    if (alternativePrice && !isEmpty(alternativePrice)) {
      convertedAmount = handleGamePassAmount(alternativePrice);
    }
    return convertedAmount;
  }

  return (
    <LayoutContent outerClassName="gamepass-details-outer" innerClassName="gamepass-details-inner">
      {!isEmpty(selectedProduct) && 
        <Section
          sectionClassName="gamepass-details-section"
          backButtonRoute
          backRoute={() => navigate(-1)}
          text="Back"
          title={selectedProduct.shortName}
          titleCentered
          showCart
          handleRedirect={handleCartRedirect}
          cartCount={count}
        >
          <div className="gamepass-details-image-container">
            {!isEmpty(selectedProduct.imageUrls) &&
              <img
                src={selectedProduct.imageUrls.bannerUrl}
                alt={selectedProduct.shortName}
                className="gamepass-details-image"
              />}
          </div>
          <div className="gamepass-details-contents">
            <div className="gamepass-details-container">
              <div className="gamepass-details-container-inner">
                <div className="gamepass-details-text-container">
                  <h1 className="gamepass-details-title">{selectedProduct.shortName}</h1>
                  <div className="gamepass-details-text-mgc-prices">
                    <span className="gamepass-details-text-mgc-price">
                      {selectedProduct.defaultPrice?.currency === mgcCurrency &&
                        <div className="gamepass-details-text-mgc-icon" />}
                      <span>{handleDefaultAmount(selectedProduct.defaultPrice)}</span>
                    </span>
                    {!isEmpty(selectedProduct.alternativePrice) &&
                      <span className="gamepass-details-text-currency-price">
                        (
                          {selectedProduct.alternativePrice?.currency === mgcCurrency &&
                            <div className="gamepass-details-text-mgc-icon" />}
                          <span>{handleConvertedAmount(selectedProduct.alternativePrice)}</span>
                        )
                      </span>}
                  </div>
                </div>
                <div className="gamepass-details-quantity-container">
                  <button
                    className="gamepass-details-quantity-icon"
                    onClick={handleSubtractQuantity}
                  >
                    <RemoveCircleOutline className="activate-gamepass-toast-quantity-icon" />
                  </button>
                  <div className="gamepass-details-quantity-input">{quantity}</div>
                  <button
                    className="gamepass-details-quantity-icon"
                    onClick={handleAddQuantity}
                  >
                    <AddCircleOutline className="activate-gamepass-toast-quantity-icon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="gamepass-details-description-container">
              <h1 className="gamepass-details-title">Description</h1>
              <div className="gamepass-details-description"> 
                <div>{selectedProduct.longDescription && selectedProduct.longDescription.title}</div>
                {
                  !isEmpty(selectedProduct.longDescription) && 
                  !isEmpty(selectedProduct.longDescription.sections) && 
                  selectedProduct.longDescription.sections.map((s, sIdx) => (
                    <div key={`DescriptionKey_${sIdx}`} className="mt-2">{s}</div>
                  ))
                }
              </div>
              <div className="gamepass-details-buttons">
                <button
                  className="gamepass-details-buy-button"
                  onClick={handleBuyNow}
                >
                  Buy Now
                </button>
                <button
                  className="gamepass-details-add-to-cart-button"
                  onClick={() => handleAddToCart()}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
          <Snackbars
            flag={isAdded}
            title={gamePassAddedToCartText}
            hide={() => dispatch(setGamePassIsAddedToCart(false))}
            handleCartRedirect={handleCartRedirect}
          />
        </Section>}
      {toast && <Toast toast={toast} open={toastOpen} hide={closeToast} />}     
    </LayoutContent>
  );
}
