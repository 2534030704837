import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ErrorToast from '../ErrorToast/ErrorToast';
import { 
  connectFromTopUp,
  setLoading, 
  setWalletSignMessage, 
} from '../../../actions';
import { 
  walletConnectFAQs, 
  walletConnectTexts, 
  wallets 
} from '../../../constants';
import { setWalletAddressFormat } from '../../../utils/setWalletAddressFormat';
import { useWalletConnect } from '../../../hooks/useWalletConnect';

export const WalletConnectContent = ({ 
  walletConnectIcon, 
  metaMaskIcon,
  hideHeader,
  hideFaqs,
  hideDisconnect,
  showWalletName,
  hideErrorToast,
  className
}) => {
  const dispatch = useDispatch();
  const {
    address,
    isConnected,
    connector,
    open,
    isSigning,
    disconnectWallet,
    isVerifying,
    shouldDisconnect
  } = useWalletConnect();

  const handleDisconnect = (e) => {
    e.stopPropagation();
    dispatch(setLoading(true));
    disconnectWallet();
  }
  
  const handleOpen = async() =>  {
    if (isConnected) return;
    dispatch(connectFromTopUp(''));
    dispatch(setWalletSignMessage(''));
    dispatch(setLoading(true));
    await open();
    dispatch(setLoading(false));
  }

  const isSuccessfullySigned = isConnected && !isSigning && !isVerifying && !shouldDisconnect;
  const title = isSuccessfullySigned ? setWalletAddressFormat(address) : walletConnectTexts.connectViaWalletConnect;
  let icon = walletConnectIcon;
  if (isSuccessfullySigned && connector?.name === wallets.metaMask.name) {
    icon = metaMaskIcon;
  }

  return (
    <>
      {!hideHeader &&
        <div className="wallet-connect-title">{walletConnectTexts.title}</div>}
      <div onClick={handleOpen} className={classNames("wallet-connect", className)}>
        <div className="wallet-connect-details">
          <img src={icon} alt="wallet-icon" className="wallet-connect-icon" />
          <div className={classNames(
            "wallet-connect-text-wrapper", 
            {"wallet-connect-text": isSuccessfullySigned}
          )}>
            <div className="d-flex flex-column gap-1">
              {showWalletName && 
                isConnected && 
                <div className="wallet-connect-name">{connector?.name}</div>}
              <div>{title}</div>
            </div>
            {!isSuccessfullySigned && 
              <div className="wallet-connect-chains">{`(${walletConnectTexts.validChains})`}</div>}
          </div>
        </div>
        {!hideDisconnect &&
         <>
          {isSuccessfullySigned
            ? <button onClick={handleDisconnect} className="wallet-connect-disconnect">{walletConnectTexts.disconnect}</button>
            : <KeyboardArrowRightRoundedIcon className="wallet-connect-arrow" />}
         </>}
      </div>
      {!hideFaqs && 
        <a 
          className="wallet-connect-faqs" 
          href={walletConnectFAQs.link} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {walletConnectFAQs.title}
        </a>}
      {!hideErrorToast && <ErrorToast />}
    </>
  );
};
