import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { TutorialScreen } from './TutorialScreen';
import './Tutorial.scss';

export const TutorialSwipeable = ({ screens, selectedIndex, selectIndex }) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => selectedIndex === screens.length - 1 ? null : selectIndex(selectedIndex + 1),
    onSwipedRight: () => selectedIndex === 0 ? null : selectIndex(selectedIndex - 1)
  });

  return (
    <div className="tutorials" {...handlers}>
      {screens.map((s, idx) => {
        const translate = `${-100 * selectedIndex}%`;
        return <TutorialScreen key={`TutorialScreen_${idx}_${s.title}`} {...s} translate={translate} />;
      })}
    </div>
  );
}