import React, { Fragment } from 'react';
import classNames from 'classnames';
import { GoldBalance } from '../Commons';
import { rewardCenterSideTabs } from '../../constants';

export const SideTabs = ({ isDesktop, selectedSideTab, setSelectedSideTab }) => (
  <Fragment>
    {!isDesktop && 
      <GoldBalance />}
    <div className="rewards-center-buy-container">
      {rewardCenterSideTabs.map((r, rIdx) => (
        <>
          <div 
            key={`Tabs_${rIdx}`} 
            onClick={() => setSelectedSideTab(r.kind)}
            className={classNames(
              "rewards-center-buy-button-wrapper", {
                "rewards-center-buy-button-wrapper-active": selectedSideTab === r.kind && isDesktop,
                "rewards-center-buy-button-wrapper-inactive": selectedSideTab !== r.kind && isDesktop
              }
            )}
          >
            <div className={classNames(
              "rewards-center-buy-button",
              {"rewards-center-buy-button-active": selectedSideTab === r.kind}
            )}>
              <img src={r.icon} alt="gift" className="rewards-center-buy-button-icon" />
              <div className="rewards-center-buy-button-title">{r.title}</div>
            </div>
          </div>
          {!isDesktop && 
            rIdx === 0 &&
            <div className="rewards-center-buy-separator" />}
        </>
      ))}
      {isDesktop && 
        <GoldBalance />}
    </div>
  </Fragment>
);
