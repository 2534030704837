import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Section } from '../Components';
import { HorizontalScroll } from '../Commons';
import { maxWidths, minWidths } from '../../constants';
import '../Components/Section/Section.scss';

export const CommunitiesGrid = ({ title, list, link, linkText }) => {
  const sm = useMediaQuery({ minWidth: minWidths.mobileSM, maxWidth: maxWidths.mobileSM });
  const md = useMediaQuery({ minWidth: minWidths.tablet, maxWidth: maxWidths.tablet });
  const lg = useMediaQuery({ minWidth: minWidths.desktopSM, maxWidth: maxWidths.desktopLG });
  const xl = useMediaQuery({ minWidth: minWidths.desktopXL, maxWidth: maxWidths.desktopXXL });
  const xxxl = useMediaQuery({ minWidth: minWidths.desktopXXXL });

  let slidesToShow = 2;
  if (sm) slidesToShow = 3;
  else if (md) slidesToShow = 4;
  else if (lg || xl || xxxl) slidesToShow = list.length > 4 ? 4 : list.length;

  return (
    <Section title={title} link={link} linkText={linkText}>
      <div className="guilds-list">
        <HorizontalScroll slidesToShow={slidesToShow} className="guilds-list-scroll">
          {list.map(l => {
            const experience = `${l.experience.toLowerCase()}s`;
            const linkUrl = `/${experience}/${l.id}`;
            return (
              <div key={`${title}_${l.name}`} className="guilds-list-item">
                <Link to={l.name.includes('Coming Soon') ? '/community' : linkUrl} className="guilds-list-item-link">
                  <div className="guild-thumbnail" style={{ backgroundImage: `url(${l.image_url})` }}>
                    <div className="guild-thumbnail-name">{l.name}</div>
                  </div>
                </Link>
              </div>
            );
          })}
        </HorizontalScroll>
      </div>
    </Section>
  );
}