import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import { Steps } from '../Commons';
import ChatThreadMessageMenu from './ChatThreadMessageMenu';
import ChatThreadOptions from './ChatThreadOptions';
import {
  claimScholarship,
  setTopUpVisible
} from '../../actions';
import {
  firebaseEvents,
  homeMenuItemRoutes,
  minWidths
} from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './ChatThread.scss';

const ChatThreadMessage = ({
  messageId,
  componentId,
  self,
  imageAttachmentUrl,
  qrCodeImageUrl,
  pfpUrl,
  applicationStatus,
  options,
  message,
  consecutivePrevSelf,
  consecutivePrevSender,
  consecutiveNextSelf,
  consecutiveNextSender,
  confirmDeleteMessage,
  submitMessage,
  action,
  body
}) => {
  const [hovered, onHover] = useState(false);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const handleClaim = (action) => {
    if (!action.path) return;
    dispatch(claimScholarship(action.path));
  }

  const handleClick = (uri) => {
    if (uri.includes(homeMenuItemRoutes.gamePass)) {
      logFirebaseEventWithTimestamp(firebaseEvents.wenLamboMessagesJoin);
    }
  }

  const handleImageClick = (uri) => {
    window.location.href = uri;
  }

  return (
    <div
      className={classNames("chat-thread-message", {
        "chat-thread-message-self": self,
        "chat-thread-message-consecutive": consecutivePrevSelf || consecutivePrevSender
      })}
      id={componentId}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      {!self &&
        pfpUrl &&
        <div
          className="chat-thread-message-profile-pic"
          style={{ backgroundImage: `url(${pfpUrl})` }}
        />}
      {self &&
        hovered &&
        <ChatThreadMessageMenu confirmDeleteMessage={() => confirmDeleteMessage(messageId)} />}
      <div className={classNames("chat-thread-message-text", {
        "chat-thread-message-text-sender": !self,
        "chat-thread-message-text-self": self,
        "chat-thread-message-text-sender-before": !consecutivePrevSender && consecutiveNextSender,
        "chat-thread-message-text-sender-between": consecutivePrevSender && consecutiveNextSender,
        "chat-thread-message-text-sender-after": consecutivePrevSender && !consecutiveNextSender,
        "chat-thread-message-text-self-before": !consecutivePrevSelf && consecutiveNextSelf,
        "chat-thread-message-text-self-between": consecutivePrevSelf && consecutiveNextSelf,
        "chat-thread-message-text-self-after": consecutivePrevSelf && !consecutiveNextSelf
      })}>
        {isEmpty(body) && <>
          {imageAttachmentUrl &&
            <img
              src={imageAttachmentUrl}
              alt="event"
              className="chat-thread-message-event mb-2"
            />}
          <div
            className={classNames(
              "ps-3 pe-3 py-3",
              {"chat-thread-message-text-spacing": applicationStatus || options || qrCodeImageUrl}
            )}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {options &&
            <ChatThreadOptions
              options={options}
              onOptionClick={submitMessage}
            />}
          {applicationStatus &&
            <Steps
              currentStep={applicationStatus.currentStatus}
              steps={applicationStatus.steps}
            />}
          {qrCodeImageUrl &&
            <img
              src={qrCodeImageUrl}
              alt="QRCode"
              className="chat-thread-message-qr-code ps-3 pe-3 pb-2"
            />}
          {action &&
            <div className="ps-3 pe-3 pb-3">
              {action.path ?
                <div
                  className={classNames(
                    "chat-thread-message-path",
                    {"chat-thread-message-path-disabled": !action.isEnabled}
                  )}
                  onClick={() => handleClaim(action)}
                >
                  {action.ctaText}
                </div> :
                <a
                  href={action.uri}
                  onClick={() => handleClick(action.uri)}
                >
                  {action.ctaText}
                </a>}
            </div>}
        </>}
        {!isEmpty(body) && body.map((b, bIdx) => {
          const newCtaText = b.value.ctaText && kebabCase(b.value.ctaText.toLowerCase());
          const isTopUp = newCtaText && newCtaText.includes('top-up-mgc');
          return (
            <div key={`MessageBody_${bIdx}`}>
              {b.value &&
                b.kind === "text" &&
                <div
                  className={classNames(
                    "ps-3 pe-3 py-3",
                    {"chat-thread-message-text-spacing": applicationStatus || options || qrCodeImageUrl}
                  )}
                  dangerouslySetInnerHTML={{ __html: b.value }}
                />}
              {b.value.ctaImageUrl &&
                <img
                  src={b.value.ctaImageUrl}
                  alt="nft"
                  className="chat-thread-message-nft ps-3 pe-3"
                  onClick={() => handleImageClick(b.value.uri)}
                />}
              {b.kind === "image" &&
                <img
                  src={b.value}
                  alt="nft"
                  className="chat-thread-message-image ps-3 pe-3 pb-3"
                />}
              {b.value.ctaText &&
                <div className="ps-3 pe-3 pb-3">
                  {(isTopUp && isDesktop) ?
                    <Link
                      to="/wallet"
                      onClick={() => dispatch(setTopUpVisible(true))}
                    >
                      {b.value.ctaText}
                    </Link> :
                    <a href={b.value.uri}>{b.value.ctaText}</a>}
                </div>}
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default ChatThreadMessage;