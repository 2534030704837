import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { 
  useLocation, 
  useNavigate, 
  useSearchParams 
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { 
  ErrorToast, 
  LayoutContent, 
  Section 
} from '../Components';
import { OrderStatus } from './OrderStatus';
import { PaymentStatusContents } from './PaymentStatusContents';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { 
  firebaseEvents, 
  paymentStatus, 
  primaryType, 
  siteRoutes, 
  storageKeys, 
  transactionParams
} from '../../constants';
import { gamePassInventorySelector, transactionDataSelector } from '../../selectors';
import { getUserTransactionData, showGamePassActivated } from '../../actions';
import { useQuery } from '../../hooks/useQuery';
import './PaymentStatus.scss';

export const PaymentStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();

  const [isGamePass, setIsGamePass] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const details = useSelector(state => transactionDataSelector(state));
  const inventory = useSelector(state => gamePassInventorySelector(state));

  const status = location.pathname.split('/')[3];
  const referenceId = query.get(transactionParams.referenceId);
  const id = query.get(transactionParams.id);
  const newStatus = status.toLowerCase();
  const isFromReferral = Cookies.get(storageKeys.isFromReferral);

  useEffect(() => {
    dispatch(getUserTransactionData({ referenceId, id }));
  },[dispatch, referenceId, id])

  useEffect(() => {
    if (!isEmpty(details?.details?.pendingItems)) setIsGamePass(true);
  }, [details])

  useEffect(() => {
    if (details?.status === paymentStatus.completed && 
      details.primaryType === primaryType.topup) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.walletTopupSuccess, {
          amount: details.details?.fromAmount?.value,
          currency: details.details?.fromAmount?.currency
        });
    }
  }, [details])

  const handlePINBack = () => {
    if (searchParams.has(transactionParams.authStep)) {
      dispatch(showGamePassActivated({ result: false }));
      searchParams.delete(transactionParams.authStep)
      setSearchParams(searchParams);
    }
  }
  
  const handleReferralNavigate = () => {
    navigate(siteRoutes.referral, { state: { fillNumbers: true }, replace: true });
    Cookies.remove(storageKeys.isFromReferral);
  }

  const isTopUpTransaction = !isGamePass ||
    newStatus === paymentStatus.cancel &&
    !isEmpty(details);

  return (
    <LayoutContent 
      outerClassName={classNames(
        "payment-status-content",
        {"payment-status-content-topup": isTopUpTransaction}
      )}
      innerClassName="payment-status-content-inner"
    >
      <Section 
        sectionClassName="payment-status-content-section"
        sectionHeaderClassName="payment-status-content-section-header"
        backButtonRoute={Boolean(isFromReferral) && !isEmpty(details)}
        backRoute={handleReferralNavigate}
      >
        {isTopUpTransaction ?
          <PaymentStatusContents
            details={details}
            newStatus={newStatus}
            navigate={navigate}
          /> : 
          <OrderStatus 
            details={details} 
            inventory={inventory} 
          />}
      </Section>
      <ErrorToast />
    </LayoutContent> 
  );
}