import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import classNames from 'classnames';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { LayoutContent, PageTitle, Section } from '../Components';
import { monthNames } from '../../constants/defaults';
import { 
  getUserTransactionDetails, 
  setInstructionsVisible, 
  setSelectedMethod, 
  setTransactionVisible
} from '../../actions';
import { useQuery } from '../../hooks/useQuery';
import { 
  absoluteValue,
  direction,
  goldCurrency,
  minWidths,
  paymentStatus,
  phpCurrency,
  primaryType,
  siteRoutes,
  transactionCategory
} from '../../constants';
import './TransactionDetails.scss';

export const TransactionDetails = ({ isModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const [searchParams, setSearchParams] = useSearchParams();
  const [expirationDate, setExpirationDate] = useState('');

  const details = useSelector((state) => state.transactions.transactionDetails);
  const selectedMethod = useSelector((state) => state.transactions.selectedMethod);

  const id = location.pathname.split('/')[3];
  const desktopId = query.get('id');
  const d = new Date(details.timestamp);
  
  useEffect(() => {
    if (isEmpty(details)) return;
    if (details.expiresAt) return setExpirationDate(new Date(details.expiresAt));
  }, [details])

  const handleMediaQueryChange = (matches) => {
    if (matches) {
      navigate(`${siteRoutes.wallet}?id=${id}`);
      dispatch(setTransactionVisible(true));
    }
  }

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet }, undefined, handleMediaQueryChange);
  
  useEffect(() => {
    if (!isDesktop && id) dispatch(getUserTransactionDetails({ id }));
    if (isEmpty(desktopId)) return;
  },[dispatch, id, isDesktop, desktopId, selectedMethod])

  const getDateFormatExpires = () => {
    const updatedDateFormat = expirationDate && `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}`;
    return updatedDateFormat;
  }

  const getTimeFormat = () => {
    const updatedTimeFormat = new Date(details.timestamp).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
    return updatedTimeFormat;
  }

  const getTimeFormatExpires = () => {
    const updatedTimeFormat = new Date(details.expiresAt).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
    return updatedTimeFormat;
  }

  const getDateFormatText = () => {
    const month = monthNames[d.getMonth()];
    const updatedDateFormat = `${month.slice(0, 3)} ${d.getDate()}, ${d.getFullYear()}`;
    return updatedDateFormat;
  }

  const handleBackRoute = () => {
    if (!isDesktop) {
      const route = isModal ? siteRoutes.wallet : -1;
      navigate(route);
    } else {
      searchParams.delete('id');
      searchParams.delete('channel');
      setSearchParams(searchParams, { replace: true });
    }
    dispatch(setTransactionVisible(false));
  }

  const handleViewInstruction = () => {
    dispatch(setTransactionVisible(false));
    if (isDesktop) {
      searchParams.set('channel', details.details?.paymentChannel.code);
      setSearchParams(searchParams, { replace: true });
      dispatch(setInstructionsVisible(true))
    } else {
      navigate({
        pathname: `/payment/instructions?channel=${details.details?.paymentChannel.code}`, 
        state: location.pathname
      });
    }
  }

  const symbol = details.direction === direction.incoming ? "+" : "-";

  return (
    <Fragment>
      {!isEmpty(details) && <LayoutContent>
        <PageTitle title="Transaction Details"/>
        <Section 
          title={capitalize(details.primaryType)} 
          sectionClassName="transactions-details"
          sectionHeaderClassName="transactions-details-section"
          backButtonRoute
          backRoute={handleBackRoute}
          titleCentered 
          close={isDesktop}
        >
          {isEmpty(details.details?.pendingItems) ? <div>
            <div className="transactions-details-header">
              <div className="d-flex justify-content-center align-items-center gap-1">
                <div className={classNames(
                  "transactions-details-header-symbol", {
                    "transactions-details-header-symbol-failed": 
                    details.status === paymentStatus.failed || 
                    details.status === paymentStatus.pending
                })}>
                  {symbol}
                </div>
                {details.amount?.currency !== phpCurrency && 
                  <div className={classNames(
                    "transactions-details-mgc", {
                    "transactions-details-gold": details.amount?.currency === goldCurrency
                  })}/>}
                <div className={classNames(
                  "transactions-details-header-amount", {
                  "transactions-details-header-amount-failed": 
                  details.status === paymentStatus.failed || 
                  details.status === paymentStatus.pending
                })}>
                  {details.amount?.currency !== phpCurrency ? 
                    absoluteValue(details.amount?.value) 
                    : `${absoluteValue(details.amount?.value)} ${details.amount?.currency}`}
                </div>
              </div>
              <div className={classNames(
                "transactions-details-header-status", {
                "transactions-details-header-status-failed": details.status === paymentStatus.failed,
                "transactions-details-header-status-success": details.status === paymentStatus.success,
                "transactions-details-header-status-pending": details.status === paymentStatus.pending
              })}>
                {details.status}
              </div>
              {details.status === paymentStatus.failed && <div className="transactions-details-header-status-reason">
                {details.failedReason}
              </div>}
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="transactions-details-header-date">Transaction time {getDateFormatText()} {getTimeFormat()}</div>
              </div>
            </div>
            {!isEmpty(details.details) && <>
              <div className="transactions-details-body">
                {details.details.source && <Row className="w-100">
                  <Col className="transactions-details-body-title">Source</Col>
                  <Col className="transactions-details-body-subtitle">{capitalize(details.details.source)}</Col>
                </Row>}
                {details.details.gameName && <Row className="w-100">
                  <Col className="transactions-details-body-title">Game</Col>
                  <Col className="transactions-details-body-subtitle">{details.details.gameName}</Col>
                </Row>}
                {details.details.paymentMethod && <Row className="w-100">
                  <Col className="transactions-details-body-title">Payment Method</Col>
                  <Col className="transactions-details-body-subtitle">{capitalize(kebabCase(details.details.paymentMethod))}</Col>
                </Row>}
                {details.details.campaignName && <Row className="w-100">
                  <Col className="transactions-details-body-title">Campaign</Col>
                  <Col className="transactions-details-body-subtitle">{startCase(details.details.campaignName)}</Col>
                </Row>}
                {details.details.category && 
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Category</Col>
                    <Col className="transactions-details-body-subtitle">{startCase(details.details.category)}</Col>
                  </Row>}
                {details.details.subCategory && 
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Subcategory</Col>
                    <Col className="transactions-details-body-subtitle">{startCase(details.details.subCategory)}</Col>
                  </Row>}
                {details.details.collection &&
                <Row className="w-100">
                  <Col className="transactions-details-body-title">Collection</Col>
                  <Col className="transactions-details-body-subtitle">{details.details.collection}</Col>
                </Row>}
                {details.details.itemName &&
                <Row className="w-100">
                  <Col className="transactions-details-body-title">Item</Col>
                  <Col className="transactions-details-body-subtitle">
                    {details.details.itemName}
                  </Col>
                </Row>}
                {details.details.buyerUsername &&
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Sold to</Col>
                    <Col className="transactions-details-body-subtitle">{details.details.buyerUsername}</Col>
                  </Row>}
                {details.details.seller && 
                  details.details.seller.id !== 'withdrawal' && 
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Purchased From</Col>
                    <Col className="transactions-details-body-subtitle">{details.details.seller.userId}</Col>
                  </Row>}
                {details.details.accountNumber && <Row className="w-100">
                  <Col className="transactions-details-body-title">Account Number</Col>
                  <Col className="transactions-details-body-subtitle">{details.details.accountNumber}</Col>
                </Row>}
                {details.details.paymentChannel && <Row className="w-100">
                  <Col className="transactions-details-body-title">Payment Channel</Col>
                  <Col className="transactions-details-body-subtitle">{details.details.paymentChannel.name}</Col>
                </Row>}
                {details.details.fromAmount && 
                  details.primaryType !== 'sale' &&
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Amount</Col>
                    <Col className="transactions-details-body-subtitle">{details.details.fromAmount.text}</Col>
                  </Row>}
              </div>
              {details.primaryType === 'topup' && 
                details.barCode && 
                <div className="transactions-details-info">
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Payment Code</Col>
                    <Col className="transactions-details-body-subtitle">{details.barCode}</Col>
                  </Row>
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Expiration Date</Col>
                    <Col className="transactions-details-body-subtitle">{getDateFormatExpires()} | {getTimeFormatExpires()}</Col>
                  </Row>
                  <Row className="w-100">
                    <Col className="transactions-details-body-title">Payment Instructions</Col>
                    <Col className="transactions-details-body-subtitle">
                      <div className="transactions-details-body-button">
                        <div onClick={handleViewInstruction} className="transactions-details-body-subtitle">View Here</div>
                        <ArrowForwardIosRoundedIcon className="transactions-details-body-arrow"/>
                      </div>
                    </Col>
                  </Row>
                </div>}
              </>}
            <div className="transactions-details-footer">
              {details?.details?.category === transactionCategory.cryptoToken &&
                <Row className="w-100">
                  <Col xs={5} sm={5} md={6} lg={6} className="transactions-details-body-title">Transaction Hash</Col>
                  <Col xs={7} sm={7} md={6} lg={6} className="transactions-details-body-subtitle">{details?.referenceId}</Col>
                </Row>}
              <Row className="w-100">
                <Col xs={5} sm={5} md={6} lg={6} className="transactions-details-body-title">Transaction ID</Col>
                <Col xs={7} sm={7} md={6} lg={6} className="transactions-details-body-subtitle">{details.id}</Col>
              </Row>
            </div>
        </div> : 
        <div className="transactions-details-products">
          <div className="transactions-details-products-body">
            <div className="transactions-details-products-header">
              <div className="transactions-details-products-transaction">{getDateFormatText()} {getTimeFormat()}</div>
              <div className={classNames(
                "transactions-details-header-status", 
                "transactions-details-products-status", {
                "transactions-details-header-status-failed": details.status === paymentStatus.failed,
                "transactions-details-header-status-success": details.status === paymentStatus.success,
                "transactions-details-header-status-pending": details.status === paymentStatus.pending
              })}>
                {details.status}
              </div>
            </div>
            {!isEmpty(details.details.pendingItems) && 
              <div className="transactions-details-products-container">
                {details.details.pendingItems?.map((i, iIdx) => (
                  <div key={`Products_${iIdx}_${i.productId}`} className="transactions-details-products-items">
                    <img className="transactions-details-products-image" src={i.imageUrl} alt={i.skuKind} />
                    <div className="d-flex flex-column gap-1 w-100">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                            <div className="transactions-details-products-name">{i.name}</div>
                            <div className="transactions-details-products-quantity">{`Qty: ${i.quantity}`}</div>
                          </div>
                          <div className="transactions-details-products-amount">{i.subTotal?.text}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>}
            <div className="transactions-details-products-wrapper">
              <div className="transactions-details-products-total">Total</div>
              <div className="transactions-details-products-total">{details.amount?.value} {details.amount?.currency}</div>
            </div>
          </div>
          <div className="transactions-details-products-footer">
            <div className="transactions-details-products-wrapper-footer">
              <div className="d-flex justify-content-between w-100">
                <div className="transactions-details-products-title">Payment Method</div>
                <div className="transactions-details-products-title-value">{details.origin}</div>
              </div>
              <Row className="w-100">
                <Col xs={5} sm={5} md={6} lg={6} className="transactions-details-products-title">Transaction ID</Col>
                <Col xs={7} sm={7} md={6} lg={6} className="transactions-details-products-title-value">{details.id}</Col>
              </Row>
            </div>
          </div>
        </div>}
        </Section>
      </LayoutContent>}
    </Fragment>
  )
}
