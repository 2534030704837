import {
  SET_GAMEPASS_PRODUCTS,
  SET_GAMEPASS_SCREEN,
  SET_GAMEPASS_SELECTED_PRODUCT,
  SET_BUY_GAMEPASS,
  SET_ACTIVATE_GAMEPASS,
  SET_GAMEPASS_INVENTORY,
  SET_GAMEPASS_TOURNAMENTS,
  SET_GAMEPASS_TOURNAMENTS_DETAILS,
  SET_GAMEPASS_TOURNAMENT_TOKEN,
  SHOW_GAMEPASS_POPUP,
  SET_GAMEPASS_PRODUCT_DETAILS,
  SET_GAMEPASS_TRANSACTION_IS_FOUND,
  SET_GAMEPASS_TRANSACTION_IS_IN_TIMELIMIT,
  SET_GAMEPASS_SKU_DETAILS,
  SET_GAMEPASS_PLAY_GAME,
  SET_GAMEPASS_ACTIVE_ENTITLEMENTS,
  SET_DISPLAY_TOAST,
  SET_JOIN_BETA_RESPONSE_SUCCESS,
  SET_JOIN_BETA_RESPONSE_ERROR,
  SET_BETA_APPLICATION_FORM,
  SET_BETA_APPLICATION_RESULT,
  SET_GAMEPASS_BETA_USER,
  SHOW_GAMEPASS_ACTIVATED,
  SET_GAMEPASS_ACTIVATING,
  SET_OVERRIDE_ACTIVE_BOOSTER,
  ACTIVATE_GAMEPASS_AND_BOOSTER,
  RESET_STATE,
  SET_CLAIM_FREE_GAMEPASS,
  SET_USER_HAS_GAMEPASS,
  SET_USER_HAS_ONLY_GAMEPASS,
  SET_USER_HAS_GOLD_BOOSTER,
  DISABLE_GOLD_BOOSTER_SELECTION,
  SET_FREE_GAMEPASS_TIMER,
  SET_GAME_OVER_URL
} from '../actions/constants';

const gamePassState = {
  products: [],
  productDetails: {},
  skuDetails: {},
  sections: [],
  selectedProduct: {},
  buyDetails: {},
  inventory: [],
  activateDetails: {},
  tournaments: [],
  tournamentsDetails: {},
  tournament: {
    token: '',
    result: false
  },
  isGamePassTermsAndConditionsShown: false,
  isWenLamboTutorialShown: false,
  isFound: false,
  isInTimeLimit: false,
  openPopup: false,
  entitlements: [],
  toast: undefined,
  betaResponse: {
    success: '',
    error: ''
  },
  betaApplicationForm: {},
  betaApplicationResult: undefined,
  betaUser: false,
  showActivated: {
    result: false,
    kind: ''
  },
  isGamePassActivating: false,
  overrideActiveBooster: {
    result: false,
    description: '',
    message: '',
    data: {}
  },
  isGamePassAndBoosterActivated: false,
  showClaimFreeGamePass: false,
  hasGamePass: false,
  hasOnlyGamePass: false,
  hasGoldBooster: false,
  isDisabled: false,
  freeTimer: 0,
  gameOverUrl: ''
};

const gamePassReducer = (state = gamePassState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return gamePassState;
    case SET_GAMEPASS_PRODUCTS:
      return {
        ...state,
        products: action.products
      };
    case SET_GAMEPASS_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.productDetails
      };
    case SET_GAMEPASS_SKU_DETAILS:
      return {
        ...state,
        skuDetails: action.skuDetails
      };
    case SET_GAMEPASS_SCREEN:
      return {
        ...state,
        sections: action.gamePassScreenSections
      };
    case SET_GAMEPASS_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.selectedProduct
      };
    case SET_BUY_GAMEPASS:
      return {
        ...state,
        buyDetails: action.buyDetails
      };
    case SET_GAMEPASS_INVENTORY:
      return {
        ...state,
        inventory: action.inventory
      };
    case SET_ACTIVATE_GAMEPASS:
      return {
        ...state,
        activateDetails: action.activateDetails
      };
    case SET_GAMEPASS_TOURNAMENTS:
      return {
        ...state,
        tournaments: action.tournaments
      };
    case SET_GAMEPASS_TOURNAMENTS_DETAILS:
      return {
        ...state,
        tournamentsDetails: action.tournamentsDetails
      };
    case SET_GAMEPASS_TOURNAMENT_TOKEN:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          ...action.tournament
        }
      };
    case SHOW_GAMEPASS_POPUP:
      return {
        ...state,
        [action.flagName]: action.isShown
      };
    case SET_GAMEPASS_TRANSACTION_IS_FOUND:
      return {
        ...state,
        isFound: action.isFound
      };
    case SET_GAMEPASS_TRANSACTION_IS_IN_TIMELIMIT:
      return {
        ...state,
        isInTimeLimit: action.isInTimeLimit
      };
    case SET_GAMEPASS_PLAY_GAME:
      return {
        ...state,
        openPopup: action.openPopup
      };
    case SET_GAMEPASS_ACTIVE_ENTITLEMENTS:
      return {
        ...state,
        entitlements: action.entitlements
      };
    case SET_GAMEPASS_BETA_USER:
      return {
        ...state,
        betaUser: action.betaUser
      };
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case SET_JOIN_BETA_RESPONSE_SUCCESS:
      return {
        ...state,
        betaResponse: {
          ...state.betaResponse,
          success: action.betaResponseSuccess
        }
      };
    case SET_JOIN_BETA_RESPONSE_ERROR:
      return {
        ...state,
        betaResponse: {
          ...state.betaResponse,
          error: action.betaResponseError
        }
      };
    case SET_BETA_APPLICATION_FORM:
      return {
        ...state,
        betaApplicationForm: action.applicationForm
      };
    case SET_BETA_APPLICATION_RESULT:
      return {
        ...state,
        betaApplicationResult: action.applicationResult
      };
    case SHOW_GAMEPASS_ACTIVATED:
      return {
        ...state,
        showActivated: action.showActivated
      };
    case SET_GAMEPASS_ACTIVATING:
      return {
        ...state,
        isGamePassActivating: action.isGamePassActivating
      };
    case SET_OVERRIDE_ACTIVE_BOOSTER:
      return {
        ...state,
        overrideActiveBooster: {
          ...state.overrideActiveBooster,
          ...action.overrideActiveBooster
        }
      };
    case ACTIVATE_GAMEPASS_AND_BOOSTER:
      return {
        ...state,
        isGamePassAndBoosterActivated: action.isGamePassAndBoosterActivated
      };
    case SET_CLAIM_FREE_GAMEPASS:
      return {
        ...state,
        showClaimFreeGamePass: action.showClaimFreeGamePass
      };
    case SET_USER_HAS_GAMEPASS:
      return {
        ...state,
        hasGamePass: action.hasGamePass
      };
    case SET_USER_HAS_ONLY_GAMEPASS:
      return {
        ...state,
        hasOnlyGamePass: action.hasOnlyGamePass
      };
    case SET_USER_HAS_GOLD_BOOSTER:
      return {
        ...state,
        hasGoldBooster: action.hasGoldBooster
      };
    case DISABLE_GOLD_BOOSTER_SELECTION:
      return {
        ...state,
        isDisabled: action.isDisabled
      };
    case SET_FREE_GAMEPASS_TIMER:
      return {
        ...state,
        freeTimer: action.freeTimer
      };
    case SET_GAME_OVER_URL:
      return {
        ...state,
        gameOverUrl: action.gameOverUrl
      };
    default:
      return state;
  }
}

export default gamePassReducer;