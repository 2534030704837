import { useEffect, useState } from 'react';
import { defaultTimeoutMS } from '../constants';

export const useOtpTimer = (props) => {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } 
    }, defaultTimeoutMS * 2)
    return () => {
      clearInterval(myInterval);
    };
  });
  return {minutes, seconds};
}
