import React from 'react';
import { Modal } from 'react-bootstrap';
import './Confirm.scss';

export const Confirm = ({ open, title, text, confirm, confirmText, hide, hideText }) => (
  <Modal
    backdrop
    show={open}
    className="confirm-container"
    contentClassName="confirm"
    dialogClassName="confirm-dialog"
    backdropClassName="confirm-bg"
    onHide={hide}
  >
    <Modal.Header>{title}</Modal.Header>
    <Modal.Body>{text}</Modal.Body>
    <Modal.Footer>
      <button onClick={hide} className="confirm-cancel-button">{hideText}</button>
      <button onClick={confirm} className="confirm-accept-button">{confirmText}</button>
    </Modal.Footer>
  </Modal>
);