import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { LayoutContent, PageTitle, Section } from '../Components';
import { Share, Toast } from '../Commons';
import { ReferralHistory } from './ReferralHistory';
import { ReferralInvite } from './ReferralInvite';
import { ReferralFreeSpin } from './ReferralFreeSpin';
import {
  getReferralHistory,
  setDisplayToast,
  setLoading,
  inviteFriend,
  getReferralLink,
  getUserProfile
} from '../../actions';
import {
  bannerDimensions,
  cryptoFreeSpinName,
  defaultMobileNumberPrefix,
  defaultTimeoutMS,
  firebaseEvents,
  maxWidths,
  referralMechanics,
  referralSharePlatforms,
  regexConstants
} from '../../constants';
import {
  referralLinkSelector,
  referralShareMessageSelector,
  referralHistorySelector,
  referralToastSelector,
  guestSelector,
  walletCryptoSelector
} from '../../selectors';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import desktopBanner from '../../assets/referral/banners/desktop.png';
import mobileBanner from '../../assets/referral/banners/mobile.png';
import referralShare from '../../assets/referral/referral-share.png';
import './ReferralScreen.scss';

export const ReferralScreen = () => {
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS })
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const referralLink = useSelector(state => referralLinkSelector(state));
  const referralShareMessage = useSelector(state => referralShareMessageSelector(state));
  const referralHistory = useSelector(state => referralHistorySelector(state));
  const toast = useSelector(state => referralToastSelector(state));
  const isGuest = useSelector(state => guestSelector(state));
  const crypto = useSelector(state => walletCryptoSelector(state));

  const [shareOpen, openShare] = useState(false);
  const [toastOpen, openToast] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState(defaultMobileNumberPrefix);
  const [emptyMobileNumberError, setEmptyMobileNumberError] = useState(false);
  const [freeSpinCount, setFreeSpinCount] = useState(0);

  useEffect(() => {
    if (!isGuest) {
      dispatch(getReferralLink());
      dispatch(getReferralHistory());
      dispatch(getUserProfile({ version: 'v2' }));
    }
  }, [dispatch, isGuest]);

  useEffect(() => {
    if (!isEmpty(crypto)) {
      let count = 0;
      const filterredCrypto = crypto?.find(i => i?.name === cryptoFreeSpinName);
      if (!isEmpty(filterredCrypto)) {
        count = Number(filterredCrypto?.val);
      }
      setFreeSpinCount(count);
    }
  }, [crypto])

  useEffect(() => {
    logFirebaseEventWithTimestamp(firebaseEvents.wenLamboRefer);
  }, []);

  useEffect(() => {
    openToast(true);
  }, [toast]);

  const closeToast = () => {
    openToast(false);
    setMobileNumber('');
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }
  
  const handleShare = () => {
    if (navigator.share && isMobile) {
      navigator.share({
        title: decodeURIComponent(referralShareMessage),
        text: decodeURIComponent(referralShareMessage),
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      openShare(true);
    }
    logFirebaseEventWithTimestamp(firebaseEvents.wenLamboReferShare);
  }

  const handleMobileNumberInput = (mobnum) => {
    const mobileNumberWithoutCountryCode = mobnum?.replace(countryCode, '');
    const mobileNumberValue = mobileNumberWithoutCountryCode?.replace(regexConstants.nonDigit, '');
    setMobileNumber(mobileNumberValue);
    if (emptyMobileNumberError) {
      setEmptyMobileNumberError(false);
    }
  }

  const handleInvite = () => {
    if (mobileNumber !== '') {
      const completeMobileNumber = `${countryCode}${mobileNumber}`;
      dispatch(setLoading(true));
      dispatch(inviteFriend(completeMobileNumber, referralShareMessage));
    } else {
      setEmptyMobileNumberError(true);
    }
    logFirebaseEventWithTimestamp(firebaseEvents.wenLamboReferInvite);
  }

  let banner = desktopBanner;
  let dimensions = bannerDimensions.referralPageBanner.desktop;
  if (isMobile) {
    banner = mobileBanner;
    dimensions = bannerDimensions.referralPageBanner.mobile;
  }
    
  return (
    <LayoutContent outerClassName="referral-screen">
      <PageTitle title="Refer A Friend" />
      <Section 
        sectionClassName="referral-screen-section" 
        text="Back" 
        title="Refer a Friend"
        titleCentered
        backButtonRoute 
        backRoute={() => navigate(-1)}
      >
        <img 
          src={banner} 
          className={classNames(
            {"referral-screen-banner-desktop": !isMobile},
            {"referral-screen-banner-mobile": isMobile}
          )}
          alt="Refer A Friend"
          width={dimensions.width}
          height={dimensions.height}
        />
        <div className="referral-screen-form">
          <ReferralFreeSpin count={freeSpinCount} />
          <ReferralInvite
            link={referralLink}
            handleShare={handleShare}
            handleInvite={handleInvite}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            mobileNumber={mobileNumber}
            handleMobileNumberInput={handleMobileNumberInput}
            emptyMobileNumberError={emptyMobileNumberError}
            isGuest={isGuest}
            freeSpinCount={freeSpinCount}
          />
        </div>
        <div className="referral-screen-view-full-mechanics">
          <Link to={referralMechanics.cta.redirectUri} className="referral-screen-view-full-mechanics-link">
            {referralMechanics.cta.text}
          </Link>
        </div>
        {referralHistory &&
          !isEmpty(referralHistory) &&
          <Section title={referralHistory.dataset} sectionClassName="referral-screen-history">
            <ReferralHistory list={referralHistory.d} />
          </Section>}
      </Section>
      <Share
        image={referralShare}
        title={referralShareMessage}
        subtitle={referralLink.replace('https://', '')}
        url={referralLink}
        shareIsVisible={shareOpen}
        exitShare={() => openShare(false)}
        platforms={referralSharePlatforms}
      />
      {toast &&
        <Toast
          toast={toast}
          open={toastOpen}
          hide={closeToast}
        />}
    </LayoutContent>
  );
}