import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { 
  erc20ABI, 
  useContractWrite, 
  useNetwork 
} from 'wagmi';
import { ModalContent } from './ModalContent';
import { 
  checkoutButtonTexts,
  currencies,
  defaultTimeoutMS,
  errorMessage,
  paymentMethods,
  siteRoutes,
  usdtABI,
  wallets
} from '../../../constants';
import { 
  disableCheckoutButton,
  getGamePassCheckout,
  getGamePassCheckoutSelectedCurrency,
  payGamePassOnCheckout, 
  setDisplayToast, 
  setGamePassCheckoutSelectedPaymentMethod, 
  setLoading, 
  showGamePassCheckout 
} from '../../../actions';
import { 
  checkoutSelectCurrencySelector,
  checkoutSelectedPaymentMethodSelector,
  disableCheckoutButtonSelector,
  loadingSelector, 
  showPaymentMethodsSelector, 
  userWalletConnectedSelector
} from '../../../selectors';
import { useWalletConnect } from '../../../hooks/useWalletConnect';
import './GamePassCheckoutModal.scss';

export const GameOverCheckoutContents = ({ 
  handleSelectPaymentMethod,
  checkoutContents
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chain } = useNetwork();
  const { address, isConnected, connector } = useWalletConnect();

  const [selectedCurrency, setSelectedCurrency] = useState({});

  const selectedPaymentMethod = useSelector(state => checkoutSelectedPaymentMethodSelector(state));
  const showPaymentMethods = useSelector(state => showPaymentMethodsSelector(state));
  const loading = useSelector(state => loadingSelector(state));
  const currency = useSelector(state => checkoutSelectCurrencySelector(state));
  const isDisabled = useSelector(state => disableCheckoutButtonSelector(state));
  const userWalletConnected = useSelector(state => userWalletConnectedSelector(state));

  const prevWalletConnectedRef = useRef(userWalletConnected);

  useEffect(() => {
    if (prevWalletConnectedRef.current && !userWalletConnected) {
      dispatch(getGamePassCheckout());
    }
    prevWalletConnectedRef.current = userWalletConnected;
  }, [dispatch, userWalletConnected]);

  useEffect(() => {
    if (!isEmpty(currency)) {
      for (const item of currency) {
        if (item?.isSelected) {
          setSelectedCurrency(item);
        }
      }
    }
  }, [currency])

  useEffect(() => {
    if (isEmpty(checkoutContents)) return;
    for (const paymentMethod of checkoutContents?.acceptedPaymentMethods) {
      if (checkoutContents?.selectedPaymentMethod === paymentMethod.code) {
        dispatch(setGamePassCheckoutSelectedPaymentMethod(paymentMethod));
      }
    }
  }, [dispatch, checkoutContents])

  useEffect(() => {
    if (selectedPaymentMethod.code === wallets.walletConnect.name) {
      if (isConnected) {
        dispatch(getGamePassCheckoutSelectedCurrency({ chainId: chain?.id }));
      }
    }
  }, [dispatch, isConnected, selectedPaymentMethod, chain])

  const { data, write, isLoading } = useContractWrite({ 
    address: selectedCurrency.address,
    abi: selectedCurrency.kind === currencies.USDT ? usdtABI : erc20ABI,
    functionName: 'transfer',
    args: [checkoutContents?.selectedPaymentMethodRecipient,
      checkoutContents?.summary?.total?.wei
    ],
    onError(error) {
      const splittedErrorMessage = error?.message?.split('\n');
      const firstErrorMessage = splittedErrorMessage[0]?.trim();
      if (firstErrorMessage?.includes(errorMessage.userRejectedRequest)) {
        return;
      }
      let message = firstErrorMessage;
      const usdtInsufficientBalance = selectedCurrency.kind === currencies.USDT && 
        error?.message?.includes(errorMessage.rpcRequestFailed);
      if (error?.message?.includes(errorMessage.transferAmountExceedsBalance) ||
        usdtInsufficientBalance) {
        message = errorMessage.insufficientBalance;
      }
      dispatch(setDisplayToast({ result: false, message }));
      dispatch(disableCheckoutButton(true));
    }
  });

  useEffect(() => {
    dispatch(setLoading(isLoading));
    return () => {
      dispatch(setLoading(false));
    }
  }, [dispatch, isLoading])

  useEffect(() => {
    if (data?.hash) {
      dispatch(payGamePassOnCheckout({ transactionHash: data.hash }));
    }
  }, [dispatch, data])

  const handleCheckoutPay = () => {
    if (!loading) {
      const { selectedPaymentMethod, summary } = checkoutContents;
      if (selectedPaymentMethod === paymentMethods.ccdc) {
        dispatch(showGamePassCheckout(false));
        setTimeout(() => {
          navigate(`${siteRoutes.payment}/card?channel=${selectedPaymentMethod}&amount=${summary?.total?.value}&currency=${summary?.total?.currency}`);
        }, defaultTimeoutMS)
        return;
      }

      if (selectedPaymentMethod === wallets.walletConnect.name) {
        write?.();
      } else {
        dispatch(payGamePassOnCheckout());
      }
    }
  }

  return (
    <ModalContent
      checkoutContents={checkoutContents}
      showPaymentMethods={showPaymentMethods}
      selectedPaymentMethod={selectedPaymentMethod}
      handleSelectPaymentMethod={handleSelectPaymentMethod}
      buttonClassName={classNames(
        "game-pass-checkout-button",
        "game-pass-checkout-button-game-over",
        {"game-pass-checkout-button-disabled": isDisabled}
      )}
      buttonText={`${checkoutButtonTexts.pay} ${checkoutContents?.summary?.subTotal?.text}`}
      handleCheckoutPay={handleCheckoutPay}
      isGameOverCheckout
      address={address}
      connector={connector}
      currency={currency}
    />
  );
}