import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import './Properties.scss';

const Properties = ({ properties, xs = 6, sm = 6, md = 4, lg = 3, xl = 3, fluid = false, noTopSpace = false }) => (
  <Row className={classNames("properties", {"properties-fluid": fluid, "properties-no-top-space": noTopSpace})}>
    {properties.map(s => (
      <Col key={`Property_${s.label}`} xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="property">
        <div className="property-inner">
          <div className="property-label">{s.label}</div>
          <div className="property-value">{s.value}</div>
        </div>
      </Col>
    ))}
  </Row>
);

export default Properties;