import { connect } from 'react-redux';
import {
  setDisplayToast,
  setLoading,
  submitPIN,
  recoverPIN,
  startPINOTPTimer,
  submitPINOTP,
  setPINAuthStep,
  submitNewPIN,
  setPINValidationError,
  resendEmailOTP,
  setShowLoading
} from '../actions';
import {
  pinAuthStepSelector,
  pinOTPTimerActiveSelector,
  pinValidationErrorSelector,
  displayLoginToastSelector
} from '../selectors';
import { pinTexts, pinFormInputs, storageKeys } from '../constants';
import { PIN } from '../components';

const mapStateToProps = (state) => ({
  toast: displayLoginToastSelector(state),
  username: localStorage.getItem(storageKeys.username) || '',
  authStep: pinAuthStepSelector(state),
  timerActive: pinOTPTimerActiveSelector(state),
  hasValidationError: pinValidationErrorSelector(state),
  pinTexts,
  pinFormInputs
});

const mapDispatchToProps = (dispatch) => ({
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast)),
  setLoading: (loading) => dispatch(setLoading(loading)),
  submitPIN: (pin) => dispatch(submitPIN(pin)),
  recoverPIN: (payload) => dispatch(recoverPIN(payload)),
  startOTPTimer: (timerActive) => dispatch(startPINOTPTimer(timerActive)),
  submitPINOTP: (params) => dispatch(submitPINOTP(params)),
  submitNewPIN: (params) => dispatch(submitNewPIN(params)),
  setAuthStep: (step) => dispatch(setPINAuthStep(step)),
  setPINValidationError: (hasValidationError) => dispatch(setPINValidationError(hasValidationError)),
  resendEmailOTP: (payload) => dispatch(resendEmailOTP(payload)),
  setShowLoading: (showLoading) => dispatch(setShowLoading(showLoading))
});

const PINContainer = connect(mapStateToProps, mapDispatchToProps)(PIN);

export default PINContainer;