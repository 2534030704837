import React, { Component } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, update, child, get } from 'firebase/database';
import { database } from '../../utils/firebase';
import { LayoutContent, PageTitle, Section } from '../Components';
import ChatMessage from './ChatMessage';
import { messagesEmpty } from '../../constants';
import './Chat.scss';

export class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: ''
    };
  }

  componentDidMount() {
    this.getUserId();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userId !== this.state.userId && this.state.userId !== '') {
      this.getChatMessages(this.state.userId);
    } else {
      this.props.setLoading(false);
    }
  }

  getUserId = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) this.setState({ userId: user.uid });
    });
  }

  getChatMessages = (userId) => {
    if (userId && userId !== '') {
      get(child(ref(database), `chatReference/${userId}`)).then((snapshot) => {
        const messages = {
          title: 'Messages',
          threads: []
        };
        if (snapshot.exists()) {
          const data = snapshot.val();
          if (data) {
            const messageThreads = Object.keys(data).map(k => data[k]);
            const orderedThreads = orderBy(messageThreads, ['timestamp'], ['desc']);
            messages.threads = orderedThreads;
          }
        }
        this.props.setChatMessages(messages);
      }).catch((error) => {
        console.error(error);
        this.props.setLoading(false);
      });
    }
  }

  markAsRead = (messageId) => {
    const latestMessageRef = ref(database, `chatReference/${this.state.userId}/${messageId}`);
    update(latestMessageRef, { read: true });
    this.getChatMessages(this.state.userId);
  }

  goToMessage = (e) => {
    e.stopPropagation();
    this.props.setLoading(true);
  }

  render() {
    const { messages } = this.props;
    
    if (messages) {
      const title = !isEmpty(messages) ? messages.title : "Messages";
      return (
        <LayoutContent>
          <PageTitle title={title} />
          <Section title={title} sectionClassName="chat-messages-section" titleCentered>
            {messages.threads ? (
              isEmpty(messages.threads) ? <div className={classNames("chat-messages", "chat-messages-empty")}>{messagesEmpty}</div>
              : <div className="chat-messages">
                {messages.threads.map((t, tIdx) => <ChatMessage key={`ChatMessage_${tIdx}`} {...t} goToMessage={this.goToMessage} markAsRead={this.markAsRead} />)}
              </div>
            ) : null}
          </Section>
        </LayoutContent>
      );
    }

    return null;
  }
}