export const mgcData = '2,5,10,20';
export const phpData = '100,200,500,1000';
export const mgcDefaultCredits = '0.00';
export const goldDefaultCredits = '0';
export const phpCurrency = 'PHP';
export const mgcCurrency = 'MGC';
export const usdCurrency = 'USD';
export const goldCurrency = 'VAU';
export const countryCode = 'PH';
export const crypto = 'CRYPTO';
export const topUpDirection = {
  to: 'to',
  from: 'from'
};
export const topUpChannelKinds = {
  eWallet: 'E_WALLET',
  overTheCounter: 'OVER_THE_COUNTER',
  card: 'CARD',
  carrierBilling: 'CARRIER_BILLING'
};
export const topUpChannels = {
  grabPay: 'PH_GRABPAY',
  gcash: 'PH_GCASH',
  maya: 'PH_PAYMAYA',
  shopeePay: 'PH_SHOPEEPAY',
  sevenEleven: '7ELEVEN',
  cebuanaLhuillier: 'CEBUANA',
  palawanExpress: 'DP_PALAWAN',
  mLhuillier: 'DP_MLHUILLIER',
  ccdc: 'CCDC',
  globe: 'CARRIER_GLOBE',
  walletConnect: 'WALLETCONNECT',
  externalWallet: 'EXTERNAL_WALLET'
};
export const instructionText = 'Please follow the payment instructions below and pay before the payment code expires. Your MGC balance will be updated if top-up is successful.'
export const paymentStatus = {
  failed: 'failed',
  success: 'success',
  cancel: 'cancel',
  completed: 'completed',
  pending: 'pending'
}
export const errorCodes = {
  unauthorized: 'NotAuthorized',
  forbidden: 'Forbidden'
};
export const walletPageRoutes = {
  wallet: '/wallet',
  topupPayment: '/topup/payment'
};
export const topUpText = 'Top Up';
export const paymentTexts = {
  payVia: 'Pay via',
  payWith: 'Pay with',
  topUpMGC: 'Top up MGC'
};