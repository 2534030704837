import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Coupon } from '../Commons';
import { utilizeClaimedReward } from '../../actions';
import {
  dateTimeFormats,
  rewardStatuses,
  rewardsCenterTexts,
  siteRoutes
} from '../../constants';
import './RewardsCenter.scss';

export const Reward = ({ id, name, status, subtitle, imageUrl, expiresAt, cta }) => {
  const dispatch = useDispatch();
  
  const handleClick = (rewardId, destinationUrl) => {
    dispatch(utilizeClaimedReward(rewardId, destinationUrl));
  }

  const currentDateTime = DateTime.now();
  const expiryDateTime = DateTime.fromISO(expiresAt);
  const fmtExpiryDateTime = expiryDateTime.toFormat(dateTimeFormats.rewardExpiryDate);
  const isBeforeExpiry = currentDateTime < expiryDateTime;

  let expiryStr = rewardsCenterTexts.expired;
  if (isBeforeExpiry) {
    expiryStr = `${rewardsCenterTexts.expiresAt} ${fmtExpiryDateTime}`;
  }

  const link = cta ? null : `${siteRoutes.rewardsCenter}/${id}`;

  return (
    <Coupon
      className="rewards-center-reward"
      link={link}
      imageUrl={imageUrl}
      statusIndicator={
        <div className={classNames(
          "rewards-center-reward-status",
          `rewards-center-reward-status-${status.toLowerCase()}`
        )}>
          {status}
        </div>
      }
    >
      <div className="rewards-center-reward-info-top">
        <div className="rewards-center-reward-info-title">
          {name}
        </div>
        {subtitle && <div className="rewards-center-reward-info-subtitle">
          {subtitle}
        </div>}
        <div className="rewards-center-reward-info-expiry-date">
          {expiryStr}
        </div>
      </div>
      {cta &&
        status === rewardStatuses.active &&
        <button className="rewards-center-reward-button" onClick={() => handleClick(id, cta.url)}>
          {cta.title}
        </button>}
      {link &&
        <div>
          <Link to={link} className="rewards-center-reward-info-view">
            <div>{rewardsCenterTexts.viewMechanicsAndTC}</div>
            <KeyboardArrowRightRoundedIcon
              className="rewards-center-reward-info-view-icon"
            />
          </Link>
        </div>}
    </Coupon>
  );
}