import { connect } from 'react-redux';
import { setLoading, validatePIN, setDisplayToast } from '../actions';
import { pinResetFormInputs } from '../constants';
import { currentPINSelector, userProfileToastSelector } from '../selectors';
import { ResetPIN } from '../components';

const mapStateToProps = (state) => ({
  currentPIN: currentPINSelector(state),
  toast: userProfileToastSelector(state),
  inputs: pinResetFormInputs
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  validatePIN: (currentPIN, newPIN) => dispatch(validatePIN(currentPIN, newPIN)),
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast))
});

const ResetPINContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPIN);

export default ResetPINContainer;