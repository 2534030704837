import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import MediaQuery from 'react-responsive';
import { NFTItemDetails } from './NFTItemDetails';
import {
  LayoutContent,
  OrderSummary,
  PageTitle,
  Properties,
  Section
} from '../Components';
import { Back } from '../Commons';
import {
  maxWidths,
  minWidths,
  storageKeys
} from '../../constants';
import './NFTItem.scss';

export class NFTItem extends Component {
  constructor(props) {
    super(props);

    const currentRoute = window.location.pathname.split('/').slice(1);

    this.state = {
      nftId: currentRoute[1],
      nftItemId: currentRoute[currentRoute.length - 1]
    };
  };

  componentDidMount() {
    this.props.getNFTItemDetails(this.state.nftId, this.state.nftItemId);
  }

  buy = () => {
    this.props.setLoading(true);
    this.props.buyNFTItem(this.state.nftItemId);
  }

  loginToBuy = () => {
    localStorage.setItem(storageKeys.previousRoute, window.location.pathname);
  }

  render() {
    const { nftItem, showOrderSummary, orderSummaryVisible, sessionExpired } = this.props;
    const { nftId } = this.state;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const loggedIn = sessionToken && sessionToken !== '';
    
    if (nftItem && !isEmpty(nftItem)) {
      const priceText = nftItem.price.split(' ');
      const orderSummary = [
        { label: priceText[priceText.length - 1], value: nftItem.price },
        { label: nftItem.experience, value: nftItem.name }
      ];

      return (
        <LayoutContent>
          <PageTitle title={nftItem.name} />
          <MediaQuery minWidth={minWidths.tablet}>
            <Back text="Back to NFTs" link={`/nfts/${nftId}`} />
          </MediaQuery>
          <MediaQuery maxWidth={maxWidths.mobileSM}>
            <Section backButtonRoute={`/nfts/${nftId}`} sectionClassName="nft-item-back-mobile" />
          </MediaQuery>
          <NFTItemDetails
            {...nftItem}
            loggedIn={loggedIn}
            sessionExpired={sessionExpired}
            imageUrl={nftItem.image_url}
            description={nftItem.short_desc}
            showOrderSummary={() => showOrderSummary(true)}
            loginToBuy={this.loginToBuy}
          />
          <Section title="Properties">
            <Properties properties={nftItem.stats} xs={12} fluid />
          </Section>
          <OrderSummary
            open={orderSummaryVisible}
            summary={orderSummary}
            accept={this.buy}
            hide={() => showOrderSummary(false)}
          />
        </LayoutContent>
      );
    }

    return null;
  }
}