import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import { PlayNowModal } from './PlayNowModal';
import { Popup } from '../../Commons';
import { getFlags, setWalkthroughHeightLimitFlag } from '../../../actions';
import { 
  duration, 
  flagKeys, 
  minWidths, 
  onBoardingData, 
  storageKeys 
} from '../../../constants';
import './WenLamboOnBoardingTutorial.scss';

const WenLamboOnBoardingTutorial = ({ gamesSection, inventory }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const [searchParams, setSearchParams] = useSearchParams();

  const [position, setPosition] = useState({
    top: 0,
    left: 0
  });
  const [selectedTutorial, setSelectedTutorial] = useState('');

  const tutorialFlag = searchParams.get(onBoardingData.onboard);
  const showModal = tutorialFlag === onBoardingData.flags.modal;
  const scrollToGames = tutorialFlag === onBoardingData.flags.games;
  const activateGamePass = tutorialFlag === onBoardingData.flags.activate;

  const scrollToTargetAdjusted = () => {
    var element = document.getElementById(selectedTutorial);
    var headerOffset = scrollToGames ? 200 : 350;
    var elementPosition = element?.getBoundingClientRect()?.top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    setPosition(element?.getBoundingClientRect());
  }
  
  const handleScrollElement = () => {
    if (isEmpty(selectedTutorial)) return;
    const elementSizeAndPosition = document.getElementById(selectedTutorial)?.getBoundingClientRect();
    setPosition(elementSizeAndPosition);
  }
  
  const handleSizeAndPosition = () => {
     if (isEmpty(selectedTutorial)) return;
    setTimeout(() => {
      scrollToTargetAdjusted();
    }, duration.pointThreeSeconds)
    setTimeout(() => {
      const elementSizeAndPosition = document.getElementById(selectedTutorial)?.getBoundingClientRect();
      setPosition(elementSizeAndPosition);
    }, duration.oneSecond)
  }

  const hideModal = () => {
    if (tutorialFlag) {
      const localStorageKeys = [
        storageKeys.isTutorial,
        storageKeys.previousRoute
      ];
      localStorageKeys.map(k => {
        if (localStorage.getItem(k)) localStorage.removeItem(k);
      });
      if (searchParams.has(onBoardingData.onboard)) {
        searchParams.delete(onBoardingData.onboard);
        setSearchParams(searchParams);
      }
      dispatch(getFlags({ key: flagKeys.isFreeGamePassModalShown }));
    }
  }

  useEffect(() => {
    if (isEmpty(selectedTutorial)) return;
    setPosition({ top: -1000, left: 0 });
    handleSizeAndPosition();
    dispatch(setWalkthroughHeightLimitFlag(true));
    return () => dispatch(setWalkthroughHeightLimitFlag(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTutorial])

  useEffect(() => {
    window.addEventListener('resize', handleSizeAndPosition);
    window.addEventListener('scroll', handleScrollElement);
    localStorage.setItem(storageKeys.previousRoute, window.location.href);
    return () => {
      window.removeEventListener('resize', handleSizeAndPosition);
      window.removeEventListener('scroll', handleScrollElement);
    }
  }, [selectedTutorial])

  useEffect(() => {
    const showQuery = searchParams.get(onBoardingData.onboard);
    let tutorial = '';
    if (showQuery === onBoardingData.flags.activate) {
      tutorial = onBoardingData.ids.gamePassTutorial;
    } else if (showQuery === onBoardingData.flags.games) {
      tutorial = onBoardingData.ids.playAndEarnGold;
    }
    setSelectedTutorial(tutorial);
  }, [searchParams])

  const gamesPosition = scrollToGames ? 30 : 10;
  const gamesPositionWidth = scrollToGames ? 60 : 20;
  const boxTitle = scrollToGames ? onBoardingData.titles.selectGame : onBoardingData.titles.tapActivate;

  useEffect(() => {
    if (tutorialFlag) {
      localStorage.setItem(storageKeys.isTutorial, true);
    }
  }, [tutorialFlag])

  return (
    <div>
      <div className="wenlambo-walkthrough-block" />
      {(activateGamePass || scrollToGames) &&
        <div 
          className="onboarding-tutorial"
          style={{ 
            top: position?.top, 
            left: position?.left - gamesPosition,
            height: `${position?.height + 30}px`, 
            width: `${position?.width + gamesPositionWidth}px`
          }}
        >
          <div 
            className={classNames(
              "onboarding-tutorial-box", {
              "onboarding-tutorial-box-desktop": isDesktop,
              "onboarding-tutorial-box-games": scrollToGames
            })}
          >
            <div className="position-relative">
              <div>{boxTitle}</div>
              <div className={classNames(
                "onboarding-tutorial-box-arrow",
                {"onboarding-tutorial-box-arrow-games": scrollToGames}
              )}/>
            </div>
          </div>
        </div>}
      <Popup 
        open={showModal}
        hide={hideModal}
        className="onboarding-tutorial-popup" 
        centered
        header
      >
        <PlayNowModal 
          games={gamesSection?.d} 
          isNewUser={location?.state?.isNewUser}
          inventory={inventory}
        />
      </Popup>
    </div>
  );
}

export default WenLamboOnBoardingTutorial;