import {
  SET_LOADING,
  RENDER_PAGE_CONTENTS,
  SHOW_ORDER_SUMMARY,
  TRY_LOGIN,
  DISPLAY_REGISTRATION_FORM,
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_OTP,
  START_LOGIN_OTP_TIMER,
  START_EMAIL_LOGIN_OTP_TIMER,
  SUBMIT_LOGIN_PIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_OTP_SUCCESS,
  LOGIN_OTP_FAILURE,
  SET_AUTH_STEP,
  SET_SESSION_EXPIRED,
  VALIDATE_PIN,
  DISPLAY_SET_PIN_FORM,
  SUBMIT_PIN,
  RECOVER_PIN,
  START_PIN_OTP_TIMER,
  SUBMIT_PIN_OTP,
  SUBMIT_NEW_PIN,
  SET_SUBMIT_NEW_PIN,
  SET_PIN_AUTH_STEP,
  SET_PIN_VALIDATION_ERROR,
  ENABLE_EMAIL_OTP,
  SET_EMAIL_OTP_TO_ENABLED,
  SET_DISPLAY_TOAST,
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  GET_USER_PROFILE_FIELDS,
  SET_USER_PROFILE_FIELDS,
  SET_USER_PROFILE_COUNTRY_CODE,
  EDIT_USER_PROFILE,
  UPDATE_USER_PROFILE_PHOTO,
  REMOVE_USER_PROFILE_PHOTO,
  SET_USER_PROFILE_ERROR_STATUS,
  GET_HOME_COLLECTION,
  SET_HOME_COLLECTION,
  GET_GAME_DETAILS,
  SET_GAME_DETAILS,
  GET_NFT_COLLECTION_DETAILS,
  SET_NFT_COLLECTION_DETAILS,
  GET_NFT_ITEM_DETAILS,
  SET_NFT_ITEM_DETAILS,
  BUY_NFT_ITEM,
  GET_LAUNCHPAD_COLLECTION,
  SET_LAUNCHPAD_COLLECTION,
  GET_USER_COLLECTIONS,
  SET_USER_COLLECTIONS,
  GET_USER_COLLECTION,
  SET_USER_COLLECTION,
  GET_USER_COMMUNITIES,
  SET_USER_COMMUNITIES,
  GET_USER_COMMUNITY,
  SET_USER_COMMUNITY,
  GET_GUILD_APPLICATION_FORM,
  SET_GUILD_APPLICATION_FORM,
  SUBMIT_GUILD_APPLICATION_FORM,
  GET_SPEND_MOBILE_LOAD_PRODUCTS,
  SET_SPEND_MOBILE_LOAD_PRODUCTS,
  CONFIRM_SPEND_MOBILE_LOAD_PRODUCT,
  SET_SPEND_MOBILE_LOAD_PRODUCT_CONFIRMATION,
  PAY_MOBILE_LOAD_PRODUCT,
  SET_UNREAD_MESSAGES,
  GET_CHAT_MESSAGES,
  SET_CHAT_MESSAGES,
  GET_CHAT_MESSAGE_THREAD,
  SET_CHAT_MESSAGE_THREAD,
  SUBMIT_CHAT_MESSAGE,
  GET_TUTORIAL_SCREENS,
  SET_TUTORIAL_SCREENS,
  GET_PROFILE_TUTORIAL,
  SET_PROFILE_TUTORIAL,
  GET_TRANSACTIONS,
  SET_TRANSACTIONS,
  GET_MORE_TRANSACTIONS,
  GET_QUESTS,
  SET_QUESTS,
  SET_QUEST_LEADERBOARDS,
  SET_QUEST_FAQS,
  RESEND_EMAIL_OTP,
  SET_HOME_TUTORIAL,
  SET_SPEND_PAYMENT_STEP,
  SET_SPEND_LOADING,
  SET_SPEND_ERROR,
  SET_SPEND_COUNTRY,
  GET_SPEND_COUNTRY,
  SUBMIT_SPEND_OTP,
  SEND_SPEND_OTP,
  SET_SPEND_OTP_SUCCESS,
  SET_SPEND_LOADING_TEXT,
  MINT_NFT_LAUNCHPAD_ITEM,
  MINTING_IS_REDEEM,
  GET_LAUNCHPAD_COLLECTION_ITEM,
  SET_LAUNCHPAD_COLLECTION_ITEM,
  GET_FEATURED_PROMOTION,
  GET_FEATURED_PROMOTION_SETTINGS,
  SET_REDEEM_DATA,
  GET_TICKET,
  SET_CLAIM_DATA,
  GET_NFT_DATA,
  SET_FEATURED_PROMOTION,
  SET_GUEST,
  GET_SPIN_PRIZE,
  SET_SPIN_PRIZE,
  GET_REDEEM,
  SET_REDEEM,
  GET_SPIN_AVAILABLE,
  SET_SPIN_AVAILABLE,
  GET_USER_TRANSACTIONS,
  SET_USER_TRANSACTIONS,
  GET_PAYMENT_METHODS,
  SET_PAYMENT_METHODS,
  CREATE_PAYMENT,
  SET_PAYMENT_CHANNELS,
  GET_PAYMENT_CHANNELS,
  SET_CREATE_PAYMENT,
  GET_USER_TRANSACTIONS_DETAILS,
  SET_USER_TRANSACTIONS_DETAILS,
  GET_CURRENCY_CONVERSION,
  SET_CURRENCY_CONVERSION,
  GET_CURRENCY_CONVERSION_BATCH,
  SET_CURRENCY_CONVERSION_BATCH,
  CLAIM_SCHOLARSHIP,
  SET_URL_PARAMS,
  SET_CURRENCY_LOADING,
  SET_TOPUP_VISIBLE,
  SET_INSTRUCTIONS_VISIBLE,
  SET_BARCODE_VISIBLE,
  SET_SELECTED_METHOD,
  SET_TRANSACTION_VISIBLE,
  GET_MARKETPLACE_COLLECTIONS,
  SET_MARKETPLACE_COLLECTIONS,
  GET_MARKETPLACE_NFT_DETAILS,
  SET_MARKETPLACE_NFT_DETAILS,
  GET_COLLECTION_DETAILS,
  SET_COLLECTION_DETAILS,
  SET_COLLECTION_PAGINATION,
  SET_COLLECTION_PAGINATION_LOADING,
  SET_LIST_NFT,
  LIST_NFT,
  BUY_NFT,
  SET_BUY_NFT,
  CANCEL_LIST_NFT,
  SET_CANCEL_LIST_NFT,
  SET_ERROR_MESSAGE,
  SET_ALL_LISTED_NFT,
  GET_ALL_LISTED_NFT,
  GET_TOURNAMENTS,
  SET_TOURNAMENTS,
  SET_OPEN_MODAL,
  SET_BUY_NAVIGATE,
  SET_MARKETPLACE_COLLECTION,
  SET_BUY_NFT_ID,
  GET_LISTING_FEE,
  SET_LISTING_FEE,
  SET_LISTING_FEE_LOADING,
  SET_ALL_LISTED_NFT_PAGINATION,
  SET_COLLECTION_NFT_PAGINATION,
  SET_LISTED_NFT_PAGINATION,
  SET_CANCEL_LIST_BACK,
  SET_SELECTED_SLUG,
  GET_SPIN_PRIZES,
  SET_SPIN_PRIZES,
  SET_PRIZE_SLUG,
  SET_PRIZE_SLUG_DATA,
  GET_PRIZE_SLUG,
  GET_REWARDS,
  SET_REWARDS,
  GET_REWARD_DETAILS,
  SET_REWARD_DETAILS,
  REDEEM_REWARD,
  GET_GAMEPASS_PRODUCTS,
  SET_GAMEPASS_PRODUCTS,
  GET_GAMEPASS_SCREEN,
  SET_GAMEPASS_SCREEN,
  SET_GAMEPASS_SELECTED_PRODUCT,
  SET_HIDDEN_HEADER,
  SET_HIDDEN_MENU_ICON,
  SET_HIDDEN_FOOTER,
  BUY_GAMEPASS,
  SET_BUY_GAMEPASS,
  GET_GAMEPASS_INVENTORY,
  SET_GAMEPASS_INVENTORY,
  ACTIVATE_GAMEPASS,
  SET_ACTIVATE_GAMEPASS,
  GET_GAMEPASS_FLAG,
  SET_GAMEPASS_FLAG,
  SHOW_GAMEPASS_POPUP,
  CLEAR_LISTED_NFT,
  SET_CLEAR_USER_TRANSACTIONS,
  SET_USER_TRANSACTIONS_SIZE,
  GET_DYNAMIC_NFT_SLUG,
  SET_DYNAMIC_NFT_SLUG,
  GET_REFERRAL_LINK,
  SET_REFERRAL_LINK,
  SET_REFERRAL_CODE,
  SET_REFERRAL_SHARE_MESSAGE,
  GET_REFERRAL_HISTORY,
  SET_REFERRAL_HISTORY,
  INVITE_FRIEND,
  SET_REFERRAL_INVITE,
  GET_REFERRER_DETAILS,
  SET_REFERRER_DETAILS,
  GET_WITHDRAWAL_NFT,
  SET_WITHDRAWAL_NFT,
  SET_WITHDRAWAL_NFT_VALIDATE,
  GET_WITHDRAWAL_NFT_VALIDATE,
  GET_WITHDRAW_NFT,
  SET_WITHDRAW_NFT,
  SET_SPEND_REFERENCE_ID,
  GET_FLAGS,
  SET_FLAGS,
  SET_UPDATE_FLAG,
  UPDATE_FLAG,
  GET_GAMEPASS_TOURNAMENTS,
  SET_GAMEPASS_TOURNAMENTS,
  GET_GAMEPASS_TOURNAMENTS_DETAILS,
  SET_GAMEPASS_TOURNAMENTS_DETAILS,
  GET_GAMEPASS_TOURNAMENT_TOKEN,
  SET_GAMEPASS_TOURNAMENT_TOKEN,
  GET_GAMEPASS_PRODUCT_DETAILS,
  SET_GAMEPASS_PRODUCT_DETAILS,
  SET_GAMEPASS_TRANSACTION_IS_FOUND,
  SET_GAMEPASS_TRANSACTION_IS_IN_TIMELIMIT,
  GET_FEATURED_REWARDS,
  SET_FEATURED_REWARDS,
  SET_REWARD_IS_CLAIMED,
  GET_GAMEPASS_SKU_DETAILS,
  SET_GAMEPASS_SKU_DETAILS,
  GAMEPASS_ADD_TO_CART,
  SET_GAMEPASS_IS_ADDED_TO_CART,
  GET_GAMEPASS_CART_ITEMS,
  SET_GAMEPASS_CART_ITEMS,
  GET_GAMEPASS_CHECKOUT,
  SET_GAMEPASS_CHECKOUT,
  SET_GAMEPASS_PLAY_GAME,
  GET_GAMEPASS_ACTIVE_ENTITLEMENTS,
  SET_GAMEPASS_ACTIVE_ENTITLEMENTS,
  GET_USER_TRANSACTION_DATA,
  SET_USER_TRANSACTION_DATA,
  UPDATE_GAMEPASS_CART_ITEMS,
  SET_UPDATE_GAMEPASS_CART_ITEMS,
  ADD_GAMEPASS_TO_CART,
  UPDATE_GAMEPASS_CHECKOUT,
  PAY_GAMEPASS_ON_CHECKOUT,
  SET_GAMEPASS_CHECKOUT_PAYMENT,
  GET_GAMEPASS_CART_COUNT,
  SET_GAMEPASS_CART_COUNT,
  GAMEPASS_CHECKOUT_RETURN_TO_PREVIOUS_PAGE,
  GET_SCHOLARSHIP_PROMOS,
  SET_SCHOLARSHIP_PROMOS,
  JOIN_BETA,
  SET_JOIN_BETA_RESPONSE_SUCCESS,
  SET_JOIN_BETA_RESPONSE_ERROR,
  GET_BETA_APPLICATION_FORM,
  SET_BETA_APPLICATION_FORM,
  SUBMIT_BETA_APPLICATION_FORM,
  SET_BETA_APPLICATION_RESULT,
  SET_GAMEPASS_BETA_USER,
  SHOW_GAMEPASS_ACTIVATED,
  PAY_GAMEPASS_ON_CHECKOUT_OTP,
  PAY_GAMEPASS_ON_CHECKOUT_RESEND_OTP,
  GET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  SET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  ADD_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  DELETE_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  SET_ADDED_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  SET_GAMEPASS_ACTIVATING,
  GET_MODAL_NOTIFICATIONS,
  SET_MODAL_NOTIFICATIONS,
  SET_WALKTHROUGH_HEIGHT_LIMIT_FLAG,
  GET_HOME_PAGE_BANNERS,
  SET_HOME_PAGE_DESKTOP_BANNER,
  SET_HOME_PAGE_MOBILE_BANNER,
  GET_WEN_LAMBO_PAGE_BANNERS,
  SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_LOGO,
  SET_WEN_LAMBO_PAGE_MOBILE_BANNER_LOGO,
  SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_BG,
  SET_WEN_LAMBO_PAGE_MOBILE_BANNER_BG,
  SET_WEN_LAMBO_STW_DESKTOP_BANNER,
  SET_WEN_LAMBO_STW_MOBILE_BANNER,
  GET_DAILY_REWARDS,
  SET_DAILY_REWARDS,
  CLAIM_DAILY_REWARDS,
  SET_CLAIMED_DAILY_REWARDS,
  CLAIM_DAILY_REWARDS_IS_LOADING,
  CONFIRM_REWARD_REDEMPTION,
  SHOW_REWARD_OTP_FORM,
  SET_OVERRIDE_ACTIVE_BOOSTER,
  SET_MODAL_NOTIFICATIONS_IS_LOADING,
  GET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS,
  SET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS,
  GET_ONBOARDING_TUTORIALS,
  SET_ONBOARDING_TUTORIALS,
  GET_FIRST_TIME_COMPLETION_REWARDS,
  SET_FIRST_TIME_COMPLETION_REWARDS,
  CLAIM_ACHIEVEMENT_REWARD,
  CLAIMED_ACHIEVEMENT_REWARD,
  DISABLE_FOOTER_POINTER_EVENT,
  GET_REWARD_PARTNERS,
  SET_REWARD_PARTNERS,
  SET_SHOW_PAYMENT_METHODS,
  CLAIM_ITEM_VIA_VOUCHER,
  SET_ITEM_REDEMPTION_ERROR,
  UTILIZE_CLAIMED_REWARD,
  ACTIVATE_GAMEPASS_AND_BOOSTER,
  RETRY_GAMEPASS_TOURNAMENT_TOKEN,
  SET_REMOTE_CONFIG,
  ACTIVATE_MULTIPLE_SKUS,
  LOGIN_DATA,
  SET_SHOW_LOADING,
  SET_FAILED_REQUESTS,
  GET_PROMOTION_SPIN_THE_WHEEL_DETAILS,
  SET_PROMOTION_SPIN_THE_WHEEL_DETAILS,
  NAVIGATE_TO,
  RESET_STATE,
  SET_UNAUTHORIZED_REDIRECT,
  SHOW_GAMEPASS_CHECKOUT,
  SET_GAMEPASS_CHECKOUT_FORM,
  SET_CLAIM_FREE_GAMEPASS,
  SHOW_ACTIVE_MODAL,
  REDIRECT_TO_GAME_PARTNER_PAGE,
  USER_PROFILE_REQUESTED,
  SELECT_GAMEPASS_CHECKOUT_CURRENCY,
  SET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY,
  GET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY,
  DISABLE_CHECKOUT_BUTTON,
  SHOW_CURRENCY_TOAST,
  SET_GAMEPASS_CHECKOUT_SELECTED_PAYMENT_METHOD,
  SET_TRANSACTION_ACTIVE_TAB,
  SHOW_REFERRAL_AD_BANNER,
  GET_WALLET_SIGN_MESSAGE,
  SET_WALLET_SIGN_MESSAGE,
  VERIFY_WALLET_SIGN_MESSAGE,
  VERIFYING_WALLET_SIGN_MESSAGE,
  SET_USER_WALLET_ADDRESS,
  USER_WALLET_CONNECTED,
  REDIRECT_TO_CHECKOUT_PAGE,
  GET_REWARD_OTP,
  SET_USER_HAS_GAMEPASS,
  SET_USER_HAS_ONLY_GAMEPASS,
  GET_BUY_REWARDS,
  SET_BUY_REWARDS,
  CONFIRM_BUY_REWARD_SUCCESS,
  CONFIRM_BUY_REWARD,
  SET_FREE_GAMEPASS_TIMER,
  SET_GAME_OVER_URL,
  SET_USER_HAS_GOLD_BOOSTER,
  DISABLE_GOLD_BOOSTER_SELECTION,
  SET_WALLET_CURRENCY_CONVERSION,
  CONNECT_FROM_TOPUP,
  GET_CRYPTO_CURRENCIES,
  SET_CRYPTO_CURRENCIES,
  WITHDRAW_CRYPTO,
  VALIDATE_CRYPTO_WITHDRAWAL,
  CRYPTO_WITHDRAWAL_VALIDATE_SUCCESS_DATA,
  GET_CRYPTO_CHAINS,
  SET_CRYPTO_CHAINS,
  GET_WITHDRAWAL_NOTICE,
  SET_WITHDRAWAL_NOTICE
} from './constants';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading
});

export const setShowLoading = (showLoading) => ({
  type: SET_SHOW_LOADING,
  showLoading
});

export const navigateTo = (navigateData) => ({
  type: NAVIGATE_TO,
  navigateData
});

export const resetState = () => ({
  type: RESET_STATE
});

export const unauthorizedRedirect = (payload) => ({
  type: SET_UNAUTHORIZED_REDIRECT,
  payload
});

export const showActiveModal = (activeModal) => ({
  type: SHOW_ACTIVE_MODAL,
  activeModal
});

export const renderPageContents = (pageContentsRendered) => ({
  type: RENDER_PAGE_CONTENTS,
  pageContentsRendered
});

export const showOrderSummary = (orderSummaryVisible) => ({
  type: SHOW_ORDER_SUMMARY,
  orderSummaryVisible
});

export const setRemoteConfigKeys = (remoteConfigKeys) => ({
  type: SET_REMOTE_CONFIG,
  remoteConfigKeys
});

export const tryLogin = (payload) => ({
  type: TRY_LOGIN,
  payload
});

export const displayRegistrationForm = (registrationFormVisible) => ({
  type: DISPLAY_REGISTRATION_FORM,
  registrationFormVisible
});

export const setErrorMessage = (errorMessage) => ({
  type: SET_ERROR_MESSAGE,
  errorMessage
})

export const submitLogin = (formData) => ({
  type: SUBMIT_LOGIN,
  formData
});

export const loginSuccess = (auth) => ({
  type: LOGIN_SUCCESS,
  auth
});

export const loginFailure = (auth) => ({
  type: LOGIN_FAILURE,
  auth
});

export const loginData = (loginData) => ({
  type: LOGIN_DATA,
  loginData
});

export const setAuthStep = (step) => ({
  type: SET_AUTH_STEP,
  step
});

export const setSessionExpired = (sessionExpired) => ({
  type: SET_SESSION_EXPIRED,
  sessionExpired
});

export const validatePIN = (currentPIN, newPIN) => ({
  type: VALIDATE_PIN,
  currentPIN,
  newPIN
});

export const setGuest = (isGuest) => ({
  type: SET_GUEST,
  isGuest
})

export const setUrlParams = (urlParams) => ({
  type: SET_URL_PARAMS,
  urlParams
})

export const displaySetPINForm = (setPINFormDisplayed) => ({
  type: DISPLAY_SET_PIN_FORM,
  setPINFormDisplayed
});

export const startLoginOTPTimer = (loginOTPTimerActive) => ({
  type: START_LOGIN_OTP_TIMER,
  loginOTPTimerActive
});

export const startEmailLoginOTPTimer = (emailLoginOTPTimerActive) => ({
  type: START_EMAIL_LOGIN_OTP_TIMER,
  emailLoginOTPTimerActive
});

export const loginOTPSuccess = (token) =>({
  type: LOGIN_OTP_SUCCESS,
  token
});

export const loginOTPFailure = (auth) => ({
  type: LOGIN_OTP_FAILURE,
  auth
});

export const enableEmailOTP = (isEmailAvailable) => ({
  type: ENABLE_EMAIL_OTP,
  isEmailAvailable
});

export const setEmailOTPToEnabled = (isEmailOTPEnabled) => ({
  type: SET_EMAIL_OTP_TO_ENABLED,
  isEmailOTPEnabled
});

export const setDisplayToast = (toast) => ({
  type: SET_DISPLAY_TOAST,
  toast
});

export const setHideHeader = (hideHeader) => ({
  type: SET_HIDDEN_HEADER,
  hideHeader
});

export const setHideMenuIcon = (hideMenuIcon) => ({
  type: SET_HIDDEN_MENU_ICON,
  hideMenuIcon
});

export const setHideFooter = (hideFooter) => ({
  type: SET_HIDDEN_FOOTER,
  hideFooter
});

export const setWalkthroughHeightLimitFlag = (hasNoWalkthroughHeightLimit) => ({
  type: SET_WALKTHROUGH_HEIGHT_LIMIT_FLAG,
  hasNoWalkthroughHeightLimit
});

export const disableFooterPointerEvent = (shouldDisable) => ({
  type: DISABLE_FOOTER_POINTER_EVENT,
  shouldDisable
});

export const submitLoginOTP = (params) => ({
  type: SUBMIT_LOGIN_OTP,
  params
});

export const submitLoginPIN = (params) => ({
  type: SUBMIT_LOGIN_PIN,
  params
});

export const submitPIN = (currentPIN) => ({
  type: SUBMIT_PIN,
  currentPIN
});

export const recoverPIN = (payload) => ({
  type: RECOVER_PIN,
  payload
});

export const startPINOTPTimer = (pinOTPTimerActive) => ({
  type: START_PIN_OTP_TIMER,
  pinOTPTimerActive
});

export const submitPINOTP = (params) => ({
  type: SUBMIT_PIN_OTP,
  params
});

export const submitNewPIN = (params) => ({
  type: SUBMIT_NEW_PIN,
  params
});

export const setSubmitNewPIN = (isSubmit) => ({
  type: SET_SUBMIT_NEW_PIN,
  isSubmit
});

export const setPINAuthStep = (step) => ({
  type: SET_PIN_AUTH_STEP,
  step
});

export const setPINValidationError = (hasValidationError) => ({
  type: SET_PIN_VALIDATION_ERROR,
  hasValidationError
});

export const getUserProfile = (payload) => ({
  type: GET_USER_PROFILE,
  payload
});

export const setUserProfile = (userProfile) => ({
  type: SET_USER_PROFILE,
  userProfile
});

export const userProfileRequested = (isRequested) => ({
  type: USER_PROFILE_REQUESTED,
  isRequested
});

export const getUserProfileFields = () => ({
  type: GET_USER_PROFILE_FIELDS
});

export const setUserProfileFields = (fields) => ({
  type: SET_USER_PROFILE_FIELDS,
  fields
});

export const setUserProfileCountryCode = (countryCode) => ({
  type: SET_USER_PROFILE_COUNTRY_CODE,
  countryCode
});

export const editUserProfile = (userProfileValues) => ({
  type: EDIT_USER_PROFILE,
  userProfileValues
});

export const updateUserProfilePhoto = (imageFile) => ({
  type: UPDATE_USER_PROFILE_PHOTO,
  imageFile
});

export const removeUserProfilePhoto = () => ({
  type: REMOVE_USER_PROFILE_PHOTO
});

export const setUserProfileErrorStatus = (userProfileErrorStatus) => ({
  type: SET_USER_PROFILE_ERROR_STATUS,
  userProfileErrorStatus
});

export const getHomeCollection = () => ({
  type: GET_HOME_COLLECTION
});

export const setHomeCollection = (homeCollection) => ({
  type: SET_HOME_COLLECTION,
  homeCollection
});

export const getGameDetails = (gameName) => ({
  type: GET_GAME_DETAILS,
  gameName
});

export const setGameDetails = (gameDetails) => ({
  type: SET_GAME_DETAILS,
  gameDetails
});

export const getNFTCollectionDetails = (nftId) => ({
  type: GET_NFT_COLLECTION_DETAILS,
  nftId
});

export const setNFTCollectionDetails = (nftCollectionDetails) => ({
  type: SET_NFT_COLLECTION_DETAILS,
  nftCollectionDetails
});

export const getNFTItemDetails = (nftId, nftItemId) => ({
  type: GET_NFT_ITEM_DETAILS,
  nftId,
  nftItemId
});

export const setNFTItemDetails = (nftItemDetails) => ({
  type: SET_NFT_ITEM_DETAILS,
  nftItemDetails
});

export const clearListedNfts = (details) => ({
  type: CLEAR_LISTED_NFT,
  details
});

export const buyNFTItem = (nftItemId) => ({
  type: BUY_NFT_ITEM,
  nftItemId
});

export const getLaunchpadCollection = () => ({
  type: GET_LAUNCHPAD_COLLECTION
});

export const getLaunchpadCollectionItem = (id) => ({
  type: GET_LAUNCHPAD_COLLECTION_ITEM,
  id
});

export const setLaunchpadCollection = (launchpadCollection) => ({
  type: SET_LAUNCHPAD_COLLECTION,
  launchpadCollection
});

export const setLaunchpadCollectionItem = (launchpadCollectionItem) => ({
  type: SET_LAUNCHPAD_COLLECTION_ITEM,
  launchpadCollectionItem
});

export const getNftData = (nftData) => ({
  type: GET_NFT_DATA,
  nftData
});

export const getPrizeSlug = (payload) => ({
  type: GET_PRIZE_SLUG,
  payload
});

export const setPrizeSlug = (prizeSlug) => ({
  type: SET_PRIZE_SLUG,
  prizeSlug
});

export const setPrizeSlugData = (prizeSlugData) => ({
  type: SET_PRIZE_SLUG_DATA,
  prizeSlugData
});

export const getDynamicSlug = (payload) => ({
  type: GET_DYNAMIC_NFT_SLUG,
  payload
});

export const setDynamicSlug = (slugEnableButton) => ({
  type: SET_DYNAMIC_NFT_SLUG,
  slugEnableButton
});

export const mintNFTLaunchpadItem = (payload) => ({
  type: MINT_NFT_LAUNCHPAD_ITEM,
  payload
});

export const redeemNFT = (redeem) => ({
  type: MINTING_IS_REDEEM,
  redeem
});

export const getUserCollections = (category) => ({
  type: GET_USER_COLLECTIONS,
  category
});

export const setUserCollections = (userCollections) => ({
  type: SET_USER_COLLECTIONS,
  userCollections
});

export const getUserCollection = (userCollectionId) => ({
  type: GET_USER_COLLECTION,
  userCollectionId
});

export const setUserCollection = (userCollection) => ({
  type: SET_USER_COLLECTION,
  userCollection
});

export const getUserCommunities = () => ({
  type: GET_USER_COMMUNITIES
});

export const setUserCommunities = (userCommunities) => ({
  type: SET_USER_COMMUNITIES,
  userCommunities
});

export const getUserCommunity = (userCommunityId, setLoading = true) => ({
  type: GET_USER_COMMUNITY,
  userCommunityId,
  setLoading
});

export const setUserCommunity = (userCommunity) => ({
  type: SET_USER_COMMUNITY,
  userCommunity
});

export const getGuildApplicationForm = (userCommunityId) => ({
  type: GET_GUILD_APPLICATION_FORM,
  userCommunityId
});

export const claimScholarship = (payload) => ({
  type: CLAIM_SCHOLARSHIP,
  payload
})

export const setGuildApplicationForm = (guildApplicationForm) => ({
  type: SET_GUILD_APPLICATION_FORM,
  guildApplicationForm
});

export const submitGuildApplicationForm = (guildId, form, userId, newProfile) => ({
  type: SUBMIT_GUILD_APPLICATION_FORM,
  guildId, 
  form,
  userId,
  newProfile
});

export const getSpendMobileLoadProducts = (formData) => ({
  type: GET_SPEND_MOBILE_LOAD_PRODUCTS,
  formData
});

export const setSpendMobileLoadProducts = (mobileLoadProducts) => ({
  type: SET_SPEND_MOBILE_LOAD_PRODUCTS,
  mobileLoadProducts
});

export const confirmSpendMobileLoadProduct = (confirmData) => ({
  type: CONFIRM_SPEND_MOBILE_LOAD_PRODUCT,
  confirmData
});

export const setSpendMobileLoadProductConfirmation = (confirmation) => ({
  type: SET_SPEND_MOBILE_LOAD_PRODUCT_CONFIRMATION,
  confirmation
});

export const setSpendCountry = (country) => ({
  type: SET_SPEND_COUNTRY,
  country
});

export const getSpendCountry = () => ({
  type: GET_SPEND_COUNTRY
});

export const payMobileLoadProduct = (paymentData) => ({
  type: PAY_MOBILE_LOAD_PRODUCT,
  paymentData
});

export const setSpendReferenceId = (referenceId) => ({
  type: SET_SPEND_REFERENCE_ID,
  referenceId
});

export const setSpendLoadingText = (loadingText) => ({
  type: SET_SPEND_LOADING_TEXT,
  loadingText
});

export const setUnreadMessages = (unreadMessages) => ({
  type: SET_UNREAD_MESSAGES,
  unreadMessages
});

export const getChatMessages = () => ({
  type: GET_CHAT_MESSAGES
});

export const setChatMessages = (messages) => ({
  type: SET_CHAT_MESSAGES,
  messages
});

export const getChatMessageThread = (threadId) => ({
  type: GET_CHAT_MESSAGE_THREAD,
  threadId
});

export const setChatMessageThread = (messageThread) => ({
  type: SET_CHAT_MESSAGE_THREAD,
  messageThread
});

export const submitChatMessage = (message) => ({
  type: SUBMIT_CHAT_MESSAGE,
  message
});

export const getTutorialScreens = (fromProfilePage) => ({
  type: GET_TUTORIAL_SCREENS,
  fromProfilePage
});

export const setTutorialScreens = (tutorialScreens) => ({
  type: SET_TUTORIAL_SCREENS,
  tutorialScreens
});

export const getProfileTutorial = () => ({
  type: GET_PROFILE_TUTORIAL
});

export const setProfileTutorial = (hasTutorial) => ({
  type: SET_PROFILE_TUTORIAL,
  hasTutorial
});

export const getTransactions = () => ({
  type: GET_TRANSACTIONS
});

export const setTransactions = (transactions) => ({
  type: SET_TRANSACTIONS,
  transactions
});

export const getMoreTransactions = (page) => ({
  type: GET_MORE_TRANSACTIONS,
  page
});

export const getQuests = () => ({
  type: GET_QUESTS
});

export const setQuests = (quests) => ({
  type: SET_QUESTS,
  quests
});

export const setQuestLeaderboards = (questLeaderboards) => ({
  type: SET_QUEST_LEADERBOARDS,
  questLeaderboards
});

export const setQuestFAQs = (questFAQs) => ({
  type: SET_QUEST_FAQS,
  questFAQs
});

export const resendEmailOTP = (payload) => ({
  type: RESEND_EMAIL_OTP,
  payload
});

export const setHomeTutorial = (tutorial) => ({
  type: SET_HOME_TUTORIAL,
  tutorial
});

export const setPaymentStep = (paymentStep) => ({
  type: SET_SPEND_PAYMENT_STEP,
  paymentStep
});

export const setSpendLoading = (loading) => ({
  type: SET_SPEND_LOADING,
  loading
});

export const setSpendOTPSuccess= (success) => ({
  type: SET_SPEND_OTP_SUCCESS,
  success
});

export const submitSpendOTP = (params) => ({
  type: SUBMIT_SPEND_OTP,
  params
});

export const sendSpendOTP = (payload) => ({
  type: SEND_SPEND_OTP,
  payload
});

export const setSpendError = (error) => ({
  type: SET_SPEND_ERROR,
  error
});

export const getFeaturedPromotion = () => ({
  type: GET_FEATURED_PROMOTION
});

export const setFeaturedPromotion = (featuredPromotion) => ({
  type: SET_FEATURED_PROMOTION,
  featuredPromotion
});

export const getFeaturedPromotionSettings = (payload) => ({
  type: GET_FEATURED_PROMOTION_SETTINGS,
  payload
});

export const getRedeem = (payload) => ({
  type: GET_REDEEM,
  payload
});

export const setRedeem = (redeem) => ({
  type: SET_REDEEM,
  redeem
});

export const setRedeemData = (data) => ({
  type: SET_REDEEM_DATA,
  data
});

export const getTicket = (id) => ({
  type: GET_TICKET,
  id
});

export const setClaimData = (claimData) => ({
  type: SET_CLAIM_DATA,
  claimData
});

export const getSpinPrize = (payload) => ({
  type: GET_SPIN_PRIZE,
  payload
});

export const setSpinPrize = (prize) => ({
  type: SET_SPIN_PRIZE,
  prize
});

export const getSpinPrizes = (payload) => ({
  type: GET_SPIN_PRIZES,
  payload
});

export const setSpinPrizes = (prizes) => ({
  type: SET_SPIN_PRIZES,
  prizes
});

export const getSpinAvailable = (payload) => ({
  type: GET_SPIN_AVAILABLE,
  payload
});

export const getWenLamboSpinTheWheelDetails = (payload, queryParams) => ({
  type: GET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS,
  payload,
  queryParams
});

export const setWenLamboSpinTheWheelDetails = (wenLamboSpinTheWheelDetails) => ({
  type: SET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS,
  wenLamboSpinTheWheelDetails
});

export const getPromotionSpinTheWheelDetails = (payload) => ({
  type: GET_PROMOTION_SPIN_THE_WHEEL_DETAILS,
  payload
});

export const setPromotionSpinTheWheelDetails = (promotionSpinTheWheelDetails) => ({
  type: SET_PROMOTION_SPIN_THE_WHEEL_DETAILS,
  promotionSpinTheWheelDetails
});

export const getOnboardingTutorials = () => ({
  type: GET_ONBOARDING_TUTORIALS
});

export const setOnboardingTutorials = (onboarding) => ({
  type: SET_ONBOARDING_TUTORIALS,
  onboarding
});

export const setSpinAvailable = (spinAvailable) => ({
  type: SET_SPIN_AVAILABLE,
  spinAvailable
});

export const getUserTransactions = (payload) => ({
  type: GET_USER_TRANSACTIONS,
  payload
});

export const setUserTransactions = (transactions) => ({
  type: SET_USER_TRANSACTIONS,
  transactions
});

export const setUserTransactionsSize = (size) => ({
  type: SET_USER_TRANSACTIONS_SIZE,
  size
});

export const setClearUserTransactions = (transactions) => ({
  type: SET_CLEAR_USER_TRANSACTIONS,
  transactions
});

export const getUserTransactionDetails = (payload) => ({
  type: GET_USER_TRANSACTIONS_DETAILS,
  payload
});

export const setUserTransactionDetails = (transactionDetails) => ({
  type: SET_USER_TRANSACTIONS_DETAILS,
  transactionDetails
});

export const getUserTransactionData = (payload) => ({
  type: GET_USER_TRANSACTION_DATA,
  payload
});

export const setUserTransactionData = (data) => ({
  type: SET_USER_TRANSACTION_DATA,
  data
});

export const setTransactionActiveTab = (activeTab) => ({
  type: SET_TRANSACTION_ACTIVE_TAB,
  activeTab
});

export const getPaymentMethods = (payload) => ({
  type: GET_PAYMENT_METHODS,
  payload
});

export const getPaymentChannels = (payload) => ({
  type: GET_PAYMENT_CHANNELS,
  payload
});

export const setPaymentMethods = (buyData) => ({
  type: SET_PAYMENT_METHODS,
  buyData
});

export const setPaymentChannels = (channels) => ({
  type: SET_PAYMENT_CHANNELS,
  channels
});

export const createPayment = (payload) => ({
  type: CREATE_PAYMENT,
  payload
});

export const setCreatePayment = (payment) => ({
  type: SET_CREATE_PAYMENT,
  payment
});

export const getCurrencyConversion = (payload) => ({
  type: GET_CURRENCY_CONVERSION,
  payload
});

export const setCurrencyConversion = (currency) => ({
  type: SET_CURRENCY_CONVERSION,
  currency
});

export const getCurrencyConversionBatch = (payload) => ({
  type: GET_CURRENCY_CONVERSION_BATCH,
  payload
});

export const setCurrencyConversionBatch = (currencyBatch) => ({
  type: SET_CURRENCY_CONVERSION_BATCH,
  currencyBatch
});

export const setCurrencyLoading = (isLoading) => ({
  type: SET_CURRENCY_LOADING,
  isLoading
});

export const setWalletCurrencyConversion = (walletCurrency) => ({
  type: SET_WALLET_CURRENCY_CONVERSION,
  walletCurrency
});

export const connectFromTopUp = (isFromTopUp) => ({
  type: CONNECT_FROM_TOPUP,
  isFromTopUp
});

export const setTopUpVisible = (isTopUpVisible) => ({
  type: SET_TOPUP_VISIBLE,
  isTopUpVisible
});

export const setInstructionsVisible = (isInstructionsVisible) => ({
  type: SET_INSTRUCTIONS_VISIBLE,
  isInstructionsVisible
});

export const setBarcodeVisible = (isBarcodeVisible) => ({
  type: SET_BARCODE_VISIBLE,
  isBarcodeVisible
});

export const setSelectedMethod = (selectedMethod) => ({
  type: SET_SELECTED_METHOD,
  selectedMethod
});

export const setTransactionVisible = (isTransactionVisible) => ({
  type: SET_TRANSACTION_VISIBLE,
  isTransactionVisible
});

export const getMarketplaceCollection = () => ({
  type: GET_MARKETPLACE_COLLECTIONS
});

export const setMarketplaceCollection = (collections) => ({
  type: SET_MARKETPLACE_COLLECTIONS,
  collections
});

export const setMarketplaceNftCollection = (nftCollection) => ({
  type: SET_MARKETPLACE_COLLECTION,
  nftCollection
});

export const getAllListedNft = (payload) => ({
  type: GET_ALL_LISTED_NFT,
  payload
});

export const setAllListedNft = (listedNfts) => ({
  type: SET_ALL_LISTED_NFT,
  listedNfts
});

export const setAllListedNftPagination = (listedNftsPagination) => ({
  type: SET_ALL_LISTED_NFT_PAGINATION,
  listedNftsPagination
});

export const setListedNftPagination = (listedNftPagination) => ({
  type: SET_LISTED_NFT_PAGINATION,
  listedNftPagination
});

export const getMarketplaceNftDetails = (payload) => ({
  type: GET_MARKETPLACE_NFT_DETAILS,
  payload
});

export const setMarketplaceNftDetails = (details) => ({
  type: SET_MARKETPLACE_NFT_DETAILS,
  details
});

export const getCollectionDetails = (payload) => ({
  type: GET_COLLECTION_DETAILS,
  payload
});

export const setCollectionDetails = (collection) => ({
  type: SET_COLLECTION_DETAILS,
  collection
});

export const setCollectionPagination = (paginationData) => ({
  type: SET_COLLECTION_PAGINATION,
  paginationData
});

export const setCollectionNftPagination = (paginationNftData) => ({
  type: SET_COLLECTION_NFT_PAGINATION,
  paginationNftData
});

export const setCollectionPaginationLoading = (isRendering) => ({
  type: SET_COLLECTION_PAGINATION_LOADING,
  isRendering
});

export const listNFT = (payload) => ({
  type: LIST_NFT,
  payload
});

export const setIsList = (isList) => ({
  type: SET_LIST_NFT,
  isList
});

export const getListingFee = (payload) => ({
  type: GET_LISTING_FEE,
  payload
});

export const setListingFee = (listingFee) => ({
  type: SET_LISTING_FEE,
  listingFee
});

export const setListingFeeLoading = (listingFeeLoading) => ({
  type: SET_LISTING_FEE_LOADING,
  listingFeeLoading
});

export const buyNFT = (payload) => ({
  type: BUY_NFT,
  payload
});

export const setBuyNftId = (transactionId) => ({
  type: SET_BUY_NFT_ID,
  transactionId
});

export const setIsBuy = (isBuy) => ({
  type: SET_BUY_NFT,
  isBuy
});

export const cancelListNFT = (payload) => ({
  type: CANCEL_LIST_NFT,
  payload
});

export const setIsCancelListNFT = (isCancelList) => ({
  type: SET_CANCEL_LIST_NFT,
  isCancelList
});

export const setIsCancelListBack = (cancelListBack) => ({
  type: SET_CANCEL_LIST_BACK,
  cancelListBack
});

export const setOpenModal = (openModal) => ({
  type: SET_OPEN_MODAL,
  openModal
});

export const setBuyNavigate = (buyNavigate) => ({
  type: SET_BUY_NAVIGATE,
  buyNavigate
});

export const setSelectedSlug = (slug) => ({
  type: SET_SELECTED_SLUG,
  slug
});

export const getTournaments = (tournamentType) => ({
  type: GET_TOURNAMENTS,
  tournamentType
});

export const setTournaments = (tournaments) => ({
  type: SET_TOURNAMENTS,
  tournaments
});

export const getRewards = () => ({
  type: GET_REWARDS
});

export const setRewards = (rewardsList) => ({
  type: SET_REWARDS,
  rewardsList
});

export const getFeaturedRewards = () => ({
  type: GET_FEATURED_REWARDS
});

export const setFeaturedRewards = (featuredRewards) => ({
  type: SET_FEATURED_REWARDS,
  featuredRewards
});

export const getRewardDetails = (rewardId) => ({
  type: GET_REWARD_DETAILS,
  rewardId
});

export const setRewardDetails = (rewardDetails) => ({
  type: SET_REWARD_DETAILS,
  rewardDetails
});

export const redeemReward = (payload) => ({
  type: REDEEM_REWARD,
  payload
});

export const setRewardIsClaimed = (isClaimed) => ({
  type: SET_REWARD_IS_CLAIMED,
  isClaimed
});

export const getModalNotifications = (payload) => ({
  type: GET_MODAL_NOTIFICATIONS,
  payload
});

export const setModalNotifications = (notifications) => ({
  type: SET_MODAL_NOTIFICATIONS,
  notifications
});

export const setModalNotificationsIsLoading = (notificationsIsLoading) => ({
  type: SET_MODAL_NOTIFICATIONS_IS_LOADING,
  notificationsIsLoading
});

export const getDailyRewards = () => ({
  type: GET_DAILY_REWARDS
});

export const setDailyRewards = (dailyRewards) => ({
  type: SET_DAILY_REWARDS,
  dailyRewards
});

export const claimDailyRewards = (payload) => ({
  type: CLAIM_DAILY_REWARDS,
  payload
});

export const setClaimedDailyRewards = (claimedRewards) => ({
  type: SET_CLAIMED_DAILY_REWARDS,
  claimedRewards
});

export const claimDailyRewardsIsLoading = (isLoading) => ({
  type: CLAIM_DAILY_REWARDS_IS_LOADING,
  isLoading
});

export const confirmRewardRedemption = (payload) => ({
  type: CONFIRM_REWARD_REDEMPTION,
  payload
});

export const showRewardOTPForm = (isOTPFormVisible) => ({
  type: SHOW_REWARD_OTP_FORM,
  isOTPFormVisible
});

export const getRewardOTP = (payload) => ({
  type: GET_REWARD_OTP,
  payload
});

export const getBuyRewards = () => ({
  type: GET_BUY_REWARDS
});

export const setBuyRewards = (buyRewards) => ({
  type: SET_BUY_REWARDS,
  buyRewards
});

export const confirmBuyReward = (payload) => ({
  type: CONFIRM_BUY_REWARD,
  payload
});

export const confirmBuyRewardSuccess = (successData) => ({
  type: CONFIRM_BUY_REWARD_SUCCESS,
  successData
});

export const getGamePassProducts = () => ({
  type: GET_GAMEPASS_PRODUCTS
});

export const setGamePassProducts = (products) => ({
  type: SET_GAMEPASS_PRODUCTS,
  products
});

export const getGamePassProductDetails = (payload) => ({
  type: GET_GAMEPASS_PRODUCT_DETAILS,
  payload
});

export const setGamePassProductDetails = (productDetails) => ({
  type: SET_GAMEPASS_PRODUCT_DETAILS,
  productDetails
});

export const getGamePassSkuDetails = (payload) => ({
  type: GET_GAMEPASS_SKU_DETAILS,
  payload
});

export const setGamePassSkuDetails = (skuDetails) => ({
  type: SET_GAMEPASS_SKU_DETAILS,
  skuDetails
});

export const setGamePassTransactionIsFound = (isFound) => ({
  type: SET_GAMEPASS_TRANSACTION_IS_FOUND,
  isFound
});

export const setGamePassTransactionIsInTimeLimit = (isInTimeLimit) => ({
  type: SET_GAMEPASS_TRANSACTION_IS_IN_TIMELIMIT,
  isInTimeLimit 
});

export const getGamePassScreen = () => ({
  type: GET_GAMEPASS_SCREEN
});

export const setGamePassScreen = (gamePassScreenSections) => ({
  type: SET_GAMEPASS_SCREEN,
  gamePassScreenSections
});

export const setGamePassSelectedProduct = (selectedProduct) => ({
  type: SET_GAMEPASS_SELECTED_PRODUCT,
  selectedProduct
});

export const buyGamePass = (payload) => ({
  type: BUY_GAMEPASS,
  payload
});

export const setBuyGamePass = (buyDetails) => ({
  type: SET_BUY_GAMEPASS,
  buyDetails
});

export const getGamePassTournaments = () => ({
  type: GET_GAMEPASS_TOURNAMENTS
});

export const setGamePassTournaments = (tournaments) => ({
  type: SET_GAMEPASS_TOURNAMENTS,
  tournaments
});

export const getGamePassTournamentsDetails = (payload) => ({
  type: GET_GAMEPASS_TOURNAMENTS_DETAILS,
  payload
});

export const setGamePassTournamentsDetails = (tournamentsDetails) => ({
  type: SET_GAMEPASS_TOURNAMENTS_DETAILS,
  tournamentsDetails
});

export const getGamePassTournamentToken = (payload) => ({
  type: GET_GAMEPASS_TOURNAMENT_TOKEN,
  payload
});

export const setGamePassTournamentToken = (tournament) => ({
  type: SET_GAMEPASS_TOURNAMENT_TOKEN,
  tournament
});

export const retryGamePassTournamentToken = (payload) => ({
  type: RETRY_GAMEPASS_TOURNAMENT_TOKEN,
  payload
});

export const getGamePassInventory = (redirectIfUnauthorized) => ({
  type: GET_GAMEPASS_INVENTORY,
  redirectIfUnauthorized
});

export const setGamePassInventory = (inventory) => ({
  type: SET_GAMEPASS_INVENTORY,
  inventory
});

export const activateGamePass = (payload) => ({
  type: ACTIVATE_GAMEPASS,
  payload
});

export const setActivateGamePass = (activateDetails) => ({
  type: SET_ACTIVATE_GAMEPASS,
  activateDetails
});

export const setOverrideActiveBooster = (overrideActiveBooster) => ({
  type: SET_OVERRIDE_ACTIVE_BOOSTER,
  overrideActiveBooster
});

export const activateGamePassAndBooster = (isGamePassAndBoosterActivated) => ({
  type: ACTIVATE_GAMEPASS_AND_BOOSTER,
  isGamePassAndBoosterActivated
});

export const activateMultipleSkus = (payload) => ({
  type: ACTIVATE_MULTIPLE_SKUS,
  payload
})

export const getGamePassFlag = (flagName) => ({
  type: GET_GAMEPASS_FLAG,
  flagName
});

export const setGamePassFlag = (flagName, isShown) => ({
  type: SET_GAMEPASS_FLAG,
  flagName,
  isShown
});

export const showGamePassPopup = (flagName, isShown) => ({
  type: SHOW_GAMEPASS_POPUP,
  flagName,
  isShown
});

export const showGamePassActivated = (showActivated) => ({
  type: SHOW_GAMEPASS_ACTIVATED,
  showActivated
});

export const setBetaUser = (betaUser) => ({
  type: SET_GAMEPASS_BETA_USER,
  betaUser
});

export const gamePassAddToCart = (payload) => ({
  type: GAMEPASS_ADD_TO_CART,
  payload
});

export const setGamePassIsAddedToCart = (isAdded) => ({
  type: SET_GAMEPASS_IS_ADDED_TO_CART,
  isAdded
});

export const getGamePassCartItems = () => ({
  type: GET_GAMEPASS_CART_ITEMS
});

export const setGamePassCartItems = (items) => ({
  type: SET_GAMEPASS_CART_ITEMS,
  items
});

export const getGamePassCartCount = () => ({
  type: GET_GAMEPASS_CART_COUNT
});

export const setGamePassCartCount = (count) => ({
  type: SET_GAMEPASS_CART_COUNT,
  count
});

export const updateGamePassCartItems = (payload) => ({
  type: UPDATE_GAMEPASS_CART_ITEMS,
  payload
});

export const setUpdateGamePassCartItems = (updatedItems) => ({
  type: SET_UPDATE_GAMEPASS_CART_ITEMS,
  updatedItems
});

export const addGamePassToCart = (payload) => ({
  type: ADD_GAMEPASS_TO_CART,
  payload
});

export const getGamePassCheckout = (payload) => ({
  type: GET_GAMEPASS_CHECKOUT,
  payload
});

export const setGamePassCheckout = (contents) => ({
  type: SET_GAMEPASS_CHECKOUT,
  contents
});

export const showGamePassCheckout = (showCheckout) => ({
  type: SHOW_GAMEPASS_CHECKOUT,
  showCheckout
});

export const updateGamePassCheckout = (payload) => ({
  type: UPDATE_GAMEPASS_CHECKOUT,
  payload
});

export const payGamePassOnCheckout = (payload) => ({
  type: PAY_GAMEPASS_ON_CHECKOUT,
  payload
});

export const payGamePassOnCheckoutOtp = (payload) => ({
  type: PAY_GAMEPASS_ON_CHECKOUT_OTP,
  payload
});

export const payGamePassOnCheckoutResendOtp = (payload) => ({
  type: PAY_GAMEPASS_ON_CHECKOUT_RESEND_OTP,
  payload
});

export const setGamePassCheckoutPayment = (payDetails) => ({
  type: SET_GAMEPASS_CHECKOUT_PAYMENT,
  payDetails
});

export const gamePassCheckoutReturnToPreviousPage = (mustRedirectBack) => ({
  type: GAMEPASS_CHECKOUT_RETURN_TO_PREVIOUS_PAGE,
  mustRedirectBack
});

export const getGamePassCheckoutTokenizedPayments = (payload) => ({
  type: GET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  payload
});

export const setGamePassCheckoutTokenizedPayments = (tokenizedPayments) => ({
  type: SET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  tokenizedPayments
});

export const addGamePassCheckoutTokenizedPayments = (payload) => ({
  type: ADD_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  payload
});

export const setAddedGamePassCheckoutTokenizedPayments = (tokenizedIsAdded) => ({
  type: SET_ADDED_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  tokenizedIsAdded
});

export const deleteGamePassCheckoutTokenizedPayments = (payload) => ({
  type: DELETE_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  payload
});

export const selectGamePassCheckoutCurrency = () => ({
  type: SELECT_GAMEPASS_CHECKOUT_CURRENCY
});

export const getGamePassCheckoutSelectedCurrency = (payload) => ({
  type: GET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY,
  payload
});

export const showCurrencyToast = (showCurrency) => ({
  type: SHOW_CURRENCY_TOAST,
  showCurrency
});

export const setGamePassCheckoutSelectedCurrency = (currency) => ({
  type: SET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY,
  currency
});

export const disableCheckoutButton = (isDisabled) => ({
  type: DISABLE_CHECKOUT_BUTTON,
  isDisabled
});

export const setShowPaymentMethods = (showPaymentMethods) => ({
  type: SET_SHOW_PAYMENT_METHODS,
  showPaymentMethods
});

export const setGamePassCheckoutSelectedPaymentMethod = (selectedPaymentMethod) => ({
  type: SET_GAMEPASS_CHECKOUT_SELECTED_PAYMENT_METHOD,
  selectedPaymentMethod
});

export const redirectToCheckoutPage = (redirectToCheckout) => ({
  type: REDIRECT_TO_CHECKOUT_PAGE,
  redirectToCheckout
});

export const setUserHasGamePass = (hasGamePass) => ({
  type: SET_USER_HAS_GAMEPASS,
  hasGamePass
});

export const setUserHasOnlyGamePass = (hasOnlyGamePass) => ({
  type: SET_USER_HAS_ONLY_GAMEPASS,
  hasOnlyGamePass
});

export const setUserHasGoldBooster = (hasGoldBooster) => ({
  type: SET_USER_HAS_GOLD_BOOSTER,
  hasGoldBooster
});

export const disableGoldBoosterSelection = (isDisabled) => ({
  type: DISABLE_GOLD_BOOSTER_SELECTION,
  isDisabled
});

export const setFreeGamePassTimer = (freeTimer) => ({
  type: SET_FREE_GAMEPASS_TIMER,
  freeTimer
});

export const setGameOverUrl = (gameOverUrl) => ({
  type: SET_GAME_OVER_URL,
  gameOverUrl
});

export const setGamePassCheckoutForm = (checkoutForm) => ({
  type: SET_GAMEPASS_CHECKOUT_FORM,
  checkoutForm
});

export const setClaimFreeGamePass = (showClaimFreeGamePass) => ({
  type: SET_CLAIM_FREE_GAMEPASS,
  showClaimFreeGamePass
});

export const setGamePassPlayGame = (openPopup) => ({
  type: SET_GAMEPASS_PLAY_GAME,
  openPopup
});

export const getGamePassActiveEntitlements = () => ({
  type: GET_GAMEPASS_ACTIVE_ENTITLEMENTS
});

export const setGamePassActiveEntitlements = (entitlements) => ({
  type: SET_GAMEPASS_ACTIVE_ENTITLEMENTS,
  entitlements
});

export const getReferralLink = () => ({
  type: GET_REFERRAL_LINK
});

export const setReferralLink = (referralLink) => ({
  type: SET_REFERRAL_LINK,
  referralLink
});

export const setReferralCode = (referralCode) => ({
  type: SET_REFERRAL_CODE,
  referralCode
});

export const setReferralShareMessage = (referralShareMessage) => ({
  type: SET_REFERRAL_SHARE_MESSAGE,
  referralShareMessage
});

export const getReferralHistory = () => ({
  type: GET_REFERRAL_HISTORY
});

export const setReferralHistory = (referralHistory) => ({
  type: SET_REFERRAL_HISTORY,
  referralHistory
});

export const inviteFriend = (mobileNumber, message) => ({
  type: INVITE_FRIEND,
  mobileNumber,
  message
});

export const setReferralInvite = (referralInvite) => ({
  type: SET_REFERRAL_INVITE,
  referralInvite
});

export const showReferralAdBanner = (openBanner) => ({
  type: SHOW_REFERRAL_AD_BANNER,
  openBanner
});

export const getReferrerDetails = (referrer) => ({
  type: GET_REFERRER_DETAILS,
  referrer
});

export const setReferrerDetails = (referrerDetails) => ({
  type: SET_REFERRER_DETAILS,
  referrerDetails
});

export const getWithdrawalNFT = (payload) => ({
  type: GET_WITHDRAWAL_NFT,
  payload
});

export const setWithdrawalNFT = (selectedNft) => ({
  type: SET_WITHDRAWAL_NFT,
  selectedNft
});

export const getWithdrawalNFTValidate = (payload) => ({
  type: GET_WITHDRAWAL_NFT_VALIDATE,
  payload
});

export const setWithdrawalNFTValidate = (validatedNft) => ({
  type: SET_WITHDRAWAL_NFT_VALIDATE,
  validatedNft
});

export const getWithdrawNft = (payload) => ({
  type: GET_WITHDRAW_NFT,
  payload
});

export const setWithdrawNft = (withdrawStatus) => ({
  type: SET_WITHDRAW_NFT,
  withdrawStatus
});

export const getFlags = (payload) => ({
  type: GET_FLAGS,
  payload
});

export const setFlags = (flagsData) => ({
  type: SET_FLAGS,
  flagsData
});

export const updateFlag = (payload) => ({
  type: UPDATE_FLAG,
  payload
});

export const setUpdateFlag = (flagDetails) => ({
  type: SET_UPDATE_FLAG,
  flagDetails
});

export const getScholarshipPromos = (promoKey) => ({
  type: GET_SCHOLARSHIP_PROMOS,
  promoKey
});

export const setScholarshipPromos = (promos) => ({
  type: SET_SCHOLARSHIP_PROMOS,
  promos
});

export const joinBeta = (betaCode) => ({
  type: JOIN_BETA,
  betaCode
});

export const setJoinBetaResponseSuccess = (betaResponseSuccess) => ({
  type: SET_JOIN_BETA_RESPONSE_SUCCESS,
  betaResponseSuccess
});

export const setJoinBetaResponseError = (betaResponseError) => ({
  type: SET_JOIN_BETA_RESPONSE_ERROR,
  betaResponseError
});

export const getBetaApplicationForm = () => ({
  type: GET_BETA_APPLICATION_FORM
});

export const setBetaApplicationForm = (applicationForm) => ({
  type: SET_BETA_APPLICATION_FORM,
  applicationForm
});

export const submitBetaApplicationForm = (applicationFormAnswer) => ({
  type: SUBMIT_BETA_APPLICATION_FORM,
  applicationFormAnswer
});

export const setBetaApplicationResult = (applicationResult) => ({
  type: SET_BETA_APPLICATION_RESULT,
  applicationResult
});

export const setGamePassActivating = (isGamePassActivating) => ({
  type: SET_GAMEPASS_ACTIVATING,
  isGamePassActivating
});

export const getHomePageBanners = () => ({
  type: GET_HOME_PAGE_BANNERS
});

export const setHomePageDesktopBanner = (homePageDesktopBanner) => ({
  type: SET_HOME_PAGE_DESKTOP_BANNER,
  homePageDesktopBanner
});

export const setHomePageMobileBanner = (homePageMobileBanner) => ({
  type: SET_HOME_PAGE_MOBILE_BANNER,
  homePageMobileBanner
});

export const getWenLamboPageBanners = () => ({
  type: GET_WEN_LAMBO_PAGE_BANNERS
});

export const setWenLamboPageDesktopBannerLogo = (wenLamboPageDesktopBannerLogo) => ({
  type: SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_LOGO,
  wenLamboPageDesktopBannerLogo
});

export const setWenLamboPageMobileBannerLogo = (wenLamboPageMobileBannerLogo) => ({
  type: SET_WEN_LAMBO_PAGE_MOBILE_BANNER_LOGO,
  wenLamboPageMobileBannerLogo
});

export const setWenLamboPageDesktopBannerBG = (wenLamboPageDesktopBannerBG) => ({
  type: SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_BG,
  wenLamboPageDesktopBannerBG
});

export const setWenLamboPageMobileBannerBG = (wenLamboPageMobileBannerBG) => ({
  type: SET_WEN_LAMBO_PAGE_MOBILE_BANNER_BG,
  wenLamboPageMobileBannerBG
});

export const setWenLamboSTWDesktopBanner = (wenLamboSTWDesktopBanner) => ({
  type: SET_WEN_LAMBO_STW_DESKTOP_BANNER,
  wenLamboSTWDesktopBanner
});

export const setWenLamboSTWMobileBanner = (wenLamboSTWMobileBanner) => ({
  type: SET_WEN_LAMBO_STW_MOBILE_BANNER,
  wenLamboSTWMobileBanner
});

export const getFirstTimeCompletionRewards = (rewardKey) => ({
  type: GET_FIRST_TIME_COMPLETION_REWARDS,
  rewardKey
});

export const setFirstTimeCompletionRewards = (firstTimeCompletionRewards) => ({
  type: SET_FIRST_TIME_COMPLETION_REWARDS,
  firstTimeCompletionRewards
});

export const claimAchievementReward = (payload) => ({
  type: CLAIM_ACHIEVEMENT_REWARD,
  payload
});

export const claimedAchievementReward = (isClaimedAchievementReward) => ({
  type: CLAIMED_ACHIEVEMENT_REWARD,
  isClaimedAchievementReward
});

export const getRewardPartners = (payload) => ({
  type: GET_REWARD_PARTNERS,
  payload
});

export const setRewardPartners = (rewardPartners) => ({
  type: SET_REWARD_PARTNERS,
  rewardPartners
});

export const claimItemViaVoucher = (voucherCode) => ({
  type: CLAIM_ITEM_VIA_VOUCHER,
  voucherCode
});

export const setItemRedemptionError = (error) => ({
  type: SET_ITEM_REDEMPTION_ERROR,
  error
});

export const utilizeClaimedReward = (rewardId, destinationUrl) => ({
  type: UTILIZE_CLAIMED_REWARD,
  rewardId,
  destinationUrl
});

export const failedRequests = (payload) => ({
  type: SET_FAILED_REQUESTS,
  payload
});

export const redirectToGamePartnerPage = (gamePartnerPageRedirect) => ({
  type: REDIRECT_TO_GAME_PARTNER_PAGE,
  gamePartnerPageRedirect
});

export const getWalletSignMessage = (payload) => ({
  type: GET_WALLET_SIGN_MESSAGE,
  payload
});

export const setWalletSignMessage = (signMessage) => ({
  type: SET_WALLET_SIGN_MESSAGE,
  signMessage
});

export const verifyWalletSignMessage = (payload) => ({
  type: VERIFY_WALLET_SIGN_MESSAGE,
  payload
});

export const verifyingWalletSignMessage = (isVerifying) => ({
  type: VERIFYING_WALLET_SIGN_MESSAGE,
  isVerifying
});

export const setUserWalletAddress = (walletAddress) => ({
  type: SET_USER_WALLET_ADDRESS,
  walletAddress
});

export const userWalletConnected = (isConnected) => ({
  type: USER_WALLET_CONNECTED,
  isConnected
});

export const getCryptoCurrencies = (payload) => ({
  type: GET_CRYPTO_CURRENCIES,
  payload
});

export const setCryptoCurrencies = (currencies) => ({
  type: SET_CRYPTO_CURRENCIES,
  currencies
});

export const getCryptoChains = (payload) => ({
  type: GET_CRYPTO_CHAINS,
  payload
});

export const setCryptoChains = (chains) => ({
  type: SET_CRYPTO_CHAINS,
  chains
});

export const withdrawCrypto = (payload) => ({
  type: WITHDRAW_CRYPTO,
  payload
});

export const validateCryptoWithdrawal = (payload) => ({
  type: VALIDATE_CRYPTO_WITHDRAWAL,
  payload
});

export const cryptoWithdrawalValidateSuccessData = (validateSuccessData) => ({
  type: CRYPTO_WITHDRAWAL_VALIDATE_SUCCESS_DATA,
  validateSuccessData
});

export const getWithdrawalNotice = (payload) => ({
  type: GET_WITHDRAWAL_NOTICE,
  payload
});

export const setWithdrawalNotice = (notice) => ({
  type: SET_WITHDRAWAL_NOTICE,
  notice
});