import React, { Fragment } from 'react';
import classNames from 'classnames';
import { defaultLocaleString } from '../../constants';
import goldCoin from '../../assets/rewards/icons/gold-coin.png';

export const BuyWithGold = ({ 
  items, 
  setSelectedReward, 
  setOpenBuyConfirmation, 
  setSelectedProduct,
  goldBalance
}) => {
  const onClickBuy = (sku, product) => {
    setOpenBuyConfirmation(true);
    setSelectedReward(sku);
    setSelectedProduct(product);
  }
  return (
    <div className="rewards-center-buy-contents">
      {items?.map((i, iIdx) => (
        <Fragment key={`BuyRewards_${iIdx}`}>
          {i?.skus?.map((s, sIdx) => (
            <div 
              key={`BuyRewardsSku_${sIdx}`} 
              className="rewards-center-buy-contents-container"
            >
              <div className="rewards-center-buy-contents-wrapper">
                <div className="w-100">
                  <img src={s?.imageUrls?.imageUrl} alt={s?.kind} className="rewards-center-buy-contents-image" />
                  <div className="rewards-center-buy-contents-name">{s?.shortName}</div>
                </div>
                <div className="d-flex justify-content-between flex-column w-100">
                  <div className="d-flex justify-content-center flex-column align-items-start mt-3">
                    <div className="d-flex center align-items-center gap-1">
                      <img src={goldCoin} alt="gold-coin" className="rewards-center-buy-contents-gold-icon" />
                      <div className="rewards-center-buy-contents-gold">
                        {s?.defaultPrice?.value.toLocaleString(defaultLocaleString)} Gold
                      </div>
                    </div>
                    <button 
                      onClick={() => onClickBuy(s, i)} 
                      className={classNames(
                        "rewards-center-buy-contents-button",
                        {"rewards-center-buy-contents-button-disabled": goldBalance < s?.defaultPrice?.value || 
                          s?.supply?.total <= 0}
                      )}
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  )
};
