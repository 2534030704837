import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './ChatThread.scss';

const ChatThreadHeader = ({ imageUrl, name }) => (
  <div className="chat-thread-header">
    <Link to="/messages" className="chat-thread-header-back">
      <ArrowBackIosNewRoundedIcon />
    </Link>
    <div className="chat-thread-header-info">
      <div className="chat-thread-header-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
      <span className="chat-thread-header-name">{name}</span>
    </div>
  </div>
);

export default ChatThreadHeader;