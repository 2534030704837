import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import { OrderActivate } from './OrderActivate';
import {
  absoluteValue,
  activateKind,
  authSteps,
  backToGamePassPageText,
  backToHomeText,
  cardsKind,
  defaultQuantity,
  defaultTimeoutMS,
  firebaseEvents,
  gamePassActivateAndPlayText,
  gamePassCheckoutCTA,
  gamePassContinueText,
  gamePassCTA,
  gamePassGamesCTA,
  gamePassInventoryCTA,
  gamePassPlayText,
  gamePassSetPinAndContinueText,
  gamePassViewInventoryText,
  gamePassViewInventoryTextDetailed,
  onBoardingData,
  orderCompleted,
  orderProcessing,
  paymentFailed,
  retryPaymentText,
  status,
  storageKeys,
  successStatus,
  transactionIdText,
  welcomeGamePassBundleSkus
} from '../../constants';
import { 
  activateGamePass, 
  activateGamePassAndBooster, 
  setAuthStep, 
  setLoading
} from '../../actions';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import success from '../../assets/spend/payment-success.png';
import processing from '../../assets/spend/payment-process.png';
import failed from '../../assets/spend/payment-failed.png';

export const OrderStatus = ({ details, inventory }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onboardingFlag = Cookies.get(storageKeys.isLoggedInTutorial);
  const checkoutUrl = Cookies.get(storageKeys.checkoutUrl);
  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const tokenData = jwt_decode(sessionToken);

  let inventoryButtonText = onboardingFlag ? gamePassActivateAndPlayText : gamePassViewInventoryText;
  if (details.status !== status.completed) {
    if (onboardingFlag) {
      if (tokenData?.claims?.hasPIN) {
        inventoryButtonText = gamePassContinueText;
      } else {
        inventoryButtonText = gamePassSetPinAndContinueText;
      }
    } else {
      inventoryButtonText = gamePassViewInventoryTextDetailed;
    }
  }

  const backButtonText = details.status !== status.completed ? backToHomeText : gamePassPlayText;
  const successText = details.status !== status.completed ? orderProcessing : orderCompleted;

  const handleBack = () => {
    let route = details.status !== status.completed ? '/' : gamePassGamesCTA;
    if (onboardingFlag) {
      route = `${gamePassCTA}?onboard=${onBoardingData.flags.modal}`;
      Cookies.remove(storageKeys.isLoggedInTutorial);
    }
    navigate(route);
  }

  const handleActivateAndPlay = (data) => {
    if (!isEmpty(inventory)) {
      for (const item of inventory) {
        if (item.subKind === data.sku && item.quantity >= data.quantity) {
          dispatch(activateGamePass(data));
          return;
        }
      }
    } else {
      if (details.status === status.pending) {
        if (!tokenData?.claims?.hasPIN) {
          dispatch(setAuthStep(authSteps.pin));
        } else {
          const url = `${gamePassCTA}?${onBoardingData.onboard}=${onBoardingData.flags.modal}`;
          navigate(url);
        }
      }
    }
    if (data.lastItem) {
      dispatch(setLoading(false));
      dispatch(activateGamePassAndBooster(true));
    }
  }

  const goToInventory = () => {
    if (details.status === status.completed
      && !isEmpty(details)) {
      const pathname = window.location.pathname;
      const pathnameArr = pathname.split('/').slice(1);
      const paymentMethod = pathnameArr[1].toUpperCase();
      const pageUrl = `${pathname}${window.location.search}`;
      logFirebaseEventWithTimestamp(
        firebaseEvents.homeGamePassBuyActivate, {
          sku_name: details.details.pendingItems.map(i => i.name).join(', '),
          payment_method: paymentMethod,
          page_url: pageUrl
        });
    }
    if (onboardingFlag) {
      welcomeGamePassBundleSkus.forEach((item, index) => {
        setTimeout(() => {
          const kind = item.includes(cardsKind.gamePass) ? activateKind.gamePass : activateKind.goldBoost;
          const itemData = {
            sku: item,
            quantity: defaultQuantity,
            kind,
            lastItem: welcomeGamePassBundleSkus.length - 1 === index,
            isOnboarding: true
          };
          handleActivateAndPlay(itemData);
        }, index * defaultTimeoutMS);
      });
    } else {
      navigate(gamePassInventoryCTA);
    }
  }

  useEffect(() => {
    if (!isEmpty(details)) {
      const pathname = window.location.pathname;
      const pathnameArr = pathname.split('/').slice(1);
      const paymentMethod = pathnameArr[1]?.toUpperCase();
      const pageUrl = `${pathname}${window.location.search}`;
      const qty = {};
      details.details.pendingItems.map(i => {
        return qty[i.skuKind] = i.quantity;
      });
      const amountValue = details.amount?.value;
      const amount = amountValue?.indexOf('-') === 0 ? amountValue.slice(1) : amountValue;
      let event = firebaseEvents.buyProcessing;
      if (details.status === status.completed) {
        event = firebaseEvents.buySuccess;
      } else if (details.status === status.failed) {
        event = firebaseEvents.buyFailed;
      }
      logFirebaseEventWithTimestamp(
        event, {
          sku_name: details.details.pendingItems.map(i => i.name).join(', '),
          payment_method: paymentMethod,
          page_url: pageUrl,
          amount,
          qty: JSON.stringify(qty),
          currency: details.amount?.currency
        });
    }
  }, [details])

  let statusImage = failed;
  if (details.status === status?.completed) {
    statusImage = success;
  } else if (details.status === status?.pending) {
    statusImage = processing;
  }

  const updatedTitle = details.status === status.failed ? paymentFailed : successText;

  const handleRetryPayment = () => {
    if (checkoutUrl && onboardingFlag) {
      window.location.href = checkoutUrl;
    } else {
      navigate(gamePassCheckoutCTA);
    }
  }

  useEffect(() => {
    return () => {
      Cookies.remove(storageKeys.checkoutUrl);
      dispatch(activateGamePassAndBooster(false));
    };
  }, [dispatch])

  return (
    <Fragment>
      {!isEmpty(details) && 
        <div className="payment-purchase">
          <div className="d-flex flex-column gap-2 align-items-center flex-grow-1 justify-content-center w-100">
            <div className="payment-purchase-wrapper">
              <img
                className="payment-purchase-success"
                src={statusImage}
                alt="success-icon"
              />
              <div className="payment-purchase-success-text">{updatedTitle}</div>
              {details.status !== status.failed && 
                <div className="payment-purchase-success-id">
                  {transactionIdText} {details.id}
                </div>}
              {details.status === status.pending &&
                <div className="payment-purchase-success-note mt-3">{successStatus.orderDescription}</div>}
              {details.failedReason &&
                <div className="payment-purchase-success-note">{details.failedReason}</div>}
              {details.status !== status.failed && 
                <div className="payment-purchase-box">
                  {details.details.pendingItems.map((i, iIdx) => (
                    <div key={`Product_${iIdx}_${i.productId}`} className="d-flex gap-3 align-items-center pb-4">
                      <img className="payment-purchase-box-card" src={i.imageUrl} alt="card"/>
                      <div className="d-flex flex-column gap-2">
                        <div className="payment-purchase-box-text">{i.name} {`(${i.quantity}x)`}</div>
                        <div className="d-flex gap-1 align-items-center">
                          {i.subTotal.currencyIconUrl && 
                            <img className="payment-purchase-box-icon" 
                              src={i.subTotal.currencyIconUrl} 
                              alt="mgo-coin"
                            />}
                          {i.subTotal.text && 
                            <div className="payment-purchase-box-price">{i.subTotal.text}</div>}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="payment-purchase-box-bottom">
                    {!isEmpty(details.amount) && 
                      <div className="payment-purchase-box-total">
                        <div>Total</div>
                        <div>{details.amount.currency} {absoluteValue(details.amount.value)}</div>
                      </div>}
                  </div>
                </div>}
              </div>
            {/* Hide Inventory UI, UI access to inventory and all redirections to it
              {details.status !== status.failed &&
                details.status === status.completed &&
                <OrderActivate 
                  inventory={inventory} 
                  pendingItems={details?.details?.pendingItems} 
                  isMultipleActivate
                />} */}
          </div>
            <div>
              {details.status !== status.failed ?
                <div className="w-100 d-flex flex-column align-items-center">
                  <div className="checkout-purchase-button">
                    {/* Hide Inventory UI, UI access to inventory and all redirections to it
                      <button 
                        onClick={goToInventory}
                        className="checkout-purchase-button-activate">
                        {inventoryButtonText}
                      </button> */}
                    <button
                      onClick={handleBack} 
                      className="checkout-purchase-button-activate">
                      {backButtonText}
                    </button>
                  </div>
                </div> : 
                <div className="w-100 d-flex flex-column align-items-center">
                  <div className="checkout-purchase-button">
                    <button 
                      onClick={handleRetryPayment}
                      className="checkout-purchase-button-activate"
                    >
                      {retryPaymentText}
                    </button>
                  </div>
                </div>}
            </div>
        </div>}
    </Fragment>
  );
}