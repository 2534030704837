import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { gamePassGamesCTA } from '../../constants';
import './GamePassGame.scss';

const GamesList = ( {games} ) => (
  <div className="gamepass-game-list-container">
    {games.length > 1 && <h6 className="gamepass-game-title"> Other Games </h6>}
    {games &&
     !isEmpty(games) && 
     games.map((game, gIdx) => (
      <button 
        className="gamepass-game-games-container" 
        key={gIdx}
        onClick={() => window.location.href = `${gamePassGamesCTA}/${game.id}`}>
        <img 
          src={game.imageUrl} 
          alt={`${game.slug}`} 
          className="gamepass-game-games-image"
        />
        <div className="gamepass-games-game-info">
          <div className="gamepass-games-game-genre">
          {game.genre}
          </div>
          <div className="gamepass-game-name">
          {game.name}
          </div> 
        </div>
      </button>
    ))}
  </div>
);

export default GamesList;