import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { rewardDetailsTexts } from '../../constants';
import './RewardDetails.scss';

export const RewardDetailsRedeemButton = ({ onClick }) => (
  <div className="reward-details-redeem">
    <button
      className="reward-details-redeem-button"
      onClick={onClick}
    >
      Mark as Redeemed
    </button>
    <div className="reward-details-mark-as-redeemed-warning">
      <ErrorIcon sx={{ fontSize: 20 }} />
      <div className="reward-details-mark-as-redeemed-warning-text">
        {rewardDetailsTexts.warning}
      </div>
    </div>
  </div>
);