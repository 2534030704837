import { takeLatest, call, put, all } from 'redux-saga/effects';
import { requestUserProfile } from '../api/menu';
import { requestUserProfilePage, requestUserProfileFields } from '../api/profile';
import { GET_USER_PROFILE } from '../actions/constants';
import {
  setLoading,
  setUserProfile,
  setSessionExpired,
  renderPageContents,
  setUserProfileErrorStatus,
  setGuest,
  failedRequests,
  redirectToGamePartnerPage,
  userProfileRequested,
  setUserHasGamePass,
  setUserHasGoldBooster,
  setFreeGamePassTimer,
  setGameOverUrl,
  setUserHasOnlyGamePass,
} from '../actions';
import { cardsKind, siteRoutes, storageKeys } from '../constants';

var CryptoJS = require("crypto-js");

const getUserProfileSaga = function* (payload) {
  const { gameOverUrl, getGameOverQueryParams, message } = payload?.payload || '';
  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const hasSessionToken = sessionToken && sessionToken.length > 0;
  const redirectUri = sessionStorage.getItem(storageKeys.redirectUri);
  const clientId = sessionStorage.getItem(storageKeys.clientId);
  const { pathname } = window.location;
  const login = pathname.includes(siteRoutes.login) || pathname.includes(siteRoutes.pin) || pathname.includes('');
  let freeTimer;
  let gamePassTimer;
  try {
    const response = yield call(requestUserProfile, sessionToken, 'v3');
    if (response.status >= 200 && response.status < 400) {
      const { entitlements } = response?.data?.d || [];
      // this if for game over screen of games
      for (const item of entitlements) {
        if (item?.kind === cardsKind.gamePassFree) {
          freeTimer = item?.duration?.value ?? 0;
        }
        if (item?.kind === cardsKind.gamePass) {
          gamePassTimer = item?.duration?.value ?? 0;
        }
      }
      if (gameOverUrl) {
        gameOverUrl.search = getGameOverQueryParams(message, freeTimer, gamePassTimer)?.toString();
      }
      yield put(setGameOverUrl(gameOverUrl));

      yield call(getUserProfileEntitlementsSaga, entitlements);
      yield call(getUserProfilePageSaga, response.data.d);
    } else {
      yield put(setLoading(false));
    }
    
    if (redirectUri && clientId) {
      yield put(redirectToGamePartnerPage(true));
    }

    yield put(userProfileRequested(true));
  } catch (e) {
    if (e.response && e.response.data) {
      yield put(setUserProfileErrorStatus(e.response?.status));
      yield put(userProfileRequested(true));
      if (gameOverUrl) {
        gameOverUrl.search = getGameOverQueryParams(message, freeTimer)?.toString();
      }
      yield put(setGameOverUrl(gameOverUrl));
      if (hasSessionToken) yield put(setSessionExpired(true));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
      if (e.response?.status >= 500 || login) yield put(setLoading(false));
      if (login) yield put(renderPageContents(true));
    }
  }
}

const getUserProfilePageSaga = function* (userProfile) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserProfilePage, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const updatedUserProfile = { ...userProfile, ...response.data.d };
      yield call(getUserProfilePageWithTutorialSaga, updatedUserProfile);
    } else {
      yield put(setLoading(false));
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserProfilePageWithTutorialSaga = function* (userProfile) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserProfileFields, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const { showTutorial, profile } = response.data.d;
      const personalInfo = profile.form.find(f => f.fields.map(ff => ff.field).includes('mobnum'));
      const mobileNumber = personalInfo.fields.find(f => f.field === 'mobnum').value;
      const encryptedMobileNumber = CryptoJS.AES.encrypt(JSON.stringify(mobileNumber), 'mobilenumber').toString();
      const updatedUserProfile = { ...userProfile, mobnum: mobileNumber, showTutorial };
      if (!localStorage.getItem(storageKeys.username)) localStorage.setItem(storageKeys.username, updatedUserProfile.username);
      if (!localStorage.getItem(storageKeys.mobileNumber)) localStorage.setItem(storageKeys.mobileNumber, encryptedMobileNumber);
      yield put(setGuest(false));
      yield put(setUserProfile(updatedUserProfile));
    }
    yield put(renderPageContents(true));
    yield put(setLoading(false));
  } catch (e) {
    yield put(renderPageContents(true));
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserProfileEntitlementsSaga = function* (entitlements) {
  try {
    let hasBooster = false;
    let hasGamePass = false;
    let hasOnlyGamePass = false;
    for (const item of entitlements) {
      if (item?.kind.includes(cardsKind.gamePass) &&
        item?.duration?.value !== 0) {
        hasGamePass = true;
      }
      if (item?.kind === cardsKind.gamePass &&
        item?.duration?.value !== 0) {
        hasOnlyGamePass = true;
      }
      if (item?.kind.includes(cardsKind.goldBoost) && 
        item?.duration?.value !== 0) {
        hasBooster = true;
      }
      if (item?.kind.includes(cardsKind.gamePassFree)) {
        yield put(setFreeGamePassTimer(item?.duration?.value));
      }
      yield put(setUserHasGamePass(hasGamePass));
      yield put(setUserHasOnlyGamePass(hasOnlyGamePass));
      yield put(setUserHasGoldBooster(hasBooster));
    }
  } catch(e) {}
}

export default function* menuSaga() {
  yield all([
    takeLatest(GET_USER_PROFILE, getUserProfileSaga)
  ]);
}