import React from 'react';
import { Col } from 'react-bootstrap';
import './Partners.scss';

export const PartnersThumbnail = ({ code, name, imageUrls, isMobile }) => (
  <Col xs="12" sm="12" md="6" className="partners-thumbnails-item">
    <div className="partners-thumbnails-item-header">
      <img
        src={imageUrls?.logoUrl}
        className="partners-thumbnails-item-logo"
        alt={`Logo_${code}`}
      />
      <div className="partners-thumbnails-item-title">{name}</div>
    </div>
    <img
      src={isMobile ? imageUrls['bannerUrl-mobile'] : imageUrls?.bannerUrl}
      className="partners-thumbnails-item-banner"
      alt={`Banner_${code}`}
    />
  </Col>
);