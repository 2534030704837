import { 
  crypto,
  goldCurrency, 
  mgcCurrency, 
  phpCurrency, 
  usdCurrency
} from '../constants';

export const getTransactionAssets = (tabs, assetText) => {
  const assetTypes = {
    [tabs[0]]: [mgcCurrency],
    [tabs[1]]: [phpCurrency, usdCurrency],
    [tabs[2]]: [goldCurrency],
  };
  if (assetTypes.hasOwnProperty(assetText)) {
    return assetTypes[assetText];
  }
  return [crypto];
};