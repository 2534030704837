import {
  RESET_STATE,
  SET_TOURNAMENTS
} from '../actions/constants';

const tournamentsState = {
  tournaments: {}
};

const tournamentsReducer = (state = tournamentsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return tournamentsState;
    case SET_TOURNAMENTS:
      return {
        ...state,
        tournaments: action.tournaments
      };
    default:
      return state;
  }
}

export default tournamentsReducer;