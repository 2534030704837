import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Col, Row } from 'react-bootstrap';
import { 
  LayoutContent, 
  PageTitle, 
  Featured, 
  GamePassPlayPopup
} from '../Components';
import { ImagePreview, Banner, Toast } from '../Commons';
import { GamePassGamesSection } from '../GamePassGames/GamePassGamesSection';
import GamesList from './GamesList';
import GameDetails from './GameDetails';
import Gallery from '../Game/Gallery';
import HowToPlay from './HowToPlay';
import { 
  getGamePassTournaments, 
  getGamePassTournamentsDetails, 
  getUserProfile, 
  setDisplayToast, 
  setGamePassPlayGame
} from '../../actions';
import { 
  defaultTimeoutMS, 
  gamePassGameMechanics
} from '../../constants';
import { 
  displayLoginToastSelector,
  gamePassTournamentsDetailsSelector,
  gamePassTournamentsSelector,
  guestSelector,
  userHasGamePassSelector
} from '../../selectors';
import './GamePassGame.scss';

export const GamePassGame = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allGames = useSelector((state) => gamePassTournamentsSelector(state));
  const gameDetails = useSelector((state) => gamePassTournamentsDetailsSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const isGuest = useSelector(state => guestSelector(state));
  const hasGamePass = useSelector(state => userHasGamePassSelector(state));
  
  const gameId = window.location.pathname.split('/').slice(1)[2];

  const [game, setGame] = useState({});
  const [imageUrl, setImageURL] = useState();
  const [description, setDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [gameInfo, setGameInfo] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [games, setGames] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);

  useEffect(() => {
    dispatch(getGamePassTournaments());
    dispatch(getGamePassTournamentsDetails(gameId));
    dispatch(getUserProfile());
  }, [dispatch, gameId])

  useEffect(() => {
    setGame(gameDetails);
  }, [gameDetails])

  useEffect(() => {
    const description = game.shortDescription || gamePassGameMechanics;
    setDescription(description);

    const longDescription = game.longDescription && game.longDescription.texts ? game.longDescription.texts : gamePassGameMechanics;
    setLongDescription(longDescription);

    let newValue = []
    if (isEmpty(game.genres)) return;
    for (const item of game.genres) {
      newValue.push(item);
    }
    const info = [{
      label: 'Genres',
      value: newValue.join(', ')
    }]
    setGameInfo(info);

    if (isEmpty(game.imageUrls)) return;
    setImageURL(game.imageUrls[0].bannerUrl);

    const filteredGames = allGames.filter(g => g.id !== gameId);
    setGames(filteredGames);

  }, [game, allGames, gameId])

  useEffect(() => {
    dispatch(setGamePassPlayGame(false));
    setToastOpen(true);
  }, [dispatch, toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const onClickButton = () => {
    if (!hasGamePass && !isGuest) {
      dispatch(setGamePassPlayGame(true));
    } else {
      navigate(`/play/${game.slug}/${game.id}`);
    }
  }

  return (
    <LayoutContent outerClassName="gamepass-game">
      <PageTitle title={game.gameName} />
      <GamePassGamesSection
        backButtonRoute
        backRoute={() => navigate(-1)}   
        className="gamepass-game-section"
        showCoins
      /> 
      <Featured fluid>
        <Banner 
          imageUrl={imageUrl}
          buttonAnchor
          imageClickable
        />
      </Featured>
      <Row>
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
          <GameDetails
            {...game}
            gameName={game.gameName}
            description={description}
            buttonText="Play Game"
            onClickButton={onClickButton} 
          />
          <HowToPlay mechanics={longDescription} />
          {!isEmpty(game.imageUrls) && 
            <Gallery 
              screenshots={game.imageUrls[0].screenshots} 
              title="Gallery"
              sectionClassName="gamepass-game-gallery"
              openImage={image => setImagePreviewUrl(image)}
              gamePassGame
            />}
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <GamesList games={games} />
        </Col>
      </Row>
      {imagePreviewUrl && 
        <ImagePreview url={imagePreviewUrl} openImage={image => setImagePreviewUrl(image)} gamepassGame />}
      <GamePassPlayPopup {...game} />
      {toast && 
        <Toast toast={toast} open={toastOpen} hide={closeToast} />}
    </LayoutContent>
  );
}