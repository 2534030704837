import { RESET_STATE, SET_GAME_DETAILS } from '../actions/constants';

const gamesState = {
  selected: {}
};

const gamesReducer = (state = gamesState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return gamesState;
    case SET_GAME_DETAILS:
      return {
        ...state,
        selected: action.gameDetails
      };
    default:
      return state;
  }
}

export default gamesReducer;