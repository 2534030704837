import {
  SET_DISPLAY_TOAST,
  SET_USER_COMMUNITIES,
  SET_USER_COMMUNITY,
  SET_GUILD_APPLICATION_FORM,
  RESET_STATE,
} from '../actions/constants';

const collectionsState = {
  sections: [],
  toast: undefined,
  form: undefined,
  totalWeight: 0,
};

const communitiesReducer = (state = collectionsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return collectionsState;
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case SET_USER_COMMUNITIES:
      return {
        ...state,
        sections: action.userCommunities
      };
    case SET_USER_COMMUNITY:
      return {
        ...state,
        selected: action.userCommunity
      };
    case SET_GUILD_APPLICATION_FORM:
      let tw = 0;
      if (action.guildApplicationForm) {
        tw = action.guildApplicationForm.fields.reduce((a, b) => a + b.weight, 0);
      }
      return {
        ...state,
        form: action.guildApplicationForm,
        totalWeight: tw
      };
    default:
      return state;
  }
}

export default communitiesReducer;