import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { 
  authSteps,
  minWidths, 
  onboardingParams, 
  onboardingTexts, 
  storageKeys, 
  wenLamboSpinTheWheelMechanicsCTA
} from '../../../constants';
import { showGamePassCheckoutSelector } from '../../../selectors';
import { 
  displayRegistrationForm, 
  setAuthStep, 
  showGamePassCheckout 
} from '../../../actions';
import { useQuery } from '../../../hooks';
import animatedBanner from '../../../assets/onboarding/animated-banner.gif';
import './BuyBoosterModal.scss';

const BuyBoosterModal = ({ open, hide }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  const [bannerIsLoaded, setBannerIsLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  const showCheckout = useSelector(state => showGamePassCheckoutSelector(state));

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const isTablet = useMediaQuery({ minHeight: minWidths.desktopSM });
  const isMobileOnboarding = query.get(onboardingParams.isMobileOnboarding);

  const onClose = () => {
    Cookies.set(storageKeys.isBuyBoosterShown, true);
    dispatch(setAuthStep(authSteps.login));
    dispatch(displayRegistrationForm(true));
    setBannerIsLoaded(false);
    hide();
  }

  const heightOffset = isDesktop ? 200 : 120;

  const getWindowHeight = () => {
    setHeight(window.innerHeight);
  }

  const handleModalRedirect = () => {
    navigate(wenLamboSpinTheWheelMechanicsCTA);
  }

  useEffect(() => {
    getWindowHeight();
    window.addEventListener('resize', getWindowHeight);
    return () => {
      window.removeEventListener('resize', getWindowHeight);
    }
  }, [])

  let modalImageHeight = `${height - heightOffset}px`;
  if (isTablet) modalImageHeight = '100%';
  const modalImageStyle = { height: modalImageHeight };

  useEffect(() => {
    if (Boolean(isMobileOnboarding)) {
      dispatch(showGamePassCheckout(true));
    }
  }, [dispatch, isMobileOnboarding])

  useEffect(() => {
    if (showCheckout) {
      dispatch(setAuthStep());
    }
  }, [dispatch, showCheckout])

  const handleShow = () => {
    dispatch(showGamePassCheckout(true));
  }

  return (
    <Modal
      backdrop
      centered
      show={open}
      onHide={onClose}
      className="buy-booster-container"
      contentClassName="buy-booster"
      dialogClassName="buy-booster-dialog"
      backdropClassName="buy-booster-bg"
      enforceFocus={false}
    >
      <Modal.Body className="buy-booster-body">
        <div className="buy-booster-wrapper">
          <div className="position-relative">
            <img 
              onClick={handleShow} 
              onLoad={() => setBannerIsLoaded(true)} 
              src={animatedBanner} 
              alt="onboarding-banner"
              className="buy-booster-banner"
              style={modalImageStyle}
            />
            {bannerIsLoaded &&
              <CloseRoundedIcon 
                onClick={onClose} 
                className="buy-booster-close" 
              />}
          </div>
          {bannerIsLoaded &&
            <div 
              className="buy-booster-body-text" 
              onClick={handleModalRedirect}
            >
              {onboardingTexts.howItWorks}
            </div>}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BuyBoosterModal;