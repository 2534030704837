import React, { Fragment } from 'react';
import './FloatingContainer.scss';

export const FloatingContainer = ({ isGuest, isTutorial, children }) => (
  <Fragment>
    {!isGuest &&
     !isTutorial &&
      <div className="floating-container">
        {children}
      </div>}
  </Fragment>
);