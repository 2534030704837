import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { LaunchpadCollection } from './LaunchpadCollection';
import { launchpadCollectionSelector } from '../../selectors';
import { getLaunchpadCollection } from '../../actions';
import { firebaseEvents, mgcCurrency } from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';

export const Launchpad = () => {
  const dispatch = useDispatch();

  const collection = useSelector(state => launchpadCollectionSelector(state));

  useEffect(() => {
    dispatch(getLaunchpadCollection());
    logFirebaseEventWithTimestamp(
      firebaseEvents.nftMarketplacePage, {
        payment_method: mgcCurrency
      });
  }, [dispatch]);

  return (
    <>
      {collection &&
        !isEmpty(collection) &&
        <LaunchpadCollection collection={collection} />}
    </>
  );
}