import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { LayoutContent, PageTitle } from '../Components';
import { LaunchpadItemSections } from './LaunchpadItemSections';
import { LaunchpadNFTMint } from './LaunchpadNFTMint';
import './LaunchpadItem.scss';

export class LaunchpadItem extends Component {
  constructor(props) {
    super(props);

    const route = window.location.pathname.split('/').slice(1);
    const id = route[route.length - 1];

    this.state = {
      itemId: id,
      shareIsVisible: false
    };
  }

  componentDidMount() {
    this.props.getLaunchpadCollectionItem(this.state.itemId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collection !== this.props.collection) {
      this.setState({ item: this.props.collection.find(c => c.id === parseInt(this.state.itemId)) });
    }
  }

  exitShare = () => {
    this.setState({ shareIsVisible: false });
  }

  openShare = () => {
    this.setState({ shareIsVisible: true });
  }

  render() {
    const { mintingNFTSuccess, collectionItem, nftData } = this.props;
    const { shareIsVisible } = this.state;

    if (!isEmpty(collectionItem)) {
      return (
        <>
          <LayoutContent outerClassName="launchpad-item-container">
            <PageTitle title={collectionItem.name}/>
            {isEmpty(nftData) && 
              !mintingNFTSuccess && 
              <>
                <Link to="/launchpad" className="launchpad-item-back">
                  <ArrowBackIosNewRoundedIcon />
                </Link>
                <div className="launchpad-item">
                <div className="launchpad-item-image-container">
                  <div className="launchpad-item-image-wrapper">
                    <img
                      className="launchpad-item-image"
                      src={collectionItem.imageUrls[0].image_url}
                      alt="item-icon"
                    />
                  </div>
                </div>
                <LaunchpadItemSections item={collectionItem} />
              </div>
            </>}
          </LayoutContent>
          <LaunchpadNFTMint
            shareIsVisible={shareIsVisible}
            openShare={this.openShare}
            exitShare={this.exitShare}
            mintingNFTSuccess={mintingNFTSuccess}
            urlText={collectionItem.slug}
          />
        </>
      );
    }

    return null;
  }
}