import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AvailablePaymentMethods } from './AvailablePaymentMethods';
import SelectCurrencyDropdown from '../SelectCurrencyDropdown/SelectCurrencyDropdown';
import {
  defaultPaymentMethod,
  mgcCurrency,
  paymentMethodsData,
  selectPaymentMethodText,
  wallets
} from '../../../constants';
import { setWalletAddressFormat } from '../../../utils/setWalletAddressFormat';
import mgoCoin from '../../../assets/icons/mgo-coin.png';
import './GamePassCheckoutModal.scss';

export const ModalContent = ({
  checkoutContents,
  errors,
  showPaymentMethods,
  selectedPaymentMethod,
  handleSelectPaymentMethod,
  buttonClassName,
  buttonText,
  handleCheckoutPay,
  checkoutForm,
  checkoutCheckbox,
  isGameOverCheckout,
  address,
  connector,
  currency
}) => {
  let updatedContent = selectedPaymentMethod;
  if (selectedPaymentMethod?.code === wallets.walletConnect.name) {
    const updatedAddress = address ? setWalletAddressFormat(address) : '';
    const connectorName = connector?.name || '';
  
    updatedContent = {
      ...selectedPaymentMethod,
      description: updatedAddress,
      name: connectorName,
      imageUrl: connectorName === wallets.metaMask.name ? paymentMethodsData?.default.imageUrl : selectedPaymentMethod?.imageUrl
    };
  }
  return (
    <div className="game-pass-checkout-contents">
      <div className="game-pass-checkout-item">
        {checkoutContents?.items?.map((i, iIdx) => {
          const mgc = i?.priceOptions?.filter(p => p?.currency.includes(mgcCurrency))[0];
          return (
            <div 
              key={`CheckoutItems_${iIdx}`} 
              className="game-pass-checkout-item-wrapper"
            >
              <img 
                src={i.imageUrl} 
                alt="game-pass-bundle" 
                className="game-pass-checkout-item-image" 
              />
              <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                <div className="game-pass-checkout-item-title">
                  {i.name}
                </div>
                <div className="game-pass-checkout-item-amount-wrapper">
                  <div className="game-pass-checkout-item-amount">
                    {i.price?.text}
                  </div>
                  {mgc &&
                    <div className="game-pass-checkout-item-amount-mgc">
                      <img 
                        src={mgoCoin} 
                        alt={mgc?.currency} 
                        className="game-pass-checkout-item-amount-mgc-icon" 
                      />
                      <div>{mgc?.value} {mgc?.currency}</div>
                    </div>}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {checkoutForm}
      <div className="position-relative">
        {isGameOverCheckout &&
          <div className="game-pass-checkout-method-label">{selectPaymentMethodText}</div>}
        <div 
          className={classNames(
            "game-pass-checkout-method", {
              "game-pass-checkout-method-dropdown-open": showPaymentMethods?.dropdown,
              "game-pass-checkout-method-game-over": isGameOverCheckout,
              "game-pass-checkout-method-error": errors?.paymentMethod
            }
          )}
          onClick={handleSelectPaymentMethod}
        >
          {isEmpty(updatedContent) ?
            <div className="game-pass-checkout-method-title">
              {selectPaymentMethodText}
            </div> :
            <div className="d-flex align-items-center gap-3">
              <div className="game-pass-checkout-method-image-wrapper">
                {updatedContent?.imageUrl &&
                  <img
                    className={classNames(
                      "game-pass-checkout-method-image",
                      {"game-pass-checkout-method-image-small": updatedContent?.code === defaultPaymentMethod || 
                        updatedContent?.code === wallets.walletConnect.name}
                    )}
                    src={updatedContent?.imageUrl}
                    alt={updatedContent?.code}
                  />}
              </div>
              <div className="d-flex align-items-start flex-column justify-content-start">
                <div className="game-pass-checkout-method-title">{updatedContent?.name}</div>
                <div className="game-pass-checkout-method-description">{updatedContent?.description}</div>
              </div>
            </div>}
            <ArrowDropDownIcon className="game-pass-checkout-method-arrow" />
          {showPaymentMethods?.dropdown &&
            <AvailablePaymentMethods
              checkoutContents={checkoutContents}
              hasError={errors?.paymentMethod}
              isGameOverCheckout={isGameOverCheckout}
            />}
        </div>
      </div>
      {updatedContent?.code === wallets.walletConnect.name &&
        !isEmpty(currency) &&
        <SelectCurrencyDropdown />}
      {checkoutCheckbox}
      <button 
        onClick={handleCheckoutPay} 
        className={buttonClassName}
      >
        {buttonText}
      </button>
    </div>

  )
};