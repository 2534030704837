import React, { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Grid } from '../Commons';
import { PageTitle } from '../Components';
import { setHideFooter, setHideHeader } from '../../actions';
import { minWidths } from '../../constants';

const WalletViewAll = ({
  isCollectibles,
  handleSelectedItem,
  showCollectibles,
  collections,
  walletEmpty,
  hide,
  handleShowCollectibles
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.layout.userProfile.uid);
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  useEffect(() => {
    const location = window.location.search;
    const queryParams = new URLSearchParams(location);
    const collectiblesIsVisible = queryParams.get("show");
    if (collectiblesIsVisible) {
      handleShowCollectibles(true);
    } else {
      handleShowCollectibles(false);
    }
  }, [location, handleShowCollectibles])

  useEffect(() => {
    if (!isDesktop) {
      dispatch(setHideFooter(true));
      dispatch(setHideHeader(true));
    }

    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideHeader(false));
    }
  }, [dispatch, isDesktop])

  return (
    <div className="collectibles-view">
      <PageTitle title="My Collections" />
      <div className="collectibles-view-header">
        <ArrowBackIosNewRoundedIcon onClick={() => navigate(-1)} className="collectibles-view-back"/>
        <div className="collectibles-view-title">My Collections</div>
      </div>
      {
        isEmpty(collections) ? <div className="collection-list-empty">{walletEmpty}</div>
        : <Grid 
            isCollectibles={isCollectibles} 
            hide={hide} 
            handleSelectedItem={handleSelectedItem} 
            items={collections}
            keyPrefix="Wallet" 
            className="collection-list" 
            experience="wallet" 
            showCollectibles={showCollectibles}
            userId={userId}
          />
      }
    </div>
  )
}

export default WalletViewAll