import { defaultWebAppDomain } from '../constants';

export const setShareText = (text, urlText) => (
  `Check out the ${text} NFT I minted from MetaverseGo! Get yours now at ${defaultWebAppDomain}/launchpad/${urlText}`
);

export const setSTWShareText = (prize, referralLink) => {
  let article = '';
  if (prize &&
    !(/[0-9]/i.test(prize[0]))) {
    if (/[aeiou]/i.test(prize[0])) {
      article = 'an ';
    } else {
      article = 'a ';
    }
  }
  const prizeStr = `${article}${prize}`;
  return `I won ${prizeStr} by playing games at Wen Lambo by MetaverseGo! Sign up at metaversego.gg and start playing today for a chance to become a winner like me! Over PHP50M in prizes are up for grabs. #WenLambo #MetaverseGo \nRegister through my referral link: ${referralLink}`;
}