import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { useDispatch, useSelector } from 'react-redux';
import { guestSelector } from '../../selectors';
import { unauthorizedRedirect } from '../../actions';
import { authSteps, firebaseEvents, storageKeys } from '../../constants';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './Menu.scss';

export const MenuItem = ({ name, route, selected, selectMenuItem, unreadMessages, openMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isGuest = useSelector(state => guestSelector(state));

  const isSelected = selected === name;
  const newName = kebabCase(name.toLowerCase());
  const isHelpAndSupport = route.includes('help');

  const showTutorial = () => {
    if (!isGuest) {
      window.location.href = '/';
      localStorage.setItem(storageKeys.isNew, true);
    }
  };

  const handleEvent = () => {
    logFirebaseEvent(firebaseEvents.supportFromSocial);
  }

  const handleRoute = () => {
    if (isHelpAndSupport && isGuest) {
      window.location.href = route;
    } else if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
    } else {
      if (isHelpAndSupport) {
        handleEvent();
        window.location.href = route;
        return;
      }
      navigate(route);
    }
    openMenu();
  }
  
  return (
    <div
      className={classNames("menu-item", {
        "menu-item-active": isSelected,
        "menu-item-disabled": name === 'Walkthrough' && isGuest
      })}
      onMouseOver={() => selectMenuItem(name)}
      onMouseOut={() => selectMenuItem("")}
      onTouchStart={() => selectMenuItem(name)}
      onTouchEnd={() => selectMenuItem("")}
    >
      {name === "Walkthrough" ?
        <div onClick={showTutorial}>
          <div className={classNames(
            "menu-item-icon", 
            `menu-item-icon-${newName}`,
            {active: (isSelected && !isGuest)}
          )}/>
          <div className={classNames(
            "menu-item-name", {
            "menu-item-name-active": (isSelected && !isGuest)
          })}>
            {name}
          </div>
        </div> :
        <div onClick={handleRoute} className="menu-item-link">
          <div className={classNames(
            "menu-item-icon",
            `menu-item-icon-${newName}`,
            {active: isSelected})
          } />
          <div className={classNames(
            "menu-item-name",
            {"menu-item-name-active": isSelected}
          )}>
            {name}
          </div>
          {unreadMessages > 0 &&
            name === "Messages" &&
            !isGuest &&
            <div className="menu-item-messages">
              {unreadMessages}
            </div>}
        </div>}
    </div>
  );
};
