import React from 'react';
import { Stats } from '../Components';
import './NFTItem.scss';

export const NFTItemDetails = ({ loggedIn, sessionExpired, name, description, imageUrl, price, showOrderSummary, loginToBuy }) => {
  // const loginLink = sessionExpired ? '/pin' : '/login';
  // const loggedOut = !loggedIn || (loggedIn && sessionExpired);
  const stat = [{ label: 'Current Price', value: price }];

  return (
    <div className="nft-item">
      <div className="nft-item-image">
        <img src={imageUrl} alt={name} />
      </div>
      <div className="nft-item-info">
        {name && <div className="nft-item-name">{name}</div>}
        {description && <p className="nft-item-description">{description}</p>}
        <Stats stats={stat} xs={12} sm={12} md={12} lg={12} xl={4} fluid noTopSpace />
        {/*
          loggedOut ? <a href={loginLink} className="nft-item-buy-button" onClick={loginToBuy}>Login to Buy</a>
            : <button onClick={showOrderSummary} className="nft-item-buy-button">Buy</button>
        */}
      </div>
    </div>
  );
}