import React from 'react';
import classNames from 'classnames';
import { voucherTexts } from '../../constants';
import './RewardsCenter.scss';

export const VoucherInput = ({ voucherCode, handleVoucherCode, claimItem, error }) => (
  <div className="rewards-center-voucher-input">
    <div className="rewards-center-voucher-input-inner">
      <input
        type="text"
        className="rewards-center-voucher-input-text-field"
        onChange={handleVoucherCode}
        value={voucherCode}
      />
      <button
        className={classNames(
          "rewards-center-voucher-input-button",
          {"rewards-center-voucher-input-button-disabled": voucherCode === ''}
        )}
        onClick={claimItem}
        disabled={voucherCode === ''}
      >
        {voucherTexts.claim}
      </button>
    </div>
    {error !== '' &&
      <div className="rewards-center-voucher-input-error">{error}</div>}
  </div>
);