import cebuanaForm from '../assets/payment-channel-forms/cebuana.jpg';
import mLhuillierForm from '../assets/payment-channel-forms/mlhuillier.png';
import palawanForm from '../assets/payment-channel-forms/palawan.png';

export const paymentInstructions = {
  sevenEleven: {
    title: 'Step-by-Step Instructions',
    steps: [
      {
        title: 'Step 1: Find Nearest Branch',
        details: [
          { text: 'Visit the nearest 7 Eleven branch before the time on the payment code/barcode runs out.' },
          { text: 'Tell the cashier that you would like to make a payment and let them scan the barcode above.' }
        ]
      },
      {
        title: 'Step 2: Show Payment Details',
        details: [
          { text: 'Present the payment code/barcode to the cashier and confirm that the amount is correct.' },
          { text: 'Proceed to make a payment with the amount on your payment code/barcode.' }
        ]
      },
      {
        title: 'Step 3: Transaction Completed',
        details: [
          { text: 'Receive your proof of payment from the cashier.' },
          { text: 'Your transaction is successful.' }
        ]
      }
    ]
  },
  cebuana: {
    title: 'Step-by-Step Instructions',
    steps: [
      {
        title: 'Step 1: Find Nearest Branch',
        details: [
          { text: 'Visit your nearest Cebuana branch before the payment code expires.' },
          { text: 'Tell the cashier/staff that you will be making a Bills Payment to XENDIT.' }
        ]
      },
      {
        title: 'Step 2: Payment Details',
        details: [
          { text: 'You will receive a Transaction Form Slip.' },
          { text: 'Please fill out the following information.',
            sub: [
              { label: 'Transaction Type', value: 'Payment' },
              { label: 'Sender', value: 'Your Name' },
              { label: 'Receiver', value: 'Xendit' },
              { label: 'Control No./Account No./Reference No.', value: 'Payment Code given by merchant' },
              { label: 'Amount', value: 'Amount due of your payment code' }
            ],
            imageUrl: cebuanaForm },
          { text: 'Give the filled out slip to the cashier/staff with your payment (cash only).' }
        ]
      },
      {
        title: 'Step 3: Transaction Completed',
        details: [
          { text: 'You will receive a receipt as proof of payment.' },
          { text: 'Your transaction is successful.' }
        ]
      }
    ]
  },
  palawan: {
    title: 'Step-by-Step Instructions',
    steps: [
      {
        title: 'Step 1: Find Nearest Branch',
        details: [
          { text: 'Visit your nearest Palawan Pawnshop or Palawan Express Pera Padala branch before payment code expires.' },
          { text: 'You may refer to this <a href="https://www.palawanpawnshop.com/branch-finder" target="_blank">branch locator</a> (Pera Padala Authorized Agents only are not yet supported).' },
          { text: 'Tell the cashier/staff that you will be making a Bills Payment (not a remittance).' }
        ]
      },
      {
        title: 'Step 2: Payment Details',
        details: [
          { text: 'You will be given a SEND MONEY FORM (White Form) to fill-up (see sample below).' },
          { text: 'Please fill out the following information.',
            sub: [
              {
                title: 'Sender',
                list: [
                  { label: 'Name', value: 'Your name' },
                  { label: 'Mobile No.', value: 'Your mobile number' }
                ]
              },
              {
                title: 'Receiver',
                list: [
                  { label: 'Name', value: 'DRAGONPAY / Payment Code given by merchant' },
                  { label: 'Amount', value: 'Amount due of your payment code' },
                  { label: 'Purpose of Transaction', value: 'Payment' },
                  { label: 'Relationship to Receiver', value: 'Client' }
                ]
              }
            ],
            imageUrl: palawanForm },
          { text: 'Give the filled out slip to the cashier/staff with your payment (cash only).' }
        ]
      },
      {
        title: 'Step 3: Transaction Completed',
        details: [
          { text: 'You will receive a receipt as proof of payment.' },
          { text: 'Your transaction is successful.' }
        ]
      }
    ]
  },
  mLhuillier: {
    title: 'Step-by-Step Instructions',
    steps: [
      {
        title: 'Step 1: Find Nearest Branch',
        details: [
          { text: 'Visit your nearest M Lhuillier outlet branch before payment code expires.' },
          { text: 'You may refer to this <a href="https://mlhuillier.com/branches" target="_blank">branch locator</a>.' },
          { text: 'Tell the cashier/staff that you will be making a Bills Payment (not a remittance).' }
        ]
      },
      {
        title: 'Step 2: Payment Details',
        details: [
          { text: 'You will be given a Bills Payment Slip to fill-up (see sample below).' },
          { text: 'Please fill out the following information.',
            sub: [
              { label: 'Date', value: 'Date of payment' },
              { label: 'Payment to', value: 'DRAGONPAY' },
              { label: 'Account Name', value: 'Your Name' },
              { label: 'Account Number', value: 'Payment Code given by merchant' },
              { label: 'Contact Number', value: 'Your mobile number' }
            ],
            imageUrl: mLhuillierForm },
          { text: 'Give the filled out slip to the cashier/staff with your payment (cash only).' },
          { text: 'The cashier will charge you an extra fee for their service (see below table). Dragonpay subsidizes or absorbs the first <strong>PHP 10.00</strong> of the fee so you only have to pay the net fee due over-and-above the actual online purchase amount.' },
          { text: 'Below is an illustration of how the extra fee is computed based on sample purchase amounts of PHP 1,000 / 12,000 / 50,000 / 80,000.',
            table: {
              headers: ['Online Purchase', 'Net Fee Due'],
              cells: [
                { purchase: '< PHP 50,000', due: 'PHP 20.00' },
                { purchase: 'PHP 50,000.01 - 100,000', due: 'PHP 50.00' },
                { purchase: 'PHP 100,000.01 - 150,000', due: 'PHP 70.00' },
                { purchase: 'PHP 150,000.01 - 200,000', due: 'PHP 90.00' },
                { purchase: 'PHP 200,000.01 - 250,000', due: 'PHP 110.00' }
              ]
            } }
        ]
      },
      {
        title: 'Step 3: Transaction Completed',
        details: [
          { text: 'You will receive a receipt as proof of payment.' },
          { text: 'Your transaction is successful.' }
        ]
      }
    ]
  },
};