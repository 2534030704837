import React from 'react';
import { FloatingIcon } from '../../Commons';
import { useCountdown } from '../../../hooks/useCountdown';
import {
  gameNightStartTimes,
  gameNightEndTimes
} from '../../../constants';
import floatingIconActive from '../../../assets/discord-daily-boost/floating-icon.png';
import floatingIconInactive from '../../../assets/discord-daily-boost/floating-icon-inactive.png';
import './DiscordDailyBoostIcon.scss';

const DiscordDailyBoostIcon = ({ showDiscordDailyBoost, inactive }) => {
  const handleClick = () => {
    showDiscordDailyBoost();
  }

  const formatTimeVal = (time) => {
    return time > 9 ? time : `0${time}`;
  }

  const handleCountdownVal = ([days, hours, minutes, seconds]) => {
    const hoursVal = formatTimeVal(hours);
    const minutesVal = formatTimeVal(minutes);
    const secondsVal = formatTimeVal(seconds);
    const countdownVal = `${hoursVal}:${minutesVal}:${secondsVal}`;
    return countdownVal;
  }
  
  const dateNow = new Date();
  const scheduledStartTime = new Date();
  const scheduledEndTime = new Date();
  const scheduledTimes = gameNightStartTimes.map((s, sIdx) => {
    scheduledStartTime.setHours(s.hours, s.minutes, s.seconds);
    scheduledEndTime.setHours(gameNightEndTimes[sIdx].hours, gameNightEndTimes[sIdx].minutes, gameNightEndTimes[sIdx].seconds);
    return dateNow >= scheduledStartTime && dateNow < scheduledEndTime;
  });
  const endDateIndexActual = scheduledTimes.indexOf(true);
  const endDateIndex = endDateIndexActual >= 0 ? endDateIndexActual : 0;

  const endDate = dateNow.setHours(gameNightEndTimes[endDateIndex].hours, gameNightEndTimes[endDateIndex].minutes, gameNightEndTimes[endDateIndex].seconds);
  const countdown = useCountdown(endDate);
  const countdownVal = handleCountdownVal(countdown);

  const icon = inactive ? floatingIconInactive : floatingIconActive;

  return (
    <FloatingIcon
      onClick={handleClick}
      src={icon}
      alt="discord-daily-boost"
    >
      {!inactive && 
        <div className="discord-daily-boost-icon-timer">
          {countdownVal}
        </div>}
    </FloatingIcon>
  );
}

export default DiscordDailyBoostIcon;