import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import './Game.scss';

const GameInfo = ({ info, gamepassGame }) => {
  const mdValue = gamepassGame ? 12 : 6;
  const lgValue = gamepassGame ? 12 : 4;
  return (
  <Container fluid className="game-info">
    <Row className="game-info-table">
      {info.filter(i => i.value && i.value !== '').map(i => (
        <Col key={`Info_${i.label}`} xs="12" md={mdValue} lg={lgValue} className="game-info-table-cell">
          <div className={classNames(
            "game-info-label", 
            {"gamepass-game-info-label" : gamepassGame}
          )}>
            {i.label}
          </div>
          <div className={classNames(
            "game-info-value", 
            {"gamepass-game-info-value" : gamepassGame}
          )}>
            {i.value}
          </div>
        </Col>
      ))}
    </Row>
  </Container>
);
}
export default GameInfo;