import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { HiddenHeaderAndFooter } from '../Commons';
import { LayoutContent, PageTitle } from '../Components';
import {
  minWidths,
  paymentInstructions,
  siteRoutes
} from '../../constants';
import { 
  setBarcodeVisible, 
  setCreatePayment, 
  setInstructionsVisible, 
  setTopUpVisible, 
  setTransactionVisible 
} from '../../actions';
import { useQuery } from '../../hooks/useQuery';
import './PaymentInstructions.scss';

export const PaymentInstructions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const channel = new URLSearchParams(search).get('channel');
  const method = new URLSearchParams(search).get('method');
  const mobile = new URLSearchParams(search).get('mobile');
  const query = useQuery();
  const id = query.get('id');
  const isInstructionsVisible = useSelector((state) => state.transactions.isInstructionsVisible);
  const isFromMobileTransactionId = location.state && location.state.split('/')[3];
  
  let content = paymentInstructions.sevenEleven;
  if (channel) {
    if (channel.toLowerCase().includes('cebuana')) content = paymentInstructions.cebuana;
    else if (channel.toLowerCase().includes('palawan')) content = paymentInstructions.palawan;
    else if (channel.toLowerCase().includes('mlhuillier')) content = paymentInstructions.mLhuillier;
  }
  
  const handleMediaQueryChange = (matches) => {
    if (matches && (isInstructionsVisible || !method)) {
      if (isFromMobileTransactionId){
        navigate(`/wallet${window.location.search}&id=${isFromMobileTransactionId}`);
      } else {
        if (isInstructionsVisible) navigate(-1);
      }
      dispatch(setBarcodeVisible(false));
      dispatch(setInstructionsVisible(true));
    }
    if (!matches && isInstructionsVisible) navigate(`/payment/instructions${window.location.search}`);
  }
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet }, undefined,  handleMediaQueryChange);

  const handleNavigate = () => {
    if (isDesktop && isInstructionsVisible) {
      dispatch(setInstructionsVisible(false));
      if (!method && !id) dispatch(setBarcodeVisible(true));
      else if (!method && id) dispatch(setTransactionVisible(true));
      else dispatch(setTopUpVisible(true));
      return;
    }
    if (method && !isDesktop) {
      navigate(`/topup/payment${window.location.search}`);
      dispatch(setInstructionsVisible(false));
      dispatch(setTopUpVisible(true));
      return;
    }
    navigate(-1);
    dispatch(setInstructionsVisible(false));
    dispatch(setTopUpVisible(false));
  }

  const handleWalletNavigate = () => {
    dispatch(setInstructionsVisible(false));
    dispatch(setCreatePayment({}));
    navigate(siteRoutes.wallet, { replace: isDesktop });
  }

  useEffect(() => {
    return () => {
      if (!isDesktop) dispatch(setInstructionsVisible(false));
    }
  }, [dispatch, isDesktop])

  return (
    <LayoutContent 
      innerClassName="payment-instructions-layout-inner" 
      outerClassName="payment-instructions-layout"
    >
      <HiddenHeaderAndFooter hideFooter />
      <PageTitle title="Payment Instructions" />
      <div className="payment-instructions">
        <div>
          <div className={classNames("payment-instructions-header", {"payment-instructions-header-hide": mobile})}>
            <ArrowBackIosNewRoundedIcon onClick={handleNavigate} className="payment-instructions-header-back" />
            <div className="px-5">Payment Instructions</div>
          </div>
          <div className={classNames("payment-instructions-container", {"payment-instructions-container-mobile": mobile})}>
            <div className={classNames("payment-instructions-box", {"payment-instructions-box-transparent": isDesktop && isInstructionsVisible})}>
              <div className="payment-instructions-title">{content.title}</div>
              <div>
                {content.steps.map((s, sIdx) => (
                  <div key={`PaymentInstructions_${channel}_${sIdx + 1}`} className="payment-instructions-step">
                    <div className="payment-instructions-step-title">{s.title}</div>
                    <ul className="payment-instructions-step-details">
                      {s.details.map((d, dIdx) => (
                        <li key={`PaymentInstructions_${channel}_${sIdx + 1}_Detail_${dIdx}`} className="payment-instructions-step-detail">
                          <div className="payment-instructions-step-text" dangerouslySetInnerHTML={{ __html: d.text }} />
                          {d.sub && <ul className="payment-instructions-step-subdetails">
                            {d.sub.map(ds => {
                              if (ds.title && ds.list) {
                                return (
                                  <li key={`PaymentInstructions_${channel}_${sIdx + 1}_Detail_${dIdx}_${ds.title}`}>
                                    {ds.title && ds.list && <div>
                                      <div className="payment-instructions-step-value">{ds.title}</div>
                                      <ul className="payment-instructions-step-subdetails">
                                        {ds.list.map(l => (
                                          <li key={`PaymentInstructions_${channel}_${sIdx + 1}_Detail_${dIdx}_${ds.title}_${l.label}`}>
                                            <span className="payment-instructions-step-label">{`${l.label}: `}</span>
                                            <span className="payment-instructions-step-value">{l.value}</span>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>}
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={`PaymentInstructions_${channel}_${sIdx + 1}_Detail_${dIdx}_${ds.label}`}>
                                    <span className="payment-instructions-step-label">{`${ds.label}: `}</span>
                                    <span className="payment-instructions-step-value">{ds.value}</span>
                                  </li>
                                );
                              }
                            })}
                          </ul>}
                          {d.imageUrl && <div className="payment-instructions-form">
                            <img src={d.imageUrl} alt={`${channel}_Form`} className="payment-instructions-form-image" />
                          </div>}
                          {d.table && <div className="payment-instructions-table">
                            <Row>{d.table.headers.map((h, hIdx) => {
                              const headerWidthXs = hIdx === 0 ? 8 : 4;
                              const headerWidthSm = hIdx === 0 ? 7 : 5;
                              return <Col key={`${h}`} xs={headerWidthXs} sm={headerWidthSm} className="payment-instructions-table-header">{h}</Col>;
                            })}</Row>
                            {d.table.cells.map((c, cIdx) => (
                              <Row key={`${d.table.headers[0]}_${cIdx}`}>
                                {Object.keys(c).map((ck, ckIdx) => {
                                  const cellWidthXs = ckIdx === 0 ? 8 : 4;
                                  const cellWidthSm = ckIdx === 0 ? 7 : 5;
                                  return <Col key={`${d.table.headers[0]}_${cIdx}_${ck}`} xs={cellWidthXs} sm={cellWidthSm}>{c[ck]}</Col>;
                                })}
                              </Row>
                            ))}
                          </div>}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames("payment-instructions-back-to-wallet", {"payment-instructions-back-to-wallet-mobile": mobile})}>
          <div onClick={handleWalletNavigate} className="payment-instructions-back-to-wallet-link">
            <button className="payment-instructions-back-to-wallet-button">Back to Wallet</button>
          </div>
        </div>
      </div>
    </LayoutContent>
  )
}