import React from 'react';
import Lottie from 'lottie-react';
import { modalNotificationsBodyKind } from '../../../constants';
import './DailyRewards.scss';

export const UnclaimedAnimation = ({
  kind,
  value,
  handleClaimRewards,
  isLoading,
  animation,
  animationLoopEnabled
}) => (
  <div className="d-flex align-items-center justify-content-center w-100">
    {kind === modalNotificationsBodyKind.text &&
      !isLoading &&
      <div className="daily-rewards-content-title">{value}</div>}
    {kind === modalNotificationsBodyKind.animation &&
      <div onClick={handleClaimRewards} className="daily-rewards-animated">
        <Lottie 
          animationData={animation} 
          loop={animationLoopEnabled} 
          autoplay={true}
        />
      </div>}
    {kind === modalNotificationsBodyKind.cta &&
      !isLoading &&
      <button 
        onClick={handleClaimRewards} 
        className="daily-rewards-button"
      >
        {value.ctaText}
      </button>}
  </div>
);