import { connect } from 'react-redux';
import { getQuests, setLoading } from '../actions';
import { questListSelector, questLeaderboardsSelector, questFAQsSelector } from '../selectors';
import { Quests } from '../components';

const mapStateToProps = (state) => ({
  quests: questListSelector(state),
  leaderboards: questLeaderboardsSelector(state),
  faqs: questFAQsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  getQuests: () => dispatch(getQuests())
});

const QuestsContainer = connect(mapStateToProps, mapDispatchToProps)(Quests);

export default QuestsContainer;