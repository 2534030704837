import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined' && 
        typeof window.scrollTo === 'function') {
        try {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
          });
        } catch (error) {}
      }
    }, 0);
  }, [pathname]);

  return null;
}