import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Roulette } from '../../Commons';
import { getSpinAvailable, getSpinPrize, setSpinPrize } from '../../../actions';
import { spinAvailableSelector, spinPrizeSelector } from '../../../selectors';
import { imagePrizes } from '../../../constants';
import { 
  crocs, 
  free, 
  gcash, 
  load20, 
  load50, 
  metapup, 
  metmetSad, 
  mgc, 
  plainsAndPrints, 
  slimmersWorld, 
  theMomentGroup, 
  uniqlo,
  iphone,
  slimmersWorldMain,
  kamsRoast,
  theMensRoom,
  collezione,
  arrowLand,
  theNewEra,
  botejyu
} from './images';
import './Wheel.scss';

const Wheel = ({ 
  setIsSpinning, 
  setSpinCount, 
  wheelImage,
  popUpBackground,
  prizes,
  isExtraGDay 
}) =>  {
  const dispatch = useDispatch();
  const location = useLocation();

  const [couponNum, setCouponNum] = useState(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [open, setOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [prize, setPrize] = useState('');

  const prizeData = useSelector(state => spinPrizeSelector(state));
  const spinAvailable = useSelector(state => spinAvailableSelector(state));
  const search = useLocation().search;
  
  const tokenValue = new URLSearchParams(search).get('token');
  const prizeParams = new URLSearchParams(search).get('prize');
  const marketingId = location.pathname.split('/')[3]; 
  
  const handleOpen = () => {
    if (isEmpty(prizeData)) return;
    setOpen(true);
  };

  const handleSpinAvailable = useCallback(async() => {
    await new Promise(resolve => {
      dispatch(getSpinAvailable({token: tokenValue ? tokenValue : '', marketingId}));
      resolve();
    });
    setSpinCount(spinAvailable);
  },[tokenValue, dispatch, setSpinCount, spinAvailable, marketingId])

  useEffect(() => {
    handleSpinAvailable();
  },[handleSpinAvailable])
  
  const handleClose = () => {
    setOpen(false);
    setSpinning(false);
    setMustSpin(false);
    setIsSpinning(false);
    setTimeout(() => {
      dispatch(setSpinPrize({}));
    }, 300)
  };

  const onClick = () => {
    if (!isEmpty(prizeData)) return;
    const token = tokenValue ? tokenValue : '';
    dispatch(getSpinPrize({token, prize: prizeParams, marketingId}));
  };

  const handleSpin = useCallback(async() => {
    setSpinCount(prev => prev - 1);
    setIsSpinning(true);
    let finalPrize = [];
    await new Promise(resolve => {
      const priceArr = [];
      for (const prizeIndex of Object.keys(prizes)){
        if (prizes[prizeIndex] === prizeData.sku){
          priceArr.push(Number(prizeIndex));
        }
      }
      const randomIndex = Math.floor(Math.random() * priceArr.length);
      finalPrize = priceArr[randomIndex];
      resolve();
    })
    if (!spinning) {
      setSpinning(true);
      setCouponNum(finalPrize);
      setMustSpin(true);
    }
  }, [prizeData, setIsSpinning, spinning, prizes, setSpinCount]);

  useEffect(() => {
    if (isEmpty(prizeData)) return;
    handleSpin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[prizeData])

  useEffect(() => {
    if (!isEmpty(prizeData) && couponNum) setPrize(prizes[couponNum]);
  },[couponNum, prizeData, prizes])

  const getImage = () => {
    if (isEmpty(prizeData)) return;
    switch (prize) {
      case imagePrizes.iphone:
        return iphone;
      case imagePrizes.loadTwenty:
        return load20;
      case imagePrizes.loadFifty:
        return load50;
      case imagePrizes.youLose: 
      case imagePrizes.noPrize:
        return metmetSad;
      case imagePrizes.freeSpin:
        return free;
      case imagePrizes.mgc:
        return mgc;
      case imagePrizes.metapup:
        return metapup;
      case imagePrizes.gcash:
        return gcash;
      case imagePrizes.uniqlo:
        return uniqlo;
      case imagePrizes.plains:
        return plainsAndPrints;
      case imagePrizes.moment:
        return theMomentGroup;
      case imagePrizes.crocs:
        return crocs;
      case imagePrizes.slimmers:
        return slimmersWorld;
      case imagePrizes.slimmersMain:
        return slimmersWorldMain;
      case imagePrizes.kamsRoast:
        return kamsRoast;
      case imagePrizes.theMensRoom:
        return theMensRoom;
      case imagePrizes.collezione:
        return collezione;
      case imagePrizes.arrowLand:
        return arrowLand;
      case imagePrizes.theNewEra:
        return theNewEra;
      case imagePrizes.botejyu:
        return botejyu;
      default:
        break;
    }
  }

  const prizeImage = prizeData?.imageUrl ? prizeData.imageUrl : getImage();
  const onImageError = (e) => {
    e.target.src = getImage();
  }

  return (
    <>
      <div className="wheel-wrapper">
        <Roulette
          wheelImage={wheelImage}
          mustStartSpinning={mustSpin}
          prizeNumber={couponNum}
          handleSpin={() => onClick()}
          onStopSpinning={() => {
            setSpinning(false);
            setMustSpin(false);
            setIsSpinning(false);
            setSpinCount(prizeData.availableSpins);
            handleOpen();
          }}
        />
      </div>
      <Modal
        backdrop
        show={open}
        className="wheel-container"
        contentClassName={classNames(
          `wheel ${popUpBackground}`, 
          {"wheel-sm-small": prize.includes(imagePrizes.noPrize)}
        )}
        dialogClassName="wheel-dialog"
        backdropClassName="wheel-bg"
        centered
        onHide={handleClose}
      >
        <div onClick={handleClose} className="wheel-close"></div>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center flex-column position-relative w-100">
            <div className={classNames(
              "wheel-prize-wrapper w-100", 
              {"wheel-prize-wrapper-gap": prize.includes(imagePrizes.noPrize)}
            )}>
              <img 
                src={prizeImage} 
                alt="prize"
                onError={onImageError}
                className={classNames(
                  "wheel-prize-image", 
                  {"wheel-prize-image-banner": !isExtraGDay && !prize.includes(imagePrizes.freeSpin)}
                )}
              />
              <div className="wheel-prize-title mt-1">{prizeData?.title}</div>
              <div className="wheel-prize-bottom mt-1 mb-2">{prizeData?.detail}</div>
            </div>
          </div>
          {prize.includes(imagePrizes.freeSpin) ? (
            <button onClick={handleClose} className="wheel-button">Spin again</button>
          ) : (
            !prize.includes(imagePrizes.noPrize) &&
            !prize.includes(imagePrizes.youLose) && 
            <a href={prizeData?.cta?.url} className="wheel-button">{prizeData?.cta?.title}</a>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Wheel;
