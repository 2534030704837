export const sectionGMusicFest = 'g-music-fest';
export const sectionExtraGDay = 'extra-g-day';
export const sectionSMEvent = 'sm-astro-nft';
export const componentType = {
  bittoShare: 'bitto-share',
  header: 'header',
  title: 'title',
  image: 'image',
  event: 'event',
  background: 'background'
};
export const bittoInlineStyle = (background) => {
  const inLineStyle = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'fixed',
    overflow: 'overlay',
    left: '0'
  }
  return inLineStyle;
};
export const launchpadSharePlatforms = [
  'facebook',
  'whatsapp',
  'twitter',
  'telegram'
];
export const sectionType = {
  voucherMint: 'voucher-mint',
  qrMint: 'qr-mint',
  unliMint: 'unli-mint',
  mintingProgress: 'minting-progress',
  about: 'about'
};
export const launchpadBanners = {
  gudiSquad: {
    name: 'squad',
    banner: ''
  },
  astro: {
    name: 'astro',
    banner: 'event'
  },
  fest: {
    name: 'fest',
    banner: 'fest'
  }
};
export const launchpadMintProcessing = {
  title: 'Processing...',
  description: 'Please do not refresh or leave the page while minting. This may take up to a few minutes.'
};
export const launchpadCTA = {
  title: "Visit launchpad",
  astro: '/launchpad/astro'
};
export const launchpadItemTexts = {
  alreadyClaimedNFT: 'You have already claimed your NFT.',
  loginToClaimNFT: 'Login to Claim NFT',
  loginToMint: 'Login to Mint',
  allNFTsRedeemed: 'All NFTs from this collection have been redeemed.',
  stayTuned: 'Stay tuned for more collections in the launchpad.',
  freeSpinAlreadyCollected: 'Sorry, you have collected the free spin.',
  astroNFTCollectionSuccess: 'You have collected your Astro NFT.',
  freeSpinReward: 'You have received an additional one (1) FREE spin.',
  enterVoucherCode: 'Enter Voucher Code',
  learnMore: 'Learn More'
};
export const launchpadNftSlug = {
  wenlambo: 'wenlambo'
};