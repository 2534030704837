import React from 'react'
import Webcam from "react-webcam";
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';
import { Modal } from 'react-bootstrap';
import './Camera.scss';

export const Camera = ({uploadFile, open, hide}) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    var file = dataURLtoFile(imageSrc, `${Date.now().toString()}.jpeg`);
    uploadFile(file);
    hide();
  }, [webcamRef, hide, uploadFile]);
  
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  };

  return (
    <Modal
      backdrop
      show={open}
      className="camera-container"
      contentClassName="camera"
      dialogClassName="camera-dialog"
      backdropClassName="camera-bg"
      onHide={hide}
    > 
      <Modal.Header className="camera-header">
        <h3>Camera</h3>
        <div 
          onClick={hide}
          className='camera-close'>Cancel</div>
      </Modal.Header>
      <Modal.Body>
        <Webcam
          className='camera-web'
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
        <button className='camera-btn' onClick={capture}>
          <PhotoCameraRoundedIcon fontSize='large'/>
        </button>
      </Modal.Body>
    </Modal>
  );
}
