import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { load } from 'recaptcha-v3';
import { firebaseEvents, recaptchaSiteKey } from '../constants';
import { setLoading, submitLogin } from '../actions';
import { getDeviceInfo } from '../utils/getDeviceInfo';
import { mobileNumberSelector } from '../selectors';
import { logFirebaseEvent } from '../utils/logFirebaseEvent';

export const useLogin = () => {
  const dispatch = useDispatch();

  const [recaptcha, setRecaptcha] = useState('');
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  const mobileNumber = useSelector(state => mobileNumberSelector(state));

  const getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        setRecaptcha(recaptcha);
        setIsRecaptchaLoaded(true);
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  useEffect(() => {
    getRecaptcha();
  }, [])

  const requestOtp = () => {
    let formData = {
      mobileNumber,
      deviceInfo: getDeviceInfo(),
    };
    dispatch(setLoading(true));
    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedFormData = {
            ...formData,
            recaptchaToken: t
          };
          dispatch(submitLogin(updatedFormData));
        })
        .catch(error => {
          logFirebaseEvent(
            firebaseEvents.failedEvent, {
              error_message: error
            });
        });
    } else {
      dispatch(submitLogin(formData));
    }
  }

  return {
    requestOtp
  }
}
