import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import isEmpty from 'lodash/isEmpty';
import TransactionCard from '../TransactionCard/TransactionCard';
import {
  setBarcodeVisible,
  setSelectedMethod,
  setTransactionVisible
} from '../../../actions';
import {
  channels,
  direction,
  minWidths,
  monthNames
} from '../../../constants';
import './TransactionHistory.scss';

const TransactionHistory = ({ 
  transactions, 
  setIsBarcodeVisible,
  handleTransaction,
  hooks,
  setIsInView
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedMethod = useSelector((state) => state.transactions.selectedMethod);
  const isTransactionVisible = useSelector((state) => state.transactions.isTransactionVisible);

  const { ref, inView } = useInView({ threshold: 0.9 });

  useEffect(() => {
    if (setIsInView){
      setIsInView(inView);
      return () => setIsInView(false);
    }
  }, [inView, setIsInView])

  const handleTransactions = async(method) => {
    await new Promise(resolve => {
      dispatch(setSelectedMethod(method));
      resolve();
    });
    if (isDesktop) {
      dispatch(setTransactionVisible(true));
    } else {
      navigate({ 
        pathname: `/wallet/transactions/${method.id}`,
        state: location.pathname 
      });
    }
  }

  const handleShowBarcode = (e, method) => {
    e.stopPropagation();
    dispatch(setSelectedMethod(method));
    dispatch(setBarcodeVisible(true));
    if (searchParams.has('id')){
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }

  const handleMediaQueryChange = (matches) => {
    if (!isTransactionVisible) return;
    if (!matches) return navigate(`/wallet/transactions/${selectedMethod.id}`);
    navigate(`/wallet?id=${selectedMethod.id}`);
    dispatch(setTransactionVisible(true));
  }

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet }, undefined, handleMediaQueryChange);
  
  useEffect(() => {
    if (isTransactionVisible && isDesktop && selectedMethod){
      searchParams.set('id', selectedMethod.id);
      setSearchParams(searchParams, { replace: true });
    }
  }, [isTransactionVisible, selectedMethod, isDesktop, setSearchParams, searchParams])

  return (
    <div>
      {transactions.map((t, tIdx) => {
        const d = new Date(t.timestamp);
        const month = monthNames[d.getMonth()];
        const updatedDateFormat = `${month.slice(0, 3)} ${d.getDate()}, ${d.getFullYear()}`;
        const updatedTimeFormat = new Date(t.timestamp).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        })
        
        t.status = t.status.toLowerCase();
        const isOverTheCounter = !isEmpty(t.details) && 
          !isEmpty(t.details.paymentChannel?.code) && 
          t.details.paymentChannel?.code.toLowerCase() === channels.sevenEleven;
        
        const transactionMethod = hooks ? handleTransactions : handleTransaction;
        const barcodeMethod = hooks ? handleShowBarcode : setIsBarcodeVisible;
        const symbol = t.direction === direction.incoming ? "+" : "-";

        return (
          <div key={`TRANSACTIONS_${tIdx}`} ref={ref}>
            <TransactionCard
              handleTransaction={transactionMethod}
              setIsBarcodeVisible={barcodeMethod}
              transaction={t}
              symbol={symbol}
              isOverTheCounter={isOverTheCounter}
              updatedDateFormat={updatedDateFormat} 
              updatedTimeFormat={updatedTimeFormat}
            />
          </div>
        )
      })}
    </div>
  )
}

export default TransactionHistory;
