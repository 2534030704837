import React, { useState } from 'react';
import classNames from 'classnames';
import './Home.scss';

export const HomeCredit = ({ 
  className, 
  title, 
  background, 
  amount, 
  shouldCenter, 
  children 
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div className="home-credits-column">
      <div className="home-credit">
        <img 
          src={background}
          onLoad={() => setIsLoaded(true)}
          alt={title} 
          loading="lazy" 
          className={classNames(
            "home-credit-bg", 
            {"home-credit-bg-center": shouldCenter}
          )}
        />
        {isLoaded &&
          <div className="home-credit-contents">
            <div className="home-credit-title">{title}</div>
            <div className="home-credit-text">
              <div className={classNames("home-credit-icon", `${className}-icon`)} />
              <div className="home-credit-amount">{amount}</div>
            </div>
            <div className="d-flex gap-2 align-items-center home-credit-instruction">
              {children}
            </div>
          </div>}
      </div>
    </div>
  )
};