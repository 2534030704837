import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { OTP } from '../Commons';
import { setAuthStep, startLoginOTPTimer, submitLoginOTP } from '../../actions';
import { authSteps, storageKeys } from '../../constants';

export const LoginOTP = ({ 
  otpMinutes, 
  otpSeconds, 
  transform, 
  backToLogin, 
  texts, 
  countryCode, 
  urlParams, 
  slug, 
  params, 
  loginFormData,
  ...props 
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.checkoutData) {
      dispatch(setAuthStep(authSteps.otp));
      dispatch(startLoginOTPTimer(true));
    }
  }, [dispatch, location])

  const handleBackToLogin = () => {
    if (location?.state?.checkoutData) {
      navigate('/');
    }
    backToLogin();
  }

  const submitOTP = (otpDigits) => {
    const checkoutData = location?.state?.checkoutData;
    const customer = location?.state?.customer;
    const otp = otpDigits.join('');
    const mobnum = `${countryCode}${loginFormData.mobileNumber}`;
    const urlParams = params;
    const referralCode = Cookies.get(storageKeys.referralCode);

    const initPayload = { otp, mobnum, urlParams, slug };
    let payload = initPayload;
    if (referralCode) {
      payload.referralCode = referralCode;
    }
    
    if (!isEmpty(checkoutData)) {
      payload.checkoutData = checkoutData;
    }
    
    if (!isEmpty(customer)) {
      payload.customer = customer;
    }
    dispatch(submitLoginOTP(payload));
  };

  return (
    <div className="login-otp" style={transform}>
      <div className="login-otp-header">
        <div className="back" onClick={handleBackToLogin}><ArrowBackIosNewRoundedIcon /></div>
        <span>{texts.title}</span>
      </div>
      <div className="login-otp-form">
        <OTP 
          {...props} 
          submit={submitOTP} 
          description={texts.description} 
          time={{ minutes: otpMinutes, seconds: otpSeconds }} 
          autoSubmit 
        />
      </div>
    </div>
  )
};