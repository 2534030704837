import React from 'react';
import classNames from 'classnames';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { 
  defaultPaymentMethod, 
  paymentMethodsData, 
  selectPaymentMethodText, 
  walletConnectTexts, 
  wallets 
} from '../../constants';
import { setWalletAddressFormat } from '../../utils/setWalletAddressFormat';

export const GamePassCheckoutPaymentMethod = ({ 
  selectedPaymentMethod, 
  navigate, 
  address, 
  connector, 
  isConnected 
}) => {
  let updatedContent = selectedPaymentMethod;
  if (selectedPaymentMethod?.code === wallets.walletConnect.name) {
    const newAddress = address ? setWalletAddressFormat(address) : '';
    const connectorName = connector?.name || selectedPaymentMethod?.name;
  
    updatedContent = {
      ...selectedPaymentMethod,
      description: newAddress,
      name: connectorName,
      imageUrl: connectorName === wallets.metaMask.name ? paymentMethodsData?.default.imageUrl : selectedPaymentMethod?.imageUrl
    };
  }

  return (
    <div className="checkout-payment">
      <div className="checkout-payment-title">Payment Method</div>
      <div 
        onClick={() => navigate('payment-methods')}
        className="checkout-payment-card"
      >
        <div className="checkout-payment-card-wrapper">
          {updatedContent !== '' ?
            <>
              {updatedContent.imageUrl &&
                <img 
                  src={updatedContent.imageUrl} 
                  alt={updatedContent.code} 
                  className={classNames(
                    "checkout-payment-card-icon", {
                    "checkout-payment-card-icon-small": (updatedContent.code === defaultPaymentMethod ||
                      updatedContent.code === wallets.walletConnect.name)
                  })} 
                />}
              <div className="d-flex flex-column gap-1">
                <div className="checkout-payment-card-text">
                  <div>{updatedContent.name}</div> 
                  {!isConnected &&
                    updatedContent.code === wallets.walletConnect.name &&
                    <div className="checkout-payment-card-subtext">
                      ({walletConnectTexts.notConnected})
                    </div>}
                </div>
                {updatedContent.description &&
                  <div className="checkout-payment-card-description">{updatedContent.description}</div>}
                {updatedContent.selectedInputNumber &&
                  <div className="checkout-payment-card-description">{updatedContent.selectedInputNumber}</div>}
              </div>
            </> :
            <div className="checkout-payment-card-text">{selectPaymentMethodText}</div>}
          <KeyboardArrowRightRoundedIcon className="checkout-payment-card-arrow" />
        </div>
      </div>
    </div>
  )
};

