import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { HomeFeaturedCarousel } from './HomeFeaturedCarousel';
import {
  authSteps,
  firebaseEvents,
  gamePassCTA,
  gamePassCheckoutCTA,
  homeFeaturedBanners,
  maxWidths,
  minWidths,
  singleQuantityAddToCart,
  wenLamboText
} from '../../constants';
import { guestSelector } from '../../selectors';
import {
  addGamePassToCart,
  showGamePassCheckout,
  unauthorizedRedirect
} from '../../actions';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './Home.scss';

export const HomeFeatured = ({ featuredBanner, selectedItem, carousel, handleBannerHeight }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS });
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const isGuest = useSelector((state) => guestSelector(state));

  useEffect(() => {
    const getBannerSize = () => {
      const bannerEl = document.querySelector('.banner-cover-carousel');
      if (bannerEl) {
        const height = bannerEl.clientHeight;
        const updatedHeight = isDesktop ? height / 2 : height;
        handleBannerHeight(updatedHeight);
      }
    };
    getBannerSize();
    window.addEventListener('resize', getBannerSize);
    return () => {
      window.removeEventListener('resize', getBannerSize);
    };
  }, [isDesktop]);
  
  const handleRoute = (route) => {
    dispatch(
      unauthorizedRedirect({ 
        isGuest, 
        authSteps: authSteps.login,
        route
      })
    );
    return;
  }

  const handleBuyNow = () => {
    if (selectedItem) {
      const addToCartData = {
        productId: selectedItem.productId,
        skuId: selectedItem.id,
        quantity: singleQuantityAddToCart,
        isCheckout: true,
        redirectLink: gamePassCheckoutCTA,
        requestCheckout: true,
        navigate
      };
      if (isGuest) {
        dispatch(
          unauthorizedRedirect({ 
            isGuest, 
            authSteps: authSteps.login
          })
        );
        return;
      }
      dispatch(addGamePassToCart(addToCartData));
      let event = firebaseEvents.homeGamePassBuy;
      if (window.location.pathname.toLowerCase().includes('gamepass')) {
        event = firebaseEvents.wenLamboMainBuy;
      }
      logFirebaseEventWithTimestamp(
        event, {
          sku_name: selectedItem.shortName,
          page_url: window.location.pathname
        });
    }
  }

  const handleBannerCheckout = () => {
    if (isGuest) {
      dispatch(showGamePassCheckout(true));
    } else {
      handleBuyNow();
    }
  }

  let banner = null;
  if (isMobile) {
    if (!isEmpty(featuredBanner.mobile)) {
      banner = featuredBanner.mobile;
    }
  } else {
    if (!isEmpty(featuredBanner.desktop)) {
      banner = featuredBanner.desktop;
    }
  }
  
  return (
    <div className="home-menu">
      {banner && 
        !carousel &&
          <div onClick={() => handleRoute(gamePassCTA)}>
            <img
              src={banner}
              alt={wenLamboText}
              className={classNames(
                "home-menu-banner",
                "home-menu-banner-image"
              )}
            />
          </div>}
      {banner &&
        carousel &&
        <>
          <div className="home-padding" />
          <HomeFeaturedCarousel 
            featured={homeFeaturedBanners} 
            wenLamboBanner={featuredBanner}
            handleBannerCheckout={handleBannerCheckout}
          />
        </>}
    </div>
  );
}