import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Modal } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { redeemFeaturedSelector } from '../../../selectors';
import { getFeaturedPromotion } from '../../../actions';
import { modalNotificationsBodyKind } from '../../../constants';
import './DiscordDailyBoostModal.scss';

const DiscordDailyBoostModal = ({ open, hide }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const featuredPromotions = useSelector(state => redeemFeaturedSelector(state));

  useEffect(() => {
    if (open && isEmpty(featuredPromotions)) {
      dispatch(getFeaturedPromotion());
    }
  }, [dispatch, featuredPromotions, open]);

  const handleNavigate = (uri) => {
    const url = new URL(uri);
    navigate(url.pathname);
    hide();
  }

  return (
    <Modal
      backdrop
      centered
      show={open}
      onHide={hide}
      className="discord-daily-boost-container"
      contentClassName="discord-daily-boost"
      dialogClassName="discord-daily-boost-dialog"
      backdropClassName="discord-daily-boost-bg"
    >
      <Modal.Body className="discord-daily-boost-body">
        <CloseRoundedIcon onClick={hide} className="discord-daily-boost-close" />
        {featuredPromotions?.modal?.map((m, mIdx) => (
          <div className="discord-daily-boost-content" key={mIdx}>
            {m.kind === modalNotificationsBodyKind.image && 
              <img 
                alt={m.kind}
                src={m.value} 
                className="discord-daily-boost-image"
              />}
            {m.kind === modalNotificationsBodyKind.header &&
              <div className="discord-daily-boost-title">{m.value}</div>}
            {m.kind === modalNotificationsBodyKind.text &&
              <div className="discord-daily-boost-subtitle">{m.value}</div>}
            {m.kind === modalNotificationsBodyKind.cta &&
              <div 
                className="discord-daily-boost-button"
                onClick={() => handleNavigate(m.value.uri)}
              >
                {m.value.ctaText}
              </div>}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
};

export default DiscordDailyBoostModal;