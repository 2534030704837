import axios from 'axios';
import { baseUrl } from './url';

export function requestUserProfile(sessionToken, version) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me', version)
  });
}

export function requestUnreadMessages(url) {
  return axios.request({
    method: 'GET',
    url
  });
}