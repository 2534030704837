import { defaultWebAppLocalhost, isDev } from './defaults';

export const sentryKey = {
  prod: 'https://3373ccb395cb4dc8838171e573c38140@o1347931.ingest.sentry.io/6676082',
  dev: 'https://4a4c85a4b87e4c818f5d4d43cfa7fb1f@o1347931.ingest.sentry.io/4505147363033088'
};
export const sentryDSN = isDev ? sentryKey.dev : sentryKey.prod;
export const sentryTracesSampleRate = isDev ? 1.00 : 0.05;
export const enableSentry = !window.location.href.includes(defaultWebAppLocalhost);
export const sentryErrors = [
  'Unexpected',
  'ResizeObserver',
  'Application offline',
  'localStorage',
  'storage',
  'Non-Error',
  'lodash',
  'classnames',
  'react-bootstrap',
  'react-redux',
  'react-responsive',
  'invocation',
  'AbortError'
];
export const blacklistedUrls = [/extensions\//i, /^chrome:\/\//i, /\/(gtm|ga|analytics)\.js/i];