import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { defaultQuantity } from '../../constants';

export const OrderSkus = ({ 
  item, 
  inventory, 
  filterredInventory, 
  selectedSkus, 
  setSelectedSkus, 
  isChecked,
  handleUpdateItems
}) => {
  const [quantity, setQuantity] = useState(defaultQuantity);

  const handleIncrement = () => {
    if (quantity === item?.quantity) return;
    setQuantity(quantity + 1);
  }

  const handleDecrement = () => {
    if (quantity === 1) return;
    setQuantity(quantity - 1);
  }

  useEffect(() => {
    for (const sku of selectedSkus) {
      if (sku.skuKind === item.skuKind) {
        sku.quantity = quantity;
      }
      if (sku.quantity === 0) {
        const filterredSkus = selectedSkus.filter(s => s.skuKind !== sku.skuKind);
        setSelectedSkus(filterredSkus);
      }
    }
  }, [quantity, selectedSkus, item, setSelectedSkus])

  useEffect(() => {
    if (!isEmpty(inventory)) {
      if (isEmpty(filterredInventory)) {
        setQuantity(0);
      };
    }
  }, [inventory, filterredInventory])

  const handleChecked = (checked) => {
    let filteredSkus = selectedSkus.filter(s => s.skuKind !== item.skuKind);
    const updatedItem = handleUpdateItems(item);
    if (checked) {
      const newItem = { ...updatedItem, quantity };
      filteredSkus = [...selectedSkus, newItem];
    }
    setSelectedSkus(filteredSkus);
  };

  return (
    <div className={classNames(
      "payment-activate-card",
      {"payment-activate-card-disabled": isEmpty(filterredInventory)}
    )}>
      <div className="position-relative">
        <Checkbox
          className="payment-activate-checkbox"
          checked={isChecked}
          onChange={(e) => handleChecked(e.target.checked)}
          sx={{
            color: "#615374",
            '&.Mui-checked': {
              color: "#f304a0",
            },
            zIndex: 2
          }}
        />
        {isChecked &&
          <div className="payment-activate-checkbox-floater" />}
      </div>
      <div className="d-flex align-items-center gap-3">
        <img 
          src={item?.imageUrl} 
          alt={item?.skuKind} 
          className={classNames(
            "payment-activate-image",
            {"payment-activate-image-disabled": isEmpty(filterredInventory)}
          )}
        />
        <div className="d-flex align-items-start flex-column justify-content-center gap-2">
          <div className="payment-activate-name">{item?.name}</div>
          <div className="d-flex align-items-center">
            <RemoveCircleOutline 
              className="payment-activate-icon" 
              onClick={handleDecrement}
            />
            <div className="payment-activate-quantity">{quantity}</div>
            <AddCircleOutline 
              className="payment-activate-icon" 
              onClick={handleIncrement}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
