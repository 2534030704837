import React from 'react';
import classNames from 'classnames';
import { Carousel, CarouselItem } from '../../Commons';
import { bannerDimensions } from '../../../constants';
import './MechanicsCarousel.scss';

const MechanicsCarousel = ({ title, description, className, carousel, stw }) => (
  <div className={classNames(
    "mechanics-carousel",
    {[`${className}-carousel`]: className}
  )}>
    <div className="mechanics-carousel-inner">
      {stw &&
        <div className="mechanics-carousel-title">Mechanics</div>}
      <h1 className="mechanics-carousel-title">{title}</h1>
      {description &&
        <p className="mechanics-carousel-description">{description}</p>}
      <Carousel className="mechanics-carousel-banners" arrows>
        {carousel.map((c, cIdx) => (
          <CarouselItem key={`ReferralMechanic_${cIdx + 1}`}>
            <div className="mechanics-carousel-banner">
              <img
                src={c.imageUrl}
                alt={c.title}
                width={bannerDimensions.referralMechanicsCarousel.width}
                height={bannerDimensions.referralMechanicsCarousel.height}
              />
              <p>{c.description}</p>
            </div>
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  </div>
);

export default MechanicsCarousel;