import React from 'react';
import { Row } from 'react-bootstrap';
import { PartnersThumbnail } from './PartnersThumbnail';
import './Partners.scss';

export const PartnersThumbnails = ({ title, items, isMobile }) => (
  <div className="partners-thumbnails">
    <h1 className="partners-thumbnails-title">{title}</h1>
    <Row className="partners-thumbnails-items">
      {items.map((i, iIdx) => (
        <PartnersThumbnail key={`Thumbnail_${iIdx}_${i.code}`} {...i} isMobile={isMobile} />
      ))}
    </Row>
  </div>
);