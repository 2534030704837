import { connect } from 'react-redux';
import { setChatMessageThread, submitChatMessage, setLoading, setDisplayToast } from '../actions';
import { chatMessageThreadSelector, displayLoginToastSelector } from '../selectors';
import { ChatThread } from '../components';

const mapStateToProps = (state) => ({
  toast: displayLoginToastSelector(state),
  thread: chatMessageThreadSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast)),
  setChatMessageThread: (thread) => dispatch(setChatMessageThread(thread)),
  submitChatMessage: (message) => dispatch(submitChatMessage(message)),
  setLoading: (loading) => dispatch(setLoading(loading))
});

const ChatThreadContainer = connect(mapStateToProps, mapDispatchToProps)(ChatThread);

export default ChatThreadContainer;