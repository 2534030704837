import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Partners.scss';

export const PartnersIcons = ({ title, items }) => (
  <div className="partners-icons">
    <h1 className="partners-icons-title">{title}</h1>
    <Row className="partners-icons-items">
      {items.map((i, iIdx) => (
        <Col key={`${iIdx}_${i.code}`} xs="6" sm="6" md="3" className="partners-icons-item">
          <div className="partners-icons-item-inner">
            <img
              src={i.imageUrls.logoUrl}
              className="partners-icons-item-logo"
              alt={`Logo_${i.code}`}
            />
            <div className="partners-icons-item-title">{i.name}</div>
          </div>
        </Col>
      ))}
    </Row>
  </div>
);