import { connect } from 'react-redux';
import { setLoading } from '../actions';
import { NotFound } from '../components';

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

const NotFoundContainer = connect(null, mapDispatchToProps)(NotFound);

export default NotFoundContainer;