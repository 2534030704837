import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import './ChatThread.scss';

const ChatThreadMessageMenu = ({ confirmDeleteMessage }) => (
  <div className="chat-thread-message-menu">
    <button onClick={confirmDeleteMessage}><DeleteIcon /></button>
  </div>
);

export default ChatThreadMessageMenu;