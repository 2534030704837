import { connect } from 'react-redux';
import { getHomeCollection } from '../actions';
import { trendingDropsSelector, topCollectionsSelector } from '../selectors';
import { NFTs } from '../components';

const mapStateToProps = (state) => ({
  trendingDrops: trendingDropsSelector(state),
  topCollections: topCollectionsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getHomeCollection: () => dispatch(getHomeCollection())
});

const NFTsContainer = connect(mapStateToProps, mapDispatchToProps)(NFTs);

export default NFTsContainer;