import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import LayoutContent from '../LayoutContent/LayoutContent';
import PageTitle from '../PageTitle/PageTitle';
import Section from '../Section/Section';
import './ProfileMenu.scss';

const ProfileMenu = ({ title, menu, backButtonRoute = '/profile' }) => (
  <LayoutContent>
    <PageTitle title={title} />
    <Section title={title} backButtonRoute={backButtonRoute} sectionClassName="profile-menu-section" titleCentered>
      <div className="profile-menu-list">
        {menu.map(m => (
          <Link key={`${title}_${m.name}`} to={m.route} className="profile-menu-item">
            <span>{m.name}</span>
            <ArrowForwardIosRoundedIcon className="profile-menu-item-forward-icon" />
          </Link>
        ))}
      </div>
    </Section>
  </LayoutContent>
);

export default ProfileMenu;