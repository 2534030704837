import { 
  GAMEPASS_CHECKOUT_RETURN_TO_PREVIOUS_PAGE,
  RESET_STATE,
  SET_ADDED_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  SET_GAMEPASS_CHECKOUT, 
  SET_GAMEPASS_CHECKOUT_FORM, 
  SET_GAMEPASS_CHECKOUT_PAYMENT,
  SET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  SHOW_GAMEPASS_CHECKOUT,
  SET_SHOW_PAYMENT_METHODS,
  SET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY,
  DISABLE_CHECKOUT_BUTTON,
  SHOW_CURRENCY_TOAST,
  SET_GAMEPASS_CHECKOUT_SELECTED_PAYMENT_METHOD,
  REDIRECT_TO_CHECKOUT_PAGE
} from '../actions/constants';

const checkoutState = {
  contents: {},
  payDetails: {},
  mustRedirectBack: false,
  tokenizedPayments: [],
  tokenizedIsAdded: false,
  showPaymentMethods: {
    modal: false,
    dropdown: false,
    currency: false
  },
  showCheckout: false,
  checkoutForm: {
    mobileNumber: ''
  },
  currency: [],
  isDisabled: false,
  showCurrency: false,
  selectedPaymentMethod: {},
  redirectToCheckout: false
};

const checkoutReducer = (state = checkoutState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return checkoutState;
    case SET_GAMEPASS_CHECKOUT:
      return {
        ...state,
        contents: action.contents
      };
    case SHOW_GAMEPASS_CHECKOUT:
      return {
        ...state,
        showCheckout: action.showCheckout
      };
    case SET_GAMEPASS_CHECKOUT_PAYMENT:
      return {
        ...state,
        payDetails: action.payDetails
      };
    case GAMEPASS_CHECKOUT_RETURN_TO_PREVIOUS_PAGE:
      return {
        ...state,
        mustRedirectBack: action.mustRedirectBack
      };
    case SET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS:
      return {
        ...state,
        tokenizedPayments: action.tokenizedPayments
      };
    case SET_ADDED_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS:
      return {
        ...state,
        tokenizedIsAdded: action.tokenizedIsAdded
      };
    case SET_SHOW_PAYMENT_METHODS:
      return {
        ...state,
        showPaymentMethods: {
          ...state.showPaymentMethods,
          ...action.showPaymentMethods
        }
      };
    case SET_GAMEPASS_CHECKOUT_FORM:
      return {
        ...state,
        checkoutForm: action.checkoutForm
      };
    case SET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY:
      return {
        ...state,
        currency: action.currency
      };
    case DISABLE_CHECKOUT_BUTTON:
      return {
        ...state,
        isDisabled: action.isDisabled
      };
    case SHOW_CURRENCY_TOAST:
      return {
        ...state,
        showCurrency: action.showCurrency
      };
    case SET_GAMEPASS_CHECKOUT_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.selectedPaymentMethod
      };
    case REDIRECT_TO_CHECKOUT_PAGE:
      return {
        ...state,
        redirectToCheckout: action.redirectToCheckout
      };
    default:
      return state;
  }
}

export default checkoutReducer;