import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { GridStats } from '../GridStats/GridStats';

export const TournamentBannerInfoDesktop = ({ title, text, buttons, prizes, stats, children }) => {
  const hasPrizeAndStats = prizes || stats;
  const leftPanelColXS = hasPrizeAndStats ? 7 : 12;
  const leftPanelColSM = hasPrizeAndStats ? 6 : 12;
  const leftPanelColXL = hasPrizeAndStats ? 8 : 12;

  return (
    <div className="tournament-banner-info">
      <Row>
        <Col xs={leftPanelColXS} sm={leftPanelColSM} lg={leftPanelColXS} xl={leftPanelColXL}>
          <div>
            <div className="tournament-banner-text">
              <h1 className="tournament-banner-title">{title}</h1>
              <div className="tournament-banner-short-desc">{text}</div>
              {buttons}
            </div>
          </div>
          {children}
        </Col>
        {hasPrizeAndStats && <Col xs={5} sm={6} lg={5} xl={4}>
          <div className="tournament-banner-prizes-stats">
            <div className="tournament-banner-prizes-stats-inner">
              <GridStats stats={stats} className="tournament-banner" isInDetails />
            </div>
          </div>
        </Col>}
      </Row>
    </div>
  );
}