import React, { useState } from 'react';
import classNames from 'classnames';
import {
  answerFormats,
  fieldTypes,
  regexConstants
} from '../../../constants';

const FormFieldWithWeight = ({ formField, updateWeight, formErrors, idKey, className }) => {
  const [checkboxAnswers, setCheckboxAnswers] = useState([]);

  const handleCheckboxAnswers = (answer) => {
    if (!checkboxAnswers.includes(answer)) {
      checkboxAnswers.push(answer);
    } else {
      const checkboxAnswerIdx = checkboxAnswers.indexOf(answer);
      if (checkboxAnswerIdx !== -1) checkboxAnswers.splice(checkboxAnswerIdx, 1);
    }
    setCheckboxAnswers(checkboxAnswers);
  }

  const handleInput = (e, field) => {
    switch (field.answerFormat) {
      case answerFormats.textField:
      case answerFormats.textArea:
      case answerFormats.multipleChoice:
        updateWeight(field[idKey], field.weight, e.target.value);
        break;
      case 'checkbox':
        handleCheckboxAnswers(e.target.value);
        updateWeight(field[idKey], field.weight, checkboxAnswers);
        break;
      default:
        break;
    }
  }

  const renderCheckbox = (fieldType, field, choice, key) => {
    const { caption, imageUrl } = choice;
    const id = field[idKey];
    const choiceId = choice[idKey];

    return (
      <label className={`${className}-checkbox`} key={`${choiceId}_${key}`}>
        <input
          name={id}
          id={`${id}_${choiceId}`}
          type={fieldType}
          value={choiceId}
          onClick={(e) => handleInput(e, field)}
        />
        <span className={fieldType}></span>
        {imageUrl ?
          <div className="img-container">
            <img alt={caption} src={imageUrl} />
          </div> :
          <span>{caption}</span>}
      </label>
    );
  }

  const renderInput = (field) => {
    switch (field.answerFormat) {
      case answerFormats.textField:
        const fieldIsFullName = field.caption.toLowerCase() === 'full name';
        return (
          <div className={classNames(
            `${className}-input`,
            `${className}-text`
          )}>
            <input 
              id={field[idKey]} 
              type={fieldTypes.textField}
              onChange={(e) => handleInput(e, field)} 
              onKeyPress={(event) => {
                if (!fieldIsFullName) return;
                if (regexConstants.specialChar.test(event.key)) {
                  event.preventDefault();
                }
              }} 
            />
            {field.imageUrl && <img alt={field.caption} />}
          </div>
        );
      case answerFormats.textArea:
        return (
          <div className={`${className}-textarea`}>
            <textarea
              id={field[idKey]}
              onChange={(e) => handleInput(e, field)}
              rows="4"
            />
            {field.imageUrl && <img alt={field.caption} />}
          </div>
        );
      case answerFormats.multipleChoice:
      case 'checkbox':
        const isMultipleChoice = field.answerFormat === answerFormats.multipleChoice;
        const fieldType = isMultipleChoice ? fieldTypes.multipleChoice : fieldTypes.multipleSelect;
        return (
          <div className={`${className}-input`}>
            {field.choices &&
              field.choices.map((choice, key) => (
                renderCheckbox(fieldType, field, choice, key)
              ))}
          </div>
        );
      default:
        break;
    }
  }

  return (
    <div className={`${className}-group`}>
      <div className={`${className}-label`}>
        {formField.required && <span>*</span>}
        {formField.caption}
      </div>
      <div>{renderInput(formField)}</div>
      {formErrors[idKey] ?
        <div className={`${className}-error`}>{formErrors[idKey]}</div>
        : null }
    </div>
  );
}

export default FormFieldWithWeight;