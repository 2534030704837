import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { gamePassAddToCart, setGamePassIsAddedToCart } from '../../../actions';
import { cartTitles, regexConstants } from '../../../constants';
import './AddToCartToast.scss';

const AddToCartToast = ({ open, hide, sku, productId }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);

  const handleValueChange = (e) => {
    if (e.target.value === '' || regexConstants.numbersOnly.test(e.target.value)) {
      setValue(e.target.value);
    }
  }

  useEffect(() => {
    if (value < 1) setValue(1);
    return () => dispatch(setGamePassIsAddedToCart(false));
  }, [dispatch, value])

  const handleAddToCart = () => {
    const cartData = {
      productId,
      skuId: sku.id,
      quantity: value
    };
    dispatch(gamePassAddToCart(cartData));
    setValue(1);
    hide();
  }

  const handleHide = () => {
    setValue(1);
    hide();
  }
  
  return (
    <Modal
      backdrop
      show={open}
      className="add-to-cart-container"
      contentClassName="add-to-cart"
      dialogClassName="add-to-cart-dialog"
      backdropClassName="add-to-cart-bg"
      centered
      onHide={handleHide}
    >
      <Modal.Body>
        <Modal.Header closeButton closeVariant="white" onHide={hide} />
        <div className="add-to-cart-body">
          <div className="add-to-cart-content">
            <img src={sku?.imageUrls?.bannerUrl} alt={sku?.kind} className="add-to-cart-image" />
            <div className="add-to-cart-content-wrapper">
              <div className="add-to-cart-content-name">{sku?.shortName}</div>
              <div className="add-to-cart-content-amount">
                <div className="add-to-cart-content-coin" />
                <div className="add-to-cart-content-value">{sku?.defaultPrice?.value}</div>
                <div className="add-to-cart-content-currency">{sku?.defaultPrice?.currency}</div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center mt-3">
            <div className="add-to-cart-content-name">{cartTitles.quantity}</div>
            <div className="d-flex align-items-center gap-2">
              <RemoveCircleOutline 
                onClick={() => setValue(value - 1)} 
                className="add-to-cart-content-sign" 
              />
              <input 
                className="add-to-cart-content-input"
                onChange={handleValueChange}
                value={value}
                autoFocus
              />
              <AddCircleOutline 
                onClick={() => setValue(value + 1)} 
                className="add-to-cart-content-sign" 
              />
            </div>
          </div>
          <button 
            onClick={handleAddToCart} 
            className="add-to-cart-content-button mt-4"
          >
            {cartTitles.addToCart}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default AddToCartToast;


