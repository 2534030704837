import React from 'react';
import trophy from '../../../assets/tournaments/trophy.png';
import './TournamentPrize.scss';

export const TournamentPrize = ({ symbolText, symbolTextKind, valueText }) => {
  const prize = symbolTextKind === 'ValPrefix' ? `${symbolText} ${valueText}` : `${valueText} ${symbolText}`;
  const prizePool = `Prize Pool ${prize}`;
  return (
    <div className="tournament-prize">
      <img className="tournament-prize-trophy" src={trophy} alt="Trophy" />
      <div className="tournament-prize-text">{prizePool}</div>
    </div>
  );
}