import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './Coupon.scss';

export const Coupon = ({
  className,
  link,
  imageUrl,
  statusIndicator,
  couponDesignClassName,
  couponInfoClassName,
  children
}) => {
  const backgroundImageStyle = { backgroundImage: `url(${imageUrl})` };
  return (
    <div className={classNames("coupon", className)}>
      {link ?
        <Link
          to={link}
          className="coupon-image"
          style={backgroundImageStyle}
        >
          {statusIndicator}
        </Link> :
        <div
          className="coupon-image"
          style={backgroundImageStyle}
        >
          {statusIndicator}
        </div>
      }
      <div className={classNames(
        "coupon-design",
        "coupon-design-top",
        couponDesignClassName
      )} />
      <div className={classNames(
        "coupon-design",
        "coupon-design-bottom",
        couponDesignClassName
      )} />
      <div className={classNames(
        "coupon-info",
        couponInfoClassName
      )}>
        {children}
      </div>
    </div>
  );
}