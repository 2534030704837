import React from 'react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { 
  mainnet,
  goerli,
  bsc, 
  bscTestnet,
  polygon, 
  polygonMumbai, 
  avalanche,
  avalancheFuji
} from 'wagmi/chains';
import { isDev, wallets } from '../../../constants';

let chains = [mainnet, avalanche, bsc, polygon];
if (isDev) {
  chains = [mainnet, goerli, avalanche, bsc, bscTestnet, polygon, polygonMumbai, avalancheFuji];
}
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

const WalletConnectProvider = ({ children }) => (
  <>
    <WagmiConfig config={wagmiConfig}>
      {children}
    </WagmiConfig>
    <Web3Modal 
      projectId={projectId} 
      ethereumClient={ethereumClient} 
      explorerRecommendedWalletIds={[wallets.metaMask.id]}
      explorerExcludedWalletIds="ALL" 
    />
  </>
);

export default WalletConnectProvider;