import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { LayoutContent, PageTitle } from '../Components';
import { maxWidths } from '../../constants';
import './MGCConversion.scss';

export const MGCConversion = () => {
  const mobile = useMediaQuery({ maxWidth: maxWidths.mobileSM });
  const backToPreviousPage = () => window.history.back();
  const search = useLocation().search;
  const isInMobile = new URLSearchParams(search).get('mobile');
  
  return (
    <LayoutContent outerClassName="mgc-conversion">
      <PageTitle title="MGC Conversion" />
      {mobile && !isInMobile && <div className="mgc-conversion-back" onClick={backToPreviousPage}>
        <ArrowBackIosNewRoundedIcon className="mgc-conversion-back-icon" />
      </div>}
      {!isInMobile && <h1>MGC Conversion</h1>}
      <div className="mgc-conversion-content">
        <p>MetaverseGo! Credits (MGC) is a virtual platform currency whose value is pegged to the USDC stablecoin.</p>
        <p>For MGC earned from play-to-earn games, which have their own in-game tokens, conversion rates will apply upon transfer to the MetaverseGo! wallet.</p>
        <p>As an example, all SLP earned from Axie Infinity will be reflected in the MetaverseGo! wallet as MGC. The conversion rate that will prevail will be the market exchange rate <strong>at the time of transfer to MetaverseGo</strong>.</p>
        <div className="mgc-conversion-sample">Sample computation:
          <ul>
            <li>On July 15 at 12:00, you transferred 10,000 SLP earnings from Axie Infinity to MetaverseGo!.</li>
            <li>At the exact time of transfer, prevailing exchange rate is 1 SLP = 0.004 MGC.</li>
            <li>This means that you will receive 40 MGC (computed as 10,000 &times; 0.004).</li>
          </ul>
        </div>
        <p>Before transfers are initiated, any game earnings will still be displayed in your MetaverseGo! wallet as <strong>pending MGC</strong>, and will be reflected temporarily as an <strong>estimate amount</strong> based on the current exchange rate.</p>
      </div>
      {!mobile && <div className="mgc-conversion-got-it">
        <button className="mgc-conversion-got-it-btn" onClick={backToPreviousPage}>Got It</button>
      </div>}
    </LayoutContent>
  );
}