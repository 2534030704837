import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isArray from 'lodash/isArray';
import { Popup } from '../../Commons';
import { activateGamePass, activateMultipleSkus } from '../../../actions';
import { 
  activateKind, 
  cardsKind, 
  minWidths, 
  overrideActiveBoosterTexts 
} from '../../../constants';
import './OverrideActiveBoosterToast.scss';

const OverrideActiveBoosterToast = ({ open, hide, overrideDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const isGamePassAlreadyActivated = overrideDetails?.data?.activatedSkus?.some(a => a.includes(cardsKind.gamePass)); 

  const handleActivate = () => {
    const selectedSkus = overrideDetails?.data?.selectedSkus;
  
    if (isArray(selectedSkus)) {
      let firstBooster = false;

      const newSelectedSkus = selectedSkus.map((sku) => {
        if (!firstBooster && sku.skuKind.includes(cardsKind.goldBoost)) {
          sku.isOverridable = true;
          firstBooster = true;
        }
        return sku;
      });
      
      const activateData = {
        selectedSkus: newSelectedSkus,
        isGamePassAlreadyActivated,
        navigate
      };
  
      dispatch(activateMultipleSkus(activateData));
    } else {
      const overrideData = {
        sku: overrideDetails?.data?.sku,
        override: true,
        quantity: overrideDetails?.data?.quantity,
        kind: activateKind.goldBoost,
        shouldRedirectToGamePassGamesPage: true,
        navigate
      };
  
      dispatch(activateGamePass(overrideData));
    }
  };

  return (
    <Popup 
      open={open} 
      hide={hide} 
      className="override-active-booster" 
      header={!isDesktop}
      headerTitle={overrideDetails?.message}
      centered={isDesktop}
    >
      {isDesktop && 
        <h1 className="override-active-booster-title">{overrideDetails?.message}</h1>}
      <p className="override-active-booster-text">{overrideDetails?.description}</p>
      <div className='override-active-booster-button-container'>
        <button 
          className="override-active-booster-buy-button" 
          onClick={handleActivate}
        >
          {overrideActiveBoosterTexts.activateBooster}
        </button>
        <button 
          className="override-active-booster-button" 
          onClick={hide}
        >
          {overrideActiveBoosterTexts.cancel}
        </button>
      </div>
    </Popup>
  )
}

export default OverrideActiveBoosterToast