import React, { Component } from 'react';
import { LayoutContent, PageTitle } from '../Components';
import './SpendResult.scss';

export class SpendResult extends Component {
  continue = () => {
    window.location.assign('/');
  }

  render() {
    return (
      <LayoutContent>
        <PageTitle title="Spend" />
        <div className="spend-result-content">
          <div className="spend-result-status">Success</div>
          <div className="spend-result-message">
            Thank you so much for your purchase!
          </div>
          <button onClick={this.continue} className="spend-result-continue">Take Me Home</button>
        </div>
      </LayoutContent>
    );
  }
}