import React from 'react';
import { Checkbox } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { updateGamePassCartItems } from '../../actions';

export const CartCard = ({ item }) => {
  const dispatch = useDispatch();

  const handleUpdateGamePassCartItems = (props) => {
    const ids = {
      productId: item.productId,
      skuId: item.skuId
    };
    const updateData = [{
      ...ids,
      ...props
    }];
    dispatch(updateGamePassCartItems(updateData));
  }
  
  const handleItemChecked = (isCheckout) => {
    handleUpdateGamePassCartItems({ isCheckout });
  }
  
  const handleItemIncrement = () => {
    const incrementObj = { newQuantity: item.quantity + 1 };
    handleUpdateGamePassCartItems(incrementObj);
  }
  
  const handleItemDecrement = () => {
    const decrementObj = { newQuantity: item.quantity - 1 };
    handleUpdateGamePassCartItems(decrementObj);
  }

  return (
    <div className="gamepass-cart-card">
      <div className="position-relative">
        <Checkbox
          className="gamepass-cart-checkbox"
          checked={item.isCheckout}
          onChange={(e) => handleItemChecked(e.target.checked)}
          sx={{
            color: "#615374",
            '&.Mui-checked': {
              color: "#f304a0",
              zIndex: 2
            },
          }}
        />
        {item.isCheckout && <div className="gamepass-cart-checkbox-floater"/>}
      </div>
      <img src={item.imageUrl} alt={item.name} className="gamepass-cart-card-image" />
      <div className="gamepass-cart-card-container">
        <div className="gamepass-cart-card-name">{item.name}</div>
        <div className="d-flex align-items-center gap-2">
          {item.subTotal.currencyIconUrl && 
            <img src={item.subTotal.currencyIconUrl} 
              alt="mgo-icon" 
              className="gamepass-cart-card-mgc" 
            />}
          <div className="gamepass-cart-card-text">{item.subTotal.text}</div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="gamepass-cart-card-wrapper">
            <RemoveCircleOutline
              onClick={handleItemDecrement}
              className="gamepass-cart-card-sign"
            />
            <div className="gamepass-cart-card-quantity">{item.quantity}</div>
            <AddCircleOutline
              onClick={handleItemIncrement}
              className="gamepass-cart-card-sign"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
