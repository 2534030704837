import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { 
  cancelStatus, 
  completedStatus, 
  failedStatus, 
  paymentStatus, 
  primaryType, 
  siteRoutes, 
  statusIcons, 
  successStatus, 
  transactionIdText,
  withdrawalStatus
} from '../../constants';

export const PaymentStatusContents = ({ details, newStatus, navigate }) => {
  const initialContents = {
    title: '',
    subTitle: '',
    buttonText: '',
    description: '',
  };

  const [contents, setContents] = useState(initialContents);

  const { details: data } = details || {};
  const isWithdrawal = details?.primaryType === primaryType.withdrawal;
  const { success, failed, pending } = withdrawalStatus(
    data?.amount, 
    data?.token, 
    data?.recipientAddress, 
    details?.referenceId
  );

  const handleContents = useCallback(() => {
    let updatedContents = { ...initialContents };

    if (newStatus === paymentStatus.cancel) {
      updatedContents = {
        ...updatedContents,
        title: cancelStatus.title,
        subTitle: cancelStatus.subTitle,
        buttonText: cancelStatus.buttonText,
      };
    } else {
      switch (details?.status) {
        case paymentStatus.pending:
          updatedContents = {
            ...updatedContents,
            title: isWithdrawal ? pending?.title : successStatus.title,
            subTitle: `${transactionIdText} ${details.id}`,
            buttonText: successStatus.buttonText,
            description: isWithdrawal ? pending?.description : successStatus.description,
          };
          break;
        case paymentStatus.completed:
          updatedContents = {
            ...updatedContents,
            title: isWithdrawal ? success?.title : completedStatus.title,
            subTitle: `${transactionIdText} ${details.id}`,
            buttonText: completedStatus.buttonText,
            description: isWithdrawal ? success?.description : completedStatus.description,
          };
          break;
        case paymentStatus.failed:
          updatedContents = {
            ...updatedContents,
            title: isWithdrawal ? failed?.title : failedStatus.title,
            subTitle: isWithdrawal ? failed?.description : failedStatus.subTitle,
            buttonText: failedStatus.buttonText,
          };
          break;
        default:
          break;
      }
    }

    setContents(updatedContents);
  }, [newStatus, details]);

  useEffect(() => {
    handleContents();
  }, [handleContents]);

  const handleNavigate = () => {
    let path = siteRoutes.wallet;
    if (isWithdrawal && 
      details?.status === paymentStatus.failed) {
      path = `${siteRoutes.withdrawalMethods}${siteRoutes.withdraw}`;
    }
    navigate(path);
  }

  const status = newStatus === paymentStatus.cancel ? paymentStatus.failed : details?.status;

  if (!isEmpty(contents.title)) {
    return (
      <div className="payment-status">
        <div className="payment-status-container">
          <div className="payment-status-details">
            <img 
              src={statusIcons[status]} 
              alt={status} 
              className="payment-status-icons" 
            />
            <div className="payment-status-text">{contents.title}</div>
            <div className="payment-status-description">{contents.subTitle}</div>
            {(details?.status === paymentStatus.pending ||
              details?.status === paymentStatus.completed) &&
              <div className={classNames(
                "payment-status-description", 
                {"payment-status-description-completed": details?.status === paymentStatus.completed}
              )}>
                {contents.description}
              </div>}
          </div>
          <div className="payment-status-wrapper">
            <button 
              onClick={handleNavigate}
              className="payment-status-button"
            >
              {contents.buttonText}
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
