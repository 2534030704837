import { setWalletAddressFormat } from "./setWalletAddressFormat";

const updatePaymentMethod = (method, updates) => ({
  ...method,
  description: updates.description,
  name: updates.name ?? method.name,
  imageUrl: updates.imageUrl ?? method.imageUrl,
});

export const overridePaymentMethodsData = (
  paymentMethods,
  newImageUrl,
  walletConnectCode,
  metaMaskCode,
  newDescription,
  connector
) => {
  const connectorUpdates = {
    description: setWalletAddressFormat(newDescription),
    name: connector?.name,
  };

  const updatedMethods = paymentMethods?.map(p => {
    if (p.code === walletConnectCode) {
      const updates = {
        ...connectorUpdates,
        imageUrl: connector?.name === metaMaskCode ? newImageUrl : p.imageUrl,
      };
      return updatePaymentMethod(p, updates);
    }
    return p;
  });

  return updatedMethods;
};