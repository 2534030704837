import React, { Fragment } from 'react';

export const GamePassCheckoutSku = ({ items }) => (
  <Fragment>
    {items.map((i, iIdx) => (
      <div
        className="checkout-card"
        key={`Item_${iIdx}_${i.productId}_${i.skuId}`}
      >
        <img
          src={i.imageUrl}
          alt={`banner ${i.skuKind}`}
          className="checkout-card-image"
        />
        <div className="d-flex align-items-start flex-column gap-2">
          <div className="checkout-card-name">{i.name} {`(${i.quantity}x)`}</div>
          <div className="d-flex align-items-center gap-1">
            {i.subTotal.currencyIconUrl && 
              <img className="checkout-card-icon" 
                src={i.subTotal.currencyIconUrl} 
                alt={i.name} 
              />}
            <div className="checkout-card-price">{i.subTotal.text}</div>
          </div>
        </div>
      </div>
    ))}
  </Fragment>
);
