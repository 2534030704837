import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Popup,
  QRScanner,
  Toast
} from '../Commons';
import {
  LayoutContent,
  Section,
  WithdrawalConfirmation
} from '../Components';
import { WithdrawOTP } from './WithdrawOTP';
import { 
  getWithdrawalNFT, 
  getWithdrawalNFTValidate, 
  resendEmailOTP, 
  setDisplayToast, 
  setLoading, 
  setWithdrawalNFTValidate, 
  startLoginOTPTimer, 
  submitLoginOTP, 
  tryLogin
} from '../../actions';
import { 
  defaultTimeoutMS,
  firebaseEvents,
  notEnoughMGCText, 
  otpDefaultTimes, 
  recaptchaSiteKey, 
  regexConstants, 
  texts 
} from '../../constants';
import {
  loginOTPTimerActiveSelector,
  walletCryptoSelector,
  displayLoginToastSelector,
  userProfileSelector,
  selectedNFTSelector,
  validatedNFTSelector
} from '../../selectors';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import { getWalletCredits } from '../../utils/getWalletCredits';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './NFTWithdrawal.scss';

export const NFTWithdrawal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
  const [notEnoughCredits, setNotEnoughCredits] = useState(false);
  const [destinationAddress, setDestinationAddress] = useState('');
  const [withdrawDisabled, setWithdrawalDisabled] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [result, setResult] = useState('');
  const [userCredit, setUserCredit] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
  const [recaptcha, setRecaptcha] = useState({});
  const [emailIsActive, setEmailIsActive] = useState(false);

  const crypto = useSelector(state => walletCryptoSelector(state));
  const timerActive = useSelector(state => loginOTPTimerActiveSelector(state));
  const selectedNft = useSelector(state => selectedNFTSelector(state));
  const validatedNft = useSelector(state => validatedNFTSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const layoutState = useSelector(state => userProfileSelector(state));
  
  const id = location.pathname.split('/')[2];

  useEffect(() => {
    if (!isEmpty(validatedNft)) setOpenConfirmation(true);
  }, [validatedNft])

  useEffect(() => {
    setToastOpen(true);
    setOpenCamera(false);
  }, [toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }
  
  useEffect(() => {
    dispatch(getWithdrawalNFT(id));
  }, [dispatch, id])

  useEffect(() => {
    destinationAddress === '' ? setWithdrawalDisabled(true) : setWithdrawalDisabled(false);
  },[destinationAddress, withdrawDisabled])

  useEffect(() => {
    if (result.data && result.data.length > 0) {
      const fullAddress = result.data.split(':').slice(-1)[0];
      setDestinationAddress(fullAddress);
    }
  }, [result])

  const handleQRScan = () => {
    setOpenCamera(true);
    setResult('');
  }

  useEffect(() => {
    const userCreditObject = getWalletCredits(crypto);
    if (userCreditObject) {
      setUserCredit(userCreditObject.val);
    }
  }, [crypto])
  
  const handleWithdraw = () => {
    const newUserCredit = parseFloat(userCredit.replace(regexConstants.comma, ''));
    const gasFeeValue = parseFloat(selectedNft.gasFee.value.replace(regexConstants.comma, ''));
    if (newUserCredit < gasFeeValue) {
      setNotEnoughCredits(true);
    } else {
      const nftValidateData = {
        collectionId: selectedNft.collectionId,
        nftId: id.toString(),
        toAddress: destinationAddress
      };
      setNotEnoughCredits(false);
      dispatch(getWithdrawalNFTValidate(nftValidateData));
    }
  }

  const handleBack = () => {
    dispatch(startLoginOTPTimer(false));
    showOTP ? setShowOTP(false) : navigate(-1);
  }

  const submitOTP = (otpDigits) => {
    const withdrawalData = {
      collectionId: selectedNft.collectionId,
      nftId: id.toString(),
      toAddress: destinationAddress
    }
    const otp = otpDigits.join('');
    dispatch(setLoading(true));
    dispatch(submitLoginOTP({
      otp,
      mobnum: layoutState.mobnum,
      withdrawal: withdrawalData
    }));
  }

  const sectionTitle = showOTP ? 'Verification' : 'NFT Withdrawal';

  const handleConfirmationHide = () => {
    setOpenConfirmation(false);
    dispatch(setWithdrawalNFTValidate({}));
  }

  const handleConfirm = () => {
    setOpenConfirmation(false);
    dispatch(setWithdrawalNFTValidate({}));
    handleLogin(layoutState.mobnum);
    dispatch(startLoginOTPTimer(true));
    setShowOTP(true);
  }

  const getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        setIsRecaptchaLoaded(true);
        setRecaptcha(recaptcha);
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  const handleLogin = (mobileNumber) => {
    const payload = { mobileNumber };
    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          dispatch(tryLogin(updatedPayload));
        })
        .catch(error => {
          dispatch(tryLogin(payload));
        });
    } else {
      dispatch(tryLogin(payload));
    }
  }

  const handleEmailLogin = (e) => {
    const payload = {
      mobileNumber: layoutState.mobnum,
      deviceInfo: getDeviceInfo()
    };
    e.preventDefault(e);
    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          dispatch(resendEmailOTP(updatedPayload));
        })
        .catch(error => {
          dispatch(resendEmailOTP(payload));
        });
    } else {
      dispatch(resendEmailOTP(payload));
    }
  };

  useEffect(() => {
    getRecaptcha();
  }, [])

  const resendOTP = (e) => {
    const payload = {
      mobileNumber: layoutState.mobnum,
      deviceInfo: getDeviceInfo()
    };

    e.preventDefault(e);

    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          dispatch(tryLogin(updatedPayload));
        })
        .catch(error => {
          dispatch(tryLogin(payload));
        });
    } else {
      dispatch(tryLogin(payload));
    }
  }

  const resendOTPEmail = () => {
    setEmailIsActive(true);
  }

  const stopTimerEmail = () => {
    setEmailIsActive(false);
  }
  
  return (
    <Fragment>
      {!isEmpty(selectedNft) && 
        <LayoutContent outerClassName="nft-withdrawal-outer"> 
          <Section
            title={sectionTitle}
            backButtonRoute
            backRoute={handleBack}
            sectionClassName="nft-withdrawal-section" 
            titleCentered
          />
          {!showOTP && 
            <div>
              <div className="nft-withdrawal">
                <div className="nft-withdrawal-details-container">
                  <img 
                    src={selectedNft.imageUrl} 
                    className="nft-withdrawal-details-image" 
                    alt={selectedNft.collectionName} 
                  />
                  <div className="nft-withdrawal-details-text-container">
                    <p className="nft-withdrawal-details-title">{selectedNft.collectionName}</p>
                    <p className="nft-withdrawal-details-text">{selectedNft.nftName}</p>
                  </div>
                </div>
                <p className="nft-withdrawal-title">{texts.network}</p>
                <span className="nft-withdrawal-network-text"> 
                  <img 
                    src={selectedNft.iconUrl} 
                    alt={selectedNft.chain} 
                    className="nft-withdrawal-network-icon"
                  /> 
                  {selectedNft.chain} 
                </span>
                <p className="nft-withdrawal-title">{texts.destinationWalletAddress}</p>
                <div className="position-relative">
                  <TextareaAutosize 
                    className="nft-withdrawal-input-container"
                    value={destinationAddress}
                    onChange={e => setDestinationAddress(e.target.value)}
                    placeholder="Enter Polygon Address"
                  />
                  <div onClick={handleQRScan} className="nft-withdrawal-qr" />
                </div>
                <div className="nft-withdrawal-notes-container">
                  <div className="nft-withdrawal-notes-text" dangerouslySetInnerHTML={{__html: selectedNft.notice}} />
                </div>
              </div>
              <div className="nft-withdrawal-gas-container">
                <div className="nft-withdrawal-gas-text-container">
                  <p className="nft-withdrawal-gas-text">{texts.gasFee}</p>
                  <span className="nft-withdrawal-gas-mgc"> 
                    <div className="nft-withdrawal-gas-mgc-icon" />
                    {selectedNft.gasFee.text}
                  </span>
                </div>
                <button 
                  className={classNames(
                    "nft-withdrawal-gas-button", 
                    {"nft-withdrawal-gas-button-disabled": withdrawDisabled}
                  )} 
                  onClick={handleWithdraw} 
                  disabled={withdrawDisabled}
                > 
                  {texts.withdrawNFT}
                </button>
              </div>
            </div>}
          <Popup 
            open={notEnoughCredits}
            hide={() => setNotEnoughCredits(false)}
            header
            headerTitle={texts.topUpMGC}
            footer
            buttonText={texts.topUpMGC}
            className="nft-withdrawal-popup"
            buttonClick={() => navigate('/wallet')}
          >
            <p className="nft-withdrawal-popup-text">
              {notEnoughMGCText}
            </p>
          </Popup>
          {showOTP && 
            <WithdrawOTP 
              showOTP={showOTP}
              emailIsActive={emailIsActive}
              texts={texts}
              timerActive={timerActive}
              otpMinutes={otpDefaultTimes.minutes}
              otpSeconds={otpDefaultTimes.seconds}
              stopTimer={() => dispatch(startLoginOTPTimer(false))}
              resend={resendOTP}
              submit={params => submitOTP(params)}
              resendOTP={resendOTPEmail}
              stopTimerEmail={stopTimerEmail}
              resendEmail={handleEmailLogin}
            />}
          {!isEmpty(validatedNft) && 
            <WithdrawalConfirmation 
              notice={selectedNft.notice}
              item={validatedNft}
              open={openConfirmation} 
              hide={handleConfirmationHide}
              handleConfirm={handleConfirm} 
            />}
          {openCamera && 
            <QRScanner setOpenCamera={setOpenCamera} setResult={setResult} result={result} />}
        </LayoutContent>}
      {toast && 
        <Toast toast={toast} open={toastOpen} hide={closeToast}/>}
    </Fragment>
  )
}
