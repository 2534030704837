import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { OTP } from '../Commons';

export const PINAuthentication = ({ otpMinutes, otpSeconds, transform, backToPIN, ...props }) => (
  <div className="pin-authentication" style={transform}>
    <div className="pin-authentication-content">
      <div className="pin-authentication-content-inner">
        <div className="pin-authentication-header">
          <div className="pin-authentication-header-back" onClick={backToPIN}><ArrowBackIosNewRoundedIcon /></div>
          <span className="pin-authentication-header-title">Authentication</span>
        </div>
        <div className="pin-authentication-message">
          <h1 className="pin-authentication-message-title">Verify with Text Message</h1>
          <p className="pin-authentication-message-description">
            Your 6-digit authentication code was sent to your registered mobile number
          </p>
        </div>
        <div className="pin-authentication-otp">
          <OTP {...props} description="Please enter the authentication code." time={{ minutes: otpMinutes, seconds: otpSeconds }} autoSubmit />
        </div>
      </div>
    </div>
  </div>
);