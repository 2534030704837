import React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import confetti from '../../../assets/rewards/bg/confetti.png';
import './BuyRewardModal.scss';

const BuyRewardModal = ({ 
  open, 
  hide, 
  title, 
  itemName,
  imageUrl, 
  description, 
  selectedReward, 
  confirm,
  handleGoToRewards,
  isSuccess
}) => {
  const onClickConfirm = () => {
    confirm();
    hide();
  }
  return (
    <Modal
      backdrop
      show={open}
      className="buy-reward-modal-container"
      contentClassName="buy-reward-modal"
      dialogClassName="buy-reward-modal-dialog"
      backdropClassName="buy-reward-modal-bg"
      centered
      onHide={hide}
    >
      <Modal.Body className="buy-reward-modal-body">
        {isSuccess && <img className="buy-reward-modal-confetti" src={confetti} alt="confetti" />}
        <div className={classNames(
          "buy-reward-modal-title",
          {"buy-reward-modal-title-success": isSuccess}
        )}>
          {title}&nbsp;
          {itemName && <span className="buy-reward-modal-item">{itemName}</span>}
        </div>
        {imageUrl &&
          <img 
            src={imageUrl} 
            alt={selectedReward?.kind} 
            className="buy-reward-modal-image"
          />}
        {description && <div className="buy-reward-modal-description">{description}</div>}
        {isSuccess ?
          <button className="buy-reward-modal-button-solo" onClick={handleGoToRewards}>Go to Rewards</button> :
          <div className="buy-reward-modal-button-container">
            <button className="buy-reward-modal-button" onClick={hide}>Cancel</button>
            <button className="buy-reward-modal-button" onClick={onClickConfirm}>Confirm</button>
          </div>}
      </Modal.Body>
    </Modal>
    )
}

export default BuyRewardModal;