import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import {
  instructionText,
  maxWidths,
  monthNames
} from '../../../constants';

export const BarcodeDetails = ({ selectedMethod, hide, handleNavigate }) => {
  const isDesktop = useMediaQuery({ minWidth: maxWidths.mobileSM });
  const d = new Date(selectedMethod.expiresAt);
  const month = monthNames[d.getMonth()];
  const updatedDateFormat = `${month.slice(0, 3)} ${d.getDate()}, ${d.getFullYear()}`;
  const updatedTimeFormat = new Date(selectedMethod.expiresAt).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
  })
  
  return (
    <>
    <div>
      <div className="barcode-show">Ask the cashier to scan this barcode:</div>
        <div className="barcode-image">
        <img className="w-100" src={selectedMethod?.barCodeImageUrl} alt="barcode"/>
      </div>
      <div className="barcode-body">
        <Row className="barcode-row">
          <Col md={6} lg={6}>
            <Row className={classNames({"barcode-row": !isDesktop})}>
              <Col xs={6} md={12} lg={12}><div className="barcode-body-title">Payment Channel:</div></Col>
              <Col xs={6} md={12} lg={12}><div className="barcode-body-subtitle">{selectedMethod.details?.paymentChannel?.name}</div></Col>
            </Row>
          </Col>
          <Col md={6} lg={6}>
            <Row>
              <Col xs={6} md={12} lg={12}><div className="barcode-body-title">Payment Code:</div></Col>
              <Col xs={6} md={12} lg={12}><div className="barcode-body-subtitle">{selectedMethod?.barCode}</div></Col>
            </Row>
          </Col>
        </Row>
        <Row className="barcode-row">
          <Col md={6} lg={6}>
            <Row className={classNames({"barcode-row": !isDesktop})}>
              <Col xs={6} md={12} lg={12}><div className="barcode-body-title">Pay to Merchant:</div></Col>
              <Col xs={6} md={12} lg={12}><div className="barcode-body-subtitle">Xendit</div></Col>
            </Row>
          </Col>
          <Col md={6} lg={6}>
            <Row>
              <Col xs={6} md={12} lg={12}>
                <div className="barcode-body-title">Amount to Pay:</div>
              </Col>
              <Col xs={6} md={12} lg={12}>
                <div className="barcode-body-subtitle">{selectedMethod.details?.fromAmount?.text}</div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="barcode-row">
          <Col md={6} lg={6}>
            <Row className={classNames({"barcode-row": !isDesktop})}>
              <Col xs={6} md={12} lg={12}>
                <div className="barcode-body-title">Pay Before:</div>
              </Col>
              <Col xs={6} md={12} lg={12}>
                <div className="barcode-body-subtitle">{updatedDateFormat} {updatedTimeFormat}</div>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={6}>
            <Row>
              <Col xs={6} md={12} lg={12}>
                <div className="barcode-body-title">Transaction ID:</div>
              </Col>
              <Col xs={6} md={12} lg={12}>
                <div className="barcode-body-subtitle">{selectedMethod.id}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {isDesktop && <div>
        <div className="barcode-instruction">{instructionText}</div>
        <button onClick={handleNavigate} className="barcode-view-button mb-2">View Instruction</button>
        <button onClick={hide} className="barcode-close-button">Close</button>
      </div>}
    </div>
    </>
  )
}
