import axios from 'axios';
import { baseUrl } from './url';

export function requestUserProfilePage(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/profile')
  });
}

export function requestUserProfileFields(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/profile/details')
  });
}

export function requestEditUserProfile(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/profile/details')
  });
}

export function requestUploadProfilePhoto(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken, 'Content-Type': 'multipart/form-data' },
    data: body,
    url: baseUrl('me/profile/pfp')
  });
}

export function requestTransactions(url) {
  return axios.request({
    method: 'GET',
    url
  });
}

export function requestSetNewPIN(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/pin')
  });
}

export function requestValidatePIN(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/pin/validate')
  });
}