import React from 'react';
import classNames from 'classnames';

export const Sections = ({ type, sections, hasSectionPrefix, titleCentered }) => (
  <div className="legal-statement-sections">
    {sections.map((s, sIdx) => {
      const { title, contents } = s;
      const sectionNumber = sIdx + 1;
      const hasSectionNumber = type !== 'FairPlayPolicy';
      return (
        <div key={`${type}_Section_${sectionNumber}`} className="legal-statement-section">
          <div className={classNames("legal-statement-title", {"legal-statement-center": titleCentered})}>
            {hasSectionPrefix && <span>Section&nbsp;</span>} {hasSectionNumber && `${sectionNumber}. `}
            <span className="legal-statement-uppercase">{title}</span>
          </div>
          <div className="legal-statement-section-contents">
            {contents.length > 0 && contents.map((c, cIdx) => {
              const gamePassSection = title === 'About the Site' && cIdx === contents.length - 1;
              const contentId = gamePassSection ? 'gamepass' : undefined;
              return (
                <div
                  key={`${type}_Section_${sectionNumber}_Content_${cIdx}`}
                  id={contentId}
                  dangerouslySetInnerHTML={{ __html: c }}
                  className="legal-statement-section-paragraph"
                />
              );
            })}
          </div>
        </div>
      );
    })}
  </div>
);