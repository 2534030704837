import { connect } from 'react-redux';
import { getUserCommunity } from '../actions';
import { guestSelector, guildSelector, sessionExpiredSelector } from '../selectors';
import { Guild } from '../components';

const mapStateToProps = (state) => ({
  guild: guildSelector(state),
  sessionExpired: sessionExpiredSelector(state),
  isGuest: guestSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getGuild: (guildId) => dispatch(getUserCommunity(guildId))
});

const GuildContainer = connect(mapStateToProps, mapDispatchToProps)(Guild);

export default GuildContainer;