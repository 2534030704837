import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { referralProgress, storageKeys } from '../../constants';

export const ReferralProgress = ({ isGuest, freeSpinCount }) => {
  const location = useLocation();

  const referralCode = Cookies.get(storageKeys.referralCode);
  const highlightFirstNumber = isGuest && referralCode;
  const highlightAllNumbers = location?.state?.fillNumbers;

  return (
    <div className="referral-screen-progress">
      <div className="referral-screen-progress-title">{referralProgress.title}</div>
      <div className="referral-screen-progress-wrapper">
        <div className="referral-screen-progress-dotted"/>
        {referralProgress.data.map((r, rIdx) => {
          return (
            <div 
              className="referral-screen-progress-box" 
              key={`ReferralProgress_${r.id}_${rIdx}`}
            >
              <div className={classNames(
                "referral-screen-progress-box-number",
                {"referral-screen-progress-box-number-fill": 
                  (highlightFirstNumber && r.id === 1 || 
                  highlightAllNumbers && freeSpinCount > 0)}
              )}>
                {r.id}
              </div>
              <div className="referral-screen-progress-box-title">{r.title}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
