import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FloatingContainer, Scheduler } from '../../Commons'
import DiscordDailyBoostIcon from '../DiscordDailyBoostIcon/DiscordDailyBoostIcon';
import { guestSelector } from '../../../selectors';
import { 
  defaultModals, 
  gameNightEndTimes, 
  gameNightStartTimes, 
  storageKeys 
} from '../../../constants';
import { showActiveModal } from '../../../actions';

const FloatingIconManager = () => {
  const dispatch = useDispatch();
  const isGuest = useSelector(state => guestSelector(state));
  const isTutorial = localStorage.getItem(storageKeys.isTutorial);

  const showDiscordDailyBoost = () => {
    dispatch(showActiveModal(defaultModals.goldBonanza));
  }

  return (
    <FloatingContainer
      isGuest={isGuest}
      isTutorial={isTutorial}
    >
      <Scheduler
        startTimes={gameNightStartTimes} 
        endTimes={gameNightEndTimes}
      >
        <DiscordDailyBoostIcon showDiscordDailyBoost={showDiscordDailyBoost} />
      </Scheduler>
    </FloatingContainer>
  )
}

export default FloatingIconManager;