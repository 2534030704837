import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../hooks/useQuery';
import {
  LayoutContent,
  // MechanicsCarousel,
  PageTitle,
  Section
} from '../Components';
import { HiddenHeaderAndFooter } from '../Commons';
import { ReferralMechanicsFAQs } from './ReferralMechanicsFAQs';
import { setHideFooter } from '../../actions';
import { referralMechanics } from '../../constants';
import './ReferralMechanics.scss';

export const ReferralMechanics = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const mobile = query.get('mobile');
  const backToPreviousPage = () => window.history.back();

  useEffect(() => {
    dispatch(setHideFooter(true));
  }, [dispatch]);

  const title = mobile ? '' :  referralMechanics.title;

  return (
    <>
      {referralMechanics &&
        <LayoutContent outerClassName="referral-mechanics" innerClassName="referral-mechanics-wrapper">
          <PageTitle title="Refer A Friend Mechanics" />
          <HiddenHeaderAndFooter 
            hideFooter
            hideHeader
            hideMenuIcon
          />
          <Section 
            sectionClassName="referral-mechanics-section"
            sectionHeaderClassName="referral-mechanics-header"
            title={title}
            titleCentered
            backButtonRoute={!mobile}
            backRoute={backToPreviousPage}
          >
            <div className="referral-mechanics-content">
              <div className="referral-mechanics-description-container">
                <div className="referral-mechanics-description">
                  {referralMechanics.description}
                </div>
              </div>
              {/* temporarily remove the carousel
              <MechanicsCarousel {...referralMechanics.mechanics} /> */}
              <ReferralMechanicsFAQs {...referralMechanics.faqs} />
            </div>
          </Section>
        </LayoutContent>}
    </>
  );
}