import React from 'react';
import classNames from 'classnames';
import { Phone } from '../Commons';
import { referralSMSTexts } from '../../constants';
import './ReferralScreen.scss';

export const ReferralSMS = ({
  countryCode,
  setCountryCode,
  mobileNumber,
  handleMobileNumberInput,
  handleInvite,
  emptyMobileNumberError
}) => (
  <>
    <div className="referral-screen-referral-link-label">{referralSMSTexts.label}</div>
    <div className={classNames(
      "referral-screen-referral-link-mobile-number",
      {"referral-screen-referral-link-mobile-number-error": emptyMobileNumberError}
    )}>
      <Phone
        type="text"
        mobileNumber={mobileNumber}
        onChange={(e) => handleMobileNumberInput(e.target.value)}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        className="referral-screen-referral-link-mobile-number-input"
        dropdownMenuClassName="referral-screen-referral-link-mobile-number-dropdown"
        placeholder={referralSMSTexts.placeholder}
        hasFilter
        hideCountryFlag
        hideCountryCode
        removeAutoFocus
      />
      <button
        className="referral-screen-referral-link-button"
        onClick={handleInvite}
      >
        {referralSMSTexts.button}
      </button>
    </div>
    {emptyMobileNumberError &&
      <div className="referral-screen-referral-link-error">{referralSMSTexts.emptyError}</div>}
  </>
);