import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { GamePassProductAmount } from '../Components';
import { GridItem, HorizontalScroll } from '../Commons';
import { minWidths, maxWidths, defaultCTATexts } from '../../constants';

export const WalletCollection = ({ handleShowCollectibles, collections, walletEmpty, handleSelectedItem }) => {
  const location = useLocation();
  const userId = useSelector((state) => state.layout.userProfile.uid);

  useEffect(() => {
    const location = window.location.search;
    const queryParams = new URLSearchParams(location);
    const collectiblesIsVisible = queryParams.get("show");
    if (collectiblesIsVisible) {
      handleShowCollectibles(true);
    } else {
      handleShowCollectibles(false);
    }
  },[location, handleShowCollectibles])

  const sm = useMediaQuery({ minWidth: minWidths.mobileSM, maxWidth: maxWidths.mobileSM });
  const md = useMediaQuery({ minWidth: minWidths.tablet, maxWidth: maxWidths.tablet });
  const lg = useMediaQuery({ minWidth: minWidths.desktopSM, maxWidth: maxWidths.desktopLG });
  const xl = useMediaQuery({ minWidth: minWidths.desktopXL, maxWidth: maxWidths.desktopXXL });
  const xxxl = useMediaQuery({ minWidth: minWidths.desktopXXXL });

  let slidesToShow = 2;
  if (sm) slidesToShow = 3;
  else if (md) slidesToShow = 4;
  else if (lg) slidesToShow = 5;
  else if (xl) slidesToShow = 6;
  else if (xxxl) slidesToShow = 7;

  return (
    <>
      {collections.length > 1 && 
        <Link
          to="/wallet?show=collectibles"
          className="collection-list-button"
        >
          {defaultCTATexts.viewAll}
        </Link>}
      {
        isEmpty(collections) ? (
          <div className="wallet-empty">{walletEmpty}</div>
        ) : (
          <Row className="collection-list">
            <HorizontalScroll slidesToShow={slidesToShow}>
              {collections.map(i => (
                <GridItem key={`COLLECTIONS_${i.id}`} className="collection-list-item nft">
                  <Link to={`/wallet/${i.id}`} onClick={() => handleSelectedItem(i)} className="collection-list-item-link mt-3">
                    <div className="collection-list-image position-relative">
                      <div className="collection-list-image-nft" style={{ backgroundImage: `url(${i.image_url})`}}></div>
                      {
                        !isEmpty(i.nftListing) && 
                        userId === i.nftListing?.uid && 
                        <div className="collection-list-image-indicator">Listed</div>
                      }
                    </div>
                    <div className="collection-list-item-wrapper">
                      <div className="collection-list-item-wrapper-title">{i.collectionName}</div>
                      <div className="collection-list-item-wrapper-name">{i.name}</div>
                      {i.nftListing && 
                        <GamePassProductAmount 
                          {...i.nftListing} 
                          isMarketplace 
                        />}
                    </div>
                  </Link>
                </GridItem>
              ))}
            </HorizontalScroll>
          </Row>
        )
      }
    </>
  )
}

export default WalletCollection;