import React from 'react';
import isEmpty from 'lodash/isEmpty';
import './GamePassGame.scss';

const HowToPlay = ( {mechanics} ) => (
  <div className="gamepass-game-how-to-play">
    <h6 className="gamepass-game-how-to-play-title">How to Play</h6>
    {!isEmpty(mechanics) && mechanics.map((m, mIdx) => (
      <p key={`MECHANICS_KEY_${mIdx}`} className="gamepass-game-description mt-2">{m}</p>
    ))}
  </div>
);

export default HowToPlay;