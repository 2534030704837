import React, { Component } from 'react';
import { load } from 'recaptcha-v3';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { LayoutContent, PageTitle, WalletCredits } from '../Components';
import { Phone, Processing, Toast } from '../Commons';
import CheckoutTable from './CheckoutTable';
import SpendSuccess from './SpendSuccess';
import { SpendOTP } from './SpendOTP';
import {
  defaultMobileNumberPrefix,
  defaultTimeoutMS,
  firebaseEvents,
  loginTexts,
  mgcDefaultCredits,
  mobileNumberEmptyInputMessage,
  mobileNumberValidationErrorMessage,
  otpDefaultTimes,
  recaptchaSiteKey,
  regexConstants,
  storageKeys
} from '../../constants';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import { getWalletCredits } from '../../utils/getWalletCredits';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './Spend.scss';

var CryptoJS = require("crypto-js");
export class Spend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptcha: {},
      isRecaptchaLoaded: false,
      countryCode: defaultMobileNumberPrefix,
      deviceInfo: getDeviceInfo(),
      mobileNumber: '',
      mobileNumberValidated: true,
      mobileNumberInputDisabled: false,
      mobileNumberInputError: false,
      selectedPaymentMethod: 'USDC',
      selectedProduct: {},
      selectedProductValidated: true,
      selectedProductInputDisabled: false,
      currentStep: 1,
      sortedProduct: [],
      emailIsActive: false,
      otpMinutes: otpDefaultTimes.minutes,
      otpSeconds: otpDefaultTimes.seconds,
    };
  }

  componentDidMount() {
    const { setLoading, getSpendCountry } = this.props;
    setLoading(false);
    getSpendCountry();
    this.getRecaptcha();
    this.updateMobileNumberOnLoad();
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { products, confirmation, paymentStep, isSuccess } = this.props;
    const { mobileNumber, selectedPaymentMethod, selectedProductInputDisabled, countryCode } = this.state;

    
    if (prevState.mobileNumber !== mobileNumber) {
      if (mobileNumber.length === 10) {
        const formData = {
          mobnum10: mobileNumber,
          cur: selectedPaymentMethod,
          dialCode: countryCode
        };
        this.disableMobileNumberInput(true);
        this.disableSelectedProductInput(true);
        this.props.getMobileLoadProducts(formData);
      }
      if (mobileNumber.length < 10 && !selectedProductInputDisabled) {
        this.disableSelectedProductInput(true);
      }
    }

    if (prevProps.products !== products) {
      this.disableMobileNumberInput(false);
      this.disableSelectedProductInput(false);
    }

    if (prevProps.isSuccess !== isSuccess) {
      this.confirm();
    }

    if (prevProps.confirmation !== confirmation) {
      this.newPaymentStep(3)
    }

    if (prevProps.paymentStep !== paymentStep){
      if (paymentStep === 2) this.sendCode();
      const scrollTop = document.querySelector('.content');
      scrollTop.scroll(0,0);
      this.setState({
        otpMinutes: otpDefaultTimes.minutes,
        otpSeconds: otpDefaultTimes.seconds
      });
      this.props.startOTPTimer(false);
    }

    if (prevProps.products !== products){
      if(products && products.list.length > 0) {
        const sorted = products.list.sort((a,b) => a.product - b.product);
        this.setState({sortedProduct: sorted});
      }
    }

    if (prevProps.toast !== this.props.toast && this.props.toast) {
      this.openToast(true);
    }
  }

  getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        this.setState({
          recaptcha,
          isRecaptchaLoaded: true
        });
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  };

  closeToast = () => {
    const { toast, setPaymentStep } = this.props;
    this.openToast(false);
    if (toast.message.includes("OTP required")) setPaymentStep(2);
    setTimeout(() => this.props.setDisplayToast(), defaultTimeoutMS);
  };

  disableMobileNumberInput = (mobileNumberInputDisabled) => {
    this.setState({ mobileNumberInputDisabled });
  }

  setMobileNumberInputError = (mobileNumberInputError) => {
    this.setState({ mobileNumberInputError });
  }

  validateMobileNumber = (mobileNumberValidated) => {
    this.setState({ mobileNumberValidated });
  }

  validateSelectedProduct = (selectedProductValidated) => {
    this.setState({ selectedProductValidated });
  }

  disableSelectedProductInput = (selectedProductInputDisabled) => {
    this.setState({ selectedProductInputDisabled });
  }

  setCountryCode = (countryCode) => {
    this.setState({ countryCode });
  }

  updateMobileNumberOnLoad = () => {
    const { userProfile } = this.props;
    if (!userProfile.mobnum) return;
    const newNumber = userProfile.mobnum.replace(regexConstants.quotes, '').slice(3);
    this.setState({ mobileNumber: newNumber});
  }

  updateMobileNumber = (e) => {
    this.setMobileNumberInputError(false);
    this.validateMobileNumber(true);
    this.setState({ mobileNumber: e.target.value});
  }

  updateSelectedProduct = (selectedProduct) => {
    this.validateSelectedProduct(true);
    this.setState({ selectedProduct });
  }


  handleEmailLogin = (e) => {
    const { isRecaptchaLoaded, recaptcha, countryCode, mobileNumber, deviceInfo } = this.state;
    const payload = {
      mobileNumber: `${countryCode}${mobileNumber}`,
      deviceInfo
    };

    e.preventDefault(e);

    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          this.props.resendEmailOTP(updatedPayload);
        })
        .catch(error => {
          this.props.resendEmailOTP(payload);
        });
    } else {
      this.props.resendEmailOTP(payload);
    }
  };

  newPaymentStep = (currentStep) => {
    const { mobileNumber, selectedProduct } = this.state;
    if (currentStep === 3 && (mobileNumber === '' || (mobileNumber !== '' && !/9\d{9}/.test(mobileNumber)))) {
      this.validateMobileNumber(false);
    } else if (currentStep === 3 && isEmpty(selectedProduct)) {
      this.validateSelectedProduct(false);
    } else {
      this.validateMobileNumber(true);
      this.validateSelectedProduct(true);
      this.disableMobileNumberInput(false);
      this.disableSelectedProductInput(false);
      this.props.setPaymentStep(1);
      this.stopTimerEmail();
    }
  }

  sendCode = () => {
    const { isRecaptchaLoaded, recaptcha, deviceInfo } = this.state;
    const payload = { deviceInfo };

    this.props.setLoading(true);

    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          this.props.sendSpendOTP(updatedPayload);
        })
        .catch(error => {
          this.props.sendSpendOTP(payload);
        });
    } else {
      this.props.sendSpendOTP(payload);
    }
  };

  submitOTP = (otpDigits) => {
    const otp = otpDigits.join('');
    const encryptedMobileNumber = localStorage.getItem(storageKeys.mobileNumber);
    const bytes = CryptoJS.AES.decrypt(encryptedMobileNumber, storageKeys.mobileNumber.toLowerCase());
    const mobileNumberLS = bytes.toString(CryptoJS.enc.Utf8).replace(regexConstants.quotes, '');

    let mobnum = '';
    if (mobileNumberLS && mobileNumberLS !== '') mobnum = mobileNumberLS;
    this.props.setLoading(true);

    this.props.submitSpendOTP({ otp, mobnum });
  };

  confirm = () => {
    const { products, crypto, setDisplayToast } = this.props;
    const { mobileNumber, selectedProduct, selectedPaymentMethod, countryCode } = this.state;
    const userMgc = getWalletCredits(crypto);
    const userMgcValStr = userMgc ? userMgc.val : mgcDefaultCredits;
    const userMgcVal = parseFloat(userMgcValStr);
    const productMgcVal = selectedProduct.price;
    
    if (userMgcVal < productMgcVal) {
      setDisplayToast({
        result: false,
        message: 'You do not have enough MGC balance.'
      })
      return;
    }

    if (isEmpty(selectedProduct)) {
      if (mobileNumber === '') {
        this.setMobileNumberInputError(true);
      } else {
        if (products && !isEmpty(products)) {
          this.validateSelectedProduct(false);
        } else {
          this.validateMobileNumber(false);
        }
      }
    } else {
      if (mobileNumber === '') {
        this.setMobileNumberInputError(true);
      } else {
        const confirmData = {
          mobnum10: mobileNumber,
          cur: selectedPaymentMethod,
          productId: selectedProduct.id,
          dialCode: countryCode
        };
        this.disableMobileNumberInput(true);
        this.disableSelectedProductInput(true);
        this.props.confirmMobileLoadProduct(confirmData);
      }
    }
  }

  submit = () => {
    const { paymentAddress, priceAmount } = this.props.confirmation;
    const { mobileNumber, selectedProduct, selectedPaymentMethod, countryCode } = this.state;
    const paymentData = {
      mobnum10: mobileNumber,
      cur: selectedPaymentMethod,
      productId: selectedProduct.id,
      paymentAddress,
      total: priceAmount,
      dialCode: countryCode
    };
    this.props.payMobileLoadProduct(paymentData);
  }

  resendOTP = () => {
    this.setState({ emailIsActive: true });
  }

  stopTimerEmail = () => {
    this.setState({ emailIsActive: false });
  }


  render() {
    const { 
      products, 
      confirmation, 
      crypto, 
      paymentStep, 
      isLoading, 
      isError, 
      toast, country,
      timerActive,
      startOTPTimer,
      setTopUpVisible
    } = this.props;
    const {
      countryCode,
      selectedProduct,
      mobileNumber,
      mobileNumberValidated,
      mobileNumberInputDisabled,
      mobileNumberInputError,
      selectedProductValidated,
      selectedProductInputDisabled,
      sortedProduct,
      toastOpen,
      emailIsActive,
      otpMinutes,
      otpSeconds
    } = this.state;
    let translate = "";
    let heightStyle = {};
    if (paymentStep === 1) {
      translate = "0";
      heightStyle = { height: "100%" };
    }
    if (paymentStep === 2) {
      translate = "-100%";
      heightStyle = { height: "10px" };
    }
    if (paymentStep === 3) {
      translate = "-200%";
      heightStyle = { height: '10px' };
    }
    if (paymentStep === 4) {
      translate = "-300%";
      heightStyle = { height: '10px' };
    }
    const incompleteMobileNumber = mobileNumber.length < 10;
    const nextStepButtonDisabled = mobileNumber.length < 10 || mobileNumberInputDisabled;

    return (
      <LayoutContent>
        <PageTitle title="Spend" />
        <div className="spend-header">Buy Mobile Prepaid</div>
        <div className="spend-details">
          <div
            id="spend-first-step"
            className="spend-first-step"
            style={{ transform: `translateX(${translate})` }}
          >
            <WalletCredits crypto={crypto} handleShowTopUp={setTopUpVisible}/>
            <div className="spend-first-step-inner">
              <div className="spend-form-group">
                <label className="spend-form-label">Enter Prepaid Mobile Number</label>
                <div
                  className={classNames("spend-mobile-number-container", {
                    "spend-mobile-number-error":
                      mobileNumberInputError || !mobileNumberValidated,
                  })}
                >
                  <Phone
                    type="text"
                    onChange={(e) => this.updateMobileNumber(e)}
                    country={country && country}
                    countryCode={countryCode}
                    setCountryCode={this.setCountryCode}
                    className="spend-mobile-number"
                    dropdownMenuClassName="spend-country-code-dropdown"
                    hasFilter
                    mobileNumber={mobileNumber}
                  />
                </div>
              </div>
              {sortedProduct && products && !isEmpty(products) && (
                <div className="amount-options" style={heightStyle}>
                  {products.list.length > 0 && sortedProduct.map((p) => (
                    <div
                      key={`Product_${products.operator.id}_${p.id}`}
                      className="amount-options-item"
                      onClick={() =>
                        selectedProductInputDisabled || incompleteMobileNumber
                          ? null
                          : this.updateSelectedProduct(p)
                      }
                    >
                      <div
                        className={classNames("amount-details", {
                          "amount-details-selected":
                            p.id === selectedProduct.id,
                          "amount-details-error": !selectedProductValidated,
                          "amount-details-disabled":
                            p.id !== selectedProduct.id &&
                            selectedProductInputDisabled,
                        })}
                      >
                        <span className="currency">{p.product_currency}</span>
                        <span className="amount">{p.product}</span>
                        <div className="conversion">
                          <div className="conversion-mgc" />
                          <span>{p.price}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {mobileNumberInputError && <div className="validation-error">{mobileNumberEmptyInputMessage}</div>}
              {!mobileNumberValidated && <div className="validation-error">{mobileNumberValidationErrorMessage}</div>}
              {!selectedProductValidated && <div className="validation-error">Please select an amount.</div>}
              <div className="next-step">
                <button
                  className={classNames("next-step-button", {
                    "next-step-button-disabled": nextStepButtonDisabled,
                  })}
                  onClick={() =>
                    nextStepButtonDisabled ? null : this.confirm()
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <div 
            className={classNames('spend-otp-step', {'spend-otp-show': paymentStep === 2}, {'spend-otp-hide': paymentStep !== 2})} 
            style={{ transform: `translateX(${translate})` }}>
            <SpendOTP
              authStep={paymentStep}
              timerActive={timerActive}
              emailIsActive={emailIsActive}
              submit={this.submitOTP}
              texts={loginTexts.otp}
              backToProduct={() => this.newPaymentStep(1)}
              stopTimer={() => startOTPTimer(false)}
              otpMinutes={otpMinutes}
              otpSeconds={otpSeconds}
              resendEmail={this.handleEmailLogin}
              resendOTP={this.resendOTP}
              stopTimerEmail={this.stopTimerEmail}
            />
          </div>
          <div
            className="checkout spend-second-step"
            style={{ transform: `translateX(${translate})` }}
          >
            <div className="back-to-first-step">
              <button
                className="previous-step-button"
                onClick={() => this.newPaymentStep(1)}
              >
                <ArrowBackIosNewRoundedIcon className="previous-step-button-icon" />
                Payment details
              </button>
            </div>
            <div id="spend-second-step" className="spend-second-step-inner">
              {confirmation && !isEmpty(confirmation) && (
                <div className="checkout-details">
                  <div className="checkout-details-row">
                    <span>Total Amount to Pay</span>
                    <div className="checkout-details-main">
                      <span className="amount">{confirmation.priceAmount}</span>
                      <div className="conversion-mgc" />
                      <span className="note">
                        Prices may change after payment due to slippage
                        tolerance of 0.5%
                      </span>
                    </div>
                  </div>
                  <CheckoutTable
                    id="Product"
                    data={{
                      title: "Product",
                      details: [
                        { label: "Mobile Number", value: mobileNumber },
                        { label: "Product", value: confirmation.productAmount },
                      ],
                    }}
                  />
                  <CheckoutTable
                    id="Amount"
                    data={{
                      title: "Payment Details",
                      details: [
                        { label: "Amount", value: confirmation.priceAmount },
                        {
                          label: "Fees",
                          value: confirmation.waived
                            ? "Waived"
                            : confirmation.fee,
                        },
                      ],
                    }}
                  />
                </div>
              )}
              <div className="checkout-button-wrapper" style={{transform: `translateY(${isLoading ? '-20px' : '0px'})`}}>
                <button onClick={this.submit} className="checkout-button" disabled={isLoading}>
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Processing/>
                      <p>Payment Processing..</p>
                    </div>
                  ) : (
                    "Pay"
                  )}
                </button>
              </div>
              {isLoading && (
                <div className="checkout-wait" style={{transform: `translateY(${isLoading ? '-25px' : '0px'})`}}>
                  Please wait while we process your transaction.
                </div>
              )}
            </div>
          </div>
          <SpendSuccess
            isError={isError}
            selectedProduct={selectedProduct}
            mobileNumber={mobileNumber}
            currentStep={() => this.props.setPaymentStep(1)}
            translate={{ transform: `translateX(${translate})` }}
          />
          {toast && <Toast toast={toast} open={toastOpen} hide={this.closeToast}/>}
        </div>
      </LayoutContent>
    );
  }
}