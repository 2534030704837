import { 
  SET_USER_COLLECTIONS, 
  SET_USER_COLLECTION,
  RESET_STATE
} from '../actions/constants';

const collectionsState = {
  list: [],
  selected: {},
};

const collectionsReducer = (state = collectionsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return collectionsState;
    case SET_USER_COLLECTIONS:
      return {
        ...state,
        list: action.userCollections
      };
    case SET_USER_COLLECTION:
      return {
        ...state,
        selected: action.userCollection
      };
    default:
      return state;
  }
}

export default collectionsReducer;