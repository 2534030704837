import axios from 'axios';
import { baseUrl } from './url';

export function requestUserCommunities(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('guilds')
  });
}

export function requestUserCommunity(guildId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`guilds/${guildId}`)
  });
}

export function requestGuildApplicationForm(guildId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`guilds/${guildId}/apply`)
  });
}

export function requestSubmitGuildApplicationForm(guildId, body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`guilds/${guildId}/apply`)
  });
}