import React from 'react';
import classNames from 'classnames';
import { Phone } from '../../Commons';
import {
  formInputKinds,
  onboardingTexts,
  storageKeys
} from '../../../constants';

export const GamePassCheckoutForm = ({ 
  countryCode, 
  setCountryCode, 
  hideEmail,
  toast,
  handleInputChange,
  handleCheckoutPay,
  checkoutForm
}) => (
  <div>
    <div className="game-pass-checkout-number">
      <div className="game-pass-checkout-number-title">
        {onboardingTexts.enterMobileNumber}
      </div>
      <Phone
        type="text"
        onChange={(e) => handleInputChange(storageKeys.mobileNumber, e.target.value)}
        countryCode={countryCode}
        width={18}
        height={18}
        setCountryCode={setCountryCode}
        mobileNumber={checkoutForm?.mobileNumber}
        className={classNames(
          "game-pass-checkout-number-input",
          {"game-pass-checkout-number-input-error": toast?.message}
        )}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleCheckoutPay();
          }
        }}
        dropdownMenuClassName="game-pass-checkout-number-input-dropdown"
        hasFilter
        hideCountryFlag
      />
      {toast?.message &&
        <div className="game-pass-checkout-number-input-text-error">
          {toast?.message}
        </div>}
    </div>
    {!hideEmail && 
      <div className="game-pass-checkout-email">
        <div className="game-pass-checkout-number-title">
          {onboardingTexts.enterEmailAddress}
        </div>
        <input
          className="game-pass-checkout-email-input"
          onChange={(e) => handleInputChange(formInputKinds.email, e.target.value)}
          value={checkoutForm?.email} 
        />
      </div>}
  </div>
);
