import React, { useState } from 'react'
import { BackArrow } from '../Commons'
import { LayoutContent, PageTitle } from '../Components'
import { useDispatch, useSelector } from 'react-redux';
import { getRedeem } from '../../actions';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import './Redeem.scss'

const Redeem = () => {
  const [value, setValue]= useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const redeem = useSelector(state => state.redeem.redeem);
  const splittedUrl = location.pathname.split('/');
  const id = splittedUrl[2];
  const prize = splittedUrl[3];
  const data = {
    promotionId: id,
    sku: prize,
    info: {
      mobnum: "63" + value
    }
  }

  const getImage = () => {
    if (prize.includes("50")) return "load";
    if (prize.includes("20")) return "load-small";
    if (prize.includes("MGO")) return "mgc";
    if (prize.includes("METAPUP")) return "metapup";
    if (prize.includes("GCASH")) return "gcash";
    if (prize.includes("IPHONE")) return "iphone";
  }
  
  const getPrize = () => {
    if (prize.includes("50")) return "PHP 50 Globe load";
    if (prize.includes("20")) return "PHP 20 Globe load";
    if (prize.includes("GCASH")) return "PHP 1000 Gcash";
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const handleRedeem = () => {
   if (isEmpty(redeem)) dispatch(getRedeem(data))
   if (!isEmpty(redeem)) window.location.href = '/';
  }

  return (
    <LayoutContent outerClassName="redeem" innerClassName="redeem-inner">
      <PageTitle title={'Redeem'} />
      <BackArrow />
      <div className="redeem-container">
        <div className="redeem-container-wrapper">
          <div className={`redeem-container-${getImage()}`}></div>
          <div className="redeem-container-icon"></div>
          {!isEmpty(redeem) && <div className="redeem-container-title" dangerouslySetInnerHTML={{__html: redeem.message}}/>}
          {isEmpty(redeem) && 
          <>
          <div className="redeem-container-title">{`Congratulations! You won ${getPrize()} from Spin the Wheel.`}</div>
          <div className="redeem-container-desc">Enter a Globe prepaid number to redeem prize.</div>
          </>}
          <div className="w-100 mt-3">
            {isEmpty(redeem) && <div className="position-relative">
              <div className="redeem-container-input-country">+63</div>
              <input 
                onChange={(e) => handleChange(e)} 
                type="text" 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} 
                autoFocus 
                className="redeem-container-input"/>
            </div>}
            <button onClick={handleRedeem} className={classNames("redeem-container-button mt-3", {"redeem-container-button-disabled": value.length < 10})}>
            {isEmpty(redeem) ? "Redeem" : "Back to home"}
            </button>
          </div>
        </div>
      </div>
    </LayoutContent>
  )
}

export default Redeem