import { operatingSystems } from '../constants';
import { getDeviceInfo } from './getDeviceInfo';

export const isMobile = () => {
  const mobileOS = operatingSystems.filter(os => (
    os.version !== 'OS X'  // Macintosh
      && os.version !== 'NT'      // Windows
      && os.version !== 'rv'      // Linux
    ))
    .map(os => os.name);
  const deviceInfo = getDeviceInfo();
  return mobileOS.includes(deviceInfo.os);
}