import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Popover } from '@mui/material';
import { setGamePassTournamentToken } from '../../actions';

export const TestMenu = ({ items, hide, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setTimeout(() => {
      document.body.style.overflow = '';
      setAnchorEl(null);
    }, 0);
  };

  const open = Boolean(anchorEl);

  const handleNavigate = (item) => {
    const newUrl = new URL(item?.redirect_uri);
    const data = {
      url: item?.url,
      passMarketingId: item?.passMarketingId,
      gameOverUrl: item?.game_over_url
    }
    dispatch(
      setGamePassTournamentToken({ 
        token: '',
        result: false 
      }
    ));
    navigate(newUrl?.pathname, { state: { data } });
    handleClose();
    hide();
  }

  return (
    <div className="menu-test">
      <div 
        className="d-flex align-items-center justify-content-between w-100"
        onClick={handleClick}
      >
        <div className="menu-test-title">{title}</div>
        <KeyboardArrowRightRoundedIcon />
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            background: 'transparent',
            transform: 'translateY(-8px) !important'
          }
        }}
      >
        <div className="menu-test-box">
          {items?.map((g, gIdx) => (
            <div className="menu-test-box-container" key={`GameUrls_${gIdx}`}>
              <div 
                className="menu-test-button"
                onClick={() => handleNavigate(g)}
              >
                {g?.button_title}
              </div>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
}