import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import isEmpty from 'lodash/isEmpty';
import { Modal } from 'react-bootstrap';
import { MenuList } from './MenuList';
import { 
  firebaseEvents, 
  remoteConfigKeys, 
  wenLamboIds 
} from '../../constants';
import { getPromotionSpinTheWheelDetails, setRemoteConfigKeys } from '../../actions';
import { 
  promotionSpinTheWheelDetailsSelector, 
  remoteConfigKeysSelector, 
  userProfileSelector 
} from '../../selectors';
import { remoteConfig } from '../../utils/firebase';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './Menu.scss';

export const Menu = ({ isOpen, openMenu, sideMenuItems, sideMenuOtherItems, ...props }) => {
  const dispatch = useDispatch();
  const [selected, selectMenuItem] = useState('');
  const [isPromotionMenuShown, showPromotionMenu] = useState(false);

  const promotion = useSelector(state => promotionSpinTheWheelDetailsSelector(state));
  const userProfile = useSelector(state => userProfileSelector(state));

  useEffect(() => {
    if (!isOpen) return;
    // for testing spin the wheel and games
    fetchAndActivate(remoteConfig)
      .then(() => {
        const strippedStwEnabled = getValue(remoteConfig, remoteConfigKeys.strippedStwEnabled);
        const strippedGameUrls = getValue(remoteConfig, remoteConfigKeys.strippedGameUrls);
        const strippedStwUsers = getValue(remoteConfig, remoteConfigKeys.strippedStwUsers);
        const conquestStwUrl = getValue(remoteConfig, remoteConfigKeys.conquestStwUrl);
        dispatch(
          setRemoteConfigKeys({
            strippedStwEnabled: strippedStwEnabled.asBoolean(),
            strippedGameUrls: JSON.parse(strippedGameUrls?._value),
            strippedStwUsers: JSON.parse(strippedStwUsers?._value),
            conquestStwUrl: JSON.parse(conquestStwUrl?._value)
          })
        );
      })
      .catch((err) => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: err
          });
      });
    // Web3 Community Festival menu
    dispatch(getPromotionSpinTheWheelDetails({ marketingId: wenLamboIds.web3CommunityFestival }));
  }, [dispatch, isOpen])

  useEffect(() => {
    if (!isOpen && isEmpty(promotion)) {
      return;
    }
    if (promotion?.uids) {
      for (const uid of promotion.uids) {
        if (uid === userProfile.uid) {
          showPromotionMenu(true);
        }
      }
    }
    return () => showPromotionMenu(false);
  }, [isOpen, promotion])

  return (
    <Modal
      backdrop
      show={isOpen}
      className="menu-container"
      contentClassName="menu"
      dialogClassName="menu-dialog"
      backdropClassName="menu-bg"
      onHide={openMenu}
      centered
    >
      <Modal.Body>
        <MenuList
          {...props}
          selected={selected}
          items={sideMenuItems}
          others={sideMenuOtherItems}
          selectMenuItem={selectMenuItem}
          openMenu={openMenu}
          isPromotionMenuShown={isPromotionMenuShown}
        />
      </Modal.Body>
    </Modal>
  );
}