import { 
  RESET_STATE,
  SET_GAMEPASS_CART_COUNT,
  SET_GAMEPASS_CART_ITEMS, 
  SET_GAMEPASS_IS_ADDED_TO_CART, 
  SET_UPDATE_GAMEPASS_CART_ITEMS
} from '../actions/constants';

const cartState = {
  isAdded: false,
  items: [],
  updatedItems: [],
  count: 0
};

const cartReducer = (state = cartState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return cartState;
    case SET_GAMEPASS_IS_ADDED_TO_CART:
      return {
        ...state,
        isAdded: action.isAdded
      };
    case SET_GAMEPASS_CART_ITEMS:
      return {
        ...state,
        items: action.items
      };
    case SET_UPDATE_GAMEPASS_CART_ITEMS:
      return {
        ...state,
        updatedItems: action.updatedItems
      };
    case SET_GAMEPASS_CART_COUNT:
      return {
        ...state,
        count: action.count
      };
    default:
      return state;
  }
}

export default cartReducer;