import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LayoutContent, PageTitle } from '../Components'
import { getUserCollection } from '../../actions/index'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useLocation } from 'react-router-dom';
import './Collection.scss';

export const Collection = () => {
  const dispatch = useDispatch();
  const location = useLocation().pathname.split('/');
  const items = useSelector(state => state.collections.selected);
  const [selectedItem, setSelectedItem] = useState();
  useEffect(() => { dispatch(getUserCollection(location[2])) },[dispatch, location])

  return (
    <LayoutContent innerClassName="collection-list-inner">
      {!selectedItem && <div className="collectibles">
        <PageTitle title="Collectibles" />
        <div className="collectibles-header">
          <ArrowBackIosNewRoundedIcon onClick={() => window.history.back()} className="collectibles-header-back"/>
          <div className="collectibles-header-title">Collectibles</div>
        </div>
        <div className="collectibles-container">
          {/* <div className="collectibles-title">{collections[0].name}</div> */}
          <div className="collectibles-wrapper">
            {items && items.collection?.map(i => {
              return (
                <div onClick={() => setSelectedItem(i)} key={i.id} className="collectibles-item">
                  <div className="collectibles-image-wrapper">
                    <img className="collectibles-image" src={i.image_url} alt="nft"></img>
                  </div>
                  <div className="collectibles-name">{i.name}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>}
    </LayoutContent>
  )
}

export default Collection