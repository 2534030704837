import React, { Component } from 'react';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, push } from 'firebase/database';
import { Progress, Toast } from '../Commons';
import {
  FormFieldWithWeight,
  LayoutContent,
  PageTitle
} from '../Components';
import { defaultTimeoutMS, firebaseEvents } from '../../constants';
import { database } from '../../utils/firebase';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './GuildApplication.scss';

export class GuildApplication extends Component {
  constructor(props) {
    super(props);

    const route = window.location.pathname.split('/').slice(1);
    const id = route[route.length - 2];

    this.state = {
      guildId: id,
      updatedFields: [],
      currentWeight: 0,
      progress: 0,
      toastOpen: false,
      userId: '',
      formErrors: {}
    }
  }

  componentDidMount() {
    const { guildId } = this.state;
    this.props.getGuild(guildId, false);
    this.props.getGuildApplicationForm(guildId);
    this.getUserId();
  }

  componentDidUpdate(prevProps) {
    const { toast, guild, form } = this.props;

    if (prevProps.toast !== toast && toast) {
      this.openToast(true);
    };

    if (prevProps.form !== form) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.guildApplyScholarship, {
          guild_name: guild?.name
        });
    }
  }

  getUserId = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) this.setState({ userId: user.uid });
    });
  }

  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  }

  updateWeight = (fieldId, fieldWeight, value) => {
    const { totalWeight, form } = this.props;
    const { currentWeight, updatedFields } = this.state;
    const updatedFieldIds = updatedFields.map(f => f.question);
    const fieldIndex = form.fields.map(f => f._id).indexOf(fieldId);

    if (fieldIndex === -1) return;
    
    if (value) {
      const field = { question: fieldId, answer: value };
      if (!updatedFieldIds.includes(fieldId)) {
        updatedFields.push(field);
        let newWeight = currentWeight + fieldWeight;
        newWeight = newWeight < 0 ? 0 : newWeight;
        this.updateWeightStates(newWeight, totalWeight, updatedFields);
      } else {
        const idx = updatedFieldIds.indexOf(fieldId);
        updatedFields[idx] = field;
        this.updateWeightStates(currentWeight, totalWeight, updatedFields);
      }
    } else {
      const idx = updatedFieldIds.indexOf(fieldId);
      if (!(idx === -1)) {
        updatedFields.splice(idx, 1);
        let newWeight = currentWeight - fieldWeight;
        newWeight = newWeight < 0 ? 0 : newWeight;
        this.updateWeightStates(newWeight, totalWeight, updatedFields);
      }
    }
  }

  updateWeightStates = (newWeight, totalWeight, updatedFields) => {
    const perc = (newWeight / totalWeight) * 100;
    this.setState({
      currentWeight: newWeight,
      progress: perc,
      updatedFields
    });
  }

  submit = (e) => {
    e.preventDefault();

    const { guild, form } = this.props;
    const { guildId, userId, updatedFields } = this.state;
    const sortedFields = sortBy(updatedFields, (f) => f.question);
    const fieldAnswers = sortedFields.map(f => f.answer);
    const requiredFields = form.fields.filter(f => f.required).map(f => f._id);
    const updatedFieldQuestions = updatedFields.map(f => f.question);
    const answeredRequiredFields = intersection(requiredFields, updatedFieldQuestions);
    
    const errors = {};
    for (let field of requiredFields) {
      const index = updatedFields.findIndex(f => f.question === field);
      if (index === -1) errors[field] = 'This field is required';
    }
    if (Object.keys(errors).length !== 0) {
      this.props.setDisplayToast({
        result: false,
        message: "Please fill-up all required fields"
      });
    }
    this.setState({ formErrors: errors });

    if (userId && userId !== '' && guild && isEqual(requiredFields, answeredRequiredFields)) {
      const profileRef = ref(database, `chatReference/${userId}`);
      const newProfileRef = push(profileRef);
      const threadRef = ref(database, `messages/${newProfileRef.key}`);
      const newThreadRef = push(threadRef);
      const newProfile = {
        id: newThreadRef.key,
        imgUrl: guild.pfp_url,
        name: guild.name,
        partnerId: guildId
      };
      logFirebaseEventWithTimestamp(
        firebaseEvents.guildSubmitScholarship, {
          guild_name: guild.name
        });
      this.props.submitGuildApplicationForm(guildId, fieldAnswers, userId, newProfile);
    }
  }

  closeToast = () => {
    const { toast } = this.props;
    this.openToast(false);
    setTimeout(() => {
      this.props.setDisplayToast();
      if (toast.message.includes("Please fill-up")) return;
      window.location.href = "/community";
    }, defaultTimeoutMS);
  }

  render() {
    const { form, toast } = this.props;
    const { progress, toastOpen } = this.state;

    if (form && !isEmpty(form)) {
      const { title, subtitle, fields } = form;
      return (
        <LayoutContent>
          <PageTitle title={title} />
          <div className="guild-application-header">
            <h1 className="guild-application-header-title">{title}</h1>
            <p className="guild-application-header-subtitle">{subtitle}</p>
            <Progress percentage={progress} barClassName="guild-application-header-progress" />
          </div>
          <div className="guild-application-form">
            {
              fields &&
              <form>
                {fields.map((field, key) => (
                  <FormFieldWithWeight
                    key={key}
                    formField={field}
                    updateWeight={this.updateWeight}
                    formErrors={this.state.formErrors}
                    idKey="_id"
                    className="guild-application-form"
                  />
                ))}
                <div className="guild-application-form-submit">
                  <button onClick={(e) => this.submit(e)}>Submit</button>
                </div>
              </form>
            }
          </div>
          {toast && <Toast toast={toast} open={toastOpen} hide={this.closeToast} />}
        </LayoutContent>
      );
    } else if (toast) {
      return <Toast toast={toast} open={toastOpen} hide={this.closeToast} />
    }

    return null;
  }
}