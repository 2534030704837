import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs, { Tab } from 'react-best-tabs';
import { CircularProgress } from '@mui/material';
import { TransactionHistory } from '../Components';
import { 
  maxLimit, 
  mgcCurrency,
  offsetIncrementValue, 
  startingOffset, 
  transactionsTab, 
} from '../../constants';
import { 
  getUserTransactions, 
  setClearUserTransactions, 
  setTransactionActiveTab, 
  setUserTransactionsSize 
} from '../../actions';
import { transactionSizeSelector, transactionActiveTabSelector } from '../../selectors';
import { getTransactionAssets } from '../../utils/getTransactionAssets';
import 'react-best-tabs/dist/index.css';

export const TransactionsTab = ({ 
  transactions, 
  handleSelectedMethod, 
  showBarcode, 
  hooks 
}) => {
  const dispatch = useDispatch();
  
  const data = useSelector(state => transactionSizeSelector(state));
  const activeTab = useSelector(state => transactionActiveTabSelector(state));

  const [isInView, setIsInView] = useState(false);
  const [offsetNumber, setOffsetNumber] = useState(startingOffset);
  const [stopPage, setStopPage] = useState(false);
  const [assets, setAssets] = useState(getTransactionAssets(transactionsTab, transactionsTab[activeTab - 1]));

  useEffect(() => {
    if (data.size < maxLimit) setStopPage(true);
  }, [data])

  useEffect(() => {
    if (stopPage) return;
    dispatch(getUserTransactions({
      kinds: ["offchain"],
      assets: assets,
      limit: maxLimit,
      offset: offsetNumber
    }));
  }, [dispatch, activeTab, offsetNumber, stopPage, assets])

  useEffect(() => {
    dispatch(setClearUserTransactions([]));
    dispatch(setUserTransactionsSize({ loading: false, size: offsetIncrementValue }));
    setOffsetNumber(startingOffset);
    setStopPage(false);
  }, [dispatch])

  const handleTab = (event, tab) => {
    dispatch(setClearUserTransactions([]));
    setOffsetNumber(startingOffset);
    setStopPage(false);
    dispatch(setTransactionActiveTab(tab));
    const assetText = event.target.innerText;
    const assetsArr = getTransactionAssets(transactionsTab, assetText);
    setAssets(assetsArr);
  }

  useEffect(() => {
    if (isInView) {
      setOffsetNumber(prev => prev + offsetIncrementValue);
    }
  }, [isInView])

  return (
    <Tabs
      activeTab={activeTab}
      className="transactions-tabs transactions-tabs-content"
      ulClassName="transactions-tabs-lists"
      activityClassName="transactions-tabs-indicator"
      onClick={(event, tab) => handleTab(event, tab)}
    >
      {transactionsTab.map((t, tIdx) => {
        return (
          <Tab key={`TAB_KEY_${tIdx}`} title={t} className="transactions-center-tab-title">
            {transactions.length > 0 ? 
              <div className="position-relative">
                <div className="wallet-container">
                  <TransactionHistory
                    handleTransaction={handleSelectedMethod}
                    setIsBarcodeVisible={showBarcode}
                    hooks={hooks}
                    transactions={transactions}
                    setIsInView={setIsInView}
                  />
                </div>
              </div> 
              : 
              <div>
                {!data.loading && <div className="wallet-empty mt-0">
                  You have no transactions yet
                </div>}
              </div>}
            {data.loading && 
              <div className="mt-3 d-flex justify-content-center">
                <CircularProgress sx={{ color: "#615374" }} />
              </div>}
          </Tab>
        )
      })}
    </Tabs>
  )
}