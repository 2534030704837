import React from 'react';
import { BlurBackgroundModal } from '../Commons';
import { rewardConfirmModalTexts } from '../../constants';
import './RewardDetails.scss';

export const RewardDetailsNotice = ({ open, hide, onClick }) => (
  <BlurBackgroundModal
    open={open}
    hide={hide}
    title={rewardConfirmModalTexts.mobileNumberValidation.title}
  >
    <div className="reward-details-confirm">
      <div className="reward-details-confirm-description">
        {rewardConfirmModalTexts.mobileNumberValidation.description}
      </div>
      <button
        onClick={onClick}
        className="blur-modal-button"
      >
        {rewardConfirmModalTexts.mobileNumberValidation.button}
      </button>
    </div>
  </BlurBackgroundModal>
);