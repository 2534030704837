import React from 'react';
import classNames from 'classnames';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { launchpadItemTexts } from '../../constants';
import './LaunchpadItem.scss';

export const LaunchpadItemSection = ({
  wrapperHidden,
  title,
  hasVoucherMechanics,
  onVoucherMechanicsClick,
  textInput,
  voucherButtonClaim,
  voucherButtonEnabled,
  redeemNFT,
  isGuest,
  guestButtonText,
  buttonText,
  claimedContent
}) => (
  <div className={classNames(
    "mint-wrapper", {
    "mint-wrapper-hide": wrapperHidden
  })}>
    <div className="nft-voucher-text-wrapper">
      {title && 
        <div className="nft-voucher-text">{title}</div>}
      {hasVoucherMechanics &&
        onVoucherMechanicsClick &&
        <div 
          onClick={onVoucherMechanicsClick} 
          className="nft-voucher-text-icon"
        >
          <HelpOutlineIcon fontSize="small" className="nft-voucher-icon" />
          <div className="nft-voucher-text-learn">{launchpadItemTexts.learnMore}</div>
        </div>}
    </div>
    {textInput}
    <button 
      className={classNames(
        "nft-voucher-button", {
          "nft-voucher-button-claim": voucherButtonClaim,
          "nft-voucher-button-enabled": voucherButtonEnabled
        }
      )} 
      onClick={redeemNFT}
    >
      {isGuest ? guestButtonText : buttonText}
    </button>
    {claimedContent}
  </div>
);