import React from 'react'
import { useLocation } from 'react-router-dom';
import { spinData, spinDataAstro } from '../../../constants/mechanics';
import './SpinMechanics.scss';

export const Mechanics = () => {
  const location = useLocation();
  const event = location.pathname.split('/')[3]; 
  const isExtraGDay = event && event.toLowerCase().includes("extra_g_day");

  const getData = () => {
    if (!isExtraGDay) return spinDataAstro;
    return spinData;
  }

  return (
    <div className="mechanics-wrapper">
      <div className="mechanics-text">Mechanics</div>
      {getData().map((m, mIdx) => {
        return (
          <div key={`Mechanics_${mIdx}`} className="mechanics-box">
            <div className="mechanics-box-title">{m.title}</div>
            <div dangerouslySetInnerHTML={{__html: m.content}} className="mechanics-box-desc"/>
            {m.button && <div className="mechanics-box-wrapper">
              <a href="/launchpad/gudisquad" className="mechanics-box-button my-3">{m.button}</a>
            </div>}
            {m.description && <div dangerouslySetInnerHTML={{__html: m.description}} className="mechanics-box-desc"/>}
          </div>
        )
      })}
    </div>
  )
}