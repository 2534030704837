export const bannerSlugs = {
  homePageBanner: 'home-page-banner',
  homePageReferralBanner: 'home-page-referral-banner',
  wenLamboPageBanner: 'wenlambo-page-banner',
  wenLamboPageBannerBG: 'wenlambo-page-banner-bg',
  wenLamboSTWBanner: 'wenlambo-stw-banner',
  wenLamboReferralBanner: 'wenlambo-referral-banner',
  referralPageBanner: 'referral-page-banner',
  walletReferralBanner: 'wallet-referral-banner'
};
export const homeFeaturedBanners = [
  {
    experience: 'gamepass',
    name: 'Wen Lambo',
    short_desc: '',
    slug: 'wenlambo',
  }
];
export const bannerDimensions = {
  homePageReferralBanner: {
    desktop: {
      width: 703,
      height: 91
    },
    mobile: {
      width: 720,
      height: 211
    }
  },
  homePageMarketplaceBanner: {
    desktop: {
      width: 571,
      height: 183
    },
    mobile: {
      width: 567,
      height: 241
    }
  },
  gamePassGamesBanner: {
    desktop: {
      width: 703,
      height: 125
    },
    mobile: {
      width: 567,
      height: 214
    }
  },
  referralPageBanner: {
    desktop: {
      width: 708,
      height: 232
    },
    mobile: {
      width: 567,
      height: 567
    }
  },
  referralMechanicsCarousel: {
    width: 703,
    height: 333
  },
  walletReferralBannerText: {
    desktop: {
      width: 270,
      height: 33
    },
    mobile: {
      width: 220,
      height: 60
    }
  },
  launchpadBanner: {
    desktop: {
      width: 926,
      height: 166
    },
    tablet: {
      width: 531,
      height: 158
    },
    mobile: {
      width: 567,
      height: 214
    }
  }
};