import windowsIcon from '../../../../assets/icons/platforms/windows.png';
import macosIcon from '../../../../assets/icons/platforms/macos.png';
import androidIcon from '../../../../assets/icons/platforms/android.png';
import webIcon from '../../../../assets/icons/platforms/web.png';
import iosIcon from '../../../../assets/icons/platforms/ios.png';
import xboxIcon from '../../../../assets/icons/platforms/xbox.png';
import linuxIcon from '../../../../assets/icons/platforms/linux.png';
import playStationIcon from '../../../../assets/icons/platforms/playstation.png';
import nintendoSwitchIcon from '../../../../assets/icons/platforms/nintendoswitch.png';
import steamIcon from '../../../../assets/icons/platforms/steam.png';

export const platformIcons = {
  windows: windowsIcon,
  macos: macosIcon,
  android: androidIcon,
  web: webIcon,
  ios: iosIcon,
  xbox: xboxIcon,
  linux: linuxIcon,
  playStation: playStationIcon,
  nintendoSwitch: nintendoSwitchIcon,
  steam: steamIcon
};