import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import './Quests.scss';

export const QuestLeaderboards = ({ leaderboards }) => (
  <div className="quests-leaderboards">
    <Row className="quests-leaderboards-row">
      <Col xs="2" sm="2" className={classNames("quests-leaderboards-rank", "quests-leaderboards-table-header")}>Rank</Col>
      <Col xs="8" sm="8" className={classNames("quests-leaderboards-id", "quests-leaderboards-table-header")}>ID</Col>
      <Col xs="2" sm="2" className={classNames("quests-leaderboards-xp", "quests-leaderboards-table-header")}>XP</Col>
    </Row>
    {
      leaderboards.map(l => (
        <Row key={`${l.rank}_${l.username}`} className="quests-leaderboards-row">
          <Col xs="2" sm="2" className="quests-leaderboards-rank">{l.rank}</Col>
          <Col xs="8" sm="8" className="quests-leaderboards-id">{l.username}</Col>
          <Col xs="2" sm="2" className="quests-leaderboards-xp">{l.xp}</Col>
        </Row>
      ))
    }
  </div>
);