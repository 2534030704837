import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { ErrorToast, LayoutContent, PageTitle, Section } from '../Components';
import { CardPaymentForm } from './CardPaymentForm';
import { useQuery } from '../../hooks';
import { checkoutContentsSelector, topUpChannelsSelector } from '../../selectors';
import { getPaymentChannels, updateGamePassCheckout } from '../../actions';
import { paymentKind, topUpChannels } from '../../constants';
import './CardPayment.scss';

export const CardPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [formFields, setFormFields] = useState([]);
  const channels = useSelector(state => topUpChannelsSelector(state));
  const checkoutContents = useSelector(state => checkoutContentsSelector(state));

  const query = useQuery();
  const channel = query.get('channel');
  const amount = query.get('amount');
  const currency = query.get('currency');
  const from = query.get('from');

  useEffect(() => {
    if (location?.state) {
      const updateData = {
        selectedPaymentMethod: location?.state?.paymentData?.selectedPaymentMethod,
        from: location?.state?.from,
        shouldNotBack: true
      };
      dispatch(updateGamePassCheckout(updateData));
      return;
    }
    if (channel) {
      const kind = channel === topUpChannels.ccdc && paymentKind.card;
      dispatch(getPaymentChannels(kind));
    }
  }, [dispatch, channel, location]);

  useEffect(() => {
    if (!isEmpty(checkoutContents)) {
      setFormFields(checkoutContents.selectedPaymentMethodBillingForm);
    }
  }, [checkoutContents, location])

  useEffect(() => {
    if (isEmpty(channels)) return;
    const filteredChannel = channels.filter(c => c.code === channel);
    setFormFields(filteredChannel[0]?.form);
  }, [channels, channel])

  return (
    <LayoutContent 
      outerClassName="card-payment"
      innerClassName="card-payment-inner"
    >
      <PageTitle title={formFields?.title} />
      {!isEmpty(formFields) &&
        <Section
          sectionClassName="card-payment-section"
          backButtonRoute
          backRoute={() => navigate(-1)}
          title={formFields?.title}
          titleCentered
        >
          <CardPaymentForm
            description={formFields?.description}
            fields={formFields?.fields}
            amount={amount}
            channel={channel}
            currency={currency}
            from={from}
          />
        </Section>}
        <ErrorToast />
    </LayoutContent>
  )
}