import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Section } from '../Components';
import { HorizontalScroll } from '../Commons';
import { minWidths, maxWidths } from '../../constants';
import '../Components/Section/Section.scss';
import './Game.scss';

const Gallery = ({ title, screenshots, openImage, sectionClassName, compressed, gamePassGame }) => {
  const md = useMediaQuery({ minWidth: minWidths.tablet, maxWidth: maxWidths.tablet });
  const lg = useMediaQuery({ minWidth: minWidths.desktopSM, maxWidth: maxWidths.desktopLG });
  const xl = useMediaQuery({ minWidth: minWidths.desktopXL });

  let slidesToShow = 2;
  if (md) slidesToShow = compressed ? 2 : 3;
  else if (lg) slidesToShow = compressed ? 2 : 4;
  else if (xl) slidesToShow = compressed ? 3 : 4;

  return (
    <Section title={title} sectionClassName={sectionClassName}>
      <div className={classNames("section-list", "gallery")}>
        <HorizontalScroll slidesToShow={slidesToShow} className="gallery-scroll">
          {screenshots.map((s, idx) => (
            <div key={`Screenshot_${idx}`} className="screenshot">
              <div 
                className={classNames(
                  "screenshot-image", {
                  "screenshot-image-game": gamePassGame
                })} 
                style={{ backgroundImage: `url(${gamePassGame ? s : s.image_url})` }} 
                onClick={() => openImage(gamePassGame ? s : s.image_url)} 
              />
            </div>
          ))}
        </HorizontalScroll>
      </div>
    </Section>
  );
}

export default Gallery;