import {
  START_PIN_OTP_TIMER,
  SET_PIN_AUTH_STEP,
  SET_PIN_VALIDATION_ERROR,
  DISPLAY_SET_PIN_FORM,
  SET_SUBMIT_NEW_PIN,
  RESET_STATE
} from '../actions/constants';
import { authSteps } from '../constants';

const pinState = {
  pinOTPTimerActive: false,
  authStep: authSteps.login,
  hasValidationError: false,
  setPINFormDisplayed: true,
  isSubmit: false
};

const pinReducer = (state = pinState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return pinState;
    case START_PIN_OTP_TIMER:
      return {
        ...state,
        pinOTPTimerActive: action.pinOTPTimerActive
      };
    case SET_PIN_AUTH_STEP:
      return {
        ...state,
        authStep: action.step
      };
    case SET_PIN_VALIDATION_ERROR:
      return {
        ...state,
        hasValidationError: action.hasValidationError
      };
    case DISPLAY_SET_PIN_FORM:
      return {
        ...state,
        setPINFormDisplayed: action.setPINFormDisplayed
      };
    case SET_SUBMIT_NEW_PIN:
      return {
        ...state,
        isSubmit: action.isSubmit
      };
    default:
      return state;
  }
}

export default pinReducer;