import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { LayoutContent } from '../Components';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {
  getScholarshipPromos,
  setLoading
} from '../../actions';
import { promosData } from '../../constants';
import { scholarshipPromosSelector } from '../../selectors';
import './Promos.scss';

const Promos = () => {
  const dispatch = useDispatch();
  const scholarshipPromos = useSelector(state => scholarshipPromosSelector(state));

  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(getScholarshipPromos(promosData.key));
  }, [dispatch]);

  return (
    <>
      {!isEmpty(scholarshipPromos) &&
        <LayoutContent>
          <div className="promos">
            <div 
              className="promos-back" 
              onClick={() => window.history.back()}
            >
              <ArrowBackIosNewRoundedIcon />
            </div>
            <div className="promos-wrapper">
              <h1 className="promos-title">{promosData.title}</h1>
              <div className="promos-list">
                {promosData.description.map((d, dIdx) => (
                  <div key={`Desc_${dIdx}_${d.name}`}>
                    <div className="promos-list-number">{dIdx + 1}.</div>
                    <div className="promos-list-name">{d.value}</div>
                  </div>
                ))}
              </div>
            </div>
            {scholarshipPromos.map((p, pIdx) => (
              <div key={`Plans_${pIdx}`} className="promos-wrapper">
                <div className="promos-eligible">
                  {p.title}
                </div>
                {p.promos.map((s, sIdx) => (
                  <div key={`Plans_${pIdx}_${sIdx}`} className="promos-container">
                    <div className="promos-item-title">{s.name}</div>
                    <div className={classNames({
                      "promos-item": !s.name.includes('Platinum'),
                      "promos-item-solo": s.name.includes('Platinum')
                    })}>
                      {s.skus.map((g, gIdx) => (
                        <div 
                          key={`GlobePromos_${gIdx}`} 
                          className={classNames(
                            "promos-item-box",
                            {"promos-item-box-solo": s.name.includes('Platinum')}
                          )}
                        >
                          <div className="promos-item-box-name">{g.name}</div>
                          <div className="promos-item-box-desc">{g.value}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="promos-buy-wrapper">
              <a
                href={promosData.href}
                target="_blank"
                rel="noopener noreferrer"
                className="promos-buy"
              >
                Buy Promos
              </a>
            </div>
          </div>
        </LayoutContent>}
    </>
  );
}

export default Promos;