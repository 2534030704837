export const loginFormInputs = [
  {
    name: 'mobileNumber',
    type: 'phone',
    label: 'Enter your phone number'
  },
  {
    name: 'email',
    type: 'email',
    label: 'Enter your email address'
  },
  {
    name: 'username',
    type: 'text',
    label: 'Enter your username'
  }
];
export const loginTexts = {
  title: 'Login to experience the complete MetaverseGo experience!',
  footer: 'Don’t have an account?',
  button: {
    next: 'Next',
    acceptAndContinue: 'Accept & Continue',
    signup: 'Signup',
    login: 'Login'
  },
  register: {
    title: 'Register and Claim Free 30-Minute Game Pass',
    footer: 'Already have an account?'
  },
  otp: {
    title: 'Verification',
    newTitle: 'Enter 6-digit verification code sent to',
    newTitleEmail: 'Enter 6-digit verification code sent to your email',
    description: 'Enter 6-digit verification code sent to your number',
    newDescription: 'Enter 6 digit code',
    or: 'or',
    didNotReceiveOtp: "Didn't receive OTP via SMS?",
    resendVerificationCode: 'Resend verification code',
    resendCodeIn: 'Resend code in',
    resendCodeViaSMS: 'Resend code via SMS in',
    resendVerificationThroughEmail: 'Resend verification code through email',
    resendCodeViaEmail: 'Resend code via email in',
    resendCodeEmail: 'Resend code via email',
    resendCode: 'Resend Code',
    wrongNumber: 'Wrong Number?',
    sendCodeViaEmail: 'Send code',
    submit: 'Submit',
    error: {
      title: 'Error',
      description: 'OTP validation failed'
    }
  },
  pin: {
    title: 'Set PIN',
    subtitle: 'Set your PIN',
    dismiss: 'Set PIN later',
    description: 'Your PIN is the password to your MetaverseGo account. For your account security, never share your PIN with anyone.',
    login: 'Enter PIN',
    error: {
      title: 'Error',
      description: 'PIN validation failed'
    }
  },
  termsAndPrivacy: {
    title: 'Terms & Privacy'
  },
  loginError: 'Please enter a valid mobile number.',
  signupError: 'Please enter valid details.',
  consent: 'By registering with MetaverseGo and ticking this box, I certify and acknowledge that I have fully read, understood and agree to the MetaverseGo <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a> and I consent for MetaverseGo to share my personal data to authorized third parties in accordance with its <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> for the purposes as stated therein.',
};
export const loginWaitListText = {
  title: "Aww, you don't have an early access code.",
  description: [
    'MetaverseGo is in the closed beta stage, but dont worry we have added you to the waitlist!',
    'You will receive an email if access has been granted.',
    'Follow our social media accounts to stay up-to-date.',
  ],
  icon: [
    {
      name: 'facebook',
      link: 'https://www.facebook.com/metaversego.gg',
    },
    {
      name: 'instagram',
      link: 'https://www.instagram.com/metaversego.gg',
    },
    {
      name: 'twitter',
      link: 'https://www.twitter.com/metaversego_gg',
    },
    {
      name: 'discord',
      link: 'https://discord.gg/3PXsNnPn9u',
    },
    {
      name: 'telegram',
      link: 'https://t.me/MetaverseGoOfficial',
    },
  ],
};
export const registrationStepQuery = 'step=registration';
export const authSteps = {
  default: 0,
  login: 1,
  otp: 2,
  pin: 3,
  termsAndConditions: 4
};
export const partnerRedirectUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/partners/redir`;