export const clearLocalStorageExcept = async(preservedKeys) => {
  const preservedItems = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const value = localStorage.getItem(key);
    
    if (preservedKeys.includes(key)) {
      preservedItems[key] = value;
    }
  }
  localStorage.clear();
  for (const key in preservedItems) {
    localStorage.setItem(key, preservedItems[key]);
  }
};