import React from 'react';
import classNames from 'classnames';
import './LayoutContent.scss';

const LayoutContent = ({ outerClassName, innerClassName, children, ...props }) => (
  <div className={classNames("content", outerClassName)} {...props}>
    <div className={classNames("content-inner", innerClassName)}>
      {children}
    </div>
  </div>
);

export default LayoutContent;