import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  Collectibles,
  LayoutContent,
  PageTitle
} from '../Components';
import { getCollectionDetails } from '../../actions';
import { firebaseEvents, mgcCurrency } from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';

export const MarketplaceCollection = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const collection = useSelector((state) => state.marketplace.collection);
  const isList = useSelector((state) => state.marketplace.isList);
  const isBuy = useSelector((state) => state.marketplace.isBuy);
  const isCancelList = useSelector((state) => state.marketplace.isCancelList);

  const slug = location.pathname.split('/')[2].toLowerCase();
  const id = location.pathname.split('/')[3];

  useEffect(() => {
    logFirebaseEventWithTimestamp(
      firebaseEvents.nftMarketplaceBuyPage, {
        payment_method: mgcCurrency
      });
  }, [])

  useEffect(() => {
    dispatch(getCollectionDetails({ slug, id }));
  }, [dispatch, id, slug, isList, isCancelList, isBuy])
  
  return (
    <LayoutContent>
      <PageTitle title="NFT Details" />
      {!isEmpty(collection) &&
        <Collectibles item={collection} />}
    </LayoutContent>
  )
}
