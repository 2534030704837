import { takeLatest, call, put, all } from 'redux-saga/effects';
import { requestScholarshipPromos } from '../api/scholarship';
import { GET_SCHOLARSHIP_PROMOS } from '../actions/constants';
import { setScholarshipPromos, setLoading, failedRequests } from '../actions';

const getScholarshipPromosSaga = function* (payload) {
  try {
    const response = yield call(requestScholarshipPromos);
    if (response.status >= 200 && response.status < 400) {
      const promos = response.data.d[payload.promoKey];
      yield put(setScholarshipPromos(promos));
    }
    setLoading(false);
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* scholarshipSaga() {
  yield all([
    takeLatest(GET_SCHOLARSHIP_PROMOS, getScholarshipPromosSaga),
  ]);
}
