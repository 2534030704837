import { connect } from 'react-redux';
import {
  getUserCommunity,
  getGuildApplicationForm,
  submitGuildApplicationForm,
  setDisplayToast
} from '../actions';
import {
  guildSelector,
  displayGuildApplicationFormToastSelector,
  guildApplicationFormSelector,
  guildApplicationFormWeightSelector,
} from '../selectors';
import { GuildApplication } from '../components';

const mapStateToProps = (state) => ({
  guild: guildSelector(state),
  form: guildApplicationFormSelector(state),
  totalWeight: guildApplicationFormWeightSelector(state),
  toast: displayGuildApplicationFormToastSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getGuild: (guildId) => dispatch(getUserCommunity(guildId)),
  getGuildApplicationForm: (guildId) => dispatch(getGuildApplicationForm(guildId)),
  submitGuildApplicationForm: (guildId, form, userId, newProfile) => dispatch(submitGuildApplicationForm(guildId, form, userId, newProfile)),
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast))
});

const GuildApplicationContainer = connect(mapStateToProps, mapDispatchToProps)(GuildApplication);

export default GuildApplicationContainer;