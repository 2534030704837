import axios from 'axios';
import { baseUrl } from './url';

export function requestGamePassAddToCart(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/cart/items')
  });
}

export function requestGamePassCartItems(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/cart/items')
  });
}

export function requestGamePassCartCount(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/cart/items/count')
  });
}

export function requestGamePassUpdateCartItems(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/cart/update')
  });
}