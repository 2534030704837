import axios from 'axios';
import { baseUrl } from './url';

export function requestLogin(body) {
  const { recaptchaToken, ...rest } = body;
  const initialRequest = {
    method: 'POST',
    data: rest,
    url: baseUrl('login')
  };
  let request = initialRequest;
  if (recaptchaToken && recaptchaToken !== '') {
    request = {
      ...initialRequest,
      headers: { 'g-recaptcha-response': recaptchaToken }
    };
  }
  return axios.request(request);
}

export function requestValidateOTP(body) {
  return axios.request({
    method: 'POST',
    data: body,
    url: baseUrl('otp')
  });
}

export function requestNewOTP(body) {
  return axios.request({
    method: 'GET',
    data: body,
    url: baseUrl('otp')
  });
}

export function requestEmailOTP(body) {
  const { recaptchaToken, ...rest } = body;
  return axios.request({
    method: 'POST',
    data: rest,
    url: baseUrl('otp/email'),
    headers: { 'g-recaptcha-response': recaptchaToken }
  });
}