import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import './LaunchpadItem.scss';

export const LaunchpadItemDetails = ({ text, icon, desc, image, link, cta, utilityText }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [])

  return (
    <div className="launchpad-details">
      <div className="launchpad-details-header" onClick={() => setOpen(!open)}>
        <div className="launchpad-details-header-content">
          <div className={`launchpad-details-${icon}`}/>
          <div className="launchpad-details-text">{text}</div>
        </div>
        <div>
          {!open ? <KeyboardArrowDownRoundedIcon/> : <KeyboardArrowUpRoundedIcon/>}
        </div>
      </div>
      {desc && 
        open && 
        <div className="launchpad-details-description" dangerouslySetInnerHTML={{ __html: desc }} />}
      {image && 
        open && 
        <div className={`launchpad-details-${image}`} />}
      {utilityText && 
        open && 
        <div className="launchpad-details-description">{utilityText}</div>}
      {!isEmpty(cta) && 
        open && 
        <a href={link} className="launchpad-details-link">
          <div>{cta.title}</div>
          <ArrowForwardIosRoundedIcon fontSize='small' className="launchpad-details-link-arrow" />
        </a>}
    </div>
  )
}
