import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  requestCryptoChains,
  requestCryptoCurrencies, 
  requestCryptoWithdrawalValidate, 
  requestVerifySignMessage, 
  requestWalletSignMessage, 
  requestWithdrawCrypto, 
  requestWithdrawalNotice
} from '../api/crypto';
import { 
  GET_CRYPTO_CHAINS,
  GET_CRYPTO_CURRENCIES, 
  GET_WALLET_SIGN_MESSAGE, 
  GET_WITHDRAWAL_NOTICE, 
  VALIDATE_CRYPTO_WITHDRAWAL, 
  VERIFY_WALLET_SIGN_MESSAGE,
  WITHDRAW_CRYPTO 
} from '../actions/constants';
import { 
  cryptoWithdrawalValidateSuccessData,
  failedRequests, 
  navigateTo, 
  redirectToCheckoutPage, 
  setCryptoChains, 
  setCryptoCurrencies, 
  setLoading, 
  setWalletSignMessage, 
  setWithdrawalNotice, 
  verifyingWalletSignMessage
} from '../actions';
import { 
  responseStatus, 
  siteRoutes, 
  storageKeys 
} from '../constants';

const getWalletSignMessageSaga = function* (payload) {
  const { disconnect, signMessage } = payload?.payload;
  yield put(verifyingWalletSignMessage(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWalletSignMessage, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setWalletSignMessage(response.data.d?.signMessage));
      signMessage(response.data.d?.signMessage);
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      new Promise(resolve => {
        disconnect();
        resolve();
      });
      yield put(setWalletSignMessage(''));
      yield put(verifyingWalletSignMessage(false));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const verifyWalletSignMessageSaga = function* (payload) {
  const { disconnect, signature, address, chainId, type } = payload?.payload;
  const verifyObj = { 
    signature, 
    address, 
    chainId, 
    type 
  };
  yield put(verifyingWalletSignMessage(true));
  yield put(setWalletSignMessage(''));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestVerifySignMessage, verifyObj, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(verifyingWalletSignMessage(false));
      yield put(redirectToCheckoutPage(true));
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      new Promise(resolve => {
        disconnect();
        resolve();
      });
      yield put(verifyingWalletSignMessage(false));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getCryptoCurrenciesSaga = function* (payload) {
  const { intent, chainId } = payload.payload || '';
  const chain = { chainId };
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCryptoCurrencies, intent, chain, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setCryptoCurrencies(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setCryptoCurrencies([]));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const withdrawCryptoSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWithdrawCrypto, payload?.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(cryptoWithdrawalValidateSuccessData({}));
      yield put(navigateTo({ path: `${siteRoutes.withdrawalMethods}${siteRoutes.withdraw}/status?id=${response.data.d?.transactionId}`}));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(cryptoWithdrawalValidateSuccessData({}));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const validateCryptoWithdrawalSaga = function* (payload) {
  const { 
    fromCurrency, 
    toCurrency, 
    fromAmount, 
    chainId, 
    toAddress, 
    channelCode, 
    onSuccess 
  } = payload?.payload || '';
  const validateParams = {
    fromCurrency,
    toCurrency,
    fromAmount,
    chainId,
    toAddress,
    channelCode
  }
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCryptoWithdrawalValidate, validateParams, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(cryptoWithdrawalValidateSuccessData(validateParams));
      onSuccess();
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getCryptoChainsSaga = function* (payload) {
  const { intent } = payload?.payload || '';
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCryptoChains, intent, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setCryptoChains(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getWithdrawalNoticeSaga = function* (payload) {
  const { intent, params } = payload?.payload || '';
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWithdrawalNotice, params, intent, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setWithdrawalNotice(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* cryptoSaga() {
  yield all([
    takeLatest(GET_WALLET_SIGN_MESSAGE, getWalletSignMessageSaga),
    takeLatest(VERIFY_WALLET_SIGN_MESSAGE, verifyWalletSignMessageSaga),
    takeLatest(GET_CRYPTO_CURRENCIES, getCryptoCurrenciesSaga),
    takeLatest(WITHDRAW_CRYPTO, withdrawCryptoSaga),
    takeLatest(VALIDATE_CRYPTO_WITHDRAWAL, validateCryptoWithdrawalSaga),
    takeLatest(GET_CRYPTO_CHAINS, getCryptoChainsSaga),
    takeLatest(GET_WITHDRAWAL_NOTICE, getWithdrawalNoticeSaga)
  ]);
}