import axios from 'axios';
import { baseUrl } from './url';

export function requestFlags(key, version = 'v1', sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/flags/${key}`, version)
  });
}

export function requestUpdateFlag(key, body, version = 'v1', sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`me/flags/${key}`, version)
  });
}