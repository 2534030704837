import React, { useEffect } from 'react'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useDispatch, useSelector } from 'react-redux';
import { getTicket } from '../../actions';
import { LayoutContent, PageTitle } from '../Components';
import { useNavigate } from 'react-router-dom';
import './TicketRedeemClaim.scss'
import _ from 'lodash';

export const TicketRedeemClaim = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(state => state.redeem.claimData);
  const id = useSelector(state => state.redeem.featuredPromotion._id);
  useEffect(() => {
    dispatch(getTicket(id));
  },[dispatch, id])
  return (
    <>
    {!_.isEmpty(data) && <LayoutContent outerClassName="ticket-redeem" innerClassName="ticket-claim-wrapper">
      <PageTitle title="Event Pass"/>
      <div className="ticket-claim">
        <div className='ticket-claim-container'>
          <div className="ticket-claim-body">
            <ArrowBackIosNewRoundedIcon onClick={() => navigate("/redeem")} className="ticket-claim-back"/>
            <div className="ticket-claim-title">{data.instructions}</div>
            <div className="ticket-claim-footer">
              <div className="ticket-claim-footer-wrapper first">
                <img src={data.imageUrl} alt="nft" className="ticket-claim-image gudi"></img>
                <div className="ticket-claim-name">{data.detail}</div>
              </div>
              <div className="ticket-claim-footer-wrapper second">
                <img src={data.qrCodeImageUrl} alt="qrCode" className="ticket-claim-image"></img>
                <div className="ticket-claim-warning">Do not screenshot this page with the QR code and share with others, or you may lose access to the event.</div>
                <div>
                  <div className="ticket-claim-desc">Access Pass powered by</div>
                  <div className="ticket-claim-name">Tickelo</div>
                </div>
                <a href={data.qrCodeImageUrl} className="ticket-claim-button">Download Qr Code</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutContent>}
    </>
  )
}

export default TicketRedeemClaim