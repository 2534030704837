import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../actions';
import { LayoutContent, PageTitle } from '../Components';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { gudiData, gudiSquadData, spinData, astroData, spinDataAstro } from '../../constants';
import './Mechanics.scss'

export const Mechanics = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const mobile = new URLSearchParams(search).get('mobile');
  const location = useLocation();
  useEffect(() => {
    dispatch(setLoading(false));
  }, [dispatch])
  const backToPreviousPage = () => window.history.back();
  const getData = () => {
    if (location.pathname.includes("spin-the-wheel/MGO_917_EXTRA_G_DAY")) return spinData;
    if (location.pathname.includes("gudisquad")) return gudiSquadData;
    if (location.pathname.includes("gudi")) return gudiData;
    if (location.pathname.includes("astro")) return astroData;
    return spinDataAstro;
  }
  return (
    <LayoutContent outerClassName="launchpad-mechanics">
      <PageTitle title="Mechanics" />
      {!mobile && 
      <>
        <div className="launchpad-mechanics-title">Mechanics</div>
        <div className="launchpad-mechanics-back" onClick={backToPreviousPage}>
          <ArrowBackIosNewRoundedIcon className="launchpad-mechanics-back-icon" />
        </div>
      </>}
      <div className={classNames("launchpad-mechanics-wrapper", {"launchpad-mechanics-wrapper-mobile": mobile})}>
        <div className="launchpad-mechanics-container"> 
          {getData().map((m, mIdx) => (
            <div key={`Mechanics_${mIdx}`} className="launchpad-mechanics-box">
              <div className="launchpad-mechanics-box-title">{m.title}</div>
              {m.claimTitle && <div className="launchpad-mechanics-box-sub">{m.claimTitle}</div>}
              <div dangerouslySetInnerHTML={{__html: m.content}} className="launchpad-mechanics-box-desc"/>
              {m.button && <div className="mechanics-box-wrapper">
                <a href="/launchpad/gudisquad" className="mechanics-box-button my-3">{m.button}</a>
              </div>}
              {m.description && <div dangerouslySetInnerHTML={{__html: m.description}} className="mechanics-box-desc"/>}
            </div>
          ))}
        </div>
      </div>
    </LayoutContent>
  )
}
