import { connect } from 'react-redux';
import { getGameDetails } from '../actions';
import { gameDetailsSelector } from '../selectors';
import { Game } from '../components';

const mapStateToProps = (state) => ({
  game: gameDetailsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getGameDetails: (gameName) => dispatch(getGameDetails(gameName))
});

const GameContainer = connect(mapStateToProps, mapDispatchToProps)(Game);

export default GameContainer;