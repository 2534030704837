import React from 'react';
import { redirectWithAuthToken } from '../../utils/redirectWithAuthToken';
import wenLamboIcon from '../../assets/wen-lambo.png';
import './Footer.scss';

export const CustomFooterItem = ({ route }) => {
  const handleRoute = () => {
    redirectWithAuthToken(process.env.REACT_APP_WENLAMBO_SITE_URL, route);
  }
  return (
    <div className="footer-menu-item">
      <div onClick={handleRoute} className="footer-menu-item-custom-link">
        <img src={wenLamboIcon} className="footer-menu-item-custom-link-icon" />
      </div>
    </div>
  )
};