import { connect } from 'react-redux';
import { setChatMessages, setLoading } from '../actions';
import { chatMessagesSelector } from '../selectors';
import { Chat } from '../components';

const mapStateToProps = (state) => ({
  messages: chatMessagesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setChatMessages: (messages) => dispatch(setChatMessages(messages)),
  setLoading: (loading) => dispatch(setLoading(loading))
});

const ChatContainer = connect(mapStateToProps, mapDispatchToProps)(Chat);

export default ChatContainer;