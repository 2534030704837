import { 
  RESET_STATE,
  SET_PROMOTION_SPIN_THE_WHEEL_DETAILS,
  SET_SPIN_AVAILABLE, 
  SET_SPIN_PRIZE, 
  SET_SPIN_PRIZES, 
  SET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS 
} from "../actions/constants";

const spinTheWheelState = {
  prize: {},
  prizes: {},
  spinAvailable: 0,
  wenLamboSpinTheWheelDetails: {},
  promotionSpinTheWheelDetails: {}
};
  
const spinTheWheelReducer = (state = spinTheWheelState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return spinTheWheelState;
    case SET_SPIN_PRIZE:
      return {
        ...state,
        prize: action.prize
      };
    case SET_SPIN_PRIZES:
      return {
        ...state,
        prizes: action.prizes
      };
    case SET_SPIN_AVAILABLE:
      return {
        ...state,
        spinAvailable: action.spinAvailable
      };
    case SET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS:
      return {
        ...state,
        wenLamboSpinTheWheelDetails: action.wenLamboSpinTheWheelDetails
      };
    case SET_PROMOTION_SPIN_THE_WHEEL_DETAILS:
      return {
        ...state,
        promotionSpinTheWheelDetails: action.promotionSpinTheWheelDetails
      };
    default:
      return state;
  }
}

export default spinTheWheelReducer;