import axios from 'axios';
import { baseUrl } from './url';

export function requestRedeem(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('promotions/featured')
  });
}

export function requestRedeemSettings(marketingId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`promotions/settings/${marketingId}`)
  });
}

export function requestTicket(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl("tickets/create")
  });
}
