export const promosData = {
  title: 'How to be Eligible for MetaverseGo Scholarship',
  description: [
    {
      name: 'GlobeTMSubscriber',
      value: 'Be a Globe or TM subscriber'
    },
    {
      name: 'EligiblePromos',
      value: 'Check Eligible Promos and Plans below'
    },
    {
      name: 'PromoKeyword',
      value: 'Subscribe by texting promo keyword to 8080 (prepaid only)'
    }
  ],
  key: 'globePromos',
  href: 'http://glbe.co/NEWGlobeONE',
  plans: [
    {
      title: 'Eligible Promos for Prepaid',
      promos: [
        {
          title: 'Globe Prepaid',
          skus: [
            {
              name: 'Go90',
              value: 'valid for 7 days'
            },
            {
              name: 'Go+99',
              value: 'valid for 7 days'
            },
            {
              name: 'Go120',
              value: 'valid for 7 days'
            },
            {
              name: 'Go+129',
              value: 'valid for 7 days'
            },
            {
              name: 'Go140',
              value: 'valid for 7 days'
            },
            {
              name: 'Go+149',
              value: 'valid for 7 days'
            },
            {
              name: 'Go250',
              value: 'valid for 15 days'
            },
            {
              name: 'Go+250',
              value: 'valid for 15 days'
            },
            {
              name: 'Go+250',
              value: 'valid for 15 days'
            },
            {
              name: 'Go400',
              value: 'valid for 15 days'
            },
            {
              name: 'Go+400',
              value: 'valid for 15 days'
            }
          ]
        },
        {
          title: 'TM',
          skus: [
            {
              name: 'EASYSURF90',
              value: 'valid for 7 days'
            },
            {
              name: 'EASYSURF99',
              value: 'valid for 7 days'
            },
            {
              name: 'EASYSURF140',
              value: 'valid for 7 days'
            },
            {
              name: 'EASYSURF299',
              value: 'valid for 30 days'
            },
            {
              name: 'EASYSURF599',
              value: 'valid for 30 days'
            },
            {
              name: 'EASYSURF999',
              value: 'valid for 30 days'
            },
            {
              name: 'EASYSURF1299',
              value: 'valid for 30 days'
            },
            {
              name: 'EASYSURF1999',
              value: 'valid for 30 days'
            },
            {
              name: 'EASYSURF2499',
              value: 'valid for 30 days'
            }
          ]
        },
        {
          title: 'Globe Home Prepaid Wifi',
          skus: [
            {
              name: 'FAMSURF199',
              value: 'valid for 7 days'
            },
            {
              name: 'FAMSURF299',
              value: 'valid for 7 days'
            },
            {
              name: 'FAMSURF499',
              value: 'valid for 15 days'
            },
            {
              name: 'FAMSURF999',
              value: 'valid for 30 days'
            }
          ]
        }
      ]
    },
    {
      title: 'Eligible Plans for Postpaid',
      promos: [
        {
          title: 'Globe GPlan Plus Plans',
          skus: [
            {
              name: 'SIM-Only 599'
            },
            {
              name: 'SIM-Only 799'
            },
            {
              name: 'SIM-Only 999'
            },
            {
              name: 'SIM-Only 1299'
            },
            {
              name: 'SIM-Only 1499'
            },
            {
              name: 'SIM-Only 1799'
            },
            {
              name: 'SIM-Only 1999'
            },
            {
              name: 'SIM-Only 2499'
            }
          ]
        },
        {
          title: 'Globe Platinum Plans',
          skus: [
            {
              name: 'All Platinum Plans'
            }
          ]
        }
      ]
    }
  ]
};