import styled from "styled-components";

interface Roulette {
  startSpinningTime: number;
  continueSpinningTime: number;
  stopSpinningTime: number;
  startRotationDegrees: number;
  finalRotationDegrees: number;
}

export const RotationContainer = styled.div<Roulette>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => props.startRotationDegrees}deg);
  &.started-spinning {
    animation: spin ${({ startSpinningTime }) => startSpinningTime / 1000}s
    cubic-bezier(0.75, -0.25, 0.96, 0.98) 0s 1 normal forwards running,
      continueSpin 0.50s linear
        ${({ startSpinningTime }) => startSpinningTime / 1000}s 1 normal
        forwards running,
      stopSpin ${({ stopSpinningTime }) => stopSpinningTime / 1000}s
        cubic-bezier(0, 0, 0.15, 1.02)
        ${({ startSpinningTime, continueSpinningTime }) =>
          (startSpinningTime + continueSpinningTime) / 1000}s
        1 normal forwards running;
  }
  @keyframes spin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes stopSpin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => 1440 + props.finalRotationDegrees}deg);
    }
  }
`;

export const WheelImage = styled.img<any>`
  position: relative;
  width: 100%;
  margin: 45px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  object-fit: cover;
  max-width: 450px;

  @media (max-width: 767px) {
    width: 550px;
    max-width: none;
  }

  @media (max-height: 767px) {
    max-width: 450px;
  }

  @media (max-height: 675px) {
    max-width: 420px;
  }

  @media (max-height: 575px) {
    max-width: 410px;
  }
`

export const WheelArrow = styled.img<any>`
  position: absolute;
  width: 110px;
  filter: drop-shadow(-10px 30px 5px #000000CC);
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`

export const SpinText = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  font-style: normal;
  letter-spacing: 0.025em;
  cursor: pointer;
  position: absolute;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  cursor: pointer;
  background: linear-gradient(142.79deg, #f304a0 -2.01%, #792de5 87.59%);

  .chance {
    font-size: 15px;
    font-weight: 400;
    transform: translateY(-5px);

    @media (min-width: 768px) {
      font-size: 13px;
    }
  }

  @media (max-width: 767px) {
    width: 195px;
    height: 195px;
  }

  @media (max-height: 767px) {
    width: 170px;
    height: 170px;
  }

  @media (max-height: 675px) {
    width: 160px;
    height: 160px;
  }

  @media (max-height: 575px) {
    width: 150px;
    height: 150px;
  }
`
