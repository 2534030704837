import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  duration,
  firebaseEvents,
  referralLinkTexts
} from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './ReferralScreen.scss';

export const ReferralLink = ({ link, handleShare }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, duration.oneSecond);
    logFirebaseEventWithTimestamp(firebaseEvents.wenLamboReferCopy);
  }

  const text = copied ? referralLinkTexts.linkCopied : link;

  return (
    <>
      <div className="referral-screen-referral-link-wrapper">
        <div className="referral-screen-referral-link-copy">
          <div className="referral-screen-referral-link-copy-url">{text}</div>
          <CopyToClipboard text={link} onCopy={handleCopy}>
            <button className="referral-screen-referral-link-copy-button">
              <div className="referral-screen-referral-link-copy-icon" />
            </button>
          </CopyToClipboard>
        </div>
        <button
          className="referral-screen-referral-link-button"
          onClick={handleShare}
        >
          {referralLinkTexts.button}
        </button>
      </div>
    </>
  );
}