import { 
  CLEAR_LISTED_NFT,
  RESET_STATE,
  SET_ALL_LISTED_NFT,
  SET_ALL_LISTED_NFT_PAGINATION,
  SET_BUY_NAVIGATE,
  SET_BUY_NFT, 
  SET_BUY_NFT_ID, 
  SET_CANCEL_LIST_BACK, 
  SET_CANCEL_LIST_NFT, 
  SET_COLLECTION_DETAILS, 
  SET_COLLECTION_NFT_PAGINATION, 
  SET_COLLECTION_PAGINATION, 
  SET_COLLECTION_PAGINATION_LOADING, 
  SET_LISTED_NFT_PAGINATION, 
  SET_LISTING_FEE, 
  SET_LISTING_FEE_LOADING, 
  SET_LIST_NFT, 
  SET_MARKETPLACE_COLLECTION, 
  SET_MARKETPLACE_COLLECTIONS, 
  SET_MARKETPLACE_NFT_DETAILS, 
  SET_OPEN_MODAL,
  SET_SELECTED_SLUG
} from "../actions/constants";

const marketplaceState = {
  details: [],
  collections: [],
  collection: [],
  nftCollection: [],
  listedNfts: [],
  listedNftsPagination: 1,
  listedNftPagination: 1,
  paginationData: [],
  paginationNftData: [],
  isRendering: false,
  isList: false,
  isBuy: false,
  isCancelList: false,
  cancelListBack: false,
  openModal: false,
  buyNavigate: false,
  transactionId: "",
  listingFee: "",
  listingFeeLoading: false,
  slug: ""
};

const merketplaceReducer = (state = marketplaceState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return marketplaceState;
    case SET_MARKETPLACE_COLLECTIONS:
      return {
        ...state,
        collections: action.collections
      };
    case SET_MARKETPLACE_COLLECTION:
      return {
        ...state,
        nftCollection: action.nftCollection
      };
    case SET_ALL_LISTED_NFT:
      return {
        ...state,
        listedNfts: [...state.listedNfts, ...action.listedNfts]
      };
    case SET_ALL_LISTED_NFT_PAGINATION:
      return {
        ...state,
        listedNftsPagination: state.listedNftsPagination + 1
      };
    case SET_LISTED_NFT_PAGINATION:
      return {
        ...state,
        listedNftPagination: state.listedNftPagination + 1
      };
    case SET_MARKETPLACE_NFT_DETAILS:
      return {
        ...state,
        details: [...state.details, ...action.details]
      };
    case CLEAR_LISTED_NFT:
      return {
        ...state,
        details: action.details
      };
    case SET_COLLECTION_DETAILS:
      return {
        ...state,
        collection: action.collection
      }
    case SET_COLLECTION_PAGINATION:
      return {
        ...state,
        paginationData: action.paginationData
      };
    case SET_COLLECTION_NFT_PAGINATION:
      return {
        ...state,
        paginationNftData: action.paginationNftData
      };
    case SET_COLLECTION_PAGINATION_LOADING:
      return {
        ...state,
        isRendering: action.isRendering
      };
    case SET_LIST_NFT:
      return {
        ...state,
        isList: action.isList
      };
    case SET_LISTING_FEE:
      return {
        ...state,
        listingFee: action.listingFee
      };
    case SET_LISTING_FEE_LOADING:
      return {
        ...state,
        listingFeeLoading: action.listingFeeLoading
      };
    case SET_BUY_NFT:
      return {
        ...state,
        isBuy: action.isBuy
      };
    case SET_BUY_NFT_ID:
      return {
        ...state,
        transactionId: action.transactionId
      };
    case SET_CANCEL_LIST_NFT:
      return {
        ...state,
        isCancelList: action.isCancelList
      };
    case SET_CANCEL_LIST_BACK:
      return {
        ...state,
        cancelListBack: action.cancelListBack
      };
    case SET_OPEN_MODAL:
      return {
        ...state,
        openModal: action.openModal
      };
    case SET_BUY_NAVIGATE:
      return {
        ...state,
        buyNavigate: action.buyNavigate
      };
    case SET_SELECTED_SLUG:
      return {
        ...state,
        slug: action.slug
      };
    default:
      return state;
  }
}

export default merketplaceReducer;