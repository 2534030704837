import React from 'react';
import isArray from 'lodash/isArray';
import './GamePassGame.scss';

const GameDetails = ({ genres, participants, gameName, description, buttonText, onClickButton }) => {
  const joinedGenres = isArray(genres) ? genres?.join(', ') : genres;
  return (
    <div className="gamepass-game-details">
      <div className="gamepass-game-details-wrapper">
        <div className="gamepass-game-details-genres">{joinedGenres}</div>
        <div className="gamepass-game-details-participants">
          <div className="gamepass-game-details-participants-icon" />
          <div className="gamepass-game-details-participants-text">{participants}</div>
        </div>
      </div>
      <div className="gamepass-game-details-title">{gameName}</div>
      <p className="gamepass-game-details-description">{description}</p>
      <button onClick={onClickButton} className="gamepass-game-button">{buttonText}</button>
    </div>
  )
};

export default GameDetails;