import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setInstructionsVisible, setTopUpVisible } from '../../actions';
import { useQuery } from '../../hooks/useQuery';
import PaymentInstruction from '../PaymentInstructions';

export const WalletInstructionsModal = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const method = query.get("method");
  const isInstructionsVisible = useSelector((state) => state.transactions.isInstructionsVisible);
  const handleNavigate = () => {
    dispatch(setInstructionsVisible(false));
    if (method) dispatch(setTopUpVisible(true));
  }

  return (
    <Modal
      backdrop
      show={isInstructionsVisible}
      className="wallet-modal-container"
      contentClassName="wallet-modal"
      dialogClassName="wallet-modal-dialog"
      backdropClassName="wallet-modal-bg"
      centered
      onHide={handleNavigate}
    >
      <Modal.Body>
        <PaymentInstruction/>
      </Modal.Body>
    </Modal>
  )
}
