import {
  SET_LOADING,
  RENDER_PAGE_CONTENTS,
  SET_USER_PROFILE,
  SET_USER_PROFILE_ERROR_STATUS,
  SHOW_ORDER_SUMMARY,
  SET_UNREAD_MESSAGES,
  SET_SESSION_EXPIRED,
  SET_GUEST,
  SET_URL_PARAMS,
  SET_HIDDEN_FOOTER,
  SET_HIDDEN_MENU_ICON,
  SET_HIDDEN_HEADER,
  SET_WALKTHROUGH_HEIGHT_LIMIT_FLAG,
  DISABLE_FOOTER_POINTER_EVENT,
  SET_REMOTE_CONFIG,
  SET_SHOW_LOADING,
  NAVIGATE_TO,
  RESET_STATE,
  SHOW_ACTIVE_MODAL
} from '../actions/constants';
import {
  menuItems,
  sideMenuItems,
  sideMenuOtherItems
} from '../constants';

const layoutState = {
  loading: true,
  showLoading: false,
  navigateData: null,
  pageContentsRendered: false,
  userProfile: {},
  userProfileErrorStatus: 0,
  menuItems,
  sideMenuItems,
  sideMenuOtherItems,
  orderSummaryVisible: false,
  unreadMessages: 0,
  sessionExpired: false,
  isGuest: false,
  urlParams: {},
  hideHeader: false,
  hideMenuIcon: false,
  hideFooter: false,
  hasNoWalkthroughHeightLimit: false,
  shouldDisable: false,
  remoteConfigKeys: {
    strippedStwEnabled: false,
    strippedGameUrls: '',
    strippedStwUsers: []
  },
  activeModal: ''
};

const layoutReducer = (state = layoutState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return layoutState;
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_SHOW_LOADING:
      return {
        ...state,
        showLoading: action.showLoading
      };
    case NAVIGATE_TO:
      return {
        ...state,
        navigateData: action.navigateData
      };
    case RENDER_PAGE_CONTENTS:
      return {
        ...state,
        pageContentsRendered: action.pageContentsRendered
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile
      };
    case SET_USER_PROFILE_ERROR_STATUS:
      return {
        ...state,
        userProfileErrorStatus: action.userProfileErrorStatus
      };
    case SHOW_ORDER_SUMMARY:
      return {
        ...state,
        orderSummaryVisible: action.orderSummaryVisible
      };
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.unreadMessages
      };
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.sessionExpired
      };
    case SET_GUEST:
      return {
        ...state,
        isGuest: action.isGuest
      };
    case SET_URL_PARAMS:
      return {
        ...state,
        urlParams: action.urlParams
      };
    case SET_HIDDEN_HEADER:
      return {
        ...state,
        hideHeader: action.hideHeader
      };
    case SET_HIDDEN_MENU_ICON:
      return {
        ...state,
        hideMenuIcon: action.hideMenuIcon
      };
    case SET_HIDDEN_FOOTER:
      return {
        ...state,
        hideFooter: action.hideFooter
      };
    case SET_WALKTHROUGH_HEIGHT_LIMIT_FLAG:
      return {
        ...state,
        hasNoWalkthroughHeightLimit: action.hasNoWalkthroughHeightLimit
      };
    case DISABLE_FOOTER_POINTER_EVENT:
      return {
        ...state,
        shouldDisable: action.shouldDisable
      };
    case SET_REMOTE_CONFIG:
      return {
        ...state,
        remoteConfigKeys: {
          ...state.remoteConfigKeys,
          ...action.remoteConfigKeys
        }
      };
    case SHOW_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.activeModal
      };
    default:
      return state;
  }
}

export default layoutReducer;