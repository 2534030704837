import React from 'react';
import classNames from 'classnames';
import './GamePassMechanics.scss';

export const GamePassFAQs = ({ title, items, mobile }) => (
  <div className={classNames(
    "game-pass-mechanics-faqs", {
      "game-pass-mechanics-faqs-mobile": mobile
    }
  )}>
    <h1 className="game-pass-mechanics-title">{title}</h1>
    <div className="game-pass-mechanics-faqs-list">
      {items.map((f, fIdx) => (
        <div key={`GamePassFAQ_${fIdx + 1}`} className="game-pass-mechanics-faq">
          <div className="game-pass-mechanics-faq-question">{f.question}</div>
          <div
            className="game-pass-mechanics-faq-answer"
            dangerouslySetInnerHTML={{ __html: f.answer }}
          />
        </div>
      ))}
    </div>
  </div>
);