import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFeaturedPromotionSettings, setLoading } from '../../actions';
import { LayoutContent, PageTitle } from '../Components'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './TicketRedeem.scss'
import { musicFestData } from '../../constants/ticketRedeem';

export const TicketRedeem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(state => state.redeem.data);
  const marketingId = useSelector(state => state.redeem.featuredPromotion.marketingId);

  useEffect(() => {
    dispatch(setLoading(false));
  },[dispatch])

  useEffect(() => {
   if (marketingId) dispatch(getFeaturedPromotionSettings(marketingId));
  },[marketingId, dispatch])

  const handleNavigate = (data) => {
    if (data.isEligible) return navigate(`/redeem${data.action.path}`);
    navigate(data.action.path);
  }
  return (
    <LayoutContent outerClassName="ticket-container" innerClassName="ticket-wrapper">
      <PageTitle title="Ticket Redeem"/>
      <div className="ticket">
        <div className="ticket-inner">
          <ArrowBackIosNewRoundedIcon onClick={() => window.location.href = '/'} className="ticket-inner-back"/>
          <div className="ticket-image-container">
            <div className="ticket-image"/>
          </div>
          <div className="ticket-body">
            <div className="ticket-body-container">
              {musicFestData.map((d, dIdx) => {
                return (
                  <div className="ticket-body-wrapper" key={dIdx}>
                    <div className="ticket-claim-header" dangerouslySetInnerHTML={{__html: d.title}}/>
                    <div className="ticket-claim-contents" dangerouslySetInnerHTML={{__html: d.contents}}/>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="ticket-footer">
        {Object.keys(data).length !== 0 && <div className="ticket-footer-container">
          <div className="ticket-footer-text-wrapper">
            {data.captions.map((c, cIdx) => {
              return <div key={`Captions_${cIdx}`} className="ticket-footer-text m-1 mb-1">{c}</div>
            })}
          </div>
          <button onClick={() => handleNavigate(data)} className="ticket-footer-button">{data.action.buttonTitle}</button>
        </div>}
      </div>
    </LayoutContent>
  )
}

