import cryptoWalletIcon from '../assets/wallet-connect/crypto-wallet.png';
import metaMaskIcon from '../assets/wallet-connect/metamask.png';

export const wallets = {
  walletConnect: {
    name: 'WALLETCONNECT'
  },
  metaMask: {
    id: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    name: 'MetaMask'
  }
};
export const isNotSupportedMessage = 'Wallet is currently not supported';
export const walletConnectTexts = {
  connectViaWalletConnect: 'Connect via WalletConnect',
  validChains: 'Ethereum or Polygon Mainnet',
  disconnect: 'Disconnect',
  selectCurrency: 'Select Currency',
  apply: 'Apply',
  title: 'Your Crypto Wallet',
  notConnected: 'Not Connected'
};
export const paymentMethodsData = {
  default: {
    name: 'Metamask',
    imageUrl: metaMaskIcon
  },
  tokenizedPayment: {
    code: 'WALLETCONNECT',
    imageUrl: cryptoWalletIcon,
    name: 'Pay via Crypto'
  }
};
export const errorMessage = {
  insufficientBalance: 'You may have insufficient balance.',
  transferAmountExceedsBalance: 'ERC20: transfer amount exceeds balance',
  userRejectedRequest: 'User rejected the request',
  rpcRequestFailed: 'RPC Request failed'
};
export const types = {
  connect: 'connect'
};
export const preservedKeys = ['sessionToken', 'mobileNumber', 'username', '_grecaptcha'];
export const usdtABI = [
  {
    type: 'event',
    name: 'Approval',
    inputs: [
      {
        indexed: true,
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        name: 'value',
        type: 'uint256',
      },
    ],
  },
  {
    type: 'event',
    name: 'Transfer',
    inputs: [
      {
        indexed: true,
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        name: 'value',
        type: 'uint256',
      },
    ],
  },
  {
    type: 'function',
    name: 'allowance',
    stateMutability: 'view',
    inputs: [
      {
        name: 'owner',
        type: 'address',
      },
      {
        name: 'spender',
        type: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
  },
  {
    type: 'function',
    name: 'approve',
    stateMutability: 'nonpayable',
    inputs: [
      {
        name: 'spender',
        type: 'address',
      },
      {
        name: 'amount',
        type: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
  },
  {
    type: 'function',
    name: 'balanceOf',
    stateMutability: 'view',
    inputs: [
      {
        name: 'account',
        type: 'address',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
  },
  {
    type: 'function',
    name: 'decimals',
    stateMutability: 'view',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint8',
      },
    ],
  },
  {
    type: 'function',
    name: 'name',
    stateMutability: 'view',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'string',
      },
  ],
  },
  {
    type: 'function',
    name: 'symbol',
    stateMutability: 'view',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'string',
      },
    ],
  },
  {
    type: 'function',
    name: 'totalSupply',
    stateMutability: 'view',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
  },
  {
    type: 'function',
    name: 'transfer',
    stateMutability: 'nonpayable',
    inputs: [
      {
        name: 'recipient',
        type: 'address',
      },
      {
        name: 'amount',
        type: 'uint256',
      },
    ],
    outputs: [],
  },
  {
    type: 'function',
    name: 'transferFrom',
    stateMutability: 'nonpayable',
    inputs: [
      {
        name: 'sender',
        type: 'address',
      },
      {
        name: 'recipient',
        type: 'address',
      },
      {
        name: 'amount',
        type: 'uint256',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
  },
];
export const currencies = {
  USDT: 'USDT'
};
export const walletConnectFAQs = {
  title: 'How to use WalletConnect?',
  link: 'https://help.metaversego.gg/hc/en-us/categories/7815342190991-Wallet-Connect'
};