import React from 'react';
import { Link } from 'react-router-dom';
import { Progress } from '../Commons';

export const ProfileInfo = ({ imageUrl, username, levelProgress, editProfileLink, editProfileLinkClick }) => {
  const profilePhotoStyle = imageUrl && imageUrl !== '' ? { backgroundImage: `url(${imageUrl})` } : null;
  return (
    <div className="profile-info">
      <div className="profile-info-pic" style={profilePhotoStyle}></div>
      <div className="profile-info-wrapper">
        <div className="profile-info-details">
          <p className="profile-info-details-name">{username}</p>
          <Link to={editProfileLink} className="profile-info-details-edit-profile" onClick={editProfileLinkClick}>Edit Profile</Link>
        </div>
        <div className="profile-info-quest">
          <div className="profile-info-quest-level">{levelProgress.level}</div>
          <Progress
            tickVisible
            percentage={levelProgress.percentage}
            className="profile-info-quest-progress"
            barClassName="profile-info-quest-progress-bar"
            tickClassName="profile-info-quest-progress-tick"
          />
          <div>
            <div className="profile-info-quest-xp">{levelProgress.experience}</div>
            <div className="profile-info-quest-remaining">{levelProgress.nextLevelExperience}</div>
          </div>
        </div>
        {/* <div className="profile-info-footnote">{levelProgress.howToLevelUp} <a href={levelProgress.howToLevelUpUrl} target="_blank" rel="noreferrer">{levelProgress.howToLevelUpClickable}</a></div> */}
      </div>
    </div>
  );
}