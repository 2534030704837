import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { GameOverCheckoutContents } from './GameOverCheckoutContents';
import { GamePassCheckoutContents } from './GamePassCheckoutContents';
import { BlurBackgroundModal } from '../../Commons';
import { 
  authSteps,
  defaultMobileNumberPrefix,
  duration,
  gamePassCheckoutTitle,
  gamePassPacks,
  gamePassScreenSections,
  layoutQueryParams,
  onboardingTexts,
  paymentMethods,
  singleQuantityAddToCart,
  storageKeys
} from '../../../constants';
import {
  addGamePassToCart,
  getGamePassCheckout,
  setGamePassCheckout,
  setShowPaymentMethods,
  updateGamePassCheckout 
} from '../../../actions';
import { 
  authStepSelector,
  checkoutContentsSelector,
  homeCollectionSelector,
  remoteConfigKeysSelector,
  showPaymentMethodsSelector 
} from '../../../selectors';
import './GamePassCheckoutModal.scss';

const GamePassCheckoutModal = ({ open, hide }) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [countryCode, setCountryCode] = useState(defaultMobileNumberPrefix);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({
    paymentMethod: false,
    termsAndConditions: false
  });

  const checkoutContents = useSelector(state => checkoutContentsSelector(state));
  const showPaymentMethods = useSelector(state => showPaymentMethodsSelector(state));
  const authStep = useSelector(state => authStepSelector(state));
  const remoteConfigKey = useSelector(state => remoteConfigKeysSelector(state));
  const homeCollection = useSelector(state => homeCollectionSelector(state));

  const pmSourceSessionStorage = sessionStorage.getItem(storageKeys.pmSource);
  const pmSourceCookie = Cookies.get(storageKeys.pmSource);
  const checkoutSku = searchParams.get(layoutQueryParams.checkoutSku);

  const isGameOverCheckout = checkoutSku &&
    checkoutSku !== '' &&
    checkoutSku !== gamePassPacks.welcomeBundle;

  const handleSelectPaymentMethod = () => {
    dispatch(setShowPaymentMethods({ dropdown: !showPaymentMethods?.dropdown, currency: false }));
  }

  const handleClose = () => {
    setTimeout(() => {
      if (checkoutSku) {
        searchParams.delete(layoutQueryParams.checkoutSku);
        setSearchParams(searchParams, { replace: true });
      }
    }, duration.pointThreeSeconds)
    
    dispatch(setGamePassCheckout({}));
    hide();
  }

  const getCheckoutSku = useCallback(() => {
    if (isEmpty(homeCollection)) return;
    if (isGameOverCheckout) {
      const gamePasses = homeCollection?.find(c => c.dataset === gamePassScreenSections.buyGamePass);
      const selectedSection = gamePasses?.d?.find(d => {
        const skus = d.skus.find(s => s.kind === checkoutSku);
        return !isNil(skus);
      });
      const selectedItem = selectedSection?.skus?.find(s => s.kind === checkoutSku);
      const addToCartData = {
        productId: selectedItem?.productId,
        skuId: selectedItem?.id,
        quantity: singleQuantityAddToCart,
        isCheckout: true,
        requestCheckout: true,
        isCheckoutModal: true
      };
      dispatch(addGamePassToCart(addToCartData));
    } else {
      dispatch(getGamePassCheckout(onboardingTexts.onboarding));
    }
  }, [homeCollection, isGameOverCheckout])

  useEffect(() => {
    if (authStep === authSteps.otp) {
      hide();
    }
  }, [authStep, hide])

  useEffect(() => {
    if (open && isEmpty(checkoutContents)) {
      if (pmSourceSessionStorage && pmSourceCookie) {
        dispatch(updateGamePassCheckout({ 
          selectedPaymentMethod: paymentMethods.gcash,
          from: onboardingTexts.onboarding,
          shouldNotBack: true
        }));
      } 
      
      getCheckoutSku();
    };
  }, [dispatch, open, checkoutContents, pmSourceSessionStorage, pmSourceCookie, getCheckoutSku])

  let modalTitle = '';
  if (!showPaymentMethods?.modal) modalTitle = onboardingTexts.gamePassCheckout;
  if (isGameOverCheckout) modalTitle = gamePassCheckoutTitle;

  return (
    <BlurBackgroundModal
      open={open}
      title={modalTitle}
      hide={handleClose}
    >
      {isGameOverCheckout ?
        <GameOverCheckoutContents
          checkoutContents={checkoutContents}
          handleSelectPaymentMethod={handleSelectPaymentMethod}
        /> :
        <GamePassCheckoutContents
          checkoutContents={checkoutContents}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          handleSelectPaymentMethod={handleSelectPaymentMethod}
          checked={checked}
          setChecked={setChecked}
          hideEmail
          setErrors={setErrors}
          errors={errors}
          remoteConfigKey={remoteConfigKey}
        />}
    </BlurBackgroundModal>
  )
}

export default GamePassCheckoutModal;