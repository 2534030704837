import React, { Component } from 'react';
import { 
  Barcode, 
  LayoutContent, 
  PageTitle,
  Section, 
  TransactionDetailsModal 
} from '../Components';
import { WalletInstructionsModal } from '../Wallet/WalletInstructionsModal';
import { TransactionsTab } from './TransactionsTab';
import './Transactions.scss';

export class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      filter: false,
      latestDate: '',
      oldestDate: '',
      startDate: '',
      endDate: '',
      filteredTransactions: [],
      isFilter: false,
      selectedType: '',
      types: [],
      selectedStatus: '',
      statuses: [],
      isBarcodeVisible: false
    };

    this.transactions = null;
  }

  componentDidMount() {
    this.props.setLoading(true);
  }

  componentDidUpdate(prevProps) {
    const { transactions } = this.props;
    if (prevProps.transactions !== transactions) {
      this.handleTransactionTypes();
      this.handleTransactionStatuses();
    }
  }

  openFilter = (filter) => {
    this.setState({ filter });
  }

  clearFilter = () => {
    const { transactions } = this.props;
    this.showFilterIcon(false);
    this.openFilter(false);
    this.setState({ filteredTransactions: transactions });
  }

  getStartDate = (date) => {
    this.setState({ startDate: date });
  }

  getEndDate = (date) => {
    this.setState({ endDate: date });
  }

  getLatestDate = () => {
    const { transactions } = this.props;
    let allDates = []
    for (const date of transactions){
      const newDate = date.dateCreated.split('T');
      allDates.push(newDate[0]);
    }
    this.setState({ latestDate: allDates[0] });
    allDates.reverse();
    this.setState({ oldestDate: allDates[0] });
  }

  handleTransactionTypes = () => {
    const { transactions } = this.props;
    let types = [];
    for (const t of transactions) {
      if (t.category && !types.includes(t.category)) types.push(t.category);
    }
    this.setState({ types });
  }

  handleTransactionStatuses = () => {
    const { transactions } = this.props;
    let statuses = [];
    for (const t of transactions) {
      if (t.status && !statuses.includes(t.status)) statuses.push(t.status);
    }
    this.setState({ statuses });
  }
  
  showFilterIcon = (isFilter) => {
    this.setState({ isFilter });
  }
  
  handleSelectedType = (type) => {
    this.setState({ selectedType: type });
  }

  handleSelectedStatus = (status) => {
    this.setState({ selectedStatus: status });
  }

  showBarcode = (e, method) => {
    e.stopPropagation();
    this.setState({ selectedMethod: method });
    this.props.setBarcodeVisible(true);
  }

  hideBarcode = () => {
    this.props.setBarcodeVisible(false);
  }

  render() {
    const { 
      transactions, 
      isBarcodeVisible, 
      selectedMethod 
    } = this.props;
    if (transactions) {
      return (
        <LayoutContent outerClassName="transactions-container">
          <PageTitle title="Transaction History" />
          <Section
            title="Transaction History"
            sectionClassName="transactions-section"
            sectionHeaderClassName="transactions-section-header"
            backButtonRoute="/wallet"
            titleCentered
          >
            <TransactionsTab
              setIsBarcodeVisible={this.showBarcode}
              hooks={true}
              transactions={transactions}
            />
          </Section>
          {selectedMethod && 
            <Barcode 
              selectedMethod={selectedMethod} 
              isBarcodeVisible={isBarcodeVisible}
              hide={this.hideBarcode}
            />}
          <WalletInstructionsModal/>
          <TransactionDetailsModal/>
        </LayoutContent>
      );
    }

    return null;
  }
}