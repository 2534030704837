import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { authSteps, firebaseEvents } from '../../../constants';
import { unauthorizedRedirect } from '../../../actions';
import {
  logFirebaseEvent,
  logFirebaseEventWithTimestamp
} from '../../../utils/logFirebaseEvent';
import './Banner.scss';

export const Banner = ({
  title,
  buttonId,
  text,
  imageUrl,
  buttonLink,
  buttonText,
  openInNewTab,
  imageClickable,
  carousel,
  buttonAnchor,
  onClick,
  children,
  id,
  button,
  onClickButton,
  featured,
  isGuest
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const anchorTarget = openInNewTab ? '_blank' : '_self';

  const handleRoute = (route) => {
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login,
          route
        })
      );
    } else if (openInNewTab) {
      window.open(route, anchorTarget);
    } else {
      navigate(route);
    }
    logFirebaseEvent(
      firebaseEvents.playGame, {
        game_id: id, 
        game_name: title
      });
    logFirebaseEventWithTimestamp(
      firebaseEvents.gamesLaunch, {
        game_name: title
      });
  }

  return (
    <div className={classNames("banner", {
      "banner-clickable": imageClickable,
      "banner-carousel": carousel,
      "banner-carousel-solo": featured?.length <= 1
    })}>
      {imageClickable ? (
        buttonAnchor ?
          <a
            href={buttonLink}
            target={anchorTarget}
            rel="noreferrer"
            className={classNames(
              "banner-cover",
              "banner-cover-link",
              {"banner-cover-carousel": carousel}
            )}
            style={{ backgroundImage: `url(${imageUrl})` }}
            onClick={onClick}
          /> :
          <Link
            to={buttonLink}
            className={classNames(
              "banner-cover",
              "banner-cover-link",
              {"banner-cover-carousel": carousel}
            )}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        ) :
        <div
          className="banner-cover"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />}
      {title && text && <div className="banner-details">
        <div className={classNames("banner-description", {"banner-description-without-cta": !buttonText})}>
          <div className="banner-info">
            <div>
              <div className={classNames("banner-text", {"banner-text-with-button": buttonText})}>
                <h1 className="banner-title">{title}</h1>
                <div className="banner-short-desc">{text}</div>
              </div>
              {buttonText && <div className="banner-button-wrapper">
                {buttonAnchor ?
                  button  ?  
                  <button onClick={onClickButton} className="banner-button">{buttonText}</button> :
                  <div
                    target={anchorTarget}
                    rel="noreferrer"
                    id={buttonId}
                    name={buttonId}
                    className="banner-button"
                    onClick={() => handleRoute(buttonLink)}
                  >{buttonText}</div> :
                  <Link to={buttonLink} className="banner-button">
                    {buttonText}
                  </Link>}
              </div>}
            </div>
            {children}
          </div>
        </div>
      </div>}
    </div>
  );
}