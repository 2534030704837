import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Col, Row } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import CollectiblesModal from './CollectiblesModal';
import { CollectiblesSection } from './CollectiblesSection';
import Section from '../Section/Section';
import GamePassProductAmount from '../GamePassProductAmount/GamePassProductAmount';
import { 
  DesktopModal, 
  Popup, 
  Share, 
  Toast 
} from  '../../Commons'; 
import { 
  setBuyNavigate, 
  setDisplayToast, 
  setHideFooter, 
  setHideHeader, 
  setOpenModal, 
  setTopUpVisible,
  setIsList,
  unauthorizedRedirect
} from '../../../actions';
import {
  authSteps,
  collectiblesTexts,
  defaultDomain,
  launchpadSharePlatforms,
  minWidths,
  regexConstants,
  sellDefaultValue
} from '../../../constants';
import { 
  cancelListRedirectBackSelector, 
  displayLoginToastSelector, 
  guestSelector, 
  marketplaceCollectionSelector, 
  marketplaceOpenModalSelector, 
  nftBuyRedirectToTopUpSelector, 
  nftCanBuySelector, 
  nftCanListSelector, 
  userProfileSelector 
} from '../../../selectors';
import { getWalletCredits } from '../../../utils/getWalletCredits';
import { setShareText } from '../../../utils/setShareText';
import './Collectibles.scss';

const Collectibles = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openShare, setOpenShare] = useState(false);
  const [toastOpen , setToastOpen] = useState(false);
  const [isOwned, setIsOwned] = useState(false);
  const [cancelList, setCancelList] = useState(false);
  const [unlisted, setUnlisted] = useState(false);
  const [price, setPrice] = useState(false);
  const [neededFunds, setNeededFunds] = useState(0);
  const [showDetailsIcons, setShowDetailsIcons] = useState(false);

  const isGuest = useSelector(state => guestSelector(state));
  const collection = useSelector(state => marketplaceCollectionSelector(state));
  const isList = useSelector(state => nftCanListSelector(state));
  const isBuy = useSelector(state => nftCanBuySelector(state));
  const userProfile = useSelector(state => userProfileSelector(state));
  const openModal = useSelector(state => marketplaceOpenModalSelector(state));
  const buyNavigate = useSelector(state => nftBuyRedirectToTopUpSelector(state));
  const cancelListBack = useSelector(state => cancelListRedirectBackSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  useEffect(() => {
    if (!isDesktop) {
      dispatch(setHideFooter(true));
      dispatch(setHideHeader(true));
    }

    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideHeader(false));
    }
  }, [dispatch, isDesktop])

  useEffect(() => {
    if (isEmpty(item.nftListing) && userProfile.uid === item.owner.uid) {
      setIsOwned(true);
      setCancelList(false);
    } else if (!isEmpty(item.nftListing) && userProfile.uid === item.owner.uid){
      setCancelList(true);
      setIsOwned(false);
    } else {
      setIsOwned(false);
      setCancelList(false);
    }
  }, [collection, item, userProfile])

  useEffect(() => {
    if (isEmpty(item.nftListing) && item.owner.uid !== userProfile.uid) {
      setUnlisted(true);
    }
    return () => setUnlisted(false);
  }, [item, userProfile])
 
  useEffect(() => {
    setToastOpen(true)
  }, [toast])

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    },500);
  }

  const handleButtonText = () => {
    let buttonText = collectiblesTexts.buy;
    if (isOwned) buttonText = collectiblesTexts.sell;
    if (!isOwned && cancelList) buttonText = collectiblesTexts.cancelListing;
    if (isGuest) buttonText = collectiblesTexts.loginToBuy;
    return buttonText;
  }
  
  const handleButton = () => {
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    if (isList) dispatch(setIsList(false));
    dispatch(setOpenModal(true));
  }

  const handleModal = (boolean) => {
    dispatch(setOpenModal(boolean));
  }

  useEffect(() => {
    let value = '';
    if (!isGuest) {
      const valueObject = getWalletCredits(userProfile?.wallet?.crypto);
      if (valueObject) value = valueObject.val;
    }
    const newMgcValue = parseFloat(value.replace(regexConstants.comma, ''));
    const newNftPrice = parseFloat(item.nftListing?.price.replace(regexConstants.comma, ''));
    if (newMgcValue < newNftPrice){
      const neededFunds = newNftPrice - newMgcValue;
      setNeededFunds(neededFunds.toFixed(2));
    }
    return () => setNeededFunds(0);
  }, [userProfile, item, isGuest])
  
  useEffect(() => {
    if (buyNavigate && isDesktop) {
      navigate('/wallet');
      dispatch(setTopUpVisible(true));
    } else if (buyNavigate && !isDesktop) {
      navigate('/topup');
    }
    dispatch(setBuyNavigate(false));
  }, [dispatch, navigate, isDesktop, buyNavigate])

  const handleBackRoute = () => {
    if (cancelListBack) return window.location.href = `/marketplace/${item.slug}`;
    navigate(-1);
  }

  useEffect(() => {
    let price = '';
    if (!isEmpty(item.nftListing)){
      price = item.nftListing.priceLC;
    } else {
      price = `MGC ${sellDefaultValue}.00`
    }
    setPrice(price);
  }, [item, unlisted])

  const listedFlag = (!isOwned && cancelList && !isDesktop);

  return (
    <>
      <Section 
        backButtonRoute 
        backRoute={handleBackRoute} 
        text="back to collection" 
        sectionClassName="collectibles-section"
        listed={listedFlag && "Listed"}
        titleCentered
      >
        <div className={`collectibles-details ${listedFlag && "pt-0"}`}>
          <Row className="collectibles-details-container">
            <Col md={6} lg={5}>
              <div className="collectibles-details-image-wrapper">
                <img 
                  className="collectibles-details-image" 
                  src={item.imageUrl || item.image_url} 
                  alt="nft"
                />
              </div>
            </Col>
            <Col md={6} lg={7} className="collectibles-details-section">
              <div className="collectibles-details-section-header"> 
                <div className="collectibles-details-section-header-box">
                  <div className="collectibles-details-section-nft">
                    {item.collectionName || item.name}
                  </div>
                  <div className="collectibles-details-section-name">
                    <div className="d-flex align-items-center gap-3">
                      <div>{item.name}</div>
                      {!isOwned && 
                        cancelList && 
                        isDesktop && 
                        <div className="collectibles-details-section-indicator">
                          {collectiblesTexts.listed}
                        </div>}
                    </div>
                  </div>
                  <div className="collectibles-details-section-owner">
                    {collectiblesTexts.ownedBy} &nbsp;
                    <span className="collectibles-details-section-owner-text">
                      {item.owner && item.owner.username}
                    </span>
                  </div>
                </div>
                {!item.isWithdrawable &&  
                  (isOwned ||
                  (!isOwned && cancelList)) &&
                  <div 
                    onClick={() => setOpenShare(true)} 
                    className="collectibles-details-share-wrapper"
                  >
                    <div className="collectibles-details-share-icon" />
                    <div className="collectibles-details-share">
                      {collectiblesTexts.share}
                    </div>
                  </div>}
                {item.isWithdrawable && 
                  !isDesktop &&
                  (isOwned ||
                  (!isOwned && cancelList)) &&
                  <div 
                    className='kebab-dropdown-button' 
                    onClick={() => setShowDetailsIcons(true)}
                  >
                    <MoreVertIcon />
                  </div>}
                {item.isWithdrawable && 
                  isDesktop && 
                  (isOwned ||
                  (!isOwned && cancelList)) &&
                  <Dropdown>
                    <Dropdown.Toggle className="kebab-dropdown-button">
                      <MoreVertIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="kebab-dropdown-menu" variant='light'>
                        <Dropdown.Item 
                          className="kebab-dropdown-wrapper" 
                          onClick={() => setOpenShare(true)} 
                          variant='light'
                        > 
                          <div className="kebab-dropdown-share-icon"/>    
                          <div className="kebab-dropdown-item">
                            {collectiblesTexts.share}
                          </div>
                        </Dropdown.Item>
                        {isEmpty(item.nftListing) && 
                          <Dropdown.Item 
                            className="kebab-dropdown-wrapper" 
                            onClick={() => navigate(`/wallet/${item.id}/withdrawal`)} 
                            variant='light'
                          > 
                            <div className="kebab-dropdown-withdraw-icon"/>    
                            <div className="kebab-dropdown-item">
                              {collectiblesTexts.withdraw}
                            </div>
                          </Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
              <div className="collectibles-details-box">
                <div className={classNames(
                    "collectibles-details-box-wrapper", 
                    {"collectibles-details-box-wrapper-hide": isOwned}
                )}>
                  <GamePassProductAmount 
                    {...item?.nftListing} 
                    isMarketplace 
                    fontLarge 
                  />
                </div>
                <button 
                  onClick={handleButton} 
                  className={classNames(
                    "collectibles-details-box-button", {
                      "collectibles-details-box-button-disabled": unlisted, 
                      "collectibles-details-box-button-sell": isOwned
                    }
                  )}
                >
                  {handleButtonText()}
                </button>
              </div>
              <CollectiblesSection item={item} />
            </Col>
          </Row>
        </div>
      </Section>
      <Share
        image={item.imageUrl}
        title={setShareText(item.name, item.slug)}
        subtitle={defaultDomain.replace('https://', '')}
        url={item.imageUrl}
        shareIsVisible={openShare}
        exitShare={() => setOpenShare(false)}
        platforms={launchpadSharePlatforms}
      />
      <DesktopModal
        open={openModal} 
        handleOpen={handleModal}
        isOwned={isOwned}
        isList={isList}
        isBuy={isBuy}
        cancelList={cancelList}
      >
        <CollectiblesModal
          {...item}
          image={item.imageUrl || item.image_url}
          setOpenModal={handleModal}
          isOwned={isOwned}
          isList={isList}
          cancelList={cancelList}
          isBuy={isBuy}
          neededFunds={neededFunds}
        />
      </DesktopModal>
      {toast && 
        <Toast toast={toast} open={toastOpen} hide={closeToast} />}
      <Popup
        open={showDetailsIcons && !isDesktop}
        hide={() => setShowDetailsIcons(false)}
        className="kebab-dropdown-toast"
      >
        <div className='kebab-dropdown-wrapper' onClick={() => setOpenShare(true)} >
          <div className="kebab-dropdown-share-icon" />    
          <div className="kebab-dropdown-item">
            {collectiblesTexts.share}
          </div>
        </div>
        {isEmpty(item.nftListing) && 
          <div 
            className='kebab-dropdown-wrapper' 
            onClick={() => navigate(`/wallet/${item.id}/withdrawal`)} 
          >
            <div className="kebab-dropdown-withdraw-icon" />    
            <div className="kebab-dropdown-item">
              {collectiblesTexts.withdraw}
            </div>
          </div>}
      </Popup>
    </>
  );
}

export default Collectibles;
