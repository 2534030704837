import axios from 'axios';
import { baseUrl } from './url';

export function requestTutorialScreens(key, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/tutorials?key=${key}`)
  });
}

export function requestMockTutorialScreens(url) {
  return axios.request({
    method: 'GET',
    url
  });
}

export function requestOnboardingTutorial(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('onboarding/tutorials')
  });
}