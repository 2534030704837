import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../hooks/useQuery';
import {
  GamePassTermsSection,
  LayoutContent,
  MechanicsCarousel,
  PageTitle,
  Section
} from '../Components';
import { HiddenHeaderAndFooter } from '../Commons';
import { GamePassMechanicsPass } from './GamePassMechanicsPassType';
import { GamePassFAQs } from './GamePassFAQs';
import { setHideFooter } from '../../actions';
import { gamePassMechanics } from '../../constants';
import './GamePassMechanics.scss';

export const GamePassMechanics = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const mobile = query.get('mobile');
  const backToPreviousPage = () => window.history.back();

  useEffect(() => {
    dispatch(setHideFooter(true));
  }, [dispatch]);

  const title = mobile ? '' :  gamePassMechanics.title;

  return (
    <>
      {gamePassMechanics &&
        <LayoutContent outerClassName="game-pass-mechanics" innerClassName="game-pass-mechanics-wrapper">
          <PageTitle title="Game Pass Mechanics" />
          <HiddenHeaderAndFooter 
            hideFooter
            hideHeader
            hideMenuIcon
          />
          <Section 
            sectionClassName="game-pass-mechanics-section"
            sectionHeaderClassName="game-pass-mechanics-header"
            title={title}
            titleCentered
            backButtonRoute={!mobile}
            backRoute={backToPreviousPage}
          >
            <div className="game-pass-mechanics-content">
              <GamePassFAQs {...gamePassMechanics.wenLambo.faqs} mobile />
              <GamePassMechanicsPass {...gamePassMechanics.gamePass} />
              <GamePassMechanicsPass {...gamePassMechanics.goldBooster} />
              <MechanicsCarousel {...gamePassMechanics.gamePass.mechanics} />
              <GamePassFAQs {...gamePassMechanics.gamePass.faqs} />
              <GamePassFAQs {...gamePassMechanics.goldBooster.faqs} />
              <GamePassTermsSection {...gamePassMechanics.generalTermsAndConditions} />
            </div>
          </Section>
        </LayoutContent>}
    </>
  );
}