import {
  mgcCurrency,
  goldCurrency,
  goldDefaultCredits
} from '../constants';

export const getWalletCredits = (crypto) => {
  let walletCredits = null;
  if (crypto && crypto.length) {
    const walletCreditsObject = crypto.find(c => c.name === mgcCurrency);
    if (walletCreditsObject) {
      walletCredits = {
        name: walletCreditsObject.name,
        val: walletCreditsObject.val
      };
    }
  }
  return walletCredits;
}

export const getGoldCoinsValue = (crypto) => {
  let goldCoinsValue = goldDefaultCredits;
  if (crypto && crypto.length) {
    const goldCoinsObject = crypto.find(c => c.name === goldCurrency);
    if (goldCoinsObject) {
      goldCoinsValue = goldCoinsObject.val;
    }
  }
  return goldCoinsValue;
}