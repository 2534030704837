import { RESET_STATE, SET_NFT_COLLECTION_DETAILS, SET_NFT_ITEM_DETAILS } from '../actions/constants';

const nftState = {
  selected: null,
  selectedItem: {}
};

const nftReducer = (state = nftState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return nftState;
    case SET_NFT_COLLECTION_DETAILS:
      return {
        ...state,
        selected: action.nftCollectionDetails
      };
    case SET_NFT_ITEM_DETAILS:
      return {
        ...state,
        selectedItem: action.nftItemDetails
      };
    default:
      return state;
  }
}

export default nftReducer;