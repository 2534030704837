import React from 'react';
import classNames from 'classnames';
// import { DateTime } from 'luxon';
// import { useCountdown } from '../../../hooks/useCountdown';
import participantsIcon from '../../../assets/tournaments/participants-icon.png';
// import clockIcon from '../../../assets/tournaments/clock-icon.png';

export const GridStats = ({ stats, isInDetails, className }) => {
  const participants = stats.find(s => s.label === 'Participants');
  const participantsLabel = isInDetails && ' Participants';

  /* const currentDateValue = DateTime.now();
  const formatTime = (time) => time < 10 ? `0${time}` : time;

  const startDate = stats.find(s => s.label === 'Start Date');
  const startDateValue = DateTime.fromISO(startDate.value);
  const startDateCountdown = useCountdown(startDate.value);
  const startDateDuration = startDateCountdown.reduce((a, b) => a + b);
  const startFmtDays = startDateCountdown[0] > 0 ? `${startDateCountdown[0]}d ` : '';
  const startFmtHrs = formatTime(startDateCountdown[1]);
  const startFmtMins = formatTime(startDateCountdown[2]);
  const startFmtSecs = formatTime(startDateCountdown[3]);
  const startFmtDateTimeLabel = isInDetails ? 'Starts in ' : '';
  const startFmtDateTime = `${startFmtDateTimeLabel}${startFmtDays}${startFmtHrs}:${startFmtMins}:${startFmtSecs}`;

  const endDate = stats.find(s => s.label === 'Start Date');
  const endDateValue = DateTime.fromISO(endDate.value);
  const endDateCountdown = useCountdown(endDate.value);
  const endDateDuration = endDateCountdown.reduce((a, b) => a + b);
  const endFmtDays = endDateCountdown[0] > 0 ? `${endDateCountdown[0]}d ` : '';
  const endFmtHrs = formatTime(endDateCountdown[1]);
  const endFmtMins = formatTime(endDateCountdown[2]);
  const endFmtSecs = formatTime(endDateCountdown[3]);
  const endFmtDateTimeLabel = isInDetails ? 'Ends in ' : '';
  const endFmtDateTime = `${endFmtDateTimeLabel}${endFmtDays}${endFmtHrs}:${endFmtMins}:${endFmtSecs}`;

  const isBeforeStartDate = currentDateValue < startDateValue && startDateDuration > 0;
  const isBeforeEndDate = currentDateValue >= startDateValue && currentDateValue < endDateValue && endDateDuration > 0; */

  return (
    <>
      {participants && participants.value &&
        <div className={classNames(`${className}-prizes-stats-item`, `${className}-stats`)}>
        {participants && participants.value &&
          <span className={`${className}-stats-participants`}>
            <img
              src={participantsIcon}
              className={`${className}-stats-participants-icon`}
              alt="Players"
            />
            {participants.value}{participantsLabel}
          </span>}
        {/* isBeforeStartDate &&
          <span className={`${className}-stats-duration`}>
            <img
              src={clockIcon}
              className={`${className}-stats-duration-icon`}
              alt="Duration"
            />
            {startFmtDateTime}
          </span> */}
        {/* isBeforeEndDate &&
          <span className={`${className}-stats-duration`}>
            <img
              src={clockIcon}
              className={`${className}-stats-duration-icon`}
              alt="Duration"
            />
            {endFmtDateTime}
          </span> */}
      </div>}
    </>
  );
}