import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { Grid } from '@mui/material';
import { MarketplaceListedItem } from './MarketplaceListedItem';
import { LayoutContent, PageTitle, Section } from '../Components';
import { Skeletons } from '../Commons';
import {
  clearListedNfts,
  setHideFooter,
  setHideHeader
} from '../../actions';
import {
  marketplaceDetailsSelector,
  nftCollectionSelector
} from '../../selectors';
import { range, minWidths } from '../../constants';
import './Marketplace.scss';

export const Marketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDropDown, setOpenDropdown] = useState(false);
  const [stats, setStats] = useState([]);

  const nftCollection = useSelector(state => nftCollectionSelector(state));
  const nftDetails = useSelector(state => marketplaceDetailsSelector(state));

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  useEffect(() => {
    const nftArr = [];
    const nftStats = ['chain', 'size', 'owned'];
    Object.keys(nftCollection).forEach((key) => {
      if (nftStats.includes(key)){
        nftArr.push({
          text: key,
          value: nftCollection[key]
        });
      }
    })
    setStats(nftArr);
  }, [nftCollection])

  const handleBackRoute = () => {
    navigate(-1);
  }

  useEffect(() => {
    if (!isDesktop) {
      dispatch(setHideFooter(true));
      dispatch(setHideHeader(true));
    }

    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideHeader(false));
    }
  }, [dispatch, isDesktop])

  useEffect(() => {
    return () => {
      dispatch(clearListedNfts([]));
    }
  }, [dispatch])

  return (
    <LayoutContent outerClassName="marketplace-collection" innerClassName="marketplace-collection-inner">
      <PageTitle title="Collection" />
      <div className="marketplace-collection-bg" />
      <Section sectionClassName="marketplace-collection-section" text="Back" backButtonRoute backRoute={handleBackRoute}>
        <div className="marketplace-collection-main">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="marketplace-collection-image-container">
                {nftCollection.imageUrl ?
                  <img
                    className="marketplace-collection-image"
                    src={nftCollection.imageUrl}
                    alt="nft"
                  /> :
                  <Skeletons 
                    largeImage={isDesktop} 
                    mobile={!isDesktop} 
                    width={!isDesktop ? 115 : ''} 
                    height={!isDesktop ? 115 : ''} 
                    image
                  />}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} className="marketplace-nft-contents">
              <div className="marketplace-nft-info">
                <div className="marketplace-nft-title">{nftCollection.name}</div>
                <div className="marketplace-nft-description py-2 w-100">{nftCollection.description || <Skeletons desc />}</div>
                <div className="marketplace-nft-stats-wrapper">
                  <Grid container spacing={2} py={2}>
                    {isEmpty(stats) && range(4).map(m => (
                      <Grid key={`Stats_${m}`} item xs={6} sm={6} md={6} lg={3}>
                        <Skeletons show width="100%" height={80} />
                      </Grid>
                    ))}
                    {stats.map((s, sIdx) => (
                      <Grid item xs={6} sm={6} md={6} lg={3} key={sIdx} className="marketplace-nft-stats-box">
                        <div className={`marketplace-nft-stats`}>
                          <div className="marketplace-nft-stats-value">{s.value}</div>
                          <div className="marketplace-nft-stats-text">{capitalize(s.text)}</div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <div className={classNames({
                  "marketplace-nft-collection": openDropDown,
                  "marketplace-nft-collection-hide": !openDropDown
                })}>
                  <div 
                    onClick={() => setOpenDropdown(!openDropDown)}
                    role="button"
                    className={classNames(
                      "marketplace-nft-collection-wrapper", 
                      {"marketplace-nft-collection-wrapper-hide": !openDropDown && isDesktop}
                    )}
                  >
                    <div className="marketplace-nft-about-wrapper">
                      <div className="marketplace-nft-about"/>
                      <div className="marketplace-nft-about-title">About the Collection</div>
                    </div>
                    <div>
                    {openDropDown ?
                      <KeyboardArrowUpRoundedIcon role="button" /> :
                      <KeyboardArrowDownRoundedIcon role="button" />}
                    </div>
                  </div>
                  {openDropDown && 
                    !isEmpty(nftCollection.section) && 
                    <div
                      className="marketplace-nft-about-body"
                      dangerouslySetInnerHTML={{ __html: nftCollection.section[0].body }}
                    />}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <MarketplaceListedItem 
          nftCollection={nftCollection}
          nftData={nftDetails} 
          nftName={nftCollection.name}/>
      </Section>
    </LayoutContent>
  ) 
}