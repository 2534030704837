import React from 'react';
import classNames from 'classnames';
import coin from '../../../assets/gamepass/coin.png';
import './GoldCoinsHeader.scss';

const GoldCoinsHeader = ({ value, className }) => (
  <div className={classNames("gold-coins-header", className)}>
    <img 
      src={coin} 
      className="gold-coins-header-icon"
      alt="coin" 
    /> 
    {value}
  </div>
);

export default GoldCoinsHeader;