export const maxWidths = {
  desktopXXL: 1599,
  desktopXL: 1440,
  desktopLG: 1199,
  desktopSM: 1023,
  tablet: 991,
  mobileSM: 767,
  mobileXS: 575,
  mobileXXS: 376
};
export const minWidths = {
  desktopXXXL: 1600,
  desktopXXL: 1441,
  desktopXL: 1200,
  desktopMD: 1024,
  desktopSM: 992,
  tablet: 768,
  mobileSM: 576
};