import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  failedRequests,
  setLoading, 
  setShowLoading, 
  setWithdrawalNFT, 
  setWithdrawalNFTValidate, 
  setWithdrawNft 
} from '../actions';
import { 
  GET_WITHDRAW_NFT, 
  GET_WITHDRAWAL_NFT, 
  GET_WITHDRAWAL_NFT_VALIDATE 
} from '../actions/constants';
import {
  requestWithdraw,
  requestWithdrawalNFT,
  requestWithdrawalValidate
} from '../api/withdrawal';
import { storageKeys } from '../constants';

const getWithdrawalNFTSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWithdrawalNFT, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setWithdrawalNFT(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getWithdrawalNFTValidateSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWithdrawalValidate, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setWithdrawalNFTValidate(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getWithdrawSaga = function* (payload) {
  yield put(setShowLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWithdraw, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setWithdrawNft(response.data.d));
      window.location.href = `/wallet/${payload.payload.nftId}/withdrawal/pending?referenceId=${response.data.d.referenceId}`;
    }
  } catch (e) {
    yield put(setShowLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* withdrawalSaga() {
  yield all([
    takeLatest(GET_WITHDRAWAL_NFT, getWithdrawalNFTSaga),
    takeLatest(GET_WITHDRAWAL_NFT_VALIDATE, getWithdrawalNFTValidateSaga),
    takeLatest(GET_WITHDRAW_NFT, getWithdrawSaga)
  ]);
}