import { connect } from 'react-redux';
import { SpendResult } from '../components';

const mapStateToProps = (state) => ({
     
});

const mapDispatchToProps = (dispatch) => ({
     
});

const SpendResultContainer = connect(mapStateToProps, mapDispatchToProps)(SpendResult);

export default SpendResultContainer