import { connect } from 'react-redux';
import { getMoreTransactions, getUserTransactions, setBarcodeVisible, setLoading, setSelectedMethod, setTransactionVisible } from '../actions';
import { transactionBarcodeSelector, transactionSelectedMethodSelector, userTransactionsSelector } from '../selectors';
import { Transactions } from '../components';

const mapStateToProps = (state) => ({
  transactions: userTransactionsSelector(state),
  isBarcodeVisible: transactionBarcodeSelector(state),
  selectedMethod: transactionSelectedMethodSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getTransactions: () => dispatch(getUserTransactions()),
  getMoreTransactions: (page) => dispatch(getMoreTransactions(page)),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setBarcodeVisible: (isBarcodeVisible) => dispatch(setBarcodeVisible(isBarcodeVisible)),
  setSelectedMethod: (selectedMethod) => dispatch(setSelectedMethod(selectedMethod)),
  setTransactionVisible: (isTransactionVisible) => dispatch(setTransactionVisible(isTransactionVisible))
});

const TransactionsContainer = connect(mapStateToProps, mapDispatchToProps)(Transactions);

export default TransactionsContainer;