import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  requestClaimItemViaVoucher, 
  requestRewardOTP, 
  requestUtilizeClaimedReward 
} from '../api/vouchers';
import { 
  CLAIM_ITEM_VIA_VOUCHER, 
  GET_REWARD_OTP, 
  UTILIZE_CLAIMED_REWARD 
} from '../actions/constants';
import {
  failedRequests,
  loginData,
  setDisplayToast,
  setItemRedemptionError,
  setLoading,
  showRewardOTPForm,
  startLoginOTPTimer
} from '../actions';
import {
  responseStatus,
  storageKeys
} from '../constants';
import { getRewardsSaga } from './rewards';

const claimItemViaVoucherSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const data = { voucherCode: payload.voucherCode };
    const response = yield call(requestClaimItemViaVoucher, data, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield call(getRewardsSaga);
      const toast = {
        result: true,
        title: 'Success',
        message: response.data.message
      };
      yield put(setDisplayToast(toast));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      yield put(setItemRedemptionError(e.response.data.message));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const utilizeClaimedRewardSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const data = { rewardId: payload.rewardId };
    const response = yield call(requestUtilizeClaimedReward, data, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield call(getRewardsSaga);
      window.location.href = payload.destinationUrl;
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getRewardOTPSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const { mobnum } = payload?.payload || {};
    const response = yield call(requestRewardOTP, payload?.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(loginData({ mobnum }));
      yield put(showRewardOTPForm(true));
      yield put(startLoginOTPTimer(true));
    }
    yield put(setLoading(false));
  } catch (e) {
    if (e.response && e.response.data) {
      yield put(setLoading(false));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* vouchersSaga() {
  yield all([
    takeLatest(CLAIM_ITEM_VIA_VOUCHER, claimItemViaVoucherSaga),
    takeLatest(UTILIZE_CLAIMED_REWARD, utilizeClaimedRewardSaga),
    takeLatest(GET_REWARD_OTP, getRewardOTPSaga)
  ]);
}