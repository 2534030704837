import React, { useEffect, useRef } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Phone } from '../Commons';
import { setErrorMessage } from '../../actions';
import {
  firebaseEvents,
  formInputKinds,
  registrationStepQuery,
  siteRoutes,
  storageKeys
} from '../../constants';
import { loginErrorMessageSelector } from '../../selectors';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';

export const LoginForm = ({
  transform,
  formInputs,
  invalidInputs,
  formData,
  countryCode,
  registrationFormVisible,
  texts,
  displayRegistrationForm,
  consentCheckboxChecked,
  setCountryCode,
  handleInput,
  handleLogin,
  checkConsentCheckbox,
  setEmailParams,
  setSlugParams,
  authStep
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneRef = useRef(null);
  const search = useLocation().search;

  const loginErrorMessage = useSelector(state => loginErrorMessageSelector(state));
  const [searchParams, setSearchParams] = useSearchParams();

  const params = new URLSearchParams(search);
  const isRegistrationVisible = params.get('step');
  const email = params.get('email');
  const slug = params.get('slug');
  const voucherCode = params.get('code');
  const isTutorial = params.get(storageKeys.isTutorial);

  const registerButtonDisabled = !consentCheckboxChecked && isRegistrationVisible;
  const newInvalidInputs = invalidInputs.filter(i => i !== 'earlyAccessCode');
  const buttonText = isRegistrationVisible ? 'Register' : 'Login';

  useEffect(() => {
    if (registrationFormVisible) {
      let searchParams = `?${registrationStepQuery}`;
      navigate({ 
        pathname: siteRoutes.login, 
        search: searchParams
      });
    }
  }, [registrationFormVisible, navigate])

  useEffect(() => {
    if (isTutorial) {
      localStorage.setItem(storageKeys.isTutorial, isTutorial);
      searchParams.delete(storageKeys.isTutorial);
      setSearchParams(searchParams, { replace: true });
      displayRegistrationForm(true);
    };
  }, [isTutorial, searchParams, setSearchParams, navigate, displayRegistrationForm])

  useEffect(() => {
    if (email) {
      setEmailParams({ email: email, voucherCode: voucherCode });
      if (searchParams.has('email')) {
        searchParams.delete('email');
        searchParams.delete('code');
        setSearchParams(searchParams);
        displayRegistrationForm(true);
      }
    }
    if (slug) {
      setSlugParams({ slug: slug });
      if (searchParams.has('slug')) {
        searchParams.delete('slug');
        setSearchParams(searchParams);
      }
    }
  }, [email, voucherCode, searchParams, setSearchParams, setEmailParams, slug, setSlugParams, displayRegistrationForm])
  
  const signUp = () => { 
    phoneRef.current.focus(); 
    displayRegistrationForm(true);
    dispatch(setErrorMessage({}));
    logFirebaseEventWithTimestamp(firebaseEvents.signUpClick);
  };

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      handleLogin(e, registerButtonDisabled);
    }
  };

  const handleNavigate = () => {
    if (registrationFormVisible) {
      displayRegistrationForm(false);
      checkConsentCheckbox(false);
    }
    window.history.back();
  }

  const handleSignIn = () => {
    if (registrationFormVisible) {
      displayRegistrationForm(false);
      checkConsentCheckbox(false);
    }
    navigate({ 
      pathname: siteRoutes.login, 
      replace: true 
    });
  }

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.keyCode === 9 && authStep === 2) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [authStep])

  const handleKeyDown = (e, idx) => {
    const isLastInputField = formInputs.length === idx + 1;
    if ((e.keyCode === 9) || (e.keyCode === 9 && !registrationFormVisible) || (e.keyCode === 9 && isLastInputField)) {
      e.preventDefault();
    }
  };

  return (
    <div className="login-content" style={transform}>
      <div className="login-otp-header">
        <div className="back" onClick={handleNavigate}><ArrowBackIosNewRoundedIcon /></div>
      </div>
      <div className="login-form">
        <div className={classNames(
          "login-logo",
          {"mb-0": loginErrorMessage.message}
        )} />
        {loginErrorMessage.message && <div className="login-form-box">
          <div className="login-form-error-icon"/>
          <div className="login-form-error">{loginErrorMessage.message}</div>
        </div>}
        <form>
          {formInputs.map((i, idx) => {
            if (i.type !== formInputKinds.phone && !isRegistrationVisible) return null;
            return (
              <div className="login-form-row" key={`FormData_${i.name}`}>
                <span className="login-form-label">{i.label}</span>
                {
                  i.type === formInputKinds.kinds ?
                    <Phone
                      id={i.name}
                      type="text"
                      onChange={(e) => handleInput(e, i.name)}
                      countryCode={countryCode}
                      value={formData[i.name]}
                      setCountryCode={setCountryCode}
                      className={classNames(
                        "login-form-group",
                        {"login-form-group-error": invalidInputs.includes(i.name)}
                      )}
                      dropdownMenuClassName="login-form-dropdown-menu"
                      phoneRef={phoneRef}
                      hasFilter
                      onKeyPress={handleKeypress}
                      onKeyDown={(e) => handleKeyDown(e, idx)}
                    /> :
                    <input
                      type={i.type} 
                      className={classNames(
                        "login-form-input",
                        {"login-form-input-error": newInvalidInputs.includes(i.name)}
                      )}
                      onChange={(e) => handleInput(e, i.name)}
                      value={formData[i.name]}
                      onKeyPress={handleKeypress}
                      onKeyDown={(e) => handleKeyDown(e, idx)}
                    />
                }
                {i.type === formInputKinds.phone &&
                  !isRegistrationVisible &&
                  !isEmpty(invalidInputs) &&
                  <span className={classNames("login-form-label", "login-form-label-error")}>{texts.loginError}</span>}
                {idx === formInputs.length - 1 &&
                  isRegistrationVisible &&
                  !isEmpty(invalidInputs) &&
                  <span className={classNames("login-form-label", "login-form-label-error")}>{texts.signupError}</span>}
              </div>
            );
          })}
          {isRegistrationVisible &&
            <label className="login-consent">
              <div className="login-consent-label" dangerouslySetInnerHTML={{ __html: texts.consent }} />
              <input type="checkbox" value="Consent" onClick={() => checkConsentCheckbox(!consentCheckboxChecked)} />
              <span className="checkbox"></span>
            </label>}
          {isRegistrationVisible ?
            <div className={classNames("login-signup", "login-signin")}>
              Already have an account?&nbsp;
              <Link to="#" onClick={handleSignIn} className="login-signup-link">Sign in</Link>
            </div> :
            <div className="login-signup">
              Don't have an account?&nbsp;
              <div className="login-signup-link" onClick={signUp}>Sign up now!</div>
            </div>}
          <button 
            type="submit"
            className={classNames(
              "login-submit",
              {"login-submit-disabled": registerButtonDisabled}
            )} 
            onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}
            onClick={(e) => handleLogin(e, registerButtonDisabled)}>{buttonText}</button>
        </form>
      </div>
    </div>
  );
}