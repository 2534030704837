import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Tabs, { Tab } from 'react-best-tabs';
import { 
  BuyRewardModal, 
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { Toast } from '../Commons';
import { Rewards } from './Rewards';
import { VoucherInput } from './VoucherInput';
import { SideTabs } from './SideTabs';
import { BuyWithGold } from './BuyWithGold';
import {
  getRewards,
  setLoading,
  claimItemViaVoucher,
  setItemRedemptionError,
  setDisplayToast,
  getBuyRewards,
  confirmBuyReward,
  confirmBuyRewardSuccess
} from '../../actions';
import {
  confirmBuyRewardSuccessSelector,
  getBuyRewardsSelector,
  itemRedemptionErrorSelector,
  rewardsListSelector,
  vouchersToastSelector,
  walletCryptoSelector
} from '../../selectors';
import {
  buyRewardTitles,
  defaultLocaleString,
  defaultTimeoutMS,
  maxWidths,
  minWidths,
  rewardCenterSideTabsKind,
  rewardStatuses,
  rewardsCenterParams,
  rewardsCenterTexts
} from '../../constants';
import {
  rewardsCenterBannerTablet,
  rewardsCenterBannerDesktop,
  rewardsCenterBannerMobile
}  from './images';
import { getGoldCoinsValue } from '../../utils/getWalletCredits';
import 'react-best-tabs/dist/index.css';
import './RewardsCenter.scss';

export const RewardsCenter = () => {
  const [selectedSideTab, setSelectedSideTab] = useState(rewardCenterSideTabsKind.reward);
  const [selectedReward, setSelectedReward] = useState({});
  const [openBuyConfirmation, setOpenBuyConfirmation] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const tabletDisplay = useMediaQuery({ maxWidth: maxWidths.tablet });
  const mobileDisplay = useMediaQuery({ maxWidth: maxWidths.mobileXS });
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const handleBanner = () => {
    if (mobileDisplay) return rewardsCenterBannerMobile;
    if (tabletDisplay) return rewardsCenterBannerTablet;
    return rewardsCenterBannerDesktop;
  }

  const rewards = useSelector(state => rewardsListSelector(state));
  const toast = useSelector(state => vouchersToastSelector(state));
  const errorMessage = useSelector(state => itemRedemptionErrorSelector(state));
  const buyRewards = useSelector(state => getBuyRewardsSelector(state));
  const buySuccess = useSelector(state => confirmBuyRewardSuccessSelector(state));
  const crypto = useSelector(state => walletCryptoSelector(state));
  const goldBalance = getGoldCoinsValue(crypto);
  const hasError = errorMessage !== '';
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [voucherCode, setVoucherCode] = useState('');
  const [toastOpen, openToast] = useState(false);

  const backToPreviousPage = () => {
    navigate(-1);
  }

  useEffect(() => {
    // tab selection via query params
    if (searchParams.has(rewardsCenterParams.tab)) {
      const selectedTab = searchParams.get(rewardsCenterParams.tab);
      if (selectedTab === rewardsCenterParams.buyReward) {
        setSelectedSideTab(rewardCenterSideTabsKind.buyWithGold);
        searchParams.delete(rewardsCenterParams.tab);
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getRewards());
    dispatch(getBuyRewards());
  }, [dispatch]);

  useEffect(() => {
    if (toast) openToast(true);
  }, [toast]);

  let activeRewards = [];
  let usedRewards = [];
  let expiredRewards = [];
  if (rewards && !isEmpty(rewards)) {
    activeRewards = rewards.filter(r => r.status === rewardStatuses.active);
    usedRewards = rewards.filter(r => r.status === rewardStatuses.used);
    expiredRewards = rewards.filter(r => r.status === rewardStatuses.expired);
  }

  const handleVoucherCode = (e) => {
    setVoucherCode(e.target.value);
    if (hasError) {
      dispatch(setItemRedemptionError(''));
    }
  }

  const claimItem = () => {
    dispatch(setLoading(true));
    dispatch(claimItemViaVoucher(voucherCode));
  }

  const closeToast = () => {
    openToast(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const hideBuyConfirmation = () => {
    setOpenBuyConfirmation(false);
  }

  const handleConfirm = () => {
    dispatch(setLoading(true));
    dispatch(
      confirmBuyReward({ 
        productKind: selectedProduct?.kind,
        skuKind: selectedReward?.kind
      })
    );
  }

  const handleGoToRewards = () => {
    setSelectedSideTab(rewardCenterSideTabsKind.reward);
    dispatch(confirmBuyRewardSuccess({}));
  }

  const confirmTitle = () => {
    const name = selectedReward?.shortName;
    const value = selectedReward?.defaultPrice?.value.toLocaleString(defaultLocaleString);
    const title = buyRewardTitles(name, value).confirmation;
    return title; 
  }

  const successTitle = () => {
    const title = buyRewardTitles().success;
    const itemName = selectedReward?.shortName;
    return {
      title, 
      itemName
    };
  }
  
  return (
    <LayoutContent outerClassName="rewards-center-container" innerClassName="rewards-center-inner">
      <PageTitle title="Reward Center" />
      <Section
        sectionClassName="rewards-center-section"
        sectionHeaderClassName="rewards-center-section-header"
        backButtonRoute
        backRoute={backToPreviousPage}
      >
        <img className="rewards-center-banner" src={handleBanner()} alt="banner"/>
        <VoucherInput
          voucherCode={voucherCode}
          handleVoucherCode={handleVoucherCode}
          claimItem={claimItem}
          error={errorMessage}
        />
        <div className="rewards-center-buy">
          <SideTabs 
            isDesktop={isDesktop}
            setSelectedSideTab={setSelectedSideTab} 
            selectedSideTab={selectedSideTab} 
          />
          {selectedSideTab === rewardCenterSideTabsKind.buyWithGold ?
            <BuyWithGold 
              items={buyRewards} 
              setSelectedReward={setSelectedReward} 
              setSelectedProduct={setSelectedProduct}
              setOpenBuyConfirmation={setOpenBuyConfirmation}
              goldBalance={Number(goldBalance)}
            /> :
            <Tabs
              activeTab="1"
              className="rewards-center-tabs"
              ulClassName="rewards-center-tabs-lists"
              activityClassName="rewards-center-tabs-indicator"
            >
              <Tab title="All" className="rewards-center-tab-title">
                <Rewards rewards={rewards} title="All" />
              </Tab>
              <Tab title="Active" className="rewards-center-tab-title">
                <Rewards rewards={activeRewards} title="Active" />
              </Tab>
              <Tab title="Used" className="rewards-center-tab-title">
                <Rewards rewards={usedRewards} title="Used" />
              </Tab>
              <Tab title="Expired" className="rewards-center-tab-title">
                <Rewards rewards={expiredRewards} title="Expired" />
              </Tab>
            </Tabs>}
        </div>
      </Section>
      <BuyRewardModal 
        open={openBuyConfirmation} 
        hide={hideBuyConfirmation}
        selectedReward={selectedReward}
        confirm={handleConfirm}
        imageUrl={selectedReward?.imageUrls?.bannerUrl}
        title={confirmTitle()}
      />
      <BuyRewardModal 
        open={!isEmpty(buySuccess)} 
        hide={() => dispatch(confirmBuyRewardSuccess({}))}
        title={successTitle()?.title}
        itemName={successTitle()?.itemName}
        description={rewardsCenterTexts.goToRewards}
        handleGoToRewards={handleGoToRewards}
        isSuccess
      />
      {toast &&
        <Toast
          toast={toast}
          open={toastOpen}
          hide={closeToast}
        />}
    </LayoutContent>
  );
}