import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  useAccount, 
  useDisconnect, 
  useNetwork, 
  useSignMessage 
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import Cookies from 'js-cookie';
import { 
  connectFromTopUp,
  getWalletSignMessage, 
  setLoading, 
  setUserWalletAddress, 
  setWalletSignMessage, 
  userWalletConnected, 
  verifyWalletSignMessage, 
  verifyingWalletSignMessage 
} from '../actions';
import { connectFromTopUpSelector, verifyingWalletSignMessageSelector } from '../selectors';
import { 
  duration, 
  preservedKeys, 
  storageKeys, 
  types 
} from '../constants';
import { clearLocalStorageExcept } from '../utils/clearLocalStorageExcept';

export const useWalletConnect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { disconnectAsync, isLoading } = useDisconnect({
    onSettled() {
      dispatch(setLoading(false));
    },
    onSuccess() {
      dispatch(setLoading(false));
    }
  });
  const { open, isOpen } = useWeb3Modal();
  const { chain } = useNetwork();
  
  const [signature, setSignature] = useState('');
  const [newAddress, setNewAddress] = useState('');
  
  const isVerifying = useSelector(state => verifyingWalletSignMessageSelector(state));
  const isFromTopUp = useSelector(state => connectFromTopUpSelector(state));

  const disconnectWallet = useCallback(async() => {
    const disconnect = await disconnectAsync();
    setTimeout(() => {
      clearLocalStorageExcept(preservedKeys);
    }, duration.oneSecond)
    return disconnect;
  }, [disconnectAsync])

  const { data, isLoading: isSigning, isSuccess, signMessageAsync } = useSignMessage({
    onError() {
      disconnectWallet();
      dispatch(setWalletSignMessage(''));
      dispatch(verifyingWalletSignMessage(false));
    }
  });
  const signMessage = async(message) => {
    const signature = await signMessageAsync({ message });
    return signature;
  };
  const { address, isConnected, connector } = useAccount({
    onConnect({ isReconnected }) {
      if (!isReconnected && !shouldDisconnect) {
        dispatch(getWalletSignMessage({ disconnect: disconnectWallet, signMessage }));
      }
    },
  });
  const shouldDisconnect = Cookies.get(storageKeys.disconnectWallet);

  useEffect(() => {
    if (address && newAddress && address !== newAddress) {
      dispatch(setLoading(true));
      disconnectWallet();
    }
    setNewAddress(address);
  }, [dispatch, address, newAddress, disconnectWallet])

  useEffect(() => {
    if (isSuccess || isOpen) {
      Cookies.remove(storageKeys.disconnectWallet);
    }
  }, [isSuccess, isOpen])

  useEffect(() => {
    if (isSuccess) {
       if (isFromTopUp) {
        navigate(isFromTopUp, { replace: true });
        dispatch(connectFromTopUp(''));
        dispatch(verifyingWalletSignMessage(false));
      }
    }
  }, [dispatch, isSuccess, isFromTopUp])

  useEffect(() => {
    dispatch(userWalletConnected(isConnected));
    dispatch(setUserWalletAddress(address));
  }, [dispatch, isConnected, address])
  
  useEffect(() => {
    if (data) {
      setSignature(data);
      Cookies.remove(storageKeys.disconnectWallet);
    }
  }, [data])

  useEffect(() => {
    if (signature && address && chain) {
      dispatch(
        verifyWalletSignMessage({ 
          disconnect: disconnectWallet, 
          signature, 
          address, 
          chainId: chain?.id, 
          type: types.connect
        })
      );
    }
    setSignature('');
  }, [signature, address, chain, dispatch, disconnectWallet])

  useEffect(() => {
    if (!isConnected) {
      setSignature('');
    }
  }, [isConnected])

  useEffect(() => {
    const loading = isSigning || isLoading || isVerifying;
    dispatch(setLoading(loading));
    return () => {
      dispatch(setLoading(false));
    }
  }, [dispatch, isLoading, isSigning, isVerifying])

  useEffect(() => {
    if (shouldDisconnect && !isVerifying && !isSigning) {
      disconnectWallet();
    }
  }, [dispatch, shouldDisconnect, isVerifying, isSigning, disconnectWallet])

  useEffect(() => {
    const handleUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    const handleBeforeUnload = () => {
      if (isSigning || isVerifying) {
        Cookies.set(storageKeys.disconnectWallet, true);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [isSigning, isVerifying]);

  return {
    address,
    isConnected,
    connector,
    open,
    isSigning,
    isVerifying,
    disconnectWallet,
    isSuccess,
    shouldDisconnect,
    chain
  }
};