import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Modal } from 'react-bootstrap';
import QrScanner from 'qr-scanner';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { setDisplayToast } from '../../../actions';
import { minWidths, texts } from '../../../constants';
import './QRScanner.scss';

export const QRScanner = ({ setOpenCamera, setResult, result }) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const videoRef = useRef(null);
  const inputFile = useRef(null); 

  useEffect(() => {
    if (!videoRef.current) return;
    const scanner = new QrScanner(videoRef.current, result => setResult(result), {
      onDecodeError: error => {
         return null;
      },
      returnDetailedScanResult: true,
      highlightScanRegion: true,
      highlightCodeOutline: true
    });
    scanner.start();

    return () => {
      scanner.stop();
    }
  }, [videoRef.current])

  useEffect(() => {
    if (result.data && result.data.length > 0) {
      setOpenCamera(false);
    }
  }, [result])

  const handleInputFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
        return;
    }

    QrScanner.scanImage(file, { returnDetailedScanResult: true })
      .then(result => setResult(result))
      .catch(e => dispatch(setDisplayToast({
        result: false,
        message: 'No QR code found.'
      })));
  }

  return (
    <Modal
      backdrop
      show={true}
      className="qr-reader-container"
      contentClassName="qr-reader"
      dialogClassName="qr-reader-dialog"
      backdropClassName="qr-reader-bg"
      onHide={() => setOpenCamera(false)}
    >
      <Modal.Header 
        closeButton={isDesktop} 
        closeVariant="white" 
        className="qr-reader-header"
      >
        <div className="position-relative w-100">
          <div className="qr-reader-title">{texts.qrScan}</div>
          {!isDesktop &&
            <KeyboardArrowLeftRoundedIcon 
              className="qr-reader-arrow" 
              onClick={() => setOpenCamera(false)}
            />}
        </div>
      </Modal.Header>
      <Modal.Body className="qr-reader-body">
        <input 
          onChange={handleInputFile}
          ref={inputFile} 
          type="file" 
          className="qr-reader-input" 
        />
        <video ref={videoRef} className="qr-reader-video" />
        <div className="qr-reader-button-wrapper">
          <button 
            onClick={() => inputFile.current.click()} 
            className="qr-reader-button"
          >
            {texts.uploadQR}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
