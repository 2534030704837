import React, { Component } from 'react';
import camelCase from 'lodash/camelCase';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { LayoutContent, PageTitle, RatingPlatforms } from '../../Components';
import { Tags } from '../../Commons';
import Section from '../Section/Section';
import { defaultTimeoutMS, maxWidths, minWidths } from '../../../constants';
    
const maxLength = 8;
const listContainerHeight = 342;

class ListContainer extends Component {
  constructor(props) {
    super(props);

    const loadedAllItems = props.data.d.length <= maxLength;

    this.state = {
      items: loadedAllItems ? props.data.d : props.data.d.slice(0, maxLength),
      scrollTop: 0,
      loading: !loadedAllItems
    };
  }

  loadMoreItems = () => {
    const { data } = this.props;
    const { items } = this.state;
    const addedItems = data.d.slice(items.length, items.length + maxLength);

    if (data.d.length > items.length) {
      this.setState({ items: items.concat(addedItems) });
    } else {
      this.setState({ loading: false });
    }
  }

  lazyLoad = () => {
    const list = document.getElementById('list-container');
    const listScrollHeight = (list.clientHeight - list.scrollTop) + listContainerHeight;
    const innerHeight = window.innerHeight - listContainerHeight;

    if (listScrollHeight <= innerHeight) {
      setTimeout(this.loadMoreItems, defaultTimeoutMS);
    }
  }

  backRoute = (e) => {
    e.preventDefault();
    window.history.back();
  }

  render() {
    const { data, keyPrefix, className } = this.props;
    const { items, loading } = this.state;

    return (
      <LayoutContent onScroll={this.lazyLoad} id="list-container" outerClassName="scroll-container">
        <PageTitle title={data.dataset} />
        <Section
          title={data.dataset}
          sectionClassName={`${className}-section`}
          backButtonRoute
          backRoute={this.backRoute}
          titleCentered
        >
          <div className={`${className}-mobile`}>
            {items.map(i => {
              const experience = `${i.experience}s`;
              const name = i.slug || camelCase(i.name).toLowerCase();
              const link = `/${experience}/${name}`;
              const firstTwoTags = i.tags.slice(0, 2);
              const firstTwoTagsLengths = firstTwoTags.map(t => t.length);
              const firstTwoTagsTotalLength = firstTwoTagsLengths.reduce((a, b) => a + b);
              const maxTags = firstTwoTagsTotalLength > 20 ? 1 : 2;
              return (
                <div key={`${keyPrefix}_${i.id}`} className={`${className}-mobile-item`}>
                  <div className={`${className}-mobile-item-wrapper`}>
                    <Link to={link} className={`${className}-mobile-image-wrapper`}>
                      <img src={i.image_url} alt="item-icon" className={`${className}-mobile-image`}></img>
                    </Link>
                    <div className={`${className}-mobile-details`}>
                      <MediaQuery minWidth={minWidths.mobileSM}>
                        <Tags name={i.name} tags={i.tags} maxTags={2} />
                      </MediaQuery>
                      <MediaQuery maxWidth={maxWidths.mobileXS}>
                        <Tags name={i.name} tags={i.tags} maxTags={maxTags} />
                      </MediaQuery>
                      <Link to={link} className={`${className}-mobile-details-name`}>{i.name}</Link>
                      {i.platforms && i.platforms.length > 0 && <RatingPlatforms platforms={i.platforms} />}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {loading && <div className={`${className}-loading`}>Loading...</div>}
        </Section>
      </LayoutContent>
    );
  }
}

export default ListContainer;