import { Fragment, useEffect, useState } from 'react';
import { defaultSchedulerTimeout } from '../../../constants';

export const Scheduler = ({ startTimes, endTimes, children }) => {
  const [isEnabled, setEnabled] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const scheduledStartTime = new Date();
    const scheduledEndTime = new Date();

    const scheduledTimes = startTimes.map((s, sIdx) => {
      scheduledStartTime.setHours(s.hours, s.minutes, s.seconds);
      scheduledEndTime.setHours(endTimes[sIdx].hours, endTimes[sIdx].minutes, endTimes[sIdx].seconds);
      return currentDate >= scheduledStartTime && currentDate < scheduledEndTime;
    });

    const isScheduled = scheduledTimes.reduce((a, b) => a || b);

    setEnabled(isScheduled);

    const timer = setTimeout(() => {
      setCount(count + 1);
      setEnabled(isScheduled);
    }, defaultSchedulerTimeout);

    return () => clearTimeout(timer);
  }, [count]);

  return (
    <Fragment>
      {isEnabled && children}
    </Fragment>
  )
};
