import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  GamePassTermsSection,
  LayoutContent,
  MechanicsCarousel,
  PageTitle,
  Section
} from '../Components';
import { Overview } from '../SpinTheWheelMechanics/Overview';
import { Eligibility } from '../SpinTheWheelMechanics/Eligibility';
import { FAQs } from '../SpinTheWheelMechanics/FAQs';
import { setHideFooter, setHideHeader } from '../../actions';
import { spinTheWheelMechanicsGlobal, spinTheWheelSections } from '../../constants';
import '../SpinTheWheelMechanics/SpinTheWheelMechanics.scss';

export const SpinTheWheelMechanicsGlobal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backToPreviousPage = () => {
    navigate(-1);
  }

  const overviewSection = useRef();
  const eligibilitySection = useRef();
  const mechanicsSection = useRef();
  const faqsSection = useRef();
  const termsSection = useRef();

  const [selectedSection, selectSection] = useState('');
  const [isDropdownOpen, openDropdown] = useState(false);
  const [sections, setSections] = useState([]);
  
  useEffect(() => {
    dispatch(setHideHeader(true));
    dispatch(setHideFooter(true));
    return () => {
      dispatch(setHideHeader(false));
      dispatch(setHideFooter(false));
    }
  }, [dispatch]);

  useEffect(() => {
    const pageSections = spinTheWheelMechanicsGlobal &&
      spinTheWheelMechanicsGlobal.sections &&
      spinTheWheelMechanicsGlobal.sections.map(s => s.name);
    setSections(pageSections);
  }, [spinTheWheelMechanicsGlobal]);

  const renderSections = (sections) => {
    return sections.map(s => {
      switch (s.name.toLowerCase()) {
        case spinTheWheelSections.overview.toLowerCase():
          return (
            <div key={`STWSection_${s.name}`} ref={overviewSection}>
              <Overview {...s} />
            </div>
          );
        case spinTheWheelSections.eligibility.toLowerCase():
          return (
            <div key={`STWSection_${s.name}`} ref={eligibilitySection}>
              <Eligibility {...s} />
            </div>
          );
        case spinTheWheelSections.mechanics.toLowerCase():
          return (
            <div key={`STWSection_${s.name}`} ref={mechanicsSection}>
              <MechanicsCarousel {...s} className="stw-mechanics" />
            </div>
          );
        case spinTheWheelSections.faqs.toLowerCase():
          return (
            <div key={`STWSection_${s.name}`} ref={faqsSection}>
              <FAQs {...s} />
            </div>
          );
        case spinTheWheelSections.termsAndConditions.toLowerCase():
          return (
            <div key={`STWSection_${s.name}`} ref={termsSection}>
              <GamePassTermsSection {...s} />
            </div>
          );
        default:
          return null;
      }
    });
  }

  const closeDropdown = () => {
    openDropdown(false);
  }

  const handleSelectSection = (section, sectionRef) => {
    selectSection(section);
    closeDropdown();
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const overview = spinTheWheelMechanicsGlobal.sections.find(s => s.name === 'Overview');
    selectSection(overview.name);
  }, [spinTheWheelMechanicsGlobal]);

  return (
    <>
      {spinTheWheelMechanicsGlobal &&
        <LayoutContent outerClassName="stw-mechanics" innerClassName="stw-mechanics-wrapper">
          <PageTitle title="Spin the Wheel Mechanics Global" />
          <Section 
            sectionClassName="stw-mechanics-section"
            sectionHeaderClassName="stw-mechanics-header"
            backButtonRoute
            backRoute={backToPreviousPage}
            title={spinTheWheelMechanicsGlobal.title}
            titleCentered
          >
            <div className="stw-mechanics-content">
              <button
                onClick={() => openDropdown(!isDropdownOpen)}
                className="stw-mechanics-dropdown-toggle"
              >
                <div>{selectedSection}</div>
                {isDropdownOpen ?
                  <KeyboardArrowUpRoundedIcon /> :
                  <KeyboardArrowDownRoundedIcon />}
              </button>
              <div>{renderSections(spinTheWheelMechanicsGlobal.sections)}</div>
            </div>
          </Section>
          <div className={classNames(
            "stw-mechanics-dropdown-list",
            {"stw-mechanics-dropdown-list-visible": isDropdownOpen}
          )}>
            <div
              className="stw-mechanics-dropdown-list-overlay"
              onClick={closeDropdown}
            />
            <div className="stw-mechanics-dropdown-list-dialog">
              {sections.map(s => {
                const selected = selectedSection === s;
                let selectedRef = overviewSection;
                if (s === spinTheWheelSections.eligibility) {
                  selectedRef = eligibilitySection;
                } else if (s === spinTheWheelSections.mechanics) {
                  selectedRef = mechanicsSection;
                } else if (s === spinTheWheelSections.faqs) {
                  selectedRef = faqsSection;
                } else if (s === spinTheWheelSections.termsAndConditions) {
                  selectedRef = termsSection;
                }
                return (
                  <div
                    key={`SpinTheWheelMechanics_Section_${s}`}
                    className={classNames(
                      "stw-mechanics-dropdown-list-item",
                      {"stw-mechanics-dropdown-list-item-selected": selected}
                    )}
                    onClick={() => handleSelectSection(s, selectedRef)}
                  >
                    {s}
                    {selected && <CheckRoundedIcon />}
                  </div>
                );
              })}
            </div>
          </div>
        </LayoutContent>}
    </>
  );
}