import React, { Component } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.scss';

export class Datepicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: new Date(),
      yearPickerEnabled: false,
      monthPickerEnabled: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateStr !== this.props.dateStr) {
      this.setSelectedDate(this.props.dateStr);
    }
  }

  setSelectedDate = (dateStr) => {
    const date = dateStr.replace(' +', '+');
    this.setState({ selected: new Date(date) });
  }

  showMonthYearPicker = (monthPickerEnabled, yearPickerEnabled) => {
    this.setState({ monthPickerEnabled, yearPickerEnabled });
  }

  onPrevClick = (decreaseMonth, decreaseYear) => {
    const { monthPickerEnabled, yearPickerEnabled } = this.state;
    if (!monthPickerEnabled && !yearPickerEnabled) decreaseMonth();
    if (monthPickerEnabled || yearPickerEnabled) decreaseYear();
  }

  onNextClick = (increaseMonth, increaseYear) => {
    const { monthPickerEnabled, yearPickerEnabled } = this.state;
    if (!monthPickerEnabled && !yearPickerEnabled) increaseMonth();
    if (monthPickerEnabled || yearPickerEnabled) increaseYear();
  }

  setDate = (date) => {
    const { monthPickerEnabled, yearPickerEnabled } = this.state;
    if (!monthPickerEnabled && !yearPickerEnabled) this.props.setDate(date);
    if (monthPickerEnabled) this.showMonthYearPicker(false, false);
    if (yearPickerEnabled) this.showMonthYearPicker(true, false);
  }

  render() {
    const { dateStr } = this.props;
    const { selected, monthPickerEnabled, yearPickerEnabled } = this.state;

    if (dateStr !== '') {
      return (
        <DatePicker
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            decreaseYear,
            increaseYear
          }) => {
            const currDateObj = DateTime.now().toObject();
            const dateObj = DateTime.fromJSDate(new Date(date)).toObject();
            
            let nextBtnDisabled = dateObj.year === currDateObj.year && dateObj.month === currDateObj.month;
            if (monthPickerEnabled || yearPickerEnabled) nextBtnDisabled = dateObj.year === currDateObj.year;

            return (
              <div className="datepicker-custom-header">
                <button onClick={() => this.onPrevClick(decreaseMonth, decreaseYear)} className="datepicker-prev-next-btn">
                  <ArrowBackIosNewRoundedIcon />
                </button>
                {
                  !monthPickerEnabled && !yearPickerEnabled &&
                  <button onClick={() => this.showMonthYearPicker(true, false)} className="datepicker-header-btn">
                    {DateTime.fromJSDate(new Date(date)).toFormat('LLLL yyyy')}
                  </button>
                }
                {
                  monthPickerEnabled && !yearPickerEnabled &&
                  <button onClick={() => this.showMonthYearPicker(false, true)} className="datepicker-header-btn">
                    {DateTime.fromJSDate(new Date(date)).toFormat('yyyy')}
                  </button>
                }
                {
                  !monthPickerEnabled && yearPickerEnabled &&
                  <button onClick={() => this.showMonthYearPicker(false, true)} className="datepicker-header-btn">
                    Years
                  </button>
                }
                <button
                  onClick={() => this.onNextClick(increaseMonth, increaseYear)}
                  disabled={nextBtnDisabled}
                  className={classNames("datepicker-prev-next-btn", {"datepicker-prev-next-btn-disabled": nextBtnDisabled})}
                >
                  <ArrowForwardIosRoundedIcon />
                </button>
              </div>
            );
          }}
          {...this.props}
          selected={selected}
          onChange={(date) => this.setDate(date)}
          showMonthYearPicker={monthPickerEnabled}
          showYearPicker={yearPickerEnabled}
          onCalendarClose={() => this.showMonthYearPicker(false, false)}
          onCalendarOpen={() => this.showMonthYearPicker(false, false)}
          minDate={new Date(1900, 0, 1)}
          maxDate={new Date()}
          shouldCloseOnSelect={!monthPickerEnabled && !yearPickerEnabled}
          calendarClassName="datepicker"
          onKeyDown={(e) => e.preventDefault()}
        />
      );
    }
    return null;
  }
};