import axios from 'axios';
import { baseUrl } from './url';

export function requestBannerImage(sessionToken, slug, platform) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`resources/${slug}?platform=${platform}`)
  });
}

export function requestAllBannerImages(sessionToken, slugs, platform) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`resources?platform=${platform}&slugs=${slugs}`)
  });
}