import { takeLatest, call, put, all } from 'redux-saga/effects';
import { 
  failedRequests,
  setAllListedNft,
  setBuyNavigate,
  setBuyNftId,
  setCollectionDetails, 
  setCollectionNftPagination, 
  setCollectionPagination, 
  setCollectionPaginationLoading, 
  setDisplayToast, 
  setIsBuy, 
  setIsCancelListBack, 
  setIsCancelListNFT, 
  setIsList, 
  setListingFee, 
  setListingFeeLoading, 
  setLoading, 
  setMarketplaceCollection, 
  setMarketplaceNftCollection, 
  setMarketplaceNftDetails, 
  setOpenModal
} from '../actions';
import { 
  BUY_NFT, 
  CANCEL_LIST_NFT, 
  GET_COLLECTION_DETAILS, 
  GET_ALL_LISTED_NFT, 
  GET_MARKETPLACE_COLLECTIONS, 
  GET_MARKETPLACE_NFT_DETAILS, 
  LIST_NFT, 
  GET_LISTING_FEE
} from '../actions/constants';
import { 
  requestAllListedNft,
  requestBuyNFT, 
  requestCancelListNFT, 
  requestListingFee, 
  requestListNFT, 
  requestMarketplaceCollectiblesDetails, 
  requestMarketplaceCollection, 
  requestMarketplaceNftDetails,
} from '../api/marketplace';
import { 
  errorCodes, 
  firebaseEvents, 
  mgcCurrency, 
  storageKeys
} from '../constants';
import { logFirebaseEvent, logFirebaseEventWithTimestamp } from '../utils/logFirebaseEvent';

const getMarketplaceCollectionSaga = function* () {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestMarketplaceCollection, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setMarketplaceCollection(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getAllListedNftSaga = function* (payload) {
  yield put(setCollectionPaginationLoading(true));
  yield put(setLoading(true));
  const { page, limit, listed } = payload.payload;
  const pageUrl = page && page ? `?page=${page}` : '';
  const limitUrl = limit && limit ? `&limit=${limit}`: '';
  const listedUrl = listed && listed ? `&listed=${listed}`: '';
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestAllListedNft, pageUrl, limitUrl, listedUrl, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setAllListedNft(response.data.d));
      yield put(setCollectionPagination({
        hasNextPage: response.data.hasNextPage,
        page: response.data.page
      }));
    }
    yield put(setCollectionPaginationLoading(false));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getMarketplaceNftDetailsSaga = function* (payload) {
  yield put(setCollectionPaginationLoading(true));
  yield put(setLoading(true));
  const { slug, page, limit, listed } = payload.payload;
  const pageUrl = page ? `?page=${page}` : '';
  const limitUrl = limit ? `&limit=${limit}`: '';
  const listedUrl = listed ? `&listed=${listed}`: '';
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestMarketplaceNftDetails, slug, pageUrl, limitUrl, listedUrl, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setMarketplaceNftDetails(response.data.d));
      yield put(setMarketplaceNftCollection(response.data.collection));
      yield put(setCollectionNftPagination({
        hasNextPage: response.data.hasNextPage,
        page: response.data.page
      }));
    }
    yield put(setCollectionPaginationLoading(false));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getCollectionDetailsSaga = function* (payload) {
  yield put(setLoading(true));
  const slug = payload.payload.slug;
  const id = payload.payload.id
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestMarketplaceCollectiblesDetails, slug, id, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setCollectionDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const marketplaceListNFTSaga = function* (payload) {
  const { collectionId, nftId, price, slug } = payload.payload;
  logFirebaseEventWithTimestamp(
    firebaseEvents.nftMarketplaceSellConfirmation, {
      payment_method: mgcCurrency,
      amount: price,
      currency: mgcCurrency
    });
  yield put(setLoading(true));
  yield put(setOpenModal(false));
  try {
    const listData = { collectionId, nftId, price };
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestListNFT, listData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setIsList(true));
      yield put(setOpenModal(true));
      yield put(setLoading(false));
      logFirebaseEvent(
        firebaseEvents.sellNFT, {
          nft_id: nftId, 
          item_name: slug, 
          value: price, 
          item_type: 'NFT', 
          virtual_currency_name: mgcCurrency
        });
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const marketplaceListingFeeSaga = function* (payload) {
  yield put(setListingFeeLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestListingFee, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setListingFee(response.data.d));
      yield put(setListingFeeLoading(false));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setListingFeeLoading(false));
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const marketplaceBuyNFTSaga = function* (payload) {
  const { collectionId, nftId, slug, price } = payload.payload;
  yield put(setLoading(true));
  yield put(setOpenModal(false));
  try {
    const buyData = { collectionId, nftId };
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestBuyNFT, buyData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setBuyNftId(response.data.d.referenceId));
      yield put(setIsBuy(true));
      yield put(setOpenModal(true));
      yield put(setIsCancelListBack(true));
      logFirebaseEvent(
        firebaseEvents.buyNFT, {
          nft_id: nftId, 
          item_name: slug, 
          item_type: 'NFT',
          value: price, 
          virtual_currency_name: mgcCurrency
        });
      logFirebaseEventWithTimestamp(
        firebaseEvents.nftMarketplaceBuySuccess, {
          payment_method: mgcCurrency,
          amount: price,
          currency: mgcCurrency
        });
    }
    yield put(setLoading(false));
  } catch (e) {
    logFirebaseEventWithTimestamp(
      firebaseEvents.nftMarketplaceBuyFail, {
        payment_method: mgcCurrency,
        amount: price,
        currency: mgcCurrency
      });
    yield put(setLoading(false));
    yield put(setIsCancelListBack(false));
    yield put(setIsBuy(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url
      }
      yield put(failedRequests(failedData));
      const toast = {
        result: false,
        title: e.response.data.code,
        message: e.response.data.message
      };
      if (e.response.data.code === errorCodes.forbidden){
        yield put(setBuyNavigate(true));
      } else {
        yield put(setDisplayToast(toast));
      }
    }
  }
}

const marketplaceCancelListNFTSaga = function* (payload) {
  const { collectionId, nftId, slug, price } = payload.payload;
  yield put(setLoading(true));
  try {
    const cancelListData = { collectionId, nftId };
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCancelListNFT, cancelListData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setIsCancelListBack(true));
      yield put(setIsCancelListNFT(true));
      yield put(setOpenModal(false));
      logFirebaseEvent(
        firebaseEvents.cancelNFTListing, {
          nft_id: nftId, 
          item_name: slug, 
          value: price,
          item_type: 'NFT', 
          virtual_currency_name: mgcCurrency
        });
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setIsCancelListBack(false));
    yield put(setIsCancelListNFT(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* marketplaceSaga() {
  yield all([
    takeLatest(GET_MARKETPLACE_COLLECTIONS, getMarketplaceCollectionSaga),
    takeLatest(GET_ALL_LISTED_NFT, getAllListedNftSaga),
    takeLatest(GET_MARKETPLACE_NFT_DETAILS, getMarketplaceNftDetailsSaga),
    takeLatest(GET_COLLECTION_DETAILS, getCollectionDetailsSaga),
    takeLatest(LIST_NFT, marketplaceListNFTSaga),
    takeLatest(GET_LISTING_FEE, marketplaceListingFeeSaga),
    takeLatest(BUY_NFT, marketplaceBuyNFTSaga),
    takeLatest(CANCEL_LIST_NFT, marketplaceCancelListNFTSaga)
  ])
}