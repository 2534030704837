import React from 'react';
import { webGLSubtitle, webGLTitle } from '../../constants';
import mainImage from '../../assets/gamepass/web-gl-main.png';
import box from '../../assets/gamepass/web-gl-box.png';

export const GamePassPlayWebGL = () => (
  <div className="gamepass-play-web-gl">
    <div className="gamepass-play-web-gl-wrapper">
      <img src={box} className="gamepass-play-web-gl-box" alt="box-logo" />
      <div className="gamepass-play-web-gl-body">
        <img src={mainImage} className="gamepass-play-web-gl-main" alt="main-logo" />
        <div className="gamepass-play-web-gl-title">{webGLTitle}</div>
        <div className="gamepass-play-web-gl-subtitle">{webGLSubtitle}</div>
      </div>
    </div>
  </div>
);
