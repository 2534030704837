import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import MediaQuery from 'react-responsive';
import { Featured, LayoutContent, Section } from '../Components';
import { Banner, Tabs } from '../Commons';
import { QuestList } from './QuestList';
import { QuestLeaderboards } from './QuestLeaderboards';
import { QuestFAQs } from './QuestFAQs';
import { maxWidths, minWidths } from '../../constants';
import titleBannerDesktop from '../../assets/quests/title-banner-desktop.jpg';
import titleBannerTablet from '../../assets/quests/title-banner-tablet.jpg';
import titleBannerMobile from '../../assets/quests/title-banner-mobile.jpg';

export class Quests extends Component {
  componentDidMount() {
    this.props.getQuests();
  }

  render() {
    const { quests, leaderboards, faqs } = this.props;

    if (!isEmpty(quests) && !isEmpty(leaderboards) && !isEmpty(faqs)) {
      return (
        <LayoutContent>
          <Featured fluid>
            <MediaQuery minWidth={minWidths.desktopXL}>
              <Banner imageUrl={titleBannerDesktop} />
            </MediaQuery>
            <MediaQuery minWidth={minWidths.tablet} maxWidth={maxWidths.desktopLG}>
              <Banner imageUrl={titleBannerTablet} />
            </MediaQuery>
            <MediaQuery maxWidth={maxWidths.mobileSM}>
              <Banner imageUrl={titleBannerMobile} />
            </MediaQuery>
          </Featured>
          <Section sectionClassName="quests">
            <Tabs contents={{
              Quests: <QuestList quests={quests} />,
              Leaderboards: <QuestLeaderboards leaderboards={leaderboards} />,
              FAQs: <QuestFAQs faqs={faqs} />
            }} />
          </Section>
        </LayoutContent>
      )
    }

    return null;
  }
}