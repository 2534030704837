import { connect } from 'react-redux';
import {
  setLoading,
  getUserProfile,
  setUnreadMessages,
  loginOTPSuccess,
  setSessionExpired,
  renderPageContents,
  setDisplayToast,
  setGuest,
  setBetaUser,
  setAuthStep,
  resetState,
  navigateTo,
  setClaimFreeGamePass,
  getFlags,
  getGamePassInventory,
  showGamePassCheckout,
  showReferralAdBanner
} from '../actions';
import {
  loadingSelector,
  pageContentsRenderedSelector,
  userProfileSelector,
  menuItemsSelector,
  sideMenuItemsSelector,
  sideMenuOtherItemsSelector,
  sessionTokenSelector,
  unreadMessagesSelector,
  mobileNumberSelector,
  sessionExpiredSelector,
  guestSelector,
  hideHeaderSelector,
  hideMenuIconSelector,
  hideFooterSelector,
  betaUserFlagSelector,
  walkthroughHeightLimitFlagSelector,
  authStepSelector,
  showLoadingSelector,
  remoteConfigKeysSelector,
  loginDataSelector,
  modalNotificationsSelector,
  showClaimFreeGamePassSelector,
  freeGamePassSelector,
  showGamePassCheckoutSelector,
  transactionDataSelector,
  redirectToGamePartnerPageSelector,
  homeCollectionSelector,
  userProfileToastSelector,
  referralAdBannerSelector
} from '../selectors';
import { Layout } from '../components';

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  pageContentsRendered: pageContentsRenderedSelector(state),
  userProfile: userProfileSelector(state),
  mobileNumber: mobileNumberSelector(state),
  menuItems: menuItemsSelector(state),
  sideMenuItems: sideMenuItemsSelector(state),
  sideMenuOtherItems: sideMenuOtherItemsSelector(state),
  sessionToken: sessionTokenSelector(state),
  unreadMessages: unreadMessagesSelector(state),
  sessionExpired: sessionExpiredSelector(state),
  isGuest: guestSelector(state),
  hideHeader: hideHeaderSelector(state),
  hideMenuIcon: hideMenuIconSelector(state),
  hideFooter: hideFooterSelector(state),
  betaUser: betaUserFlagSelector(state),
  hasNoWalkthroughHeightLimit: walkthroughHeightLimitFlagSelector(state),
  authStep: authStepSelector(state),
  showLoading: showLoadingSelector(state),
  remoteConfigKeys: remoteConfigKeysSelector(state),
  loginData: loginDataSelector(state),
  notifications: modalNotificationsSelector(state),
  showClaimFreeGamePass: showClaimFreeGamePassSelector(state),
  freeGamePass: freeGamePassSelector(state),
  showCheckout: showGamePassCheckoutSelector(state),
  onboardingTransactionData: transactionDataSelector(state),
  gamePartnerPageRedirect: redirectToGamePartnerPageSelector(state),
  homeCollection: homeCollectionSelector(state),
  toast: userProfileToastSelector(state),
  referralAdBanner: referralAdBannerSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast)),
  setLoading: (loading) => dispatch(setLoading(loading)),
  renderPageContents: (rendered) => dispatch(renderPageContents(rendered)),
  getUserProfile: () => dispatch(getUserProfile()),
  setUnreadMessages: (unreadMessages) => dispatch(setUnreadMessages(unreadMessages)),
  loginOTPSuccess: (token) => dispatch(loginOTPSuccess(token)),
  setSessionExpired: (expired) => (dispatch(setSessionExpired(expired))),
  setGuest: (isGuest) => dispatch(setGuest(isGuest)),
  setBetaUser: (betaUser) => dispatch(setBetaUser(betaUser)),
  setAuthStep: (authStep) => dispatch(setAuthStep(authStep)),
  navigateTo: (navigateData) => dispatch(navigateTo(navigateData)),
  resetState: () => dispatch(resetState()),
  setClaimFreeGamePass: (showClaimFreeGamePass) => dispatch(setClaimFreeGamePass(showClaimFreeGamePass)),
  getFlags: (payload) => dispatch(getFlags(payload)),
  getGamePassInventory: () => dispatch(getGamePassInventory()),
  showGamePassCheckout: (showCheckout) => dispatch(showGamePassCheckout(showCheckout)),
  showReferralAdBanner: (openBanner) => dispatch(showReferralAdBanner(openBanner))
});

const LayoutContainer = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default LayoutContainer;