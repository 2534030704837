import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useNetwork } from 'wagmi';
import { Radio } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Switch from 'react-switch';
import { 
  ErrorToast,
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { UserActiveNumbers } from './UserActiveNumbers';
import { 
  addGamePassCheckoutTokenizedPayments,
  deleteGamePassCheckoutTokenizedPayments,
  gamePassCheckoutReturnToPreviousPage,
  getGamePassCheckout, 
  getGamePassCheckoutTokenizedPayments, 
  setAddedGamePassCheckoutTokenizedPayments, 
  updateGamePassCheckout,
  setLoading
} from '../../actions';
import {
  checkoutContentsSelector,
  checkoutRedirectBackSelector,
  checkoutTokenizedIsAddedSelector,
  checkoutTokenizedPaymentsSelector,
  redirectToCheckoutPageSelector
} from '../../selectors';
import { useQuery } from '../../hooks/useQuery';
import { 
  defaultPaymentMethod, 
  defaultMobileNumberPrefix, 
  gamePassRecommendedMethodsText, 
  paymentMethods,
  gamePassPaymentMethodsTitles,
  switchColor,
  gamePassCheckoutCTA,
  storageKeys,
  onboardingTexts,
  paymentMethodsData,
  wallets
} from '../../constants';
import { overridePaymentMethodsData } from '../../utils/overridePaymentMethodsData';
import { useWalletConnect } from '../../hooks/useWalletConnect';
import './GamePassPaymentMethods.scss';

export const GamePassPaymentMethods = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const {
    address,
    isConnected,
    connector,
    open,
    isSigning,
    isVerifying,
    isSuccess
  } = useWalletConnect();
  const { chain } = useNetwork();

  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [tokenizedPaymentMethods, setTokenizedPaymentMethods] = useState([]);
  const [inputNumberRequired, setInputNumberRequired] = useState(false);
  const [showUserNumbers, setShowUserNumbers] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedNumber, setSelectedNumber] = useState({});
  const [active, setActive] = useState(false);

  const contents = useSelector(state => checkoutContentsSelector(state));
  const mustRedirectBack = useSelector(state => checkoutRedirectBackSelector(state));
  const tokenizedIsAdded = useSelector(state => checkoutTokenizedIsAddedSelector(state));
  const tokenizedPayments = useSelector(state => checkoutTokenizedPaymentsSelector(state));
  const redirectToCheckout = useSelector(state => redirectToCheckoutPageSelector(state));

  const pmSourceSessionStorage = sessionStorage.getItem(storageKeys.pmSource);
  const pmSourceCookie = Cookies.get(storageKeys.pmSource);

  useEffect(() => {
    if (isEmpty(contents)) {
      dispatch(gamePassCheckoutReturnToPreviousPage(true));
    }
  }, [dispatch])
  
  useEffect(() => {
    if (!isEmpty(contents)) return;
    if (pmSourceSessionStorage && pmSourceCookie) {
      dispatch(
        updateGamePassCheckout({ 
          selectedPaymentMethod: paymentMethods.gcash,  
          shouldNotBack: true,
          from: onboardingTexts.onboarding
        })
      );
      return;
    }
    dispatch(getGamePassCheckoutTokenizedPayments({ selectedPaymentMethod: paymentMethods.carrierX }));
  }, [dispatch, contents, pmSourceSessionStorage, pmSourceCookie])

  useEffect(() => {
    if (mustRedirectBack) navigate(gamePassCheckoutCTA);
    return () => dispatch(gamePassCheckoutReturnToPreviousPage(false));
  }, [dispatch, navigate, mustRedirectBack])

  useEffect(() => {
    if (tokenizedIsAdded) {
      navigate('?show=input&user=numbers');
    }
  }, [navigate, tokenizedIsAdded])

  useEffect(() => {
    if (query.get('show') && !query.get('user')) {
      setInputNumberRequired(true);
      setShowUserNumbers(false);
      return;
    } 
    if (query.get('show') && query.get('user')) {
      setShowUserNumbers(true);
      setInputNumberRequired(false);
      return;
    } 
    setShowUserNumbers(false);
    setInputNumberRequired(false);
  }, [query, location])

  useEffect(() => {
    if (isEmpty(contents)) return;
    let selectedMethod = defaultPaymentMethod;
    if (!isNil(contents.selectedPaymentMethod) &&
      contents.selectedPaymentMethod !== '') {
      selectedMethod = contents.selectedPaymentMethod;
    }
    
    const mgcPaymentMethod = contents.acceptedPaymentMethods?.filter(a => a.code === defaultPaymentMethod);
    const otherPaymentMethods = contents.acceptedPaymentMethods?.filter(a => a.code !== defaultPaymentMethod);
    const arrangedAcceptedPaymentMethods = [].concat(...mgcPaymentMethod, ...otherPaymentMethods);
    let updatedAcceptedPaymentMethods = arrangedAcceptedPaymentMethods;

    if (isConnected && !isSigning && !isVerifying) {
      updatedAcceptedPaymentMethods = overridePaymentMethodsData(
        updatedAcceptedPaymentMethods, 
        paymentMethodsData?.default.imageUrl,
        wallets.walletConnect.name, 
        wallets.metaMask.name, 
        address, 
        connector
      );
    } else {
      updatedAcceptedPaymentMethods = arrangedAcceptedPaymentMethods?.filter(a => a.code !== wallets.walletConnect.name);
    }
    let acceptedPaymentMethods = updatedAcceptedPaymentMethods;
    if (pmSourceSessionStorage && pmSourceCookie) {
      const filteredAcceptedPaymentMethods = arrangedAcceptedPaymentMethods?.filter(a => a.code === paymentMethods.gcash);
      acceptedPaymentMethods = filteredAcceptedPaymentMethods;
    } else {
      const tokenizedPayments = [];
      if (!isEmpty(contents.tokenizedPaymentMethods)) {
        tokenizedPayments.push(...contents.tokenizedPaymentMethods);
      }
      if (!isEmpty(arrangedAcceptedPaymentMethods)) {
        for (const paymentMethod of arrangedAcceptedPaymentMethods) {
          if (paymentMethod?.code === wallets.walletConnect.name) {
            tokenizedPayments.push(paymentMethodsData.tokenizedPayment);
          }
        }
      }
      setTokenizedPaymentMethods(tokenizedPayments);
    }
    setAcceptedPaymentMethods(acceptedPaymentMethods);
    setSelectedPaymentMethod(selectedMethod);
  }, [contents, isConnected, isSigning, isVerifying])

  useEffect(() => {
    if (!isEmpty(selectedNumber)) {
      setInputValue(selectedNumber.mobileNumber);
      setActive(selectedNumber.isActive);
    }
  }, [selectedNumber])

  const handleSelectedMethod = (code, selectedInputNumber) => {
    setSelectedPaymentMethod(code);
    let paymentMethodData = {
      selectedPaymentMethod: code,
      navigate
    }
    if (code === wallets.walletConnect.name) {
      paymentMethodData.chainId = chain?.id;
    }
    if (code === paymentMethods.carrierX && isEmpty(selectedInputNumber)) {
      navigate('?show=input');
    } else {
      dispatch(updateGamePassCheckout(paymentMethodData));
    }
  }
  
  useEffect(() => {
    if (isSuccess && 
      !isSigning && 
      !isVerifying && 
      redirectToCheckout) {
      handleSelectedMethod(wallets.walletConnect.name);
    }
  }, [isSuccess, isSigning, isVerifying, redirectToCheckout]);

  const handleInputNumberChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleSaveDetails = () => {
    dispatch(addGamePassCheckoutTokenizedPayments({ 
      selectedPaymentMethod: paymentMethods.carrierX,
      inputNumber: inputValue,
      isActive: active
    }));
  }

  const OnSwitchChange = () => {
    setActive(!active);
  }

  const handleOpenWalletConnect = async() =>  {
    if (isConnected) {
      handleSelectedMethod(wallets.walletConnect.name);
    } else {
      dispatch(setLoading(true));
      await open();
      dispatch(setLoading(false));
    }
  }

  const handleTokenizedMethods = (data) => {
    if (data.code === wallets.walletConnect.name) {
      handleOpenWalletConnect();
      return;
    }
    if (!isEmpty(tokenizedPayments?.values)) {
      navigate('?show=input&user=numbers');
      return;
    };
    if (data.code === paymentMethods.carrierX) {
      navigate('?show=input');
    }
  }

  const handleBack = () => {
    dispatch(setAddedGamePassCheckoutTokenizedPayments(false));
    if (!inputNumberRequired && isEmpty(contents)) dispatch(getGamePassCheckout());
    if (showUserNumbers) {
      navigate(gamePassCheckoutCTA);
      setShowUserNumbers(false);
      setInputNumberRequired(false);
    } else {
      navigate(-1);
    }
  }

  const onDelete = () => {
    let paymentMethod = selectedPaymentMethod;
    if (tokenizedPayments?.paymentMethod === paymentMethods.carrierX) {
      paymentMethod = tokenizedPayments?.paymentMethod;
    }
    dispatch(deleteGamePassCheckoutTokenizedPayments({
      selectedPaymentMethod: paymentMethod,
      tokenizedPaymentId: selectedNumber._id
    }));
  }

  let title = gamePassPaymentMethodsTitles.paymentMethods;
  if (inputNumberRequired) {
    if (active) {
      title = gamePassPaymentMethodsTitles.addGlobeNumber;
    } else {
      title = gamePassPaymentMethodsTitles.globeLoad;
    }
  }
  const updatedTitle = showUserNumbers ? gamePassPaymentMethodsTitles.globeLoad : title;
    
  return (
    <LayoutContent 
      outerClassName="payment-methods"
      innerClassName="payment-methods-inner"
    >
      <PageTitle title="Payment Methods" />
      <Section
        sectionClassName="payment-methods-section"
        backButtonRoute
        backRoute={handleBack}
        title={updatedTitle}
        titleCentered
        text="Back"
      >
        {!inputNumberRequired && 
          !showUserNumbers &&
          <>
            <div className="payment-methods-title">{gamePassRecommendedMethodsText}</div>
            <div className="payment-methods-box">
              {acceptedPaymentMethods.map((p, pIdx) => (
                <div 
                  key={`PaymentMethod_${pIdx}`} 
                  className="payment-methods-container"
                  onClick={() => handleSelectedMethod(p.code, p.selectedInputNumber)}
                >
                  <div className="payment-methods-content">
                    <div className="payment-methods-wrapper">
                      <div className="payment-methods-wrapper-box">
                        <img 
                          src={p.imageUrl} 
                          alt={p.code} 
                          className={classNames(
                            "payment-methods-wrapper-image",
                            {"payment-methods-wrapper-image-small": (p.code === defaultPaymentMethod || 
                              p.code === wallets.walletConnect.name)}
                          )}
                        />
                      </div>
                      <div className="d-flex align-items-start flex-column justify-content-start gap-1">
                        <div className="payment-methods-wrapper-title">{p.name}</div>
                        {p.description && <div className="payment-methods-wrapper-description">{p.description}</div>}
                        {p.selectedInputNumber && <div className="payment-methods-wrapper-description">{p.selectedInputNumber}</div>}
                      </div>
                    </div>
                    <Radio
                      className="ms-3"
                      checked={selectedPaymentMethod === p.code}
                      name="radio-buttons"
                      value={p.code}
                      sx={{
                        color: "#f304a0",
                        '&.Mui-checked': {
                          color: "#f304a0",
                        },
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          {!isEmpty(tokenizedPaymentMethods) &&
            <div className="pb-1">
              <div className="payment-methods-title">{gamePassPaymentMethodsTitles.paymentMethods}</div>
              {tokenizedPaymentMethods.map((t, tIdx) => (
                <div
                  onClick={() => handleTokenizedMethods(t)}
                  className="payment-methods-container" 
                  key={`TokenizedItems_${tIdx}`}
                >
                  <div className="payment-methods-box">
                    <div className="payment-methods-content">
                      <div className="payment-methods-wrapper">
                        <div className="payment-methods-wrapper-box">
                          <img 
                            src={t.imageUrl} 
                            alt={t.code} 
                            className={classNames(
                              "payment-methods-wrapper-image",
                              {"payment-methods-wrapper-image-small": t.code === defaultPaymentMethod}
                            )}
                          />
                        </div>
                        <div className="d-flex align-items-start flex-column justify-content-start gap-1">
                          <div className="payment-methods-wrapper-title">{t.name}</div>
                          {t.description && 
                            <div className="payment-methods-wrapper-description">{t.description}</div>}
                        </div>
                      </div>
                    </div>
                    <KeyboardArrowRightRoundedIcon className="payment-methods-arrow" />
                  </div>
                </div>
              ))}
            </div>}
          </>}
        {inputNumberRequired &&
          !showUserNumbers &&
          <>
            <div className="payment-methods-input-box">
              <div className="payment-methods-input-text">{gamePassPaymentMethodsTitles.inputNumber}</div>
              <div className="position-relative w-100">
                <div className="payment-methods-input-prefix">{defaultMobileNumberPrefix}</div>
                <input 
                  onChange={handleInputNumberChange} 
                  className="payment-methods-input"
                  value={inputValue}
                  autoFocus
                />
              </div>
              {!isEmpty(tokenizedPayments?.values) &&
                <div className="d-flex w-100 justify-content-between align-items-end">
                  <div className="payment-methods-active">{gamePassPaymentMethodsTitles.setAsActiveNumber}</div>
                  <Switch 
                    onChange={OnSwitchChange} 
                    checked={active} 
                    uncheckedIcon 
                    checkedIcon 
                    height={20}
                    width={40}
                    onColor={switchColor}
                  />
                </div>}
            </div>
            <div className="payment-methods-button">
              <div 
                onClick={handleSaveDetails} 
                className={classNames(
                  "payment-methods-button-save",
                  {"payment-methods-button-save-disabled": !active && !isEmpty(tokenizedPayments?.values)}
              )}>
                {gamePassPaymentMethodsTitles.saveDetails}
              </div>
            </div>
            {!isEmpty(selectedNumber) && 
              tokenizedPayments?.values?.length > 1 &&
              <div onClick={onDelete} className="payment-methods-button-delete">{gamePassPaymentMethodsTitles.delete}</div>}
          </>}
          {showUserNumbers && 
            <UserActiveNumbers 
              setSelectedNumber={setSelectedNumber} 
              setInputValue={setInputValue}
              setActive={setActive}
            />}
      </Section>
      <ErrorToast />
    </LayoutContent>
  )
}