import React from 'react';
import { FormField } from '../Components';
import './RewardDetails.scss';

export const RewardDetailsClaimForm = ({ id, claimForm, updateAnswer, formErrors, submitForm }) => (
  <form className="reward-details-form">
    {claimForm?.map((field, key) => (
      <FormField
        key={`RewardDetailsFormField_${id}_${key}`}
        formField={field}
        updateAnswer={updateAnswer}
        formErrors={formErrors}
        idKey="key"
        className="reward-details-form"
      />
    ))}
    <button
      className="reward-details-form-submit"
      onClick={submitForm}
    >
      Submit
    </button>
  </form>
);