import axios from 'axios';
import { baseUrl } from './url';

export function requestUserCollections(category, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/collections/all')
  });
}

export function requestUserCollection(collectionId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/collections/${collectionId}`)
  });
}