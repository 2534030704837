import React from 'react'
import { Skeleton } from '@mui/material';

export const Skeletons = ({ 
  mobile, 
  limitTwo,
  limitThree,
  limitFour, 
  largeImage, 
  title,
  image,
  desc,
  box,
  width,
  height,
  show 
}) => {
  const newWidth = width || '100%';
  let newHeight = largeImage ? 400 : 230;
  if (mobile) newHeight = height || 150;

  return (
    <>
      {
        image && 
        <Skeleton 
          sx={{ borderRadius: "12px", backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width={newWidth} 
          height={newHeight} 
        />
      }
      {
        limitTwo && 
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width={newWidth} 
          height={10} 
          className="mt-2"
        />
      }
      {
        limitThree && 
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width={newWidth} 
          height={10} 
          className="mt-2"
        />
      }
      {
        limitFour && 
        <Skeleton 
        sx={{ backgroundColor: "#272139" }} 
        animation="wave" variant="rounded"
        width={newWidth} 
        height={10} 
        className="mt-2"/>
      }
      {
        title && 
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width={newWidth} 
          height={20}
        />
      }
      {
        desc && 
        <>
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width={newWidth} 
          height={15} 
          className="mt-2"
        />
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width={newWidth} 
          height={15} 
          className="mt-2"
        />
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" 
          variant="rounded" 
          width="100%" 
          height={15} 
          className="mt-2"
        />
        </>
      }
      {
        show && 
        width && 
        height && 
        <Skeleton 
          sx={{ backgroundColor: "#272139" }} 
          animation="wave" variant="rounded" 
          width={newWidth} 
          height={height}
        />
      }
    </>
  )
}
