import React from 'react';
import classNames from 'classnames';
import './Steps.scss';

export const Steps = ({ currentStep, steps }) => {
  const currentStepIdx = steps.map(s => s.id).indexOf(currentStep);
  const finishedSteps = steps.slice(0, currentStepIdx + 1);

  return (
    <div className="pe-3 ps-3 pb-2">
      {steps.map((s, idx) => {
        return (
          <div key={s.id} className="step">
            <div className="step-indicator">
              <div className={classNames("step-bullet", {"step-bullet-active": finishedSteps.includes(s)})}></div>
              <div className={classNames({
                "step-connector": idx < steps.length - 1,
                "step-connector-active": finishedSteps.includes(s) && idx < finishedSteps.length - 1
              })}></div>
            </div>
            <div className="step-text">{s.title}</div>
          </div>
        );
      })}
    </div>
  );
}