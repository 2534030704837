import React from 'react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import { SectionList } from '../Components';
import { BuyGamePassSection } from '../GamePassScreen/BuyGamePassSection';
import {
  gamePassProductKinds,
  gamePassScreenSections,
  siteRoutes
} from '../../constants';
import tournamentsIcon from '../../assets/tournaments/tournaments-icon.png';
import premiumPassIcon from '../../assets/tournaments/premium-pass-icon.png';
import './Home.scss';

export const HomeSections = ({ sections, isGuest, handleGameDetails }) => (
  <div className="home-sections">
    <div className="home-sections-inner">
      {sections.map(t => {
        const isTournaments = t.dataset === 'Tournaments';
        const isPremiumPass = t.dataset.includes('Premium Pass');
        const isMarketplace = t.experience.toLowerCase().includes('marketplace');
        const isGamePass = t.dataset.includes('Game Pass');
        const isGamePassGames = t.dataset === gamePassScreenSections.playAndEarnGold;
        const experience = t.experience.toLowerCase();
        const linkExperience = isGamePass || isGamePassGames ? camelCase(experience).toLowerCase() : `${experience}s`;
        const linkCategory = t.dataset.split(' ')[0].toLowerCase();

        let link = `/${linkExperience}?category=${linkCategory}`;

        if (isTournaments) link = `/${linkExperience}`;
        else if (isGuest && isGamePass) link = siteRoutes.login;
        else if (isPremiumPass) link = `/${linkCategory}`;
        else if (isMarketplace) link = siteRoutes.launchpad;
        else if (isGamePass) link = `/${linkExperience}/buy`;
        else if (isGamePassGames) link = `/${linkExperience}/games`;

        let icon = null;
        if (isTournaments) icon = tournamentsIcon;
        else if (isPremiumPass) icon = premiumPassIcon;

        const thumbnailNameCentered = !isGamePass && !isMarketplace;

        if (isGamePass) {
          const gamePasses = compact(t.d);
          const recommendedItems = gamePasses.filter(i => i.name.toLowerCase() === gamePassProductKinds.recommended);
          const buyItems = [].concat(...recommendedItems.map(b => {
            const { currency, kind, skus } = b;
            skus.map(s => {
              s.currency = currency;
              s.mainKind = kind;
              return s;
            });
            return skus;
          }));

          return (
            <BuyGamePassSection
              key={`Section_${t.dataset}`}
              title={t.dataset}
              collection={recommendedItems}
              list={buyItems}
              link={link}
              linkText="View all"
              sectionClassName={classNames(
                "game-pass-screen-section-inner",
                "game-pass-screen-section-inner-home",
                "game-pass-screen-section-inner-themed"
              )}
            />
          );
        } else {
          return (
            <SectionList
              key={`Section_${t.dataset}`}
              title={t.dataset}
              list={t.d}
              icon={icon}
              detailed={isTournaments}
              link={link}
              linkText="View all"
              marketplace={isMarketplace}
              handleGameDetails={handleGameDetails}
              thumbnailNameCentered={thumbnailNameCentered}
              sectionClassName={classNames(
                "home-section", {
                  "game-pass-screen-section-inner": isGamePassGames,
                  "game-pass-screen-section-inner-home": isGamePassGames,
                  "game-pass-screen-section-inner-themed": isGamePassGames
                }
              )}
            />
          );
        }
      })}
    </div>
  </div>
);