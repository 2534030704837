import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { load } from 'recaptcha-v3';
import isEmpty from 'lodash/isEmpty';
import { BlurBackgroundModal } from '../Commons';
import { 
  authSteps,
  duration,
  firebaseEvents,
  loginTexts, 
  otpDefaultTimes, 
  otpMaxLength, 
  recaptchaSiteKey, 
  regexConstants, 
  storageKeys 
} from '../../constants';
import { 
  displayLoginToastSelector, 
  emailLoginOTPTimerActiveSelector, 
  isEmailAvailableSelector, 
  isEmailOTPEnabledSelector, 
  loadingSelector, 
  loginDataSelector, 
  loginOTPTimerActiveSelector
} from '../../selectors';
import { 
  resendEmailOTP,
  setAuthStep,
  setDisplayToast,
  setEmailOTPToEnabled,
  setLoading,
  showGamePassCheckout,
  startEmailLoginOTPTimer,
  startLoginOTPTimer, 
  submitLoginOTP, 
  tryLogin
} from '../../actions';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './OTPModal.scss';

export const OTPModal = ({ open, hasGamePartnerRedirectUri, hideWrongNumber }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [emailMinutes, setEmailMinutes] = useState(0);
  const [emailSeconds, setEmailSeconds] = useState(0);
  const [recaptcha, setRecaptcha] = useState('');
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  const timerActive = useSelector(state => loginOTPTimerActiveSelector(state));
  const emailTimerActive = useSelector(state => emailLoginOTPTimerActiveSelector(state));
  const loginData = useSelector(state => loginDataSelector(state));
  const toast = useSelector(state => displayLoginToastSelector(state));
  const isEmailAvailable = useSelector(state => isEmailAvailableSelector(state));
  const isEmailOTPEnabled = useSelector(state => isEmailOTPEnabledSelector(state));
  const loading = useSelector(state => loadingSelector(state));

  const getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        setRecaptcha(recaptcha);
        setIsRecaptchaLoaded(true);
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  useEffect(() => {
    getRecaptcha();
  }, [])

  useEffect(() => {
    if (timerActive) {
      setMinutes(otpDefaultTimes.minutes);
      setSeconds(otpDefaultTimes.seconds);
    }
  }, [timerActive])

  useEffect(() => {
    if (emailTimerActive) {
      setEmailMinutes(otpDefaultTimes.minutes);
      setEmailSeconds(otpDefaultTimes.seconds);
    }
  }, [emailTimerActive])

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            dispatch(startLoginOTPTimer(false));
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, duration.oneSecond);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, timerActive, minutes, seconds]);

  useEffect(() => {
    if (emailTimerActive) {
      const emailInterval = setInterval(() => {
        if (emailSeconds > 0) {
          setEmailSeconds(emailSeconds - 1);
        }
        if (emailSeconds === 0) {
          if (emailMinutes === 0) {
            dispatch(startEmailLoginOTPTimer(false));
            clearInterval(emailInterval);
          } else {
            setEmailSeconds(59);
            setEmailMinutes(emailMinutes - 1);
          }
        }
      }, duration.oneSecond);
      return () => {
        clearInterval(emailInterval);
      };
    }
  }, [dispatch, emailTimerActive, emailMinutes, emailSeconds]);

  const formatTime = (minutes, seconds, resendViaEmail) => {
    const prefix = resendViaEmail ? loginTexts.otp.resendCodeViaEmail : loginTexts.otp.resendCodeIn;
    const minutesString = minutes?.toString();
    const secondsString = seconds?.toString();
    if (typeof minutesString === 'string' && 
      typeof secondsString === 'string') {
      return `${prefix} ${minutesString.padStart(2, '0')}:${secondsString.padStart(2, '0')}`;
    }
  }

  const checkoutData = loginData?.checkoutData;
  const customer = loginData?.customer;

  const submitOTP = () => {
    if (!loading) {
      const mobnum = loginData.mobnum;
      const referralCode = Cookies.get(storageKeys.referralCode);
      const initPayload = { otp, mobnum };
      let payload = initPayload;
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      if (!isEmpty(checkoutData)) {
        payload.checkoutData = checkoutData;
      }
      if (!isEmpty(customer)) {
        payload.customer = customer;
      }
      if (location?.state?.route) {
        payload.route = location?.state?.route;
      }
      dispatch(setLoading(true));
      dispatch(submitLoginOTP(payload));
    }
  }

  useEffect(() => {
    if (otp.length === 6) {
      submitOTP();
    }
  }, [otp])

  const handleResendOTP = () => {
    if (!loading) {
      const formData = {
        mobileNumber: loginData.mobnum,
        deviceInfo: getDeviceInfo()
      }
      dispatch(setLoading(true));
      dispatch(setDisplayToast());
      if (isRecaptchaLoaded) {
        recaptcha.execute('login')
          .then(t => {
            const updatedFormData = {
              ...formData,
              recaptchaToken: t
            };
            dispatch(tryLogin(updatedFormData));
          })
          .catch(error => {
            logFirebaseEvent(
              firebaseEvents.failedEvent, {
                error_message: error
              });
            dispatch(tryLogin(formData));
          });
      } else {
        dispatch(tryLogin(formData));
      }
    }
  }
  
  const handleResendOTPViaEmail = () => {
    if (!loading) {
      const formData = {
        mobileNumber: loginData.mobnum,
        deviceInfo: getDeviceInfo()
      }
      dispatch(setLoading(true));
      dispatch(setDisplayToast());
      if (isRecaptchaLoaded) {
        recaptcha.execute('login')
          .then(t => {
            const updatedFormData = {
              ...formData,
              recaptchaToken: t
            };
            dispatch(resendEmailOTP(updatedFormData));
          })
          .catch(error => {
            logFirebaseEvent(
              firebaseEvents.failedEvent, {
                error_message: error
              });
            dispatch(resendEmailOTP(formData));
          });
      } else {
        dispatch(resendEmailOTP(formData));
      }
    }
  }

  const formatPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber?.replace(regexConstants.formattedPhoneNumber, '$1$2 $3 $4');
    return formattedPhoneNumber;
  }

  const handleWrongNumber = () => {
    if (isEmpty(loginData?.checkoutData)) {
      return dispatch(setAuthStep(authSteps.login));
    }
    dispatch(showGamePassCheckout(true));
    dispatch(setAuthStep());
  }

  const handleChange = (otp) => {
    dispatch(setDisplayToast());
    setOtp(otp);
  }

  const handleHide = () => {
    if (!hasGamePartnerRedirectUri) {
      dispatch(setDisplayToast());
      dispatch(setAuthStep());
      if (isEmailOTPEnabled) {
        dispatch(setEmailOTPToEnabled(false));
      }
    }
  }

  let title = `${loginTexts.otp.newTitle} ${formatPhoneNumber(loginData.mobnum)}`;
  if (isEmailOTPEnabled) {
    title = loginTexts.otp.newTitleEmail;
  }

  let titleComponent = title;
  if (hasGamePartnerRedirectUri) {
    titleComponent = <div className="otp-auth-header-logo" />;
  }

  return (
    <BlurBackgroundModal
      open={open}
      title={titleComponent}
      hide={handleHide}
      hideClose={hasGamePartnerRedirectUri}
      dialogClassName={classNames({"otp-auth-dialog": hasGamePartnerRedirectUri})}
      backdropClassName={classNames({"otp-auth-backdrop": hasGamePartnerRedirectUri})}
    >
      <div className="otp-auth">
        <div className="otp-auth-input-text">{loginTexts.otp.newDescription}</div>
        <div className={classNames(
          "otp-auth-input",
          {"otp-auth-input-error": toast}
        )}>
          <OtpInput
            shouldAutoFocus={true}
            numInputs={otpMaxLength}
            onChange={otp => handleChange(otp)}
            value={otp}
            containerStyle="otp-auth-input-grid"
            inputStyle={classNames(
              "otp-auth-digit",
              {"otp-auth-digit-error": toast}
            )}
            focusStyle="otp-auth-digit-focused"
            isInputNum={true}
            placeholder="——————"
          />
        </div>
        {toast &&
          <div className="otp-auth-error">{toast?.message}</div>}
        <div 
          onClick={submitOTP}
          className={classNames(
            "blur-modal-button",
            {"blur-modal-button-disabled": otp.length < 6 || loading}
          )}
        >
          {loginTexts.button.next}
        </div>
        {minutes === 0 && seconds === 0 ? 
          <div className="otp-auth-text-wrapper">
            <div className="otp-auth-text" onClick={handleResendOTP}>
              {loginTexts.otp.resendCode}
            </div>
            {!hideWrongNumber &&
              <div className="otp-auth-text" onClick={handleWrongNumber}>
                {loginTexts.otp.wrongNumber}
              </div>}
          </div> : 
          <div className="otp-auth-text-wrapper">{formatTime(minutes, seconds)}</div>}
        {isEmailAvailable &&
          <div className="otp-auth-email-wrapper">
            {((emailMinutes === 0 && emailSeconds === 0) || 
              (emailMinutes === otpDefaultTimes.minutes && emailSeconds === otpDefaultTimes.seconds)) ?
              <div className="otp-auth-text" onClick={handleResendOTPViaEmail}>
                {loginTexts.otp.resendCodeEmail}
              </div> :
              <div>{formatTime(emailMinutes, emailSeconds, true)}</div>}
          </div>}
      </div>
    </BlurBackgroundModal>
  );
}