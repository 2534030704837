import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { load } from 'recaptcha-v3';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { HiddenHeaderAndFooter, Toast } from '../Commons';
import { LoginForm } from './LoginForm';
import { LoginOTP } from './LoginOTP';
import { LoginPIN } from './LoginPIN';
import { LoginWaitListModal } from './LoginWaitListModal';
import { 
  loginTexts,
  defaultMobileNumberPrefix,
  recaptchaSiteKey,
  defaultTimeoutMS,
  firebaseEvents,
  authSteps,
  otpDefaultTimes,
  registrationStepQuery,
  storageKeys,
  regexConstants,
  formInputKinds
} from '../../constants';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import {
  logFirebaseEvent,
  logFirebaseEventWithTimestamp
} from '../../utils/logFirebaseEvent';
import './Login.scss';

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptcha: {},
      isRecaptchaLoaded: false,
      countryCode: defaultMobileNumberPrefix,
      loginFormData: {
        mobileNumber: '',
        email: '',
        username: '',
        earlyAccessCode: ''
      },
      invalidLoginFormInputs: [],
      pinFormData: {
        pin: '',
        confirm: ''
      },
      invalidPINFormInputs: [],
      deviceInfo: getDeviceInfo(),
      toastOpen: false,
      consentCheckboxChecked: false,
      emailIsActive: false,
      otpMinutes: otpDefaultTimes.minutes,
      otpSeconds: otpDefaultTimes.seconds,
      params: {}, 
      slug: ''
    };
  }

  componentDidMount() {
    const isLoginPage = this.props.authStep === authSteps.login &&
      !window.location.search.includes(storageKeys.isTutorial);
    const registrationFormVisible = window.location.search.includes(registrationStepQuery);
    const isDailyRewardsShown = Cookies.get(storageKeys.isDailyRewardsShown);

    this.getRecaptcha();
    this.props.displayRegistrationForm(registrationFormVisible);
    this.props.setLoading(false);

    if (isLoginPage) {
      this.logLoginOrRegistrationEvent(registrationFormVisible);
    }

    if (isDailyRewardsShown) {
      Cookies.remove(storageKeys.isDailyRewardsShown);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { params } = this.state;
    const { toast, authStep, registrationFormVisible } = this.props;

    if (prevProps.toast !== toast && toast) {
      this.openToast(true);
    }

    if (prevProps.authStep !== authStep && authStep) {
      if (authStep === authSteps.login) {
        this.logLoginOrRegistrationEvent(registrationFormVisible);
      } else if (authStep === authSteps.otp) {
        logFirebaseEventWithTimestamp(firebaseEvents.authenticationPage);
      }
      this.setState({
        otpMinutes: otpDefaultTimes.minutes,
        otpSeconds: otpDefaultTimes.seconds
      });
      window.scrollTo(0, 0);
    }

    if (prevProps.registrationFormVisible !== registrationFormVisible) {
      this.logLoginOrRegistrationEvent(registrationFormVisible);
    }

    if (prevState.params !== params && params) {
      this.setState({
        loginFormData: {
          ...this.state.loginFormData,
          email: params.email
        }
      });
    }
  }

  getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        this.setState({
          recaptcha,
          isRecaptchaLoaded: true
        });
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  logLoginOrRegistrationEvent = (registrationFormVisible) => {
    let event = firebaseEvents.loginPage;
    if (registrationFormVisible) {
      event = firebaseEvents.signUpPage;
    }
    logFirebaseEventWithTimestamp(event);
  }

  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  };

  closeToast = () => {
    this.openToast(false);
    setTimeout(() => this.props.setDisplayToast(), defaultTimeoutMS);
  };

  closeWaitListModal = () => {
    setTimeout(() => {
      this.props.setDisplayToast();
      window.location.reload();
    }, defaultTimeoutMS);
  }

  handleLoginInput = (e, fieldName) => {
    const { value } = e.target;
    let newValue = value;
    if (fieldName.includes(formInputKinds.username) || fieldName.includes(formInputKinds.earlyAccessCode)) {
      newValue = value.replace(regexConstants.specialCharUnderscoreDot, '');
    } else if (fieldName === formInputKinds.phone) {
      let mobileNumberWithoutCountryCode = value.replace(this.state.countryCode, '');
      newValue = mobileNumberWithoutCountryCode.replace(regexConstants.nonDigit, '');
    }
    this.setState({
      invalidLoginFormInputs: this.state.invalidLoginFormInputs.filter(i => i !== fieldName),
      loginFormData: {
        ...this.state.loginFormData,
        [fieldName]: newValue
      }
    });
  };

  setCountryCode = (countryCode) => {
    this.setState({ countryCode });
  };

  setEmailParams = (params) => {
    this.setState({ params });
  }

  setSlugParams = (slug) => {
    this.setState({ slug });
  }

  handleLogin = (e, registerButtonDisabled) => {
    const { loginFormInputs, registrationFormVisible } = this.props;
    const { loginFormData, countryCode, deviceInfo, isRecaptchaLoaded, recaptcha } = this.state;

    e.preventDefault();

    if (!registerButtonDisabled) {
      const loginFormDataValues =
        loginFormData.email === '' ||
        loginFormData.username === '' ||
        loginFormData.mobileNumber === '';
      const mobileNumber = `${countryCode}${loginFormData.mobileNumber}`;
      const mobileNumberValid = isValidPhoneNumber(mobileNumber);
      if (registrationFormVisible) {
        if (loginFormDataValues) {
          this.setState({
            invalidLoginFormInputs: loginFormInputs
              .filter((i) => loginFormData[i.name] === '')
              .map((i) => i.name),
          });
        } else {
          const updatedFormData = {
            ...loginFormData,
            mobileNumber,
            deviceInfo,
            withoutCountryCodeMobnum: loginFormData.mobileNumber
          };
          if (isRecaptchaLoaded) {
            recaptcha.execute('login')
              .then(t => {
                const updatedFormDataWithRecaptchaToken = {
                  ...updatedFormData,
                  recaptchaToken: t
                };
                this.checkFormData(updatedFormDataWithRecaptchaToken);
              })
              .catch(error => {
                this.checkFormData(updatedFormData);
              });
          } else {
            this.checkFormData(updatedFormData);
          }
        }
      } else {
        if (mobileNumberValid) {
          const payload = {
            mobileNumber,
            deviceInfo,
            withoutCountryCodeMobnum: loginFormData.mobileNumber
          };
          this.props.setLoading(true);
          if (isRecaptchaLoaded) {
            recaptcha.execute('login')
              .then(t => {
                const updatedPayload = {
                  ...payload,
                  recaptchaToken: t
                };
                this.props.tryLogin(updatedPayload);
              })
              .catch(error => {
                this.props.tryLogin(payload);
              });
          } else {
            this.props.tryLogin(payload);
          }
        } else {
          this.setState({ invalidLoginFormInputs: [storageKeys.mobileNumber] });
        }
      }
    }
  };

  handleEmailLogin = (e) => {
    const { loginFormData, countryCode, deviceInfo, isRecaptchaLoaded, recaptcha } = this.state;
    const payload = {
      mobileNumber: `${countryCode}${loginFormData.mobileNumber}`,
      deviceInfo,
      withoutCountryCodeMobnum: loginFormData.mobileNumber
    };

    e.preventDefault(e);
    
    if (isRecaptchaLoaded) {
      recaptcha.execute('login')
        .then(t => {
          const updatedPayload = {
            ...payload,
            recaptchaToken: t
          };
          this.props.resendEmailOTP(updatedPayload);
        })
        .catch(error => {
          this.props.resendEmailOTP(payload);
        });
    } else {
      this.props.resendEmailOTP(payload);
    }
  };

  checkFormData = (formData) => {
    const { mobileNumber, email } = formData;
    const mobileNumberValid = isValidPhoneNumber(mobileNumber);

    const invalidLoginFormInputs = [];
    if (!mobileNumberValid) {
      invalidLoginFormInputs.push(storageKeys.mobileNumber);
    }
    if (!regexConstants.email.test(email)) {
      invalidLoginFormInputs.push(formInputKinds.email);
    }
    this.setState({ invalidLoginFormInputs });

    if (isEmpty(invalidLoginFormInputs)) {
      this.props.setLoading(true);
      this.props.submitLogin(formData);
      logFirebaseEventWithTimestamp(firebaseEvents.registerComplete);
    }
  };

  resendOTP = () => {
    this.setState({ emailIsActive: true });
  }

  stopTimerEmail = () => {
    this.setState({ emailIsActive: false });
  }

  backToLogin = () => {
    const { setAuthStep, startLoginOTPTimer } = this.props;
    setAuthStep(authSteps.login);
    startLoginOTPTimer(false);
    this.stopTimerEmail();
  };

  goToLogin = () => {
    this.props.displayRegistrationForm(false);
  };

  goToSignup = () => {
    this.props.displayRegistrationForm(true);
  };

  checkConsentCheckbox = (consentCheckboxChecked) => {
    this.setState({ consentCheckboxChecked });
  };

  handlePINInput = (e, fieldName) => {
    this.setState({
      invalidPINFormInputs: this.state.invalidPINFormInputs.filter(i => i !== fieldName),
      pinFormData: {
        ...this.state.pinFormData,
        [fieldName]: e.target.value
      }
    });
  };

  submitPIN = (e) => {
    const { pinFormInputs } = this.props;
    const { pinFormData, params, slug } = this.state;
    const { pin, confirm } = pinFormData;
    const pinFormDataValues = pinFormInputs.map((i) => pinFormData[i.name]);
    const urlParams = params;

    e.preventDefault();

    if (!pinFormDataValues.includes('')) {
      if (pin === confirm) {
        this.setState({ invalidPINFormInputs: [] });
        this.props.submitLoginPIN({ pin, urlParams, slug });
      } else {
        this.setState({ invalidPINFormInputs: ['confirm'] });
      }
    } else {
      this.setState({ invalidPINFormInputs: pinFormInputs.filter(i => pinFormData[i.name] === '').map(i => i.name) });
    }
  };

  render() {
    const {
      loginFormInputs,
      pinFormInputs,
      authStep,
      timerActive,
      startLoginOTPTimer,
      registrationFormVisible,
      displayRegistrationForm,
      hasPINValidationError,
      setPINValidationError,
      setPINFormDisplayed,
      toast,
      isSubmit
    } = this.props;
    const {
      countryCode,
      loginFormData,
      invalidLoginFormInputs,
      pinFormData,
      invalidPINFormInputs,
      toastOpen,
      consentCheckboxChecked,
      emailIsActive,
      otpMinutes,
      otpSeconds,
      params,
      slug
    } = this.state;
    const otpReceived = authStep === authSteps.otp;
    const goToPIN = authStep === authSteps.pin;

    const queryParams = new URLSearchParams(window.location.search);
    const step = queryParams.get('step');

    let translateX = step === 'otp' ? -100 : 0;
    if (otpReceived) translateX = -100;
    if (goToPIN) translateX = -200;
    const transform = { transform: `translateX(${translateX}%)` };

    return (
      <div className="login-container">
        <HiddenHeaderAndFooter hideHeader hideFooter all />
        <div className="login-pages">
          <LoginForm
            authStep={authStep}
            transform={transform}
            countryCode={countryCode}
            formInputs={loginFormInputs}
            invalidInputs={invalidLoginFormInputs}
            formData={loginFormData}
            registrationFormVisible={registrationFormVisible}
            texts={loginTexts}
            displayRegistrationForm={displayRegistrationForm}
            consentCheckboxChecked={consentCheckboxChecked}
            setCountryCode={this.setCountryCode}
            handleInput={this.handleLoginInput}
            handleLogin={this.handleLogin}
            checkConsentCheckbox={this.checkConsentCheckbox}
            setEmailParams={this.setEmailParams}
            setSlugParams={this.setSlugParams}
          />
          <LoginOTP
            emailIsActive={emailIsActive}
            resendEmail={this.handleEmailLogin}
            authStep={authStep}
            transform={transform}
            backToLogin={this.backToLogin}
            texts={loginTexts.otp}
            timerActive={timerActive}
            resend={this.handleLogin}
            stopTimer={() => startLoginOTPTimer(false)}
            otpMinutes={otpMinutes}
            otpSeconds={otpSeconds}
            resendOTP={this.resendOTP}
            stopTimerEmail={this.stopTimerEmail}
            loginFormData={loginFormData}
            countryCode={countryCode}
            params={params}
            slug={slug}
          />
          <LoginPIN
            transform={transform}
            setPINFormDisplayed={setPINFormDisplayed}
            texts={loginTexts.pin}
            formInputs={pinFormInputs}
            invalidInputs={invalidPINFormInputs}
            formData={pinFormData}
            hasValidationError={hasPINValidationError}
            handleInput={this.handlePINInput}
            backToLogin={this.backToLogin}
            submitPIN={this.submitPIN}
            validatePIN={this.props.submitPIN}
            setPINValidationError={setPINValidationError}
            isSubmit={isSubmit}
          />
        </div>
        {toast && toast.message.includes('waitlist') ? (
          <LoginWaitListModal hide={this.closeWaitListModal} />
        ) : (
          toast && (
            <Toast toast={toast} open={toastOpen} hide={this.closeToast} />
          )
        )}
      </div>
    );
  }
}
