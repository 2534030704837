import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Modal } from 'react-bootstrap';
import { Radio } from '@mui/material';
import { checkoutSelectCurrencySelector } from '../../../selectors';
import { getGamePassCheckoutSelectedCurrency } from '../../../actions';
import { walletConnectTexts } from '../../../constants';
import './SelectCurrencyToast.scss';

const SelectCurrencyToast = ({ open, hide, chain }) => {
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const currency = useSelector(state => checkoutSelectCurrencySelector(state));

  const handleSelectedCurrency = (data) => {
    setSelectedCurrency(data.kind);
  }

  const handleApply = () => {
    const currencyData = {
      chainId: chain?.id,
      currency: selectedCurrency
    }
    dispatch(getGamePassCheckoutSelectedCurrency(currencyData));
  }

  useEffect(() => {
    if (isEmpty(currency)) {
      return;
    }
    if (open) {
      for (const item of currency) {
        if (item.isSelected) {
          setSelectedCurrency(item.kind);
        }
      }
    }
  }, [open, currency])

  return (
    <Modal
      backdrop
      show={open}
      className="select-currency-toast-container"
      contentClassName="select-currency-toast"
      dialogClassName="select-currency-toast-dialog"
      backdropClassName="select-currency-toast-bg"
      centered
      onHide={hide}
    >
      <Modal.Header 
        className="select-currency-toast-header"
        closeButton 
        closeVariant="white" 
        onHide={hide}
      >
        <div className="select-currency-toast-header-title">{walletConnectTexts.selectCurrency}</div>
      </Modal.Header>
      <Modal.Body className="select-currency-toast-body">
        <div className="select-currency-toast-body-container">
          {!isEmpty(currency) && 
            currency.map((c, cIdx) => (
            <div 
              key={`Currency_${cIdx}`} 
              onClick={() => handleSelectedCurrency(c)} 
              className="select-currency-toast-body-content"
            >
              <Radio
                checked={c.kind === selectedCurrency}
                name="radio-buttons"
                value={c.kind}
                sx={{
                  color: "#f304a0",
                  '&.Mui-checked': {
                    color: "#f304a0",
                  }
                }}
              />
              <img src={c.iconUrl} alt={c.kind} className="select-currency-toast-body-content-image" />
              <div>{c.name}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="select-currency-toast-footer">
        <button 
          onClick={handleApply} 
          className="select-currency-toast-footer-button"
        >
          {walletConnectTexts.apply}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectCurrencyToast;