import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HorizontalScroll.scss';

export const HorizontalScroll = ({ slidesToShow, className, children }) => {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    className
  };
  return <Slider {...sliderSettings}>{children}</Slider>;
}