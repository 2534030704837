import React, { useEffect, useState } from 'react';
import { GamePassProductAmount, LayoutContent, PageTitle, Section } from '../Components';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import Tabs, { Tab } from 'react-best-tabs';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Skeletons } from '../Commons';
import { 
  getAllListedNft, 
  getMarketplaceCollection, 
  setAllListedNftPagination,
  setSelectedSlug
} from '../../actions';
import {
  listedNftsPaginationSelector,
  listedNftsSelector,
  marketplaceCollectionsSelector,
  marketplacePaginationDataHasNextPageSelector,
  marketplacePaginationDataPageSelector,
  marketplaceRenderingSelector
} from '../../selectors';
import { bannerDimensions, maxWidths, range } from '../../constants';
import { 
  launchpadBannerMobile, 
  launchpadBannerTablet, 
  launchpadBannerDesktop, 
  marketplaceBannerMobile,
  marketplaceBannerTablet, 
  marketplaceBannerDesktop
} from './images';
import 'react-best-tabs/dist/index.css';
import './Launchpad.scss';

export const LaunchpadCollection = ({ collection }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(1);
  const [listedCollections, setListedCollections] = useState([]);

  const pagination = useSelector(state => listedNftsPaginationSelector(state));
  const hasNextPage = useSelector(state => marketplacePaginationDataHasNextPageSelector(state));
  const page = useSelector(state => marketplacePaginationDataPageSelector(state));
  const collections = useSelector(state => marketplaceCollectionsSelector(state));
  const listedNfts = useSelector(state => listedNftsSelector(state));
  const isRendering = useSelector(state => marketplaceRenderingSelector(state));

  const tabletDisplay = useMediaQuery({ maxWidth: maxWidths.tablet });
  const smallerDisplay = useMediaQuery({ maxWidth: maxWidths.mobileSM });
  const mobileDisplay = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  const { ref, inView } = useInView({ threshold: 0.9 });

  const handleImage = () => {
    if (activeTab === 1) {
      if (mobileDisplay) return marketplaceBannerMobile;
      if (tabletDisplay) return marketplaceBannerTablet;
      return marketplaceBannerDesktop;
    }
    if (mobileDisplay) return launchpadBannerMobile;
    if (tabletDisplay) return launchpadBannerTablet;
    return launchpadBannerDesktop;
  }

  const handleDimensions = () => {
    if (mobileDisplay) return bannerDimensions.launchpadBanner.mobile;
    if (tabletDisplay) return bannerDimensions.launchpadBanner.tablet;
    return bannerDimensions.launchpadBanner.desktop;
  }
  
  const handleSelectedItem = (data) => {
    if (!smallerDisplay) return;
    navigate(`/launchpad/${data.slug}`);
  }

  const handleSelectedItemDesktop = (data) => {
    navigate(`/launchpad/${data.slug}`);
  }

  useEffect(() => {
    setListedCollections(listedNfts.filter(i => typeof i !== 'number'));
  }, [listedNfts])

  useEffect(() => {
    if (isEmpty(collections) && isEmpty(listedNfts)){
      dispatch(getMarketplaceCollection());
      dispatch(getAllListedNft({ page: 1, limit: 10, listed: true }));
    } 
  }, [dispatch, collections, listedNfts])

  useEffect(() => {
    if (page === pagination || pagination === 1) return;
    if (!hasNextPage) return;
    const data = {
      page: pagination,
      limit: 10,
      listed: true
    }
    dispatch(getAllListedNft(data));
  }, [dispatch, pagination, page, hasNextPage])

  useEffect(() => {
    if (!hasNextPage) return;
    if (inView) {
      setListedCollections(prev => [...prev, ...range(2)]);
      dispatch(setAllListedNftPagination());
    }
  }, [dispatch, inView, hasNextPage])

  const handleCollectionNavigate = (slug) => {
    navigate(`/marketplace/${slug.slug}`);
    dispatch(setSelectedSlug(slug.slug));
  }

  return (
    <LayoutContent outerClassName="launchpad-container" innerClassName="launchpad-inner">
      <PageTitle title="Launchpad" />
      <Section sectionClassName="launchpad-section">
        <img
          className="launchpad-banner"
          src={handleImage()}
          alt="banner"
          width={handleDimensions().width}
          height={handleDimensions().height}
        />
        <Tabs
          activeTab="1"
          className="launchpad-tabs"
          ulClassName="launchpad-tabs-lists"
          activityClassName="launchpad-tabs-indicator"
          onClick={(event, tab) => setActiveTab(tab)}
        >
          <Tab title="Marketplace" className="launchpad-tabs-lists-title">
            <div className="launchpad-tabs-lists-grids">
              <div className="launchpad-tabs-lists-grid">
                <div className="marketplace-header">Trending Collections</div>
                <Grid
                  container
                  spacing={mobileDisplay ? 3 : 8}
                  className="marketplace-container"
                >
                  {isEmpty(collections) &&
                    range(4).map(m => (
                      <Grid
                        item
                        key={`GridItem_${m}`}
                        xs={6} sm={4} md={3} lg={2}
                        className="marketplace-box"
                      > 
                        <Skeletons
                          mobile={smallerDisplay}
                          limitTwo
                          image
                        />
                      </Grid>
                    ))}
                  {!isEmpty(collections) && 
                    collections.map((m, mIdx) => (
                      <Grid
                        item
                        key={`MarketplaceItem_${mIdx}_${m.id}`}
                        xs={6} sm={4} md={3} lg={2}
                        className="marketplace-box"
                        onClick={() => handleCollectionNavigate(m)}
                      >
                        <div className="marketplace-box-item">
                          <div
                            className="marketplace-image"
                            style={{ backgroundImage: `url(${m.imageUrl})` }}
                          />
                          <div className="marketplace-title">{m.name}</div>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </div>
              <div className="launchpad-tabs-lists-grid">
                <div className="marketplace-header">NFTs for Sale</div>
                <Grid container spacing={mobileDisplay ? 3 : 8} className="marketplace-container">
                  {isEmpty(listedCollections) && 
                    isRendering && 
                    range(4).map(m => (
                      <Grid
                        item
                        key={`GridItem_${m}`}
                        xs={6} sm={4} md={3} lg={2}
                        className="marketplace-box"
                      > 
                        <Skeletons
                          image
                          mobile
                          limitTwo
                          limitThree
                          limitFour
                          height={smallerDisplay ? 160 : 250}
                        />
                      </Grid>
                    ))}
                  {!isEmpty(listedCollections) && 
                    listedCollections.map((l, lIdx) => (
                      <Grid
                        item
                        key={`MarketplaceItem_${lIdx}_${l.id}`}
                        xs={6} sm={4} md={3} lg={2}
                        className="marketplace-box"
                        onClick={() => navigate(`/marketplace/${l.slug}/${l.id}`)}
                      >
                        {typeof l !== 'number' && 
                          <div className="marketplace-box-item" ref={ref}>
                            <div
                              className="marketplace-image"
                              style={{ backgroundImage: `url(${l.imageUrl})` }}
                            />
                            <div className="d-flex justify-content-center gap-1 flex-column mt-2">
                              <div className="marketplace-box-name">{l.collectionName}</div>
                              <div className="marketplace-box-title">{l.name}</div>
                              {l.nftListing &&
                                <div className="d-flex align-items-center gap-1">
                                  <GamePassProductAmount 
                                    {...l.nftListing} 
                                    isMarketplace 
                                  />
                                </div>}
                            </div>
                          </div>}
                        {typeof l === "number" && 
                          <Skeletons 
                            image 
                            mobile 
                            limitTwo 
                            limitThree 
                            limitFour 
                            height={smallerDisplay ? 160 : 250}
                          />}
                      </Grid>
                    ))}
                </Grid>
              </div>
              {!isRendering && 
                isEmpty(listedCollections) && 
                <div className="marketplace-container-box">There are no NFTs for sale yet.</div>}
            </div>
          </Tab>
          <Tab title="Launchpad" className="launchpad-tabs-lists-title mr-3">
            {collection.map(c => (
              <div key={c.id} className="launchpad-content">
                <div 
                  onClick={() => handleSelectedItem(c)}
                  className="launchpad">
                  <Grid 
                    container 
                    spacing={smallerDisplay ? 2 : 3} 
                    className="launchpad-wrapper"
                  >
                    <Grid 
                      item 
                      xs={4} sm={4} md={3} lg={3} 
                      className="launchpad-image-wrapper"
                    >
                      <img src={c.imageUrls[0].imageUrl} alt="item-icon" className="launchpad-image"></img>
                    </Grid>
                    <Grid 
                      item xs={8} 
                      sm={8} md={9} lg={9} 
                      className="launchpad-details-id"
                    >
                      <ArrowForwardIosRoundedIcon className="launchpad-details-next"/>
                      <div className="launchpad-details-name">{c.name}</div>
                      <div className="launchpad-details-desc">{c.description}</div>
                      <div className="launchpad-details-stats-wrapper">
                        {c.stats.map((s, sIdx) => {
                          const isFirstChild = sIdx === 0;
                          const isSecondChild = sIdx === 1;
                          let spacingValue = 5;
                          if (isSecondChild) spacingValue = 3;
                          if (isFirstChild) spacingValue = 4
                          if (!smallerDisplay) spacingValue = 4
                          return (
                            <Grid 
                              item 
                              xs={spacingValue} sm={4} md={4} lg={4} 
                              key={`Launchpad_Stats_${sIdx}`} 
                              className="launchpad-details-stats"
                            >
                              <div className="launchpad-details-stats-value">{s.value}</div>
                              <div className="launchpad-details-stats-label">{s.label}</div>
                            </Grid>
                          )
                        })}
                      </div>
                      <button 
                        onClick={() => handleSelectedItemDesktop(c)} 
                        className="launchpad-details-stats-button">
                        Visit Launchpad
                      </button>
                    </Grid>
                  </Grid>
                </div>    
              </div>
            ))}
          </Tab>
        </Tabs>
      </Section>
    </LayoutContent>
  )
}
