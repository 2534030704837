import { takeLatest, call, put, all } from 'redux-saga/effects';
import { requestQuests, requestQuestLeaderboards } from '../api/quests';
import { GET_QUESTS } from '../actions/constants';
import { setQuests, setQuestLeaderboards, setQuestFAQs, setLoading, failedRequests } from '../actions';
import { questFAQs, storageKeys } from '../constants';

const getQuestsSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestQuests, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield call(getQuestLeaderboardsSaga, response.data.d);
    } else {
      yield call(getQuestLeaderboardsSaga, []);
    }
  } catch (e) {
    yield call(getQuestLeaderboardsSaga, []);
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getQuestLeaderboardsSaga = function* (quests) {
  try {
    const url = 'https://beta.metaversego.gg/api/v1/leaderboard';
    const response = yield call(requestQuestLeaderboards, url);
    if (response.status >= 200 && response.status < 400) {
      yield put(setQuests(quests));
      yield put(setQuestLeaderboards(response.data.top));
      yield put(setQuestFAQs(questFAQs));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* questsSaga() {
  yield all([
    takeLatest(GET_QUESTS, getQuestsSaga),
  ]);
}
