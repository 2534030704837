import React, { useState } from 'react';
import classNames from 'classnames';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import './SpinTheWheelMechanics.scss';

export const Prize = ({ title, imageUrl, details, pesoValue, usdValue, quantity, description }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    if (details || description) {
      setCollapsed(!collapsed);
    }
  }

  return (
    <div className="stw-mechanics-prizes-item">
      <div className="stw-mechanics-prizes-item-inner">
        <img
          src={imageUrl}
          alt={title}
          className="stw-mechanics-prizes-item-image"
        />
        <div
          className={classNames(
            "stw-mechanics-prizes-item-title",
            {"stw-mechanics-prizes-item-title-collapsible": (details || description)}
          )}
          onClick={handleCollapse}
        >
          <div>{title}</div>
          {(details || description) && (
            collapsed ?
              <KeyboardArrowUpRoundedIcon /> :
              <KeyboardArrowDownRoundedIcon />
            )}
        </div>
      </div>
      {(details || description) &&
        collapsed &&
        <div>
          {description &&
          <div className="stw-mechanics-prizes-item-details">{description}</div>}
          {details &&
          <div className="d-flex flex-row">
            <div className="stw-mechanics-prizes-item-details-title">Details</div>
            <div className="stw-mechanics-prizes-item-details">{details}</div>
          </div>}
          {pesoValue &&
            <div className="d-flex flex-row">
              <div className="stw-mechanics-prizes-item-details-title">Peso Value</div>
              <div className="stw-mechanics-prizes-item-details">{pesoValue}</div>
            </div>}
          {usdValue &&
            <div className="d-flex flex-row">
              <div className="stw-mechanics-prizes-item-details-title">USD Value</div>
              <div className="stw-mechanics-prizes-item-details">{usdValue}</div>
            </div>}
          {quantity &&
            <div className="d-flex flex-row">
              <div className="stw-mechanics-prizes-item-details-title">Quantity</div>
              <div className="stw-mechanics-prizes-item-details">{quantity}</div>
            </div>}
        </div>
        }
    </div>
  );
}