import { takeLatest, call, put } from 'redux-saga/effects';
import { requestHomeCollection } from '../api/home';
import { requestGamePassProducts, requestGamePassTournaments } from '../api/gamepass';
import { GET_HOME_COLLECTION } from '../actions/constants';
import { setLoading, setHomeCollection, failedRequests } from '../actions';
import { 
  gameCenterBannerDetails,
  gamePassScreenSections,
  responseStatus,
  storageKeys
} from '../constants';

const getHomeCollectionSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestHomeCollection, sessionToken, 'v2');
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const homeSections = response.data.sections.map(s => {
        if (s.dataset.includes('Featured')) {
          s.d.splice(0, 0, gameCenterBannerDetails);
        }
        return s;
      });
      const collectionWithGamePassGamesSection = yield call(getPlayAndEarnGoldSectionSaga, homeSections);
      const updatedHomeCollection = yield call(getBuyGamePassSectionSaga, collectionWithGamePassGamesSection);
      yield put(setHomeCollection(updatedHomeCollection));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getBuyGamePassSectionSaga = function* (sections) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassProducts, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const buySection = {
        dataset: gamePassScreenSections.buyGamePass,
        experience: 'Game Pass',
        d: response.data.d
      };
      const datasets = sections.map(s => s.dataset);
      if (datasets.includes(gamePassScreenSections.playAndEarnGold)) {
        const gamePassGamesSectionIndex = datasets.indexOf(gamePassScreenSections.playAndEarnGold);
        sections.splice(gamePassGamesSectionIndex, 0, buySection);
      } else {
        sections.splice(1, 0, buySection);
      }
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
  return sections;
}

const getPlayAndEarnGoldSectionSaga = function* (sections) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassTournaments, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const tournaments = response.data.d;
      const playSection = {
        dataset: gamePassScreenSections.playAndEarnGold,
        experience: 'Game Pass',
        d: tournaments
      }
      sections.splice(1, 0, playSection);
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
  return sections;
}

export default function* homeSaga() {
  yield takeLatest(GET_HOME_COLLECTION, getHomeCollectionSaga);
}