import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  ErrorToast, 
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { payGamePassOnCheckoutOtp, payGamePassOnCheckoutResendOtp } from '../../actions';
import { 
  otpDefaultTimes,
  otpInputTitle, 
  otpVerificationText, 
  resendVerificationCodeText 
} from '../../constants';
import { useOtpTimer, useQuery } from '../../hooks';
import './GlobeOTP.scss';

export const GlobeOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [code, setCode] = useState('');

  const { minutes, seconds } = useOtpTimer({
    initialMinute: otpDefaultTimes.minutes, 
    initialSeconds: otpDefaultTimes.seconds
  });
  const query = useQuery();
  const method = query.get('method');
  const referenceId = query.get('referenceId');

  const handleChange = (e) => {
    setCode(e.target.value);
  }

  useEffect(() => {
    const payData = { 
      method, 
      referenceNumber: referenceId, 
      code 
    };
    if (code.length === 6) {
      dispatch(payGamePassOnCheckoutOtp(payData));
    }
  }, [dispatch, code])

  const handleResend = () => {
    const resendData = { method, referenceNumber: referenceId };
    dispatch(payGamePassOnCheckoutResendOtp(resendData));
  }

  const setResendCodeText = (minutes, seconds) => {
    return `Resend code in ${minutes?.toString().padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}`;
  }

  return (
    <LayoutContent outerClassName="globe-otp">
      <PageTitle title="OTP" />
      <Section
        backButtonRoute
        backRoute={() => navigate(-1)}
        sectionClassName="globe-otp-section"
        sectionHeaderClassName="globe-otp-section-header"
        title="Verification"
        titleCentered
        text="Back"
      > 
        <div className="globe-otp-container">
          <div className="globe-otp-wrapper">
            <div className="globe-otp-text">{otpVerificationText}</div>
            <div className="d-flex flex-column gap-2">
              <div className="globe-otp-input-title">{otpInputTitle}</div>
              <input 
                className="globe-otp-input"
                onChange={handleChange}
                value={code}
                autoFocus
                maxLength={6}
              />
            </div>
            {minutes === 0 && seconds === 0
              ? <div onClick={handleResend} className="globe-otp-resend-text">{resendVerificationCodeText}</div>
              : <div className="globe-otp-resend">{setResendCodeText(minutes, seconds)}</div>}
          </div>
        </div>
      </Section>
      <ErrorToast />
  </LayoutContent>
  )
}