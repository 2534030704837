import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { TournamentBannerInfoDesktop } from './TournamentBannerInfoDesktop';
import { TournamentBannerInfoMobile } from './TournamentBannerInfoMobile';
import { maxWidths } from '../../../constants';
import './TournamentBanner.scss';

const TournamentBanner = ({ title, id, imageUrl, onClick, ...props }) => {
  const mobile = useMediaQuery({ maxWidth: maxWidths.mobileSM });

  return (
    <div className="tournament-banner">
      <div className="tournament-banner-cover" style={{ backgroundImage: `url(${imageUrl})` }} />
      {title && <div className="tournament-banner-details">
        <div className="tournament-banner-description">
          {mobile ?
            <TournamentBannerInfoMobile {...props} title={title} /> :
            <TournamentBannerInfoDesktop {...props} title={title} />}
        </div>
      </div>}
    </div>
  );
}

export default TournamentBanner;