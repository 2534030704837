import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Section from '../Section/Section';
import {
  buyNFT,
  cancelListNFT,
  getListingFee,
  listNFT,
  setIsBuy,
  setIsCancelListNFT,
  setIsList,
  setTopUpVisible
} from '../../../actions';
import {
  buyNftText,
  cancelListNftText,
  collectiblesTexts,
  firebaseEvents,
  mgcCurrency,
  minWidths,
  sellDefaultValue
} from '../../../constants';
import { useDebounce } from '../../../hooks/useDebounce';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';

const CollectiblesModal = ({ 
  image, 
  name, 
  slug,
  cancelList, 
  setOpenModal, 
  isOwned, 
  collectionId,
  nftListing, 
  id, 
  collectionName,
  royalties,
  isBuy,
  listingFee,
  isList,
  neededFunds
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(Number(sellDefaultValue));
  const [title, setTitle] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [closeButtonText, setCloseButtonText] = useState('');

  const isCancelList = useSelector((state) => state.marketplace.isCancelList);
  const feeData = useSelector((state) => state.marketplace.listingFee);
  const feeIsLoading = useSelector((state) => state.marketplace.listingFeeLoading);
  const transactionId = useSelector((state) => state.marketplace.transactionId);
  const mgcVal = useSelector((state) => state.layout.userProfile.wallet.crypto[0].valLC);

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const debounceValue = useDebounce(value, 500);
  const isFromWallet = location.pathname.includes('wallet');

  const handleNavigate = () => {
    setOpenModal(false);
    dispatch(setIsBuy(false));
    dispatch(setIsList(false));
    dispatch(setIsCancelListNFT(false));
  }

  useEffect(() => {
    dispatch(setIsCancelListNFT(false));
  }, [dispatch, isCancelList])

  const handleClick = () => {
    if (neededFunds > 0 && !isList && isOwned) {
      if (!isDesktop) return navigate(`/topup?value=${neededFunds}`);
      navigate(`/wallet?value=${neededFunds}`);
      dispatch(setTopUpVisible(true));
      return;
    }
    const listData = {
      collectionId: collectionId.toString(),
      nftId: id.toString(),
      price: value.toString(),
      slug
    }
    const data = {
      collectionId: collectionId.toString(),
      nftId: id.toString(),
      price: !isEmpty(nftListing) && nftListing.price,
      slug
    }
    if (isOwned) return dispatch(listNFT(listData));
    if (cancelList) return dispatch(cancelListNFT(data));
    dispatch(buyNFT(data));
  } 

  const handleRedirect = () => {
    if (isList) {
      window.location.href = `/marketplace/${slug}`;
      return;
    };
    window.location.href = `/wallet/${id}`;
  }

  useEffect(() => {
    if (debounceValue.length === 0) return;
    if (isOwned) {
      const feeData = {
        nftId: id.toString(),
        price: debounceValue.toString()
      }
      dispatch(getListingFee(feeData));
    }
  }, [debounceValue, dispatch, id])

  const handleTitle = useCallback(() => {
    if (isList || isBuy) return setTitle('');
    if (cancelList) return setTitle(collectiblesTexts.title.cancel);
    if (isOwned) return setTitle(collectiblesTexts.title.sell);
    return setTitle(collectiblesTexts.title.confirm);
  }, [isList, isBuy, cancelList, isOwned])

  const handleButtonText = useCallback(() => {
    let defaultButtonText = collectiblesTexts.ctaButton.pay;
    if (neededFunds > 0) defaultButtonText = collectiblesTexts.ctaButton.insufficientFunds;
    if (isBuy) return setButtonText(collectiblesTexts.ctaButton.viewNft);
    if (isList) return setButtonText(collectiblesTexts.ctaButton.viewInMarketplace);
    if (isOwned) return setButtonText(collectiblesTexts.ctaButton.sell);
    if (cancelList) {
      let buttonTextSuffix = collectiblesTexts.ctaButton.cancel;
      if (isFromWallet) buttonTextSuffix = collectiblesTexts.ctaButton.proceed;
      const cancelListButtonText = `Yes, ${buttonTextSuffix}`
      return setButtonText(cancelListButtonText);
    }
    return setButtonText(defaultButtonText);
  }, [isList, isBuy, cancelList, isOwned, isFromWallet, neededFunds])

  const handleCloseButtonText = useCallback(() => {
    if (isList) return setCloseButtonText(collectiblesTexts.closeButton.close);
    if (cancelList) return setCloseButtonText(collectiblesTexts.closeButton.noNotYet);
    return setCloseButtonText(collectiblesTexts.closeButton.cancel);
  }, [isList, cancelList])

  useEffect(() => {
    handleTitle();
    handleButtonText();
    handleCloseButtonText();
  }, [isOwned, isBuy, handleTitle, handleButtonText, handleCloseButtonText])

  useEffect(() => {
    let event = '';
    let eventParams = {};
    if (!isBuy &&
      !isList &&
      !isOwned &&
      !cancelList) {
      event = firebaseEvents.nftMarketplaceBuyForConfirmation;
      eventParams = { payment_method: mgcCurrency };
    }
    if (isList &&
      !isOwned) {
      event = firebaseEvents.nftMarketplaceSell;
      eventParams = {
        currency: mgcCurrency
      };
      if (!isEmpty(nftListing)) {
        eventParams.amount = nftListing.price;
      }
    }
    if (event !== '') {
      logFirebaseEventWithTimestamp(event, eventParams);
    }
  }, [isBuy, isList, isOwned, cancelList])

  return (
    <Section 
      backButtonRoute={(!isList && isOwned) && (!isBuy)} 
      backRoute={handleNavigate} 
      title={title} 
      titleMedium
      sectionClassName={classNames(
      "collectibles-section-modal", 
      {"collectibles-section-modal-mobile": !isDesktop && isList})}
    >
      {isList && <div className="collectibles-section-modal-success">
        <div className="collectibles-section-modal-success-wrapper">
          <div className="collectibles-section-modal-success-icon"/>
          <div className="collectibles-section-modal-success-text">Listed Successfully</div>
        </div>
      </div>}
      {!isBuy && <div className={classNames(
        "collectibles-section-modal-container", 
        {"collectibles-section-modal-container-hide": !isDesktop && isList})}>
        <div className="collectibles-section-modal-box">
          <img src={image} alt="nft" className="collectibles-section-modal-image w-100"/>
          <div className="collectibles-section-modal-text">
            <div className="collectibles-section-modal-title">{collectionName}</div>
            <div className="collectibles-section-modal-name">{name}</div>
            {
              (isList || cancelList) && 
              nftListing && 
              <div className="d-flex align-items-center gap-2">
                <div className="collectibles-section-modal-mgc"></div>
                <div className="collectibles-section-modal-mgc-text">{nftListing.priceLC}</div>
              </div>
            }
          </div>
        </div>
        {
          neededFunds > 0 && 
          !isList && 
          !isOwned && 
          !cancelList && 
          <div className="collectibles-section-modal-details-balance">
            <div className="collectibles-section-modal-details-balance-text">Wallet Balance</div>
            <div className="collectibles-section-modal-details-balance-value">{mgcVal}</div>
          </div>
        }
        {
          !isList && 
          !isOwned && 
          !cancelList && 
          <div className="collectibles-section-modal-details-wrapper-buy">
            {buyNftText.map((m, mIdx) => (
              <div className="collectibles-section-modal-details" key={mIdx}>{m}</div>
            ))}
          </div>
        }
        {
          cancelList && 
          !isList && 
          <div className={classNames(
            "collectibles-section-modal-details-cancel", 
            {"collectibles-section-modal-details-cancel-hide": isFromWallet})}>{cancelListNftText}
          </div>
        }
      </div>}
      {isBuy && <div className="collectibles-section-modal-buy">
        <div className="collectibles-section-modal-buy-success mb-3"/>
        <div className="collectibles-section-modal-buy-title">Purchase Successful!</div>
        <div className="collectibles-section-modal-buy-subtitle">Transaction Ref. No {transactionId}</div>
      </div>}
      {
        isOwned && 
        !isList && 
        !isBuy && 
        <div className="collectibles-section-modal-details-container">
          <Row className="collectibles-section-modal-details-wrapper gx-0">
            <Col sm={12} md={6} lg={6} className="collectibles-section-modal-details-wrapper-column justify-content-start p-0">
              <div className="collectibles-section-modal-details-wrapper-text-price">List Price</div>
            </Col>
            <Col sm={12} md={6} lg={6} className="collectibles-section-modal-details-wrapper-column justify-content-end p-0">
              <div className="collectibles-section-modal-details-wrapper-input-wrapper">
                <input 
                  className="collectibles-section-modal-details-wrapper-input"
                  autoFocus
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyPress={(event) => {
                    if (!/^\d*\.?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} 
                />
                <div className="collectibles-section-modal-details-wrapper-input-mgc">
                  <div className="collectibles-section-modal-details-wrapper-input-mgc-icon"/>
                  <div className="collectibles-section-modal-details-wrapper-input-mgc-text">{mgcCurrency}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      }
      {
        isOwned && 
        !isList && 
        !isBuy &&
        <div className="collectibles-section-modal-details-wrapper-fees">
          <Row className="gx-0">
            <Col className="collectibles-section-modal-details-wrapper-text justify-content-start p-0">Service Fee</Col>
            <Col className="collectibles-section-modal-details-wrapper-value justify-content-end p-0">
                <div className="collectibles-section-modal-details-wrapper-value-box-mgc-text">{listingFee}</div>
            </Col>
          </Row>
          <Row className="gx-0">
            <Col className="collectibles-section-modal-details-wrapper-text justify-content-start p-0">Creator Fee</Col>
            <Col className="collectibles-section-modal-details-wrapper-value justify-content-end p-0">
              <div className="collectibles-section-modal-details-wrapper-value-box-mgc-text">{royalties}</div>
            </Col>
          </Row>
          <Row className="gx-0">
            <Col className="collectibles-section-modal-details-wrapper-text justify-content-start p-0">You Receive</Col>
            <Col className="collectibles-section-modal-details-wrapper-value justify-content-end p-0">
              <div className="collectibles-section-modal-details-wrapper-value-box">
                <div className="collectibles-section-modal-details-wrapper-value-box-mgc"/>
                <div className="collectibles-section-modal-details-wrapper-value-box-mgc-text">{feeData.total && feeData.total.text}</div>
              </div>
            </Col>
          </Row>
        </div>
      }
      <div 
        className={classNames(
          "collectibles-section-modal-button-wrapper", 
          {"collectibles-section-modal-button-wrapper-hide": !isDesktop && isList})}
      >
        {
          !isBuy && 
          !isList && 
          <button 
            onClick={handleClick} 
            className={classNames(
              "collectibles-section-modal-button", 
              {"collectibles-section-modal-button-disabled": feeIsLoading})}
          >
            <div className="collectibles-section-modal-button-text"> 
              <div>{buttonText}</div>
              {
                !isOwned && 
                !isList && 
                !cancelList && 
                !isBuy && 
                <div className="d-flex align-items-center gap-1">
                  <div className="collectibles-section-modal-button-mgc ms-1"/>
                  <div className="collectibles-section-modal-button-mgc-text">
                    {!isEmpty(nftListing) && 
                      neededFunds > 0 ? `${mgcCurrency} ${neededFunds}`: nftListing?.priceLC}
                  </div>
                </div>
              }
            </div>
          </button>
        }
        {
          (isBuy || isList) && 
          <button 
            onClick={handleRedirect} 
            className="collectibles-section-modal-button"
          >
            <div className="collectibles-section-modal-button-text"> 
              <div>{buttonText}</div>
            </div>
          </button>
        }
        <button 
          onClick={handleNavigate} 
          className={classNames(
            "collectibles-section-modal-button-second", 
            {"collectibles-section-modal-button-second-buy": isBuy})}
        >
          {closeButtonText}
        </button>
      </div>
    </Section>
  )
}

export default CollectiblesModal;