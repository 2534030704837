import { takeLatest, call, put, all, select, delay } from 'redux-saga/effects';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {
  requestUserProfileFields,
  requestTransactions,
  requestEditUserProfile,
  requestUploadProfilePhoto,
  requestSetNewPIN,
  requestValidatePIN
} from '../api/profile';
import { requestTutorialScreens } from '../api/tutorial';
import {
  GET_USER_PROFILE_FIELDS,
  EDIT_USER_PROFILE,
  UPDATE_USER_PROFILE_PHOTO,
  REMOVE_USER_PROFILE_PHOTO,
  GET_TRANSACTIONS,
  GET_MORE_TRANSACTIONS,
  VALIDATE_PIN,
  GET_PROFILE_TUTORIAL
} from '../actions/constants';
import {
  setLoading,
  setUserProfile,
  setUserProfileFields,
  setDisplayToast,
  setTransactions,
  setProfileTutorial,
  failedRequests
} from '../actions';
import {
  transactionsSelector,
  userProfileSelector,
  userProfileFieldsSelector
} from '../selectors';
import {
  defaultTimeoutMS,
  loginTexts,
  pinSuccess,
  storageKeys
} from '../constants';
import defaultProfilePhoto from '../assets/profile/profile-pic.png';

const getUserProfileFieldsSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserProfileFields, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const responseData = response.data.d.profile;
      const fields = { form: responseData.form, imageUrl: responseData.imageUrl };
      yield put(setUserProfileFields(fields));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const updateUserProfilePhotoSaga = function* (payload) {
  try {
    const fields = yield select(userProfileFieldsSelector);
    const formData = new FormData();
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    formData.append('photo', payload.imageFile);
    const response = yield call(requestUploadProfilePhoto, formData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const updatedFields = { ...fields, imageUrl: response.data.imageUrl };
      yield put(setUserProfileFields(updatedFields));
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const removeUserProfilePhotoSaga = function* () {
  try {
    const fields = yield select(userProfileFieldsSelector);
    const updatedFields = { ...fields, imageUrl: defaultProfilePhoto };
    yield put(setUserProfileFields(updatedFields));
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const editUserProfileSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestEditUserProfile, payload.userProfileValues, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const toast = {
        result: true,
        message: 'Profile updated successfully'
      };

      const responseData = response.data.d.profile;
      const fields = { form: responseData.form, imageUrl: responseData.imageUrl };

      const personalInfo = responseData.form.find(f => f.fields.map(ff => ff.field).includes('username'));
      const username = personalInfo.fields.find(f => f.field === 'username').value;
      const profile = yield select(userProfileSelector);
      profile.username = username;
      profile.profileHeader = { ...profile.profileHeader, username, imageUrl: responseData.imageUrl };
      profile.showTutorial = response.data.d.showTutorial;
      
      yield put(setUserProfileFields(fields));
      yield put(setUserProfile(profile));
      yield put(setDisplayToast(toast));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getTransactionsSaga = function* () {
  try {
    const url = 'https://run.mocky.io/v3/6800a26b-b99d-4f58-823c-b7a07e4c4863';
    const response = yield call(requestTransactions, url);
    if (response.status >= 200 && response.status < 400) {
      const { dataset, pages, page, d } = response.data;
      const transactions = { dataset, pages, page: page + 1, list: d.transactions };
      yield put(setTransactions(transactions));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getMoreTransactionsSaga = function* (payload) {
  try {
    const transactions = yield select(transactionsSelector);
    const url = 'https://run.mocky.io/v3/278300ae-838e-4654-b77e-36b481cc4112';
    const response = yield call(requestTransactions, url);
    if (response.status >= 200 && response.status < 400) {
      const { page, d } = response.data;
      const updatedTransactionList = [].concat(transactions.list, d.transactions);
      const newTransactions = { ...transactions, page: page + 1, list: updatedTransactionList };
      yield put(setTransactions(newTransactions));
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const validatePINSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const payloadData = { pin: payload.currentPIN };
    const response = yield call(requestValidatePIN, payloadData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      localStorage.setItem(storageKeys.sessionToken, response.data.token);
      const auth = getAuth();
      signInWithCustomToken(auth, response.data.token)
        .then(() => {
          localStorage.setItem(storageKeys.newPIN, payload.newPIN);
        })
        .catch((error) => {
          let toastTitle = loginTexts.pin.error.title;
          let toastMessage = loginTexts.pin.error.message;
          if (error.code && error.message) {
            toastTitle = error.code;
            toastMessage = error.message;
          }
          const toast = {
            result: false,
            title: toastTitle,
            message: toastMessage
          };
          localStorage.setItem(storageKeys.firebaseAuthErrorMessage, JSON.stringify(toast));
        });
      yield delay(defaultTimeoutMS);
      if (localStorage.getItem(storageKeys.firebaseAuthErrorMessage) && localStorage.getItem(storageKeys.firebaseAuthErrorMessage) !== '') {
        const firebaseAuthErrorMessage = JSON.parse(localStorage.getItem(storageKeys.firebaseAuthErrorMessage));
        yield put(setLoading(false));
        yield put(setDisplayToast(firebaseAuthErrorMessage));
        localStorage.removeItem(storageKeys.firebaseAuthErrorMessage);
      }
      yield delay(defaultTimeoutMS);
      if (localStorage.getItem(storageKeys.newPIN) && localStorage.getItem(storageKeys.newPIN) !== '') {
        yield call(resetPINSaga, localStorage.getItem(storageKeys.newPIN));
      }
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const resetPINSaga = function* (newPIN) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const payloadData = { pin: newPIN };
    const response = yield call(requestSetNewPIN, payloadData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const toast = {
        result: true,
        message: pinSuccess
      };
      yield put(setDisplayToast(toast));
    }
    localStorage.removeItem(storageKeys.newPIN);
    yield put(setLoading(false));
  } catch (e) {
    localStorage.removeItem(storageKeys.newPIN);
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getProfileTutorialSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestTutorialScreens, 'profile', sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setProfileTutorial(response.data.result));
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* tutorialSaga() {
  yield all([
    takeLatest(GET_USER_PROFILE_FIELDS, getUserProfileFieldsSaga),
    takeLatest(EDIT_USER_PROFILE, editUserProfileSaga),
    takeLatest(UPDATE_USER_PROFILE_PHOTO, updateUserProfilePhotoSaga),
    takeLatest(REMOVE_USER_PROFILE_PHOTO, removeUserProfilePhotoSaga),
    takeLatest(GET_TRANSACTIONS, getTransactionsSaga),
    takeLatest(GET_MORE_TRANSACTIONS, getMoreTransactionsSaga),
    takeLatest(VALIDATE_PIN, validatePINSaga),
    takeLatest(GET_PROFILE_TUTORIAL, getProfileTutorialSaga)
  ]);
}
