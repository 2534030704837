import React from 'react';
import { GridStats } from '../GridStats/GridStats';

export const TournamentBannerInfoMobile = ({ title, prizes, stats, buttons, children }) => (
  <div className="tournament-banner-info">
    <div>
      <div className="tournament-banner-text">
        <h1 className="tournament-banner-title">{title}</h1>
        {(prizes || stats) && <div className="tournament-banner-prizes-stats">
          <div className="tournament-banner-prizes-stats-inner">
            <GridStats stats={stats} className="tournament-banner" isInDetails />
          </div>
        </div>}
      </div>
      {buttons}
    </div>
    {children}
  </div>
);