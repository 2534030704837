import Lottie from 'lottie-react';
import React from 'react'
import loader from '../Commons/Loader/loader.json';
import './TopUpPayment.scss';

export const TopUpPaymentLoading = () => (
  <div className="payment-loader">
    <div className="payment-loader-wrapper">
      <Lottie animationData={loader} autoPlay loop className="payment-loader-animation"></Lottie>
      <div className="payment-loader-text">Redirecting you to payment page</div>
    </div>
  </div>
);