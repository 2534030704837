import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import {
  authSteps,
  firebaseEvents,
  gamePassCheckoutCTA,
  gamePassGamesCTA,
  gamePassMechanicsCTA,
  maxWidths,
  singleQuantityAddToCart,
  wenLamboText
} from '../../constants';
import {
  addGamePassToCart,
  showGamePassCheckout,
  unauthorizedRedirect
} from '../../actions';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './GamePassScreen.scss';

export const GamePassScreenBanner = ({ logo, bg, isGuest, selectedItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRoute = (route) => {
    dispatch(
      unauthorizedRedirect({ 
        isGuest, 
        authSteps: authSteps.login,
        route
      })
    );
  }

  const handleBuyNow = () => {
    if (selectedItem) {
      const addToCartData = {
        productId: selectedItem.productId,
        skuId: selectedItem.id,
        quantity: singleQuantityAddToCart,
        isCheckout: true,
        redirectLink: gamePassCheckoutCTA,
        requestCheckout: true,
        navigate
      };
      if (isGuest) {
        dispatch(
          unauthorizedRedirect({ 
            isGuest, 
            authSteps: authSteps.login
          })
        );
        return;
      }
      dispatch(addGamePassToCart(addToCartData));
      let event = firebaseEvents.homeGamePassBuy;
      if (window.location.pathname.toLowerCase().includes('gamepass')) {
        event = firebaseEvents.wenLamboMainBuy;
      }
      logFirebaseEventWithTimestamp(
        event, {
          sku_name: selectedItem.shortName,
          page_url: window.location.pathname
        });
    }
  }

  const handleBannerCheckout = () => {
    if (isGuest) {
      dispatch(showGamePassCheckout(true));
    } else {
      handleBuyNow();
    }
  }

  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileSM });

  let wenLamboLogo = null;
  if (isMobile) {
    if (!isEmpty(logo.mobile)) wenLamboLogo = logo.mobile;
  } else {
    if (!isEmpty(logo.desktop)) wenLamboLogo = logo.desktop;
  }

  let wenLamboBG = null;
  if (isMobile) {
    if (!isEmpty(bg.mobile)) wenLamboBG = bg.mobile;
  } else {
    if (!isEmpty(bg.desktop)) wenLamboBG = bg.desktop;
  }

  let wenLamboBGStyle = {};
  if (wenLamboBG) {
    wenLamboBGStyle = { backgroundImage: `url(${wenLamboBG})` };
  }
  
  return (
    <div
      className={classNames(
        "game-pass-screen-banner",
        {"game-pass-screen-banner-guest": isGuest}
      )}
      style={wenLamboBGStyle}
    >
      <div className="game-pass-screen-banner-content">
        {wenLamboLogo &&
          <img
            src={wenLamboLogo}
            alt={wenLamboText}
            onClick={handleBannerCheckout}
            className="game-pass-screen-banner-logo"
          />}
        <div
          onClick={() => handleRoute(gamePassGamesCTA)}
          className="game-pass-screen-banner-button"
        >
          Start Playing
        </div>
        <div
          onClick={() => navigate(gamePassMechanicsCTA)}
          className="game-pass-screen-banner-view-mechanics"
        >
          View Mechanics
          <KeyboardArrowRightRoundedIcon />
        </div>
      </div>
    </div>
  );
}