export const notEnoughMGCText = `You don't have enough MGC to complete this transaction.`;

export const texts = {
  title: 'Verification',
  description: 'Please enter 6 digit verification code sent to your number',
  network: 'Network',
  destinationWalletAddress: 'Destination Wallet Address',
  gasFee: 'Gas Fee',
  withdrawNFT: 'Withdraw NFT',
  qrScan: 'QR Scan',
  uploadQR: 'Upload QR',
  notEnoughMGC: 'Not Enough MGC',
  topUpMGC: 'Top Up MGC',
  nftName: 'NFT Name',
  viewFullAddress: 'View Full Address',
  withdrawalTransferFees: 'Withdrawal Transfer Fees',
  confirmWithdrawal: 'Confirm Withdrawal',
  confirmationDescription: 'Are you sure you want to withdraw this NFT? This may take up to 24 hours to process.',
  confirm: 'Confirm',
  cancel: 'Cancel'

};
