import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';
import { Popup } from '../../Commons';
import { setGamePassPlayGame } from '../../../actions';
import {
  buyGamePassCTA,
  firebaseEvents,
  gamePassToast,
  minWidths,
  storageKeys
} from '../../../constants';
import { gamePassPlayGameSelector } from '../../../selectors';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';
import './GamePassPlayPopup.scss';

const GamePassPlayPopup = ({ id, slug, gameName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const playGame = useSelector(state => gamePassPlayGameSelector(state));
  
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const tournamentToken = Cookies.get(storageKeys.tournamentToken);
  
  const handlePlayGame = () => {
    if (tournamentToken) Cookies.remove(storageKeys.tournamentToken);
    logFirebaseEventWithTimestamp(
      firebaseEvents.wenLamboMainPlayNoGamePass, {
        game_name: gameName
      });
    navigate(`/play/${slug}/${id}`);
    dispatch(setGamePassPlayGame(false));
  }

  return (
    <Popup 
      open={playGame} 
      hide={() => dispatch(setGamePassPlayGame(false))} 
      className="gamepass-game-toast" 
      header={!isDesktop}
      headerTitle="Activate Game Pass"
      centered={isDesktop}
    >
      {
        isDesktop && 
        <h1 className="gamepass-game-toast-title">Activate Game Pass</h1>
      }
      <p className="gamepass-game-toast-text">{gamePassToast.description}</p>
      <div className='gamepass-game-toast-button-container'>
        <button 
          className="gamepass-game-toast-buy-button" 
          onClick={() => window.location.href = buyGamePassCTA}
        >
          {gamePassToast.buttonTwo}
        </button>
        <button 
          className="gamepass-game-toast-button" 
          onClick={handlePlayGame}
        >
          {gamePassToast.buttonOne}
        </button>
      </div>
    </Popup>
  )
}

export default GamePassPlayPopup;
