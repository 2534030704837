import { RESET_STATE, SET_HOME_COLLECTION } from '../actions/constants';

const homeCollectionState = [];

const homeReducer = (state = homeCollectionState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return homeCollectionState;
    case SET_HOME_COLLECTION:
      return action.homeCollection;
    default:
      return state;
  }
}

export default homeReducer;