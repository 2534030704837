import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { HorizontalScroll } from '../Commons';
import {
  AddToCartToast,
  GamePassProductAmount,
  Section
} from '../Components';
import { addGamePassToCart, unauthorizedRedirect } from '../../actions';
import {
  cartTitles,
  firebaseEvents,
  singleQuantityAddToCart,
  gamePassCheckoutCTA,
  minWidths,
  maxWidths,
  authSteps,
  gamePassBadgeTexts
} from '../../constants';
import { guestSelector } from '../../selectors';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import cart from '../../assets/gamepass/icons/cart.png';
import cartNew from '../../assets/gamepass/icons/cart-new.png';
import '../Components/Section/Section.scss';

export const BuyGamePassSection = ({
  title,
  list,
  link,
  linkText,
  sectionClassName
}) => {
  const isGuest = useSelector(state => guestSelector(state));

  const sm = useMediaQuery({ minWidth: minWidths.mobileSM, maxWidth: maxWidths.mobileSM });
  const md = useMediaQuery({ minWidth: minWidths.tablet, maxWidth: maxWidths.tablet });
  const lg = useMediaQuery({ minWidth: minWidths.desktopSM, maxWidth: maxWidths.desktopLG });
  const xl = useMediaQuery({ minWidth: minWidths.desktopXL, maxWidth: maxWidths.desktopXXL });
  const xxxl = useMediaQuery({ minWidth: minWidths.desktopXXXL });

  let slidesToShow = 2;
  if (sm) slidesToShow = 3;
  else if (md) slidesToShow = 4;
  else if (lg) slidesToShow = 5;
  else if (xl) slidesToShow = 6;
  else if (xxxl) slidesToShow = 7;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openAddToCart, setOpenAddToCart] = useState(false);
  const [selectedSku, setSelectedSku] = useState({});
  const [productId, setProductId] = useState('');

  const handleSelectedProduct = (item) => {
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    navigate(`/gamepass/details?productId=${item.productId}&skuId=${item.id}`);
  }

  const handleBuyNow = (e, item) => {
    e.stopPropagation();
    const addToCartData = {
      productId: item.productId,
      skuId: item.id,
      quantity: singleQuantityAddToCart,
      isCheckout: true,
      redirectLink: gamePassCheckoutCTA,
      requestCheckout: true,
      navigate
    };
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    dispatch(addGamePassToCart(addToCartData));
    let event = firebaseEvents.homeGamePassBuy;
    if (window.location.pathname.toLowerCase().includes('gamepass')) {
      event = firebaseEvents.wenLamboMainBuy;
    }
    logFirebaseEventWithTimestamp(
      event, {
        sku_name: item.shortName,
        page_url: window.location.pathname
      });
  }

  const onAddToCart = (e, item) => {
    e.stopPropagation();
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    setProductId(item.productId);
    setSelectedSku(item);
    setOpenAddToCart(true);
  }

  const handleRoute = (route) => {
    dispatch(
      unauthorizedRedirect({ 
        isGuest, 
        authSteps: authSteps.login,
        route
      })
    );
    return;
  }

  const handleCartButton = (isNew) => {
    const cartButton = isNew ? cartNew : cart;
    return cartButton;
  }

  return (
    <Section 
      title={title} 
      link={link} 
      linkText={linkText} 
      sectionClassName={sectionClassName} 
      handleRoute={handleRoute}
    >
      <div className="section-list">
        <HorizontalScroll slidesToShow={slidesToShow}>
          {list.map(l => (
            <div 
              key={`${title}_${l.name}`} 
              className="section-list-item"
              onClick={() => handleSelectedProduct(l)}
            >
              <div className={classNames(
                "section-list-item-link",
                "game-pass-screen-buy",
                {"game-pass-screen-buy-new": l.isNew}
              )}>
                {l.isRecommended && (
                  l.isNew ?
                    <div className="game-pass-screen-buy-new-badge">{gamePassBadgeTexts.new}</div> : 
                    <div className="game-pass-screen-buy-recommended-badge">{gamePassBadgeTexts.recommended}</div>)}
                <div className={classNames(
                  "thumbnail-details-wrapper",
                  "game-pass-screen-buy-wrapper",
                  {"game-pass-screen-buy-wrapper-recommended": l.isRecommended}
                )}>
                  <div className="game-pass-screen-buy-card-wrapper">
                    <img
                      className={classNames(
                        "game-pass-screen-buy-card",
                        {"game-pass-screen-buy-card-recommended": l.isRecommended}
                      )}
                      src={l.imageUrls.imageUrl}
                      alt={l.shortName}
                      loading="lazy"
                    />
                  </div>
                  <div className={classNames(
                    "thumbnail-details-wrapper-details",
                    "game-pass-screen-thumbnail-details"
                  )}>
                    <div className="thumbnail-details-wrapper-name">{l.shortName}</div>
                    <GamePassProductAmount {...l} />
                  </div>
                </div>
                <div className="game-pass-screen-buy-buttons">
                  <button
                    onClick={(e) => handleBuyNow(e, l)}
                    className={classNames(
                      "game-pass-screen-buy-button",
                      {"game-pass-screen-buy-button-new": l.isNew}
                    )}
                  >
                    {cartTitles.buyNow}
                  </button>
                  <img
                    src={handleCartButton(l.isNew)}
                    alt="cart"
                    className="buy-gamepass-content-button-cart"
                    onClick={(e) => onAddToCart(e, l)}
                  />
                </div>
              </div>
            </div>
          ))}
        </HorizontalScroll>
      </div>
      <AddToCartToast
        open={openAddToCart} 
        hide={() => setOpenAddToCart(false)} 
        sku={selectedSku}
        productId={productId}
      />
    </Section>
  );
}