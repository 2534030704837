import { put, takeLatest, call, all } from 'redux-saga/effects';
import { requestHomeCollection } from '../api/home';
import {
  failedRequests,
  setLoading,
  setTournaments
} from '../actions';
import { GET_TOURNAMENTS } from '../actions/constants';
import { storageKeys } from '../constants';

const getTournamentsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestHomeCollection, sessionToken, 'v2');
    if (response.status >= 200 && response.status < 400) {
      const tournaments = response.data.sections.find(s => s.dataset.toLowerCase().includes(payload.tournamentType));
      yield put(setTournaments(tournaments));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* tournamentsSaga() {
  yield all([
    takeLatest(GET_TOURNAMENTS, getTournamentsSaga)
  ]);
}