import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Slide, Snackbar } from '@mui/material';
import { duration } from '../../../constants';
import check from '../../../assets/gamepass/icons/check.png';
import './Snackbars.scss';

export const Snackbars = ({ 
  flag, 
  hide, 
  title, 
  handleCartRedirect, 
  isGamePass,
  noMargin
}) => {
  const [transition, setTransition] = useState();
  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  useEffect(() => {
    if (flag) {
      setTransition(() => TransitionLeft);
    }
  }, [flag])

  return (
    <Snackbar
      open={flag}
      anchorOrigin={{ 
        vertical: "top",
        horizontal: "right" 
      }}
      onClose={hide}
      autoHideDuration={duration.twoSeconds}
      className={classNames(
        "snackbar", {
          "snackbar-gamepass": isGamePass, 
          "snackbar-top": noMargin
        }
      )}
      TransitionComponent={transition}
      key={transition ? transition.name : ''}
    >
      <div className="snackbar-wrapper">
        <div className="d-flex align-items-center gap-1">
          {isGamePass && <img className="snackbar-check" src={check} alt="check" />}
          <div className="snackbar-text">{title}</div>
        </div>
        {handleCartRedirect && 
          <div onClick={handleCartRedirect} className="snackbar-button">
            View cart
          </div>}
      </div>
    </Snackbar>
  )
}
