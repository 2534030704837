import { call, all } from 'redux-saga/effects';
import login from './login';
import pin from './pin';
import menu from './menu';
import home from './home';
import games from './games';
import nft from './nft';
import launchpad from './launchpad';
import marketplace from './marketplace';
import collections from './collections';
import communities from './communities';
import spend from './spend';
import chat from './chat';
import tutorial from './tutorial';
import profile from './profile';
import quests from './quests';
import redeem from './redeem'
import spinTheWheel from './spinTheWheel'
import topup from './topup';
import tournaments from './tournaments';
import rewards from './rewards';
import gamepass from './gamepass';
import referral from './referral';
import withdrawal from './withdrawal';
import flags from './flags';
import cart from './cart';
import checkout from './checkout';
import transactions from './transactions';
import scholarship from './scholarship';
import banners from './banners';
import vouchers from './vouchers';
import global from './global';
import crypto from './crypto';

const rootSaga = function* () {
  yield all([
    call(login),
    call(pin),
    call(menu),
    call(home),
    call(games),
    call(nft),
    call(launchpad),
    call(marketplace),
    call(collections),
    call(communities),
    call(spend),
    call(chat),
    call(tutorial),
    call(profile),
    call(quests),
    call(redeem),
    call(spinTheWheel),
    call(topup),
    call(tournaments),
    call(rewards),
    call(gamepass),
    call(referral),
    call(withdrawal),
    call(flags),
    call(cart),
    call(checkout),
    call(transactions),
    call(scholarship),
    call(banners),
    call(vouchers),
    call(global),
    call(crypto)
  ]);
}

export default rootSaga;