import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import {
  FloatingIconManager,
  GamePassPlayPopup,
  LayoutContent,
  PageTitle,
  SectionList
} from '../Components';
import { BackArrow } from '../Commons';
import { FeaturedGames } from './FeaturedGames';
import {
  getHomeCollection,
  getHomePageBanners
} from '../../actions';
import {
  homeCollectionSelector,
  homePageFeaturedBannerSelector
} from '../../selectors';
import {
  defaultCTATexts,
  firebaseEvents,
  gamePassScreenSections,
  homeFeaturedBanners,
  minWidths,
  pages
} from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './GameCenter.scss';

export const GameCenter = () => {
  const dispatch = useDispatch();

  const collection = useSelector(state => homeCollectionSelector(state));
  const wenLamboFeaturedBanner = useSelector(state => homePageFeaturedBannerSelector(state));

  const [featured, setFeatured] = useState([]);
  const [games, setGames] = useState([]);
  const [gameDetails, setGameDetails] = useState({});

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const backButtonText = isDesktop ? defaultCTATexts.back : '';

  useEffect(() => {
    dispatch(getHomePageBanners());
  }, [dispatch]);

  useEffect(() => {
    logFirebaseEventWithTimestamp(firebaseEvents.gamesMain);
  }, []);

  useEffect(() => {
    if (!collection ||
      (collection && isEmpty(collection))) {
      dispatch(getHomeCollection());
    }
  }, [dispatch, collection]);

  useEffect(() => {
    if (collection && !isEmpty(collection)) {
      const featuredSection = collection.find(s => s.dataset.includes('Featured Games'));
      const games = collection.filter(c => {
        return c.dataset === gamePassScreenSections.playAndEarnGold ||
          (c.dataset.includes('Games') && !c.dataset.includes('Featured'));
      }).map(s => s);
      
      const lastIndex = featuredSection.d.length - 1;
      swapElements(featuredSection.d, 1, lastIndex);
      setFeatured(featuredSection.d);
      setGames(games);
    }
  }, [dispatch, collection]);

  const swapElements = (array, index1, index2) => {
    array[index1] = array.splice(index2, 1, array[index1])[0];
  };

  return (
    <>
      <LayoutContent outerClassName="game-center" innerClassName="game-center-inner">
        <PageTitle title={pages.gameCenter} />
        <div className="game-center-featured">
          <BackArrow text={backButtonText} isInline />
          {!isEmpty(featured) &&
            <FeaturedGames
              featured={featured}
              wenLamboBanner={wenLamboFeaturedBanner}
            />}
        </div>
        {games &&
          !isEmpty(games) &&
          <div className="game-center-games">
            {games.map(game => {
              const experience = game.experience.toLowerCase();
              const isGamePassGames = game.dataset === gamePassScreenSections.playAndEarnGold;
              const linkExperience = isGamePassGames ? camelCase(experience).toLowerCase() : `${experience}s`;
              const linkCategory = game.dataset.split(' ')[0].toLowerCase();

              let link = `/${linkExperience}?category=${linkCategory}`;
              if (isGamePassGames) link = `/${linkExperience}/games`;

              let sectionTitle = game.dataset;
              if (isGamePassGames) sectionTitle = gamePassScreenSections.wenLamboGames;

              return (
                <div 
                  key={`Section_${game.dataset}`}
                  className={classNames(
                    "game-center-section",
                    {"game-center-section-themed": isGamePassGames}
                  )}
                >
                  <SectionList
                    title={sectionTitle}
                    list={game.d}
                    link={link}
                    linkText={defaultCTATexts.viewAll}
                    handleGameDetails={setGameDetails}
                    sectionClassName={classNames(
                      "game-center-section-inner",
                      {"game-pass-screen-section-inner-themed": isGamePassGames}
                    )}
                    thumbnailNameCentered
                  />
                </div>
              );
            })}
          </div>}
      </LayoutContent>
      <GamePassPlayPopup {...gameDetails} />
      <FloatingIconManager />
    </>
  );
}