import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Reward } from './Reward';
import './RewardsCenter.scss';

export const Rewards = ({ rewards, title }) => {
  if (!rewards ||
    (rewards && !rewards.length)) {
    const rewardLabel = title === 'All' ? 'rewards' : `${title.toLowerCase()} rewards`;
    return (
      <div className="rewards-center-rewards-empty">
        No {rewardLabel} available.
      </div>
    );
  }

  return (
    <Row className="rewards-center-rewards-row">
      {rewards.map((r, rIdx) => (
        <Col
          key={`Reward_${title}_${rIdx}`}
          xs="12"
          sm="12"
          md="12"
          lg="6"
          className="rewards-center-rewards-cell"
        >
          <Reward {...r} />
        </Col>
      ))}
    </Row>
  );
}