import React, { Component } from 'react';
import { LayoutContent, PageTitle } from '../Components';
import './NotFound.scss';

export class NotFound extends Component {
  componentDidMount() {
    this.props.setLoading(false);
  }
  
  render() {
    return (
      <LayoutContent outerClassName="not-found" innerClassName="not-found-inner">
        <PageTitle title="Not Found" />
        <h1>Oops!</h1>
        <p>It seems that the page you're looking for is not found.</p>
      </LayoutContent>
    );
  }
}