import { connect } from 'react-redux';
import {
  setLoading,
  confirmSpendMobileLoadProduct,
  getSpendMobileLoadProducts,
  payMobileLoadProduct,
  setPaymentStep,
  setDisplayToast,
  getSpendCountry,
  resendEmailOTP,
  startPINOTPTimer,
  submitSpendOTP,
  sendSpendOTP,
  setTopUpVisible
} from '../actions';
import {
  spendMobileLoadProductsSelector,
  spendMobileLoadProductConfirmationSelector,
  displayLoginToastSelector,
  walletCryptoSelector,
  spendPaymentStepSelector,
  spendLoadingSelector,
  spendErrorSelector,
  userProfileSelector,
  spendCountrySelector,
  pinOTPTimerActiveSelector,
  spendSuccessSelector,
  pageContentsRenderedSelector
} from '../selectors';
import { Spend } from '../components';

const mapStateToProps = (state) => ({
  userProfile: userProfileSelector(state),
  products: spendMobileLoadProductsSelector(state),
  confirmation: spendMobileLoadProductConfirmationSelector(state),
  country: spendCountrySelector(state),
  toast: displayLoginToastSelector(state),
  crypto: walletCryptoSelector(state),
  paymentStep: spendPaymentStepSelector(state),
  isLoading: spendLoadingSelector(state),
  isError: spendErrorSelector(state),
  timerActive: pinOTPTimerActiveSelector(state),
  isSuccess: spendSuccessSelector(state),
  pageContentsRendered: pageContentsRenderedSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  getMobileLoadProducts: (formData) => dispatch(getSpendMobileLoadProducts(formData)),
  getSpendCountry: () => dispatch(getSpendCountry()),
  confirmMobileLoadProduct: (confirmData) => dispatch(confirmSpendMobileLoadProduct(confirmData)),
  payMobileLoadProduct: (paymentData) => dispatch(payMobileLoadProduct(paymentData)),
  setPaymentStep: (step) => dispatch(setPaymentStep(step)),
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast)),
  resendEmailOTP: (payload) => dispatch(resendEmailOTP(payload)),
  startOTPTimer: (timerActive) => dispatch(startPINOTPTimer(timerActive)),
  submitSpendOTP: (params) => dispatch(submitSpendOTP(params)),
  sendSpendOTP: (payload) => dispatch(sendSpendOTP(payload)),
  setTopUpVisible: (isTopUpVisible) => dispatch(setTopUpVisible(isTopUpVisible))
});

const SpendContainer = connect(mapStateToProps, mapDispatchToProps)(Spend);

export default SpendContainer