export const siteRoutes = {
  home: '/',
  login: '/login',
  tutorial: '/tutorial',
  pin: '/pin',
  mgcConversion: '/mgc-conversion',
  support: '/support',
  spinTheWheel: '/spin-the-wheel',
  mechanics: '/mechanics',
  redeem: '/redeem',
  instructions: '/instructions',
  play: '/play',
  messages:'/messages',
  spend: '/spend',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  launchpad: '/launchpad',
  payment: '/payment',
  referral: '/referral',
  rewardsCenter: '/rewards-center',
  profile: '/profile',
  topUp: '/topup',
  wallet: '/wallet',
  partners: '/partners',
  gamePass: '/gamepass',
  withdraw: '/withdraw',
  withdrawalMethods: '/withdrawal-methods'
};