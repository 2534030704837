import { connect } from 'react-redux';
import {
  setLoading,
  tryLogin,
  displayRegistrationForm,
  submitLogin,
  submitLoginOTP,
  setAuthStep,
  startLoginOTPTimer,
  submitLoginPIN,
  submitPIN,
  setPINValidationError,
  setDisplayToast,
  resendEmailOTP,
  setGuest,
  loginData
} from '../actions';
import {
  registrationFormVisibleSelector,
  displayLoginToastSelector,
  authStepSelector,
  loginOTPTimerActiveSelector,
  pinValidationErrorSelector,
  displaySetPINFormSelector,
  setSubmitNewPinSelector,
  remoteConfigKeysSelector
} from '../selectors';
import { loginFormInputs, pinFormInputs } from '../constants';
import { Login } from '../components';

const mapStateToProps = (state) => ({
  toast: displayLoginToastSelector(state),
  authStep: authStepSelector(state),
  timerActive: loginOTPTimerActiveSelector(state),
  registrationFormVisible: registrationFormVisibleSelector(state),
  hasPINValidationError: pinValidationErrorSelector(state),
  setPINFormDisplayed: displaySetPINFormSelector(state),
  isSubmit: setSubmitNewPinSelector(state),
  loginData: loginData(state),
  remoteConfigKey: remoteConfigKeysSelector(state),
  loginFormInputs,
  pinFormInputs
});

const mapDispatchToProps = (dispatch) => ({
  setDisplayToast: (toast) => dispatch(setDisplayToast(toast)),
  setLoading: (loading) => dispatch(setLoading(loading)),
  tryLogin: (payload) => dispatch(tryLogin(payload)),
  displayRegistrationForm: (visible) => dispatch(displayRegistrationForm(visible)),
  submitLogin: (formData) => dispatch(submitLogin(formData)),
  submitLoginOTP: (params) => dispatch(submitLoginOTP(params)),
  setAuthStep: (step) => dispatch(setAuthStep(step)),
  startLoginOTPTimer: (timerActive) => dispatch(startLoginOTPTimer(timerActive)),
  submitLoginPIN: (params) => dispatch(submitLoginPIN(params)),
  submitPIN: (pin) => dispatch(submitPIN(pin)),
  setPINValidationError: (hasValidationError) => dispatch(setPINValidationError(hasValidationError)),
  resendEmailOTP: (payload) => dispatch(resendEmailOTP(payload)),
  setGuest: (isGuest) => dispatch(setGuest(isGuest))
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;