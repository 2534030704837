import React from 'react';
import { OTP } from '../Commons';

export const WithdrawOTP = ({ showOTP, otpMinutes, otpSeconds, transform, texts, ...props }) => (
  <div className="nft-withdrawal-otp" style={transform}>
    <div className="login-otp-form">
      <OTP 
        {...props} 
        showOTP={showOTP} 
        description={texts.description} 
        time={{ minutes: otpMinutes, seconds: otpSeconds }} 
        autoSubmit 
      />
    </div>
  </div>
);
