import React from 'react';
import Lottie from 'lottie-react';
import './Tutorial.scss';

export const TutorialScreen = ({ title, description, animation, translate }) => (
  <div className="tutorial" style={{ transform: `translateX(${translate})` }}>
    <Lottie animationData={animation} className="tutorial-animation" loop autoplay />
    <div className="tutorial-text">
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  </div>
);