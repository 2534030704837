import React from 'react';
import { WalletConnectContent } from './WalletConnectContent';
import WalletConnectProvider from '../WalletConnectProvider/WalletConnectProvider';
import walletConnectIcon from '../../../assets/wallet-connect/wallet-connect.png';
import metaMaskIcon from '../../../assets/wallet-connect/metamask.png';
import './WalletConnect.scss';

const WalletConnect = ({ 
  hideHeader, 
  hideFaqs, 
  hideDisconnect, 
  showWalletName, 
  className, 
  hideErrorToast 
}) => (
  <WalletConnectProvider>
    <WalletConnectContent 
      walletConnectIcon={walletConnectIcon} 
      metaMaskIcon={metaMaskIcon} 
      hideHeader={hideHeader}
      hideFaqs={hideFaqs}
      hideDisconnect={hideDisconnect}
      showWalletName={showWalletName}
      hideErrorToast={hideErrorToast}
      className={className}
    />
  </WalletConnectProvider>
);

export default WalletConnect;