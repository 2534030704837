import { connect } from 'react-redux';
import { getTutorialScreens } from '../actions';
import { tutorialScreensSelector } from '../selectors';
import { Tutorial } from '../components';

const mapStateToProps = (state) => ({
  tutorialScreens: tutorialScreensSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getTutorialScreens: (fromProfilePage) => dispatch(getTutorialScreens(fromProfilePage))
});

const TutorialContainer = connect(mapStateToProps, mapDispatchToProps)(Tutorial);

export default TutorialContainer;