import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import classNames from 'classnames';
import { PageTitle, Section } from '../Components';
import { Toast, Share } from '../Commons';
import { GamePassPlayWebGL } from '../GamePassPlay/GamePassPlayWebGL';
import {
  setHideFooter,
  setHideHeader,
  setHideMenuIcon,
  setDisplayToast,
  getReferralLink,
  getWenLamboSpinTheWheelDetails
} from '../../actions';
import {
  defaultDomain,
  defaultTimeoutMS,
  firebaseEvents,
  storageKeys,
  spinTheWheelShareParams,
  spinTheWheelSharePlatforms,
  wenLamboIds,
  wenLamboSTWText
} from '../../constants';
import { 
  gamePassToastSelector, 
  guestSelector, 
  referralLinkSelector, 
  remoteConfigKeysSelector, 
  userProfileSelector, 
  wenLamboSpinTheWheelDetailsSelector 
} from '../../selectors';
import { useWebGLDetector } from '../../hooks/useWebGLDetector';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { setSTWShareText } from '../../utils/setShareText';
import './SpinTheWheelGame.scss';

export const SpinTheWheelGame = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isBrowserSupportsWebGL = useWebGLDetector();

  const [iframeUrl, setIframeUrl] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const toast = useSelector(state => gamePassToastSelector(state));
  const referralLink = useSelector(state => referralLinkSelector(state));
  const wenLamboDetails = useSelector(state => wenLamboSpinTheWheelDetailsSelector(state));
  const isGuest = useSelector(state => guestSelector(state));
  const userProfile = useSelector(state => userProfileSelector(state));
  const testKeys = useSelector(state => remoteConfigKeysSelector(state));

  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const isTutorial = localStorage.getItem(storageKeys.isTutorial);
  const isLoggedInTutorial = Cookies.get(storageKeys.isLoggedInTutorial);

  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  const share = queryParams.get(spinTheWheelShareParams.share);
  const prize = queryParams.get(spinTheWheelShareParams.prize);
  const prizeImageUrl = queryParams.get(spinTheWheelShareParams.prizeImageUrl);

  useEffect(() => {
    if (!isEmpty(iframeUrl)) {
      let event = firebaseEvents.wenLamboSPWFailed;
      if (isBrowserSupportsWebGL) {
        event = firebaseEvents.wenLamboSPWPage;
      }
      logFirebaseEventWithTimestamp(event);
    }
  })

  useEffect(() => {
    let spinTheWheelData = {
      marketingId: wenLamboIds.marketingId
    };
    if ((isTutorial && isGuest) || isLoggedInTutorial) {
      spinTheWheelData.tutorial = isTutorial;
    }
    dispatch(getWenLamboSpinTheWheelDetails(spinTheWheelData, queryParams));
    dispatch(setHideFooter(true));
    dispatch(setHideHeader(true));
    dispatch(setHideMenuIcon(true));
    dispatch(getReferralLink());
  
    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideHeader(false));
      dispatch(setHideMenuIcon(false));
    }
  }, [dispatch, isGuest, isTutorial, isLoggedInTutorial, queryParams]);

  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  useEffect(() => {
    setOpenShare(share);
  }, [share]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
      navigate(-1);
    }, defaultTimeoutMS);
  }

  const backToPreviousPage = () => {
    navigate(-1);
  }

  useEffect(() => {
    let tutorial = '';
    let marketingId = `&marketingId=${wenLamboIds.marketingId}`;

    const { data } = location?.state || {};
    const loadStwFromSideMenu = testKeys?.strippedStwEnabled || 
      (isBoolean(data?.passMarketingId) && !data?.passMarketingId);

    if (isTutorial) {
      tutorial = `&isTutorial=${isTutorial}`
    } 
    if (isLoggedInTutorial) {
      tutorial = `&isLoggedInTutorial=${isLoggedInTutorial}`
    }
    let queryParamsStr = '';
    if (queryParams && queryParams.size > 0) {
      queryParamsStr = `&${queryParams.toString()}`;
    }
    let spinTheWheelUrl = wenLamboDetails?.url;
    if (loadStwFromSideMenu &&
      data?.url) {
      spinTheWheelUrl = data?.url;
    }
    if (isBoolean(data?.passMarketingId) &&
      !data?.passMarketingId) {
      marketingId = '';
    }
    const iframeUrlWenLambo = `${spinTheWheelUrl}?sessionToken=${sessionToken}${marketingId}${tutorial}${queryParamsStr}`;
    setIframeUrl(iframeUrlWenLambo);
  }, [sessionToken, wenLamboDetails, isTutorial, queryParams, isLoggedInTutorial, testKeys, userProfile, location])

  return (
    <Fragment>
      <PageTitle title={wenLamboSTWText} />
      <Section
        backButtonRoute={!isBrowserSupportsWebGL}
        backRoute={backToPreviousPage}
        sectionClassName={classNames(
          "stw-game-section",
          {"stw-game-section-webgl-unsupported": !isBrowserSupportsWebGL}
        )}
        sectionHeaderClassName="stw-game-section-header"
        titleCentered
        hidden
        text="Back"
      >
        {!isEmpty(iframeUrl) && (
          isBrowserSupportsWebGL ?
            <div className="stw-game-main-container">
              <iframe
                key={wenLamboDetails?.url}
                src={iframeUrl}
                className="stw-game-iframe" 
                title={wenLamboSTWText} 
              />
            </div> :
            <GamePassPlayWebGL />
          )}
      </Section>
      <Share
        image={prizeImageUrl}
        title={setSTWShareText(prize, referralLink)}
        subtitle={defaultDomain.replace('https://', '')}
        shareIsVisible={openShare}
        exitShare={() => setOpenShare(false)}
        platforms={spinTheWheelSharePlatforms}
      />
      {toast &&
        <Toast
          toast={toast}
          open={toastOpen}
          hide={closeToast}
        />}
    </Fragment>
  );
}