import React, { Component } from 'react';
import { LayoutContent, PageTitle } from '../../Components';
import { Grid } from '../../Commons';
import Section from '../Section/Section';
import { defaultTimeoutMS } from '../../../constants';
    
const maxLength = 32;
const gridContainerHeight = 229;

class GridContainer extends Component {
  constructor(props) {
    super(props);

    const loadedAllItems = props.data.d.length <= maxLength;

    this.state = {
      items: loadedAllItems ? props.data.d : props.data.d.slice(0, maxLength),
      scrollTop: 0,
      loading: !loadedAllItems
    };
  }

  loadMoreItems = () => {
    const { data } = this.props;
    const { items } = this.state;
    const addedItems = data.d.slice(items.length, items.length + maxLength);

    if (data.d.length > items.length) {
      this.setState({ items: items.concat(addedItems) });
    } else {
      this.setState({ loading: false });
    }
  }

  lazyLoad = () => {
    const grid = document.getElementById('grid-container');
    const gridScrollHeight = (grid.clientHeight - grid.scrollTop - 20) + gridContainerHeight;
    const innerHeight = window.innerHeight - gridContainerHeight;

    if (gridScrollHeight <= innerHeight) {
      setTimeout(this.loadMoreItems, defaultTimeoutMS);
    }
  }

  backRoute = (e) => {
    e.preventDefault();
    window.history.back();
  }

  render() {
    const { data, keyPrefix, className } = this.props;
    const { items, loading } = this.state;
    const experience = `${data.experience.toLowerCase()}s`;

    return (
      <LayoutContent onScroll={this.lazyLoad} id="grid-container" outerClassName="scroll-container">
        <PageTitle title={data.dataset} />
        <Section
          title={data.dataset}
          sectionClassName={`${className}-section`}
          backButtonRoute
          backRoute={this.backRoute}
          titleCentered
        >
          <Grid items={items} keyPrefix={keyPrefix} className={className} experience={experience} />
          {loading && <div className={`${className}-loading`}>Loading...</div>}
        </Section>
      </LayoutContent>
    );
  }
}

export default GridContainer;