import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getAuth, signOut } from 'firebase/auth';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { PINKeyboard } from '../Commons';
import { 
  getUserProfile, 
  navigateTo, 
  resetState, 
  setAuthStep, 
  setGuest, 
  setLoading, 
  setSessionExpired, 
  setUrlParams 
} from '../../actions';
import { authSteps, firebaseEvents, storageKeys } from '../../constants';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';

export const PINKeyboardPage = ({ username, transform, hasValidationError, setPINValidationError, openPINRecoveryPopup, submitPIN }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const voucherCode = new URLSearchParams(search).get('code');

  useEffect(() => {
    if (email) {
      dispatch(setUrlParams({ email: email, voucherCode: voucherCode }));
      if (searchParams.has('email')) {
        searchParams.delete('email');
        searchParams.delete('code');
        setSearchParams(searchParams);
      }
    }
  },[email, voucherCode, setSearchParams, searchParams])

  const logout = () => {
    dispatch(setLoading(true));
    const auth = getAuth();
    const analytics = getAnalytics();

    if (localStorage.getItem(storageKeys.sessionToken)) localStorage.removeItem(storageKeys.sessionToken);
    if (localStorage.getItem(storageKeys.username)) localStorage.removeItem(storageKeys.username);
    if (localStorage.getItem(storageKeys.mobileNumber)) localStorage.removeItem(storageKeys.mobileNumber);
    if (localStorage.getItem(storageKeys.previousRoute)) localStorage.removeItem(storageKeys.previousRoute);
    if (Cookies.remove(storageKeys.dynamicSlug)) Cookies.remove(storageKeys.dynamicSlug);
    if (Cookies.remove(storageKeys.slug)) Cookies.remove(storageKeys.slug);
    if (Cookies.get(storageKeys.pmSource)) Cookies.remove(storageKeys.pmSource);
    if (sessionStorage.getItem(storageKeys.pmSource)) sessionStorage.removeItem(storageKeys.pmSource);
    
    dispatch(setSessionExpired(false));
    setUserId(analytics, null);

    signOut(auth)
      .then(() => {
        dispatch(resetState());
        dispatch(getUserProfile());
        dispatch(setGuest(true));
        dispatch(navigateTo({ path: '/', replace: true }));
        dispatch(setAuthStep(authSteps.login));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: err
          });
      });
  }

  return (
    <div className="pin-keyboard" style={transform}>
      <div className="pin-keyboard-content">
        <div className="pin-keyboard-site-logo"></div>
        <PINKeyboard
          hasValidationError={hasValidationError}
          setValidationError={setPINValidationError}
          submit={submitPIN}
          menu={<div className="pin-keyboard-menu">
            <div className="pin-keyboard-menu-username">{username}</div>
            <div className="pin-keyboard-menu-switch-user">
              <button onClick={logout} className="pin-keyboard-menu-switch-user-link">Switch User</button>
            </div>
          </div>}
        />
        <button 
          className="pin-keyboard-forgot-btn" 
          onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}
          onClick={() => openPINRecoveryPopup(true)}
          >
            Forgot PIN?
          </button>
      </div>
    </div>
  )
};
