import React from 'react';
import './GamePassTermsSection.scss';

const GamePassTermsSection = ({ title, items }) => (
  <div className="game-pass-terms-section">
    <h1 className="game-pass-terms-section-title">{title}</h1>
    <ul className="game-pass-terms-section-list">
      {items.map((i, idx) => (
        <li
          key={`GamePassGeneralTermsAndConditions_${idx}`}
          className="game-pass-terms-section-item"
        >
          <div dangerouslySetInnerHTML={{ __html: i }} />
        </li>
      ))}
    </ul>
  </div>
);

export default GamePassTermsSection;