import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  setGamePassCartItems, 
  setGamePassIsAddedToCart, 
  setUpdateGamePassCartItems, 
  setLoading, 
  setGamePassCartCount,
  failedRequests,
  getGamePassCheckout,
  setGamePassCheckoutSelectedPaymentMethod,
  setGamePassCheckout
} from '../actions';
import { 
  ADD_GAMEPASS_TO_CART,
  GAMEPASS_ADD_TO_CART, 
  GET_GAMEPASS_CART_COUNT, 
  GET_GAMEPASS_CART_ITEMS,
  UPDATE_GAMEPASS_CART_ITEMS
} from '../actions/constants';
import { 
  requestGamePassAddToCart, 
  requestGamePassCartCount, 
  requestGamePassCartItems, 
  requestGamePassUpdateCartItems 
} from '../api/cart';
import { storageKeys } from '../constants';

const gamePassAddToCartSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassAddToCart, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setGamePassIsAddedToCart(true));
      yield call(getGamePassCartCountSaga, sessionToken);
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setGamePassIsAddedToCart(false));
    yield put(setLoading(false));
    if (e.response && e.response.data) {
       const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassCartItemsSaga = function* () {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassCartItems, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setGamePassCartItems(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
       const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassCartCountSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassCartCount, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setGamePassCartCount(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
       const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const updateGamePassCartItemsSaga = function* (payload, redirectLink, navigate, requestCheckout) {
  try {
    const updateItemData = {
      items: payload.payload
    };
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassUpdateCartItems, updateItemData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setUpdateGamePassCartItems(response.data.d));
      if (redirectLink && navigate) navigate(redirectLink);
      if (requestCheckout) yield put(getGamePassCheckout());
    }
  } catch (e) {
    if (e.response && e.response.data) {
       const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const addGamePassToCartSaga = function* (payload) {
  const { productId, skuId, quantity, redirectLink, navigate, requestCheckout, isCheckoutModal } = payload.payload;
  yield put(setLoading(true));
  try {
    const addToCartData = {
      productId,
      skuId,
      quantity
    }
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassAddToCart, addToCartData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      let cartPayload = [];
      for (const item of response.data.d.items) {
        const cartPayloadObject = {
          productId: item.productId,
          skuId: item.skuId
        };
        if (item.skuId !== skuId) {
          cartPayloadObject.isCheckout = false;
        } else {
          cartPayloadObject.isCheckout = true;
          cartPayloadObject.newQuantity = quantity;
        }
        cartPayload.push(cartPayloadObject);
      }
      const items = { payload: cartPayload };
      if (!isCheckoutModal) {
        yield put(setGamePassCheckoutSelectedPaymentMethod({}));
        yield put(setGamePassCheckout({}));
      }
      yield call(updateGamePassCartItemsSaga, items, redirectLink, navigate, requestCheckout);
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
       const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* cartSaga() {
  yield all([
    takeLatest(GAMEPASS_ADD_TO_CART, gamePassAddToCartSaga),
    takeLatest(GET_GAMEPASS_CART_ITEMS, getGamePassCartItemsSaga),
    takeLatest(GET_GAMEPASS_CART_COUNT, getGamePassCartCountSaga),
    takeLatest(UPDATE_GAMEPASS_CART_ITEMS, updateGamePassCartItemsSaga),
    takeLatest(ADD_GAMEPASS_TO_CART, addGamePassToCartSaga)
  ]);
}