import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { dateTimeFormats } from '../constants';

const groupMessagesByDate = (messages, data, fromDB) => {
  const messageDates = uniq(messages.map(m => {
    const messageDate = fromDB ? m.messageDate : m.message_date;
    return messageDate?.slice(0, messageDate.indexOf('T'));
  }));
  const messageGroups = messageDates.map(d => {
    if (d) {
      const date = DateTime.fromFormat(d, dateTimeFormats.date);
      const now = DateTime.now();
      const diff = now.diff(date, 'days').toObject();
      const diffByDays = Math.abs(Math.floor(diff.days));
  
      let dateLabel = date.toFormat('LLL d, yyyy');
      if (diffByDays === 0) dateLabel = 'Today';
      else if (diffByDays === 1) dateLabel = 'Yesterday';
  
      return {
        label: dateLabel,
        messages: messages.filter(m => {
          const messageDate = fromDB ? m.messageDate : m.message_date;
          return messageDate?.includes(d);
        })
      };
    }
  });
  return { ...data, messages: messageGroups };
}

export default groupMessagesByDate;