import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { copyToClipboardTexts } from '../../constants';
import './RewardDetails.scss';

export const RewardDetailsVoucherCode = ({ claimForm, copied, handleCopy }) => {
  const text = copied ? copyToClipboardTexts.copied : claimForm[0]?.placeholder;
  return (
    <div className="reward-details-form">
      <div className="reward-details-form-group">
        <div className="reward-details-form-label">{claimForm[0]?.title}</div>
        <div className="reward-details-voucher-code">
          <div className="reward-details-voucher-code-text">{text}</div>
          <CopyToClipboard text={claimForm[0]?.placeholder} onCopy={handleCopy}>
            <button className="reward-details-voucher-code-copy">
              <div className="reward-details-voucher-code-copy-icon" />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}