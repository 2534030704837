import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { setHideFooter, setHideHeader, setHideMenuIcon } from '../../../actions';
import { minWidths } from '../../../constants';

export const HiddenHeaderAndFooter = ({ hideHeader, hideFooter, hideMenuIcon, all }) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  useEffect(() => {
    if (!isDesktop || all) {
      dispatch(setHideFooter(hideFooter));
      dispatch(setHideHeader(hideHeader));
      dispatch(setHideMenuIcon(hideMenuIcon));
    }

    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideHeader(false));
      dispatch(setHideMenuIcon(false));
    }
  }, [dispatch, isDesktop, all, hideFooter, hideHeader, hideMenuIcon])

  return null;
}
