import React, { useState } from 'react';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { copyToClipboardTexts, duration } from '../../../constants';
import './CopyCode.scss';

export const CopyCode = ({ code, fluid, small, centered }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, duration.oneSecond);
  }

  const buttonText = copied ? copyToClipboardTexts.copied : copyToClipboardTexts.copyCode;

  return (
    <div className={classNames("copy-code", {
      "copy-code-fluid": fluid,
      "copy-code-small": small,
      "copy-code-centered": centered
    })}>
      <div>{code}</div>
      <CopyToClipboard text={code} onCopy={onCopy}>
        <button className={classNames("copy-code-button", {
          "copy-code-button-small": small
        })}>
          {buttonText}
        </button>
      </CopyToClipboard>
    </div>
  );
}