import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import {
  Featured,
  LayoutContent,
  PageTitle,
  Section
} from '../Components';
import { Banner, Toast } from '../Commons';
import { RewardsOTPModal } from '../OTPModal/RewardsOTPModal';
import { RewardDetailsVoucherCode } from './RewardDetailsVoucherCode';
import { RewardDetailsClaimForm } from './RewardDetailsClaimForm';
import { RewardDetailsInfo } from './RewardDetailsInfo';
import { RewardDetailsRedeemButton } from './RewardDetailsRedeemButton';
import { RewardDetailsConfirm } from './RewardDetailsConfirm';
import { RewardDetailsNotice } from './RewardDetailsNotice';
import {
  getRewardDetails,
  setLoading,
  redeemReward,
  setDisplayToast,
  confirmRewardRedemption,
  showRewardOTPForm
} from '../../actions';
import {
  defaultTimeoutMS,
  duration,
  formInputKinds,
  rewardDetailsTexts,
  rewardStatuses
} from '../../constants';
import {
  rewardDetailsSelector,
  rewardDetailsToastSelector,
  rewardOTPFormVisibleSelector
} from '../../selectors';
import './RewardDetails.scss';

export const RewardDetails = () => {
  const pathnameArr = window.location.pathname.split('/').slice(1);
  const rewardId = pathnameArr[pathnameArr.length - 1];

  const [isConfirmVisible, showConfirm] = useState(false);
  const [isNoticeVisible, showNotice] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [voucherCodeCopied, setVoucherCodeCopied] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rewardDetails = useSelector(state => rewardDetailsSelector(state));
  const toast = useSelector(state => rewardDetailsToastSelector(state));
  const isOTPFormVisible = useSelector(state => rewardOTPFormVisibleSelector(state));

  const mobileNumber = formFields?.find(f => f?.key === formInputKinds.phone);

  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getRewardDetails(rewardId));
  }, [dispatch, rewardId]);

  useEffect(() => {
    let fields = [];
    if (rewardDetails &&
      rewardDetails.claimForm) {
      fields = rewardDetails.claimForm.map(f => {
        const field = {
          key: f.key,
          value: f.placeholder,
          required: f.required
        };
        return field;
      });
    }
    setFormFields(fields);
  }, [rewardDetails]);

  const backToPreviousPage = () => {
    navigate(-1);
  }

  const confirmRedeemReward = () => {
    showConfirm(false);
    if (rewardDetails?.isOTPRequired) {
      return showNotice(true);
    }
    dispatch(setLoading(true));
    if (rewardDetails && rewardDetails.id) {
      const payload = {
        id: rewardDetails.id
      };
      if (rewardDetails.claimForm &&
        !isEmpty(rewardDetails.claimForm) &&
        isEmpty(formErrors)) {
        payload.claimForm = formFields.map(f => (
          pick(f, Object.keys(f).filter(k => k !== 'required')))
        );
      }
      dispatch(redeemReward(payload));
    } else {
      dispatch(setLoading(false));
    }
  }

  const proceedToMobileNumberValidation = () => {
    const payload = {
      mobileNumber: mobileNumber?.value,
      id: rewardDetails?.id
    };
    showNotice(false);
    dispatch(setLoading(true));
    dispatch(confirmRewardRedemption(payload));
  }
  
  const closeOTPForm = () => {
    dispatch(setDisplayToast());
    dispatch(showRewardOTPForm(false));
  }

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const updateAnswer = (fieldId, value) => {
    const fieldIds = formFields.map(f => f.key);
    const fieldIndex = fieldIds.indexOf(fieldId);

    if (fieldIndex === -1) return;
    
    const otherFormErrors = formErrors.filter(f => f !== fieldId);
    formFields[fieldIndex].value = value;
    setFormErrors(otherFormErrors);
    setFormFields(formFields);
  }

  const submitForm = (e) => {
    e.preventDefault();

    const requiredFields = formFields.filter(f => f.required);
    const errors = requiredFields.filter(f => isEmpty(f.value)).map(f => f.key);
    setFormErrors(errors);

    if (isEmpty(errors)) {
      showConfirm(true);
    }
  }

  const handleCopy = () => {
    setVoucherCodeCopied(true);
    setTimeout(() => {
      setVoucherCodeCopied(false);
    }, duration.oneSecond);
  }

  const redeemed = rewardDetails?.status === rewardStatuses.used;
  const hasForm = rewardDetails?.claimForm && !isEmpty(rewardDetails?.claimForm);
  const hasVoucherCode = !rewardDetails?.needsSubmission && rewardDetails?.claimForm && rewardDetails?.claimForm[0]?.inputKind === 'voucher';

  return (
    <>
      {rewardDetails &&
        !isEmpty(rewardDetails) &&
        <LayoutContent>
          <PageTitle title={rewardDetails.name} />
          <Section 
            sectionClassName="reward-details"
            sectionHeaderClassName="reward-details-header"
            backButtonRoute
            backRoute={backToPreviousPage}
            title="Rewards Center"
            titleCentered
          >
            {rewardDetails.imageUrls &&
              rewardDetails.imageUrls.bannerUrl &&
              <Featured fluid>
                <Banner imageUrl={rewardDetails.imageUrls.bannerUrl} />
              </Featured>}
            <RewardDetailsInfo
              title={rewardDetails.name}
              imageUrl={rewardDetails.imageUrl}
              redeemed={redeemed}
              expiresAt={rewardDetails.expiresAt}
            >
              {!redeemed &&
                hasForm &&
                <>
                  {hasVoucherCode ?
                    <RewardDetailsVoucherCode
                      claimForm={rewardDetails?.claimForm}
                      copied={voucherCodeCopied}
                      handleCopy={handleCopy}
                    /> :
                    <RewardDetailsClaimForm
                      id={rewardDetails?.id}
                      claimForm={rewardDetails?.claimForm}
                      updateAnswer={updateAnswer}
                      formErrors={formErrors}
                      submitForm={submitForm}
                    />}
                </>}
              {!redeemed &&
                hasForm &&
                !isEmpty(formErrors) &&
                <div className="reward-details-form-error-message">{rewardDetailsTexts.emptyFormError}</div>}
              {!redeemed &&
                !hasForm &&
                !hasVoucherCode &&
                <RewardDetailsRedeemButton onClick={() => showConfirm(true)} />}
            </RewardDetailsInfo>
            {rewardDetails.instructions &&
              rewardDetails.instructions.map(i => (
                <Section
                  key={`RewardDetails_${rewardDetails.id}_${i.title}`}
                  title={i.title}
                  sectionClassName="reward-details-section"
                >
                  <div
                    className="reward-details-section-content"
                    dangerouslySetInnerHTML={{ __html: i.detail }}
                  />
                </Section>
              ))}
          </Section>
          <RewardDetailsConfirm
            open={isConfirmVisible}
            hide={() => showConfirm(false)}
            onClick={confirmRedeemReward}
          />
          <RewardDetailsNotice
            open={isNoticeVisible}
            hide={() => showNotice(false)}
            onClick={proceedToMobileNumberValidation}
          />
          <RewardsOTPModal
            open={isOTPFormVisible}
            hide={closeOTPForm}
            rewardDetails={rewardDetails}
            formErrors={formErrors}
            formFields={formFields}
          />
        </LayoutContent>}
      {toast && 
        !isOTPFormVisible &&
        <Toast toast={toast} open={toastOpen} hide={closeToast} />}
    </>
  );
}