import { takeLatest, call, put, all } from 'redux-saga/effects';
import { 
  requestUserCollections, 
  requestUserCollection
} from '../api/collections';
import { 
  GET_USER_COLLECTIONS, 
  GET_USER_COLLECTION
} from '../actions/constants';
import { 
  setLoading, 
  setUserCollections, 
  setUserCollection,
  failedRequests
} from '../actions';
import { storageKeys } from '../constants';

const getUserCollectionsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserCollections, payload.category, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setUserCollections(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserCollectionSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserCollection, payload.userCollectionId, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const { data } = response;
      const userCollection = {
        id: payload.userCollectionId,
        name: data.dataset,
        collection: data.d
      }
      yield put(setUserCollection(userCollection));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* homeSaga() {
  yield all([
    takeLatest(GET_USER_COLLECTIONS, getUserCollectionsSaga),
    takeLatest(GET_USER_COLLECTION, getUserCollectionSaga)
  ]);
}
