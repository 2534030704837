import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { load } from 'recaptcha-v3';
import isEmpty from 'lodash/isEmpty';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { BlurBackgroundModal, Phone } from '../Commons';
import { 
  defaultMobileNumberPrefix, 
  firebaseEvents, 
  formInputKinds, 
  loginTexts, 
  onboardingTexts, 
  recaptchaSiteKey,
  regexConstants,
  storageKeys
} from '../../constants';
import { 
  displayRegistrationForm,
  setAuthStep, 
  setDisplayToast, 
  setLoading,
  submitLogin 
} from '../../actions';
import {
  displayLoginToastSelector,
  loadingSelector,
  registrationFormVisibleSelector
} from '../../selectors';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import Cookies from 'js-cookie';
import './LoginModal.scss';

export const LoginModal = ({ open, hasGamePartnerRedirectUri }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    mobileNumber: '',
    email: ''
  });
  const [countryCode, setCountryCode] = useState(defaultMobileNumberPrefix);
  const [recaptcha, setRecaptcha] = useState('');
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
  const [openEmail, setOpenEmail] = useState();

  const toast = useSelector(state => displayLoginToastSelector(state));
  const isRegistration = useSelector(state => registrationFormVisibleSelector(state));
  const loading = useSelector(state => loadingSelector(state));

  const isDailyRewardsShown = Cookies.get(storageKeys.isDailyRewardsShown);

  const getRecaptcha = () => {
    load(recaptchaSiteKey, { useRecaptchaNet: true, autoHideBadge: true })
      .then(recaptcha => {
        setRecaptcha(recaptcha);
        setIsRecaptchaLoaded(true);
      })
      .catch(error => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: error
          });
      });
  };

  useEffect(() => {
    getRecaptcha();
  }, [])

  useEffect(() => {
    if (isDailyRewardsShown) {
      Cookies.remove(storageKeys.isDailyRewardsShown);
    }
  }, [isDailyRewardsShown])

  const handleLogin = () => {
    if (!loading) {
      let formData = {
        mobileNumber: `${countryCode}${form.mobileNumber}`,
        deviceInfo: getDeviceInfo(),
      };
      if (form?.email && isRegistration) {
        formData.email = form.email;
      }
      dispatch(setLoading(true));
      if (isRecaptchaLoaded) {
        recaptcha.execute('login')
          .then(t => {
            const updatedFormData = {
              ...formData,
              recaptchaToken: t
            };
            dispatch(submitLogin(updatedFormData));
          })
          .catch(error => {
            logFirebaseEvent(
              firebaseEvents.failedEvent, {
                error_message: error
              });
          });
      } else {
        dispatch(submitLogin(formData));
      }
    }
  }

  const handleChange = (key, value) => {
    let keyValue = value;
    if (key === storageKeys.mobileNumber) {
      const mobileNumberWithoutCountryCode = value?.replace(countryCode, '');
      keyValue = mobileNumberWithoutCountryCode?.replace(regexConstants.nonDigit, '');
    }
    dispatch(setDisplayToast());
    setForm({
      ...form,
      [key]: keyValue
    });
  }

  const handleHide = () => {
    if (!hasGamePartnerRedirectUri) {
      dispatch(setDisplayToast());
      dispatch(setAuthStep());
      dispatch(displayRegistrationForm(false));
      setOpenEmail(false);
    }
  }

  const title = isRegistration ? loginTexts.register.title : loginTexts.title;
  const footerTitle = isRegistration ? loginTexts.register.footer : loginTexts.footer;
  const buttonText = isRegistration ? loginTexts.button.login : loginTexts.button.signup;

  let titleComponent = title;
  if (hasGamePartnerRedirectUri) {
    titleComponent = <div className="login-modal-header-logo" />;
  }

  return (
    <BlurBackgroundModal 
      open={open}
      title={titleComponent}
      buttonTitle={loginTexts.button.next}
      value={form.mobileNumber}
      hide={handleHide}
      hideClose={hasGamePartnerRedirectUri}
      dialogClassName={classNames({"login-modal-dialog": hasGamePartnerRedirectUri})}
      backdropClassName={classNames({"login-modal-backdrop": hasGamePartnerRedirectUri})}
    >
      <div className="login-modal-phone-text">{onboardingTexts.enterMobileNumber}</div>
      <Phone
        type="text"
        onChange={(e) => handleChange(storageKeys.mobileNumber, e.target.value)}
        countryCode={countryCode}
        width={18}
        height={18}
        setCountryCode={setCountryCode}
        mobileNumber={form.mobileNumber}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
          }
        }}
        className={classNames(
          "login-modal-phone",
          {"login-modal-phone-error": toast}
        )}
        dropdownMenuClassName="login-modal-phone-dropdown"
        hasFilter
        hideCountryFlag
      />
      {isRegistration && 
        <>
          <div onClick={() => setOpenEmail(!openEmail)} className="login-modal-phone-text optional">
            {onboardingTexts.enterEmailAddress}
            {openEmail ? <ArrowDropUpOutlinedIcon className="login-modal-arrow" />
              : <ArrowDropDownOutlinedIcon className="login-modal-arrow" />}
          </div>
          {openEmail &&
            <input 
              className="login-modal-email" 
              value={form.email}
              onChange={(e) => handleChange(formInputKinds.email, e.target.value)} 
            />}
        </>}
      {toast &&
        <div className="login-modal-error">{toast?.message}</div>}
      <div 
        onClick={handleLogin}
        className={classNames(
          "blur-modal-button",
          {"blur-modal-button-disabled": isEmpty(form.mobileNumber) || loading}
        )}
      >
        {loginTexts.button.next}
      </div>
      <div className="login-modal-footer">
        <div className="login-modal-footer-title">{footerTitle}</div>
        <div 
          onClick={() => dispatch(displayRegistrationForm(!isRegistration))} 
          className="login-modal-footer-button"
        >
          {buttonText}
        </div>
      </div>
    </BlurBackgroundModal>
  );
}