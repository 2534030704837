import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { 
  mgcCurrency, 
  topUpChannels, 
  withdrawalForms 
} from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, validateCryptoWithdrawal } from '../../actions';
import { withdrawalNoticeSelector } from '../../selectors';

export const WithdrawalForm = ({ 
  value, 
  chainId, 
  toCurrency, 
  transferAmount, 
  fee,
  requestOtp
}) => {
  const dispatch = useDispatch();

  const [walletAddress, setWalletAddress] = useState('');
  const [noticeText, setNoticeText] = useState('');

  const notice = useSelector(state => withdrawalNoticeSelector(state));

  const { notice: noticeObj } = notice || '';

  useEffect(() => {
    if (isEmpty(noticeObj)) {
      return;
    }
    const updatedNotice = noticeObj?.text?.replace(/\{\{help\}\}/g, `<a href="${noticeObj?.links?.help}">${noticeObj?.links?.help}</a>`);
    setNoticeText(updatedNotice);
  }, [noticeObj])

  const handleChange = (e) => {
    const { value } = e.target || '';
    setWalletAddress(value)
  }

  const getLoginOtp = () => {
    requestOtp();
  }

  const onTransfer = () => {
    dispatch(setLoading(true));
    dispatch(
      validateCryptoWithdrawal({
        fromCurrency: mgcCurrency,
        fromAmount: Number(value),
        chainId,
        toAddress: walletAddress,
        channelCode: topUpChannels.externalWallet,
        toCurrency,
        onSuccess: getLoginOtp
      })
    );
  }

  return (
    <div className="withdrawal-form">
      <div className="w-100">
        <div className="withdrawal-form-title">{withdrawalForms.title}</div>
        <div className="withdrawal-form-input-wrapper">
          <div className="withdrawal-form-input-title">{withdrawalForms.crypto.title}</div>
          <input className="withdrawal-form-input" onChange={handleChange} /> 
        </div>
        <div className="withdrawal-form-notes">
          <div dangerouslySetInnerHTML={{ __html: noticeText }} className="withdrawal-form-notes-text" />
        </div>
      </div>
      <div className="withdrawal-button-wrapper">
        <button 
          className={classNames(
            "withdrawal-button", 
            "withdrawal-form-button",
            {"withdrawal-button-disabled": !walletAddress}
          )}
          onClick={onTransfer}
        >
          <div>{transferAmount}</div>
          <div className="withdrawal-form-button-fee">{fee}</div>
        </button>
      </div>
    </div>
  )
}
