import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import ReactCountryFlag from 'react-country-flag';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  createPayment,
  getCurrencyConversion,
  getCurrencyConversionBatch,
  getPaymentChannels,
  setDisplayToast,
  setLoading,
  setTopUpVisible
} from '../../actions';
import { CryptoPayment, LayoutContent, PageTitle } from '../Components';
import { TopUpPaymentLoading } from './TopUpPaymentLoading';
import { TopUpPaymentOrder } from './TopUpPaymentOrder';
import { FeeBreakdown } from './FeeBreakdown';
import { Toast } from '../Commons';
import {
  defaultTimeoutMS,
  firebaseEvents,
  mgcCurrency,
  mgcData,
  minWidths,
  paymentTexts,
  phpCurrency,
  phpData,
  range,
  regexConstants,
  siteRoutes,
  topUpChannels,
  topUpDirection,
  usdCurrency,
  wallets
} from '../../constants';
import { 
  displayLoginToastSelector, 
  topUpPaymentSelector, 
  topUpBuyDataSelector, 
  topUpCurrencySelector,
  topUpCurrencyBatchSelector,
  topUpIsLoadingSelector,
  topUpChannelsSelector
} from '../../selectors';
import { useQuery } from '../../hooks/useQuery';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { useDebounce } from '../../hooks/useDebounce';
import './TopUpPayment.scss';

export const TopUpPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = useState(1);
  const [value, setValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [mgcPayment, setMGCPayment] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [feeBreakdownOpen, openFeeBreakdown] = useState(false);
  const [isMGCInputAllow, setIsMGCInputAllow] = useState(false);
  const [shouldRedirectToForm, setShouldRedirectToForm] = useState(false);

  const query = useQuery();
  const method = query.get("method").toLowerCase();
  const payment = query.get("mode");
  const channel = query.get("channel");
  const isBuyPayment = method && payment && channel;

  const toast = useSelector(state => displayLoginToastSelector(state));
  const paymentData = useSelector(state => topUpPaymentSelector(state));
  const buyData = useSelector(state => topUpBuyDataSelector(state));
  const currency = useSelector(state => topUpCurrencySelector(state));
  const currencyBatch = useSelector(state => topUpCurrencyBatchSelector(state));
  const currencyLoading = useSelector(state => topUpIsLoadingSelector(state));
  const channels = useSelector(state => topUpChannelsSelector(state));

  const debouncedValue = useDebounce(value, defaultTimeoutMS);

  const isCryptoTopUp = channel === wallets.walletConnect.name;

  const handleMediaQueryChange = (matches) => {
    if (matches && isBuyPayment) {
      navigate(`/wallet${window.location.search}`);
      dispatch(setTopUpVisible(true));
    }
  }

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet }, undefined,  handleMediaQueryChange);

  const changeHandler = e => {  
    const { value } = e.target;
    setWidth(value.length);
    setValue(value.toString().replace(regexConstants.startingZeroes, ''));
    setSelectedProduct({});
  };

  useEffect(() => {
    if (isCryptoTopUp) {
      return;
    }
    dispatch(getPaymentChannels(method.toUpperCase()));
  }, [dispatch, method, isCryptoTopUp])

  useEffect(() => {
    const filteredChannel = channels.filter(c => c.code === channel);
    if (!isEmpty(filteredChannel[0]?.form)) {
      setShouldRedirectToForm(true);
    } else {
      setShouldRedirectToForm(false);
    }
  }, [channels, channel])
 
  useEffect(() => {
    if (isCryptoTopUp) {
      return;
    }
    const toAmounts = mgcPayment || channel === topUpChannels.ccdc ? mgcData : phpData;
    const direction = mgcPayment ? topUpDirection.to : topUpDirection.from;
    const fromCurrency = channel === topUpChannels.ccdc ? usdCurrency : phpCurrency;
    const toCurrency = mgcCurrency;
    dispatch(setLoading(false));
    setValue("");
    dispatch(getCurrencyConversionBatch({
      fromCurrency, 
      toAmounts, 
      direction, 
      toCurrency, 
      channel
    }));
  }, [dispatch, channel, mgcPayment, isCryptoTopUp]);

  useEffect(() => {
    setSelectedProduct({});
    setValue("");
    setWidth(1);

    return () => {
      setSelectedProduct({});
      setValue("");
      setWidth(1);
    }
  }, [mgcPayment]);

  useEffect(() => {
    if (!value) setValue('0');
  }, [value])

  useEffect(() => {
    if (isCryptoTopUp) {
      return;
    }
    const direction = mgcPayment ? topUpDirection.to : topUpDirection.from;
    const fromCurrency = channel === topUpChannels.ccdc ? usdCurrency : phpCurrency;
    const toCurrency = mgcCurrency;
    if (!debouncedValue) return;
    dispatch(getCurrencyConversion({
      fromCurrency, 
      toCurrency, 
      direction, 
      value: debouncedValue, 
      channel
    }));
  }, [dispatch, debouncedValue, channel, mgcPayment, isCryptoTopUp]);

  const handleInputBlur = () => {
    if (width === 0) setWidth(1);
  };
  
  const handleSelectedProduct = (item) => {
    setSelectedProduct(item)
    if (mgcPayment) {
      setValue(item.amountToReceive.value);
      setWidth(item.amountToReceive.value.length);
    }
    if (!mgcPayment) {
      setValue(item.amountToPay.value);
      setWidth(item.amountToPay.value.length);
    }
  }

  const handlePayment = () => {
    if (shouldRedirectToForm) return navigate(`/payment/card?channel=${channel}&amount=${value}&currency=${usdCurrency}&from=topup`)
    let paymentData = {
      fromCurrency: channel === topUpChannels.ccdc ? usdCurrency : phpCurrency,
      channelCode: channel,
    };

    if (mgcPayment) {
      paymentData.toAmount = value;
    } else {
      paymentData.fromAmount = value;
    }
    logFirebaseEventWithTimestamp(firebaseEvents.walletTopupProceedPayment);
    dispatch(createPayment(paymentData));
  }

  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  useEffect(() => {
    if (!paymentData.data || isEmpty(paymentData.data.checkoutUrl)) return;
    dispatch(setTopUpVisible(false));
    window.location.href = paymentData.data.checkoutUrl;
  }, [dispatch, paymentData, method]);

  const toggleDisplay = () => {
    setMGCPayment(!mgcPayment);
    openFeeBreakdown(false);
  }

  useEffect(() => {
    for (const item of buyData){
      for (const channels of item.channels){
        if (channels.code === channel){
          if (channels.allowsMGCInput) setIsMGCInputAllow(channels.allowsMGCInput);
        }
      }
    }
  }, [method, buyData, channel])

  const amountToPay = !isEmpty(currency) ? `${currency.amountToPay.value} ${currency.amountToPay.currency}` : '';
  const amountToReceive = !isEmpty(currency) ? `${currency.amountToReceive.value} ${currency.amountToReceive.currency}` : '';
  const amountToPayLabel = mgcPayment ? 'pay' : 'receive';
  const paymentConvertedAmount = mgcPayment ? amountToPay : amountToReceive;
  const paymentCurrency = mgcPayment ? mgcCurrency : channel === topUpChannels.ccdc ? usdCurrency : phpCurrency;
  const title = isDesktop 
    ? isDesktop
      ? `${paymentTexts.payVia} ${payment}`
      : `${paymentTexts.payWith} ${payment}`
    : paymentTexts.topUpMGC;

  const handleNavigate = () => {
    if (!isDesktop) return navigate("/topup");
    navigate("/wallet");
  }

  return (
    <Fragment>
      {!isCryptoTopUp ? 
        <>
          <LayoutContent innerClassName="payment-inner">
            <PageTitle title="Payment" />
            {isEmpty(paymentData.data) && <div className="payment">
              <div className="payment-header">
                <ArrowBackIosNewRoundedIcon onClick={handleNavigate} className="payment-header-back" />
                <div className="px-5">{title}</div>
              </div>
              <div className="payment-container">
                {!isDesktop && 
                  <div className="payment-pay mb-2">
                    <div>Pay with {payment}</div>
                  </div>}
                <div className="d-flex justify-content-center align-items-center flex-column gap-3 payment-input-box">
                  <div className="payment-label">Enter Amount</div>
                  <div className="payment-type">
                    {!mgcPayment 
                      ? channel === topUpChannels.ccdc
                        ? <div className="payment-box-icon-us" />
                        : <ReactCountryFlag countryCode="PH" className="payment-country" svg />
                      : <div className={classNames("payment-box-icon", "payment-box-icon-input")} />}
                    <input 
                      className="payment-input-blank" 
                      onBlur={handleInputBlur} 
                      value={(value || !value) ? value : mgcPayment ? selectedProduct.fromAmount : selectedProduct.toAmount} 
                      type="text"
                      onKeyPress={(event) => {
                        if (!/^\d*\.?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}  
                      autoFocus 
                      onChange={changeHandler} 
                      style={{ width: `${width + 0.2}ch` }}
                    />
                    <div className="payment-currency">{paymentCurrency}</div>
                  </div>
                  {!isEmpty(currency) && 
                    <div className="payment-text">
                      You will {amountToPayLabel}&nbsp;
                      <span className="payment-converted-amount">{paymentConvertedAmount}</span>
                      {!feeBreakdownOpen 
                        ? <KeyboardArrowDownRoundedIcon 
                            onClick={() => openFeeBreakdown(!feeBreakdownOpen)} 
                            className="payment-converted-amount-icon" /> 
                        : <KeyboardArrowUpRoundedIcon 
                            onClick={() => openFeeBreakdown(!feeBreakdownOpen)} 
                            className="payment-converted-amount-icon" />}
                    </div>}
                  {currency && 
                    feeBreakdownOpen && 
                    <FeeBreakdown {...currency} />}
                </div>
                <div className="payment-text-amount">Or choose amount below:</div>
                {isEmpty(currencyBatch) &&
                  <div className="payment-amount">
                    {range(4).map(r => (
                      <div key={`PaymentBoxPlaceholder_${r}`} className="payment-box payment-box-placeholder" />
                    ))}
                  </div>}
                <div className="payment-amount">
                  {currencyBatch.map((o, oIdx) => {
                    const paymentBoxPriceValue = mgcPayment ? o.amountToReceive.value : o.amountToPay.value;
                    const paymentBoxPriceCurrency = mgcPayment ? o.amountToReceive.currency : o.amountToPay.currency;
                    const paymentBoxMGC = mgcPayment ? `${o.amountToPay.value} ${o.amountToPay.currency}` : o.amountToReceive.value;

                    return (
                      <div 
                        key={`PRICE_${oIdx}`} 
                        onClick={() => handleSelectedProduct(o)} 
                        className={classNames("payment-box", {"payment-box-active": selectedProduct === o})}>
                        <div className="payment-box-wrapper">
                          {mgcPayment && 
                            <div className="payment-box-icon" />}
                          <div className="payment-box-price">{`${paymentBoxPriceValue} ${paymentBoxPriceCurrency}`}</div>
                        </div>
                        <div className="payment-box-wrapper">
                          {!mgcPayment && 
                            <div className="payment-box-icon" />}
                          <div className="payment-box-mgc">{paymentBoxMGC}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="payment-change">
                  {isMGCInputAllow && <div 
                    onClick={toggleDisplay}
                    className="payment-change-text">{`I want to enter ${mgcPayment ? phpCurrency : mgcCurrency} instead`}
                  </div>}
                    <div className="payment-change-button-wrapper">
                      <button 
                        onClick={handlePayment} 
                        className={classNames(
                          "payment-change-button",
                          {"payment-change-button-disabled": value === 0 || currencyLoading}
                        )}
                      >
                        Continue to Payment
                      </button>
                    </div>
                </div>
              </div>
            </div>}
          {!isEmpty(paymentData.data) && 
            method.includes("over_the_counter") && 
            <TopUpPaymentOrder paymentData={paymentData}/>}
        </LayoutContent>
        {paymentData.loading && 
          !isDesktop && 
          <TopUpPaymentLoading />}
        </> : 
        <CryptoPayment />}
      {toast && 
        location.pathname.includes(siteRoutes.topUp) &&
        <Toast toast={toast} open={toastOpen} hide={closeToast}/>}
    </Fragment>
  );
}
