import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { 
  ErrorToast,
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { 
  siteRoutes, 
  countryCode, 
  cryptoIntent,
  withdrawalTitles
} from '../../constants';
import { topUpBuyDataSelector } from '../../selectors';
import { getPaymentMethods, setPaymentMethods } from '../../actions';
import './WithdrawalMethods.scss';

export const WithdrawalMethods = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useSelector(state => topUpBuyDataSelector(state));

  const handleClick = (method) => {
    navigate(`${siteRoutes.withdrawalMethods}${siteRoutes.withdraw}`, { state: { method }})
  }

  useEffect(() => {
    dispatch(
      getPaymentMethods({
        countryCode,
        intent: cryptoIntent.withdrawal
      })
    );
    return () => {
      dispatch(setPaymentMethods([]));
    }
  }, [dispatch])

  return (
    <LayoutContent>
      <PageTitle title={withdrawalTitles.withdrawalMethods} />
      <Section 
        sectionClassName="withdrawal-methods-section"
        backButtonRoute
        backRoute={() => navigate(-1)}
        title={withdrawalTitles.selectWithdrawalMethod}
        titleCentered
        text="Back"
      >
        <div className="withdrawal-methods-content">
          {methods?.map((w, wIdx) => (
            <div 
              key={`WithdrawalMethods_${wIdx}`} 
              className="withdrawal-methods-box"
              onClick={() => handleClick(w.name)}
            >
              <div className="withdrawal-methods-box-wrapper">
                <img src={w.imageUrl} alt={w.kind} className="withdrawal-methods-box-image" />
                <div className="withdrawal-methods-box-name">{w.name}</div>
              </div>
              <KeyboardArrowRightRoundedIcon className="withdrawal-methods-box-arrow" />
            </div>
          ))}
        </div>
      </Section>
      <ErrorToast />
    </LayoutContent>
  )
}
