import { connect } from 'react-redux';
import {
  setLoading,
  getLaunchpadCollection,
  getLaunchpadCollectionItem
} from '../actions';
import {
  launchpadCollectionSelector,
  launchpadCollectionSelectorItem,
  mintingNFTSuccess,
  nftDataSelector
} from '../selectors';
import { LaunchpadItem } from '../components';

const mapStateToProps = (state) => ({
  collection: launchpadCollectionSelector(state),
  collectionItem: launchpadCollectionSelectorItem(state),
  mintingNFTSuccess: mintingNFTSuccess(state),
  nftData: nftDataSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  getLaunchpadCollection: () => dispatch(getLaunchpadCollection()),
  getLaunchpadCollectionItem: (id) => dispatch(getLaunchpadCollectionItem(id))
});

const LaunchpadItemContainer = connect(mapStateToProps, mapDispatchToProps)(LaunchpadItem);

export default LaunchpadItemContainer;