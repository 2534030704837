import {
  RESET_STATE,
  SET_SPEND_COUNTRY,
  SET_SPEND_ERROR,
  SET_SPEND_LOADING,
  SET_SPEND_LOADING_TEXT,
  SET_SPEND_MOBILE_LOAD_PRODUCTS,
  SET_SPEND_MOBILE_LOAD_PRODUCT_CONFIRMATION,
  SET_SPEND_OTP_SUCCESS,
  SET_SPEND_PAYMENT_STEP,
  SET_SPEND_REFERENCE_ID
} from '../actions/constants';

const spendState = {
  mobileLoadProducts: {},
  confirmation: {},
  country: {},
  paymentStep: 1,
  isLoading: false,
  isError: {
    result: false,
    message: ''
  },
  isSuccess: false,
  referenceId: '',
  loadingText: ''
};

const spendReducer = (state = spendState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return spendState;
    case SET_SPEND_MOBILE_LOAD_PRODUCTS:
      return {
        ...state,
        mobileLoadProducts: action.mobileLoadProducts
      };
    case SET_SPEND_MOBILE_LOAD_PRODUCT_CONFIRMATION:
      return {
        ...state,
        confirmation: action.confirmation
      };
    case SET_SPEND_COUNTRY:
      return {
        ...state,
        country: action.country
      };
    case SET_SPEND_PAYMENT_STEP:
      return {
        ...state,
        paymentStep: action.paymentStep
      };
    case SET_SPEND_LOADING:
      return {
        ...state,
        isLoading: action.loading
      };
    case SET_SPEND_OTP_SUCCESS:
      return {
        ...state,
        isSuccess: action.success
      };
    case SET_SPEND_ERROR:
      return {
        ...state,
        isError: action.error
      };
    case SET_SPEND_REFERENCE_ID:
      return {
        ...state,
        referenceId: action.referenceId
      };
    case SET_SPEND_LOADING_TEXT:
      return {
        ...state,
        loadingText: action.loadingText
      };
    default:
      return state;
  }
}

export default spendReducer;