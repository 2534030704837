import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Carousel, CarouselItem } from '../Commons';
import './Partners.scss';

export const PartnersCarousel = ({ items, isMobile }) => (
  <div className="partners-thumbnails">
    <Row className="partners-carousels">
      <Col xs="12" sm="12" className="partners-carousel">
        <Carousel className="partners-carousel-items">
          {items?.map((i, iIdx) => {
            const banner = isMobile ? i?.imageUrls?.['bannerUrl-mobile'] : i?.imageUrls?.bannerUrl;
            return (
              <CarouselItem key={`Banners_${i.name}_${iIdx}`}>
                <img
                  className="partners-carousel-banner"
                  src={banner}
                  alt={i?.name}
                />
              </CarouselItem>
            );
          })}
        </Carousel>
      </Col>
    </Row>
  </div>
);