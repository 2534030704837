import React from 'react';
import './ReferralMechanics.scss';

export const ReferralMechanicsFAQs = ({ title, items }) => (
  <div className="referral-mechanics-faqs">
    <h1 className="referral-mechanics-title">{title}</h1>
    <ol>
      {items.map((f, fIdx) => {
        let answers = <ol>
          {f.answers.map((a, aIdx) => (
            <li key={`ReferAFriendFAQ_${fIdx + 1}_Answer_${aIdx + 1}`}>
              <div dangerouslySetInnerHTML={{ __html: a }} />
            </li>
          ))}
        </ol>;
        return (
          <li key={`ReferAFriendFAQ_${fIdx + 1}`}>
            <div>
              {f.question}
              {answers}
            </div>
          </li>
        );
      })}
    </ol>
  </div>
);