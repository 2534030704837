import React, { Component } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { parsePhoneNumber } from 'react-phone-number-input';
import { DateTime } from 'luxon';
import { Dropdown } from 'react-bootstrap';
import {
  Camera,
  Confirm,
  Datepicker,
  FileUploader,
  Phone,
  PopupMenu,
  Toast
} from '../Commons';
import { LayoutContent } from '../Components';
import { dateTimeFormats, regexConstants } from '../../constants';
import { EditProfileHeader } from './EditProfileHeader';
import './EditProfile.scss';

export class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
      changePhotoMenuOpen: false,
      removePhotoOpen: false,
      invalidFormInputs: [],
      toastOpen: false,
      searchVal: '',
      openCamera: false,
      activeNationality: false
    };

    this.inputRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.props.getUserProfileFields();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fields, toast } = this.props;
    const { activeNationality } = this.state;

    if (prevProps.fields !== fields) {
      if (fields.form) {
        const values = {};
        fields.form.forEach(f => f.fields.forEach(field => {
          values[field.field] = field.value || '';
        }));
        if (fields.imageUrl !== '') values.imageUrl = fields.imageUrl;
        this.setState({ values });
      }
    }

    if (prevProps.toast !== toast && toast) {
      this.openToast(true);
    }

    if (prevState.activeNationality !== activeNationality && activeNationality) {
      this.inputRef.current.focus({ preventScroll: true });
    }
  }

  componentWillUnmount(){
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ activeNationality: false });
    }
  }

  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  }

  closeToast = () => {
    this.openToast(false);
    setTimeout(() => {
      this.props.setDisplayToast();
      window.location.href = "/profile";
    },500);
  }

  handleInput = (value, fieldName) => {
    let newValue = '';
    if (fieldName.includes('dob')) {
      newValue = value;
    } else if (fieldName.includes('username')){
      newValue = value.replace(regexConstants.firstLetterSpace, '')
        .replace(regexConstants.specialCharUnderscoreDot, '');
    } else {
      newValue = value.replace(regexConstants.firstLetterSpace, '')
        .replace(regexConstants.specialChar, '');
    }
    
    this.setState({
      invalidFormInputs: this.state.invalidFormInputs.filter(i => i !== fieldName),
      values: {
        ...this.state.values,
        [fieldName]: newValue
      }
    });
  }

  handleSearch = (e) => {
    const { value } = e.target;
    this.setState({searchVal: value});
  }

  handleBirthdate = (birthdate) => {
    const date = DateTime.fromJSDate(birthdate);
    const dateFieldSection = this.props.fields.form.find(f => f.fields.map(field => field.type).includes('date'));
    const dateField = dateFieldSection.fields.find(f => f.type === 'date');
    const dateFormat = dateField.format.replace('mm', 'MM') || dateTimeFormats.profileDate;
    const formattedDate = date.toFormat(dateFormat);
    this.handleInput(formattedDate, 'dob');
  }

  handleNumericInput = (value, fieldName) => {
    const numericValue = value.replace(regexConstants.nonDigit, '');
    this.handleInput(numericValue, fieldName);
  }

  openChangePhotoMenu = (changePhotoMenuOpen) => {
    this.setState({ changePhotoMenuOpen });
  }

  openCamera = (openCamera) => {
    this.setState({ openCamera });
  }

  submit = () => {
    const { fields, editUserProfile, setLoading } = this.props;
    const { values } = this.state;
    const categorizedRequiredFields = fields.form.map(f => f.fields.filter(field => field.required && field.field !== 'mobnum' && field.field !== 'email'));
    const requiredFields = [].concat(...categorizedRequiredFields).map(f => ({ field: f.field, value: values[f.field] }));
    const newRequiredFields = [];
    fields.form.forEach(f => f.fields.forEach(f => {
      if (!f.value) return;
      if (values[f.field] === '') newRequiredFields.push(f.field);
    }));

    if (requiredFields.map(f => f.value).includes('')) {
      this.setState({ invalidFormInputs: requiredFields.filter(f => !f.value).map(f => f.field) });
    } else if (newRequiredFields.length > 0){
      this.setState({ invalidFormInputs: newRequiredFields });
    } else {
      const nonEmptyValues = pickBy(values, (v) => !isEmpty(v) && !isNil(v));
      const valuesWithoutSubfields = pick(nonEmptyValues, Object.keys(nonEmptyValues).filter(k => !k.includes('.')));
      const valuesWithSubfields = pick(nonEmptyValues, Object.keys(nonEmptyValues).filter(k => k.includes('.')));
      const payload = { ...valuesWithoutSubfields };
      Object.keys(valuesWithSubfields).map(v => v.split('.').reduce((m, s) => payload[m] = {}));
      Object.keys(valuesWithSubfields).map(v => v.split('.').reduce((m, s) => payload[m][s] = valuesWithSubfields[v]));
      this.setState({ invalidFormInputs: [] });
      setLoading(true);
      editUserProfile(payload);
    }
  }

  confirmRemovePhoto = (removePhotoOpen) => {
    this.setState({ removePhotoOpen });
  }

  openRemovePhotoConfirm = () => {
    this.openChangePhotoMenu(false);
    this.confirmRemovePhoto(true);
  }

  changeProfilePhoto = (imageFile) => {
    this.openChangePhotoMenu(false);
    this.props.updateUserProfilePhoto(imageFile);
  }

  removeProfilePhoto = () => {
    this.confirmRemovePhoto(false);
    this.props.removeUserProfilePhoto();
  }

  focusNationality = () => {
    this.setState(({ activeNationality }) => ({ activeNationality: !activeNationality }));
  }

  renderInput = (field) => {
    const { values, invalidFormInputs } = this.state;
    const inputValue = values[field.field] || '';
    switch (field.type) {
      case 'text':
      case 'email':
        const textMaxLength = field.maxLength || 1000;
        return (
          <div key={field.field} className="edit-profile-form">
            <label htmlFor={field.field} className="edit-profile-form-label">{field.title}</label>
            {field.caption && <div className={classNames("edit-profile-form-label", "edit-profile-form-caption")}>{field.caption}</div>}
            <input
              id={field.field}
              type={field.type}
              onChange={(e) => this.handleInput(e.target.value, field.field)}
              className={classNames("edit-profile-form-input", {"edit-profile-form-input-error": invalidFormInputs.includes(field.field)})}
              value={inputValue}
              readOnly={field.readOnly}
              maxLength={textMaxLength}
            />
          </div>
        );
      case 'phone':
        const phoneNumberObj = parsePhoneNumber(field.value);
        const phoneCountryCode = `+${phoneNumberObj.countryCallingCode.replace(regexConstants.whitespace, '')}`;
        const mobileNumberWithoutCountryCode = inputValue.replace(phoneCountryCode, '');
        return (
          <div key={field.field} className="edit-profile-form">
            <label htmlFor={field.field} className="edit-profile-form-label">{field.title}</label>
            {field.caption && <div className={classNames("edit-profile-form-label", "edit-profile-form-caption")}>{field.caption}</div>}
            <Phone
              id={field.field}
              type="text"
              onChange={(e) => this.handleInput(e.target.value, field.field)}
              countryCode={phoneCountryCode}
              value={mobileNumberWithoutCountryCode}
              setCountryCode={this.props.setCountryCode}
              className={classNames("edit-profile-form-input", {"edit-profile-form-input-error": invalidFormInputs.includes(field.field)})}
              dropdownMenuClassName="edit-profile-country-code-menu"
              readOnly={field.readOnly}
              hasFilter
            />
          </div>
        );
      case 'numeric':
        return (
          <div key={field.field} className="edit-profile-form">
            <label htmlFor={field.field} className="edit-profile-form-label">{field.title}</label>
            {field.caption && <div className={classNames("edit-profile-form-label", "edit-profile-form-caption")}>{field.caption}</div>}
            <input
              id={field.field}
              type="text"
              onChange={(e) => this.handleNumericInput(e.target.value, field.field)}
              className={classNames("edit-profile-form-input", {"edit-profile-form-input-error": invalidFormInputs.includes(field.field)})}
              value={inputValue}
            />
          </div>
        );
      case 'date':
        const dateValue = values[field.field] || DateTime.now().toISO();
        return (
          <div key={field.field} className="edit-profile-form">
            <label htmlFor={field.field} className="edit-profile-form-label">{field.title}</label>
            {field.caption && <div className={classNames("edit-profile-form-label", "edit-profile-form-caption")}>{field.caption}</div>}
            <Datepicker dateStr={dateValue} setDate={this.handleBirthdate} className="edit-profile-form-input" />
          </div>
        );
      case 'option':
        const optionValue = field.field === 'nationality' ? (values[field.field] || 'Please select one') : inputValue;
        const { searchVal } = this.state;
        const filteredNationality = field.options.filter(n => n.toLowerCase().startsWith(searchVal.toLowerCase()));
        return (
          <div key={field.field} className="edit-profile-form">
            <label className="edit-profile-form-label">{field.title}</label>
            {field.caption && <div className={classNames("edit-profile-form-label", "edit-profile-form-caption")}>{field.caption}</div>}
            <Dropdown 
              ref={this.wrapperRef}
              onClick={this.focusNationality}
              className="edit-profile-form-dropdown">
              <Dropdown.Toggle className="edit-profile-form-dropdown-toggle">{optionValue}</Dropdown.Toggle>
              <Dropdown.Menu className="edit-profile-form-dropdown-menu">
                <input 
                  ref={this.inputRef}
                  onChange={(e) => this.handleSearch(e)}
                  className="edit-profile-form-dropdown-search"
                />
                <div className="edit-profile-form-dropdown-list">
                  {filteredNationality.map(o => (
                    <Dropdown.Item
                      key={`${field.field}_${o}`}
                      onClick={() => this.handleInput(o, field.field)}
                      active={optionValue === o}
                      className="edit-profile-form-dropdown-item"
                    >{o}</Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      default:
        break;
    }
  }

  render() {
    const { fields, toast } = this.props;
    const { changePhotoMenuOpen, removePhotoOpen, toastOpen, invalidFormInputs, openCamera } = this.state;
    
    if (fields && !isEmpty(fields)) {
      const profilePhotoStyle = fields.imageUrl && fields.imageUrl !== '' ? { backgroundImage: `url(${fields.imageUrl})` } : null;

      return (
        <LayoutContent outerClassName="edit-profile">
          <EditProfileHeader
            profilePhotoStyle={profilePhotoStyle}
            openChangePhotoMenu={() => this.openChangePhotoMenu(true)}
            fields={fields}
            renderInput={this.renderInput}
          />
          <div className="edit-profile-save-changes">
            {invalidFormInputs.length > 0 && <div className='edit-profile-save-changes-error'>Please fill up all required fields.</div>}
            <button className="edit-profile-save-changes-btn" onClick={this.submit}>Save Changes</button>
          </div>
          <Camera open={openCamera} hide={() => this.openCamera(false)} uploadFile={this.changeProfilePhoto}/>
          <PopupMenu open={changePhotoMenuOpen} hide={() => this.openChangePhotoMenu(false)} uploadFile={this.changeProfilePhoto}>
            <div 
              className="edit-profile-popup-menu-btn" 
              onClick={() => {
                this.openCamera(true)
                this.openChangePhotoMenu(false)
              }}>Camera
            </div>
            <FileUploader buttonText="Import from Gallery" buttonClassName="edit-profile-popup-menu-btn" uploadFile={this.changeProfilePhoto} />
            <button className={classNames("edit-profile-popup-menu-btn", "edit-profile-popup-menu-btn-warning")} onClick={this.openRemovePhotoConfirm}>Remove Profile Photo</button>
          </PopupMenu>
          <Confirm
            open={removePhotoOpen}
            title="Remove Profile Photo"
            text="Are you sure you want to remove your profile photo?"
            confirm={this.removeProfilePhoto}
            confirmText="Delete"
            hide={() => this.confirmRemovePhoto(false)}
            hideText="Cancel"
          />
          {toast && <Toast toast={toast} open={toastOpen} hide={this.closeToast} />}
        </LayoutContent>
      );
    }

    return null;
  }
}