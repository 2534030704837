import { isDev } from './defaults';

const firebaseConfigDev = {
  apiKey: "AIzaSyDH2j08X0_zdbZx87Q9UCItavfiCRCCeIM",
  authDomain: "mgodev-45ad4.firebaseapp.com",
  databaseURL: "https://mgodev-45ad4-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "mgodev-45ad4",
  storageBucket: "mgodev-45ad4.appspot.com",
  messagingSenderId: "426611402355",
  appId: "1:426611402355:web:4980efa7e2e2aa7e5e2c06",
  measurementId: "G-59PQVDGL9S"
};
const firebaseConfigProd = {
  apiKey: "AIzaSyB5JnVug5pV9R-4EfzBXfWahUzLqXOvaB4",
  authDomain: "metaversego-ddf78.firebaseapp.com",
  databaseURL: "https://metaversego-ddf78-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "metaversego-ddf78",
  storageBucket: "metaversego-ddf78.appspot.com",
  messagingSenderId: "786508573549",
  appId: "1:786508573549:web:c5c278e92d39a2ef9ec864",
  measurementId: "G-MQVF69KX69"
};
export const firebaseConfig = isDev ? firebaseConfigDev : firebaseConfigProd;

const vapidKeyDev = 'BCiZ5H7Iz302w18n_jp_zZBI3zdpAMypyc1NPaFPvjlqSulnn5A5JnKEHsWtf3AzM-Yz2Ac64nIzwwl0AstyU5s';
const vapidKeyProd = 'BFIq8uR8l0RVWAMwDjbmF0Dqw0wyR7Xnfu5OiYkM0bAHUc_HvKZTCiAQICNe0VV7td1XVR1-e6__ybrcJ9RnTs8';
export const fcmVapidKey = isDev ? vapidKeyDev : vapidKeyProd;

export const firebaseEvents = {
  qrScan: 'qr-scan',
  qrRedeem: 'qr_redeem',
  playGame: 'play_game',
  appLaunch: 'app_launch',
  supportFromSocial: 'support_from_social',
  failedEvent: 'failed_event',
  applyForScholarship: 'apply_for_scholarship',
  signUp: 'sign_up',
  signUpClick: 'sign_up_click',
  signUpPage: 'sign_up_page',
  registerFailed: 'register_failed',
  registerComplete: 'register_complete',
  login: 'login',
  loginPage: 'login_page',
  loginPIN: 'login_pin',
  loginSuccess: 'login_success',
  loginFailed: 'login_failed',
  authenticationPage: 'authentication_page',
  resentOTPSMS: 'resend_otp_sms',
  resentOTPEmail: 'resend_otp_email',
  sellNFT: 'sell_nft',
  buyNFT: 'buy_nft',
  cancelNFTListing: 'cancel_nft_listing',
  buyLoad: 'buy_load',
  spendVirtualCurrency: 'spend_virtual_currency',
  gamesHome: 'games_home',
  gamesMain: 'games_main',
  gamesNewAllPage: 'games_new_all_page',
  gamesCasualAllPage: 'games_casual_all_page',
  gamesPage: 'games_page',
  gamesLaunch: 'games_launch',
  nftMarketplacePage: 'nft_marketplace_page',
  nftMarketplaceBuyPage: 'nft_marketplace_buy_page',
  nftMarketplaceBuyForConfirmation: 'nft_marketplace_buy_forconfirmation',
  nftMarketplaceBuySuccess: 'nft_marketplace_buy_success',
  nftMarketplaceBuyFail: 'nft_marketplace_buy_fail',
  nftMarketplaceSell: 'nft_marketplace_sell',
  nftMarketplaceSellConfirmation: 'nft_marketplace_sell_confirmation',
  walletViewNFT: 'wallet_view_nft',
  walletTopupInitiated: 'wallet_topup_initiated',
  walletTopupSelectPayment: 'wallet_topup_selectpayment',
  walletTopupProceedPayment: 'wallet_topup_proceedpayment',
  walletTopupSuccess: 'wallet_topup_success',
  walletTopupFailed: 'wallet_topup_failed',
  walletWithdraw: 'wallet_withdraw',
  guildViewAll: 'guild_view_all',
  guildView: 'guild_view',
  guildApplyScholarship: 'guild_apply_scholarship',
  guildSubmitScholarship: 'guild_submit_scholarship',
  guildSubmitScholarshipSuccess: 'guild_submit_scholarship_success',
  wenLamboHome: 'wenlambo_home',
  wenLamboHomePlay: 'wenlambo_home_play',
  wenLamboInviteCode: 'wenlambo_invitecode',
  wenLamboJoin: 'wenlambo_join',
  wenLamboWaitlistSignup: 'wenlambo_waitlist_signup',
  wenLamboWaitlistForm: 'wenlambo_waitlist_form',
  wenLamboWaitlistSubmit: 'wenlambo_waitlist_submit',
  wenLamboMessagesJoin: 'wenlambo_messages_join',
  wenLamboTutorial: 'wenlambo_tutorial',
  wenLamboTutorialSkip: 'wenlambo_tutorial_skip',
  wenLamboTutorialConfirm: 'wenlambo_tutorial_confirm',
  wenLamboTC: 'wenlambo_tc',
  wenLamboTCAccept: 'wenlambo_tc_accept',
  wenLamboTCReject: 'wenlambo_tc_reject',
  wenLamboFreeGamePassClaim: 'wenlambo_freegamepass_claim',
  wenLamboFreeGamePassReject: 'wenlambo_freegamepass_reject',
  wenLamboMain: 'wenlambo_main',
  wenLamboMainBuy: 'wenlambo_main_buy',
  wenLamboMainPlay: 'wenlambo_main_play',
  wenLamboMainPlayNoGamePass: 'wenlambo_main_play_nogamepass',
  wenLamboPlaying: 'wenlambo_playing',
  wenLamboQuit: 'wenlambo_quit',
  wenLamboMainSPW: 'wenlambo_main_spw',
  wenLamboMainSPWCarousel: 'wenlambo_main_spwcarousel',
  wenLamboSPWPage: 'wenlambo_spw_page',
  wenLamboSPWSpin: 'wenlambo_spw_spin',
  wenLamboSPWSpinAgain: 'wenlambo_spw_spinagain',
  wenLamboSPWShare: 'wenlambo_spw_share',
  wenLamboSPWRedeem: 'wenlambo_spw_redeem',
  wenLamboMainRefer: 'wenlambo_main_refer',
  wenLamboSPWRefer: 'wenlambo_spw_refer',
  wenLamboRefer: 'wenlambo_refer',
  wenLamboReferShare: 'wenlambo_refer_share',
  wenLamboReferInvite: 'wenlambo_refer_invite',
  wenLamboMainPlayFailed: 'wenlambo_main_play_failed',
  wenLamboSPWFailed: 'wenlambo_spw_failed',
  wenLamboDailyRewardsClaim: 'wenlambo_dailyrewards_claim',
  wenLamboDailyRewardsUnclaim: 'wenlambo_dailyrewards_unclaim',
  wenLamboDailyRewardsClaimed: 'wenlambo_dailyrewards_claimed',
  wenLamboReferCopy: 'wenlambo_refer_copy',
  wenLamboOnboardingClaim: 'wenlambo_onboarding_claim',
  wenLamboOnboardingClaimed: 'wenlambo_onboarding_claimed',
  wenLamboOnboardingSignup: 'wenlambo_onboarding_signup',
  wenLamboOnboardingPlayNow: 'wenlambo_onboarding_playnow',
  wenLamboOnboardingSelectGame: 'wenlambo_onboarding_selectgame',
  wenLamboOnboardingSelectGamePlay: 'wenlambo_onboarding_selectgame_play',
  wenLamboOnboardingFirstSpinView: 'wenlambo_onboarding_firstspin_view',
  wenLamboOnboardingFirstSpinSpin: 'wenlambo_onboarding_firstspin_spin',
  homeWalkthrough: 'home_walkthrough',
  homeWalkthroughReject: 'home_walkthrough_reject',
  homeWalkthroughStart: 'home_walkthrough_start',
  homeWalkthroughSkip: 'home_walkthrough_skip',
  homeWalkthroughEnd: 'home_walkthrough_end',
  homeGamePassBuy: 'home_gamepass_buy',
  homeGamePassBuyForConfirmation: 'home_gamepass_buy_forconfirmation',
  homeGamePassBuyPay: 'home_gamepass_buy_pay',
  homeGamePassBuyAuthentication: 'home_gamepass_buy_authentication',
  homeGamePassBuyAuthenticationSuccess: 'home_gamepass_buy_authentication_success',
  homeGamePassBuyAuthenticationFailed: 'home_gamepass_buy_authentication_failed',
  homeGamePassBuyProcessing: 'home_gamepass_buy_processing',
  homeGamePassBuySuccess: 'home_gamepass_buy_success',
  homeGamePassBuyFail: 'home_gamepass_buy_fail',
  homeGamePassBuyActivate: 'home_gamepass_buy_activate',
  inventoryGamePassBuy: 'inventory_gamepass_buy',
  inventoryGamePassActivate: 'inventory_gamepass_activate',
  initiateBuy: 'initiate_buy',
  registerInitiate: 'register_initiate',
  loginInitiate: 'login_initiate',
  buySuccess: 'buy_success',
  buyFailed: 'buy_failed',
  buyProcessing: 'buy_processing',
  activateGamePass: 'activate_gamepass'
};

export const notificationPermissions = {
  default: 'default',
  denied: 'denied',
  granted: 'granted'
};
export const remoteConfigKeys = {
  strippedStwEnabled: 'stripped_stw_enabled',
  strippedGameUrls: 'stripped_game_urls',
  strippedStwUsers: 'stripped_stw_w_users',
  conquestStwUrl: 'conquest_stw_url',
  login_mode: 'login_mode'
};