import { 
  RESET_STATE, 
  SET_CLAIM_DATA, 
  SET_FEATURED_PROMOTION, 
  SET_REDEEM, 
  SET_REDEEM_DATA 
} from "../actions/constants";

const redeemState = {
  data: {},
  claimData: {},
  featuredPromotion: {},
  redeem: {}
};

const redeemReducer = (state = redeemState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return redeemState;
    case SET_REDEEM_DATA:
      return {
        ...state,
        data: action.data
      };
    case SET_CLAIM_DATA:
      return {
          ...state,
          claimData: action.claimData
      };
    case SET_FEATURED_PROMOTION:
      return {
          ...state,
          featuredPromotion: action.featuredPromotion
      };
    case SET_REDEEM:
      return {
          ...state,
          redeem: action.redeem
      };
    default:
      return state;
  }
}

export default redeemReducer;