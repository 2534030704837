import { useEffect, useState } from 'react';

export const useWebGLDetector = () => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    const canvas = document.createElement("canvas");
  
    const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    const isBrowserSupportsWebGL = gl instanceof WebGLRenderingContext
  
    setIsSupported(isBrowserSupportsWebGL);
  }, [])

  return isSupported;
};
