import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from 'react-bootstrap';
import { maxWidths } from '../../constants';
import './Quests.scss';

export const QuestList = ({ quests }) => {
  const smallerDisplay = useMediaQuery({ maxWidth: maxWidths.desktopLG });
  return (
    <div className="quests-list">
      {
        quests.map(q => (
          <Row className="quests-grids">
            {
              q.questlines.map(ql => {
                const ctaProps = {
                  href: ql.action.kind === 'IntRoute' ? `/${ql.action.uri}` : ql.action.uri,
                  target: ql.action.kind === 'IntRoute' ? '_self' : '_blank'
                };
                return (
                  <Col key={`${q.quest}`} xs="12" sm="12" md="6" className="quests-grid">
                    <div className={classNames("quests-grid-inner", "quests-grid-inner-columns")}>
                      <Row className="quests-list-item">
                        {!smallerDisplay && <Col xs="2" sm="2" className={classNames("quests-list-xp", "quests-list-xp-desktop")}>500 XP</Col>}
                        <Col xs="7" sm="7" xl="6" className="quests-list-quest">
                          {smallerDisplay && <div className="quests-list-xp">500 XP</div>}
                          <div>{ql.name}</div>
                        </Col>
                        <Col xs="5" sm="5" xl="4" className="quests-list-completed">
                          <a {...ctaProps} rel="noreferrer" className="quests-list-completed-button">{ql.action.ctaText}</a>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })
            }
          </Row>
        ))
      }
    </div>
  );
}