import axios from 'axios';
import { baseUrl } from './url';

export function requestNFTCollectionDetails(nftId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`nfts/${nftId}`)
  });
}

export function requestNFTItemDetails(nftId, nftItemId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`nfts/${nftId}/editions/${nftItemId}`)
  });
}

export function requestBuyNFTItem(nftItemId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`buy/nft/${nftItemId}`)
  })
}