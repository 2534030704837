import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { disableFooterPointerEvent } from '../../../actions';
import { mobileAppPromptText } from '../../../constants';
import mgoIcon from '../../../assets/logo/icon.png';
import './RedirectToMobileAppToast.scss';

const RedirectToMobileAppToast = ({ open, hide, title, description }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) return;
    dispatch(disableFooterPointerEvent(true));
    return () => dispatch(disableFooterPointerEvent(false));
  }, [dispatch, open])

  const handleHide = () => {
    dispatch(disableFooterPointerEvent(false));
    hide();
  }

  const handleRedirect = () => {
    window.location.href = mobileAppPromptText.redirectLink;
  }

  return (
    <Modal
      backdrop
      show={open}
      className="mobile-app-toast-container"
      contentClassName="mobile-app-toast"
      dialogClassName="mobile-app-toast-dialog"
      backdropClassName="mobile-app-toast-bg"
      centered
    >
      <Modal.Body>
        <CloseRoundedIcon onClick={handleHide} className="mobile-app-toast-close" />
        <div className="mobile-app-toast-wrapper">
          <div className="mobile-app-toast-contents">
            <img
              src={mgoIcon}
              alt="mgo-icon"
              className="mobile-app-toast-icon"
              width={40}
              height={40}
            />
            <div className="mobile-app-toast-texts">
              <div className="mobile-app-toast-title">{title}</div>
              {description && 
                <div className="mobile-app-toast-description">{description}</div>}
              <button onClick={handleRedirect} className="mobile-app-toast-button mt-2">{mobileAppPromptText.button}</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RedirectToMobileAppToast;