
import setMegaPrize from '../utils/setMegaPrize';
import wenLamboTutorial from '../assets/gamepass/tutorial/wen-lambo-beta2.png';
import wenLamboTutorialProd from '../assets/gamepass/tutorial/wen-lambo-beta.png';
import buyGamePassTutorial from '../assets/gamepass/tutorial/buy-gamepass-beta2.png';
import buyGamePassTutorialProd from '../assets/gamepass/tutorial/buy-gamepass-beta.png';
import playGamesTutorial from '../assets/gamepass/tutorial/play-games-beta2.png';
import playGamesTutorialProd from '../assets/gamepass/tutorial/play-games-beta.png';
import spinTheWheelTutorial from '../assets/gamepass/tutorial/spin-the-wheel-beta2.png';
import spinTheWheelTutorialProd from '../assets/gamepass/tutorial/spin-the-wheel-beta.png';
import activeGamePassGuidance from '../assets/gamepass/tutorial/user-guidance/walkthrough-1.png';
import goldCoinsGuidance from '../assets/gamepass/tutorial/user-guidance/walkthrough-2.png';
import buyGamePassGuidance from '../assets/gamepass/tutorial/user-guidance/walkthrough-3.png';
import playGamesGuidance from '../assets/gamepass/tutorial/user-guidance/walkthrough-4.png';
import spinTheWheelGuidance from '../assets/gamepass/tutorial/user-guidance/walkthrough-5.png';
import welcomeToMetaverseGoTutorial from '../assets/tutorial/1.png';
import winPrizesTutorial from '../assets/tutorial/2.png';
import exploreGamesTutorial from '../assets/tutorial/3.png';
import collectNftsTutorial from '../assets/tutorial/4.png';
import playAndEarnCreditsTutorial from '../assets/tutorial/5.png';
import storeAssetsSecurelyTutorial from '../assets/tutorial/6.png';
import stayUpdatedTutorial from '../assets/tutorial/7.png';
import customizeYourProfileTutorial from '../assets/tutorial/8.png';

export const tutorialText = [
  {
    title: 'Welcome to MetaverseGo!',
    desc: 'Your journey to the Metaverse starts now! Here’s a quick guide about the app.',
    image: welcomeToMetaverseGoTutorial,
    continueButton: 'Continue',
    maybeLaterButton: 'Maybe Later'
  },
  {
    title: 'Win prizes',
    desc: 'Get a Game Pass, play games, farm Gold, and spin the wheel for a chance to win prizes!',
    image: winPrizesTutorial,
  },
  {
    title: 'Explore games',
    desc: 'View our library of blockchain games across different genres: strategy, arcade, RPG, and many more',
    image: exploreGamesTutorial
  },
  {
    title: 'Collect NFTs',
    desc: 'Get exclusive access to our selection of NFTs from brands, MGOs, and games with exciting rewards',
    image: collectNftsTutorial
  },
  {
    title: 'Play and earn credits',
    desc: 'Apply for guild scholarships and learn from the best to earn MetaverseGo credits',
    image: playAndEarnCreditsTutorial
  },
  {
    title: 'Store assets securely',
    desc: 'Keep track of all your transactions and buy load and more in your MetaverseGo wallet',
    image: storeAssetsSecurelyTutorial
  },
  {
    title: 'Stay updated',
    desc: 'Receive the latest updates from guilds, games, brand partners, and latest campaigns via your messages!',
    image: stayUpdatedTutorial
  },
  {
    title: 'Customize your profile',
    desc: 'Personalize your profile and update your photo to get the best in-app experience',
    image: customizeYourProfileTutorial
  }
];
export const walletActiveNumber = 6;
export const inboxActiveNumber = 7;
export const profileActiveNumber = 8;

export const wenLamboTutorials = (dtiDemo) => {
  const wenLamboMegaPrize = setMegaPrize(dtiDemo);
  return [
    { 
      text: 'Welcome to Wen Lambo!',
      description: `Your journey starts here! Buckle up as we bring you a chance to take home a ${wenLamboMegaPrize} and more by simply playing our selection of games.`,
      image: {
        dtiDemo: wenLamboTutorial,
        nonDtiDemo: wenLamboTutorialProd
      }
    }, 
    { 
      text: 'Get a Game Pass',
      description: 'A Game Pass is your grand ticket to explore thrilling games, earn Gold, and spin the wheel to get prizes for a limited time.',
      image: {
        dtiDemo: buyGamePassTutorial,
        nonDtiDemo: buyGamePassTutorialProd
      }
    }, 
    { 
      text: 'Play Games to Earn Gold',
      description: 'From Meta Hoops to Jumpverse, unleash your competitive spirit through our exciting gameplay and be rewarded with Gold!',
      image: {
        dtiDemo: playGamesTutorial,
        nonDtiDemo: playGamesTutorialProd
      }
    }, 
    { 
      text: 'Spin the Wheel to win Prizes',
      description: `Unlock prizes with the community all the way to a ${wenLamboMegaPrize}, and test your luck by spinning the wheel. What are you waiting for?`,
      image: {
        dtiDemo: spinTheWheelTutorial,
        nonDtiDemo: spinTheWheelTutorialProd
      }
    }, 
  ];
}
export const wenLamboTutorialMinScreens = 1;
export const wenLamboWalkthrough = [
  {
    id: 'active-gamepass',
    text: 'Game Pass & Gold Boosters Inventory',
    description:'Track the remaining time of your Game Pass and Gold Booster and activate more when needed here!',
    image: activeGamePassGuidance
  },
  {
    id: 'gold-coins',
    text: 'Gold Coins',
    description:'Check how much Gold you earn from playing games and how much Gold you need before you can spin!',
    image: goldCoinsGuidance
  },
  {
    id: 'buy-gamepass',
    text: 'Buy Game Pass',
    description:'A Game Pass grants you the ability to earn Gold just by playing games! It’s the first step to getting the Wen Lambo!',
    image: buyGamePassGuidance
  },
  {
    id: 'play-and-earn-gold',
    text: 'Play Games',
    description:'Put your Game Pass to use and unleash your competitive spirit by playing our games and earning Gold!',
    image: playGamesGuidance
  },
  {
    id: 'win-prizes',
    text: 'Spin the Wheel',
    description: 'Get a chance to win exciting prizes by spinning the wheel!',
    image: spinTheWheelGuidance
  },
];
export const buttonAction = {
  previous: 'previous',
  next: 'next'
};
export const buttonActionText = {
  previous: 'Previous',
  next: 'Next',
  skip: 'Skip',
  finish: 'Finish'
};
export const onBoardingData = {
  onboard: 'onboard',
  ids: {
    gamePassTutorial: 'gamepass-tutorial',
    playAndEarnGold: 'play-and-earn-gold'
  },
  flags: {
    activate: 'activateGamePass',
    modal: 'playOptions',
    games: 'otherGames'
  },
  titles: {
    tapActivate: 'Tap Activate',
    selectGame: 'Select a Game',
    playNowAndEarnGold: 'Play to Win big!',
    gameAndEarn: 'Try playing Meta Hoops to start your gold-earning journey for your next spin on the wheel',
    playNow: 'Play Now',
    exploreOtherGames: 'Explore Other Games',
    activateAndPlayNow: 'Activate & Play Now'
  },
  selectedGame: 'metahoops',
  from: 'from',
  selectedSku: 'game-pass-30M'
}