import React, { Component } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { LayoutContent, PageTitle, Section } from '../Components';
import { Toast } from '../Commons';
import { defaultTimeoutMS, pinSimilar, pinSuccess, regexConstants } from '../../constants';
import './ResetPIN.scss';

export class ResetPIN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        current: '',
        new: '',
        retype: ''
      },
      passwordRevealed: {
        current: false,
        new: false,
        retype: false
      },
      invalidInputs: [],
      toastOpen: false,
      success: false
    };
  }

  componentDidMount() {
    this.props.setLoading(false);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.toast !== this.props.toast && this.props.toast) {
      this.openToast(true);
      this.resetPINInputs();
      if (this.props.toast.message === pinSuccess) this.setPINSuccess(true);
    };
  }

  openToast = (toastOpen) => {
    this.setState({ toastOpen });
  }

  closeToast = () => {
    this.openToast(false);
    setTimeout(() => {
      this.props.setDisplayToast();
      if (this.state.success) {
        this.setPINSuccess(false);
        window.location.href = '/profile';
      }
    }, defaultTimeoutMS);
  }

  resetPINInputs = () => {
    this.setState({
      formData: {
        current: '',
        new: '',
        retype: ''
      }
    });
  }

  revealPassword = (key, revealed) => {
    this.setState({
      passwordRevealed: {
        ...this.state.passwordRevealed,
        [key]: revealed
      }
    });
  }

  handleInput = (e, fieldName) => {
    this.setState({
      invalidInputs: this.state.invalidInputs.filter(i => i !== fieldName),
      formData: {
        ...this.state.formData,
        [fieldName]: e.target.value.replace(regexConstants.nonDigit, '')
      }
    });
  }

  submit = () => {
    const { setLoading, setDisplayToast } = this.props;
    const { formData } = this.state;
    const formDataValues = Object.keys(formData).map(d => formData[d]);

    if (!formDataValues.includes('')) {
      if (formData.current === formData.new) {
        this.setState({ invalidInputs: Object.keys(formData) });
        setDisplayToast({
          result: false,
          message: 'Please enter different PIN.'
        });
      } else if (formData.new !== formData.retype) {
        this.setState({ invalidInputs: ['new', 'retype'] });
        setDisplayToast({
          result: false,
          message: 'Please enter the correct PIN.'
        });
      } else {
        setLoading(true);
        this.props.validatePIN(formData.current, formData.new);
      }
    } else {
      this.setState({ invalidInputs: Object.keys(formData).filter(d => formData[d] === '') });
    }
  }

  setPINSuccess = (success) => {
    this.setState({ success });
  }

  render() {
    const { currentPIN, inputs, toast } = this.props;
    const { formData, passwordRevealed, invalidInputs, toastOpen } = this.state;

    return (
      <LayoutContent 
        outerClassName="reset-pin"
        innerClassName="reset-pin-inner"
      >
        <PageTitle title="Reset PIN" />
        <Section title="Reset PIN" backButtonRoute="/profile/security" sectionClassName="reset-pin-section" sectionHeaderClassName="reset-pin-section-header" titleCentered />
        <div className="reset-pin-form">
          {inputs.map(i => {
            const retype = i.name === 'retype';
            return (
              <div className="reset-pin-row" key={`FormData_${i.name}`}>
                <span className="reset-pin-label">{i.label}</span>
                <div className={classNames("reset-pin-group", {"reset-pin-group-error": invalidInputs.includes(i.name)})}>
                  <input
                    type={passwordRevealed[i.name] ? 'text' : 'password'}
                    className="reset-pin-input"
                    onChange={(e) => this.handleInput(e, i.name)}
                    value={formData[i.name]}
                    maxLength={6}
                  />
                  <div onClick={() => this.revealPassword(i.name, !passwordRevealed[i.name])} className="reset-pin-password-reveal">
                    {passwordRevealed[i.name] ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                  </div>
                </div>
                {i.hint && <div className="reset-pin-hint">{i.hint}</div>}
                {retype && currentPIN === formData.new && !isEmpty(invalidInputs) && <div className={classNames("reset-pin-hint", "reset-pin-error")}>{pinSimilar}</div>}
              </div>
            );
          })}
        </div>
        <div className="reset-pin-submit">
          <button className="reset-pin-submit-btn" onClick={this.submit}>Save Changes</button>
        </div>
        {toast && <Toast toast={toast} open={toastOpen} hide={this.closeToast} />}
      </LayoutContent>
    );
  }
}