import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import LayoutContent from '../LayoutContent/LayoutContent';
import PageTitle from '../PageTitle/PageTitle';
import { TableOfContents } from './TableOfContents';
import { Sections } from './Sections';
import { BackHeader } from '../../Commons';
import { setHideFooter, setHideMenuIcon } from '../../../actions';
import './LegalStatement.scss';

const LegalStatement = ({
  pageTitle,
  type,
  content,
  hasSectionPrefix = false,
  sectionTitleCentered = false
}) => {
  const { lastModified, title, introduction, tableOfContents, sections } = content;
  const lastModifiedLabel = type === 'TermsAndConditions' ? 'Last Modified' : 'Date Updated';
  const showNothingFollows = type !== 'FairPlayPolicy';

  const dispatch = useDispatch();
  const search = useLocation().search;
  const mobile = new URLSearchParams(search).get('mobile');

  const backToPreviousPage = () => window.history.back();

  useEffect(() => {
    dispatch(setHideFooter(true));
    dispatch(setHideMenuIcon(true));
    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideMenuIcon(false));
    }
  }, [dispatch]);

  return (
    <LayoutContent outerClassName="legal-statement">
      <PageTitle title={pageTitle} />
      {!mobile &&
        <BackHeader backToPreviousPage={backToPreviousPage} />}
      {lastModified &&
        <div className="legal-statement-last-modified">
          {lastModifiedLabel}: {lastModified}
        </div>}
      <div dangerouslySetInnerHTML={{ __html: title }} className="legal-statement-title" />
      {introduction.map((i, idx) => (
        <div
          key={`Introduction_${idx}`}
          className="legal-statement-paragraph"
          dangerouslySetInnerHTML={{ __html: i }}
        />
      ))}
      {tableOfContents && 
        <TableOfContents {...tableOfContents} />}
      {sections &&
        <Sections
          type={upperFirst(camelCase(type))}
          sections={sections}
          hasSectionPrefix={hasSectionPrefix}
          titleCentered={sectionTitleCentered}
        />}
      {showNothingFollows && 
        <div className="legal-statement-end">
          ***<span>Nothing Follows</span>***
        </div>}
    </LayoutContent>
  );
}

export default LegalStatement;