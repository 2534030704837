import axios from 'axios';
import { baseUrl } from './url';

export function requestLaunchpadCollection(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('launchpad', 'v2')
  });
}

export function requestLaunchpadCollectionItem(slug, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`launchpad/${slug}`, 'v2')
  });
}

export function requestPrizeSlug(slug, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/qr-redeem/${slug}`)
  });
}

export function requestUnliMintSlug(slug, source, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/nft-redeem/${slug}${source}`)
  });
}

export function requestMintNft(body, slug, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`launchpad/${slug}/mint`, 'v2')
  });
}

export function requestQRMintNft(body, slug, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`launchpad/${slug}/qr-mint`, 'v2')
  });
}

export function requestUnliMintNft(body, slug, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`launchpad/${slug}/unli-mint`, 'v2')
  });
}