import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  GamePassTermsSection,
  LayoutContent,
  MechanicsCarousel,
  PageTitle,
  Section
} from '../Components';
import { Overview } from './Overview';
import { Eligibility } from './Eligibility';
import { FAQs } from './FAQs';
import { setHideFooter, setHideHeader } from '../../actions';
import { spinTheWheelMechanics } from '../../constants';
import { useQuery } from '../../hooks';
import './SpinTheWheelMechanics.scss';

export const SpinTheWheelMechanics = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const mobile = query.get('mobile');
  const backToPreviousPage = () => window.history.back();

  const overviewSection = useRef();
  const eligibilitySection = useRef();
  const mechanicsSection = useRef();
  const faqsSection = useRef();
  const termsSection = useRef();

  const [selectedSection, selectSection] = useState('');
  const [isDropdownOpen, openDropdown] = useState(false);
  const [sections, setSections] = useState([]);
  
  useEffect(() => {
    dispatch(setHideHeader(true));
    dispatch(setHideFooter(true));
    return () => {
      dispatch(setHideHeader(false));
      dispatch(setHideFooter(false));
    }
  }, [dispatch]);

  useEffect(() => {
    const pageSections = spinTheWheelMechanics &&
      spinTheWheelMechanics.sections &&
      spinTheWheelMechanics.sections.map(s => s.name);
    setSections(pageSections);
  }, [spinTheWheelMechanics]);

  const renderSections = (sections) => {
    return sections.map(s => {
      switch (s.name) {
        case 'Overview':
          return (
            <div key={`STWSection_${s.name}`} ref={overviewSection}>
              <Overview {...s} />
            </div>
          );
        case 'Eligibility':
          return (
            <div key={`STWSection_${s.name}`} ref={eligibilitySection}>
              <Eligibility {...s} />
            </div>
          );
        case 'Mechanics':
          return (
            <div key={`STWSection_${s.name}`} ref={mechanicsSection}>
              <MechanicsCarousel {...s} className="stw-mechanics" />
            </div>
          );
        case 'FAQs':
          return (
            <div key={`STWSection_${s.name}`} ref={faqsSection}>
              <FAQs {...s} />
            </div>
          );
        case 'Terms & Conditions':
          return (
            <div key={`STWSection_${s.name}`} ref={termsSection}>
              <GamePassTermsSection {...s} />
            </div>
          );
        default:
          return null;
      }
    });
  }

  const closeDropdown = () => {
    openDropdown(false);
  }

  const handleSelectSection = (section, sectionRef) => {
    selectSection(section);
    closeDropdown();
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const overview = spinTheWheelMechanics.sections.find(s => s.name === 'Overview');
    selectSection(overview.name);
  }, [spinTheWheelMechanics]);

  const title = mobile ? '' :  spinTheWheelMechanics.title;

  return (
    <>
      {spinTheWheelMechanics &&
        <LayoutContent outerClassName="stw-mechanics" innerClassName="stw-mechanics-wrapper">
          <PageTitle title="Spin the Wheel Mechanics" />
          <Section 
            sectionClassName="stw-mechanics-section"
            sectionHeaderClassName="stw-mechanics-header"
            backButtonRoute={!mobile}
            backRoute={backToPreviousPage}
            title={title}
            titleCentered
          >
            <div className="stw-mechanics-content">
              <button
                onClick={() => openDropdown(!isDropdownOpen)}
                className="stw-mechanics-dropdown-toggle"
              >
                <div>{selectedSection}</div>
                {isDropdownOpen ?
                  <KeyboardArrowUpRoundedIcon /> :
                  <KeyboardArrowDownRoundedIcon />}
              </button>
              <div>{renderSections(spinTheWheelMechanics.sections)}</div>
            </div>
          </Section>
          <div className={classNames(
            "stw-mechanics-dropdown-list",
            {"stw-mechanics-dropdown-list-visible": isDropdownOpen}
          )}>
            <div
              className="stw-mechanics-dropdown-list-overlay"
              onClick={closeDropdown}
            />
            <div className="stw-mechanics-dropdown-list-dialog">
              {sections.map(s => {
                const selected = selectedSection === s;
                let selectedRef = overviewSection;
                if (s === 'Eligibility') {
                  selectedRef = eligibilitySection;
                } else if (s === 'Mechanics') {
                  selectedRef = mechanicsSection;
                } else if (s === 'FAQs') {
                  selectedRef = faqsSection;
                } else if (s === 'Terms & Conditions') {
                  selectedRef = termsSection;
                }
                return (
                  <div
                    key={`SpinTheWheelMechanics_Section_${s}`}
                    className={classNames(
                      "stw-mechanics-dropdown-list-item",
                      {"stw-mechanics-dropdown-list-item-selected": selected}
                    )}
                    onClick={() => handleSelectSection(s, selectedRef)}
                  >
                    {s}
                    {selected && <CheckRoundedIcon />}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="stw-mechanics-dti">{spinTheWheelMechanics.dtiText}</div>
        </LayoutContent>}
    </>
  );
}