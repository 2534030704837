import React from 'react';
import classNames from 'classnames';
import {
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { topUpChannelKinds, topUpChannels } from '../../constants';

export const TopUpChannel = ({ 
  name,
  imageUrl,
  channels,
  handleChange,
  value,
  handleKindTab,
  kind,
  openTabs,
  isDesktop
}) => {
  const handleTab = () => {
    handleKindTab(kind);
  }
  const openTab = openTabs?.includes(kind);

  return (
    <div className={classNames(
      "buy-card",
      {"buy-card-active": openTab}
    )}>
      <div 
        onClick={handleTab}
        className={classNames(
          "buy-card-header",
          {"buy-card-header-active": openTab}
        )}>
        <div className={classNames(
          "buy-card-method-wrapper", {
            "buy-card-method-wrapper-card": kind === topUpChannelKinds.card,
            "buy-card-method-wrapper-card-open": openTab && kind === topUpChannelKinds.card
        })}>
          <img
            src={imageUrl}
            alt="method"
            className="buy-card-method"
          />
        </div>
        <div className="d-flex flex-column gap-3 w-100">
          <div className="buy-text">{name}</div>
          {!openTab &&
            kind !== topUpChannelKinds.card &&
            <div className={classNames(
              "buy-icon",
              {"buy-icon-hide": openTab}
            )}>
              {channels.map((i, idx) => {
                const isShopeePay = i.code.toLowerCase() === topUpChannels.shopeePay.toLowerCase();
                const hideShopeePay = isShopeePay && isDesktop; //Temporarily hide shopee pay on desktop view.
                return (
                  <img
                    key={`BUY_ICON_${idx}`}
                    src={i.imageUrl}
                    alt="method"
                    className={classNames(
                      "buy-icon-method",
                      {"buy-icon-method-hide": hideShopeePay}
                    )}
                  />
                );
              })}
            </div>}
        </div>
      </div>
      <div className={classNames(
        "buy-icon buy-icon-tab",
        {"buy-icon-tab-active": openTab}
      )}>
        {channels.map((i, idx) => {
          const isShopeePay = i.code.toLowerCase() === topUpChannels.shopeePay.toLowerCase();
          const hideShopeePay = isShopeePay && isDesktop; //Temporarily hide shopee pay on desktop view.
          return (
            <RadioGroup
              key={`RadioGroup_${idx}`}
              value={value}
              onChange={(e => handleChange(e, i, kind))}
              className={classNames(
                "buy-icon buy-icon-tab-radio",
                {"buy-icon-tab-radio-active": openTab}
              )}
            >
              <div className={classNames(
                "buy-icon-tab-wrapper",
                {"buy-icon-tab-wrapper-hide": hideShopeePay}
              )}>
                <FormControlLabel
                  value={i.name}
                  control={
                    <Radio 
                      sx={{
                        color: "#f304a0",
                        '&.Mui-checked': {
                          color: "#f304a0"
                        }
                      }}
                    />}
                  label={
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={i.imageUrl}
                        alt="method"
                        className="buy-icon-method"
                      />
                      <div className="buy-icon-tab-wrapper-text ms-3">{i.name}</div>
                    </div>}
                />
              </div>
            </RadioGroup>
          )
        })}
      </div>
      {openTab ?
        <KeyboardArrowUpRoundedIcon fontSize="large" className="buy-card-next" /> :
        <KeyboardArrowRightRoundedIcon fontSize="large" className="buy-card-next" />}
    </div>
  );
}