import React, { useEffect, useState } from 'react';
import { 
  useLocation, 
  useNavigate, 
  useSearchParams 
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Popup, Progress, Toast } from '../Commons';
import { LaunchpadItemDetails } from './LaunchpadItemDetails';
import { LaunchpadItemSection } from './LaunchpadItemSection';
import { 
  getDynamicSlug,
  getPrizeSlug,
  mintNFTLaunchpadItem, 
  setDisplayToast, 
  setPrizeSlug, 
  setPrizeSlugData, 
  setUrlParams, 
  unauthorizedRedirect
} from '../../actions';
import {
  canMintSelector, 
  claimedRewardSelector, 
  displayLoginToastSelector, 
  guestSelector, 
  prizeSlugDataSelector, 
  prizeSlugSelector, 
  slugEnableButtonSelector 
} from '../../selectors';
import {
  authSteps,
  defaultTimeoutMS,
  launchpadItemTexts,
  sectionExtraGDay, 
  sectionGMusicFest, 
  sectionSMEvent,
  sectionType,
  storageKeys
} from '../../constants';
import './LaunchpadItem.scss';

export const LaunchpadItemSections = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toastOpen, setToastOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState({});
  const [code, setCode] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useSelector(state => displayLoginToastSelector(state));
  const isGuest = useSelector(state => guestSelector(state));
  const prizeSlug = useSelector(state => prizeSlugSelector(state));
  const prizeSlugData = useSelector(state => prizeSlugDataSelector(state));
  const canMint = useSelector(state => canMintSelector(state));
  const claimedReward = useSelector(state => claimedRewardSelector(state));
  const enableButton = useSelector(state => slugEnableButtonSelector(state));
  const search = useLocation().search;

  const email = new URLSearchParams(search).get('email');
  const voucherCode = new URLSearchParams(search).get('code');
  const astroSlug = new URLSearchParams(search).get('slug');
  const qrSlug = Cookies.get(storageKeys.slug);
  const source = Cookies.get(storageKeys.source);
  const dynamicSlug = Cookies.get(storageKeys.dynamicSlug);

  const { name, description, stats, slug } = item;

  useEffect(() => {
    if (email) {
      setData({ email, voucherCode });
      setUrlParams({ email, voucherCode });
      if (searchParams.has('email')) {
        searchParams.delete('email');
        searchParams.delete('code');
        setSearchParams(searchParams);
        setCode(voucherCode);
      }
    }
    if (astroSlug &&
      searchParams.has('slug')) {
      searchParams.delete('slug');
      setSearchParams(searchParams);
    }
  }, [email, voucherCode, setSearchParams, searchParams, astroSlug])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setToastOpen(true)
  }, [toast])

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  useEffect(() => {
    if (!isGuest) {
      if (dynamicSlug && slug === source) {
        dispatch(
          getDynamicSlug({
            slug: dynamicSlug,
            source: slug
          })
        );  
        return;
      }
      if (qrSlug) {
        dispatch(setPrizeSlug(qrSlug));
        dispatch(getPrizeSlug(qrSlug));
      };
    }
  }, [dispatch, qrSlug, dynamicSlug, slug, source, isGuest])

  useEffect(() => {
    if (!isEmpty(prizeSlugData) && !canMint && prizeSlug) {
      setOpenPopup(true);
    }
  }, [dispatch, canMint, prizeSlug, prizeSlugData])

  const redeemNFT = (mintType) => {
    let newCode = code;
    if (prizeSlug 
      && mintType !== sectionType.voucherMint) {
      newCode = prizeSlug;
    } else if (dynamicSlug && 
      slug === source) {
      newCode = dynamicSlug;
    }
    const mintData = {
      voucherCode: newCode, 
      slug: slug, 
      itemDetails: item,
      mintType: mintType
    }
    dispatch(mintNFTLaunchpadItem(mintData));
  }

  useEffect(() => {
    return () => {
      dispatch(setPrizeSlugData({}));
      dispatch(setPrizeSlug(''));
    }
  }, [dispatch])

  const handleClosePopup = () => {
    setOpenPopup(false);
  }
  
  return (
    <div className="launchpad-sections">
      <div className="launchpad-sections-name">{name}</div>
      <div className="launchpad-sections-desc" dangerouslySetInnerHTML={{ __html: description }} />
      {stats && <div className="launchpad-sections-stats-wrapper">
        {stats.map((s, sIdx) => (
          <div key={`Launchpad_Item_Stats_${sIdx}`} className="launchpad-sections-stats">
            <div className="launchpad-sections-stats-value">{s.value}</div>
            <div className="launchpad-sections-stats-label">{s.label}</div>
          </div>
        ))}
      </div>}
      {item.sections.map((s, sIdx) => {
        const getIcon = () => {
          let icon = 'info';
          if (s.sectionType === sectionGMusicFest ||
            s.sectionType === sectionSMEvent ||
            s.sectionType === sectionExtraGDay) {
            icon = 'utility';
          }
          return icon;
        }

        const getImage = () => {
          if (s.sectionType === sectionGMusicFest) {
            return 'music-fest-banner';
          } else if (s.sectionType === sectionExtraGDay) {
            return 'spin-the-wheel-banner';
          } else if (s.sectionType === sectionSMEvent) {
            return 'sm-event-banner';
          }
        }

        const onVoucherMechanicsClick = () => {
          if (s.voucherMechanics &&
            s.voucherMechanics.uri) {
            window.location.href = s.voucherMechanics.uri
          }
        }

        const handleButtonClick = () => {
          if (isGuest) {
            dispatch(
              unauthorizedRedirect({ 
                isGuest, 
                authSteps: authSteps.login
              })
            );
            return;
          }
          redeemNFT(s.sectionType);
        }

        return (
          <div key={`Sections_${sIdx}`} className="mint">
            {s.sectionType === sectionType.mintingProgress && 
              <div className="mint-title">
                <div className="mint-progress">{s.title}</div>
                {s.subtitle && <div className="mint-subtitle">{s.subtitle}</div>}
              </div>}
            {s.sectionType === sectionType.mintingProgress && 
              !s.subtitle && 
              <Progress
                tickVisible
                percentage={s.percentProgress}
                className="launchpad-item-progress"
                barClassName="launchpad-item-progress-bar"
                tickClassName="launchpad-item-progress-tick"
              />}
            {s.sectionType === sectionType.voucherMint && 
              !s.cta.isSoldOut &&
              <LaunchpadItemSection
                wrapperHidden={!s.hasTextInput}
                title={s.title}
                hasVoucherMechanics={s.hasTextInput}
                onVoucherMechanicsClick={onVoucherMechanicsClick}
                textInput={s.hasTextInput && 
                  <input 
                    className={classNames(
                      "nft-voucher-input", {
                      "nft-voucher-input-claim": (s.cta.isDisabled || isGuest),
                      "nft-voucher-input-enabled": enableButton
                    })}  
                    type="text" 
                    value={code} 
                    placeholder={launchpadItemTexts.enterVoucherCode} 
                    onChange={(e) => setCode(e.target.value)}
                  />}
                voucherButtonClaim={(s.cta.isDisabled && !isGuest) || 
                  (code.length === 0 && !isGuest)}
                voucherButtonEnabled={enableButton}
                redeemNFT={handleButtonClick}
                isGuest={isGuest}
                guestButtonText={launchpadItemTexts.loginToMint}
                buttonText={s.cta.title}
              />}
            {s.sectionType === sectionType.qrMint && 
              !s.cta.isSoldOut &&
              <LaunchpadItemSection
                title={s.title}
                hasVoucherMechanics
                onVoucherMechanicsClick={onVoucherMechanicsClick}
                voucherButtonClaim={(!isEmpty(prizeSlugData) && !canMint) || 
                  (!isGuest && s.cta.isDisabled)}
                voucherButtonEnabled={!isEmpty(prizeSlugData) && canMint && (qrSlug || prizeSlug)}
                redeemNFT={handleButtonClick}
                isGuest={isGuest}
                guestButtonText={launchpadItemTexts.loginToMint}
                buttonText={s.cta.title}
                claimedContent={!isEmpty(prizeSlugData) && 
                  !canMint && 
                  <div className="nft-voucher-text-claim">{launchpadItemTexts.alreadyClaimedNFT}</div>}
              />}
            {s.sectionType === sectionType.unliMint && 
              <LaunchpadItemSection
                wrapperHidden={!s.hasTextInput}
                title={s.title}
                voucherButtonClaim={s.cta.isDisabled || isGuest}
                voucherButtonEnabled={dynamicSlug || enableButton}
                redeemNFT={handleButtonClick}
                isGuest={dynamicSlug && isGuest}
                guestButtonText={launchpadItemTexts.loginToClaimNFT}
                buttonText={s.cta.title}
              />}
            {(s.sectionType === sectionType.voucherMint ||
              s.sectionType === sectionType.qrMint) && 
              s.cta.isSoldOut &&
              <div className="mint-wrapper">
                <div className="nft-voucher-redeemed">
                  <div className="nft-voucher-redeemed-title">{launchpadItemTexts.allNFTsRedeemed}</div>
                  <div className="nft-voucher-redeemed-desc">{launchpadItemTexts.stayTuned}</div>
                </div>
              </div>}
            {s.sectionType !== sectionType.voucherMint &&
              s.sectionType !== sectionType.qrMint &&  
              s.sectionType !== sectionType.mintingProgress && 
              s.sectionType !== sectionType.unliMint && 
              <LaunchpadItemDetails
                text={s.title}
                icon={getIcon()}
                desc={s.sectionType === sectionType.about && s.body}
                cta={s.cta}
                link={s.cta?.uri}
                utilityText={s.sectionType !== sectionType.about && s.body}
                image={getImage()}
              />}
          </div>
        )})}
      {toast && <Toast toast={toast} open={toastOpen} hide={closeToast} />}
      <Popup open={openPopup} className="popup" hide={handleClosePopup}>
        {claimedReward ? 
          <>
            <div className="popup-image" />
            <div className="popup-text">{launchpadItemTexts.freeSpinAlreadyCollected}</div>
          </> : 
          <>
            <div className="popup-text mb-3 mt-5">{launchpadItemTexts.astroNFTCollectionSuccess}</div>
            <div className="popup-text">{launchpadItemTexts.freeSpinReward}</div>
          </>}
      </Popup>
    </div>
  );
}