import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Toast } from '../../Commons';
import { setDisplayToast } from '../../../actions';
import { defaultTimeoutMS, storageKeys } from '../../../constants';
import { displayLoginToastSelector } from '../../../selectors';

const ErrorToast = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toastOpen, setToastOpen] = useState(false);

  const toast = useSelector(state => displayLoginToastSelector(state));

  useEffect(() => {
    setToastOpen(true);
  }, [toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
      if (toast?.redirectUri) {
        navigate(toast?.redirectUri);
        Cookies.set(storageKeys.isBuyBoosterShown, true);
        Cookies.set(storageKeys.isSpinModalShown, true);
      }
    }, defaultTimeoutMS);
  }

  return (
    <Fragment>
      {toast &&
        <Toast toast={toast} open={toastOpen} hide={closeToast} />}
    </Fragment>
  )
};

export default ErrorToast;
