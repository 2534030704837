export const questFAQs = [
  {
    question: "What are Quests?",
    answer: "<div>Quests are tasks that you need to complete within the app in order to earn MetaPup Gems and account XP.</div><div>There are two quest types - Intro and Daily Quests.<ul><li>Intro quests are for new users, it can only be done once.</li><li>Daily quests can be completed once per day, and resets every 12:00 AM GMT+8.</li></ul></div>"
  },
  {
    question: "What are MetaPup Gems?",
    answer: "<div>MetaPup Gems are points that you earn by completing quests. Earning of MetaPup Gems is specific to a campaign period. For this early access campaign, you have until August 15, 2022 to earn MetaPup Gems.</div><div>At the end of the campaign period, you may exchange MetaPup Gems into prizes."
  },
  {
    question: "What is XP? How is it different from MetaPup Gems?",
    answer: "<div>XP are experience points that you gain on your account to increase your level. XP earned from quests or other sources are permanent, i.e. they remain even after the campaign period, and will be visible in your public profile.</div>"
  },
  {
    question: "What are the quest rewards?",
    answer: "<div>You may convert your MetaPup Gems into MetaverseGo Credits (MGC) at the end of the campaign period. MGC is our in-app currency which you can use to buy various digital goods and services within our app, such as mobile load, and in the future, NFTs in our Launchpad.</div><div>On top of that, the top 50 users in the leaderboard will win play-to-earn NFTs from our game partners!</div>"
  },
  {
    question: "How do I know my ranking?",
    answer: "<div>Simply go to the leaderboard tab beside this FAQ.</div>"
  },
  {
    question: "How do I redeem my prize?",
    answer: "<div>At the end of the campaign period, a redemption page for MGC will be enabled where you can convert your MetaPup Gems.</div><div>1 MGC = 1200 points</div><div>Upon conversion, you will see the MGC in your Wallet.</div>"
  }
];