import React, { Component } from 'react';
import classNames from 'classnames';
import { loginTexts } from '../../../constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import './PINForm.scss';

export class PINForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordRevealed: {
        pin: false,
        confirm: false
      }
    };
  }

  revealPassword = (key, revealed) => {
    this.setState({ passwordRevealed: { ...this.state.passwordRevealed, [key]: revealed } });
  }

  render() {
    const { handleInput, title, description, formInputs, invalidInputs, formData, submitPIN, isSubmit } = this.props;
    const { passwordRevealed } = this.state;

    return (
      <div className="pin-form">
        <form>
          <h1>{title}</h1>
          <p>{description}</p>
          {formInputs.map(i => (
            <div className="pin-form-row" key={`FormData_${i.name}`}>
              <span className="pin-form-label">{i.label}</span>
              <div className={classNames("pin-form-group", {"pin-form-group-error": invalidInputs.includes(i.name)})}>
                <input
                  type={passwordRevealed[i.name] ? 'text' : 'password'}
                  className="pin-form-input"
                  onChange={(e) => handleInput(e, i.name)}
                  value={formData[i.name]}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }} 
                  maxLength={6}
                />
                <div onClick={() => this.revealPassword(i.name, !passwordRevealed[i.name])} className="pin-form-password-reveal">
                  {passwordRevealed[i.name] ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </div>
              </div>
              {i.hint && <div className="pin-form-hint">{i.hint}</div>}
            </div>
          ))}
        </form>
        <div className="pin-form-button">
          <button 
            className={classNames(
              "pin-form-submit", 
              {"pin-form-submit-disabled": isSubmit})} 
            onClick={(e) => submitPIN(e)}
          >
            {loginTexts.otp.submit}
          </button>
          <button 
            onClick={this.props.handleBack} 
            className="pin-form-dismiss"
          >
            {loginTexts.pin.dismiss}
          </button>
        </div>
      </div>
    );
  }
}