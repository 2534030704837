import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAccount } from 'wagmi';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Radio } from '@mui/material';
import { updateGamePassCheckout } from '../../../actions';
import { 
  defaultPaymentMethod,
  onboardingTexts,
  paymentMethods,
  paymentMethodsData,
  storageKeys,
  wallets
} from '../../../constants';
import { overridePaymentMethodsData } from '../../../utils/overridePaymentMethodsData';

export const AvailablePaymentMethods = ({ checkoutContents, hasError, isGameOverCheckout }) => {
  const dispatch = useDispatch();
  const { address, isConnected, connector } = useAccount();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(checkoutContents?.selectedPaymentMethod);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState([]);

  const pmSourceSessionStorage = sessionStorage.getItem(storageKeys.pmSource);
  const pmSourceCookie = Cookies.get(storageKeys.pmSource);

  const handleSelectPaymentMethod = (code) => {
    const data = {
      selectedPaymentMethod: code,
      shouldNotBack: true
    };
    let updateData = data;
    if (!isGameOverCheckout) {
      updateData = {
        ...data,
        from: onboardingTexts.onboarding
      }
    }
    setSelectedPaymentMethod(code);
    dispatch(updateGamePassCheckout(updateData));
  }

  useEffect(() => {
    let acceptedPaymentMethods = checkoutContents?.acceptedPaymentMethods;
    let updatedAcceptedPaymentMethods = acceptedPaymentMethods;
    if (!isConnected) {
      updatedAcceptedPaymentMethods = acceptedPaymentMethods?.filter(a => a.code !== wallets.walletConnect.name);
    } else {
      updatedAcceptedPaymentMethods = overridePaymentMethodsData(
        updatedAcceptedPaymentMethods, 
        paymentMethodsData?.default.imageUrl,
        wallets.walletConnect.name, 
        wallets.metaMask.name, 
        address, 
        connector
      );
    }
    acceptedPaymentMethods = updatedAcceptedPaymentMethods;
    if (pmSourceSessionStorage && pmSourceCookie) {
      const filteredAcceptedPaymentMethods = checkoutContents?.acceptedPaymentMethods?.filter(a => a.code === paymentMethods.gcash);
      acceptedPaymentMethods = filteredAcceptedPaymentMethods;
    }
    setAcceptedPaymentMethods(acceptedPaymentMethods);
  }, [checkoutContents, pmSourceSessionStorage, pmSourceCookie])

  return (
    <div className={classNames(
      "game-pass-checkout-available-methods-container",
      {"game-pass-checkout-available-methods-container-error": hasError}
    )}>
      {acceptedPaymentMethods?.map((m, mIdx) => (
        <div 
          key={`AvailablePaymentMethods_${m.title}_${mIdx}`} 
          className="game-pass-checkout-available-methods-wrapper"
          onClick={() => handleSelectPaymentMethod(m.code)}
        >
          <div className="d-flex align-items-center gap-3">
            <div className="game-pass-checkout-available-methods-image-wrapper">
              <img 
                src={m.imageUrl} 
                alt={m.code} 
                className={classNames(
                  "game-pass-checkout-available-methods-image",
                  {"game-pass-checkout-available-methods-image-small": m.code === defaultPaymentMethod || 
                    m.code === wallets.walletConnect.name}
                )}
              />
            </div>
            <div className="d-flex align-items-start flex-column justify-content-start">
              <div className="game-pass-checkout-available-methods-title">{m.name}</div>
              <div className="game-pass-checkout-available-methods-description">{m.description}</div>
            </div>
          </div>
          <Radio
            checked={selectedPaymentMethod === m.code}
            name="radio-buttons"
            value={m.code}
            sx={{
              color: "#6d7278",
              '&.Mui-checked': {
                color: "#f304a0",
              },
            }}
          />
        </div>
      ))}
    </div>
  )
}
