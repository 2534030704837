import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Link } from 'react-router-dom';
import './Back.scss';

export const Back = ({ text, link }) => (
  <Link to={link} className="back-link">
    <ArrowBackIosNewRoundedIcon />
    <span>{text}</span>
  </Link>
);