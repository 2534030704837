import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  requestNFTCollectionDetails,
  requestNFTItemDetails,
  requestBuyNFTItem
} from '../api/nft';
import {
  GET_NFT_COLLECTION_DETAILS,
  GET_NFT_ITEM_DETAILS,
  BUY_NFT_ITEM
} from '../actions/constants';
import {
  setLoading,
  showOrderSummary,
  setNFTCollectionDetails,
  setNFTItemDetails,
  failedRequests
} from '../actions';
import { storageKeys } from '../constants';

const getNFTCollectionDetailsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestNFTCollectionDetails, payload.nftId, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setNFTCollectionDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getNFTItemDetailsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestNFTItemDetails, payload.nftId, payload.nftItemId, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setNFTItemDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const buyNFTItemSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestBuyNFTItem, payload.nftItemId, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      console.log(response);
    }
    yield put(showOrderSummary(false));
    yield put(setLoading(false));
  } catch (e) {
    yield put(showOrderSummary(false));
    yield put(setLoading(false));
    if (e.response) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* nftSaga() {
  yield all([
    takeLatest(GET_NFT_COLLECTION_DETAILS, getNFTCollectionDetailsSaga),
    takeLatest(GET_NFT_ITEM_DETAILS, getNFTItemDetailsSaga),
    takeLatest(BUY_NFT_ITEM, buyNFTItemSaga)
  ]);
}