import React from 'react';
import { Prize } from './Prize';
import classNames from 'classnames';
import './SpinTheWheelMechanics.scss';

export const Overview = ({ 
  title, 
  imageUrl, 
  description, 
  prizes, 
  specialPrizes, 
  prizeTitle, 
  isLocal 
}) => (
  <div className="stw-mechanics-overview">
    <div className="stw-mechanics-overview-item">
      <img src={imageUrl[0]} alt={title[0]} />
      <h1 className="stw-mechanics-title">{title[0]}</h1>
      <div 
        className="stw-mechanics-description" 
        dangerouslySetInnerHTML={{ __html: description[0] }} 
      />
    </div>
    <div className="stw-mechanics-prizes-items">
      <div className="stw-mechanics-prizes-title">{prizeTitle[0]}</div>
      {specialPrizes.map(pr => (
        <Prize key={`STWPrizes_${pr.title}`} {...pr} />
      ))}
    </div>
    <div 
      className={classNames(
        "stw-mechanics-description", 
        {"stw-mechanics-description-local": isLocal}
      )}
      dangerouslySetInnerHTML={{ __html: description[1] }} 
    />
    <div className="stw-mechanics-overview-item">
      <img src={imageUrl[1]} alt={title[1]} />
      <h1 className="stw-mechanics-title">{title[1]}</h1>
      <p className="stw-mechanics-description">{description[2]}</p>
    </div>
    <div className="stw-mechanics-prizes-items">
      <div className="stw-mechanics-prizes-title">{prizeTitle[1]}</div>
      {prizes.map(pr => (
        <Prize key={`STWPrizes_${pr.title}`} {...pr} />
      ))}
    </div>
  </div>
);