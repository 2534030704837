import React from 'react';
import { Modal } from 'react-bootstrap';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import './Toast.scss';

export const Toast = ({ toast, open, hide, headerText }) => {
  return (
    <Modal
      backdrop
      show={open}
      className="custom-toast-container"
      contentClassName="custom-toast"
      dialogClassName="custom-toast-dialog"
      backdropClassName="custom-toast-bg"
      centered
      onHide={hide}
    >
      <Modal.Header closeButton closeVariant="white" onHide={hide}>{headerText && toast.title}</Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          <div>{toast.message}</div>
          {toast.description && <div>{toast.description}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="custom-toast-symbol">
          {
            toast.result ?
              <CheckCircleOutlineRoundedIcon sx={{ fontSize: 40 }} /> :
              <ErrorOutlineRoundedIcon sx={{ fontSize: 40 }} />
          }
        </div>
      </Modal.Footer>
    </Modal>
  );
}