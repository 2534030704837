import React from 'react';
import { PINForm } from '../Commons';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export const PINAccountRecovery = ({ transform, backToPIN, texts, ...props }) => (
  <div className="pin-account-recovery" style={transform}>
    <div className="pin-account-recovery-header">
      <div className="pin-account-recovery-header-back" onClick={backToPIN}><ArrowBackIosNewRoundedIcon /></div>
      <span className="pin-account-recovery-header-title">Account Recovery</span>
    </div>
    <PINForm {...props} title={texts.title} description={texts.description} />
  </div>
);