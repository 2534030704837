import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import { GamePassProductAmount } from '../Components';
import { Skeletons } from '../Commons';
import {
  getMarketplaceNftDetails,
  setListedNftPagination
} from '../../actions';
import {
  listedNftPaginationSelector,
  marketplacePaginationNftDataHasNextPageSelector,
  marketplacePaginationNftDataPageSelector,
  marketplaceRenderingSelector
} from '../../selectors';
import {
  range,
  minWidths,
  maxWidths
} from '../../constants';
import './Marketplace.scss';

export const MarketplaceListedItem = ({ nftData, nftName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [collection, setCollection] = useState([]);

  const pagination = useSelector(state => listedNftPaginationSelector(state));
  const hasNextPage = useSelector(state => marketplacePaginationNftDataHasNextPageSelector(state));
  const page = useSelector(state => marketplacePaginationNftDataPageSelector(state));
  const isRendering = useSelector(state => marketplaceRenderingSelector(state));

  const { ref, inView } = useInView({ threshold: 0.9 });

  const tabletDisplay = useMediaQuery({ minWidth: minWidths.tablet });
  const mobileDisplay = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  const slug = location.pathname.split('/')[2].toLowerCase();

  useEffect(() => {
    setCollection(nftData.filter(i => typeof i !== "number"));
  }, [nftData])

  useEffect(() => {
    const data = {
      slug,
      page: 1,
      limit: 10,
      listed: true
    }
    dispatch(getMarketplaceNftDetails(data));
  }, [dispatch, slug])

  useEffect(() => {
    if (page === pagination || pagination === 1) return;
    if (!hasNextPage) return;
    const data = {
      slug,
      page: pagination,
      limit: 10,
      listed: true
    }
    dispatch(getMarketplaceNftDetails(data));
  }, [dispatch, slug, pagination, page, hasNextPage])

  useEffect(() => {
    if (!hasNextPage) return;
    if (inView) {
      setCollection(prev => [...prev, ...range(2)]);
      dispatch(setListedNftPagination());
    }
  }, [dispatch, inView, hasNextPage])

  return (
    <div className="marketplace-lists">
      <div className="marketplace-lists-header">
        <div className="marketplace-lists-title">
          <div className="marketplace-lists-title-icon" />
          <div className="marketplace-lists-title-text">Listed Items</div>
        </div>
        <div className="marketplace-lists-search">
          <div className="marketplace-lists-search-wrapper">
            <input className="marketplace-lists-search-input" placeholder="Search Items" />
            <div className="marketplace-lists-search-icon" />
          </div>
          <div className="d-flex align-items-center pe-0">
            <div className="marketplace-lists-filter" />
          </div>
        </div>
      </div>
      <Grid 
        container 
        spacing={mobileDisplay ? 3 : 8}
        className={classNames(
          "marketplace-lists-item",
          {"marketplace-lists-item-hide": !isRendering && isEmpty(collection)}
        )}
      >
        {isEmpty(collection) && 
          isRendering && 
          range(4).map(m => (
            <Grid
              item
              key={`GridItem_${m}`}
              xs={6} sm={4} md={3} lg={2}
              className="marketplace-lists-item-box"
            >
              <Skeletons
                image
                mobile
                limitTwo
                limitThree
                limitFour
                height={!tabletDisplay ? 160 : 250}
              />
            </Grid>
          ))}
        {!isEmpty(collection) && 
          collection.map((n, nIdx) => (
            <Grid 
              item 
              xs={6} sm={4} md={3} lg={2} 
              key={`MarketplaceNFT_${nIdx}`} 
              onClick={() => navigate(`/marketplace/${n.slug}/${n.nftListing && n.nftListing.nftId}`)}
              className="marketplace-lists-item-box"
            >
              {typeof n !== 'number' && 
                <div className="marketplace-lists-item-content" ref={ref}>
                  <img src={n.imageUrl} alt="nft" className="marketplace-lists-image"/>
                  <div className="marketplace-lists-item-details">
                    <div className="marketplace-lists-item-name">{nftName}</div>
                    <div className="marketplace-lists-item-title">{n.name}</div>
                    {n.nftListing &&
                      <GamePassProductAmount 
                        {...n.nftListing} 
                        isMarketplace 
                      />}
                  </div>
                </div>}
              {typeof n === 'number' && 
                <Skeletons 
                  image 
                  mobile 
                  limitTwo 
                  limitThree 
                  limitFour 
                  height={!tabletDisplay ? 160 : 250}
                />}
            </Grid>
          ))} 
      </Grid>
      {!isRendering && 
        isEmpty(collection) && 
        <div className="marketplace-lists-item-list">There are no listed NFTs in this collection</div>}
    </div>
  );
}