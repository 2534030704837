import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { goldCoinsText, earnGoldText, gamePassGamesCTA } from '../../../constants';
import './GamePassInventoryCoins.scss';

const GamePassInventoryCoins = ({ coins, className }) => (
  <div className={classNames("game-pass-inventory-coins", className)}>
    <div id="gold-coins" className="game-pass-inventory-coins-inner">
      <div className="game-pass-inventory-coins-stacked">
        <div className="game-pass-inventory-coins-title">{goldCoinsText}</div>
        <div className="game-pass-inventory-coins-cell">
          <div className="game-pass-inventory-coins-gold-icon" />
          <div className="game-pass-inventory-coins-gold">{coins}</div>
        </div>
      </div>
      <div className="game-pass-inventory-coins-button-wrapper">
        <Link to={gamePassGamesCTA} className="game-pass-inventory-coins-button">{earnGoldText}</Link>
      </div>
    </div>
  </div>
);

export default GamePassInventoryCoins;