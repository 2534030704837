import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Lottie from 'lottie-react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import loader from '../Commons/Loader/loader.json';
import { HiddenHeaderAndFooter, Share } from '../Commons'; 
import {
  bittoInlineStyle,
  componentType,
  defaultDomain,
  launchpadSharePlatforms,
  launchpadBanners,
  launchpadMintProcessing,
  launchpadNftSlug,
  launchpadCTA
} from '../../constants';
import { nftDataSelector } from '../../selectors';
import { setShareText } from '../../utils/setShareText';
import './LaunchpadItem.scss';

export const LaunchpadNFTMint = ({ shareIsVisible, openShare, exitShare, mintingNFTSuccess, urlText }) => {
  const nftData = useSelector(state => nftDataSelector(state));

  const location = useLocation().pathname.split('/');
  const isWenlambo = location[2] === launchpadNftSlug.wenlambo;

  const [bittoShare, setBittoShare] = useState({});
  const [header, setHeader] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [event, setEvent] = useState({});
  const [background, setBackground] = useState({});

  const getBanner = () => {
    const { gudiSquad, astro, fest } = launchpadBanners;
    if (isEmpty(nftData)) return;
    if (nftData.name.toLowerCase().includes(gudiSquad.name)) {
      return gudiSquad.banner;
    } else if (nftData.name.toLowerCase().includes(astro.name)) {
      return astro.banner;
    } else {
      return fest.banner;
    }
  }

  useEffect(() => {
    if (isEmpty(nftData)) return;
    for (const item of nftData.components){
      switch (item.componentType) {
        case componentType.bittoShare:
          setBittoShare(item.cta);
          break;
        case componentType.header:
          setHeader(item);
          break;
        case componentType.title:
          setTitle(item.title);
          break;
        case componentType.image:
          setImage(item.imageUrl);
          break;
        case componentType.event:
          setEvent(item);
          break;
        case componentType.background:
          setBackground(item);
          break;
        default:
          return null;
      }
    }

    return () => {
      setBittoShare({});
      setHeader({});
      setTitle('');
      setImage('');
      setEvent({});
      setBackground({});
    }
  }, [nftData])

  const isBittoShare = !isEmpty(bittoShare);

  return (
    <>
      {isEmpty(nftData) && 
        mintingNFTSuccess && 
        <div className="nft-voucher-minting-load">
          <div className="nft-voucher-minting-wrapper">
            <Lottie animationData={loader} className="nft-voucher-minting-icon" loop autoplay />
            <div className="nft-voucher-minting-text">{launchpadMintProcessing.title}</div>
            <div className="nft-voucher-minting-subtitle">{launchpadMintProcessing.description}</div>
          </div>
        </div>}
      {!isEmpty(nftData) && 
        !mintingNFTSuccess && 
        <div 
          style={isBittoShare ? bittoInlineStyle(background.imageUrl) : null}
          className={classNames(
            "nft-voucher-minting nft-claim", {
            "nft-voucher-minting-bitto": isBittoShare
          })}
        >
          <HiddenHeaderAndFooter hideheader hideFooter />
          <div 
            className={classNames(
              "nft-voucher-minting-container", {
              "nft-voucher-minting-container-transparent": isBittoShare
            })}
          >
            <div className={classNames(
              "nft-voucher-minting-container-content", {
              "nft-voucher-minting-container-content-gap": isBittoShare
            })}>
              {!isWenlambo &&
                <ArrowBackIosNewRoundedIcon 
                  onClick={() => window.location.href = `/launchpad/${location[2]}`} 
                  className="nft-voucher-minting-back"
                />}
              {!isEmpty(header) && 
                <img
                  src={header.imageUrl}
                  alt="header-logo"
                  className="nft-voucher-minting-header"
                />}
              <div className={classNames(
                "nft-voucher-minting-body", {
                "nft-voucher-minting-body-bg": isBittoShare,
                "nft-voucher-minting-body-center": isWenlambo
              })}>
                <div className="nft-voucher-minting-box">
                  <img 
                    src={nftData.imageUrl} 
                    alt="nft" 
                    className={classNames(
                      "nft-voucher-minting-image", {
                      "nft-voucher-minting-image-large": isBittoShare
                    })}
                  />
                </div>
                <div className={classNames(
                  "nft-voucher-minting-title", {
                  "nft-voucher-minting-title-small": isBittoShare
                })}>
                  {nftData.title}
                </div>
                <div className={classNames(
                  "nft-voucher-minting-name", {
                  "nft-voucher-minting-name-white": isBittoShare,
                  "nft-voucher-minting-name-default": isWenlambo
                })}>
                  {nftData.name}
                </div>
                <div className={classNames(
                  "nft-voucher-minting-desc", {
                  "nft-voucher-minting-desc-white": isBittoShare
                })}>
                  {nftData.subtitle}
                </div>
                {!isEmpty(nftData.components) && 
                  !isBittoShare && 
                  !isWenlambo &&
                  <button 
                    onClick={openShare} 
                    className="nft-voucher-minting-button">{nftData.components[0].cta.title}
                  </button>}
              </div>
              {isWenlambo &&
                <div 
                  onClick={() => window.location.href = `/launchpad/${location[2]}`}  
                  className="nft-voucher-minting-button">{launchpadCTA.title}
                </div>}
              {!isEmpty(nftData.components) && 
                nftData.components.length > 1 &&
                !isBittoShare &&
                <div className="nft-voucher-minting-footer">
                  {getBanner() !== launchpadBanners.gudiSquad.banner &&
                    <>
                      <div className="nft-voucher-minting-wheel">{nftData.components[1].title}</div>
                      <div className={`nft-voucher-minting-${getBanner()}`} />
                    </>}
                  {nftData.components[1].cta &&
                    !nftData.components[1].cta.uri.includes('/redeem/spin-the-wheel') &&
                    <a href={nftData.components[1].cta.uri} className="nft-voucher-minting-button">
                      {nftData.components[1].cta.title}
                    </a>}
                </div>}
              {!isEmpty(nftData.components) &&
                !isEmpty(title) &&
                !isEmpty(image) &&
                !isEmpty(event) &&
                isBittoShare &&
                <div className="nft-voucher-minting-contents">
                  <div className="nft-voucher-minting-contents-title">{title}</div>
                  <img className="nft-voucher-minting-contents-logo" src={image} alt="event" />
                  {event.cta && 
                    <button 
                      className="nft-voucher-minting-contents-button" 
                      onClick={() => window.open(event.cta.uri, '_blank', 'noreferrer')}
                    >
                      {event.cta.title}
                    </button>}
                  <div onClick={openShare} className="nft-voucher-minting-contents-share-wrapper">
                    <div className="nft-voucher-minting-contents-share-icon" />
                    <button className="nft-voucher-minting-contents-share">{bittoShare.title}</button>
                  </div>
                </div>}
            </div>
          </div>
        </div>}
      <Share
        image={nftData.imageUrl}
        title={setShareText(nftData.name, urlText)}
        subtitle={defaultDomain.replace('https://', '')}
        url={nftData.imageUrl}
        shareIsVisible={shareIsVisible}
        exitShare={exitShare}
        platforms={launchpadSharePlatforms}
      />
    </>
  );
}