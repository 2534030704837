import referralBannerDesktop from '../assets/gamepass/referral/desktop.png';
import referralBannerMobile from '../assets/gamepass/referral/mobile.png';
import spinTheWheelBanner from '../assets/gamepass/stw/desktop.png';
import spinTheWheelBannerMobile from '../assets/gamepass/stw/mobile.png';
import gamePasses from '../assets/gamepass/mechanics/game-passes.png';
import goldBoosters from '../assets/gamepass/mechanics/gold-boosters.png';
import gamePassMechanic1 from '../assets/gamepass/mechanics/game-pass-how/1.png';
import gamePassMechanic2 from '../assets/gamepass/mechanics/game-pass-how/2.png';

export const wenLamboText = 'Wen Lambo';
export const gamePassCTA = '/gamepass';
export const buyGamePassCTA = '/gamepass/buy';
export const gamePassCartCTA = '/gamepass/cart';
export const gamePassCheckoutCTA = '/gamepass/cart/checkout';
export const gamePassGamesCTA = '/gamepass/games';
export const gamePassInventoryCTA = '/gamepass/inventory';
export const gamePassMechanicsCTA = '/gamepass/mechanics';
export const wenLamboSpinTheWheelCTA = '/gamepass/spin-the-wheel';
export const wenLamboSpinTheWheelMechanicsCTA = '/gamepass/spin-the-wheel/mechanics';
export const gamePassScreenSections = {
  buyGamePass: 'Buy Game Pass & Boosters',
  playAndEarnGold: 'Play and Earn Gold',
  referAFriend: 'Refer a Friend',
  winPrizes: 'Win Prizes',
  wenLamboGames: 'Wen Lambo Games'
};
export const gamePassSections = {
  result: true,
  message: 'Success',
  sections: [
    {
      dataset: gamePassScreenSections.referAFriend,
      experience: 'Game Pass',
      d: {
        imageUrls: {
          bannerUrl: referralBannerDesktop,
          bannerUrlMobile: referralBannerMobile
        },
        link: '/referral'
      }
    },
    {
      id: 'win-prizes',
      dataset: gamePassScreenSections.winPrizes,
      experience: 'Game Pass',
      d: {
        imageUrls: {
          bannerUrl: spinTheWheelBanner,
          bannerUrlMobile: spinTheWheelBannerMobile
        },
        link: wenLamboSpinTheWheelCTA
      }
    }
  ]
};
export const gamePassEmptyTexts = {
  gamePass: 'You have no Game Passes',
  goldBoost: 'You have no Boosters'
};
export const gamePassText = 'Game Pass';
export const goldBoosterText = 'Gold Booster';
export const scoreBoosterText = 'Score Booster';
export const gamePassExpired = 'Active Pass expired';
export const gamePassToast = {
  title: 'Activate Game Pass',
  description: 'All games played without an active Game Pass will not earn Gold to win prizes. Do you wish to proceed?',
  buttonOne: 'Play Game',
  buttonTwo: 'Buy Game Pass',
};
export const gamePassGameMechanics = ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lobortis id quam quis augue nec ipsum mauris auctor. Egestas lectus cursus amet, ullamcorper lorem consectetur tristique consectetur. Lorem vulputate risus massa porttitor sit sed dignissim nec at. Nunc sagittis odio ullamcorper diam phasellus risus.'];
export const cardsKind = {
  gamePassFree: 'game-pass-free',
  gamePass: 'game-pass',
  goldBoost: 'gold-boost',
  scoreBoost: 'score-boost'
};
export const activateKind = {
  gamePass: 'gamepass',
  goldBoost: 'goldboost',
  bundle: 'bundle'
};
export const activateKindTitle = {
  gamePass: 'Game Pass',
  goldBoost: 'Booster'
};
export const gamePassTerms = {
  title: 'Terms and Conditions',
  description: 'By participating in Wen Lambo Beta campaign, you certify and acknowledge that you have fully read, understood, and agree to the <a href="/terms-and-conditions#gamepass" target="_blank">Game Pass Beta Terms and Conditions.</a>',
  buttonText: 'Yes, I Agree'
};
export const gamePassPopupFlags = {
  tutorial: 'isWenLamboPopupShown',
  termsAndConditions: 'isGamePassTermsAndConditionsShown'
};
export const gamePassMechanics = {
  title: 'Game Pass',
  wenLambo: {
    faqs: {
      title: 'Wen Lambo FAQs',
      items: [
        {
          question: 'What is Wen Lambo?',
          answer: 'Wen Lambo by MetaverseGo is a promotion that allows MetaverseGo users to buy a Game Pass and play games to earn Gold. The earned Gold can be used to spin the wheel and win prizes.'
        },
        {
          question: 'How do I join the Wen Lambo?',
          answer: 'To join Wen Lambo, you must be a registered MetaverseGo user. Sign up and create an account on app.metaversego.gg. If you’re already an existing user, just log in. After successfully entering the code, you should accept the Terms and Conditions. You can purchase a Game Pass, play games, and earn prizes.'
        },
        {
          question: 'How do I access the Wen Lambo page?',
          answer: 'Log in to your MetaverseGo account. On the homepage, navigate to the “Win Prizes” menu bar. You may also click the “Win Prizes” button at the bottom to access the Wen Lambo page.'
        },
        {
          question: 'What do I get as a Wen Lambo user?',
          answer: 'First-time Wen Lambo users are entitled to a one-time free 30-minute Game Pass. Activate the Game Pass to play games and earn Gold for a chance to win exciting prizes when you spin the wheel.'
        },
        {
          question: 'Can I convert my Gold to fiat/cash?',
          answer: 'No, you cannot convert any earned Gold from Wen Lambo into fiat/cash. Gold is used to spin the wheel for prizes. 1 spin costs 1,000 Gold.'
        },
      ]
    }
  },
  gamePass: {
    imageUrl: gamePasses,
    title: 'Game Pass',
    description: 'Game Pass allows you to play our selection of games and earn corresponding Gold from playing such games that you can use to spin the wheel and win exciting prizes. It is available in multiple durations:<ul><li><div>30 minutes</div></li><li><div>1 hour</div></li><li><div>2 hours</div></li><li><div>4 hours</div></li><li><div>1 day</div></li><li><div>3 days</div></li><li><div>7 days</div></li><li><div>30 days</div></li></ul>',
    mechanics: {
      title: 'How to Use a Game Pass',
      carousel: [
        {
          imageUrl: gamePassMechanic1,
          title: 'Game Pass Activation',
          description: 'Each Game Pass or Gold Booster has to be activated to be used. You may activate it upon purchase or on the Game Pass inventory.'
        },
        {
          imageUrl: gamePassMechanic2,
          title: 'Game Pass Timer',
          description: 'The timer will start once the Game Pass or Gold Booster has been activated and will expire once the duration has lapsed.'
        },
      ]
    },
    faqs: {
      title: 'Game Pass FAQs',
      items: [
        {
          question: 'What Games can I play with a Game Pass?',
          answer: 'You can play Crypto Blast, Trail Blazer, Jump Verse, Hive Mind, Meta Hoops, and Planet Crash. We refresh our game roster regularly, so keep checking for any new games, including Web 3.0 games!'
        },
        {
          question: 'I’m a new Wen Lambo user. Where can I find my free Game Pass?',
          answer: 'Find your free 30-minute Game Pass inside the Game Pass Inventory in the Wen Lambo Main Page. Activate your Game Pass to start playing and earning Gold.'
        },
        {
          question: 'I’ve used up my free Game Pass. How can I buy a Game Pass?',
          answer: 'You can buy a Game Pass from the Game Pass store. You can also navigate from the home screen, scroll down to the BUY GAME PASS section below the Game Pass banner. You will see various Game Pass configurations. Select your desired Game Pass and click BUY NOW. Select your preferred Payment Method and click PAY to complete your purchase. You may activate your Game Pass right away or activate it at a later time.'
        },
        {
          question: 'What are the payment methods for buying Game Passes?',
          answer: 'Game Passes and boosters can be purchased using MetaverseGo Credits (MGC).  MGC are virtual credits used for transactions within the MetaverseGo app, which can be topped up thru various third-party payment methods, e.g., telco load, credit card, e-wallet, online banking, over-the-counter, etc. Payment methods in fiat (USD or PHP) are also available depending on the country and/or mobile device used.'
        },
        {
          question: 'How do I know if my Game Pass is active?',
          answer: 'On the Wen Lambo page, you should see the Game Pass Timer under the Game Pass & Boosters section. If your Game Pass is active, the time remaining in your Game Pass is displayed. If you do not have an active Game Pass, you can activate a pre-purchased one or purchase a new one.'
        },
        {
          question: 'I want to activate a pre-purchased Game Pass. How can I activate it?',
          answer: 'At the Wen Lambo page, select ‘View Game Pass Inventory’ at the bottom of My Game Pass & Boosters section. Once you find your available Game Pass, click the Activate button. After it’s successfully activated, you should see the timer start. You’re now ready to play and win Gold!'
        },
        {
          question: 'Can I stack multiple active Game Passes?',
          answer: 'Yes, you can stack multiple Game Passes upon each other before the last one expires. Stacking an additional Game Pass will add the new Game Pass’s duration to the overall Game Pass timer.'
        },
        {
          question: 'Can I play a game without a Game Pass?',
          answer: 'Yes, you can still play a game without a Game Pass, but you will miss out on earning Gold and winning prizes. Purchase a Game Pass to earn Gold while playing games.'
        },
        {
          question: 'Can I earn Gold without a Game Pass?',
          answer: 'No, you cannot earn Gold from games without a Game Pass. Purchase a Game Pass to earn Gold while playing. Once you accumulate 1,000 Gold, you can then spin the wheel.'
        },
        {
          question: 'What happens after my Game Pass expires?',
          answer: 'Once your Game Pass expires, you cannot earn additional Gold and spin the wheel for a chance to win our prizes. The Gold you previously earned will be kept in your Gold inventory during Wen Lambo. Purchase a Game Pass to keep earning Gold and spinning the wheel.'
        },
        {
          question: 'I want to earn more Gold when playing games. What do I do?',
          answer: 'If you have an active Game Pass and want to earn even more Gold from playing games, you can purchase a Gold Booster.'
        }
      ]
    }
  },
  goldBooster: {
    imageUrl: goldBoosters,
    title: 'Gold Booster',
    description: 'Gold Booster increases your Gold earnings through a predetermined multiplier so you can have more chances to spin the wheel and win prizes. The multiplier can be:<ul><li><div>2x Gold Boost to double your Gold per game</div></li><li><div>3x Gold Boost to triple your Gold per game</div></li></ul>Gold Booster is available in multiple durations:<ul><li><div>30 minutes</div></li><li><div>1 hour</div></li><li><div>2 hours</div></li><li><div>4 hours</div></li><li><div>1 day</div></li><li><div>3 days</div></li><li><div>7 days</div></li><li><div>30 days</div></li></ul>',
    faqs: {
      title: 'Gold Booster FAQs',
      items: [
        {
          question: 'I have purchased a Gold Booster. Why can’t I activate it?',
          answer: 'Check if you have an Active Game Pass. You need an active Game Pass to activate your Gold Booster.'
        },
        {
          question: 'Can I stack Gold Boosters?',
          answer: 'Yes, you can, but you can only stack the same TYPE of Gold Booster. You can stack two 2x Gold Boosters or two 3x Gold Boosters. You cannot stack a 2x Gold Booster on top of a 3x Gold Booster. You will need to replace the Booster altogether.'
        },
        {
          question: 'What happens when I activate multiple Gold Boosters with the same multiplier?',
          answer: 'The duration length of each Gold Booster will be added, and the timer of your Gold Booster will be adjusted based on this. If you activated a 4-hour Gold Booster and still have 41 minutes left from your previously activated Gold Booster, your Gold Booster timer will be adjusted to 4 hours and 41 minutes.'
        },
        {
          question: 'What happens when I activate a 3x Gold Booster when I have an active 2x Gold Booster?',
          answer: 'You will be asked if you want to replace your active 2x Gold Booster with the 3x Gold Booster. If you want to proceed, the remaining time of your 2x Gold Booster will be forfeited, and your timer will be reset according to the duration length of your 3x Gold Booster.'
        },
        {
          question: 'What happens when I activate a 2x Gold Booster when I have an active 3x Gold Booster?',
          answer: 'You cannot do so since that will reduce your Gold earnings. You may only activate your 2x Gold Booster once the time of your 3x Gold Booster runs out.'
        },
        {
          question: 'What happens if my Game Pass Timer runs out and I still have an active Gold Booster?',
          answer: 'Your Gold Booster timer will continue to run. However, you still need an active Game Pass to earn Gold. Activate or purchase another Game Pass to maximize your earnings!'
        }
      ]
    }
  },
  generalTermsAndConditions: {
    title: 'Game Pass General Terms & Conditions',
    items: [
      'Game Passes are non-refundable, non-transferable, and valid only until the specified duration of the Game Pass purchased has lapsed after activation.',
      'MGO reserves the right to not award prizes to users who are identified to have conducted fraudulent acts, e.g., use of third-party programs, bots, hacks, etc. Prizes of users detected to do suspicious activities will be on hold. If the user has been confirmed to violate the terms, the prize will be returned to the prize pool.',
      'Game Pass is stackable and will be consumed when the user activates it.',
      'All Game Passes and Boosters purchased from the store will be valid until the end of the Wen Lambo promo. However, special rewards received as a new user or from other campaign mechanics such as Refer a Friend will expire after 30 days if unused and will be removed in inventory.',
    ]
  }
};
export const gamePassGamesGoldCoinsText = 'Current Gold';
export const gamePassExpiredText = 'Expired';
export const myGamePassSectionTitle = 'My Game Pass & Boosters';
export const gamePassInventoryTitle = 'Game Pass & Booster Inventory';
export const activeGamePassPopupTitle = 'Active Game Pass & Boosters';
export const gamePassInventorySections = {
  gamePass: 'My Game Pass',
  goldBoost: 'My Boosters'
};
export const wenLamboSTWText = 'Spin the Wheel';
export const wenLamboSTWIframeKey = 'spinTheWheelIframeUrl';
export const wenLamboIds = {
  marketingId: 'wenlambo',
  applicationId: 'wenlamboBeta',
  onBoarding: 'wenlambo_onboarding',
  conquest: 'conquest',
  web3CommunityFestival: 'web3-festival'
};
export const wenLamboBetaLinks = {
  code: {
    link: {
      href: '/beta-code',
      text: 'Input invite code instead'
    }
  },
  signup: {
    text: "Don't have an invite code?",
    link: {
      href: '/beta-application',
      text: 'Answer the form instead'
    }
  }
};
export const wenLamboBetaCodeTexts = {
  title: 'Input Invite Code',
  description: 'Welcome to Wen Lambo BETA! Enter the invite code sent to your email or answer the Form.',
  formLabel: '6-character verification code',
  checkbox: `By participating in Wen Lambo Beta campaign, you certify and acknowledge that you have fully read, understood, and agree to the Game Pass Beta <a href="/terms-and-conditions#gamepass" target="_blank">Terms and Conditions</a>`,
  button: 'Join Beta',
  success: "Thanks for joining Wen Lambo beta! We'll redirect you shortly.",
  error: {
    emptyCode: 'Sorry, invalid code. Please try again.'
  }
};
export const wenLamboBetaApplication = {
  title: 'Wen Lambo Beta Application',
  description: 'Welcome to Wen Lambo BETA! Answer the Form to proceed or Enter the invite code sent to your email.',
  error: 'Please answer all required questions',
  successToast: {
    title: 'Wen Lambo Application',
    message: "Thanks for applying. We'll send you a notification in your email/SMS and MetaverseGo inbox upon review."
  }
};
export const webGLTitle = 'Your graphics card does not seem to support WebGL';
export const webGLSubtitle = 'Please update your browser or your operation system';
export const gamePassActivatedText = 'Game Pass Activated.';
export const gamePassActivateNowText = 'Activate Now';
export const gamePassActivateText = 'Activate';
export const gamePassBuyNowText = 'Buy Now';
export const gamePassAddedToCartText = 'Added to Cart!';
export const gamePassViewInventoryTextDetailed = 'View Game Pass Inventory';
export const backToGamePassPageText = 'Back to Game Pass Page';
export const gamePassViewInventoryText = 'View Inventory';
export const gamePassSetPinAndContinueText = 'Set PIN & Continue';
export const gamePassContinueText = 'Continue';
export const gamePassActivateAndPlayText = 'Activate & Play';
export const gamePassPlayText = 'Play';
export const backToHomeText = 'Back to Home';
export const isDTIDemoUIKey = 'isDTIDemoUI';
export const gamePassRecommendedMethodsText = 'Recommended Method(s)';
export const gamePassPaymentMethodsText = 'Payment Methods';
export const gamePassActivatingMessage = 'Game Pass Activated \n Redirecting you to Games';
export const gamePassBadgeTexts = {
  recommended: 'Recommended',
  new: 'New'
};
export const gamePassProductKinds = {
  recommended: 'recommended',
  gamePasses: 'game-pass',
  goldBoosters2X: 'gold-boost-2x',
  goldBoosters3X: 'gold-boost-3x'
};
export const gamePassPacks = {
  pack4X: 'golden-nugget-4x-pack',
  pack6X: 'golden-bar-6x-pack',
  pack8X: 'golden-vault-8x-pack',
  pack10X: 'golden-reserve-10x-pack',
  welcomeBundle: 'welcome-bundle'
};
export const userHasActiveGoldBoostText = 'User has active Gold boost';
export const overrideActiveBoosterTexts = {
  activateBooster: 'Activate Gold Booster',
  cancel: 'Cancel'
};
export const gameCenterBannerDetails = {
  slug: wenLamboIds.marketingId,
  name: 'Wen Lambo',
  experience: activateKind.gamePass,
  short_desc: 'Wen Lambo by MetaverseGo is a promotion that allows MetaverseGo users to buy a Game Pass and play games to earn Gold. The earned Gold can be used to spin the wheel and win prizes.'
};
export const freeGamePassExpiryText = 'Activate before it expires on ';
export const activateTexts = {
  gamePass: 'Game Pass Activated!',
  booster: 'Gold Booster Activated!',
  bundle: 'Game Pass and Gold Booster Activated!'
};
export const gamePassParams = {
  section: 'section'
};
export const gameOverParams = ['s', 'bg', 'gb', 'bz', 'gpt', 'gpc', 'ug'];
export const gameOverAdditionalParams = {
  isUser: 'isUser',
  tournamentSlug: 'tournamentSlug',
  hasGP: 'hasGP'
};
export const freeGamePassContents = {
  title: "Here's a 30-min Game Pass for you!",
  description: 'Use this to play games and collect gold. Every 1000 Gold gets you a spin for a chance to win more prizes!',
  button: 'Claim Now'
};
export const gamePassCheckoutTitle = 'Checkout';