import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import TransactionDetails from '../../TransactionDetails';
import { 
  getUserTransactionDetails, 
  setLoading, 
  setSelectedMethod, 
  setTransactionVisible 
} from '../../../actions';
import { useQuery } from '../../../hooks/useQuery';
import { minWidths } from '../../../constants';
import './TransactionDetailsModal.scss';

const TransactionDetailsModal = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  
  const [searchParams, setSearchParams] = useSearchParams();

  const isTransactionVisible = useSelector(state => state.transactions.isTransactionVisible);
  const details = useSelector(state => state.transactions.transactionDetails);

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const id = query.get('id');
  
  useEffect(() => {
    if (isDesktop && !isEmpty(id)) {
      dispatch(setLoading(true));
      dispatch(getUserTransactionDetails({ id }));
    }
  }, [dispatch, isDesktop, id])
  
  const openModal = isTransactionVisible && !isEmpty(details) && id;

  const handleCloseTransactionModal = () => {
    dispatch(setTransactionVisible(false));
    dispatch(setSelectedMethod(''));
    searchParams.delete('id');
    searchParams.delete('channel');
    setSearchParams(searchParams, { replace: true });
  }

  return (
    <Modal
      backdrop
      show={openModal}
      className="transaction-details-modal-container"
      contentClassName="transaction-details-modal"
      dialogClassName="transaction-details-modal-dialog"
      backdropClassName="transaction-details-modal-bg"
      centered
      onHide={handleCloseTransactionModal}
    >
      <Modal.Body>
        <TransactionDetails isModal />
      </Modal.Body>
    </Modal>
  )
}

export default TransactionDetailsModal;