import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  failedRequests,
  setClaimData, 
  setFeaturedPromotion, 
  setLoading, 
  setRedeem, 
  setRedeemData 
} from '../actions';
import { 
  GET_FEATURED_PROMOTION, 
  GET_FEATURED_PROMOTION_SETTINGS, 
  GET_REDEEM, GET_TICKET 
} from '../actions/constants';
import { 
  requestRedeem, 
  requestRedeemSettings, 
  requestTicket 
} from '../api/redeem';
import { requestClaim } from '../api/spinTheWheel';
import { storageKeys } from '../constants';

const getFeaturedPromotionSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRedeem, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setFeaturedPromotion(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getFeaturedPromotionSettingsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRedeemSettings, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setRedeemData(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getTicketSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const data = {
      promotionId: payload.id
    }
    const response = yield call(requestTicket, data, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setClaimData(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getRedeemSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestClaim, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const { data } = response;
      yield put(setLoading(false));
      yield put(setRedeem({
        isRedeem: true,
        message: data.message.length > 0 ? data.message : 'You have successfully redeemed load. Thank you for participating in our Spin the Wheel Promo!'
      }));
    }
  } catch (e) {
    yield put(setRedeem({
      isRedeem: false,
      message: e.response.data.message
    }));
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* homeSaga() {
  yield takeLatest(GET_FEATURED_PROMOTION, getFeaturedPromotionSaga);
  yield takeLatest(GET_FEATURED_PROMOTION_SETTINGS, getFeaturedPromotionSettingsSaga);
  yield takeLatest(GET_TICKET, getTicketSaga);
  yield takeLatest(GET_REDEEM, getRedeemSaga)

}