import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { 
  authSteps,
  maxWidths,
  referralBannerText, 
  siteRoutes, 
  voucherTexts 
} from '../../constants';
import { unauthorizedRedirect } from '../../actions';
import { guestSelector } from '../../selectors';
import referralBannerDesktop from '../../assets/referral/home/desktop.jpg';
import referralBannerMobile from '../../assets/referral/home/mobile.jpg';
import playFriendsBannerDesktop from '../../assets/banners/play-friends/play-friends-desktop.png';
import playFriendsBannerMobile from '../../assets/banners/play-friends/play-friends-mobile.png';

export const HomeBanners = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  const isGuest = useSelector(state => guestSelector(state));

  const handleRoute = (route) => {
    dispatch(
      unauthorizedRedirect({ 
        isGuest, 
        authSteps: authSteps.login,
        route
      })
    );
    return;
  }

  const navigateToOtherDomain = (url) => {
    window.location.href = url;
  };

  let referralBanner = referralBannerDesktop;
  let playFriendsBanner = playFriendsBannerDesktop;

  if (isMobile) {
    referralBanner = referralBannerMobile;
    playFriendsBanner = playFriendsBannerMobile;
  }

  return (
    <div className="home-menu home-banners-container">
      <img 
        src={referralBanner} 
        alt={referralBannerText.referFriend} 
        className="home-banners" 
        onClick={() => handleRoute(siteRoutes.referral)}
        loading="lazy"
      /> 
      <img 
        src={playFriendsBanner} 
        alt={voucherTexts.redeemVouchers} 
        className="home-banners" 
        onClick={() => navigateToOtherDomain(process.env.REACT_APP_PLAY_FRIENDS_URL)}
        loading="lazy"
      /> 
    </div>
  )
}
