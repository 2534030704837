import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import './Quests.scss';

export const QuestFAQs = ({ faqs }) => (
  <Row className="quests-grids">
    {
      faqs.map(q => (
        <Col key={`${q.question}`} xs="12" sm="12" md="6" className="quests-grid">
          <div className={classNames("quests-grid-inner", "quests-grid-inner-rows")}>
            <div className="quests-grid-header" dangerouslySetInnerHTML={{ __html: q.question }} />
            <div className="quests-grid-paragraph" dangerouslySetInnerHTML={{ __html: q.answer }} />
          </div>
        </Col>
      ))
    }
  </Row>
);