export const regexConstants = {
  email: /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  numbersOnly: /^[0-9\b]+$/,
  nonDigit: /\D+/g,
  firstLetterSpace: /^\s/,
  specialChar: /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/,
  specialCharUnderscoreDot: /[!@#$%&*()+\-=\]{};':"\\|,<>?]+/,
  formattedPhoneNumber: /^(\+\d{3})(\d{2})(\d{3})(\d{4})$/,
  whitespace: / /gi,
  comma: /,/g,
  numberComma: /\B(?=(\d{3})+(?!\d))/g,
  quotes: /['"]+/g,
  startingZeroes: /^0+/,
  numbersWithDot: /^[0-9]*\.?[0-9]*$/
};