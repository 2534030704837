import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './GeneralDropdown.scss';

const GeneralDropdown = ({
  title, 
  options, 
  icon,
  setSelected, 
  className,
}) => {
  const [rangeSelected, setRangeSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});

  const onRangeSelect = (name, value, icon, address, kind) => {
    setRangeSelected(true);
    setSelectedOption({
      name, 
      value,
      icon,
    });
    setSelected({
      name, 
      value, 
      icon,
      address,
      kind
    });
  }

  return ( 
    <div className={classNames(
      `${className}-container`,
      {[`${className}-container-disable`]: options?.length <= 1}
    )}>
      <Dropdown drop="down">
        <Dropdown.Toggle 
          variant="dark" 
          className={`${className}-toggle`}
          size="sm" 
          split
        >
        <div className={`${className}-toggle-container`}>
          <div className={`${className}-title-container`}> 
            <div className={`${className}-title-icon-container`}> 
              {rangeSelected ? selectedOption.icon : icon} 
            </div>
            {rangeSelected ? selectedOption.name : title}  
          </div>
          {options?.length > 1 && 
            <ArrowDropDownIcon className="general-dropdown-caret" />}
        </div>
        </Dropdown.Toggle>
          <Dropdown.Menu 
            variant="light"
            className={`${className}-menu`}
          >
            {!isEmpty(options) &&
              options?.map((option, i) => {
              const name = option.name;
              let icon = option.icon;
              if (!icon) {
                icon = <img src={option?.iconUrl} alt={option?.kind} className="general-dropdown-title-icon" />;
              }
              return (
                <Dropdown.Item 
                  key={i} 
                  onClick={() => onRangeSelect(
                    name, 
                    option?.value, 
                    icon,
                    option?.address,
                    option?.kind
                  )}
                  className={`${className}-menu-item`}
                >
                  <div className={`${className}-title-icon-container`}>{icon}</div>
                  {name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
 
export default GeneralDropdown;