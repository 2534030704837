import { connect } from 'react-redux';
import { getProfileTutorial, setLoading } from '../actions';
import { hasProfileTutorialSelector, userProfileSelector } from '../selectors';
import { Profile } from '../components';
import { profileMenuItems } from '../constants';

const mapStateToProps = (state) => ({
  profile: userProfileSelector(state),
  hasTutorial: hasProfileTutorialSelector(state),
  menuItems: profileMenuItems
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  getTutorial: () => dispatch(getProfileTutorial())
});

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileContainer;