import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDisconnect } from 'wagmi';
import { getToken, onMessage } from 'firebase/messaging';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {
  Launchpad,
  Games,
  MGCConversion,
  TermsAndConditions,
  PrivacyPolicy,
  Promos,
  TopUp,
  TopUpPayment,
  SpinTheWheel,
  SpinTheWheelGame,
  SpinTheWheelMechanics,
  TicketRedeem,
  Mechanics,
  TicketRedeemClaim,
  Redeem,
  PaymentStatus,
  TransactionDetails,
  PaymentInstructions,
  Marketplace,
  WalletCollection,
  MarketplaceCollection,
  Tournaments,
  GamePassInventory,
  GamePassScreen,
  RewardsCenter,
  RewardDetails,
  BuyGamePass,
  GamePassPlay,
  GamePassGame,
  GamePassGames,
  GamePassDetails,
  GamePassMechanics,
  GamePassCheckout,
  ReferralScreen,
  ReferralMechanics,
  GameCenter,
  WithdrawalResult,
  NFTWithdrawal,
  GamePassCart,
  GamePassPaymentMethods,
  GlobeOTP,
  SpinTheWheelMechanicsGlobal,
  CardPayment,
  Withdrawal,
  FairPlayPolicy,
  Partners,
  OTPModal,
  ProtectedRoute,
  WithdrawalMethods
} from './components';
import {
  Layout,
  PIN,
  Profile,
  EditProfile,
  Home,
  Game,
  NFTs,
  NFTCollection,
  NFTItem,
  LaunchpadItem,
  Wallet,
  Communities,
  Guild,
  GuildApplication,
  Spend,
  SpendResult,
  Chat,
  ChatThread,
  Tutorial,
  Transactions,
  Security,
  ResetPIN,
  NotFound,
  Login
} from './containers';
import {
  getSpinAvailable, 
  getUserProfile, 
  navigateTo,
  setAuthStep,
  setGuest,
  setLoading,
  setPrizeSlug,
  setShowLoading,
  showGamePassCheckout
} from './actions';
import { 
  firebaseEvents,
  storageKeys, 
  notificationPermissions,
  fcmVapidKey,
  urlValidation,
  buyGamePassCTA,
  launchpadCTA,
  authSteps,
  isDev,
  layoutQueryParams,
  partnerRedirectUrl,
  siteRoutes,
  gamePassPacks,
  preservedKeys,
  duration,
  defaultTokenKey
} from './constants';
import { 
  authStepSelector,
  flagsDataSelector,
  guestSelector,
  loginSelector,
  navigateDataSelector,
  prizeSlugSelector, 
  redirectToGamePartnerPageSelector,
  referrerDetailsSelector,
  userProfileRequestedSelector,
  userProfileSelector
} from './selectors';
import { logFirebaseEvent } from './utils/logFirebaseEvent';
import { messaging } from './utils/firebase';
import { clearLocalStorageExcept } from './utils/clearLocalStorageExcept';
import './App.scss';

function App () {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { disconnectAsync } = useDisconnect({
    onSuccess() {
      dispatch(setLoading(false));
    }
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const prizeSlug = useSelector(state => prizeSlugSelector(state));
  const login = useSelector(state => loginSelector(state));
  const isGuest = useSelector(state => guestSelector(state));
  const referrerDetails = useSelector(state => referrerDetailsSelector(state));
  const userProfile = useSelector(state => userProfileSelector(state));
  const navigateData = useSelector(state => navigateDataSelector(state));
  const flagsData = useSelector(state => flagsDataSelector(state));
  const authStep = useSelector(state => authStepSelector(state));
  const gamePartnerPageRedirect = useSelector(state => redirectToGamePartnerPageSelector(state));
  const isUserProfileRequested = useSelector(state => userProfileRequestedSelector(state));

  const search = useLocation().search;
  const source = new URLSearchParams(search).get(storageKeys.source);
  const tag = new URLSearchParams(search).get(storageKeys.tag);

  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const previousRoute = localStorage.getItem(storageKeys.previousRoute);

  const loggedIn = (login.token && login.token !== '') || (!login.token && sessionToken);

  const adjustViewingHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === notificationPermissions.granted) {
      try {
        const messagingResolve = await messaging;
        const token = await getToken(messagingResolve, { vapidKey: fcmVapidKey });
        if (token) {
          onMessage(messagingResolve, (payload) => {
            const { notification, data } = payload;
            if (notification) {
              alert(notification.body);
              if (data && data.route) {
                let clickAction = `${window.location.origin}${data.route}`;
                if (data.route.includes(window.location.host)) {
                  clickAction = data.route;
                }
                window.open(clickAction, '_blank');
              }
            }
          });
        }
      } catch (e) {}
    }
  }

  useEffect(() => {
    if (isDev) requestPermission();
  }, []);

  useEffect(() => {
    const token = searchParams.get(defaultTokenKey);
    if (searchParams.has(defaultTokenKey)) {
      const myPromise = new Promise(resolve => {
        if (token) {
          localStorage.setItem(storageKeys.sessionToken, token);
          localStorage.removeItem(storageKeys.mobileNumber);
          localStorage.removeItem(storageKeys.username);
        } else {
          dispatch(setGuest(true));
          localStorage.clear();
        }
        searchParams.delete(defaultTokenKey);
        setSearchParams(searchParams, { replace: true });
        resolve();
      });

      myPromise.then(() => {
        dispatch(getUserProfile());
      })
    } 
  }, [dispatch, searchParams, setSearchParams])

  useEffect(() => {
    // navigate without reloading
    if (navigateData?.path) {
      const timeout = navigateData?.timeout || 0;
      setTimeout(() => {
        navigate(navigateData?.path, { state: navigateData?.state, replace: navigateData?.replace });
      }, timeout);
    }
    return () => {
      dispatch(navigateTo(null));
    };
  }, [dispatch, navigate, navigateData]);

  useEffect(() => {
    if (!isEmpty(userProfile)) dispatch(getSpinAvailable());
    adjustViewingHeight();
    window.addEventListener('resize', adjustViewingHeight);
    return () => window.removeEventListener('resize', adjustViewingHeight);
  }, [dispatch, userProfile]);

  useEffect(() => {
    // dynamic slug
    const slug = location.pathname.split('/')[2];
    if (searchParams.has(storageKeys.source)) {
      Cookies.set(storageKeys.dynamicSlug, slug);
      Cookies.set(storageKeys.source, source);
      navigate(`${siteRoutes.launchpad}/${source}`);
      return;
    }
    if (location.pathname.includes('qr-redeem')) {
      dispatch(setPrizeSlug(slug));
      logFirebaseEvent(
        firebaseEvents.qrScan, {
          slug: slug, 
          qr_code_url: window.location.href
        });
      navigate(launchpadCTA.astro);
    }
  }, [dispatch, navigate, location, searchParams, source])
  
  useEffect(() => {
    // for Astro NFT QR code
    if (prizeSlug) {
      Cookies.set(storageKeys.slug, prizeSlug);
      Cookies.set(storageKeys.isSpinModalShown, true);
    }
  }, [prizeSlug])
  
  useEffect(() => {
    // for affiliate link
    if (searchParams.has(storageKeys.tag)) {
      Cookies.set(storageKeys.tag, tag, { expires: 1 });
      searchParams.delete(storageKeys.tag);
      setSearchParams(searchParams)
    }
  }, [tag, searchParams, setSearchParams])

  useEffect(() => {
    // for onboarding flow
    if (searchParams.has('onboard') || location.pathname.includes('pin')) return;
    const isValidUrl = previousRoute?.includes(urlValidation) && previousRoute.includes('onboard');
    if (isValidUrl) {
      const url = new URL(previousRoute);
      navigate(`${url.pathname}${url.search}`);
      localStorage.removeItem(storageKeys.previousRoute);
    };
  }, [searchParams, previousRoute, location, navigate])

  useEffect(() => {
    // for onboarding flow redirection to Login
    if (location.pathname.includes('login')) {
      navigate(siteRoutes.home, { replace: true });
      dispatch(setAuthStep(authSteps.login));
    }
  }, [dispatch, navigate, location])

  useEffect(() => {
    // for limiting only GCash as the mode of payment from GCash redirection
    if (searchParams.has(storageKeys.pmSource)) {
      const source = searchParams.get(storageKeys.pmSource);
      Cookies.set(storageKeys.pmSource, source);
      sessionStorage.setItem(storageKeys.pmSource, source);
    }
  }, [searchParams])

  useEffect(() => {
     // for limiting only GCash as mode of payment from GCash redirection
    if (!sessionStorage.getItem(storageKeys.pmSource)) {
      Cookies.remove(storageKeys.pmSource);
    }
  }, [])

  useEffect(() => {
    // for referral redirect to buying of game gass
    if (!isEmpty(referrerDetails) && 
      !isGuest && 
      flagsData?.key === storageKeys.isGamePassTermsAndConditionsShown &&
      flagsData?.value &&
      searchParams.has(storageKeys.ref)) {
      searchParams.delete(storageKeys.ref);
      setSearchParams(searchParams, { replace: true });
      navigate(buyGamePassCTA);
    }
  }, [navigate, referrerDetails, isGuest, searchParams, setSearchParams, flagsData])

  useEffect(() => {
    // for Game Partner storing and removing flags to sessionStorage
    const redirectUri = searchParams.get(layoutQueryParams.redirectUri);
    const clientId = searchParams.get(layoutQueryParams.clientId)

    if (redirectUri && clientId) {
      sessionStorage.setItem(storageKeys.redirectUri, redirectUri);
      sessionStorage.setItem(storageKeys.clientId, clientId);
      navigate(siteRoutes.home, { replace: true });
    }

    const handleBeforeUnload = () => {
      sessionStorage.removeItem(storageKeys.redirectUri);
      sessionStorage.removeItem(storageKeys.clientId);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [searchParams, setSearchParams])

  useEffect(() => {
    // for Game Partner login in web app
    const redirectUri = sessionStorage.getItem(storageKeys.redirectUri);
    const clientId = sessionStorage.getItem(storageKeys.clientId);
    let tokenData;
    if (sessionToken) {
      tokenData = jwtDecode(sessionToken);
    }

    if (redirectUri && clientId) {
      dispatch(setShowLoading(true));

      if (isEmpty(sessionToken) && authStep !== authSteps.otp) {
        searchParams.set(layoutQueryParams.redirectUri, redirectUri);
        searchParams.set(layoutQueryParams.clientId, clientId);
        setSearchParams(searchParams, { replace: true });
        dispatch(setAuthStep(authSteps.login));
        dispatch(setShowLoading(false));
      } else {
        if (tokenData?.claims?.hasPIN) {
          const tokenData = JSON.parse(atob(sessionToken.split('.')[1]));
          const redirectRoute = `${partnerRedirectUrl}?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&uid=${tokenData.uid}`;
          if (sessionToken && gamePartnerPageRedirect) {
            window.location.replace(redirectRoute);
          }
        } else {
          searchParams.set(layoutQueryParams.redirectUri, redirectUri);
          searchParams.set(layoutQueryParams.clientId, clientId);
          setSearchParams(searchParams, { replace: true });
          dispatch(setAuthStep(authSteps.pin));
          dispatch(setShowLoading(false));
        }
      }
    }
  }, [dispatch, navigate, searchParams, setSearchParams, gamePartnerPageRedirect])

  useEffect(() => {
    // for Game Over Checkout
    const checkoutSku = searchParams.get(layoutQueryParams.checkoutSku);

    let showGameOverCheckout = false;
    let showWelcomeBundle = false;

    if (isUserProfileRequested) {
      showGameOverCheckout = !isGuest &&
        checkoutSku &&
        checkoutSku !== gamePassPacks.welcomeBundle;
      showWelcomeBundle = isGuest &&
        checkoutSku &&
        checkoutSku === gamePassPacks.welcomeBundle;
    }

    if (showGameOverCheckout || showWelcomeBundle) {
      dispatch(showGamePassCheckout(true));
    }
  }, [dispatch, isGuest, searchParams, isUserProfileRequested])

  useEffect(() => {
    // disconnect wallet once user logged out or session expired
    const disconnectWallet = async() => {
      await disconnectAsync();
      setTimeout(() => {
        clearLocalStorageExcept(preservedKeys);
      }, duration.oneSecond)
    }
    if (isGuest) {
      disconnectWallet();
    }
  }, [isGuest])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout loggedIn={loggedIn} />}>
          <Route index element={<Home />} />
          <Route path="pin" element={<PIN />} />
          <Route path='login' element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="profile" element={<Profile />} />
            <Route path="profile/edit" element={<EditProfile />} />
            <Route path="profile/security" element={<Security />} />
            <Route path="profile/security/reset-pin" element={<ResetPIN />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="wallet/:id" element={<WalletCollection />} />
            <Route path="wallet/:id/withdrawal/:result" element={<WithdrawalResult />} />
            <Route path="wallet/:id/withdrawal" element={<NFTWithdrawal />} />
            <Route path="wallet/transactions" element={<Transactions />} />
            <Route path="wallet/transactions/:id" element={<TransactionDetails />} />
            {/* Hide Inventory UI, UI access to inventory and all redirections to it
              <Route path="gamepass/inventory" element={<GamePassInventory />} /> */}
            <Route path="gamepass/cart/checkout" element={<GamePassCheckout />} />
            <Route path="gamepass/cart/checkout/otp" element={<GlobeOTP />} />
            <Route path="referral" element={<ReferralScreen />} />
            <Route path="topup" element={<TopUp />} />
            <Route path="withdrawal-methods" element={<WithdrawalMethods />} />
            <Route path="withdrawal-methods/withdraw" element={<Withdrawal />} />
            <Route path="withdrawal-methods/withdraw/status" element={<PaymentStatus />} />
            <Route path="topup/payment" element={<TopUpPayment />} />
            <Route path="payment/:method/:status" element={<PaymentStatus />} />
            <Route path="spend" element={<Spend />} />
            <Route path="spend/:success" element={<SpendResult />} />
            <Route path="messages" element={<Chat />} />
            <Route path="messages/:threadId" element={<ChatThread />} />
            <Route path="redeem/:id/:sku" element={<Redeem />} />
            <Route path="redeem/spin-the-wheel/:marketingId" element={<SpinTheWheel />} />
            <Route path="redeem/spin-the-wheel/:marketingId/mechanics" element={<Mechanics />} />
            <Route path="rewards-center" element={<RewardsCenter />} />
            <Route path="rewards-center/:slug" element={<RewardDetails />} />
            <Route path="otp" element={<OTPModal />} />
          </Route>
          <Route path="games" element={<Games />} />
          <Route path="games/:gameName" element={<Game />} />
          <Route path="game-center" element={<GameCenter />} />
          <Route path="nfts/trending" element={<NFTs />} />
          <Route path="nfts/nfts" element={<NFTs />} />
          <Route path="nfts/top" element={<NFTs />} />
          <Route path="nfts/:nftId" element={<NFTCollection />} />
          <Route path="nfts/:nftId/editions/:nftItemId" element={<NFTItem />} />
          <Route path="launchpad" element={<Launchpad />} />
          <Route path="launchpad/:slug" element={<LaunchpadItem />} />
          <Route path="launchpad/:slug/mechanics" element={<Mechanics />} />
          <Route path="marketplace/:slug" element={<Marketplace />} />
          <Route path="marketplace/:slug/:id" element={<MarketplaceCollection />} />
          <Route path="gamepass" element={<GamePassScreen />} />
          <Route path="gamepass/games" element={<GamePassGames />} />
          <Route path="gamepass/games/:id" element={<GamePassGame />} />
          <Route path="gamepass/buy" element={<BuyGamePass />} />
          <Route path="gamepass/cart" element={<GamePassCart />} />
          <Route path="gamepass/details" element={<GamePassDetails />} />
          <Route path="gamepass/cart/checkout/payment-methods" element={<GamePassPaymentMethods />} />
          <Route path="gamepass/mechanics" element={<GamePassMechanics />} />
          <Route path="gamepass/spin-the-wheel" element={<SpinTheWheelGame />} />
          <Route path="gamepass/spin-the-wheel/mechanics" element={<SpinTheWheelMechanics />} />
          <Route path="gamepass/spin-the-wheel/mechanics/global" element={<SpinTheWheelMechanicsGlobal />} />
          <Route path="play/:gameName/:id" element={<GamePassPlay />} />
          <Route path="payment/card" element={<CardPayment />} />
          <Route path="payment/instructions" element={<PaymentInstructions />} />
          <Route path="referral/mechanics" element={<ReferralMechanics />} />
          <Route path="community" element={<Communities />} />
          <Route path="guilds/:guildId" element={<Guild />} />
          <Route path="guild/:guildId/apply" element={<GuildApplication />} />
          <Route path="tutorial" element={<Tutorial />} />
          <Route path="mgc-conversion" element={<MGCConversion />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="fair-play-policy" element={<FairPlayPolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="redeem/g-music-fest" element={<TicketRedeem />} />
          <Route path="redeem/eventpass" element={<TicketRedeemClaim />} />
          <Route path="tournaments" element={<Tournaments />} />
          <Route path="premium" element={<Tournaments />} />
          <Route path="partners" element={<Partners />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="promos/globe" element={<Promos />}/>
      </Routes>
    </div>
  );
}

export default App;
