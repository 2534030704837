import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import {
  marketplaceRenderingSelector,
  spendLoadingTextSelector
} from '../../../selectors';
import loader from './loader.json';
import './Loader.scss';

export const Loader = ({ menusHidden, bottomMenuHidden, wrapped, loop = true }) => {
  const isRendering = useSelector(state => marketplaceRenderingSelector(state));
  const loadingText = useSelector(state => spendLoadingTextSelector(state));

  return (
    <div className={classNames(
      "loader", {
      "loader-no-bottom": bottomMenuHidden, 
      "loader-full": menusHidden, 
      "loader-bg": isRendering || wrapped,
      "loader-wrapped": wrapped
    })}>
      <div 
        className={classNames(
          "loader-animation", {
          "loader-animation-no-bottom": bottomMenuHidden,
          "loader-animation-wrapped": wrapped
        })}
      >
        <Lottie
          className="loader-animation-logo"
          animationData={loader}
          loop={loop}
          autoplay
        />
        {loadingText && <div className="loader-animation-text mt-3">{loadingText}</div>}
      </div>
    </div>
  )
};
