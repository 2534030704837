import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Tabs, Tab } from 'react-tabs-scrollable';
import { Col, Row } from 'react-bootstrap';
import { 
  AddToCartToast, 
  GamePassProductAmount, 
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { 
  addGamePassToCart, 
  getGamePassProducts, 
  setGamePassSelectedProduct, 
  unauthorizedRedirect
} from '../../actions';
import {
  cartTitles,
  singleQuantityAddToCart,
  gamePassProductKinds,
  defaultHeaderHeight,
  gamePassCheckoutCTA,
  maxWidths,
  gamePassParams,
  duration,
  authSteps,
  gamePassBadgeTexts
} from '../../constants';
import {
  gamePassProductsSelector,
  guestSelector
} from '../../selectors';
import { useQuery } from '../../hooks';
import {
  gamePasses,
  goldBoosters2X,
  goldBoosters3X,
  bestSellers
} from './images';
import cart from '../../assets/gamepass/icons/cart.png';
import cartNew from '../../assets/gamepass/icons/cart-new.png';
import 'react-tabs-scrollable/dist/rts.css';
import './BuyGamePass.scss';

export const BuyGamePass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileSM });
  const query = useQuery();

  const [searchParams, setSearchParams] = useSearchParams();
  const [openAddToCart, setOpenAddToCart] = useState(false);
  const [selectedSku, setSelectedSku] = useState({});
  const [productId, setProductId] = useState('');
  const [scrollTop, setScrollTop] = useState(0);
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [sections, setSections] = useState([]);

  const gamePassProducts = useSelector(state => gamePassProductsSelector(state));
  const isGuest = useSelector((state) => guestSelector(state));

  const productKind = query.get(gamePassParams.section);

  useEffect(() => {
    dispatch(getGamePassProducts());
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(gamePassProducts)) {
      const nonEmptyProducts = gamePassProducts.filter(p => p.skus && !isEmpty(p.skus));
      setProducts(nonEmptyProducts);
    }
  }, [gamePassProducts])

  const onTabClick = (e, index) => {
    const selectedKind = sections[index];
    searchParams.set(gamePassParams.section, selectedKind);
    setSearchParams(searchParams, { replace: true });
  }

  useEffect(() => {
    if (productKind) return;
    searchParams.set(gamePassParams.section, gamePassProductKinds.recommended);
    setSearchParams(searchParams, { replace: true });
  }, [productKind, searchParams, setSearchParams])

  useEffect(() => {
    let newSections = [];
    for (const product of products) {
      newSections.push(product.sectionId);
    }
    setSections(newSections);
  }, [products])

  useEffect(() => {
    const kindIndex = sections.indexOf(productKind);
    setActiveTab(kindIndex);
  }, [sections, productKind])

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(productKind);
      const page = document.getElementById('buy-gamepass');
      let elementOffset = 0;
      if (productKind !== gamePassProductKinds.recommended) {
        elementOffset = defaultHeaderHeight
      }
      const scrollToProps = {
        top: element?.offsetTop + elementOffset,
        left: 0,
        behavior: 'smooth'
      };
      setTimeout(() => {
        if (isMobile) {
          window?.scrollTo(scrollToProps);
        } else {
          page?.scrollTo(scrollToProps);
        }
      }, duration.pointOneSecond);

    }, duration.pointThreeSeconds)
  }, [isMobile, productKind])

  const handleSelectedProduct = (item) => {
    const addToCartData = {
      productId: item.productId,
      skuId: item.id,
      quantity: singleQuantityAddToCart,
      isCheckout: true,
      redirectLink: gamePassCheckoutCTA,
      requestCheckout: true,
      navigate
    };
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    dispatch(addGamePassToCart(addToCartData));
  }

  const handleProductDetails = (kind, item) => {
    dispatch(setGamePassSelectedProduct({ kind, item }));
    navigate(`/gamepass/details?productId=${item.productId}&skuId=${item.id}`);
  }

  const handleIcon = (kind) => {
    let icon = bestSellers;
    if (kind === gamePassProductKinds.gamePasses) {
      icon = gamePasses;
    } else if (kind === gamePassProductKinds.goldBoosters2X) {
      icon = goldBoosters2X;
    } else if (kind === gamePassProductKinds.goldBoosters3X) {
      icon = goldBoosters3X;
    }
    return icon;
  }
  
  const onAddToCart = (item) => {
    setProductId(item.productId);
    setSelectedSku(item);
    setOpenAddToCart(true);
  }

  const handleMobileScroll = () => {
    setScrollTop(window.scrollY);
  };

  const handleScroll = (e) => {
    if (!isMobile) {
      const page = document.getElementById('buy-gamepass');
      setScrollTop(page.scrollTop);
    }
  }

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleMobileScroll);
      return () => {
        window.removeEventListener('scroll', handleMobileScroll);
      };
    }
  }, [isMobile]);

  const handleCartButton = (isNew) => {
    const cartButton = isNew ? cartNew : cart;
    return cartButton;
  }

  const tabsSticky = scrollTop >= 64;

  return (
    <>
      {!isEmpty(products) &&
        <LayoutContent 
          id="buy-gamepass"
          outerClassName="buy-gamepass" 
          onScroll={handleScroll} 
        >
          <PageTitle title="Buy Game Pass" />
          <Section 
            sectionClassName={classNames(
              "buy-gamepass-section",
              {"buy-gamepass-section-sticky": tabsSticky}
            )}
            backButtonRoute
            backRoute={() => navigate(-1)}
            title="Buy Game Pass"
            titleCentered
            text="Back"
          >
            <Tabs
              activeTab={activeTab}
              onTabClick={onTabClick}
              hideNavBtns={true}
              hideNavBtnsOnMobile={true}
            >
              {products.map((p, pIdx) => (
                <Tab
                  key={`GamePassProductTabs_${p._id}_${pIdx}`}
                  className="buy-gamepass-tab-title"
                >
                  <img
                    src={handleIcon(p.kind)}
                    alt={p.kind}
                    className="buy-gamepass-tab-icon"
                  />
                  {p.name}
                </Tab>
              ))}
            </Tabs>
            <div className={classNames(
              "buy-gamepass-contents",
              {"buy-gamepass-contents-sticky": tabsSticky}
            )}>
              {products.map((p, pIdx) => (
                <div
                  key={`GamePassProducts_${p._id}_${pIdx}`}
                  className="buy-gamepass-content"
                  id={p.sectionId}
                >
                  <div className="buy-gamepass-content-title">{p.name}</div>
                  <Row>
                    {p.skus.map((g, gIdx) => (
                      <Col
                        xs="6"
                        sm="4"
                        md="3"
                        xl="2"
                        key={`GamePassesSKU_${g.id}_${gIdx}`}
                        className="buy-gamepass-content-box"
                      >
                        <div className={classNames(
                          "buy-gamepass-content-thumbnail",
                          {"buy-gamepass-content-thumbnail-new": g.isNew}
                        )}>
                          <div className={classNames(
                            "buy-gamepass-content-wrapper",
                            {"buy-gamepass-content-wrapper-recommended": g.isRecommended}
                          )}>
                            {g.isRecommended && (
                              g.isNew ?
                                <div className="buy-gamepass-content-new-badge">{gamePassBadgeTexts.new}</div> : 
                                <div className="buy-gamepass-content-recommended-badge">{gamePassBadgeTexts.recommended}</div>)}
                            <div className={classNames(
                              "buy-gamepass-content-info",
                              {"buy-gamepass-content-info-recommended": g.isRecommended}
                            )}>
                              <div
                                className="buy-gamepass-content-image-wrapper"
                                onClick={() => handleProductDetails(p, g)}
                              >
                                <img
                                  className={classNames(
                                    "buy-gamepass-content-image",
                                    {"buy-gamepass-content-image-recommended": g.isRecommended}
                                  )}
                                  src={g.imageUrls.imageUrl}
                                  alt={g.shortName}
                                />
                              </div>
                              <div
                                className={classNames(
                                  "buy-gamepass-content-info-details",
                                  "buy-gamepass-content-info-details-height"
                                )}
                                onClick={() => handleProductDetails(p, g)}
                              >
                                <div className="buy-gamepass-content-name">{g.shortName}</div>
                                <GamePassProductAmount {...g} />
                              </div>
                            </div>
                            <div className="buy-gamepass-content-button-wrapper">
                              <button
                                onClick={() => handleSelectedProduct(g)}
                                className={classNames(
                                  "buy-gamepass-content-button",
                                  {"buy-gamepass-content-button-new": g.isNew}
                                )}
                              >
                                {cartTitles.buyNow}
                              </button>
                              <img 
                                src={handleCartButton(g.isNew)} 
                                alt="cart" 
                                className="buy-gamepass-content-button-cart"
                                onClick={() => onAddToCart(g)}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </div>
          </Section>
          <AddToCartToast
            open={openAddToCart} 
            hide={() => setOpenAddToCart(false)} 
            sku={selectedSku}
            productId={productId}
          />
        </LayoutContent>}
    </>
  );
}