import {
  RESET_STATE,
  SET_FLAGS,
  SET_UPDATE_FLAG,
} from '../actions/constants';
  
const flagsState = {
  flagsData: {},
  flagDetails: {},
};
  
const flagsReducer = (state = flagsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return flagsState;
    case SET_FLAGS:
      return {
        ...state,
        flagsData: action.flagsData
      };
    case SET_UPDATE_FLAG:
      return {
        ...state,
        flagDetails: action.flagDetails
      };
    default:
      return state;
  }
}
  
export default flagsReducer;