import React from 'react';
import { Modal } from 'react-bootstrap';
import './PIN.scss';

export const PINRecoveryPopup = ({ open, hide, sendCode, texts }) => (
  <Modal
    backdrop
    centered
    show={open}
    className="pin-recovery-container"
    contentClassName="pin-recovery"
    dialogClassName="pin-recovery-dialog"
    backdropClassName="pin-recovery-bg"
    onHide={hide}
  >
    <Modal.Header>{texts.title}</Modal.Header>
    <Modal.Body>{texts.description}</Modal.Body>
    <Modal.Footer>
      <button className="pin-recovery-send-code-button" onClick={sendCode}>Send Code</button>
      <button className="pin-recovery-cancel-button" onClick={hide}>Cancel</button>
    </Modal.Footer>
  </Modal>
);