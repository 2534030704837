import React from 'react';
import camelCase from 'lodash/camelCase';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { GridItem } from './GridItem';
import { GridItemImage } from './GridItemImage';
import { GridItemInfo } from './GridItemInfo';
import { GridItemDetailedInfo } from './GridItemDetailedInfo';
import { GamePassProductAmount, RatingPlatforms } from '../../Components';
import { TournamentPrize } from '../../Components/TournamentPrize/TournamentPrize';
import { GridStats } from '../../Components/GridStats/GridStats';
import isEmpty from 'lodash/isEmpty';

export const Grid = ({ items, id, keyPrefix, experience, className, isCollectibles, userId }) => (
  <Row className={className}>
    {items.map(i => {
      const itemId = i.id || i._id;
      const key = id ? `${keyPrefix}_${id}_${itemId}` : `${keyPrefix}_${itemId}`;
      const imageUrl = i.images && i.images.length ? i.images[0].thumb_url : i.image_url;

      if (keyPrefix === 'CollectionItem') {
        return (
          <GridItem key={key} className={`${className}-item`}>
            <GridItemImage imageUrl={imageUrl} className={className} />
            <GridItemInfo name={i.name} className={className} />
          </GridItem>
        );
      } else {
        let name = i.id;
        if (experience === 'games') {
          name = (i.slug || camelCase(i.name).toLowerCase());
        } else if (experience === 'tournaments' ||
            experience === 'premium') {
          name = camelCase(i.gameName).toLowerCase();
        }
        
        let link = `/${experience}/${name}`;
        if (id) link = `/${experience}/${id}/editions/${i.id}`;
        if (keyPrefix === 'Tournaments') link = '#';  // Temporarily disable redirecting to Tournament details page

        let child = <GridItemInfo name={i.name} price={i.price} className={className} />;
        if (keyPrefix === 'TrendingDrops' ||
          keyPrefix === 'TopCollections' ||
          keyPrefix === 'Collection') {
          child = <div className={`${className}-item-name`}>{i.name}</div>;
        } else if (keyPrefix === 'Wallet') {
          child = <div className={`${className}-item-wrapper`}>
          <div className={`${className}-item-wrapper-title`}>{i.collectionName}</div>
            <div className={`${className}-item-wrapper-name`}>{i.name}</div>
            {i.nftListing && 
              <GamePassProductAmount 
                {...i.nftListing} 
                isMarketplace 
              />}
          </div>;
        } else if (keyPrefix.includes('Game')) {
          const firstTwoTags = i.tags.slice(0, 2);
          const firstTwoTagsLengths = firstTwoTags.map(t => t.length);
          const firstTwoTagsTotalLength = firstTwoTagsLengths.reduce((a, b) => a + b);
          const maxTags = firstTwoTagsTotalLength > 15 ? 1 : 2;
          child = <GridItemDetailedInfo name={i.name} tags={i.tags} maxTags={maxTags} className={className}>
            {i.platforms && i.platforms.length > 0 && <RatingPlatforms platforms={i.platforms} />}
          </GridItemDetailedInfo>;
        } else if (keyPrefix === 'Tournaments') {
          child = <GridItemInfo name={i.name} className={className}>
            {i.prizePool && <TournamentPrize {...i.prizePool} />}
          </GridItemInfo>;
        }

        let stats = null;
        if (keyPrefix === 'Tournaments') {
          stats = [
            { label: 'Start Date', value: i.startDate },
            { label: 'End Date', value: i.endDate },
            { label: 'Participants', value: i.participants }
          ];
        }
        return (
          <GridItem key={key} className={`${className}-item`}>
            <Link to={isCollectibles ? `/wallet/${i.id}` : link} className={`${className}-item-link`}>
              <GridItemImage imageUrl={imageUrl} className={className}>
                {
                  stats && 
                  <GridStats stats={stats} className={`${className}-item`} />
                }
                {
                  !isEmpty(i.nftListing) &&
                   userId === i.nftListing?.uid && 
                   <div className={`${className}-image-indicator`}>Listed</div>
                }
              </GridItemImage>
              {child}
            </Link>
          </GridItem>
        );
      }
    })}
  </Row>
);