import axios from 'axios';
import { baseUrl } from './url';

export function requestClaimItemViaVoucher(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('rewards/voucher/claim')
  });
}

export function requestUtilizeClaimedReward(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('rewards/voucher/use')
  });
}

export function requestRewardOTP(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/rewards/otp')
  });
}