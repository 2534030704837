export const onboardingTexts = {
  skipAndContinue: 'Skip and Continue',
  onboarding: 'onboarding',
  enterMobileNumber: 'Enter your Mobile Number',
  enterEmailAddress: 'Enter Email (Optional)',
  gamePassCheckout: 'Game Pass Checkout',
  availableMethods: 'Available Method(s)',
  howItWorks: 'How it Works'
};
export const onboardingParams = {
  isMobileOnboarding: 'isMobileOnboarding'
};