import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as Sentry from "@sentry/react";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ScrollToTop } from './components/Commons';
import { WalletConnectProvider } from './components/Components';
import { 
  blacklistedUrls,
  enableSentry, 
  sentryDSN, 
  sentryErrors, 
  sentryTracesSampleRate 
} from './constants';
import Store from './store';
import './index.css';

const tagManagerArgs = {
  gtmId: 'GTM-PS29PNH'
};

TagManager.initialize(tagManagerArgs);

const storeInstance = Store();

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Sentry.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: sentryTracesSampleRate,
  enabled: enableSentry,
  ignoreErrors: sentryErrors,
  denyUrls: blacklistedUrls,
  beforeSend(event) {
    try {
      if (event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>`) {
        return null;
      }
    } catch (event) {}
    return event;
  }
});

ReactDOM.render(
  <Provider store={storeInstance}>
    <BrowserRouter>
      <Sentry.ErrorBoundary>
        <ScrollToTop />
        <WalletConnectProvider>
          <App />
        </WalletConnectProvider>
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
