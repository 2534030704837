import React from 'react';
import { Modal } from 'react-bootstrap';
import LaunchpadShareIcons from './ShareIcons';
import './Share.scss';

export const Share = ({ 
  shareIsVisible, 
  exitShare, 
  title, 
  subtitle, 
  url, 
  image, 
  platforms 
}) => (
  <Modal
    backdrop
    show={shareIsVisible}
    className="share-popup-wrapper"
    contentClassName="share-popup"
    dialogClassName="share-popup-dialog"
    backdropClassName="share-popup-bg"
    centered
    onHide={exitShare}
  >
    <Modal.Header closeButton onHide={exitShare}>
      <div className="share-popup-content">
        {image && <img src={image} alt="nft" className="share-popup-image-share" />}
        <div className="share-popup-details">
          <div className="share-popup-title">{title}</div>
          <div className="share-popup-desc">{subtitle}</div>
        </div>
      </div>
    </Modal.Header>
    <Modal.Body>
      <LaunchpadShareIcons
        url={url}
        title={title}
        platforms={platforms}
      />
    </Modal.Body>
  </Modal>
);