import React from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import StarIcon from '@mui/icons-material/Star';
import { platformIcons } from './images';
import './RatingPlatforms.scss';

const RatingPlatforms = ({ rating, platforms }) => {
  const handleImageError = (e, iconName) => {
    const image = platformIcons[iconName];
    if (image) e.target.src = image;
  }

  return (
    <div className="rating-platforms">
      {!isNil(rating) &&
        <div className={classNames(
          "rating",
          {"rating-only": !platforms}
        )}>
          <StarIcon className="rating-icon" fontSize="inherit" />
          <span className="rating-rate">{rating}</span>
        </div>}
      {platforms && platforms.length > 0 &&
        <div className={classNames(
          "platforms",
          {"platforms-only": isNil(rating)}
        )}>
          {platforms.map(p => {
            const parsedIconUrl = p.split('/');
            const iconName = parsedIconUrl[parsedIconUrl.length - 1];
            const iconNameWithoutExtension = iconName?.split('.')[0];
            return (
              <img
                key={`Icon_${capitalize(iconNameWithoutExtension)}`}
                className="platforms-icon"
                src={p}
                alt={iconName}
                onError={(e) => handleImageError(e, iconNameWithoutExtension)}
              />
            );
          })}
        </div>}
    </div>
  );
}

export default RatingPlatforms;