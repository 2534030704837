import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Featured, LayoutContent, PageTitle } from '../Components';
import { Banner, Carousel, CarouselItem } from '../Commons';
import { CommunitiesGrid } from './CommunitiesGrid';
import { CommunitiesNews } from './CommunitiesNews';
import { firebaseEvents, storageKeys } from '../../constants';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './Communities.scss';

export class Communities extends Component {
  componentDidMount() {
    this.props.getUserCommunities();
    logFirebaseEventWithTimestamp(firebaseEvents.guildViewAll);
  }

  render() {
    const { communities, sessionExpired, isGuest } = this.props;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const loggedIn = sessionToken && sessionToken !== '' && !isGuest;
    const loggedOut = !loggedIn || (loggedIn && sessionExpired) || isGuest;

    if (communities && !isEmpty(communities)) {
      const featured = communities.find(c => c.dataset === "Featured Guilds");
      const trending = communities.find(c => c.dataset === "Trending Games");
      const recentNews = communities.find(c => c.dataset === "Recent News");
      const buttonText = loggedOut ? "Login to Apply" : "Apply as Scholar";

      return (
        <LayoutContent>
          <PageTitle title="Communities" />
          {
            featured &&
            <Featured>
              <Carousel>
                {featured.d.map((guild, key) => {
                  const experience = guild.experience.toLowerCase();
                  const guildApplicationLink = `/${experience}/${guild.id}/apply`;
                  const buttonLink = guildApplicationLink;

                  return (
                    <CarouselItem key={key}>
                      <Banner
                        title={guild.name}
                        text={guild.short_desc}
                        imageUrl={guild.image_url}
                        buttonText={buttonText}
                        buttonLink={buttonLink}
                        buttonAnchor
                        imageClickable
                        carousel
                        isGuest={isGuest}
                      />
                    </CarouselItem>
                  );
                })}
              </Carousel>
            </Featured>
          }
          {trending && <CommunitiesGrid title="Trending Guilds" list={trending.d} />}
          {recentNews && <CommunitiesNews title={recentNews.dataset} news={recentNews.d} />}
        </LayoutContent>
      );
    }

    return null;
  }
}