import React from 'react';
import classNames from 'classnames';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from 'react-router-dom';
import './BackArrow.scss';

export const BackArrow = ({ text, isInline = false }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(-1)}
      className={classNames({
        "back-button": !isInline,
        "back-button-inline": isInline
      })}>
      <ArrowBackIosNewRoundedIcon className="back-button-icon" />
      <div className={classNames({
        "back-button-title": !isInline,
        "back-button-title-inline": isInline
      })}>
        {text}
      </div>
    </div>
  );
}