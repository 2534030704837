import { 
  SET_QUESTS, 
  SET_QUEST_LEADERBOARDS, 
  SET_QUEST_FAQS, 
  RESET_STATE 
} from '../actions/constants';

const questsState = {
  quests: [],
  leaderboards: [],
  faqs: []
};

const questsReducer = (state = questsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return questsState;
    case SET_QUESTS:
      return {
        ...state,
        quests: action.quests
      };
    case SET_QUEST_LEADERBOARDS:
      return {
        ...state,
        leaderboards: action.questLeaderboards
      };
    case SET_QUEST_FAQS:
      return {
        ...state,
        faqs: action.questFAQs
      };
    default:
      return state;
  }
}

export default questsReducer;