import React, { useState } from 'react';
import classNames from 'classnames';
import countryCodes from 'country-codes-list';
import sortBy from 'lodash/sortBy';
import ReactCountryFlag from 'react-country-flag';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { defaultCountry, regexConstants } from '../../../constants';
import './Phone.scss';

export const Phone = ({
  country,
  phoneRef,
  countryCode,
  setCountryCode,
  hasFilter,
  mobileNumber,
  className,
  dropdownMenuClassName,
  width,
  height,
  hideCountryFlag,
  hideCountryCode,
  removeAutoFocus,
  placeholder,
  ...props
}) => {
  const [selectedCountryFlag, setSelectedCountryFlag] = useState('');
  const [countryNameFilter, setCountryNameFilter] = useState('');
  const location = useLocation();

  const isInEditProfile = location.pathname.includes('profile/edit');
  const isInSpend = location.pathname.includes('spend');

  const countryCodesList = countryCodes.customArray({
    name: '{countryNameEn}|{countryCode}',
    value: '+{countryCallingCode}'
  });
  const countryCodesListSorted = country && isInSpend && sortBy(country, c => c.name).map(c => ({
    ...c,
    dial_code: c.dial_code.replace(regexConstants.whitespace, '')
  }));
  const countryCodesListSortedLogin = !isInSpend && sortBy(countryCodesList, c => c.name).map(c => ({
    ...c,
    value: c.value.replace(regexConstants.whitespace, '')
  }));
  const countryCodesListSortedNew = isInSpend ? countryCodesListSorted : countryCodesListSortedLogin;
  const countryCodesListPH = countryCodesListSortedNew.filter(c => c.name.includes(defaultCountry));
  const countryCodesListOthers = countryCodesListSortedNew.filter(c => !c.name.includes(defaultCountry));
  const countryCodesListUpdated = [].concat(countryCodesListPH, countryCodesListOthers);
  const selectedCountry = countryCodesListUpdated.find(c => {
    if (isInSpend) {
      return c.dial_code === countryCode;
    } else {
      return c.value === countryCode;
    }
  });
  const selectedCountryCode = selectedCountry && (isInSpend ? selectedCountry.code : selectedCountry.name.split('|')[1]);
  const selectedCountryCodeFlag = countryCodesListUpdated.find(c => c.name === selectedCountryFlag);
  const splittedCountryCodeFlag = selectedCountryCodeFlag && selectedCountryCodeFlag.name.split('|')[1];

  const toggle = (open) => {
    if (!open) setCountryNameFilter('');
  }
  
  const handleCountryCode = (c) => {
    setCountryCode(isInSpend ? c.dial_code : c.value);
    setSelectedCountryFlag(c.name)
  }

  const defaultCountryFlagStyle = {
    verticalAlign: 'middle',
  };

  const countryFlagStyle = {
    verticalAlign: 'middle',
    width: `${width}px`,
    height: `${height}px`
  };

  let countryFlagNewStyle = defaultCountryFlagStyle;
  if (width && height) {
    countryFlagNewStyle = countryFlagStyle;
  }

  const shouldAutoFocus = !isInSpend && !removeAutoFocus;

  return (
    <div className={className}>
      {!hideCountryCode &&
        <Dropdown className="phone-country-code" onToggle={toggle}>
          <Dropdown.Toggle className={classNames(
            "phone-dropdown-toggle",
            {"phone-dropdown-toggle-hide": isInEditProfile}
          )}>
            {!hideCountryFlag &&
              <ReactCountryFlag
                countryCode={splittedCountryCodeFlag ? splittedCountryCodeFlag : selectedCountryCode}
                style={countryFlagNewStyle}
                svg
              />}
            <span>{countryCode}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className={classNames("phone-dropdown-menu", dropdownMenuClassName)}>
            {hasFilter && <input
              type="text"
              className="phone-dropdown-filter"
              placeholder="Enter your country name"
              onChange={(e) => setCountryNameFilter(e.target.value)}
              value={countryNameFilter}
            />}
            <div className="phone-dropdown-list">
              {
                countryCodesListUpdated.filter(c => {
                  const countryCodeName = c.name.split('|');
                  let updatedCountryCode = c;
                  if (countryNameFilter !== '') {
                    updatedCountryCode = countryCodeName[0]
                      .toLowerCase()
                      .indexOf(countryNameFilter.toLowerCase()) === 0;
                  }
                  return updatedCountryCode;
                }).map(c => {
                  const countryCodeName = c.name.split('|');
                  const countryName = isInSpend ? c.name : countryCodeName[0];
                  const countryCode = isInSpend ? c.code : countryCodeName[1];
                  const activeCountryCodeValue = countryCode === isInSpend ? c.dial_code : c.value;
                  const countryCodeValue = isInSpend ? c.dial_code : c.value;
                  return (
                    <Dropdown.Item
                      key={`CountryCode_${countryCode}`}
                      onClick={() => handleCountryCode(c)}
                      active={activeCountryCodeValue}
                      className="phone-dropdown-item"
                    >
                      <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ verticalAlign: 'middle' }}
                        svg
                      />
                      <div className="phone-country-code-name">{countryName}</div>
                      <div className="phone-country-code-value">{countryCodeValue}</div>
                    </Dropdown.Item>
                  );
                })
              }
            </div>
          </Dropdown.Menu>
        </Dropdown>}
      <input 
        ref={phoneRef}
        autoFocus={shouldAutoFocus}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }} 
        type="text"
        value={mobileNumber && mobileNumber}
        placeholder={placeholder}
        className="phone-input"
        {...props}
      />
    </div>
  );
}