import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Popup } from '../../Commons';
import { texts } from '../../../constants';
import './WithdrawalConfirmation.scss';

const WithdrawalConfirmation = ({ 
  item, 
  open, 
  hide, 
  handleConfirm,
  notice
}) => {
  const [showFullDestinationAddress, setShowFullDestinationAddress] = useState(false);

  return (
    <Popup
      open={open}
      hide={hide}
      className="withdrawal-confirmation-popup"
    >
      <div className="withdrawal-confirmation">
        <div className="withdrawal-confirmation-wrapper">
          <img src={item.imageUrl} alt="nft-icon" className="withdrawal-confirmation-image"></img>
          <div className="withdrawal-confirmation-details-container">
            <Row className="withdrawal-confirmation-details">
              <Col className="withdrawal-confirmation-details-title">{texts.nftName}</Col>
              <Col className="withdrawal-confirmation-details-value">{item.nftName}</Col>
            </Row>
            <Row className="withdrawal-confirmation-details">
              <Col className="withdrawal-confirmation-details-title">{texts.network}</Col>
              <Col className="withdrawal-confirmation-details-value">{item.chain}</Col>
            </Row>
            <Row className="withdrawal-confirmation-details">
              <Col className="withdrawal-confirmation-details-title">{texts.destinationWalletAddress}</Col>
              <Col className="withdrawal-confirmation-details-view-wrapper">
                <div>{item.toAddressShort}</div> 
                <div 
                  className="withdrawal-confirmation-details-view" 
                  onClick={() => setShowFullDestinationAddress(true)}
                >
                  {texts.viewFullAddress}
                </div>
              </Col>
            </Row>
            <Row className="withdrawal-confirmation-details">
              <Col className="withdrawal-confirmation-details-title"> 
                {texts.withdrawalTransferFees} 
              </Col>
              <Col className="withdrawal-confirmation-details-text"> 
                <div className="d-flex align-items-center justify-content-end">
                  <div className="withdrawal-confirmation-details-mgc-icon" />
                  {item.gasFee.text}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="withdrawal-confirmation-bottom">
          <div className="withdrawal-confirmation-container mt-4">
            <div className="withdrawal-confirmation-container-title"> 
              {texts.confirmWithdrawal}
            </div>
            <div className="withdrawal-confirmation-container-text"> 
              {texts.confirmationDescription} 
            </div>
            <div className="nft-withdrawal-notes-text mt-3" dangerouslySetInnerHTML={{__html: notice}} />
          </div>
          <button 
            className="withdrawal-confirmation-button-confirm" 
            onClick={handleConfirm}
          > 
            {texts.confirm}
          </button>
          <button 
            className="withdrawal-confirmation-button-cancel mb-3" 
            onClick={hide}
          > 
            {texts.cancel}
          </button>
        </div>
      </div>
      <Popup 
        open={showFullDestinationAddress}
        hide={() => setShowFullDestinationAddress(false)}
        className="withdrawal-confirmation-toast"
      >
        <div className="withdrawal-confirmation-toast-wrapper">
          <p className="withdrawal-confirmation-toast-address"> 
            {item.toAddress} 
          </p>
        </div> 
      </Popup>
    </Popup>
  )
};

export default WithdrawalConfirmation;