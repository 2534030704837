import {
  SET_HOME_PAGE_DESKTOP_BANNER,
  SET_HOME_PAGE_MOBILE_BANNER,
  SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_LOGO,
  SET_WEN_LAMBO_PAGE_MOBILE_BANNER_LOGO,
  SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_BG,
  SET_WEN_LAMBO_PAGE_MOBILE_BANNER_BG,
  SET_WEN_LAMBO_STW_DESKTOP_BANNER,
  SET_WEN_LAMBO_STW_MOBILE_BANNER,
  RESET_STATE
} from '../actions/constants';
  
const bannersState = {
  homePage: {
    featured: {
      desktop: '',
      mobile: ''
    }
  },
  wenLamboPage: {
    logo: {
      desktop: '',
      mobile: ''
    },
    bg: {
      desktop: '',
      mobile: ''
    },
    stw: {
      desktop: '',
      mobile: ''
    }
  }
};
  
const bannersReducer = (state = bannersState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return bannersState;
    case SET_HOME_PAGE_DESKTOP_BANNER:
      return {
        ...state,
        homePage: {
          ...state.homePage,
          featured: {
            ...state.homePage.featured,
            desktop: action.homePageDesktopBanner
          }
        }
      };
    case SET_HOME_PAGE_MOBILE_BANNER:
      return {
        ...state,
        homePage: {
          ...state.homePage,
          featured: {
            ...state.homePage.featured,
            mobile: action.homePageMobileBanner
          }
        }
      };
    case SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_LOGO:
      return {
        ...state,
        wenLamboPage: {
          ...state.wenLamboPage,
          logo: {
            ...state.wenLamboPage.logo,
            desktop: action.wenLamboPageDesktopBannerLogo
          }
        }
      };
    case SET_WEN_LAMBO_PAGE_MOBILE_BANNER_LOGO:
      return {
        ...state,
        wenLamboPage: {
          ...state.wenLamboPage,
          logo: {
            ...state.wenLamboPage.logo,
            mobile: action.wenLamboPageMobileBannerLogo
          }
        }
      };
    case SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_BG:
      return {
        ...state,
        wenLamboPage: {
          ...state.wenLamboPage,
          bg: {
            ...state.wenLamboPage.bg,
            desktop: action.wenLamboPageDesktopBannerBG
          }
        }
      };
    case SET_WEN_LAMBO_PAGE_MOBILE_BANNER_BG:
      return {
        ...state,
        wenLamboPage: {
          ...state.wenLamboPage,
          bg: {
            ...state.wenLamboPage.bg,
            mobile: action.wenLamboPageMobileBannerBG
          }
        }
      };
    case SET_WEN_LAMBO_STW_DESKTOP_BANNER:
      return {
        ...state,
        wenLamboPage: {
          ...state.wenLamboPage,
          stw: {
            ...state.wenLamboPage.stw,
            desktop: action.wenLamboSTWDesktopBanner
          }
        }
      };
    case SET_WEN_LAMBO_STW_MOBILE_BANNER:
      return {
        ...state,
        wenLamboPage: {
          ...state.wenLamboPage,
          stw: {
            ...state.wenLamboPage.stw,
            mobile: action.wenLamboSTWMobileBanner
          }
        }
      };
    default:
      return state;
  }
}
  
export default bannersReducer;