import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import { 
  setGamePassCheckout, 
  setLoading, 
  gamePassCheckoutReturnToPreviousPage,
  setGamePassCheckoutTokenizedPayments,
  setAddedGamePassCheckoutTokenizedPayments,
  setShowPaymentMethods,
  setShowLoading,
  failedRequests,
  navigateTo,
  showGamePassCheckout,
  setGamePassCheckoutSelectedCurrency,
  disableCheckoutButton,
  updateGamePassCheckout,
  showCurrencyToast,
  setGamePassCheckoutSelectedPaymentMethod
} from '../actions';
import { 
  ADD_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  DELETE_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS,
  GET_GAMEPASS_CHECKOUT, 
  GET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY, 
  SELECT_GAMEPASS_CHECKOUT_CURRENCY, 
  GET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS, 
  PAY_GAMEPASS_ON_CHECKOUT, 
  PAY_GAMEPASS_ON_CHECKOUT_OTP, 
  PAY_GAMEPASS_ON_CHECKOUT_RESEND_OTP, 
  UPDATE_GAMEPASS_CHECKOUT
} from '../actions/constants';
import { 
  requestGamePassAddTokenizedPayments,
  requestGamePassCheckout, 
  requestGamePassCheckoutPay, 
  requestGamePassCheckoutPayInstant, 
  requestGamePassCheckoutSelectCurrency, 
  requestGamePassCheckoutSelectedCurrency, 
  requestGamePassCheckoutUpdate, 
  requestGamePassDeleteTokenizedPayments, 
  requestGamePassTokenizedPayments,
  requestOnboardingGamePassCheckout,
  requestUpdateOnboardingGamePassCheckout
} from '../api/checkout';
import {
  defaultPaymentMethod,
  defaultTimeoutMS,
  gamePassCheckoutCTA,
  layoutQueryParams,
  onboardingTexts,
  responseStatus,
  siteRoutes,
  storageKeys,
  wallets
} from '../constants';
import { requestSpendLoadOtp, requestSpendLoadResendOtp } from '../api/spend';
import { checkoutContentsSelector } from '../selectors';
import { removeQueryParam } from '../utils/removeQueryParams';

const getGamePassCheckoutSaga = function* (payload) {
  yield put(setShowLoading(true));
  try {
    const requestCheckout = payload.payload === onboardingTexts.onboarding ? requestOnboardingGamePassCheckout : requestGamePassCheckout;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCheckout, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassCheckout(response.data.d));
    }
    yield put(setShowLoading(false));
  } catch (e) {
    yield put(setShowLoading(false));
    if (e.response) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const updateGamePassCheckoutSaga = function* (payload) {
  yield put(setLoading(true));
  const { selectedPaymentMethod, chainId, navigate, from, shouldNotBack = false } = payload.payload;
  const checkoutData = {
    selectedPaymentMethod,
    chainId
  }
  try {
    const requestUpdateCheckout = from === onboardingTexts.onboarding ? requestUpdateOnboardingGamePassCheckout : requestGamePassCheckoutUpdate;
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUpdateCheckout, checkoutData, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassCheckout(response.data.d));
      yield put(setGamePassCheckoutSelectedPaymentMethod(''));
      yield put(disableCheckoutButton(false));
      if (!isEmpty(response.data.d.selectedPaymentMethodBillingForm?.fields) && 
        navigate && 
        from !== onboardingTexts.onboarding) {
        navigate(`${siteRoutes.payment}/card?channel=${selectedPaymentMethod}&amount=${response.data.d?.summary?.total?.value}&currency=${response.data.d?.summary?.total?.currency}`);
      } else {
        if (!shouldNotBack) yield put(gamePassCheckoutReturnToPreviousPage(true));
        yield put(setShowPaymentMethods({ modal: false, dropdown: false }));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(gamePassCheckoutReturnToPreviousPage(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const payGamePassOnCheckoutSaga = function* (payload) {
  const { checkoutData, customer, transactionHash } = payload?.payload || {};
  if (!isEmpty(checkoutData)) {
    yield put(setShowLoading(true));
  } else {
    yield put(setLoading(true));
  }
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const tag = Cookies.get(storageKeys.tag);
    let checkoutPayload = {};
    if (tag) {
      checkoutPayload.affiliateLink = tag;
    } 
    let requestCheckoutPay = requestGamePassCheckoutPay;
    if (!isEmpty(checkoutData)) {
      requestCheckoutPay = requestGamePassCheckoutPayInstant;
      const { skuId, productId, quantity, selectedPaymentMethod } = checkoutData;
      const data = {
        skuId,
        productId,
        quantity,
        selectedPaymentMethod
      };
      checkoutPayload = data;
    } 
    if (!isEmpty(customer)) {
      checkoutPayload.customer = customer;
    }
    if (!isEmpty(transactionHash)) {
      checkoutPayload.transactionHash = transactionHash;
    }

    const response = yield call(requestCheckoutPay, checkoutPayload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      if (!isEmpty(checkoutData)) {
        Cookies.set(storageKeys.isBuyBoosterShown, true);
        Cookies.set(storageKeys.isSpinModalShown, true);
        if (window.location.pathname.includes(siteRoutes.referral)) {
          Cookies.set(storageKeys.isFromReferral, true);
        }
      }
      if (response.data.d?.billing?.isRedirectRequired || response.data.d?.billing?.checkoutUrl) {
        window.location.href = response.data.d.billing.checkoutUrl;
        Cookies.set(storageKeys.checkoutUrl, response.data.d?.billing?.checkoutUrl);
      } else if (response.data.d?.selectedPaymentMethod === defaultPaymentMethod || 
        response.data.d?.selectedPaymentMethod === wallets.walletConnect.name) {
        removeQueryParam(layoutQueryParams.checkoutSku);
        setTimeout(function() {
          window.location.href = `${siteRoutes.payment}/${kebabCase(response.data.d.selectedPaymentMethod)}/success?id=${response.data.d?.transactionId}`;
        }, defaultTimeoutMS);
      } else if (response.data.d?.billing?.isOtpRequired) {
        yield put(navigateTo({ path: `${gamePassCheckoutCTA}/otp?method=${response.data.d?.billing?.method}&referenceId=${response.data.d?.billing?.referenceId}` }));
        yield put(setLoading(false));
      }
      Cookies.remove(storageKeys.tag);
      yield put(showGamePassCheckout(false));
      yield put(setShowLoading(false));
    }
  } catch (e) {
    yield put(setShowLoading(false));
    yield put(setLoading(false));
    yield put(showGamePassCheckout(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const payGamePassOnCheckoutOtpSaga = function* (payload) {
  const { method, referenceNumber } = payload.payload;
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpendLoadOtp, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      window.location.href = `/payment/${method.toLowerCase()}/success?referenceId=${referenceNumber}`;
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const payGamePassOnCheckoutResendOtpSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpendLoadResendOtp, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const { method, referenceId } = response.data.d;
      window.location.href = `${gamePassCheckoutCTA}/otp?method=${method}&referenceId=${referenceId}`;
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassCheckoutTokenizedPaymentsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassTokenizedPayments, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassCheckoutTokenizedPayments(response.data.d))
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const addGamePassCheckoutTokenizedPaymentsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const checkoutData = yield select(checkoutContentsSelector);
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassAddTokenizedPayments, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      if (isEmpty(checkoutData?.tokenizedPaymentMethods)) {
        yield put(gamePassCheckoutReturnToPreviousPage(true));
      } else {
        yield put(setAddedGamePassCheckoutTokenizedPayments(true));
      }
      yield call(updateGamePassCheckoutSaga, {payload: {selectedPaymentMethod: payload.payload.selectedPaymentMethod, shouldNotBack: true}});
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const deleteGamePassCheckoutTokenizedPaymentsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassDeleteTokenizedPayments, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setAddedGamePassCheckoutTokenizedPayments(true));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const selectGamePassCheckoutCurrencySaga = function* () {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassCheckoutSelectCurrency, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassCheckoutSelectedCurrency(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getGamePassCheckoutSelectedCurrencySaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestGamePassCheckoutSelectedCurrency, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setGamePassCheckoutSelectedCurrency(response.data.d));
      yield put(disableCheckoutButton(false));
      yield put(showCurrencyToast(false));
      if (payload.payload?.currency) {
        yield put(updateGamePassCheckout({ 
          selectedPaymentMethod: wallets.walletConnect.name, 
          shouldNotBack: true 
        }));
      }
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      yield put(setGamePassCheckoutSelectedCurrency({}));
      yield put(disableCheckoutButton(true));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* checkoutSaga() {
  yield all([
    takeLatest(GET_GAMEPASS_CHECKOUT, getGamePassCheckoutSaga),
    takeLatest(UPDATE_GAMEPASS_CHECKOUT, updateGamePassCheckoutSaga),
    takeLatest(PAY_GAMEPASS_ON_CHECKOUT, payGamePassOnCheckoutSaga),
    takeLatest(PAY_GAMEPASS_ON_CHECKOUT_OTP, payGamePassOnCheckoutOtpSaga),
    takeLatest(PAY_GAMEPASS_ON_CHECKOUT_RESEND_OTP, payGamePassOnCheckoutResendOtpSaga),
    takeLatest(GET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS, getGamePassCheckoutTokenizedPaymentsSaga),
    takeLatest(ADD_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS, addGamePassCheckoutTokenizedPaymentsSaga),
    takeLatest(DELETE_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS, deleteGamePassCheckoutTokenizedPaymentsSaga),
    takeLatest(SELECT_GAMEPASS_CHECKOUT_CURRENCY, selectGamePassCheckoutCurrencySaga),
    takeLatest(GET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY, getGamePassCheckoutSelectedCurrencySaga)
  ]);
}