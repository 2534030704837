import React from 'react';
import classNames from 'classnames';

export const TableOfContents = ({ title, contents }) => (
  <div className="legal-statement-table-of-contents">
    <div className={classNames("legal-statement-title", "legal-statement-uppercase")}>{title}</div>
    <div className={classNames("legal-statement-paragraph", "legal-statement-numbered-list")}>
      {contents.map((c, idx) => (
        <div key={`TableOfContents_Item_${idx + 1}`}
          className={classNames("legal-statement-numbered-list-item", "legal-statement-uppercase")}
        >
          <div className="legal-statement-numbered-list-item-number">{idx + 1}.</div>
          <div className="legal-statement-numbered-list-item-text">{c}</div>
        </div>
      ))}
    </div>
  </div>
);