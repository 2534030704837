import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import { TopUp } from '../TopUp/TopUp';
import { TopUpPayment } from '../TopUpPayment/TopUpPayment';
import { TopUpPaymentLoading } from '../TopUpPayment/TopUpPaymentLoading';
import { useQuery } from '../../hooks/useQuery';
import { setCreatePayment, setTopUpVisible } from '../../actions';
import { duration, minWidths } from '../../constants';

export const WalletTopUpModal = ({ openTopUp, hide }) => {
  const query = useQuery();
  const method = query.get('method');
  const payment = query.get('mode');
  const channel = query.get('channel');
  const isBuyPayment = method && payment && channel;

  const [searchParams, setSearchParams] = useSearchParams();

  const paymentData = useSelector((state) => state.topup.payment);
  const isTopUpVisible = useSelector((state) => state.transactions.isTopUpVisible);
  const isInstructionsVisible = useSelector((state) => state.transactions.isInstructionsVisible);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMediaQueryChange = (matches) => {
    if (!matches && isBuyPayment && isTopUpVisible) {
      return navigate(`/topup/payment${window.location.search}`);
    }
    if (!matches && isTopUpVisible) {
      navigate('/topup');
      hide();
    }
  }

  useMediaQuery({ minWidth: minWidths.tablet }, undefined, handleMediaQueryChange);

  useEffect(() => {
    if (method) dispatch(setTopUpVisible(true));
  }, [dispatch, method])
  
  const handleHide = () => {
    setTimeout(() => {
      if (!isEmpty(paymentData)) {
        dispatch(setCreatePayment({}));
      }
      searchParams.delete('method');
      searchParams.delete('channel');
      searchParams.delete('mode');
      setSearchParams(searchParams, { replace: true });
    }, duration.oneSecond)
    hide();
  }

  return (
    <>
      <Modal
        backdrop
        show={openTopUp}
        className="wallet-modal-container"
        contentClassName="wallet-modal"
        dialogClassName="wallet-modal-dialog"
        backdropClassName="wallet-modal-bg"
        centered
        onHide={handleHide}
      >
        <Modal.Body>
          {!isBuyPayment && 
            !isInstructionsVisible && 
            <TopUp hide={hide} />}
          {isBuyPayment && 
            !isInstructionsVisible && 
            <TopUpPayment />}
        </Modal.Body>
      </Modal>
      {paymentData.loading && 
        <TopUpPaymentLoading />}
    </>
  )
}
