import { RESET_STATE, SET_SCHOLARSHIP_PROMOS } from '../actions/constants';

const scholarshipState = {
  promos: []
};

const scholarshipReducer = (state = scholarshipState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return scholarshipState;
    case SET_SCHOLARSHIP_PROMOS: {
      return {
        ...state,
        promos: action.promos
      };
    }
    default:
      return state;
  }
}

export default scholarshipReducer;