import { takeLatest, call, put, all } from 'redux-saga/effects';
import { 
  requestOnboardingTutorial, 
  requestTutorialScreens 
} from '../api/tutorial';
import { 
  GET_ONBOARDING_TUTORIALS, 
  GET_TUTORIAL_SCREENS 
} from '../actions/constants';
import { 
  failedRequests,
  setLoading, 
  setOnboardingTutorials, 
  setTutorialScreens 
} from '../actions';
import { storageKeys } from '../constants';
import tutorialScreens from '../assets/tutorial-screens';

const getTutorialScreensSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const responseCall = call(requestTutorialScreens, 'profile', sessionToken);
    const response = yield responseCall;
    if (response.status >= 200 && response.status < 400) {
      const responseData = response.data.d;
      const dataWithAnimations = responseData.map((d, idx) => {
        if (!payload.fromProfilePage) d.animation = tutorialScreens[idx];
        return d;
      });
      yield put(setTutorialScreens(dataWithAnimations));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getOnboardingTutorialsSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const responseCall = call(requestOnboardingTutorial, sessionToken);
    const response = yield responseCall;
    if (response.status >= 200 && response.status < 400) {
      yield put(setOnboardingTutorials(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* tutorialSaga() {
  yield all([
    takeLatest(GET_TUTORIAL_SCREENS, getTutorialScreensSaga),
    takeLatest(GET_ONBOARDING_TUTORIALS, getOnboardingTutorialsSaga)
  ]);
}
