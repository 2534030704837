import axios from 'axios';
import { baseUrl } from './url';

export function requestMarketplaceCollection(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('marketplace')
  });
}

export function requestAllListedNft(page, limit, listed, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`marketplace/all${page && page}${limit && limit}${listed && listed}`)
  });
}

export function requestMarketplaceNftDetails(slug, page, limit, listed, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`marketplace/${slug}${page && page}${limit && limit}${listed && listed}`)
  });
}

export function requestMarketplaceCollectiblesDetails(slug, id, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`marketplace/${slug}/${id}`)
  });
}

export function requestListNFT(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('marketplace/list')
  });
}

export function requestListingFee(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('marketplace/listing-fee')
  });
}

export function requestBuyNFT(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('marketplace/buy')
  });
}

export function requestCancelListNFT(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('marketplace/cancel-listing')
  });
}