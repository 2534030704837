import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './Banner.scss';

export const BannerMobile = ({ title, imageUrl, link, carousel, featured, isBundle }) => (
  <div className={classNames(
    "banner",
    "banner-mobile", {
      "banner-carousel": carousel,
      "banner-carousel-solo": featured.length <= 1
    }
  )}>
    <div
      className={classNames(
        "banner-cover",
        {"banner-cover-carousel": carousel}
      )}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
    {!isBundle &&
      <>
        {title ?
          <Link to={link} className="banner-details-mobile">
            <div className="banner-details-mobile-inner">
              <h1 className={classNames(
                "banner-title",
                "banner-title-mobile"
              )}>
                {title}
              </h1>
            </div>
          </Link> :
          <Link
            to={link}
            className={classNames(
              "banner-details-mobile",
              "banner-details-mobile-without-text"
            )}
          >
            <div className="banner-details-mobile-inner" />
          </Link>}
      </>}
  </div>
);