import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGamePassCheckoutPayment } from '../../actions';
import {
  backToGamePassPageText,
  backToHomeText,
  gamePassCTA,
  gamePassInventoryCTA,
  gamePassViewInventoryText,
  gamePassViewInventoryTextDetailed,
  orderCompleted,
  orderProcessing,
  successStatus,
  transactionIdText
} from '../../constants';
import {
  checkoutPaySelector,
  gamePassTransactionIsInTimeLimitSelector
} from '../../selectors';
import { success, process } from './images';

export const GamePassPurchased = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const payDetails = useSelector(state => checkoutPaySelector(state));
  const isInTimeLimit = useSelector(state => gamePassTransactionIsInTimeLimitSelector(state));

  const inventoryButtonText = isInTimeLimit ? gamePassViewInventoryTextDetailed : gamePassViewInventoryText;
  const backButtonText = isInTimeLimit ? backToGamePassPageText : backToHomeText;
  const successText = isInTimeLimit ? orderProcessing : orderCompleted;
  const successId = `${transactionIdText} ${payDetails.transactionId}`;
  
  useEffect(() => {
    return () => {
      dispatch(setGamePassCheckoutPayment({}));
    }
  }, [dispatch])

  const handleBack = () => {
    if (isInTimeLimit) {
      navigate(gamePassCTA);
    } else {
      navigate('/');
    }
  }

  const goToInventory = () => {
    navigate(gamePassInventoryCTA);
  }

  return (
    <div className="checkout-purchase">
      <div className="d-flex flex-column gap-2 align-items-center flex-grow-1 justify-content-center w-100">
        <img
          className="checkout-purchase-success"
          src={isInTimeLimit ? process : success}
          alt="success-icon"
        />
        <div className="checkout-purchase-success-text">{successText}</div>
        <div className="checkout-purchase-success-id">{successId}</div>
        {isInTimeLimit &&
          <div className="checkout-purchase-success-note mt-3">{successStatus.orderDescription}</div>}
        <div className="checkout-purchase-box">
          {payDetails.items.map((i, iIdx) => (
            <div key={`ProductKey_${iIdx}_${i.productId}`} className="d-flex gap-3 align-items-center pb-4">
              <img className="checkout-purchase-box-card" src={i.imageUrl} alt="card" />
              <div className="d-flex flex-column gap-2">
                <div className="checkout-purchase-box-text">{i.name} {`(${i.quantity}x)`}</div>
                <div className="d-flex gap-1 align-items-center">
                  {i.subTotal.currencyIconUrl && 
                    <img className="checkout-purchase-box-icon" 
                      src={i.subTotal.currencyIconUrl} 
                      alt="mgo-coin" 
                    />}
                  {payDetails.subTotal &&
                    <div className="checkout-purchase-box-price">{i.subTotal.text}</div>}
                </div>
              </div>
            </div>
          ))}
          <div className="checkout-purchase-box-bottom">
            <div className="checkout-purchase-box-total">
              <div>{payDetails.total.title}</div>
              <div>{payDetails.total.text}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="checkout-purchase-button">
          <button 
            onClick={goToInventory}
            className="checkout-purchase-button-activate"
          >
            {inventoryButtonText}
          </button>
          <button
            onClick={handleBack} 
            className="checkout-purchase-button-back"
          >
            {backButtonText}
          </button>
        </div>
      </div>
    </div>
  );
}