import { getAnalytics, logEvent } from 'firebase/analytics';

export const logFirebaseEvent = (eventName, params) => {
  const analytics = getAnalytics();
  logEvent(analytics, eventName, params);
}

export const logFirebaseEventWithTimestamp = (eventName, params) => {
  const eventDateTime = new Date();
  const timestamp = eventDateTime.toISOString();
  const eventParams = {
    timestamp,
    ...params
  };
  logFirebaseEvent(eventName, eventParams);
}