import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { requestLogin, requestValidateOTP } from '../api/login';
import { requestSetNewPIN, requestValidatePIN } from '../api/profile';
import { 
  SUBMIT_PIN, 
  RECOVER_PIN, 
  SUBMIT_PIN_OTP, 
  SUBMIT_NEW_PIN 
} from '../actions/constants';
import { 
  setLoading, 
  setPINAuthStep, 
  startPINOTPTimer, 
  setPINValidationError, 
  setDisplayToast, 
  failedRequests
} from '../actions';
import { layoutSelector } from '../selectors';
import {
  defaultTimeoutMS,
  firebaseEvents,
  loginTexts,
  partnerRedirectUrl,
  regexConstants,
  storageKeys
} from '../constants';
import { logFirebaseEventWithTimestamp } from '../utils/logFirebaseEvent';

var CryptoJS = require('crypto-js');

const submitPINSaga = function* (payload) {
  try {
    const layout = yield select(layoutSelector);
    const previousRoute = localStorage.getItem(storageKeys.previousRoute);
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const payloadData = { pin: payload.currentPIN };
    const response = yield call(requestValidatePIN, payloadData, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      localStorage.setItem(storageKeys.sessionToken, response.data.token);
      const sessionToken = response.data.token;
      const tokenData = JSON.parse(atob(sessionToken.split('.')[1]));
      Cookies.remove(storageKeys.officialLaunch);
      Cookies.remove(storageKeys.wenLamboOfficialLaunch);
      Cookies.remove(storageKeys.isLoggedInTutorial);
      for (let key in storageKeys.switchToApp) {
        Cookies.remove(storageKeys.switchToApp[key]);
      }
      const auth = getAuth();
      let newRoute = previousRoute;
      if (!isEmpty(layout.urlParams)) {
        newRoute = `${previousRoute}?email=${layout.urlParams.email}&code=${layout.urlParams.voucherCode}`;
      }
      const redirectUri = sessionStorage.getItem(storageKeys.redirectUri);
      const clientId = sessionStorage.getItem(storageKeys.clientId);
      let redirectRoute = '/';
      if (redirectUri && clientId) {
        redirectRoute = `${partnerRedirectUrl}?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&uid=${tokenData.uid}`;
      }
      signInWithCustomToken(auth, response.data.token)
        .then(() => {
          if (previousRoute && previousRoute !== '') {
            if (redirectUri && clientId) {
              window.location.replace(redirectRoute);
            } else {
              window.location.replace(newRoute);
              localStorage.removeItem(storageKeys.previousRoute);
            }
          } else {
            window.location.replace(redirectRoute);
          }
        })
        .catch((error) => {
          let toastTitle = loginTexts.pin.error.title;
          let toastMessage = loginTexts.pin.error.description;
          if (error.code && error.message) {
            toastTitle = error.code;
            toastMessage = error.message;
          }
          const toast = {
            result: false,
            title: toastTitle,
            message: toastMessage
          };
          localStorage.setItem(storageKeys.firebaseAuthErrorMessage, JSON.stringify(toast));
        });
      logFirebaseEventWithTimestamp(firebaseEvents.loginSuccess);
      yield delay(defaultTimeoutMS);
      if (localStorage.getItem(storageKeys.firebaseAuthErrorMessage) &&
        localStorage.getItem(storageKeys.firebaseAuthErrorMessage) !== '') {
        const firebaseAuthErrorMessage = JSON.parse(localStorage.getItem(storageKeys.firebaseAuthErrorMessage));
        yield put(setLoading(false));
        yield put(setDisplayToast(firebaseAuthErrorMessage));
        localStorage.removeItem(storageKeys.firebaseAuthErrorMessage);
      }
    }
  } catch (e) {
    logFirebaseEventWithTimestamp(firebaseEvents.loginFailed);
    yield put(setPINValidationError(true));
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const recoverPINSaga = function* (payload) {
  try {
    const encryptedMobNum = localStorage.getItem(storageKeys.mobileNumber);
    const bytes = CryptoJS.AES.decrypt(encryptedMobNum, storageKeys.mobileNumber.toLowerCase());
    const mobnum = bytes.toString(CryptoJS.enc.Utf8).replace(regexConstants.quotes, '');
    if (mobnum && mobnum !== '') {
      const formData = { mobnum, ...payload.payload };
      const response = yield call(requestLogin, formData);
      if (response.status >= 200 && response.status < 400) {
        yield put(setPINAuthStep(2));
        yield put(startPINOTPTimer(true));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitPINOTPSaga = function* (payload) {
  try {
    const response = yield call(requestValidateOTP, payload.params);
    if (response.status >= 200 && response.status < 400) {
      const sessionToken = response.data.token;
      localStorage.setItem(storageKeys.sessionToken, sessionToken);
      yield put(setPINAuthStep(3));
      yield put(startPINOTPTimer(false));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitNewPINSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSetNewPIN, payload.params, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setPINAuthStep(1));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* pinSaga() {
  yield all([
    takeLatest(SUBMIT_PIN, submitPINSaga),
    takeLatest(RECOVER_PIN, recoverPINSaga),
    takeLatest(SUBMIT_PIN_OTP, submitPINOTPSaga),
    takeLatest(SUBMIT_NEW_PIN, submitNewPINSaga)
  ]);
}