import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TutorialBox } from '../Components';
import {
  guestSelector, 
  homeTutorialStepSelector 
} from '../../selectors';
import { 
  authSteps,
  inboxActiveNumber, 
  profileActiveNumber, 
  storageKeys, 
  walletActiveNumber 
} from '../../constants';
import { unauthorizedRedirect } from '../../actions';
import './Footer.scss';

export const FooterItem = ({ 
  name, 
  route, 
  selected, 
  hovered,
  hoverMenuItem,
  step
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openTutorial, setTutorial] = useState(false);

  const tutorialStep = useSelector(state => homeTutorialStepSelector(state));
  const isGuest = useSelector(state => guestSelector(state));

  const iconName = name.toLowerCase().replace(' ', '-');
  const active = hovered === iconName || selected;
  const isNew = localStorage.getItem(storageKeys.isNew);

  const handleRoute = () => {
    if (isGuest && route !== '/') {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    navigate(route);
  }

  useEffect(() => {
    if (isNew) return setTutorial(true);
  }, [isNew])

  return (
    <div 
      key={`FooterMenu_${name}`} 
      className="footer-menu-item"
    >
      <div
        className={classNames(
          "footer-menu-item-link", {
          "footer-menu-item-link-active": (
            (active && 
            (tutorialStep !== walletActiveNumber && 
            tutorialStep !== inboxActiveNumber &&
            tutorialStep !== profileActiveNumber)) || 
            (tutorialStep === step)
          )
        })}
        onClick={handleRoute}
        onMouseOver={() => hoverMenuItem(iconName)}
        onMouseOut={() => hoverMenuItem('')}
        onTouchStart={() => hoverMenuItem(iconName)}
        onTouchEnd={() => hoverMenuItem('')}
      >
        <div className={classNames(
          "footer-menu-item-icon", 
          `footer-menu-item-icon-${iconName}`, {
          [`footer-menu-item-icon-${iconName}-active`]: (
            (active && 
            (tutorialStep !== walletActiveNumber && 
            tutorialStep !== inboxActiveNumber &&
            tutorialStep !== profileActiveNumber)) || 
            (tutorialStep === step)
          )
        })}>
          {openTutorial && 
            tutorialStep === step && 
            <div className="footer-menu-item-arrow" />}
        </div>
        <div className="footer-menu-item-name">{name}</div>
      </div>
      {openTutorial && 
        tutorialStep === step && 
        <TutorialBox footer setTutorial={setTutorial} />}
    </div>
  );
}