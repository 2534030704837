import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getTournaments, setLoading } from '../../actions';
import { tournamentsListSelector } from '../../selectors';
import { TournamentGrid } from '../Components';

export const Tournaments = () => {
  const pathnameArr = window.location.pathname.split('/').slice(1);
  const tournamentType = pathnameArr[0];

  const dispatch = useDispatch();
  const tournaments = useSelector(state => tournamentsListSelector(state));

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getTournaments(tournamentType));
  }, [dispatch, tournamentType]);

  return (
    <>
      {tournaments && !isEmpty(tournaments) &&
      <TournamentGrid {...tournaments} className="tournaments" />}
    </>
  );
}